import { useContext, useEffect } from "react"
import { ThemeContext } from "../../../../context/ThemeContext"
import { Icon } from "@iconify/react/dist/iconify.js"
import { useNavigate } from "react-router-dom"


const UncompleteSetting = () => {
    const { setTitle, listUncompleteSettings } = useContext(ThemeContext)
    const navigate = useNavigate();
    const color = {
        'site_setting' : 'bg-red-100 text-red-600',
        'email_template' : 'bg-blue-100 text-blue-600',
        'hotel' : 'bg-yellow-100 text-yellow-600'
    }

    useEffect(() => {
        setTitle('Uncomplete Settings')
    },[setTitle])

    const handleClick  = (params) => {
        navigate(params)
    }

    return (
        <div className='w-full'>
            { listUncompleteSettings?.map((post, index) => {
                return(
                    <div className={`${color[post.tipe]} w-full flex flex-col p-[20px] rounded-lg cursor-pointer shadow-lg mb-5`} key={index} onClick={() => handleClick(post.link)}>
                        <div className='font-bold'>{post.title}</div>
                        { post.tipe === 'email_template' ? 
                            <>
                                { post.deskripsi.map((des, indexDes) => {
                                    return(
                                        <div className='text-sm flex flex-row gap-2 mt-1 items-center' key={indexDes}><span><Icon icon="mdi:dot" /></span> Template Email {des.deskripsi} Belum ada</div>
                                    )
                                })}
                            </>
                            :
                            <div className='text-sm'>{post.deskripsi}</div>
                        }
                    </div>
                )
            })}
        </div>
    )
}
export default UncompleteSetting