import { Fragment } from "react"
import { useRegistrasiPerusahaan } from "./registrasiPerusahaanFunction";
import AlertComp from "../../../../Components/Atoms/AlertComp";
import Card from "../../../../Components/Layout/Card";
import Tables from "../../../../Components/molecules/Tables";
import { Icon } from "@iconify/react/dist/iconify.js";
import Modal from "../../../../Components/Atoms/Modal";
import Tooltip from "../../../../Components/Atoms/Tooltip";
import FormTextSingleComp from "../../../../Components/forms/FormTextSingleComp";
import Button from "../../../../Components/Atoms/Button";
import PagePagination from "../../../../Components/Atoms/PagePagination";


const RegistrasiPerusahaanPage = () => {
    const { alertValue, handleCloseAlert, listData, pageValue,
        handleSearch, handleChangeSearch, myLoading, handleViewDocument,
        modalState, modalDispatch, handleShowHistoryEmail, handleResendEmail,
        modalValue, setModalValue, dokumen, resendEmailForm, setResendEmailForm, handleChangeResendEmailForm,
        handleSubmitResendEmail, handleCloseModal, handlePaginate
    } = useRegistrasiPerusahaan();
    
    
    return (
        <Fragment>
            <AlertComp
                subText={alertValue.subText}
                text={alertValue.text}
                isOpen={alertValue.show}
                onClose={handleCloseAlert}
                color={alertValue.color}
            />
            <Modal
                show={modalState.isOpen}
                setShow={() => {
                    modalDispatch({type: 'CLOSE'})
                }}
                width="xl"
                persistent={false}
                btnClose={true}                                
                title={modalState.title}
                withHeader={true}
            >
                {modalState.content}
            </Modal>

            <Modal
                show={modalValue.show}
                setShow={() => {
                    setModalValue(modalValue => ({...modalValue, show: false}))
                }}
                width="xl"
                persistent={false}
                btnClose={true}                                
                title={modalValue.title}
                withHeader={true}

                // isOpen={modalValue.show}
                // onClose={handleCloseModal}
                // title={modalValue.title}
                
                // onSubmit={handleSubmitResendEmail}
                // onSubmitText={'Kirim Email'}
                // submitColor={"success"}
                // isLoading={isLoading}                
            >
                <div className="p-[20px]">

                    {  modalValue.tipe === 'dokumen' &&
                        <>
                            <iframe
                                src={`${dokumen.url}`}
                                // src={`https://docs.google.com/gview?url=${dokumen.url}&embedded=true`}
                                width="100%"
                                height="500px"
                                title="akta"
                            >
                            </iframe>                        
                        </>
                    }
                    { modalValue.tipe === 'resend_email' &&
                        <div>                            
                            <div>
                                <FormTextSingleComp
                                    {...resendEmailForm.to}
                                    formData={resendEmailForm}
                                    setFormData={setResendEmailForm}
                                />
                            </div>
                            <div className='mt-5'>
                                <FormTextSingleComp
                                    {...resendEmailForm.perusahaan}
                                    formData={resendEmailForm}
                                    setFormData={setResendEmailForm}
                                />
                            </div>
                            <div className='mt-5'>
                                <FormTextSingleComp
                                    {...resendEmailForm.email_template}
                                    formData={resendEmailForm}
                                    setFormData={setResendEmailForm}
                                    onChange={handleChangeResendEmailForm}
                                />
                            </div>
                            <div className='mt-5'>
                                <FormTextSingleComp
                                    {...resendEmailForm.subject}
                                    formData={resendEmailForm}
                                    setFormData={setResendEmailForm}
                                />
                            </div>
                            <div className='mt-5'>
                                <FormTextSingleComp
                                    {...resendEmailForm.body_email}
                                    formData={resendEmailForm}
                                    setFormData={setResendEmailForm}
                                />
                            </div>
                        </div>
                    }
                </div>
                <div className='border-t border-t-gray-200 mt-5 py-5 px-[20px] flex flex-row justify-end gap-2'>
                    { modalValue.tipe === 'resend_email' &&
                        <Button  color="primary" onClick={handleSubmitResendEmail}><Icon  icon='fa:send' /> Resend Email</Button>
                    }
                    <Button  color="base" onClick={handleCloseModal}>Close</Button>
                </div>
            </Modal>
            <Card
                isCreate={false}
                isSearch={true}
                searchValue={pageValue.search}
                handleSearch={handleSearch}
                onChangeSearch={handleChangeSearch}
            >
            
                <Tables className='border-separate rounded-sm'>
                    <Tables.Head className='bg-[#efeeee]'>
                        <tr>
                            <Tables.Header className='font-bold text-[13px] py-[10px] text-right'>#</Tables.Header>                            
                            <Tables.Header className='font-bold text-[13px] py-[10px]'>Perusahaan</Tables.Header>            
                            <Tables.Header className='font-bold text-[13px] py-[10px]'>Akta</Tables.Header>
                            <Tables.Header className='font-bold text-[13px] py-[10px]'>Action</Tables.Header>                            
                        </tr>
                    </Tables.Head>
                    <Tables.Body>
                        { myLoading && Array(16).fill(0).map((_, i) => {
                            return(
                                <Tables.Row className='animate-pulse' key={i}>
                                    <Tables.Data className='text-right' style={{textAlign : 'right'}}>
                                        <div className='h-4 w-[10px] bg-slate-200 rounded text-right'></div>                                    
                                    </Tables.Data>
                                    <Tables.Data>
                                        <div className='h-4 bg-slate-200 rounded'></div>
                                    </Tables.Data>
                                    <Tables.Data>
                                        <div className='h-4 bg-slate-200 rounded'></div>
                                    </Tables.Data>
                                    <Tables.Data>
                                        <div className='h-4 bg-slate-200 rounded'></div>
                                    </Tables.Data>
                                </Tables.Row>
                            )
                        })}
                        <Fragment>
                            {listData.map((post, index) => {
                                return (
                                    <Tables.Row key={index}>
                                        <Tables.Data className='text-right align-top'>{pageValue.offset + index + 1}</Tables.Data>
                                        <Tables.Data className='align-top'>
                                            <div className='flex flex-col items-start'>
                                                <div>{post.nama}</div>
                                                { post.reminders > 0 &&
                                                    <div 
                                                            className="items-center
                                                                text-[10px]  text-green-700 bg-green-100 
                                                                border-2 border-white rounded-md -top-2 -right-5 
                                                                dark:border-gray-900 px-3 py-1"
                                                        >Reminders count : {post.reminders}
                                                    </div>                                                    
                                                }
                                                <div className='italic mt-1'>0{post.telp}</div>
                                            </div>
                                        </Tables.Data>
                                        <Tables.Data className='align-top'>
                                            <button
                                                onClick={() => handleViewDocument('akta', post)}
                                                className={`${post.status_akta === 1 ? "text-green-600 bg-green-100" : "text-red-600 bg-red-100"} shadow items-center px-1.5 flex flex-row gap-1 py-0.5 text-[12px] rounded disabled:opacity-50`}
                                                disabled={!post.akta_display}
                                            >
                                                <Icon icon={post.status === 1 ? "solar:check-read-line-duotone" : "typcn:minus"}  className="text-[16px]" /> { post.status_akta === 1 ? "Valid" : "Not Valid"}
                                            </button>
                                        </Tables.Data>
                                        <Tables.Data className='align-top'>
                                            <div className="flex flex-row gap-2">
                                                <Tooltip tooltip={"Resend Email"} delay={500}>                                                
                                                    <button className='p-[8px] bg-orange-100 text-orange-600 rounded shadow text-[18px]'  onClick={() => handleResendEmail(post)}><Icon  icon='fa:send' /></button>
                                                </Tooltip>
                                                <Tooltip tooltip={"History Email"} delay={500}>                                                
                                                    <button className='p-[8px] bg-blue-100 text-blue-600 rounded shadow text-[18px]'  onClick={() => handleShowHistoryEmail(post)}><Icon  icon='solar:eye-outline' /></button>
                                                </Tooltip>
                                            </div>
                                        </Tables.Data>                                        
                                    </Tables.Row>
                                )
                            })}
                        </Fragment>
                    </Tables.Body> 
                </Tables>
                <div className='mt-5'>
                    <PagePagination
                        data={pageValue.obj}
                        handlePagination={handlePaginate}
                        page={pageValue.page}
                        lastPage={pageValue.lastPage}
                    />
                </div>
            </Card>

        </Fragment>
    )
}
export default RegistrasiPerusahaanPage;