import { Fragment, useContext } from 'react';
import Button from './Button';
import { ThemeContext } from '../../context/ThemeContext';

/**
 *
 * @param {{
* handlePagination: (page: number) => void
* page: number;
* data: any[];
* lastPage: number;
* totalCount: number;
* siblingCount: number;
* currentPage: number;
* pageSize: number;
* activeColor: "primary" | "base" | "success" | "warning" | "danger" | "info";
* rounded: "none" | "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "full";
* variant: "solid" | "flat";
* size: "xs" | "sm" | "md" | "lg" | "xl";
* className: string;
* }} props
*
*
*/

const PagePagination = ({
	handlePagination,
    page, 
    data,
    lastPage,
	// totalCount,
	// siblingCount = 1,
	// currentPage,
	// pageSize,
	activeColor = "primary",
	rounded,
	variant = "flat",
	size = "md",
	className
}) => {
    const { themeColor, colorMode } = useContext(ThemeContext);    
    const sizePagination =
		{
			xs: 25,
			sm: 30,
			md: 35,
			lg: 40,
			xl: 45,
    }[size] || size;

    const colorPagination =
		{
			primary: themeColor,
			base: "#BABCBD",
			success: "#4ED17E",
			warning: "#EEC239",
			danger: "#F26969",
			info: "#629BF8",
		}[activeColor] || activeColor;

    return (
        <Fragment>
            { data.length > 1 &&
                <div className='flex flex-row pagination gap-1'>
                    { parseInt(page) > 6 && 
                        <Button                            
                            size={sizePagination}
                            className="text-xs"
                            color={colorMode === "light" ? "#BABCBD95" : "#4D535595"}
                            textcolor={`${colorMode === "light" ? "#171C1E" : "white"}`}
                            variant={variant}
                            rounded={rounded}
                            onClick={() => handlePagination(1)}
                            disabled={parseInt(page) === 1}
                        >
                            {`<<`}
                        </Button>
                        // <button className='py-2 px-3 border border-gray-400 cursor-pointer rounded' onClick={() => handlePagination(1)}>{`<<`}</button>
                    }
                    { data.map((post, index) => {                        
                        return (                            
                            <Button
                                key={index}
                                size={sizePagination}
                                className="text-xs"
                                color={
                                    parseInt(post) === parseInt(page)
                                        ? colorPagination
                                        : colorMode === "light"
                                        ? "#BABCBD95"
                                        : "#4D535595"
                                }
                                textcolor={
                                    parseInt(post) === parseInt(page)
                                        ? "white"
                                        : colorMode === "light"
                                        ? "#171C1E"
                                        : "white"
                                }
                                variant={variant}
                                rounded={rounded}
                                onClick={() => handlePagination(post)}
                            >
                                {post}
                            </Button>                            
                        )
                        })}
                    { parseInt(lastPage) !== parseInt(page) &&               
                        <Button
                            size={sizePagination}
                            className="text-xs"
                            color={colorMode === "light" ? "#BABCBD95" : "#4D535595"}
                            textcolor={`${colorMode === "light" ? "#171C1E" : "white"}`}
                            variant={variant}
                            rounded={rounded}
                            disabled={parseInt(page) === parseInt(lastPage)}
                            onClick={() => handlePagination(lastPage)}
                        >
                            {`>>`}
                        </Button>
                    }
                </div>
            }
        </Fragment>
    )
}



export default PagePagination;