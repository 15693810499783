import { Fragment } from "react"
import { useKategoriPanitia } from "./kategoriPanitiaFunction"
import Card from "../../../../Components/Layout/Card";
import Tables from "../../../../Components/molecules/Tables";
import PagePagination from "../../../../Components/Atoms/PagePagination";
import FormTextSingleComp from "../../../../Components/forms/FormTextSingleComp";
import Tooltip from "../../../../Components/Atoms/Tooltip";
import { Icon } from "@iconify/react/dist/iconify.js";
import Button from "../../../../Components/Atoms/Button";
import Modal from "../../../../Components/Atoms/Modal";

const KategoriPanitiaPage = (props) => {
    const { onClickTr } = props;
    const { view, pageValue, handleCreate, handleSubmit, handleCancel, handleChangeSearch, handleSearch,
        listData, handlePaginate, formData, setFormData, handleEdit, handleDelete, handleSubmitDelete,
        modalValue, setModalValue, handleCloseModal
    } = useKategoriPanitia();
    return(
        <Fragment>
            <Modal
                show={modalValue.show}
                setShow={() => {
                    setModalValue(modalValue => ({...modalValue, show: false}))
                }}
                width='xl'
                persistent={false}
                btnClose={false}
            >
                <div className='py-[20px] px-[30px] border-b border-b-gray-200 font-bold'>
                    {modalValue.title}
                </div>
                <div className='py-[50px] px-[30px]'>
                    { modalValue.text }
                </div>
                <div className='py-[20px] px-[30px] border-t border-t-gray-200 flex flex-row justify-end gap-2'>
                    <Button color='danger' size='md' onClick={handleSubmitDelete}>Hapus</Button>
                    <Button color='base' size='md' onClick={handleCloseModal}>Cancel</Button>
                </div>
            </Modal>
            <Card
                title='Master Kandidat Ketua Umum'
                isCreate={view === 'create'}
                isSearch={view === 'list'}
                searchValue={pageValue.search}
                handleCreate={handleCreate}
                handleSubmit={handleSubmit}
                handleCancel={handleCancel}
                onChangeSearch={handleChangeSearch}
                handleSearch={handleSearch}
            >
                { view === 'create' &&
                    <Fragment>
                        <div className="grid grid-cols-2">
                            <div className="col-span-2 md:col-span-1">
                                <FormTextSingleComp
                                    {...formData.nama}
                                    formData={formData}
                                    setFormData={setFormData}
                                />
                                <div className="mt-5">
                                    <FormTextSingleComp
                                        {...formData.warna}
                                        formData={formData}
                                        setFormData={setFormData}
                                    />
                                </div>
                            </div>
                        </div>
                    </Fragment>
                }
                { view === 'list' &&
                    <Fragment>
                        <Tables className='border-separate rounded-sm'>
                            <Tables.Head className='bg-[#efeeee]'>
                                <tr>
                                    <Tables.Header className='font-bold text-[13px] py-[10px] text-right'>#</Tables.Header>
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>Nama</Tables.Header>                                
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>Warna</Tables.Header>                                
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>Action</Tables.Header>
                                </tr>
                            </Tables.Head>
                            <Tables.Body>
                                { listData?.data?.length === 0 ?
                                    <Tables.Row>
                                        <Tables.Data colspan={4} className='text-center'>Belum ada data</Tables.Data>
                                    </Tables.Row>
                                    :
                                    <Fragment>
                                        {listData?.data?.map((post, index) => {
                                            return (
                                                <Tables.Row key={index}>
                                                    <Tables.Data onClick={() => onClickTr ? onClickTr(post) : null} className={`${onClickTr && 'cursor-pointer'} text-right align-top`}>{pageValue.offset + index + 1}</Tables.Data>
                                                    <Tables.Data onClick={() => onClickTr ? onClickTr(post) : null} className={`${onClickTr && 'cursor-pointer'} align-top`}>{post.nama}</Tables.Data>
                                                    <Tables.Data onClick={() => onClickTr ? onClickTr(post) : null} className={`${onClickTr && 'cursor-pointer'} align-top`}>{post.warna}</Tables.Data>
                                                    <Tables.Data className='align-top'>
                                                        <div className='flex flex-row gap-1'>
                                                            <Tooltip
                                                                tooltip={'Edit'}
                                                            >
                                                                <button onClick={() => handleEdit(post)} className='p-1 bg-orange-500 text-white rounded'><Icon icon='line-md:edit' /></button>
                                                            </Tooltip>
                                                            <Tooltip
                                                                tooltip={'Hapus'}
                                                            >
                                                                <button onClick={() => handleDelete(post)} className='p-1 bg-red-500 text-white rounded'><Icon icon='fluent-mdl2:delete'  /></button>
                                                            </Tooltip>
                                                        </div>
                                                    </Tables.Data>
                                                </Tables.Row>
                                            )
                                        })}
                                    </Fragment>
                                }
                            </Tables.Body>
                        </Tables>
                        <div className='mt-5'>
                            <PagePagination
                                data={pageValue.obj}
                                handlePagination={handlePaginate}
                                page={pageValue.page}
                                lastPage={pageValue.lastPage}
                            />
                        </div>
                    </Fragment>
                }
            </Card>
        </Fragment>
    )
}
export default KategoriPanitiaPage