import { ThemeContext } from "../../context/ThemeContext";
import { Transition } from "@headlessui/react";
import { useContext, useEffect, useRef, useState } from "react";



/**
 *
 * @param {{
* show: boolean;
* setShow: React.Dispatch<React.SetStateAction<boolean>>;
* width: "xs" | "sm" | "md" | "lg" | "xl" | "full";
* height: "auto" | "full";
* btnClose: boolean;
* withHeader : boolean;
* title : string;
* persistent: boolean;
* isLoading: boolean;
* }} props
*
*
*/

const Modal =  ({
	show,
	setShow,
	children,
	width = "auto",
	height = "auto",
	withHeader=  false,
	title,
	// btnClose = true,
	persistent,
	isLoading = false
}) => {
    const {  themeSkin } = useContext(ThemeContext);
	const [animateWiggle, setAnimateWiggle] = useState(false);

	const contentRef = useRef();

	const onBackDropClick = () => {
		if (!persistent) {
			setShow(false);
		} else {			
			setAnimateWiggle(true);
		}
	};

    useEffect(() => {
		if (animateWiggle) {
			const content = contentRef.current;
			content.classList.add("animate-wiggle");
			setTimeout(() => {
				content.classList.remove("animate-wiggle");
				setAnimateWiggle(false);
			}, 200);

			return () => {
				content.classList.remove("animate-wiggle");
				setAnimateWiggle(false);
			};
		}
	}, [animateWiggle]);

    const modalWidth =
		{
			xs: "320px",
			sm: "480px",
			md: "640px",
			lg: "800px",
			xl: "960px",
			full: "100%",
		}[width] || width;

    const modalHeight =
		{
			full: "100%",
		}[height] || height;

    return (        
        <Transition
                show={show}
                className="fixed inset-0 z-[50] p-5 flex flex-col items-center justify-center overflow-hidden"
        >			
			<Transition.Child
				as="div"
				enterFrom="opacity-0"
				enterTo="opacity-100"
				leaveFrom="opacity-100"
				leaveTo="opacity-0"
				className="w-full h-full absolute  bg-black/50 transition-opacity duration-300"
				onClick={onBackDropClick}
			/>			
			<Transition.Child
				as="div"
				ref={contentRef}
				enterFrom="opacity-0 scale-95"
				enterTo="opacity-100 scale-100"
				leaveFrom="opacity-100 scale-100"
				leaveTo="opacity-0 scale-95"
				className={`transition-[opacity,transform] duration-300 rounded relative ${
					themeSkin === "default" ? "shadow-lg" : themeSkin
				}`}
				style={{
					maxWidth: modalWidth,
					width: width === "auto" ? "auto" : "100%",
					height: modalHeight,
				}}
			>
				{ isLoading &&
					<div 
						className='absolute top-0 left-0 w-full bg-black z-[80] h-[3px] progress-bar rounded-t'
					>
					</div>
				}
				{/* Button Close */}
				<div className="max-h-[90vh] h-full w-full bg-white dark:bg-base-600 rounded overflow-y-auto custom-scroll">
					{ withHeader &&
						<div className='px-[20px] py-[20px] border-b border-b-gray-300 font-bold'>{title}</div>
					}
					{children}
				</div>
			</Transition.Child>			
        </Transition>
    )

}

export default Modal