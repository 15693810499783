import { Fragment, useContext, useEffect, useRef } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "./Header";

import { ThemeContext } from "../../context/ThemeContext";
import SideBarRightHome from "./SideBarRightHome";

const Layout = () => {
  const { setIsScroll } = useContext(ThemeContext);
  const mainRef = useRef();
  const location = useLocation();

  // console.log('kena scroll >>>>', location.pathname)
  // useEffect(() => {
  //     if (location.pathname === '/'){
  //         if (mainRef.current) {
  //             const main = mainRef.current;
  //             main.addEventListener("scroll", () => {
  //                 if (main.scrollTop > 80) {
  //                     setIsScroll(true);
  //                 } else {
  //                     setIsScroll(false);
  //                 }
  //             });
  //             console.log('pathname >>>>', location.pathname)

  //             return () => {
  //                 main.removeEventListener("scroll", () => {});
  //             };
  //         }
  //     }else{
  //         setIsScroll(true);
  //     }
  //     // eslint-disable-next-line
  // },[mainRef, setIsScroll, location])

  useEffect(() => {
    if (mainRef.current) {
      const main = mainRef.current;
      main.addEventListener("scroll", () => {
        if (location.pathname === "/") {
          if (main.scrollTop > 80) {
            setIsScroll(true);
          } else {
            setIsScroll(false);
          }
        } else {
          setIsScroll(true);
        }
      });
      // console.log('pathname >>>>', location.pathname)

      return () => {
        main.removeEventListener("scroll", () => {});
      };
    }
    // eslint-disable-next-line
  }, [mainRef, setIsScroll, location]);

  useEffect(() => {
    if (location.pathname !== "/") {
      setIsScroll(true);
    }
  }, [setIsScroll, location]);

  return (
    <Fragment>
      <SideBarRightHome />
      <div
        className="w-full scroll-smooth h-screen overflow-y-auto custom-scroll font-poppins"
        ref={mainRef}
      >
        {/* <Scrollbar  className="col-span-9 h-full overflow-y-auto custom-scroll"> */}
        <div className="h-full custom-scroll w-full flex flex-col relative">
          <Header />
          <div className="h-full w-full">
            <Outlet />
          </div>
        </div>
        {/* </Scrollbar >                 */}
      </div>
    </Fragment>
  );
};
export default Layout;
