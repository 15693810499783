import { useCallback, useContext, useEffect, useState } from "react";
import { useGlobalFunction } from "../../../../generalFunction";
import config from "../../../../config";
import api from "../../../../api";
import { pageObj } from "../../../../pageObj";
import { UserContext } from "../../../../context/UserContext";
import { ThemeContext } from "../../../../context/ThemeContext";

export function useRegistrasiPerusahaan() {
    const { showLoading, closeLoading, isLoading, setIsLoading, alertValue, setAlertValue, handleCloseAlert,
        modalValue, setModalValue, handleCloseModal, catchFetching, pageValue, setPageValue
    } = useGlobalFunction();
    const myState = useContext(UserContext);
    const { setTitle } = useContext(ThemeContext)
    const [modalState, modalDispatch] = myState.modalState
    const [myLoading] = myState.myLoading
    const [listData, setListData] = useState([]);
    const [resendEmailForm, setResendEmailForm] = useState({
        id: {
            name: 'id',
            type: 'text',
            value: '',
            show: false,
            required: true,
            errorMsg: '',
            showError: false,
            label: 'Email Template',
            readOnly: true,
        },
        email_template: {
            name: 'email_template',
            type: 'select',
            obj: [],
            value: '',
            show: true,
            required: true,
            errorMsg: '',
            showError: false,
            label: 'Email Template',
            readOnly: false,
            disabled: false,
        },
        perusahaan: {
            name: 'perusahaan',
            type: 'text',
            value: '',
            show: true,
            required: true,
            errorMsg: '',
            showError: false,
            label: 'Perusahaan',
            readOnly: true,
            disabled: true,
        },
        to: {
            name: 'to',
            type: 'text',
            value: '',
            show: true,
            required: true,
            errorMsg: '',
            showError: false,
            label: 'Tujuan',
            readOnly: true,
            disabled: true,
        },
        subject: {
            name: 'subject',
            type: 'text',
            value: '',
            show: true,
            required: true,
            errorMsg: '',
            showError: false,
            label: 'Subject'
        },
        body_email: {
            name: 'body_email',
            type: 'editor',
            value: '',
            show: true,
            required: true,
            errorMsg: '',
            showError: false,
            label: 'Body Email'
        },
        reminder_registrasi: {
            name: 'reminder_registrasi',
            type: 'reminder_registrasi',
            value: true,
            show: false,
            required: false,
            errorMsg: '',
            showError: false,
            label: 'Body Email'
        },
    })
    const [dokumen, setDokumen] = useState({
        tipe: '',
        url: ''
    })

    useEffect(() => {
        setTitle('Unfinish Signup')
    }, [setTitle])

    const fetchData = useCallback(async (q = '', offset = 0, limit = config.itemPerPage) => {
        showLoading();
        try {
            let params = {
                offset: offset,
                limit: limit,
                is_registrasi: 'register',
                only_unregister: true,
                order_by: 'date'
            }
            if (q) {
                params['q'] = q;
            }

            const res = await api.get(`${config.endPoint.perusahaan}`, { params }).then(res => res.data);
            if (res) {
                setListData(res.results);
                setPageValue(pageValue => ({
                    ...pageValue,
                    obj: pageObj(res.count, limit, offset),
                    lastPage: Math.ceil(parseInt(res.count) / parseInt(limit))
                }))
            }
            closeLoading();
        } catch (error) {
            closeLoading();
            catchFetching(error, 'admin')
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Unable to fetch data', color: 'danger' }))
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }))
            }, config.timeOutValue)
        }
        // eslint-disable-next-line
    }, [setIsLoading])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    const handlePaginate = (page) => {
        let myOffset = (parseInt(page) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
        setPageValue(pageValue => ({ ...pageValue, page: page, offset: myOffset }))
        fetchData(pageValue.search, myOffset, config.itemPerPage, pageValue.complete);
    }

    const handleChangeSearch = (e) => {
        const { name, value } = e.target;
        setPageValue(pageValue => ({ ...pageValue, [name]: value }))
        if (name === 'complete') {
            fetchData(pageValue.search, 0, config.itemPerPage, value, pageValue.is_lock);
            setPageValue(pageValue => ({ ...pageValue, page: 1, offset: 0 }))
        }
        if (name === 'is_lock') {
            fetchData(pageValue.search, 0, config.itemPerPage, pageValue.complete, value);
            setPageValue(pageValue => ({ ...pageValue, page: 1, offset: 0 }))
        }
    }

    const handleSearch = () => {
        setPageValue(pageValue => ({ ...pageValue, offset: 0, page: 1 }));
        fetchData(pageValue.search, 0, config.itemPerPage, pageValue.complete)
    }

    const handleKeyDownSearch = (e) => {
        if (e.key === "Enter") {
            handleSearch();
        }
    }

    const handleViewDocument = (tipe, params) => {
        let title = ""
        console.log('params >>>>', params)
        if (tipe === 'akta') {
            setDokumen(dokumen => ({
                ...dokumen,
                tipe: 'dokumen',
                url: params?.akta_display,
                alasan: [2, 3].includes(params?.status_akta) ? params?.detail_display?.alasan_akta : ''
            }))
            title = `Akta Perusahaan ${params.nama}`
        }
        setModalValue(modalValue => ({ ...modalValue, show: true, title: title, tipe: 'dokumen' }))
    }

    const handleResendEmail = (post) => {
        setModalValue(modalValue => ({
            ...modalValue,
            show: true, tipe: 'resend_email', title: 'Resend Email', id: post.id
        }))
        fetchEmailTemplate();
        setResendEmailForm(resendEmailForm => ({
            ...resendEmailForm,
            id: { ...resendEmailForm.id, value: post.id },
            to: { ...resendEmailForm.to, value: post.email },
            perusahaan: { ...resendEmailForm.perusahaan, value: post.nama },
            email_template: { ...resendEmailForm.email_template, value: "" },
            body_email: { ...resendEmailForm.body_email, value: "" },
            subject: { ...resendEmailForm.subject, value: "" },
        }))
    }

    const fetchEmailTemplate = useCallback(async () => {
        setIsLoading(true);
        try {
            const res = await api.get(`${config.endPoint.masterEmailTemplate}`).then(res => res.data);
            let tmp = [{ value: '', label: '' }];
            if (res) {
                res.results.map(post => tmp.push({ value: post.id, label: `${post.kode_display} | Subject : ${post.subjek}` }))
            }
            setResendEmailForm(resendEmailForm => ({
                ...resendEmailForm,
                email_template: { ...resendEmailForm.email_template, obj: tmp }
            }))
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            let msg = 'Unable to save Data';
            let { response } = error;

            catchFetching(error, 'admin')
            if (response && response.data && response.data.message) {
                msg = response.data.message
            }
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }));
            }, config.timeOutValue)
        }
        // eslint-disable-next-line
    }, [setIsLoading, setAlertValue])

    const handleChangeResendEmailForm = (e) => {
        const { name, value } = e.target;
        if (name === 'email_template') {
            replaceString(value);
        }
    }

    const replaceString = async (id = 0) => {
        setIsLoading(false);
        try {
            let tmp = {
                'template_id': id,
                'kontak_id': modalValue.id,
                'tipe': 'perusahaan'
            }
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmp));
            const res = await api.post(`${config.endPoint.replaceStringPerusahaan}`, newForm).then(res => res.data);
            if (res) {
                setResendEmailForm(resendEmailForm => ({
                    ...resendEmailForm,
                    subject: { ...resendEmailForm.subject, value: res.subject },
                    body_email: { ...resendEmailForm.body_email, value: res.results, type: 'editor' }
                }))
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            let msg = 'Unable to save Data';
            let { response } = error;

            catchFetching(error, 'admin')
            if (response && response.data && response.data.message) {
                msg = response.data.message
            }
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }));
            }, config.timeOutValue)
        }
    }

    const handleSubmitResendEmail = async () => {
        setIsLoading(true);
        try {
            let countError = 0;
            let form = { ...resendEmailForm };
            let tmpForm = {}
            Object.entries(form).map(([index, post]) => {
                if (post.required && (post.value === '' || post.value === null)) {
                    post.showError = true;
                    countError++;
                } else {
                    tmpForm[index] = post.value;
                }
                return true;
            });

            if (countError > 0) {
                setResendEmailForm(resendEmailForm);
                setIsLoading(false);
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Lengkapi Data', color: 'danger' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }))
                }, config.timeOutValue);
                return;
            }

            tmpForm['tipe'] = 'perusahaan'
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmpForm));

            let res = await api.post(`${config.endPoint.resendEmail}`, newForm).then(res => res.data);
            if (res) {
                handleCloseModal();
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Email terkirim', color: 'success' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue)
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            let msg = 'Unable to save Data';
            let { response } = error;

            catchFetching(error, 'admin')
            if (response && response.data && response.data.message) {
                msg = response.data.message
            }
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }));
            }, config.timeOutValue)
        }
    }

    const handleShowHistoryEmail = (post) => {
        modalDispatch({
            type: 'EMAIL_LOG',
            onClose: closeModalHandler,
            title: 'Email Log',
            perusahaanId: post.id
        })
    }

    const closeModalHandler = () => {
        modalDispatch({ type: 'CLOSE' })
    }


    return {
        alertValue, setAlertValue, handleCloseAlert, modalValue, setModalValue, handleCloseModal,
        showLoading, closeLoading, isLoading, setIsLoading, pageValue, listData,
        handlePaginate, handleChangeSearch, handleKeyDownSearch, handleSearch, myLoading,
        handleViewDocument, dokumen, handleResendEmail,
        handleChangeResendEmailForm, handleSubmitResendEmail, modalState, modalDispatch, closeModalHandler,
        handleShowHistoryEmail, resendEmailForm, setResendEmailForm,
    }
}