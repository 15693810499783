import { Icon } from "@iconify/react/dist/iconify.js";
import AlertComp from "../../../../Components/Atoms/AlertComp";
import PagePagination from "../../../../Components/Atoms/PagePagination";
import Card from "../../../../Components/Layout/Card";
import Tables from "../../../../Components/molecules/Tables";
import { useDuplicatePeserta } from "./duplicatePesertaFunction";
import { Fragment } from 'react';
import Tooltip from "../../../../Components/Atoms/Tooltip";
import Modal from "../../../../Components/Atoms/Modal";
import FormTextSingleComp from "../../../../Components/forms/FormTextSingleComp";
import Button from "../../../../Components/Atoms/Button";



const ListKontak = ({kontakSelected, defaultImage, willAdded, handleDeleteKontakGroup}) => {
    return(
        <div className='flex flex-row bg-white'>
            <div className="relative group">
                <div className='group-hover:bg-black/50'>
                    { willAdded &&
                        <div className='text-center text-red-500 font-bold pt-3 group-hover:text-white'>Akan ditambahkan</div>
                    }
                    <div className='flex flex-row'>                                            
                        <div className='px-5 py-5'>
                            <img 
                                src={kontakSelected && kontakSelected.foto_url}  
                                onError={(e) => {e.target.src=defaultImage}} 
                                alt='foto' 
                                className='h-28 w-auto object-contain'
                            />
                        </div>
                        <div className='py-5 pl-3'>
                            <table className='text-sm table-card-duplicate-peserta'>                            
                                <tr>
                                    <td>Nama</td>
                                    <td>:</td>
                                    <td>{kontakSelected && kontakSelected.nama}</td>
                                </tr>
                                <tr>
                                    <td>Perusahaan</td>
                                    <td>:</td>
                                    <td>{kontakSelected && kontakSelected.perusahaan_display}</td>
                                </tr>
                                <tr>
                                    <td>Jabatan</td>
                                    <td>:</td>
                                    <td>{kontakSelected && kontakSelected.jabatan_display}</td>
                                </tr>
                                <tr>
                                    <td>Email</td>
                                    <td>:</td>
                                    <td>{kontakSelected && kontakSelected.email}</td>
                                </tr>
                                <tr>
                                    <td>HP</td>
                                    <td>:</td>
                                    <td>{kontakSelected && kontakSelected.hp}</td>
                                </tr>
                                <tr>
                                    <td>HP</td>
                                    <td>:</td>
                                    <td>{kontakSelected && kontakSelected.id}</td>
                                </tr>
                            </table>
                        </div>
                        { !willAdded &&
                            <div 
                                className='group-hover:absolute right-0 top-0 group-hover:h-full h-0 group-hover:w-[80px] w-0 rounded-r-md cursor-pointer' 
                                style={{backgroundColor: 'rgba(209, 17, 49, 0.8)'}}
                                onClick={handleDeleteKontakGroup}
                            >
                                <div className='hidden group-hover:flex flex-row text-white justify-center items-center group-hover:h-full h-0'>
                                <Icon icon="ph:trash-bold" />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}


const DuplicatePesertaPage = () => {
    const { alertValue, handleCloseAlert, handleChangeSearch, handleSearch, listData, pageValue, handlePaginate,
        handleDelete, handleGrouping, modalValue, setModalValue,
        formGroup, setFormGroup, handleCreateGroup, handleClickGroup, listGroup, groupSelected, handleDeleteGroup,
        kontakSelected, listGroupDetail, handleDeleteKontakGroup, handleSubmitDelete, handleSubmitGroup, handleCloseModal
    } = useDuplicatePeserta();
    const defaultUserImage = require('../../../../assets/images/default_user.png')
    return(
        <Fragment>
            <AlertComp
                isOpen={alertValue.show}
                onClose={handleCloseAlert}
                text={alertValue.text}
                subText={alertValue.subText}
                color={alertValue.color}
                icon={alertValue.icon}
            />
            <Modal
                show={modalValue.show}
                setShow={() => {
                    setModalValue(modalValue => ({...modalValue, show: false}))
                }}
                width="xl"
                persistent={false}
                btnClose={true}                                
                title={modalValue.title}
                withHeader={true}
            >
                {modalValue.tipe === 'revoke' &&
                    <div className='p-[20px]'>
                        {modalValue.text}
                    </div>
                }
                { modalValue.tipe === 'grouping' &&
                    <div className='grid p-[20px]'>
                        <div className='grid grid-cols-2 gap-4'>
                            <div>
                                <FormTextSingleComp
                                    {...formGroup.nama}
                                    formData={formGroup}
                                    setFormData={setFormGroup}
                                />
                                <div className="text-right">
                                    <Button color="success" className='rounded-md text-sm' onClick={handleCreateGroup}>Tambah Group</Button>
                                </div>
                            </div>
                        </div>
                        <div className='grid grid-cols-2 gap-4 mt-5'>
                            <div>
                                <div className='font-bold mb-2'>Daftar Group</div>
                                <div className='border border-gray-100 rounded-md px-5 py-5'>                    
                                    <ul>
                                        { listGroup.map((post, index) => {
                                            return(
                                                <li className='border border-gray-200 rounded mb-2' key={index}>
                                                    <div className='grid grid-cols-12'>
                                                        <div 
                                                            className={`col-span-11 py-2 cursor-pointer ${groupSelected.id === post.id && 'bg-blue-500 text-white'} pl-5`}
                                                            onClick={() => handleClickGroup(post)}
                                                        >
                                                                {post.nama}
                                                        </div>
                                                        <div 
                                                            className='bg-red-500 col-span-1 flex flex-row justify-center items-center text-white cursor-pointer'
                                                            onClick={() => handleDeleteGroup(post)}
                                                        >
                                                            <Icon icon="ph:trash-bold" />
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <div>
                                <div className='font-bold mb-2'>Perusahaan</div>
                                <div className='border border-gray-100 rounded-md px-5 py-5'>
                                    <ul>
                                        { kontakSelected && !listGroupDetail.map(x => x.id).includes(kontakSelected.id) &&
                                            <li className='border border-gray-200 rounded mb-3'>
                                                <ListKontak
                                                    kontakSelected={kontakSelected}
                                                    defaultImage={defaultUserImage}
                                                    willAdded={true}
                                                />
                                            </li>
                                        }
                                        { groupSelected.id && listGroupDetail.map((post, index) => {
                                            return(
                                                <li key={index} className='px-5 py-2 border border-gray-200 rounded mb-3'>
                                                    <ListKontak
                                                        kontakSelected={post}
                                                        defaultImage={defaultUserImage}
                                                        willAdded={false}
                                                        handleDeleteKontakGroup={() => handleDeleteKontakGroup(post)}
                                                    />
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                
                    <div className='border-t border-t-gray-200 p-[20px]'>
                        <div className="flex flex-row justify-end gap-2">
                            <Button className={`${modalValue.tipe === 'revoke' ? 'bg-red-500' : 'bg-green-600'} text-white`} onClick={() => modalValue.tipe === 'revoke' ? handleSubmitDelete() :  handleSubmitGroup()}>{ modalValue.tipe === 'revoke' ? "Revoke" : "Submit"}</Button>
                            <Button className='bg-gray-400 text-white' onClick={handleCloseModal}>Cancel</Button>
                        </div>
                    </div>
                
            </Modal>
            <Card
                isCreate={false}
                isSearch={true}
                className={'w-full'}    
                onChangeSearch={handleChangeSearch}
                handleSearch={handleSearch}            
            >
                <Fragment>
                    <Tables className='border-separate rounded-sm'>
                        <Tables.Head className='bg-[#efeeee]'>
                            <tr>
                                <Tables.Header className='font-bold text-[13px] py-[11px] text-right'>#</Tables.Header>
                                <Tables.Header className='font-bold text-[13px] py-[11px]'>Perusahaan</Tables.Header>
                                <Tables.Header className='font-bold text-[13px] py-[11px]'>Provinsi</Tables.Header>
                                <Tables.Header className='font-bold text-[13px] py-[11px]'>Peserta</Tables.Header>
                                <Tables.Header className='font-bold text-[13px] py-[11px]'>Foto</Tables.Header>
                                <Tables.Header className='font-bold text-[13px] py-[11px]'>Jabatan</Tables.Header>
                                <Tables.Header className='font-bold text-[13px] py-[11px]'>Hotel</Tables.Header>
                                <Tables.Header className='font-bold text-[13px] py-[11px]'>Status</Tables.Header>
                                <Tables.Header className='font-bold text-[13px] py-[11px]'>Status Hotel</Tables.Header>
                                <Tables.Header className='font-bold text-[13px] py-[11px]'>Action</Tables.Header>
                            </tr>
                        </Tables.Head>
                        <Tables.Body>
                            { listData.length === 0 ?
                                <Tables.Row>
                                    <Tables.Data colspan={10} className='text-center'>Belum ada data</Tables.Data>
                                </Tables.Row>
                                :                                    
                                <Fragment>
                                    {listData.map((post, index) => {
                                        return (
                                            <Tables.Row key={index}>
                                                <Tables.Data className='text-right'>{pageValue.offset + index + 1}</Tables.Data>
                                                <Tables.Data>
                                                    <div className='flex flex-col items-start'>
                                                        <div>{post.perusahaan_display}</div>
                                                        { post.kontak_group_display &&
                                                            <div
                                                                className='bg-blue-100 text-blue-600 px-2 italic py-1 text-sm rounded flex flex-row justify-start items-center'
                                                            >Group {post.kontak_group_display}</div>
                                                        }
                                                    </div>
                                                </Tables.Data>
                                                <Tables.Data>{post.provinsi}</Tables.Data>
                                                <Tables.Data>{post.nama}</Tables.Data>
                                                <Tables.Data>
                                                    <img 
                                                        src={post.foto_url} 
                                                        alt="foto" 
                                                        onError={(e) => {e.target.src=defaultUserImage}}
                                                        className='h-14 w-auto object-contain'
                                                    />
                                                </Tables.Data>
                                                <Tables.Data>{post.jabatan_display}</Tables.Data>
                                                <Tables.Data>{post.hotel_display}</Tables.Data>
                                                <Tables.Data>
                                                    { post.is_complete ?
                                                        <div className='text-center bg-green-200 text-green-700 px-2 py-1 text-[11px] rounded-full'>Complete</div>
                                                        :
                                                        <div className='text-center bg-gray-200 text-gray-700 px-2 py-1 text-[11px] rounded-full'>Waiting Approval</div>

                                                    }
                                                </Tables.Data>
                                                <Tables.Data>
                                                    { post.status_revoke ?
                                                        <div className='text-center bg-gray-200 text-gray-700 px-2 py-1 text-[11px] rounded-full'>Revoke</div>
                                                        :
                                                        <div className='text-center bg-green-200 text-green-700 px-2 py-1 text-[11px] rounded-full'>Assign</div>

                                                    }
                                                </Tables.Data>
                                                <Tables.Data>
                                                    <div className='flex flex-row gap-1'>
                                                        { post.hotel_display &&
                                                            <Tooltip tooltip={"Revoke"}>
                                                                <button className="flex flex-row bg-red-200 text-red-700 items-center p-2 gap-1 rounded" onClick={() => handleDelete(post)}>
                                                                    <Icon icon="ph:trash-bold" />
                                                                </button>
                                                            </Tooltip>
                                                        }
                                                        <Tooltip tooltip={"Group"}>
                                                            <button color="success" className='flex flex-row bg-green-200 text-green-700 p-2 gap-1 rounded' onClick={() => handleGrouping(post)}>
                                                                <Icon icon={"icon-park-outline:merge"} />
                                                            </button>
                                                        </Tooltip>
                                                    </div>

                                                </Tables.Data>
                                            </Tables.Row>
                                        )
                                    })}
                                </Fragment>
                            }
                        </Tables.Body> 
                    </Tables>
                    <div className='mt-5 flex flex-row justify-start w-full'>
                        <PagePagination
                            data={pageValue.obj}
                            handlePagination={handlePaginate}
                            page={pageValue.page}
                            lastPage={pageValue.lastPage}
                        />
                    </div>
                </Fragment>
            </Card>
        </Fragment>
    )
}
export default DuplicatePesertaPage;