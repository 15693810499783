import { Icon } from "@iconify/react/dist/iconify.js";
import { useDownloadMemberPage } from "./downloadMemberFunction";

const DownloadMember = (props) => {
  const { listData, handleDownload } = useDownloadMemberPage()

  const getIcon = (icon) => {
    console.log(icon)
    if (['pdf'].includes(icon)) {
      return "vscode-icons:file-type-pdf2"
    } else if (['docx', 'doc'].includes(icon)) {
      return "vscode-icons:file-type-word"
    } else if (['xlsx', 'xls'].includes(icon)) {
      return "vscode-icons:file-type-excel2"
    } else if (['png', 'jpg', 'jpeg', 'JPEG', 'JPG', 'PNG'].includes(icon)) {
      return "fa6-regular:image"
    } else {
      return "el:download-alt"
    }
  }
  return (
    <div>

      <div className="flex flex-row mt-[100px] gap-5 flex-wrap justify-center md:justify-start">
        {listData.map((post, index) => {
          return (
            <button
              onClick={() => handleDownload(post)}
              download={post.nama}
              className="flex flex-col items-center border border-gray-500 shadow-lg rounded-lg bg-slate-100 justify-center w-[300px]"
              key={index}
            >
              <div className="h-[200px] flex flex-row items-center justify-center">
                <Icon icon={getIcon(post?.meta?.format)} fontSize={"100"} />
              </div>
              <div className="px-[26px] py-[20px] justify-center h-[100px]  w-full bg-white rounded-b-lg flex flex-row items-center gap-4 text-blue-600">
                <span><Icon icon="icon-park-outline:download-two" fontSize={"24"} /></span>{" "} {post.nama}
              </div>
            </button>
          );
        })}
      </div>
    </div>
  );
};
export default DownloadMember;
