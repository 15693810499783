import { Icon } from "@iconify/react/dist/iconify.js";
// import FormTextSingleComp from "../../Components/forms/FormTextSingleComp";
import { useLogin } from "./loginFunction";

const LoginPage = () => {
  const {
    formData,
    handleSubmit,
    alertValue,
    handleKeyDown,
    handleShowPassword,
    handleChange,
    handleBlur,
  } = useLogin();
  return (
    <div className="h-screen w-screen">
      <div className="flex flex-col md:grid md:grid-cols-12 h-full w-full  font-admin">
        <div className="md:col-span-5 bg-gradient-to-b from-blue-900 to-blue-500 w-full h-[300px] sm:h-[900px] lg:h-screen flex flex-col items-center justify-start">
          <img
            src={require("../../assets/images/logoapjii.png")}
            alt="logo"
            className="w-40 mt-10 mb-40 "
          />
          <img
            src={require("../../assets/images/logo_munas_putih.png")}
            alt="logo"
            className="hidden md:block w-[20rem]"
          />
          <div className="text-white font-bold text-lg xl:text-3xl mt-5 md:mt-[60px]">
            Registrasi Munas 2024
          </div>
        </div>
        <div className="md:col-span-7 flex flex-col justify-center items-center text-[18px]">
          <div className="w-full relative h-full md:h-full">
            <div className="hidden lg:block absolute h-screen -left-16 z-10 bg-gradient-to-b from-blue-900 to-blue-500">
              <img
                src={require("../../assets/images/bgLoginVertical.png")}
                alt="bg"
                className="h-screen"
              />
            </div>
            <div className="block md:hidden absolute w-full -left-0 -top-40 z-10">
              <img
                src={require("../../assets/images/bgLoginHorizontal.png")}
                alt="bg"
                className="w-full"
              />
            </div>
            <div className="relative px-10  lg:pr-32 xl:pr-32 2xl:pl-[400px] lg:pl-72  flex flex-col justify-center h-[calc(100vh-300px)] lg:h-screen">
              <div className="text-gray-700 relative">
                <div className="text-[32px] font-bold">Welcome</div>
                <div className="text-[16px] mb-10">Login to your account</div>
                {alertValue.show && (
                  <div className="absolute w-full top-[65px]">
                    <div className="w-full flex flex-row justify-center">
                      <div className="px-[25px] py-[5px] text-red-600 flex flex-row justify-center text-[14px] rounded">
                        {alertValue.text}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="relative w-full border-2 rounded-lg border-sky-600">
                <input
                  className="border  rounded-lg pl-[40px] w-full py-3 text-left leading-tight 
                                        focus:outline-1 
                                        focus:outline-none bg-gray-50
                                        block focus:border-none"
                  onKeyDown={handleKeyDown}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name={formData.username.name}
                  value={formData.username.value}
                />
                <div className="absolute -top-[10px] left-[18px] text-[12px] bg-white px-1 text-sky-600">
                  Username
                </div>
                <div className="absolute top-[13px] left-[10px]">
                  <Icon
                    icon="solar:user-outline"
                    className="text-gray-400 text-[20px]"
                  />
                </div>
              </div>
              <div className="relative w-full border-2 rounded-lg border-sky-600 mt-7">
                <input
                  className="border  rounded-lg pl-[40px] w-full py-3 text-left leading-tight                                         
                                        focus:outline-none bg-gray-50
                                        focus:border-none"
                  onKeyDown={handleKeyDown}
                  type={formData.password.type}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name={formData.password.name}
                  value={formData.password.value}
                />
                <div className="absolute -top-[10px] left-[18px] text-[12px] bg-white px-1 text-sky-600">
                  Password
                </div>
                <div className="absolute top-[13px] left-[10px]">
                  <Icon icon="ph:key" className="text-gray-400 text-[20px]" />
                </div>
                <button
                  className="absolute top-[13px] right-[10px]"
                  onClick={() => handleShowPassword(formData.password.name)}
                >
                  <Icon
                    icon={
                      formData.password.showPassword
                        ? "ph:eye-closed-bold"
                        : "ph:eye-bold"
                    }
                    className="text-gray-400 text-[20px]"
                  />
                </button>
              </div>
              <div className="w-full flex flex-row justify-start mt-5">
                <button
                  className="bg-gradient-to-b from-blue-700 to-blue-500 md:w-[130px] pl-[15px] pr-[20px] py-[10px] text-white rounded-md  w-full text-[18px] font-semibold flex flex-row gap-4 items-center justify-center md:justify-start"
                  onClick={handleSubmit}
                >
                  <Icon icon="ic:round-login" className="text-white" />
                  Login
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LoginPage;
