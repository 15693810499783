import { Fragment, useEffect, useState } from "react"


const ShowDokumenPage = () => {
    const tmpData = localStorage.getItem('documentInfo') || null;
    const [data, setData] = useState(null);
    const [myUrl, setMyUrl] = useState(null);

    useEffect(() => {
        if (tmpData){
            let res = JSON.parse(tmpData)
            setMyUrl(res.myUrl)
            setData(res);
        }
    },[tmpData])

    return(
        <Fragment>
            <div className='h-fit w-full bg-white px-5 py-5' style={{height: '85vh'}}>
                { data &&
                    <div className="grid grid-cols-12 mb-5">
                        <div className="col-span-2">
                            <div>Perusahaan</div>
                            <div className='font-bold'>{ data.perusahaan_display}</div>
                        </div>
                    </div>
                }
                { myUrl &&
                    <iframe
                        src={`${myUrl}`}
                        width="100%"
                        height="100vh"
                        title="akta"
                        className="h-fit"
                        style={{height: '75vh'}}
                    >
                    </iframe>
                }
            </div>
        </Fragment>
    )
}
export default ShowDokumenPage;