import AlertComp from "../../../Components/Atoms/AlertComp";
import PagePagination from "../../../Components/Atoms/PagePagination";
import { useMemberCalon } from "./memberCalonFunction";


const MemberCalonPage = (props) => {
    const { fetchTipe } = props


    const { listData, alertValue, handleCloseAlert, handlePaginate,
        pageValue
    } = useMemberCalon({ fetchTipe: fetchTipe });
    return (
        <div className="flex flex-col">
            <AlertComp
                isOpen={alertValue.show}
                onClose={handleCloseAlert}
                text={alertValue.text}
                subText={alertValue.subText}
                color={alertValue.color}
            />
            <div className="p-6">
                <div className='text-[18px] font-bold mb-5 text-center'>{fetchTipe === 'dewas' ? 'Daftar Calon Dewan Pengawas' : 'Daftar Calon Ketua Umum'}</div>
                {listData?.data?.map((item, index) => {
                    return (
                        <div className="flex flex-col md:flex-row p-5 gap-7 justify-start items-start h-full border-b border-b-green-500 pb-5 rounded-b-lg shadow-lg" key={index}>
                            {/* Styling No Urut */}
                            <div className="flex flex-col items-center justify-center bg-green-100 text-green-800 font-bold p-3 rounded-lg shadow-md">
                                <div className="text-lg uppercase tracking-wide text-center">No Urut</div>
                                <div className="text-3xl mt-2">{item.no_urut}</div>
                            </div>

                            {/* Styling Foto */}
                            <div className='md:w-[150px] w-full h-full flex flex-row justify-center rounded-tl-lg'>
                                <img src={item.foto} alt="foto" className='h-auto md:w-[150px] object-contain rounded-md shadow-md' />
                            </div>

                            {/* Informasi Utama */}
                            <div className='h-full w-full'>
                                <div className='text-[18px] font-bold text-green-700'>{item.nama}</div>
                                <div className='font-semibold text-gray-700'>{item.perusahaan}</div>

                                {/* Visi */}
                                <div className='mt-3'>
                                    <div className='text-gray-900 font-semibold'>Visi</div>
                                    <div className='text-gray-600 text-[13px] leading-relaxed'>
                                        <div className="whitespace-pre-line">
                                            {item.visi}
                                        </div>
                                    </div>
                                </div>

                                {/* Misi */}
                                <div className='mt-3'>
                                    <div className='text-gray-900 font-semibold'>Misi</div>
                                    <div className='text-gray-600 text-[13px] leading-relaxed'>
                                        <div className="whitespace-pre-line">
                                            {item.misi}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
                {listData?.data?.length === 0 &&
                    <div className='italic mt-5 font-light text-gray-400'>Belum Ada Daftar {fetchTipe === 'dewas' ? 'Calon Dewan Pengawas' : "Calon Ketua Umum"}</div>
                }
                <div className='mt-5'>
                    <PagePagination
                        data={pageValue.obj}
                        handlePagination={handlePaginate}
                        page={pageValue.page}
                        lastPage={pageValue.lastPage}
                    />
                </div>
            </div>
        </div>
    )
}
export default MemberCalonPage;