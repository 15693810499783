import React from 'react';
const JadwalHtml = () => {
    return (
        <div className='w-[700px] font-calibri'>
            <div className='text-center font-bold'>
                <div>SUSUNAN ACARA*</div>
                <div>MUSYAWARAH NASIONAL XII APJII TAHUN 2024</div>
                <div>HOTEL VASA, SURABAYA, JAWA TIMUR</div>
            </div>

            <br />
            <div className=''>
                <table
                    cellSpacing={0}
                    cellPadding={0}
                    style={{
                        width: "100%",
                        border: "0.75pt solid #000000",
                        borderCollapse: "collapse"
                    }}
                >
                    <tbody>
                        <tr style={{ height: "41.25pt" }}>
                            <td
                                colSpan={2}
                                style={{
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top",
                                    backgroundColor: "#c9daf8"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "center",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <strong>
                                        <span style={{ fontFamily: "Calibri" }}>
                                            SABTU, 21 SEPTEMBER 2024
                                        </span>
                                    </strong>
                                </p>
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "center",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <strong>
                                        <span style={{ fontFamily: "Calibri" }}>
                                            PERKENALAN CALON ANGGOTA DEWAN PENGAWAS DAN DEBAT CALON KETUA
                                            UMUM
                                        </span>
                                    </strong>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "center",
                                        lineHeight: "150%",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <strong>
                                        <span style={{ fontFamily: "Calibri" }}>Waktu&nbsp;</span>
                                    </strong>
                                </p>
                            </td>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "center",
                                        lineHeight: "150%",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <strong>
                                        <span style={{ fontFamily: "Calibri" }}>Kegiatan&nbsp;</span>
                                    </strong>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "center",
                                        lineHeight: "150%",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>16:30 – 17:00 WIB</span>
                                </p>
                            </td>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "justify",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>
                                        Perkenalan Calon Anggota Dewan Pengawas&nbsp;
                                    </span>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "center",
                                        lineHeight: "150%",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>17:00 – 18:00 WIB</span>
                                </p>
                            </td>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "justify",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>
                                        Debat Calon Ketua Umum
                                    </span>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p
                    style={{
                        marginTop: "0pt",
                        marginBottom: "0pt",
                        widows: 2,
                        orphans: 2,
                        fontSize: "12pt"
                    }}
                >
                    <strong>
                        <span style={{ fontFamily: "Calibri" }}>&nbsp;</span>
                    </strong>
                </p>
                <p
                    style={{
                        marginTop: "0pt",
                        marginBottom: "0pt",
                        textAlign: "center",
                        widows: 2,
                        orphans: 2,
                        fontSize: "12pt"
                    }}
                >
                    <strong>
                        <span style={{ fontFamily: "Calibri" }}>&nbsp;</span>
                    </strong>
                </p>
                <table
                    cellSpacing={0}
                    cellPadding={0}
                    style={{
                        width: "100%",
                        border: "0.75pt solid #000000",
                        borderCollapse: "collapse"
                    }}
                >
                    <tbody>
                        <tr style={{ height: "41.25pt" }}>
                            <td
                                colSpan={2}
                                style={{
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top",
                                    backgroundColor: "#c9daf8"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "center",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <strong>
                                        <span style={{ fontFamily: "Calibri" }}>
                                            MINGGU, 22 SEPTEMBER 2024
                                        </span>
                                    </strong>
                                </p>
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "center",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <strong>
                                        <span style={{ fontFamily: "Calibri" }}>
                                            KEDATANGAN PESERTA DAN DAFTAR ULANG
                                        </span>
                                    </strong>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "center",
                                        lineHeight: "150%",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <strong>
                                        <span style={{ fontFamily: "Calibri" }}>Waktu&nbsp;</span>
                                    </strong>
                                </p>
                            </td>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "center",
                                        lineHeight: "150%",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <strong>
                                        <span style={{ fontFamily: "Calibri" }}>Kegiatan&nbsp;</span>
                                    </strong>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        lineHeight: "150%",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>08:30 – 21:00 WIB</span>
                                </p>
                            </td>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "justify",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>
                                        Kedatangan Peserta dan Pendaftaran Ulang Peserta MUNAS XII APJII
                                        2024
                                    </span>
                                </p>
                                <ol className='list-decimal' style={{ margin: "0pt", paddingLeft: "0pt" }}>
                                    <li
                                        style={{
                                            marginLeft: "18.61pt",
                                            textAlign: "justify",
                                            paddingLeft: "3.89pt",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        <em>Check in&nbsp;</em>Hotel (15:00 WIB)
                                    </li>
                                    <li
                                        style={{
                                            marginLeft: "18.61pt",
                                            textAlign: "justify",
                                            paddingLeft: "3.89pt",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        <em>Coffee Break&nbsp;</em>(14:00 - 17:00 WIB)
                                    </li>
                                </ol>
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginLeft: "36pt",
                                        marginBottom: "0pt",
                                        textAlign: "justify",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>&nbsp;</span>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p
                    style={{
                        marginTop: "0pt",
                        marginBottom: "0pt",
                        widows: 2,
                        orphans: 2,
                        fontSize: "12pt"
                    }}
                >
                    <strong>
                        <span style={{ fontFamily: "Calibri" }}>&nbsp;</span>
                    </strong>
                </p>
                <p style={{ marginTop: "0pt", marginBottom: "0pt", widows: 2, orphans: 2 }}>
                    <br style={{ pageBreakBefore: "always", clear: "both" }} />
                </p>
                <p
                    style={{
                        marginTop: "0pt",
                        marginBottom: "0pt",
                        widows: 2,
                        orphans: 2,
                        fontSize: "12pt"
                    }}
                >
                    <strong>
                        <span style={{ fontFamily: "Calibri" }}>&nbsp;</span>
                    </strong>
                </p>
                <table
                    cellSpacing={0}
                    cellPadding={0}
                    style={{
                        width: "100%",
                        border: "0.75pt solid #000000",
                        borderCollapse: "collapse"
                    }}
                >
                    <tbody>
                        <tr style={{ height: "37.9pt" }}>
                            <td
                                colSpan={2}
                                style={{
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top",
                                    backgroundColor: "#c9daf8"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "center",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <strong>
                                        <span style={{ fontFamily: "Calibri" }}>
                                            SENIN, 23 SEPTEMBER 2024
                                        </span>
                                    </strong>
                                </p>
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "center",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <strong>
                                        <span style={{ fontFamily: "Calibri" }}>
                                            PERSIDANGAN HARI PERTAMA&nbsp;
                                        </span>
                                    </strong>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "center",
                                        lineHeight: "150%",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <strong>
                                        <span style={{ fontFamily: "Calibri" }}>Waktu&nbsp;</span>
                                    </strong>
                                </p>
                            </td>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "center",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <strong>
                                        <span style={{ fontFamily: "Calibri" }}>Kegiatan&nbsp;</span>
                                    </strong>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        lineHeight: "150%",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>08:30 – 09:00 WIB</span>
                                </p>
                            </td>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "justify",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>
                                        Pendaftaran Ulang Peserta MUNAS XII APJII 2024.
                                    </span>
                                </p>
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "justify",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <em>
                                        <span style={{ fontFamily: "Calibri" }}>
                                            (Bagi Peserta yang belum melakukan registrasi)
                                        </span>
                                    </em>
                                </p>
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "justify",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>&nbsp;</span>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        lineHeight: "150%",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>09:00 – 10:00 WIB</span>
                                </p>
                            </td>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "justify",
                                        lineHeight: "150%",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <strong>
                                        <span style={{ fontFamily: "Calibri" }}>
                                            PEMBUKAAN MUNAS XII APJII 2024
                                        </span>
                                    </strong>
                                </p>
                                <ol className='list-decimal' type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
                                    <li
                                        style={{
                                            marginLeft: "15.08pt",
                                            textAlign: "justify",
                                            paddingLeft: "4pt",
                                            fontFamily: '"Noto Sans Symbols"',
                                            fontSize: "12pt"
                                        }}
                                    >
                                        <span style={{ fontFamily: "Calibri" }}>
                                            Menyanyikan Lagu Kebangsaan Indonesia Raya dan Mars APJII.
                                        </span>
                                    </li>
                                    <li
                                        style={{
                                            marginLeft: "15.08pt",
                                            textAlign: "justify",
                                            paddingLeft: "4pt",
                                            fontFamily: '"Noto Sans Symbols"',
                                            fontSize: "12pt"
                                        }}
                                    >
                                        <span style={{ fontFamily: "Calibri" }}>Pembacaan Doa.</span>
                                    </li>
                                    <li
                                        style={{
                                            marginLeft: "15.08pt",
                                            textAlign: "justify",
                                            paddingLeft: "4pt",
                                            fontFamily: '"Noto Sans Symbols"',
                                            fontSize: "12pt"
                                        }}
                                    >
                                        <span style={{ fontFamily: "Calibri" }}>
                                            Pertunjukan Kesenian.
                                        </span>
                                    </li>
                                    <li
                                        style={{
                                            marginLeft: "15.08pt",
                                            textAlign: "justify",
                                            paddingLeft: "4pt",
                                            fontFamily: '"Noto Sans Symbols"',
                                            fontSize: "12pt"
                                        }}
                                    >
                                        <span style={{ fontFamily: "Calibri" }}>
                                            Laporan Ketua Panitia Penyelenggara (
                                        </span>
                                        <em>
                                            <span style={{ fontFamily: "Calibri" }}>
                                                Organizing Committee
                                            </span>
                                        </em>
                                        <span style={{ fontFamily: "Calibri" }}>
                                            ) MUNAS XII APJII 2024.
                                        </span>
                                    </li>
                                    <li
                                        style={{
                                            marginLeft: "15.08pt",
                                            textAlign: "justify",
                                            paddingLeft: "4pt",
                                            fontFamily: '"Noto Sans Symbols"',
                                            fontSize: "12pt"
                                        }}
                                    >
                                        <span style={{ fontFamily: "Calibri" }}>
                                            Sambutan Ketua Umum APJII Periode 2021–2024.
                                        </span>
                                    </li>
                                    <li
                                        style={{
                                            marginLeft: "15.08pt",
                                            textAlign: "justify",
                                            paddingLeft: "4pt",
                                            fontFamily: '"Noto Sans Symbols"',
                                            fontSize: "12pt"
                                        }}
                                    >
                                        <span style={{ fontFamily: "Calibri" }}>
                                            Sambutan dari undangan VVIP&nbsp;
                                        </span>
                                        <em>
                                            <span style={{ fontFamily: "Calibri" }}>
                                                (to be confirmed).
                                            </span>
                                        </em>
                                    </li>
                                    <li
                                        style={{
                                            marginLeft: "15.08pt",
                                            textAlign: "justify",
                                            paddingLeft: "4pt",
                                            fontFamily: '"Noto Sans Symbols"',
                                            fontSize: "12pt"
                                        }}
                                    >
                                        <span style={{ fontFamily: "Calibri" }}>
                                            Ketua Umum APJII didampingi oleh Undangan VVIP Secara Resmi
                                            Membuka Rangkaian Acara MUNAS XII APJII 2024.
                                        </span>
                                    </li>
                                </ol>
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginLeft: "36pt",
                                        marginBottom: "0pt",
                                        textAlign: "justify",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>&nbsp;</span>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        lineHeight: "150%",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>10:00 – 12:00 WIB</span>
                                </p>
                            </td>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "justify",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <strong>
                                        <span style={{ fontFamily: "Calibri" }}>
                                            SIDANG PARIPURNA I&nbsp;
                                        </span>
                                    </strong>
                                </p>
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "justify",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <strong>
                                        <span style={{ fontFamily: "Calibri" }}>
                                            PEMERIKSAAN &amp; PENGESAHAN KUORUM DAN PENGESAHAN RENCANA
                                            ACARA/AGENDA&nbsp;
                                        </span>
                                    </strong>
                                </p>
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "justify",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <strong>
                                        <span style={{ fontFamily: "Calibri" }}>&nbsp;</span>
                                    </strong>
                                </p>
                                <ol
                                    type={1}
                                    className="awlist1"
                                    style={{ margin: "0pt", paddingLeft: "0pt" }}
                                >
                                    <li
                                        style={{
                                            marginLeft: "15.86pt",
                                            textIndent: "-15.86pt",
                                            textAlign: "justify",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                width: "6.75pt",
                                                font: '7pt "Times New Roman"',
                                                display: "inline-block"
                                            }}
                                        >
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                        Pembukaan Sidang Paripurna I oleh Panitia Pengarah (
                                        <em>Steering Committee</em>) MUNAS XII APJII 2024.
                                    </li>
                                    <li
                                        style={{
                                            marginLeft: "15.86pt",
                                            textIndent: "-15.86pt",
                                            textAlign: "justify",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                width: "6.75pt",
                                                font: '7pt "Times New Roman"',
                                                display: "inline-block"
                                            }}
                                        >
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                        Pemeriksaan dan Pengesahan Kuorum.
                                    </li>
                                    <li
                                        style={{
                                            marginLeft: "15.86pt",
                                            textIndent: "-15.86pt",
                                            textAlign: "justify",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        <span
                                            style={{
                                                width: "6.75pt",
                                                font: '7pt "Times New Roman"',
                                                display: "inline-block"
                                            }}
                                        >
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                        Pengesahan Rencana Acara/Agenda Sidang MUNAS XII APJII 2024 yang
                                        terdiri dari;
                                        <ol
                                            type={1}
                                            className="awlist2"
                                            style={{
                                                marginRight: "0pt",
                                                marginLeft: "0pt",
                                                paddingLeft: "0pt"
                                            }}
                                        >
                                            <li style={{ marginLeft: "35.89pt", textIndent: "-36.22pt" }}>
                                                <span
                                                    style={{
                                                        width: "18pt",
                                                        font: '7pt "Times New Roman"',
                                                        display: "inline-block"
                                                    }}
                                                >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                                <strong>Sidang Paripurna I:</strong> Pengesahan Kuorum;
                                                Pengesahan Rencana Acara/Agenda; Pengesahan Tata Tertib;
                                                Pemilihan Pimpinan-Pimpinan Sidang MUNAS XII APJII 2024.
                                            </li>
                                            <li style={{ marginLeft: "35.89pt", textIndent: "-36.22pt" }}>
                                                <span
                                                    style={{
                                                        width: "18pt",
                                                        font: '7pt "Times New Roman"',
                                                        display: "inline-block"
                                                    }}
                                                >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                                <strong>Sidang Paripurna II:&nbsp;</strong>Laporan
                                                Pengawasan Dewan Pengawas dan Laporan Pertanggungjawaban
                                                Ketua Umum.
                                            </li>
                                            <li style={{ marginLeft: "35.89pt", textIndent: "-36.22pt" }}>
                                                <span
                                                    style={{
                                                        width: "18pt",
                                                        font: '7pt "Times New Roman"',
                                                        display: "inline-block"
                                                    }}
                                                >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                                <strong>Sidang Paripurna III:</strong> Pembacaan dan
                                                Pengesahan Rencana Garis Besar Program Kerja APJII Tahun
                                                2024–2028.
                                            </li>
                                            <li style={{ marginLeft: "35.89pt", textIndent: "-36.22pt" }}>
                                                <span
                                                    style={{
                                                        width: "18pt",
                                                        font: '7pt "Times New Roman"',
                                                        display: "inline-block"
                                                    }}
                                                >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                                <strong>Sidang Paripurna IV:&nbsp;</strong>Pemilihan Anggota
                                                Dewan Pengawas dan Ketua Umum APJII Periode 2024–2028.
                                            </li>
                                            <li style={{ marginLeft: "35.89pt", textIndent: "-36.22pt" }}>
                                                <span
                                                    style={{
                                                        width: "18pt",
                                                        font: '7pt "Times New Roman"',
                                                        display: "inline-block"
                                                    }}
                                                >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                                <strong>Sidang Paripurna V:</strong> Serah Terima dan
                                                Pelantikan Anggota Dewan Pengawas dan Ketua Umum APJII
                                                Periode 2024-2028 Terpilih.
                                            </li>
                                            <li style={{ marginLeft: "35.89pt", textIndent: "-36.22pt" }}>
                                                <span
                                                    style={{
                                                        width: "18pt",
                                                        font: '7pt "Times New Roman"',
                                                        display: "inline-block"
                                                    }}
                                                >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                                <strong>Sidang Paripurna VI:&nbsp;</strong>Pengesahan
                                                seluruh Hasil Musyawarah Nasional XII APJII dan
                                                <strong>&nbsp;</strong>Penetapan Waktu Pelaksanaan
                                                Musyawarah Nasional XIII APJII.
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                                <ol
                                    className='list-decimal'
                                    start={4}
                                    type={1}
                                    style={{ margin: "0pt", paddingLeft: "0pt" }}
                                >
                                    <li
                                        style={{
                                            marginLeft: "14.11pt",
                                            textAlign: "justify",
                                            paddingLeft: "4pt",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        Pengesahan Tata Tertib Musyawarah Nasional XII APJII Tahun 2024;
                                    </li>
                                    <li
                                        style={{
                                            marginLeft: "14.11pt",
                                            textAlign: "justify",
                                            paddingLeft: "4pt",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        Pemilihan Pimpinan-Pimpinan Sidang Paripurna II dan Sidang
                                        Paripurna III Musyawarah Nasional XII APJII Tahun 2024.
                                    </li>
                                </ol>
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginLeft: "15.75pt",
                                        marginBottom: "0pt",
                                        textAlign: "justify",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>&nbsp;</span>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        lineHeight: "150%",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>12:00 – 13:00 WIB</span>
                                </p>
                            </td>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "justify",
                                        lineHeight: "150%",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>
                                        Istirahat, Sholat, Makan Siang.
                                    </span>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        lineHeight: "150%",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>13:00 – 16:00 WIB</span>
                                </p>
                            </td>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "justify",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <strong>
                                        <span style={{ fontFamily: "Calibri" }}>
                                            SIDANG PARIPURNA II
                                        </span>
                                    </strong>
                                </p>
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "justify",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <strong>
                                        <span style={{ fontFamily: "Calibri" }}>
                                            LAPORAN PENGAWASAN DEWAN PENGAWAS DAN LAPORAN
                                            PERTANGGUNGJAWABAN KETUA UMUM
                                        </span>
                                    </strong>
                                </p>
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "justify",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <strong>
                                        <span style={{ fontFamily: "Calibri" }}>&nbsp;</span>
                                    </strong>
                                </p>
                                <ol className='list-decimal' type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
                                    <li
                                        style={{
                                            marginLeft: "14.11pt",
                                            textAlign: "justify",
                                            paddingLeft: "4pt",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        Pembukaan Sidang Paripurna II oleh Pimpinan Sidang Terpilih.
                                    </li>
                                    <li
                                        style={{
                                            marginLeft: "14.11pt",
                                            textAlign: "justify",
                                            paddingLeft: "4pt",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        Laporan Pengawasan diwakili oleh Ketua Dewan Pengawas APJII
                                        Periode 2021 – 2024.
                                    </li>
                                    <li
                                        style={{
                                            marginLeft: "14.11pt",
                                            textAlign: "justify",
                                            paddingLeft: "4pt",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        Laporan Pertanggungjawaban Ketua Umum APJII Periode 2021–2024.
                                    </li>
                                    <li
                                        style={{
                                            marginLeft: "14.11pt",
                                            textAlign: "justify",
                                            paddingLeft: "4pt",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        Tanggapan Umum Peserta Atas Laporan Pertanggungjawaban Ketua
                                        Umum APJII Periode 2021–2024.
                                    </li>
                                    <li
                                        style={{
                                            marginLeft: "14.11pt",
                                            textAlign: "justify",
                                            paddingLeft: "4pt",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        Penetapan Atas Laporan Pertanggungjawaban Ketua Umum APJII
                                        Periode 2021–2024.
                                    </li>
                                    <li
                                        style={{
                                            marginLeft: "14.11pt",
                                            textAlign: "justify",
                                            paddingLeft: "4pt",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        Penetapan Demisioner Dewan Pengawas dan Ketua Umum APJII Periode
                                        2021–2024.
                                    </li>
                                </ol>
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginLeft: "54pt",
                                        marginBottom: "0pt",
                                        textAlign: "justify",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>&nbsp;</span>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        lineHeight: "150%",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>16:00 – 16:30 WIB</span>
                                </p>
                            </td>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "justify",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <strong>
                                        <span style={{ fontFamily: "Calibri" }}>
                                            SIDANG PARIPURNA III
                                        </span>
                                    </strong>
                                </p>
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "justify",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <strong>
                                        <span style={{ fontFamily: "Calibri" }}>
                                            PEMBACAAN DAN PENGESAHAN GARIS BESAR PROGRAM KERJA APJII
                                            PERIODE 2024–2028
                                        </span>
                                    </strong>
                                </p>
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "justify",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <strong>
                                        <span style={{ fontFamily: "Calibri" }}>&nbsp;</span>
                                    </strong>
                                </p>
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginLeft: "20.36pt",
                                        marginBottom: "0pt",
                                        textIndent: "-20.36pt",
                                        textAlign: "justify",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>1.</span>
                                    <span
                                        style={{
                                            width: "11.25pt",
                                            font: '7pt "Times New Roman"',
                                            display: "inline-block"
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                    <span style={{ fontFamily: "Calibri" }}>
                                        Pembukaan Sidang Paripurna III oleh Pimpinan Sidang
                                        Terpilih.&nbsp;
                                    </span>
                                </p>
                                <h5
                                    style={{
                                        marginTop: "0pt",
                                        marginLeft: "20.36pt",
                                        marginBottom: "0pt",
                                        textIndent: "-20.36pt",
                                        pageBreakInside: "avoid",
                                        pageBreakAfter: "avoid",
                                        widows: 2,
                                        orphans: 2,
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri", fontWeight: "normal" }}>
                                        2.
                                    </span>
                                    <span
                                        style={{
                                            width: "11.25pt",
                                            font: '7pt "Times New Roman"',
                                            display: "inline-block"
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                    <span style={{ fontFamily: "Calibri", fontWeight: "normal" }}>
                                        Pembacaan Rencana Garis Besar Program Kerja APJII
                                        2024–2028.&nbsp;
                                    </span>
                                </h5>
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginLeft: "20.36pt",
                                        marginBottom: "0pt",
                                        textIndent: "-20.36pt",
                                        textAlign: "justify",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>3.</span>
                                    <span
                                        style={{
                                            width: "11.25pt",
                                            font: '7pt "Times New Roman"',
                                            display: "inline-block"
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                    <span style={{ fontFamily: "Calibri" }}>
                                        Penetapan Rencana Garis Besar Program Kerja APJII 2024–2028
                                        menjadi Garis Besar Program Kerja APJII 2024–2028.
                                    </span>
                                </p>
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginLeft: "20.25pt",
                                        marginBottom: "0pt",
                                        textAlign: "justify",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>&nbsp;</span>
                                </p>
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginLeft: "20.25pt",
                                        marginBottom: "0pt",
                                        textAlign: "justify",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>&nbsp;</span>
                                </p>
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginLeft: "20.25pt",
                                        marginBottom: "0pt",
                                        textAlign: "justify",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>&nbsp;</span>
                                </p>
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "justify",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>&nbsp;</span>
                                </p>
                            </td>
                        </tr>
                        <tr style={{ height: "121.65pt" }}>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        lineHeight: "150%",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>16:30 – 17:00 WIB</span>
                                </p>
                            </td>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "justify",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <strong>
                                        <span style={{ fontFamily: "Calibri" }}>
                                            SIDANG PARIPURNA IV&nbsp;
                                        </span>
                                    </strong>
                                </p>
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "justify",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <strong>
                                        <span style={{ fontFamily: "Calibri" }}>
                                            PEMILIHAN ANGGOTA DEWAN PENGAWAS APJII PERIODE 2024
                                        </span>
                                    </strong>
                                    <span style={{ fontFamily: "Calibri" }}>–</span>
                                    <strong>
                                        <span style={{ fontFamily: "Calibri" }}>
                                            2028 DAN KETUA UMUM APJII PERIODE 2024&nbsp;
                                        </span>
                                    </strong>
                                    <span style={{ fontFamily: "Calibri" }}>&nbsp;</span>
                                    <span style={{ fontFamily: "Calibri" }}>–&nbsp;</span>
                                    <strong>
                                        <span style={{ fontFamily: "Calibri" }}>2028</span>
                                    </strong>
                                </p>
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "justify",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>&nbsp;</span>
                                </p>
                                <ol className='list-decimal' type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
                                    <li
                                        style={{
                                            marginLeft: "14.11pt",
                                            textAlign: "justify",
                                            paddingLeft: "4pt",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        Pembukaan Sidang Paripurna IV dengan agenda Pemilihan Anggota
                                        Dewan Pengawas dan Ketua Umum APJII Periode 2024–2028 oleh
                                        Panitia Pemilihan (<em>Election Committee)</em>.
                                    </li>
                                </ol>
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "justify",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>&nbsp;</span>
                                </p>
                            </td>
                        </tr>
                        <tr style={{ height: "54.8pt" }}>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        lineHeight: "150%",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>17:00 – 17:30 WIB</span>
                                </p>
                            </td>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <ol
                                    className='list-decimal'
                                    start={2}
                                    type={1}
                                    style={{ margin: "0pt", paddingLeft: "0pt" }}
                                >
                                    <li
                                        style={{
                                            marginLeft: "14.11pt",
                                            textAlign: "justify",
                                            paddingLeft: "4pt",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        Pengesahan dan Pengumuman Daftar Calon Anggota Dewan Pengawas
                                        Periode 2024–2028 dan Daftar Calon Ketua Umum APJII Periode 2024
                                        – 2028.
                                    </li>
                                </ol>
                            </td>
                        </tr>
                        <tr style={{ height: "31.85pt" }}>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        lineHeight: "150%",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>17:30 – 18:30 WIB</span>
                                </p>
                            </td>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <ol
                                    className='list-decimal'
                                    start={3}
                                    type={1}
                                    style={{ margin: "0pt", paddingLeft: "0pt" }}
                                >
                                    <li
                                        style={{
                                            marginLeft: "14.11pt",
                                            textAlign: "justify",
                                            paddingLeft: "4pt",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        Penyampaian Tata Cara Pemilihan.
                                    </li>
                                </ol>
                            </td>
                        </tr>
                        <tr>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        lineHeight: "150%",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>18:30 WIB</span>
                                </p>
                            </td>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "justify",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>Sidang di</span>
                                    <em>
                                        <span style={{ fontFamily: "Calibri" }}>skorsing</span>
                                    </em>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        lineHeight: "150%",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>18:30 – 19:30 WIB</span>
                                </p>
                            </td>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "justify",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>
                                        Istirahat, Sholat, dan Makan Malam.
                                    </span>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p
                    style={{
                        marginTop: "0pt",
                        marginBottom: "0pt",
                        widows: 2,
                        orphans: 2,
                        fontSize: "12pt"
                    }}
                >
                    <strong>
                        <span style={{ fontFamily: "Calibri" }}>&nbsp;</span>
                    </strong>
                </p>
                <p
                    style={{
                        marginTop: "0pt",
                        marginBottom: "0pt",
                        widows: 2,
                        orphans: 2,
                        fontSize: "12pt"
                    }}
                >
                    <strong>
                        <span style={{ fontFamily: "Calibri" }}>&nbsp;</span>
                    </strong>
                </p>
                <table
                    cellSpacing={0}
                    cellPadding={0}
                    style={{
                        width: "100%",
                        border: "0.75pt solid #000000",
                        borderCollapse: "collapse"
                    }}
                >
                    <tbody>
                        <tr style={{ height: "34.5pt" }}>
                            <td
                                colSpan={2}
                                style={{
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top",
                                    backgroundColor: "#c9daf8"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "center",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <strong>
                                        <span style={{ fontFamily: "Calibri" }}>
                                            SELASA, 24 SEPTEMBER 2024
                                        </span>
                                    </strong>
                                </p>
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "center",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <strong>
                                        <span style={{ fontFamily: "Calibri" }}>
                                            PERSIDANGAN HARI KEDUA
                                        </span>
                                    </strong>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "center",
                                        lineHeight: "150%",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <strong>
                                        <span style={{ fontFamily: "Calibri" }}>Waktu&nbsp;</span>
                                    </strong>
                                </p>
                            </td>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "center",
                                        lineHeight: "150%",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <strong>
                                        <span style={{ fontFamily: "Calibri" }}>Kegiatan&nbsp;</span>
                                    </strong>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        lineHeight: "150%",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>08:00 – 08:05 WIB</span>
                                </p>
                            </td>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "justify",
                                        lineHeight: "150%",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <em>
                                        <span style={{ fontFamily: "Calibri" }}>Skorsing&nbsp;</span>
                                    </em>
                                    <span style={{ fontFamily: "Calibri" }}>dicabut</span>
                                </p>
                            </td>
                        </tr>
                        <tr style={{ height: "96.45pt" }}>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        lineHeight: "150%",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>08:05 – 11:00 WIB</span>
                                </p>
                            </td>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "justify",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <strong>
                                        <span style={{ fontFamily: "Calibri" }}>
                                            [LANJUTAN] SIDANG PARIPURNA IV&nbsp;
                                        </span>
                                    </strong>
                                </p>
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "justify",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <strong>
                                        <span style={{ fontFamily: "Calibri" }}>
                                            PEMILIHAN ANGGOTA DEWAN PENGAWAS APJII PERIODE 2024
                                        </span>
                                    </strong>
                                    <span style={{ fontFamily: "Calibri" }}>–</span>
                                    <strong>
                                        <span style={{ fontFamily: "Calibri" }}>
                                            2028 DAN KETUA UMUM APJII PERIODE 2024
                                        </span>
                                    </strong>
                                    <span style={{ fontFamily: "Calibri" }}>–</span>
                                    <strong>
                                        <span style={{ fontFamily: "Calibri" }}>2028</span>
                                    </strong>
                                </p>
                                <ol
                                    className='list-decimal'
                                    start={4}
                                    type={1}
                                    style={{ margin: "0pt", paddingLeft: "0pt" }}
                                >
                                    <li
                                        style={{
                                            marginLeft: "14.11pt",
                                            textAlign: "justify",
                                            paddingLeft: "4pt",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        Pemungutan suara Pemilihan Anggota Dewan Pengawas dan Ketua Umum
                                        APJII Periode 2024 – 2028.
                                    </li>
                                </ol>
                                <ol className='list-[lower-alpha]' type="a" style={{ margin: "0pt", paddingLeft: "0pt" }}>
                                    <li
                                        style={{
                                            marginLeft: "67.78pt",
                                            textAlign: "justify",
                                            paddingLeft: "4.22pt",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        Kluster I: 08:05 – 09:00 WIB
                                    </li>
                                    <li
                                        style={{
                                            marginLeft: "68.33pt",
                                            textAlign: "justify",
                                            paddingLeft: "3.67pt",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        Kluster II: 09:00 – 10:00 WIB
                                    </li>
                                    <li
                                        style={{
                                            marginLeft: "67.1pt",
                                            textAlign: "justify",
                                            paddingLeft: "4.9pt",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        Kluster III: 10:00 – 11:00 WIB
                                    </li>
                                </ol>
                                <ol
                                    className='list-decimal'
                                    start={5}
                                    type={1}
                                    style={{ margin: "0pt", paddingLeft: "0pt" }}
                                >
                                    <li
                                        style={{
                                            marginLeft: "14.11pt",
                                            textAlign: "justify",
                                            paddingLeft: "4pt",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        Peserta MUNAS XII APJII yang telah memilih, disilakan menunggu
                                        penghitungan suara di lokasi:
                                    </li>
                                </ol>
                                <ol className='list-[lower-alpha]' type="a" style={{ margin: "0pt", paddingLeft: "0pt" }}>
                                    <li
                                        style={{
                                            marginLeft: "67.78pt",
                                            textAlign: "justify",
                                            paddingLeft: "4.22pt",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        Aviosa Atrium<em>,&nbsp;</em>Lantai 5 (<em>Smoking Area</em>);
                                        dan
                                    </li>
                                    <li
                                        style={{
                                            marginLeft: "68.33pt",
                                            textAlign: "justify",
                                            paddingLeft: "3.67pt",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        <em>Baltic Room,&nbsp;</em>Lantai 2 <em>(Non Smoking Area).</em>
                                    </li>
                                </ol>
                            </td>
                        </tr>
                        <tr style={{ height: "26.25pt" }}>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        lineHeight: "150%",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>11:00 – 13:30 WIB</span>
                                </p>
                            </td>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <ol
                                    className='list-decimal'
                                    start={6}
                                    type={1}
                                    style={{ margin: "0pt", paddingLeft: "0pt" }}
                                >
                                    <li
                                        style={{
                                            marginLeft: "14.11pt",
                                            textAlign: "justify",
                                            paddingLeft: "4pt",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        Penghitungan suara yang diawali dengan pengumuman dari Ketua
                                        Panitia Pemilihan (<em>Election Committee</em>) bahwa pemungutan
                                        suara telah berakhir.
                                    </li>
                                    <li
                                        style={{
                                            marginLeft: "14.11pt",
                                            textAlign: "justify",
                                            paddingLeft: "4pt",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        Peserta MUNAS XII APJII diarahkan oleh Panitia MUNAS XII APJII
                                        untuk makan siang dan menyaksikan penghitungan suara melalui
                                        layar <em>streaming&nbsp;</em>di lokasi:
                                    </li>
                                </ol>
                                <ol className='list-[lower-alpha]' type="a" style={{ margin: "0pt", paddingLeft: "0pt" }}>
                                    <li
                                        style={{
                                            marginLeft: "67.78pt",
                                            textAlign: "justify",
                                            paddingLeft: "4.22pt",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        Aviosa Atrium<em>,&nbsp;</em>Lantai 5 (<em>Smoking Area</em>).
                                    </li>
                                    <li
                                        style={{
                                            marginLeft: "68.33pt",
                                            textAlign: "justify",
                                            paddingLeft: "3.67pt",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        <em>Baltic Room,&nbsp;</em>Lantai 2 <em>(Non Smoking Area).</em>
                                    </li>
                                    <li
                                        style={{
                                            marginLeft: "67.1pt",
                                            textAlign: "justify",
                                            paddingLeft: "4.9pt",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        Hotel Vasa, Lantai 5.
                                    </li>
                                </ol>
                                <ol
                                    className='list-decimal'
                                    start={8}
                                    type={1}
                                    style={{ margin: "0pt", paddingLeft: "0pt" }}
                                >
                                    <li
                                        style={{
                                            marginLeft: "14.11pt",
                                            textAlign: "justify",
                                            paddingLeft: "4pt",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        Penghitungan suara pada tiap TPS tidak ada{" "}
                                        <em>skorsing&nbsp;</em>ISHOMA.
                                    </li>
                                </ol>
                            </td>
                        </tr>
                        <tr style={{ height: "25.8pt" }}>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        lineHeight: "150%",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>13:30 – 14:00 WIB</span>
                                </p>
                            </td>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <ol
                                    className='list-decimal'
                                    start={9}
                                    type={1}
                                    style={{ margin: "0pt", paddingLeft: "0pt" }}
                                >
                                    <li
                                        style={{
                                            marginLeft: "14.11pt",
                                            textAlign: "justify",
                                            paddingLeft: "4pt",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        Rekapitulasi pada 4 kotak suara Calon Dewan Pengawas dan 4 kotak
                                        suara Calon Ketua Umum.
                                    </li>
                                </ol>
                            </td>
                        </tr>
                        <tr style={{ height: "38.25pt" }}>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        lineHeight: "150%",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>14:00 – 15:00 WIB</span>
                                </p>
                            </td>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <ol
                                    className='list-decimal'
                                    start={10}
                                    type={1}
                                    style={{ margin: "0pt", paddingLeft: "0pt" }}
                                >
                                    <li
                                        style={{
                                            marginLeft: "19.69pt",
                                            textAlign: "justify",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        Persiapan Pemilihan Suara Putaran Kedua Calon Anggota Dewan
                                        Pengawas (apabila diperlukan).
                                    </li>
                                </ol>
                            </td>
                        </tr>
                        <tr style={{ height: "43.15pt" }}>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        lineHeight: "150%",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>15:00 – 16:30 WIB</span>
                                </p>
                            </td>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <ol
                                    className='list-decimal'
                                    start={11}
                                    type={1}
                                    style={{ margin: "0pt", paddingLeft: "0pt" }}
                                >
                                    <li
                                        style={{
                                            marginLeft: "19.69pt",
                                            textAlign: "justify",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        Pemungutan Suara Putaran Kedua Calon Anggota Dewan Pengawas
                                        (apabila diperlukan).
                                    </li>
                                </ol>
                            </td>
                        </tr>
                        <tr style={{ height: "32.65pt" }}>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        lineHeight: "150%",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>16:30 – 17:30 WIB</span>
                                </p>
                            </td>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <ol
                                    className='list-decimal'
                                    start={12}
                                    type={1}
                                    style={{ margin: "0pt", paddingLeft: "0pt" }}
                                >
                                    <li
                                        style={{
                                            marginLeft: "20.19pt",
                                            textAlign: "justify",
                                            paddingLeft: "1.75pt",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        Perhitungan Suara Putaran Kedua dan Rekapitulasi Suara Putaran
                                        Kedua Calon Anggota Dewan Pengawas (apabila diperlukan).
                                    </li>
                                </ol>
                            </td>
                        </tr>
                        <tr style={{ height: "24.2pt" }}>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        lineHeight: "150%",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>17:30 - 17:45 WIB</span>
                                </p>
                            </td>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <ol
                                    className='list-decimal'
                                    start={13}
                                    type={1}
                                    style={{ margin: "0pt", paddingLeft: "0pt" }}
                                >
                                    <li
                                        style={{
                                            marginLeft: "19.69pt",
                                            textAlign: "justify",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        Rapat Pleno Panitia Pemilihan (<em>Election Committee</em>).
                                    </li>
                                </ol>
                            </td>
                        </tr>
                        <tr style={{ height: "33.45pt" }}>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        lineHeight: "150%",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>17:45 – 18:00 WIB</span>
                                </p>
                            </td>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <ol
                                    className='list-decimal'
                                    start={14}
                                    type={1}
                                    style={{ margin: "0pt", paddingLeft: "0pt" }}
                                >
                                    <li
                                        style={{
                                            marginLeft: "19.69pt",
                                            textAlign: "justify",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        Pengumuman hasil rekapitulasi Penghitungan perolehan suara Dewan
                                        Pengawas Terpilih dan Ketua Umum Terpilih.
                                    </li>
                                </ol>
                            </td>
                        </tr>
                        <tr style={{ height: "12.75pt" }}>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        lineHeight: "150%",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>18:00 – 18:30 WIB</span>
                                </p>
                            </td>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <ol
                                    className='list-decimal'
                                    start={15}
                                    type={1}
                                    style={{ margin: "0pt", paddingLeft: "0pt" }}
                                >
                                    <li
                                        style={{
                                            marginLeft: "19.69pt",
                                            textAlign: "justify",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        Rapat tertutup Dewan Pengawas Terpilih.
                                    </li>
                                </ol>
                            </td>
                        </tr>
                        <tr style={{ height: "66pt" }}>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        lineHeight: "150%",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>18:30 – 18:45 WIB</span>
                                </p>
                            </td>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <ol
                                    className='list-decimal'
                                    start={16}
                                    type={1}
                                    style={{ margin: "0pt", paddingLeft: "0pt" }}
                                >
                                    <li
                                        style={{
                                            marginLeft: "19.69pt",
                                            textAlign: "justify",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        Penetapan dan Pengesahan Anggota Dewan Pengawas Terpilih
                                        sekaligus mengumumkan Ketua Dewan Pengawas, Wakil Ketua Dewan
                                        Pengawas, dan Sekretaris Dewan Pengawas serta sambutan Ketua
                                        Dewan Pengawas Terpilih.
                                    </li>
                                </ol>
                            </td>
                        </tr>
                        <tr style={{ height: "19.7pt" }}>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        lineHeight: "150%",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>18:45 – 19:00 WIB</span>
                                </p>
                            </td>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <ol
                                    className='list-decimal'
                                    start={17}
                                    type={1}
                                    style={{ margin: "0pt", paddingLeft: "0pt" }}
                                >
                                    <li
                                        style={{
                                            marginLeft: "19.69pt",
                                            textAlign: "justify",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        Penetapan dan Pengesahan Ketua Umum Terpilih.
                                    </li>
                                </ol>
                            </td>
                        </tr>
                        <tr style={{ height: "53.55pt" }}>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        lineHeight: "150%",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>19:00 – 19:10 WIB</span>
                                </p>
                            </td>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <ol
                                    className='list-decimal'
                                    start={18}
                                    type={1}
                                    style={{ margin: "0pt", paddingLeft: "0pt" }}
                                >
                                    <li
                                        style={{
                                            marginLeft: "19.69pt",
                                            textAlign: "justify",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        Penyampaian laporan penyelenggaraan Pemilihan Anggota Dewan
                                        Pengawas dan Ketua Umum APJII Periode 2024–2028 oleh Panitia
                                        Pemilihan (<em>Election Committee</em>).
                                    </li>
                                </ol>
                            </td>
                        </tr>
                        <tr style={{ height: "27.75pt" }}>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        lineHeight: "150%",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>19:10 – 20:10 WIB</span>
                                </p>
                            </td>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "justify",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>Makan malam&nbsp;</span>
                                </p>
                            </td>
                        </tr>
                        <tr style={{ height: "194.2pt" }}>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        lineHeight: "150%",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>20:10 – 21:10 WIB</span>
                                </p>
                            </td>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "justify",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <strong>
                                        <span style={{ fontFamily: "Calibri" }}>
                                            SIDANG PARIPURNA V
                                        </span>
                                    </strong>
                                </p>
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "justify",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <strong>
                                        <span style={{ fontFamily: "Calibri" }}>
                                            SERAH TERIMA DAN PELANTIKAN ANGGOTA DEWAN PENGAWAS TERPILIH
                                            DAN KETUA UMUM TERPILIH
                                        </span>
                                    </strong>
                                </p>
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "justify",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>&nbsp;</span>
                                </p>
                                <ol className='list-decimal' type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
                                    <li
                                        style={{
                                            marginLeft: "14.11pt",
                                            textAlign: "justify",
                                            paddingLeft: "4pt",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        Pembukaan Sidang Paripurna V oleh Pimpinan Sidang Panitia
                                        Pemilihan (<em>Election Committee</em>).
                                    </li>
                                    <li
                                        style={{
                                            marginLeft: "14.11pt",
                                            textAlign: "justify",
                                            paddingLeft: "4pt",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        Anggota Dewan Pengawas Terpilih dan Ketua Umum Terpilih
                                        mengucapkan sumpah/janji Anggota Dewan Pengawas Terpilih dan
                                        Ketua Umum Terpilih yang dipandu oleh Pimpinan Sidang dan
                                        didampingi oleh Rohaniawan.
                                    </li>
                                    <li
                                        style={{
                                            marginLeft: "14.11pt",
                                            textAlign: "justify",
                                            paddingLeft: "4pt",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        Penandatanganan Berita Acara sumpah/janji Anggota Dewan Pengawas
                                        Terpilih dan Ketua Umum Terpilih.
                                    </li>
                                    <li
                                        style={{
                                            marginLeft: "14.11pt",
                                            textAlign: "justify",
                                            paddingLeft: "4pt",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        Serah Terima Kepengurusan Dewan Pengawas dan Ketua Umum
                                        Demisioner kepada Dewan Pengawas dan Ketua Umum Terpilih.
                                    </li>
                                </ol>
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginLeft: "36pt",
                                        marginBottom: "0pt",
                                        textAlign: "justify",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>&nbsp;</span>
                                </p>
                            </td>
                        </tr>
                        <tr style={{ height: "27.05pt" }}>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        lineHeight: "150%",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>21:10 – 22:00 WIB</span>
                                </p>
                            </td>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "justify",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <strong>
                                        <span style={{ fontFamily: "Calibri" }}>
                                            SIDANG PARIPURNA VI
                                        </span>
                                    </strong>
                                </p>
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "justify",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <strong>
                                        <span style={{ fontFamily: "Calibri" }}>
                                            PENGESAHAN SELURUH HASIL MUNAS XII APJII 2024 DAN PENETAPAN
                                            WAKTU PELAKSANAAN MUNAS XIII APJII 2028
                                        </span>
                                    </strong>
                                </p>
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "justify",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <strong>
                                        <span style={{ fontFamily: "Calibri" }}>&nbsp;</span>
                                    </strong>
                                </p>
                                <ol className='list-decimal' type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
                                    <li
                                        style={{
                                            marginLeft: "14.11pt",
                                            textAlign: "justify",
                                            paddingLeft: "6.25pt",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        Pembukaan Sidang Paripurna VI oleh Panitia Pengarah (
                                        <em>Steering Committee</em>).
                                    </li>
                                    <li
                                        style={{
                                            marginLeft: "14.11pt",
                                            textAlign: "justify",
                                            paddingLeft: "6.25pt",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        Pengesahan Seluruh Hasil MUNAS XII APJII 2024.
                                    </li>
                                    <li
                                        style={{
                                            marginLeft: "14.11pt",
                                            textAlign: "justify",
                                            paddingLeft: "6.25pt",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        Penetapan dan Pengumuman Waktu Pelaksanaan MUNAS XIII APJII
                                        2028.
                                    </li>
                                    <li
                                        style={{
                                            marginLeft: "14.11pt",
                                            textAlign: "justify",
                                            paddingLeft: "6.25pt",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        Sambutan Ketua Umum APJII Terpilih, sekaligus mengumumkan
                                        Sekretaris Umum dan Bendahara Umum.
                                    </li>
                                </ol>
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "justify",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>&nbsp;</span>
                                </p>
                            </td>
                        </tr>
                        <tr style={{ height: "27.05pt" }}>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        lineHeight: "150%",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>22:00 WIB</span>
                                </p>
                            </td>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "0.75pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "justify",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>
                                        Penutup seluruh Rangkaian MUNAS XII APJII 2024.&nbsp;
                                    </span>
                                </p>
                            </td>
                        </tr>
                        <tr style={{ height: "27.05pt" }}>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        lineHeight: "150%",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>22:00 – 00:00 WIB</span>
                                </p>
                            </td>
                            <td
                                style={{
                                    borderTopStyle: "solid",
                                    borderTopWidth: "0.75pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "0.75pt",
                                    padding: "5pt 4.62pt",
                                    verticalAlign: "top"
                                }}
                            >
                                <p
                                    style={{
                                        marginTop: "0pt",
                                        marginBottom: "0pt",
                                        textAlign: "justify",
                                        fontSize: "12pt"
                                    }}
                                >
                                    <span style={{ fontFamily: "Calibri" }}>
                                        Rangkaian penutupan:
                                    </span>
                                </p>
                                <ol type={1} style={{ margin: "0pt", paddingLeft: "0pt" }} className='list-decimal'>
                                    <li
                                        style={{
                                            marginLeft: "32.11pt",
                                            textAlign: "justify",
                                            paddingLeft: "3.89pt",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        <em>Lighting Show</em> dan Perkusi;
                                    </li>
                                    <li
                                        style={{
                                            marginLeft: "32.11pt",
                                            textAlign: "justify",
                                            paddingLeft: "3.89pt",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        Reog mengusung Ketua Umum Terpilih ke panggung untuk{" "}
                                        <em>Closing Statement</em> dari Ketua Umum Terpilih;
                                    </li>
                                    <li
                                        style={{
                                            marginLeft: "32.11pt",
                                            textAlign: "justify",
                                            paddingLeft: "3.89pt",
                                            fontFamily: "Calibri",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        Penampilan Rindi and Friends.
                                    </li>
                                </ol>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "12pt" }}>
                    <strong>
                        <span style={{ fontFamily: "Calibri" }}>&nbsp;</span>
                    </strong>
                </p>
                <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "14pt" }}>
                    <strong>
                        <em>
                            <span style={{ fontFamily: "Calibri" }}>
                                *) Susunan acara dapat berubah sewaktu-waktu
                            </span>
                        </em>
                    </strong>
                </p>
            </div>
        </div>
    )
}

export default JadwalHtml;