import { useCallback, useEffect,  useState } from "react";
import { useGlobalFunction } from "../../../generalFunction";
import config from "../../../config";
import axios from "axios";

export function useLokasi(){
    const { isLoading, setIsLoading, showLoading, closeLoading, alertValue, setAlertValue} = useGlobalFunction();
    const [info, setInfo] = useState(null);
    const [isReady, setIsReady] = useState(false);    
    const [defaultProps, setDefaultProps] = useState({
        center: {
          lat: -7.280359387734726,
          lng: 112.7023834451269
        },
        zoom: 15
      });

    const fetchData = useCallback(async() => {
        showLoading()
        try{            
            const res = await axios.get(`${config.apiBaseUrl[process.env.NODE_ENV]}${config.endPoint.siteSettingGuest}`).then(res => res.data);
            if (res){
                setInfo(res.results);
                setDefaultProps(defaultProps => ({...defaultProps,
                    center : {...defaultProps.center,
                        lat : parseFloat(res.results.lat),
                        lng : parseFloat(res.results.lon),
                    }
                }))
                setIsReady(true);
            }
            closeLoading()
        }catch(error){
            
            closeLoading()
            const { response } = error;
            let msg = 'Unable to fetch data'
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            setAlertValue(alertValue => ({...alertValue,
                show: true,
                text : 'Error',
                subText: msg,
                color: 'danger'
            }))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
        // eslint-disable-next-line 
    },[])
    
    useEffect(() => {
        fetchData();
    },[fetchData])


    const handleShare = () => {
        window.open(`https://www.google.com/maps/search/?api=1&query=${info.lat},${info.lon}`, "_blank")
    }
    
    return {
        isLoading, setIsLoading, alertValue, info,  defaultProps, isReady, handleShare
    }
}