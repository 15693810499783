import { Icon } from "@iconify/react/dist/iconify.js";
import Search from "../../../../Components/Atoms/Search";
import Card from "../../../../Components/Layout/Card";
import { useKartuTandaPemilih } from "./kartuTandaPemilihFunction";
import Tables from "../../../../Components/molecules/Tables";
import { Fragment } from "react";
import Button from "../../../../Components/Atoms/Button";
import Modal from "../../../../Components/Atoms/Modal";
import AlertComp from "../../../../Components/Atoms/AlertComp";
import Alert from "../../../../Components/Atoms/Alert";
import { capitalAllLetters } from "../../../../utils/capitalAllLetters";
import PagePagination from "../../../../Components/Atoms/PagePagination";
import Tooltip from "../../../../Components/Atoms/Tooltip";


const KartuTandaPemilih = () => {
    const { isOnline, handleSearch, pageValue, handleChangeSearch,
        listData, handleGenerateVoucer,
        modalValue, handleCloseModal, setModalValue, handleSubmitGenerateVoucer,
        handleCloseAlert, alertValue, totalImport, importCount,
        successCouting, showCounting, handlePaginate, handleEdit, handleDelete, downloadExcel,
        isLoading
    } = useKartuTandaPemilih();

    return(
        <div>
            <AlertComp
                isOpen={alertValue.show}
                onClose={handleCloseAlert}
                text={alertValue.text}
                subText={alertValue.subText}
                color={alertValue.color}
                icon={alertValue.icon}
            />
            <Modal
                show={modalValue.show}
                setShow={() => {
                    setModalValue(modalValue => ({...modalValue, show: false}))
                }}
                width='xl'
                persistent={false}
                btnClose={false}
            >
                <div className='py-[20px] px-[30px] border-b border-b-gray-200 font-bold'>
                    {modalValue.title}
                </div>
                <div className='py-[50px] px-[30px]'>
                    { modalValue.text }
                </div>
                <div className='py-[20px] px-[30px] border-t border-t-gray-200 flex flex-row justify-end gap-2'>
                    { modalValue.tipe === 'generate' &&
                        <Button color='success' size='md' onClick={handleSubmitGenerateVoucer}>Generate</Button>
                    }
                    <Button color='base' size='md' onClick={handleCloseModal}>Cancel</Button>
                </div>
            </Modal>
            <Card
                isSearch={false}
            >
                <div className='pb-5 border-b border-b-gray-200'>
                    <div className="flex flex-row justify-between items-center">
                        <div className='font-bold flex flex-row gap-2 items-center'>
                            <div className={`${isOnline ? "text-green-500" : "text-gray-500"}`}>
                                <Icon icon="material-symbols:circle" />
                            </div>
                            <div>
                                Kartu Tanda Pemilih
                            </div>
                        </div>
                        <div className='flex flex-row items-center gap-2'>
                            <div>
                                <Search
                                    placeholder={"Cari"}
                                    onChange={(value) => handleChangeSearch(value)}
                                    handleSearch={handleSearch}
                                    value={pageValue.search}
                                />
                            </div>
                            <div>
                                <button className='text-white px-5 py-2 bg-blue-700 rounded-md shadow-md text-sm' onClick={handleGenerateVoucer}>Bulk Create</button>
                            </div>
                            <div>
                                <button disabled={isLoading} className='text-white px-5 py-2 bg-green-700 rounded-md shadow-md text-sm disabled:opacity-45' onClick={downloadExcel}>Download</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='pt-6'>
                    <div>
                        {totalImport !== importCount && (
                            <div className="animate-pulse">
                                Sedang Membuat Voucer TPS{" "}
                            </div>
                        )}
                    </div>
                    {successCouting && (
                        <div className="mb-5">
                            <Alert color="success" variant="tonal">
                                Import data successfully
                            </Alert>
                        </div>
                    )}
                    {showCounting && (
                        <div>
                            <div className="mb-0.5">
                            Import Progress {importCount} / {totalImport}
                            </div>
                            <div className="w-full bg-gray-200 rounded-full h-1.5 mb-4 dark:bg-gray-700">
                            <div
                                className="bg-blue-600 h-1.5 rounded-full dark:bg-blue-500"
                                style={{
                                width: `${totalImport === 0 ? 0 : (importCount / totalImport) * 100
                                    }%`,
                                }}
                            ></div>
                            </div>
                        </div>
                    )}
                    <Tables className='border-separate rounded-sm'>
                        <Tables.Head className='bg-[#efeeee]'>
                            <tr>
                                <Tables.Header className='font-bold text-[13px] py-[10px] text-right'>#</Tables.Header>                            
                                <Tables.Header className='font-bold text-[13px] py-[10px]'>Perusahaan</Tables.Header>            
                                <Tables.Header className='font-bold text-[13px] py-[10px]'>Peserta</Tables.Header>            
                                <Tables.Header className='font-bold text-[13px] py-[10px]'>Pukul</Tables.Header>            
                                <Tables.Header className='font-bold text-[13px] py-[10px]'>TPS</Tables.Header>                                
                                <Tables.Header className='font-bold text-[13px] py-[10px] text-center'>Action</Tables.Header>
                            </tr>
                        </Tables.Head>
                        <Tables.Body>
                            { listData?.data?.length === 0 ?
                                <Tables.Row>
                                    <Tables.Data colspan={6} className='text-center'>Belum ada data</Tables.Data>
                                </Tables.Row>
                                :
                                <Fragment>
                                    {listData?.data?.map((post, index) => {
                                        return (
                                            <Tables.Row key={index}>
                                                <Tables.Data className='text-right align-top'>{pageValue.offset + index + 1}</Tables.Data>
                                                <Tables.Data className='align-top'>{capitalAllLetters(post.perusahaan_display)}</Tables.Data>
                                                <Tables.Data className='align-top'>{capitalAllLetters(post.kontak_display)}</Tables.Data>
                                                <Tables.Data className='align-top'>{post.waktu_display}</Tables.Data>
                                                <Tables.Data className='align-top'>{post.tps_display}</Tables.Data>
                                                <Tables.Data className='align-top'>
                                                    <div className='flex flex-row gap-1'>
                                                        <Tooltip
                                                            tooltip={'Edit'}
                                                        >
                                                            <button onClick={() => handleEdit(post)} className='p-1 bg-orange-500 text-white rounded'><Icon icon='line-md:edit' /></button>
                                                        </Tooltip>
                                                        <Tooltip
                                                            tooltip={'Hapus'}
                                                        >
                                                            <button onClick={() => handleDelete(post)} className='p-1 bg-red-500 text-white rounded'><Icon icon='fluent-mdl2:delete'  /></button>
                                                        </Tooltip>
                                                    </div>
                                                </Tables.Data>
                                            </Tables.Row>
                                        )
                                    })}
                                </Fragment>
                            }
                        </Tables.Body>
                    </Tables>
                    <div className='mt-5'>
                        <PagePagination
                            data={pageValue.obj}
                            handlePagination={handlePaginate}
                            page={pageValue.page}
                            lastPage={pageValue.lastPage}
                        />
                    </div>
                    
                </div>
            </Card>
        </div>
    )
}
export default KartuTandaPemilih;