import {  useContext,  useState } from "react";
import { useGlobalFunction } from "../../../../generalFunction";
import { useCookies } from 'react-cookie';
import config from "../../../../config";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// import api from "../../../../api";
// import Moment from 'moment';
import { UserContext } from "../../../../context/UserContext";

export function useLoginMember(){
    const {  alertValue, setAlertValue, handleCloseAlert } = useGlobalFunction();
    const [cookies, setCookie] = useCookies([config.cookiesName]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const myState = useContext(UserContext);
    const [canRegister] = myState.canRegister    
    // const [canRegister, setCanRegister] = useState(false);
    const [formData, setFormData] = useState({
        username : {
            name : 'username',
            type: 'text',
            label : 'Username',
            value : '',
            require : true,
            errorMsg : '',
            showError : false,
            show: true
        },
        password : {
            name : 'password',
            type: 'password',
            label : 'Password',
            value : '',
            require : true,
            errorMsg : '',
            showError : false,
            show: true,
            showPassword : false,
            inputType : 'password'
        },
    })

    const handleShowPassword = (name) => {
        setFormData(formData => ({
            ...formData,
            [name]: {
                ...formData[name],
                showPassword: !formData[name].showPassword,
                type: !formData[name].showPassword ? 'text' : 'password'
            }
        }))
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(formData => ({
            ...formData,
            [name]: { ...formData[name], value: value }
        }))
    }

    const handleBlur = (e) => {
        const { name, value } = e.target;
        if (formData[name].require && value === ''){
            setFormData(formData => ({
                ...formData,
                [name]: { ...formData[name], showError: true }
            }))
        }else{
            setFormData(formData => ({
                ...formData,
                [name]: { ...formData[name], showError: false }
            }))
        }
    }

    const handleKeyDown = (e) => {        
        if (e.key === 'Enter'){
            handleSubmit();
        }
    }


    const handleSubmit = async() => {
        setIsLoading(true);
        try{
            let obj = {...formData}
            let tmp = {}
            let countError = 0;

            Object.entries(obj).map(([index, post]) => {
                if (post.require && post.value === ''){
                    post['showError'] = true;
                    countError++;
                }else{
                    post['showError'] = false;
                    tmp[index] = post.value;
                }
                return true;
            })

            if (countError > 0){
                setIsLoading(false);
                setAlertValue(alertValue => ({...alertValue,
                    show: true, 
                    text : 'Lengkapi Data',
                    subText : 'Harap lengkapi data yang masih kosong',
                    color : 'danger',
                }))

                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue)
                return;
            }

            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmp));
            // const res = await api.post(`${config.endPoint.loginMember}`, newForm).then(res => res.data);
            const res = await axios.post(`${config.apiBaseUrl[process.env.NODE_ENV]}${config.endPoint.loginMember}`, newForm).then(res => res.data);
            if (res){
                let tmpcookie = {
                    'token' : res.token, 
                    'user_info' : res.data.user_info,
                    'is_superuser' : false,
                    'is_perusahaan' : res.data.is_perusahaan,
                    'is_login' : true
                };
			    setCookie(config.cookiesName, tmpcookie, { path: '/', maxAge: config.expiresSession });      
                // navigate('/member')
                window.location.href = '/member'
            }

            setIsLoading(false);
        }catch(error){
            let { response } = error;
            let msg = "Terjadi kesalahan";
            if (response && response.data && response.data.message){
                msg = response.data.message
            }
            setAlertValue(alertValue => ({...alertValue,
                text : 'error',
                color: 'danger',
                subText: msg,
                show: true
            }))

            setIsLoading(false);

            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)        
        }
    }

    const handleRegister = () => {
        navigate('/signup')
    }

    const handleForgotPassword = () => {
        navigate('/forgot-password')
    }

    return {
        isLoading, setIsLoading, alertValue, setAlertValue, formData, setFormData, cookies, setCookie, 
        handleShowPassword, handleBlur, handleChange, handleKeyDown, handleSubmit, handleCloseAlert,
        canRegister, handleRegister, handleForgotPassword, 
    }
}