import { useState } from "react";
import api from "../../../api";
import config from "../../../config";
import { useGlobalFunction } from "../../../generalFunction";


export function useChangePassword(){
    const {
        alertValue, setAlertValue,  handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal, 
        isLoading, setIsLoading
    } = useGlobalFunction();
    const [formData, setFormData] = useState({
        old_password : {
            name : 'old_password',
            label : 'Old Password',
            value : '',
            type : 'password',
            col : 2,
            required : true,
            show : true,
            errorMsg : '',
            showError : false,
            flexType : 'row',
            inputType : 'password',
            showPassword : false,
        },
        new_password : {
            name : 'new_password',
            label : 'New Password',
            value : '',
            type : 'password',
            col : 2,
            required : true,
            show : true,
            errorMsg : '',
            showError : false,
            flexType : 'row',
            inputType : 'password',
            showPassword : false,
        },
        confirm_password : {
            name : 'confirm_password',
            label : 'Confirm Password',
            value : '',
            type : 'password',
            col : 2,
            required : true,
            show : true,
            errorMsg : '',
            showError : false,
            flexType : 'row',
            inputType : 'password',
            showPassword : false,
        },
    })

    const handleSubmit = async() => {
        setIsLoading(true);
        try{
            let obj = {...formData};
            let countError = 0;
            let tmpForm = {}

            Object.entries(obj).map(([index, post]) => {
                if (post.required && post.value === ''){
                    countError++;
                    post.showError = true;
                }else{
                    post.showError = false;
                    tmpForm[index] = post.value;
                }
                return true;
            })

            if (countError > 0){
                setAlertValue(alertValue => ({...alertValue,
                    show: true, text : 'Eror', subText : 'Lengkapi data', color : 'danger'
                }));
                setFormData(obj);
                setIsLoading(false);
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue,
                        show: false
                    }));                    
                }, config.timeOutValue)
                return;
            }
            
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmpForm));
            let res = await api.post(`${config.endPoint.changePassword}`, newForm).then(res => res.data);            
            if (res){
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Success', subText: res.pesan, color: 'success' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue)
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            let status = error && error.response && error.response.status ? error.response.status : '';

            let msg = 'Unable to save Data';
            let { response } = error;
            if (response && response.data && response.data.message){
                msg = response.data.message
            }

            if (status && status === 401) {
                return window.location.href = "/member/login"
            } else {
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Error', subText: msg, color: 'danger' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue)
            }
        }
    }

    return {
        alertValue, setAlertValue,  handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal, 
        isLoading, setIsLoading, handleSubmit, formData, setFormData
    }
}