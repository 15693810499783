import { useCallback, useEffect, useState } from "react"
import config from "../../../config"
import { useGlobalFunction } from "../../../generalFunction"
import { pageObj } from "../../../pageObj";
import axios from "axios";

export function usePeserta() {
    const { showLoading, closeLoading, pageValue, setPageValue } = useGlobalFunction();
    const [listData, setListData] = useState({ data: [], isLoading: false })
    const peserta = [
        {
            id: 1,
            perusahaan: 'PT. Queen Network Nusantara',
            nama: 'Supriyanto'
        },
        {
            id: 2,
            perusahaan: 'Louis Vuitton',
            nama: 'Eleanor Pena'
        },
        {
            id: 3,
            perusahaan: 'Gillete',
            nama: 'Darlene Robertson'
        },
    ]

    const fetchData = useCallback(async (q = '', offset = 0, limit = pageValue.limit) => {
        setListData(listData => ({ ...listData, isLoading: true }))
        showLoading();
        try {
            let params = {
                offset: offset,
                limit: limit
            }
            if (q) {
                params['q'] = q;
            }
            const res = await axios.get(`${config.apiBaseUrl[process.env.NODE_ENV]}${config.endPoint.pesertaWeb}`, { params: params }).then(res => res.data);
            if (res) {
                setListData(listData => ({ ...listData, isLoading: false, data: res.results }))
                setPageValue(pageValue => ({
                    ...pageValue,
                    obj: pageObj(res.count, limit, offset),
                    lastPage: Math.ceil(parseInt(res.count) / parseInt(limit))
                }))
            } else {
                setListData(listData => ({ ...listData, isLoading: false }))
            }
        } catch (error) {
            setListData(listData => ({ ...listData, isLoading: false }))
            closeLoading();
        }
        // eslint-disable-next-line
    }, [setPageValue])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    const handlePaginate = (page) => {
        let myOffset = (parseInt(page) * parseInt(pageValue.limit)) - parseInt(pageValue.limit)
        setPageValue(pageValue => ({ ...pageValue, page: page, offset: myOffset }))
        fetchData(pageValue.search, myOffset, pageValue.limit);
    }

    const handleChangeSearch = (e) => {
        const { name, value } = e.target;
        setPageValue(pageValue => ({ ...pageValue, [name]: value }))
    }

    const handleKeyDownSearch = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    }

    const handleSearch = () => {
        fetchData(pageValue.search, 0, pageValue.limit)
    }

    // Fungsi untuk mengubah limit
    const handleChangeLimit = (e) => {
        const newLimit = parseInt(e.target.value);
        console.log(newLimit)
        setPageValue(pageValue => ({ ...pageValue, limit: newLimit, page: 1, offset: 0 }));
        fetchData(pageValue.search, 0, newLimit); // Muat ulang data dengan limit baru
    }

    return {
        peserta, pageValue, listData, handlePaginate, handleChangeSearch,
        handleKeyDownSearch, handleSearch, handleChangeLimit // Return fungsi handleChangeLimit
    }
}
