import './App.css';
import Router from './router';
import { ThemeProvider } from "./context/ThemeContext";

function App() {
  return (
    <ThemeProvider>    
      <Router/>
    </ThemeProvider>
  );
}

export default App;
