// import { useContext } from "react";
import PagePagination from "../../../../Components/Atoms/PagePagination";
import Search from "../../../../Components/Atoms/Search";
import Card from "../../../../Components/Layout/Card";
import Tables from "../../../../Components/molecules/Tables";
import { useIdCard } from "./idCardFunction";
// import { ThemeContext } from "../../../../context/ThemeContext";
import { Icon } from "@iconify/react/dist/iconify.js";
import Alert from "../../../../Components/Atoms/Alert";
import default_user from "../../../../assets/images/default_user.png";
import Button from "../../../../Components/Atoms/Button";

const IDCardPage = () => {
  const {
    handleChangeSearch,
    pageValue,
    handleSearch,
    listData,
    handlePaginate,
    createIdCard,
    isOnline,
    successCouting,
    showCounting,
    importCount,
    totalImport,
    setting
  } = useIdCard();

  return (
    <div>
      <Card
        // title='Master Kandidat Ketua Umum'
        // isCreate={view === 'create'}
        isSearch={false}
      // searchValue={pageValue.search}
      // // handleCreate={handleCreate}
      // // handleSubmit={handleSubmit}
      // // handleCancel={handleCancel}
      // onChangeSearch={handleChangeSearch}
      // handleSearch={handleSearch}
      >
        <div className=" py-4 border-b border-b-gray-200 gap-4 grid grid-cols-12 w-full mb-5 -mt-6">
          <div className="col-span-full">
            {totalImport !== importCount && (
              <div className="animate-pulse">
                Sedang Membuat Id Card{" "}
              </div>
            )}
          </div>
          <div className="col-span-full font-bold flex flex-row gap-2 items-center">
            <div className={`${isOnline ? "text-green-500" : "text-gray-500"}`}>
              <Icon icon="material-symbols:circle" />
            </div>{" "}
            Master ID Card Peserta
          </div>
          <div className="col-span-full text-right flex items-center justify-end h-full">
            <div className="flex flex-row w-full  md:mr-2 justify-between gap-2 items-center">
              <div>
                <Search
                  placeholder={"Cari"}
                  onChange={(value) => handleChangeSearch(value)}
                  handleSearch={handleSearch}
                  value={pageValue.search}
                />
              </div>
              <div>
                {setting && setting?.kunci_fetching ? (
                  <Button
                    color="danger"
                    size="md"
                    disabled
                  >
                    Fetching Dikunci
                  </Button>
                ) : (
                  <>

                    <Button
                      color="info"
                      disabled={totalImport !== importCount}
                      onClick={() => createIdCard(false)}
                    >
                      <Icon icon="line-md:downloading-loop" fontSize={"24"} />
                      Create ID Card
                    </Button>
                    <Button
                      color="danger"
                      disabled={totalImport !== importCount}
                      onClick={() => createIdCard(true)}
                      className="ml-4"
                    >
                      <Icon icon="line-md:downloading-loop" fontSize={"24"} />
                      Create ID Card Force
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {successCouting && (
          <div className="mb-5">
            <Alert color="success" variant="tonal">
              Import data successfully
            </Alert>
          </div>
        )}
        {showCounting && (
          <div>
            <div className="mb-0.5">
              Import Progress {importCount} / {totalImport}
            </div>
            <div className="w-full bg-gray-200 rounded-full h-1.5 mb-4 dark:bg-gray-700">
              <div
                className="bg-blue-600 h-1.5 rounded-full dark:bg-blue-500"
                style={{
                  width: `${totalImport === 0 ? 0 : (importCount / totalImport) * 100
                    }%`,
                }}
              ></div>
            </div>
          </div>
        )}
        <Tables className="border-separate rounded-sm">
          <Tables.Head className="bg-[#efeeee]">
            <tr>
              <Tables.Header className="font-bold text-[13px] py-[10px] text-right">
                #
              </Tables.Header>
              <Tables.Header className="font-bold text-[13px] py-[10px]">
                Thumbnail
              </Tables.Header>
              <Tables.Header className="font-bold text-[13px] py-[10px]">
                Nama
              </Tables.Header>
              <Tables.Header className="font-bold text-[13px] py-[10px]">
                Perusahaan
              </Tables.Header>
              <Tables.Header className="font-bold text-[13px] py-[10px]">
                IDCard
              </Tables.Header>
            </tr>
          </Tables.Head>
          <Tables.Body>
            {listData.isLoading &&
              Array(16)
                .fill(0)
                .map((_, i) => {
                  return (
                    <Tables.Row className="animate-pulse" key={i}>
                      <Tables.Data
                        className="text-right"
                        style={{ textAlign: "right" }}
                      >
                        <div className="h-4 w-[10px] bg-slate-200 rounded"></div>
                      </Tables.Data>
                      <Tables.Data>
                        <div className="h-4 bg-slate-200 rounded"></div>
                      </Tables.Data>
                      <Tables.Data>
                        <div className="h-4 bg-slate-200 rounded"></div>
                      </Tables.Data>
                      <Tables.Data>
                        <div className="h-4 bg-slate-200 rounded"></div>
                      </Tables.Data>
                      <Tables.Data>
                        <div className="h-4 bg-slate-200 rounded"></div>
                      </Tables.Data>
                    </Tables.Row>
                  );
                })}
            {!listData.isLoading &&
              listData?.data?.map((post, index) => {
                return (
                  <Tables.Row
                    key={index}
                    className={`${post.is_lock && "row-lock"}`}
                  >
                    <Tables.Data className="text-right align-top">
                      {pageValue.offset + index + 1}
                    </Tables.Data>
                    <Tables.Data className="align-top">
                      <div className="flex flex-row gap-5">
                        <div>
                          <img
                            src={post.thumbnail || default_user}
                            alt="thumb"
                            className="h-[60px] object-contain rounded"
                            onError={(e) => (e.target.src = default_user)}
                          />
                        </div>
                      </div>
                    </Tables.Data>
                    <Tables.Data className="align-top">
                      <div>{post.nama}</div>
                    </Tables.Data>
                    <Tables.Data className="align-top">
                      <div className="flex flex-col items-start">
                        <div>{post.perusahaan_display}</div>
                      </div>
                    </Tables.Data>
                    <Tables.Data className="align-top">
                      <div className="mt-1">
                        <a
                          className="bg-blue-500 text-white px-4 py-2 rounded mt-3"
                          href={`${post.idcard}`}
                          target="blank"
                        >
                          ID Card
                        </a>
                      </div>
                    </Tables.Data>
                  </Tables.Row>
                );
              })}
          </Tables.Body>
        </Tables>
        <div className="mt-5">
          <PagePagination
            data={pageValue.obj}
            handlePagination={handlePaginate}
            page={pageValue.page}
            lastPage={pageValue.lastPage}
          />
        </div>
      </Card>
    </div>
  );
};

export default IDCardPage;
