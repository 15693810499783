import { useCallback, useContext, useEffect, useState } from "react";
import { useGlobalFunction } from "../../../../generalFunction";
import api from "../../../../api";
import config from "../../../../config";
import { ThemeContext } from "../../../../context/ThemeContext";
import { pageObj } from "../../../../pageObj";
import { useNavigate } from "react-router-dom";

export function useRegistrasiDetail() {
    const kontakId = sessionStorage.getItem('kontakId');
    const { showLoading, closeLoading, catchFetching, alertValue, setAlertValue, handleCloseAlert, modalValue, setModalValue, handleCloseModal } = useGlobalFunction();
    const { setTitle } = useContext(ThemeContext);
    const navigate = useNavigate();
    const [info, setInfo] = useState({
        isLoading: false,
        data: null
    });
    const [akta, setAkta] = useState(null);
    const [pernyataan, setPernyataan] = useState(null);
    const [kuasa, setKuasa] = useState(null);
    const [foto, setFoto] = useState(null)
    const [view, setView] = useState('dokumen')

    const [listApproveHistory, setListApproveHistory] = useState({
        data: [],
        obj: [],
        lastPage: 1,
        isLoading: false,
        offset: 0,
        page: 1,
        search: ''
    });
    const [listEmailHistory, setListEmailHistory] = useState({
        data: [],
        obj: [],
        lastPage: 1,
        isLoading: false,
        offset: 0,
        page: 1,
        search: ''
    });
    const [formEmail, setFormEmail] = useState({
        kontak_id: {
            name: 'kontak_id',
            label: 'Kontak_id',
            value: '',
            type: 'text',
            col: 2,
            required: true,
            show: false,
            errorMsg: '',
            showError: false,
            flexType: 'row'
        },
        tipe: {
            name: 'tipe',
            label: 'Tipe',
            value: '',
            type: 'text',
            col: 2,
            required: true,
            show: false,
            errorMsg: '',
            showError: false,
            flexType: 'row'
        },
        alasan: {
            name: 'alasan',
            label: 'Alasan',
            value: '',
            type: 'editor',
            col: 2,
            required: true,
            show: true,
            errorMsg: '',
            showError: false,
            flexType: 'row'
        },
    })

    const [loadingDokumen, setLoadingDokumen] = useState(false);
    const [loadingAkta, setLoadingAkta] = useState(false);
    const [loadingFoto, setLoadingFoto] = useState(false);

    useEffect(() => {
        setTitle('Detail Peserta')
    })

    const fetchInfo = useCallback(async (id = 0) => {
        setInfo(info => ({ ...info, isLoading: true }));
        showLoading();
        try {
            const res = await api.get(`${config.endPoint.dataRegistrasi}/${id}/`).then(res => res.data);
            if (res) {
                setInfo(info => ({ ...info, isLoading: true, data: res.results }));
                setAkta(res.results.akta_url);
                setPernyataan(res.results.surat_pernyataan_url);
                setKuasa(res.results.surat_kuasa_url)
                setFoto(res.results.foto_url)
            } else {
                setInfo(info => ({ ...info, isLoading: false }));
            }
            closeLoading();
        } catch (error) {
            closeLoading();
            setInfo(info => ({ ...info, isLoading: false }));
            catchFetching(error, 'admin')
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Unable to fetch data', color: 'danger' }))
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }))
            }, config.timeOutValue)
        }
        // eslint-disable-next-line
    }, [])

    const fetchHistoryApprove = useCallback(async (kontak = '', q = '', offset = 0, limit = config.itemPerPage) => {
        setListApproveHistory(listApproveHistory => ({
            ...listApproveHistory,
            isLoading: true
        }));
        showLoading();
        try {
            let params = {
                offset: offset,
                limit: limit,
                kontak: kontak
            }
            if (q) {
                params['q'] = q;
            }
            const res = await api.get(`${config.endPoint.approveLog}`, { params: params }).then(res => res.data);
            if (res) {
                setListApproveHistory(listApproveHistory => ({
                    ...listApproveHistory,
                    data: res.results,
                    isLoading: false,
                    obj: pageObj(res.count, limit, offset),
                    lastPage: Math.ceil(parseInt(res.count) / parseInt(limit))
                }))
            } else {
                setListApproveHistory(listApproveHistory => ({
                    ...listApproveHistory,
                    isLoading: false
                }));
            }
            closeLoading();
        } catch (error) {
            closeLoading();
            setListApproveHistory(listApproveHistory => ({
                ...listApproveHistory,
                isLoading: false
            }));

            let msg = 'Unable to save Data';
            let { response } = error;

            catchFetching(error, 'admin')
            if (response && response.data && response.data.message) {
                msg = response.data.message
            }
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }));
            }, config.timeOutValue)
        }
        // eslint-disable-next-line
    }, [setAlertValue])

    const fetchHistoryEmail = useCallback(async (kontak = '', q = '', offset = 0, limit = config.itemPerPage) => {
        setListEmailHistory(listEmailHistory => ({ ...listEmailHistory, isLoading: true }));
        showLoading();
        try {
            let params = {
                offset: offset,
                limit: limit,
                kontak: kontak
            }
            if (q) {
                params['q'] = q;
            }
            const res = await api.get(`${config.endPoint.emailLog}`, { params: params }).then(res => res.data);
            if (res) {
                setListEmailHistory(listEmailHistory => ({
                    ...listEmailHistory,
                    data: res.results,
                    isLoading: false,
                    obj: pageObj(res.count, limit, offset),
                    lastPage: Math.ceil(parseInt(res.count) / parseInt(limit))
                }));
            } else {
                setListEmailHistory(listEmailHistory => ({ ...listEmailHistory, isLoading: false }));
            }
            closeLoading();
        } catch (error) {
            closeLoading();
            setListEmailHistory(listEmailHistory => ({ ...listEmailHistory, isLoading: false }));

            let msg = 'Unable to save Data';
            let { response } = error;

            catchFetching(error, 'admin')
            if (response && response.data && response.data.message) {
                msg = response.data.message
            }
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }));
            }, config.timeOutValue)
        }
        // eslint-disable-next-line
    }, [setAlertValue])

    useEffect(() => {
        if (kontakId) {
            fetchInfo(kontakId);
            // fetchHistoryApprove(kontakId);
            // fetchHistoryEmail(kontakId);
        }
    }, [fetchInfo, kontakId])

    const handlePaginateHistoryApprove = (page) => {
        let myOffset = (parseInt(page) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
        setListApproveHistory(listApproveHistory => ({
            ...listApproveHistory,
            page: page,
            offset: myOffset
        }))
        fetchHistoryApprove(info.data.id, listApproveHistory.search, myOffset, config.itemPerPage);
    }

    const handleChangeView = (params) => {
        setView(params);
        if (params === 'email') {
            fetchHistoryEmail(info?.data?.id)
        }
        if (params === 'approval') {
            fetchHistoryApprove(info?.data?.id)
        }
        if (params === 'dokumen') {
            fetchInfo(info?.data?.id)
        }
    }

    const handleReject = async (info, status, type) => {
        setFormEmail(formEmail => ({
            ...formEmail,
            kontak_id: { ...formEmail.kontak_id, value: info.id },
            tipe: { ...formEmail, value: type },
            alasan: { ...formEmail.alasan, value: '' },
        }))
        // setAlasanModal(alasanModal => ({...alasanModal,
        //     show : true, 
        // }))
        setModalValue(modalValue => ({ ...modalValue, show: true, title: info?.nama }))
    }



    const handleActionApprove = async (params, type) => {

        if (type === 'akta') {
            setLoadingAkta(true);
        } else if (type === 'foto') {
            setLoadingFoto(true);
        } else {
            setLoadingDokumen(true);
        }


        try {
            let tmp = { ...params }
            tmp['tipe'] = type
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmp))

            const res = await api.post(`${config.endPoint.actionApproveDoc}`, newForm).then(res => res.data);
            if (res) {
                // handleView({perusahaan : res.perusahaan, kontak : res.kontak})
                // fetchInfo(res.kontak);
                let tmp = { ...info.data }
                if (type === 'akta') {
                    tmp['status_akta'] = res.results.status_akta
                    tmp['status_akta_display'] = res.results.status_akta_display
                    tmp['detail_display']['alasan_akta'] = res?.results?.detail_display?.alasan_akta
                } else if (type === 'surat_kuasa') {
                    tmp['detail_display']['status_surat_kuasa'] = res?.results?.detail_display?.status_surat_kuasa
                    tmp['detail_display']['status_surat_kuasa_display'] = res?.results?.detail_display?.status_surat_kuasa_display
                    tmp['detail_display']['alasan_surat_kuasa'] = res?.results?.detail_display?.alasan_surat_kuasa
                } else if (type === 'surat_pernyataan') {
                    tmp['detail_display']['status_surat_pernyataan'] = res?.results?.detail_display?.status_surat_pernyataan
                    tmp['detail_display']['status_surat_pernyataan_display'] = res?.results?.detail_display?.status_surat_pernyataan_display
                    tmp['detail_display']['alasan_surat_pernyataan'] = res?.results?.detail_display?.alasan_surat_kuasa
                } else if (type === 'foto') {
                    tmp['detail_display']['status_foto'] = res?.results?.detail_display?.status_foto
                    tmp['detail_display']['status_foto_display'] = res?.results?.detail_display?.status_foto_display
                    tmp['detail_display']['alasan_foto'] = res?.results?.detail_display?.alasan_foto
                }
                setInfo(info => ({ ...info, data: tmp }))
            }
            if (type === 'akta') {
                setLoadingAkta(false);
            } else if (type === 'foto') {
                setLoadingFoto(false);
            } else {
                setLoadingDokumen(false);
            }
        } catch (error) {
            if (type === 'akta') {
                setLoadingAkta(false);
            } else if (type === 'foto') {
                setLoadingFoto(true);
            } else {
                setLoadingDokumen(false);
            }
            let msg = 'Unable to save Data';
            let { response } = error;

            catchFetching(error, 'admin')
            if (response && response.data && response.data.message) {
                msg = response.data.message
            }
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }));
            }, config.timeOutValue)

        }
    }

    const handleChangeAlasanModal = (e) => {
        const { name, value } = e.target;
        // setAlasanModal(alasanModal => ({...alasanModal, [name] : value}))
        setFormEmail(formEmail => ({
            ...formEmail,
            [name]: { ...formEmail[name], value: value }
        }))
    }

    const handleSubmitReject = async () => {
        if (formEmail.tipe.value === 'akta') {
            setLoadingAkta(true);
        } else if (formEmail.tipe.value === 'foto') {
            setLoadingFoto(true);
        } else {
            setLoadingDokumen(true);
        }
        try {
            let countError = 0;
            let obj = { ...formEmail };
            let tmpForm = {}
            Object.entries(obj).map(([index, post]) => {
                if (post.required && post.value === '') {
                    countError++;
                    post['showError'] = true;
                } else {
                    post['showError'] = false;
                    tmpForm[index] = post.value;
                }
                return true;
            })

            if (countError > 0) {
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Lengkapi data', color: 'danger' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false, text: 'Lengkapi data', color: 'danger' }));
                }, config.timeOutValue)
                if (formEmail.tipe.value === 'akta') {
                    setLoadingAkta(false);
                } else if (formEmail.tipe.value === 'foto') {
                    setLoadingFoto(false);
                } else {
                    setLoadingDokumen(false);
                }
                return;
            }
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmpForm));
            const res = await api.post(`${config.endPoint.actionRejectDoc}`, newForm).then(res => res.data);
            if (res) {
                if (res.results) {
                    let tmp = { ...info.data }
                    if (formEmail.tipe.value === 'akta') {
                        tmp['status_akta'] = res.results.status_akta
                        tmp['status_akta_display'] = res.results?.status_akta_display
                        tmp['detail_display']['alasan_akta'] = res?.results?.detail_display?.alasan_akta
                    } else if (formEmail.tipe.value === 'surat_kuasa') {
                        tmp['detail_display']['status_surat_kuasa'] = res?.results?.detail_display?.status_surat_kuasa
                        tmp['detail_display']['status_surat_kuasa_display'] = res?.results?.detail_display?.status_surat_kuasa_display
                        tmp['detail_display']['alasan_surat_kuasa'] = res?.results?.detail_display?.alasan_surat_kuasa
                    } else if (formEmail.tipe.value === 'surat_pernyataan') {
                        tmp['detail_display']['status_surat_pernyataan'] = res?.results?.detail_display?.status_surat_pernyataan
                        tmp['detail_display']['status_surat_pernyataan_display'] = res?.results?.detail_display?.status_surat_pernyataan_display
                        tmp['detail_display']['alasan_surat_pernyataan'] = res?.results?.detail_display?.alasan_surat_kuasa
                    } else if (formEmail.tipe.value === 'foto') {
                        tmp['detail_display']['status_foto'] = res?.results?.detail_display?.status_foto
                        tmp['detail_display']['status_foto_display'] = res?.results?.detail_display?.status_foto_display
                        tmp['detail_display']['alasan_foto'] = res?.results?.detail_display?.alasan_foto
                    }
                    setInfo(info => ({ ...info, data: tmp }))
                }
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: "Berhasil", subText: 'Email telah dikirim', color: 'success' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue);
                handleCloseModal();
                fetchInfo(kontakId);
            }
            if (formEmail.tipe.value === 'akta') {
                setLoadingAkta(false);
            } else if (formEmail.tipe.value === 'foto') {
                setLoadingFoto(false);
            } else {
                setLoadingDokumen(false);
            }
        } catch (error) {
            if (formEmail.tipe.value === 'akta') {
                setLoadingAkta(false);
            } else if (formEmail.tipe.value === 'foto') {
                setLoadingFoto(false);
            } else {
                setLoadingDokumen(false);
            }

            let msg = 'Unable to save Data';
            let { response } = error;

            catchFetching(error, 'admin')
            if (response && response.data && response.data.message) {
                msg = response.data.message
            }
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }));
            }, config.timeOutValue)
        }
    }

    const handleCancel = () => {
        navigate('/admin/registrasi/data-registrasi-peserta')
    }

    return {
        showLoading, closeLoading, info, alertValue, handleCloseAlert, handleChangeView, view, akta, kuasa, pernyataan,
        formEmail, handleReject, handleActionApprove, loadingDokumen, modalValue, handleCloseModal, setModalValue, handleChangeAlasanModal,
        handleSubmitReject, loadingAkta, listApproveHistory, handlePaginateHistoryApprove,
        listEmailHistory, handleCancel, setFormEmail, loadingFoto, foto
    }
}