import { useCallback, useContext, useEffect, useState } from "react";
import { useGlobalFunction } from "../../../../generalFunction";
import api from "../../../../api";
import config from "../../../../config";
import { pageObj } from "../../../../pageObj";
import { ThemeContext } from "../../../../context/ThemeContext";


export function useUser(){
    const { isLoading, showLoading, closeLoading, setIsLoading,
        modalValue, setModalValue, handleCloseModal,
        pageValue, setPageValue, alertValue, setAlertValue, handleCloseAlert,
        catchFetching
    } = useGlobalFunction();
    const [isEdit, setIsEdit] = useState(false);
    const [listGroup, setListGroup] = useState({isLoading: true, data : []});
    const [selectedGroup, setSelectedGroup] = useState([]);
    const { setTitle } = useContext(ThemeContext);
    const [listData, setListData] = useState([
        {
            id : 1,
            nama : 'Hotel Alila',            
            perioritas : 1,
        }
    ]);
    const [view, setView] = useState('list');
    const [formData, setFormData] = useState({
        first_name : {
            name : 'first_name',
            value : '',
            type : 'text',
            show : true,
            label : 'Name',
            errorMsg : '',
            showError : false,
            required : true,            
        },
        username : {
            name : 'username',
            value : '',
            type : 'text',
            show : true,
            label : 'Username',
            errorMsg : '',
            showError : false,
            required : true,            
        },
        password : {
            name : 'password',
            value : '',
            type : 'password',
            show : true,
            label : 'Password',
            inputType : 'password',
            errorMsg : '',
            showError : false,
            required : true,
            showPassword : false,
        },
        confirm_password : {
            name : 'confirm_password',
            value : '',
            type : 'password',
            show : true,
            label : 'Confirm Password',
            inputType : 'password',
            errorMsg : '',
            showError : false,
            required : true,
            showPassword : false,
        },
        group_permissions : {
            name : 'group_permissions',
            value : '',
            type : 'select',
            show : true,
            label : 'Group Permissions',            
            errorMsg : '',
            showError : false,
            required : false,
            showPassword : false,
            obj : []
        }
    });

    const fetchData = useCallback(async(q='', offset=0, limit=config.itemPerPage) => {
        showLoading()
        try{
            const params = {
                offset : offset,
                limit : limit,
            }
            if (q) {
                params['q'] = q
            }
            const res = await api.get(`${config.endPoint.user}`, {params: params}).then(res => res.data);
            if (res){
                setListData(listData => ({...listData, isLoading: false, data: res.results}))
                setPageValue(pageValue => ({...pageValue,
                    obj : pageObj(res.count, limit, offset),
                    lastPage : Math.ceil(parseInt(res.count) / parseInt(limit))
                }))
            }else{
                setListData(listData => ({...listData, isLoading: false}))
            }
            closeLoading()
        }catch(error){
            setListData(listData => ({...listData, isLoading: false}))
            closeLoading()
            const { response } = error;
            let msg = 'Unable to fetch data'
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            setAlertValue(alertValue => ({...alertValue,
                show: true,
                text : 'Error',
                subText: msg,
                color: 'danger'
            }))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
        // eslint-disable-next-line 
    },[])

    useEffect(() => {
        setTitle('Users')
    }, [setTitle])
    
    useEffect(() => {
        fetchData();
    },[fetchData])

    const fetchGroup = useCallback(async(q='', offset=0, limit=config.itemPerPage) => {
        showLoading()
        setListGroup(listGroup => ({...listGroup, isLoading: true}))
        try{
            const params = {
                offset : offset,
                limit : limit,
            }
            if (q) {
                params['q'] = q
            }
            const res = await api.get(`${config.endPoint.permissionGroup}`, {params: params}).then(res => res.data);
            if (res){
                setListGroup(listGroup => ({...listGroup, isLoading: false, data: res.results}))                
            }else{
                setListGroup(listGroup => ({...listGroup, isLoading: false}))
            }
            closeLoading()
        }catch(error){
            setListGroup(listGroup => ({...listGroup, isLoading: false}))
            closeLoading()
            const { response } = error;
            let msg = 'Unable to fetch data'
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            setAlertValue(alertValue => ({...alertValue,
                show: true,
                text : 'Error',
                subText: msg,
                color: 'danger'
            }))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
        // eslint-disable-next-line 
    },[])
    
    

    const handleSubmit = async() => {
        try{
            let obj = {...formData}
            let countError = 0;
            let tmp = {}
            Object.entries(obj).map(([index, post]) => {
                if (post.required && post.value === ''){
                    post.showError= true;
                    countError++;
                    console.log('error is >>>', post);
                }else{
                    tmp[index] = post.value
                }
                    return true;
            })
            
            if (countError > 0){
                setAlertValue(alertValue => ({...alertValue,
                    show: true,
                    text : 'Error',
                    subText: 'Lengkapi data',
                    color: 'danger'
                }))
                setIsLoading(false);
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue)
                return;
            }
            tmp['group_permissions'] = selectedGroup
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmp));
            
            let res;
            if (isEdit){
                res = await api.put(`${config.endPoint.user}${formData.id.value}/`, newForm).then(res => res.data);
            }else{
                res = await api.post(`${config.endPoint.user}`, newForm).then(res => res.data);
            }
            if (res){
                setAlertValue(alertValue => ({...alertValue,
                    show: true,
                    text : 'Success',
                    subText: 'Data berhasil disimpan',
                    color: 'success'
                }))
            
                fetchData(pageValue.search, pageValue.offset, config.itemPerPage)
            
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue)
            }
            setView('list');
        }catch(error){
            const { response } = error;
            let msg = 'Unable to fetch data'
            catchFetching(error, 'admin');
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            setAlertValue(alertValue => ({...alertValue,
                show: true,
                text : 'Error',
                subText: msg,
                color: 'danger'
            }))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }

    const handleDelete = (post) => {
        setModalValue(modalValue => ({...modalValue,
            show: true,
            id : post.id,
            text : `Apakah anda yakin ingin menghapus hotel ${post.nama} ?`,
        }))
    }

    const handleSubmitDelete = async() => {

    }

    const handleCreate = () => {
        setView('create');
        fetchGroup();
        setIsEdit(false);
        setFormData(formData => ({...formData,
            id : {...formData.id, value : ''},
            first_name : {...formData.first_name, value : ''},
            username : {...formData.username, value : ''},            
            confirm_password : {...formData.confirm_password, value : '', required: true},            
            password : {...formData.password, value : '', required: true},
        }));
        setSelectedGroup([])
    }

    const handleCancel = () => {
        setView('list');
    }

    const handleEdit = (post) => {
        fetchGroup();
        setView('create');
        setIsEdit(true);
        setFormData(formData => ({...formData,
            id : {...formData.id, value : post.id},
            first_name : {...formData.first_name, value : post.first_name},
            username : {...formData.username, value : post.username},  
            password : {...formData.password, required: false},
            confirm_password : {...formData.confirm_password, required: false},
        }));
        setSelectedGroup(post.list_group);
    }

    const handleSelectGroup = (post) => {
        let tmp = [...selectedGroup];
        const index = tmp.findIndex(x => x === post.id);
        if (index > -1){
            tmp.splice(index, 1);
        }else{
            tmp.push(post.id)
        }
        setSelectedGroup(tmp);
    }

    const handlePaginate = (page) => {
        let myOffset = (parseInt(page) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
        setPageValue(pageValue => ({...pageValue, page : page, offset: myOffset}))
        fetchData(pageValue.search, myOffset, config.itemPerPage);
    }

    const handleChangeSearch = (e) => {
        const { name, value } = e.target;
        setPageValue(pageValue => ({...pageValue, [name] : value}))
    }
    
    const handleKeyDownSearch = (e) => {
        if (e.key === 'Enter'){
            handleSearch();
        }
    }
    
    const handleSearch = () => {
        fetchData(pageValue.search, 0, config.itemPerPage)
    }

    return {
        isLoading, showLoading, closeLoading, setIsLoading, listData,
        formData, setFormData,  setListData, handleSubmit,
        handleDelete, modalValue, setModalValue, handleCloseModal, handleSubmitDelete,
        pageValue, setPageValue, view,
        handleCreate, handleCancel, alertValue, setAlertValue, handleCloseAlert, handleEdit, listGroup,
        selectedGroup, setSelectedGroup, handleSelectGroup, handlePaginate,
        handleChangeSearch, handleKeyDownSearch, handleSearch
    }
}