import React, { useEffect, useReducer, useRef, useState } from "react";
import ActivityLogPage from "../views/Admin/Registrasi/ActivityLog/ActivityLogPage";
import EmailLogPage from "../views/Admin/Registrasi/EmailLog/EmailLogPage";
import KategoriPanitiaPage from "../views/Admin/Master/KategoriPanitia/KategoriPanitiaPage";
import config from "../config";
// import Cookies from "universal-cookie";
import { useCookies } from "react-cookie";

const UserContext = React.createContext([{}, () => { }]);

const UserProvider = (props) => {
  //setCookie jangan di delete yaa
  //eslint-disable-next-line
  const [cookies, setCookie, removeCookie] = useCookies(config.cookiesName);
  const getcookie = cookies[config.cookiesName];
  const [pilihanKetum, setPilihanKetum] = useState(null);
  const [pilihanDewas, setPilihanDewas] = useState([]);
  const [showHeader, setShowHeader] = useState(true);
  const [myLoading, setMyLoading] = useState(false);
  const [listAbsen, setListAbsen] = useState({ data: [], isLoading: false });
  const [canRegister, setCanRegister] = useState('');
  const [myPage, setMyPage] = useState(0);
  const [isSuperUser, setIsSuperUser] = useState('');
  const sessionCookieTime = 60; // satuan Menit
  const activityTimeout = useRef(null);
  const countdownInterval = useRef(null);
  const [remainingTime, setRemainingTime] = useState(0);

  const modalReducer = (state, action) => {
    switch (action.type) {
      case "OPEN":
        return {
          isOpen: true,
          text: action.text,
          size: action.size,
          content: action.content,
          title: action.title,
          onClose: action.onClose,
        };
      case "APPROVAL_LOG_PAGE":
        return {
          isOpen: true,
          title: action.title,
          content: (
            <ActivityLogPage
              onClickTr={action.clickTr}
              isModal={true}
              kontakId={action.kontakId}
            />
          ),
          onClose: action.onClose,
          size: action.size,
        };
      case "KATEGORI_PANITIA":
        return {
          isOpen: true,
          title: action.title,
          content: (
            <KategoriPanitiaPage
              onClickTr={action.clickTr}
              isModal={true}
              kontakId={action.kontakId}
            />
          ),
          onClose: action.onClose,
          size: action.size,
        };
      case "EMAIL_LOG":
        return {
          isOpen: true,
          title: action.title,
          content: (
            <EmailLogPage
              onClickTr={action.clickTr}
              isModal={true}
              kontakId={action.kontakId}
              perusahaanId={action.perusahaanId}
            />
          ),
          onClose: action.onClose,
          size: action.size,
        };
      case "CLOSE":
        return {
          isOpen: false,
          text: "",
          size: null,
          content: null,
          title: "",
        };
      default:
        return {
          isOpen: false,
          text: "",
          size: "lg",
          content: null,
          title: "",
          onClose: null,
        };
    }
  };

  const [modalState, modalDispatch] = useReducer(modalReducer, {
    isOpen: false,
    text: "",
    size: "lg",
    content: null,
    title: "",
    onClose: null,
  });

  useEffect(() => {
    if (getcookie) {
      const resetActivityTimer = () => {
        // Reset timer dan countdown setiap ada aktivitas
        clearTimeout(activityTimeout.current);
        clearInterval(countdownInterval.current); // Hentikan timer countdown
        setRemainingTime(sessionCookieTime * 60); // Set waktu awal countdown

        // Buat timer baru untuk logout otomatis
        activityTimeout.current = setTimeout(() => {
          // Logout setelah periode tidak ada aktivitas
          removeCookie(config.cookiesName, { path: "/" });
        }, sessionCookieTime * 60 * 1000);

        // Mulai countdown timer baru
        countdownInterval.current = setInterval(() => {
          setRemainingTime((prev) => {
            if (prev <= 1) {
              clearInterval(countdownInterval.current);
              return 0;
            }
            return prev - 1;
          });
        }, 1000);
      };

      const events = ["mousemove", "keydown", "scroll"];
      events.forEach((e) => window.addEventListener(e, resetActivityTimer));

      // Jalankan resetActivityTimer pertama kali
      resetActivityTimer();

      // Cleanup saat komponen tidak dipakai
      return () => {
        clearTimeout(activityTimeout.current);
        clearInterval(countdownInterval.current);
        events.forEach((e) =>
          window.removeEventListener(e, resetActivityTimer)
        );
      };
    }
  }, [getcookie, removeCookie]);

  useEffect(() => {
    if (remainingTime > 0) {
      //debug waktu session
      // console.log(`Waktu tersisa sebelum logout: ${remainingTime} detik`);
    }
  }, [remainingTime]);

  return (
    <UserContext.Provider
      value={{
        pilihanKetum: [pilihanKetum, setPilihanKetum],
        pilihanDewas: [pilihanDewas, setPilihanDewas],
        showHeader: [showHeader, setShowHeader],
        myLoading: [myLoading, setMyLoading],
        modalState: [modalState, modalDispatch],
        myPage: [myPage, setMyPage],
        listAbsen: [listAbsen, setListAbsen],
        canRegister: [canRegister, setCanRegister],
        isSuperUser: [isSuperUser, setIsSuperUser],
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
