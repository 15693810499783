import { Icon } from "@iconify/react/dist/iconify.js";
import { useLoginMember } from "./loginMemberFunction";

import "flipping-pages/dist/style.css";
import { Fragment } from "react";
import AlertComp from "../../../../Components/Atoms/AlertComp";
import logo_munas from "../../../../assets/images/munas.png";
import Loading from "../../../../Components/Atoms/Loading";

const LoginMemberPage = () => {
  const {
    formData,
    handleChange,
    handleBlur,
    handleShowPassword,
    handleKeyDown,
    isLoading,
    handleSubmit,
    alertValue,
    handleCloseAlert,
    canRegister,
    handleRegister,
    handleForgotPassword,
  } = useLoginMember();



  return (
    <Fragment>
      <AlertComp
        color={alertValue.color}
        isOpen={alertValue.show}
        text={alertValue.text}
        subText={alertValue.subText}
        onClose={handleCloseAlert}
      />

      {canRegister === '' ?
        <div className='w-full h-full min-h-screen flex flex-row justify-center items-center'>
          <Loading
            variant={"Bars"}
          />
        </div>
        :
        <>
          {canRegister ?
            <div className="w-full h-full flex flex-col justify-center rounded-lg">
              <div className="block md:hidden mb-8">
                <img
                  src={logo_munas}
                  alt="logo-munas"
                  className="h-[200px] mx-auto"
                />
              </div>
              <div className="font-bold text-[28px] ">Selamat Datang</div>
              <div className="text-[16px] font-[400]">Silakan masuk ke akun anda</div>
              <div className="mt-[37px]">
                <div className="relative">
                  <div className="absolute text-[#868AA5] text-[20px] mt-4 ml-4">
                    <Icon icon="teenyicons:envelope-solid" />
                  </div>
                  <input
                    className="border  rounded-lg pl-[50px] w-full py-3 text-left leading-tight 
                                    focus:outline-1 
                                    focus:outline-none bg-[#F5F5F7]
                                    block focus:border-none"
                    onKeyDown={handleKeyDown}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name={formData.username.name}
                    value={formData.username.value}
                  />
                  <div className="absolute -top-[10px] left-[18px] text-[12px] bg-white px-1 text-sky-600">
                    Username
                  </div>
                </div>

                <div className="relative w-full border-2 rounded-lg border-sky-600 mt-7">
                  <input
                    className="border  rounded-lg pl-[40px] w-full py-3 text-left leading-tight                                         
                                    focus:outline-none bg-gray-50
                                    focus:border-none"
                    onKeyDown={handleKeyDown}
                    type={formData.password.type}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name={formData.password.name}
                    value={formData.password.value}
                  />
                  <div className="absolute -top-[10px] left-[18px] text-[12px] bg-white px-1 text-sky-600">
                    Password
                  </div>
                  <div className="absolute top-[13px] left-[10px]">
                    <Icon icon="ph:key" className="text-gray-400 text-[20px]" />
                  </div>
                  <button
                    disabled={isLoading}
                    className="absolute top-[13px] right-[10px]"
                    onClick={() => handleShowPassword(formData.password.name)}
                  >
                    <Icon
                      icon={
                        formData.password.showPassword
                          ? "ph:eye-closed-bold"
                          : "ph:eye-bold"
                      }
                      className="text-gray-400 text-[20px]"
                    />
                  </button>
                </div>
                <div className="flex flex-row justify-end mt-2">
                  <button
                    disabled={isLoading}
                    className="text-blue-600"
                    onClick={handleForgotPassword}
                  >
                    Lupa Password ?
                  </button>
                </div>
              </div>
              <div className="mt-[37px]">
                <button
                  className="bg-[#185E9E] text-white text-[16px] 2xl:text-[18px] py-[10px] 2xl:py-[16.5px] px-[18.5px] font-semibold rounded-[8px] flex flex-row items-center"
                  onClick={handleSubmit}
                  disabled={isLoading}
                >
                  <div>Masuk</div>
                  {isLoading ? (
                    <svg
                      aria-hidden="true"
                      class="ml-[43px] w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  ) : (
                    <div className="ml-[43px]">
                      <Icon icon="octicon:chevron-right-12" />
                    </div>
                  )}
                </button>
              </div>
              {canRegister && (
                <div className="mt-0 w-full">
                  <div class="relative flex py-5 items-center">
                    <div class="flex-grow border-t border-gray-400"></div>
                    <span class="flex-shrink mx-4 text-gray-400">OR</span>
                    <div class="flex-grow border-t border-gray-400"></div>
                  </div>

                  <div className="w-full flex flex-row justify-end">
                    <button
                      className="bg-[#185E9E] text-white text-[16px] 2xl:text-[18px] py-[10px] 2xl:py-[16.5px] px-[18.5px] font-semibold rounded-[8px] flex flex-row   items-center"
                      onClick={handleRegister}
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <svg
                          aria-hidden="true"
                          class="mr-[43px] w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                      ) : (
                        <div className="mr-[43px]">
                          <Icon icon="octicon:chevron-left-12" />
                        </div>
                      )}
                      <div>Daftar</div>
                    </button>
                  </div>
                </div>
              )}
            </div>
            :
            <div className='w-full flex flex-col justify-center items-center'>
              <div className="font-bold text-[28px] text-center mx-auto">Pendaftaran  ditutup</div>
            </div>
          }
        </>
      }


    </Fragment>
  );

};
export default LoginMemberPage;
