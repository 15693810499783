import { Fragment, useContext, useEffect } from "react"
import PerusahaanComp from "./Component/PerusahaanComp";
import HotelDashboardComp from "./Component/HotelDashboardComp";
import { ThemeContext } from "../../../context/ThemeContext";


const HomeAdmin = () => {
    const { setTitle } = useContext(ThemeContext);

    useEffect(() => {
        setTitle('Dashboard')
    }, [setTitle])
    return (
        <Fragment>            
            <PerusahaanComp/>
            <HotelDashboardComp />
        </Fragment>
    )
}
export default HomeAdmin;