import { Icon } from "@iconify/react/dist/iconify.js";
import BookMarksRight from "../../Bookmarks/BookmarksRight";


const SyaratPage = ({goToPage, page}) => {
    return(
        <div
            className="page demoPage h-full overflow-y-hidden font-poppins
                w-full
            "
        >
            <div className="w-full h-full">
                <BookMarksRight goToPage={goToPage} page={page}/>
                <div 
                    className='bg-[linear-gradient(-90deg,rgba(236,236,236,1)0%,rgba(237,237,237,1)26%,rgba(250,250,250,1)77%,rgba(240,240,240,1)83%,rgba(232,232,232,1)87%,rgba(216,216,216,1)92%,rgba(200,200,200,1)96%,rgba(155,155,155,1)99%,rgba(179,179,179,1)100%)] w-full h-full'
                >
                    <div className="flex flex-col pl-[50px] pr-[42px] pb-[30px] pt-[40px] 2xl:pt-[72px]">
                        <div className="grid grid-cols-2 justify-between h-full">
                            <div className='text-[24px] font-bold col-span-1'>Syarat Dan Ketentuan Kepesertaan Musyawarah Nasional APJII 2024</div>
                            <div className='col-span-1 flex flex-row justify-end h-fit'>
                                <button className='bg-[#2194FA] text-white py-[10px] px-[20px] rounded flex flex-row gap-2 items-center'><Icon icon="uil:cloud-download" /> Download</button>
                            </div>                            
                        </div>
                        <div className='mt-[20px]'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi euismod lacus velit, sed hendrerit ante egestas in. Morbi lectus velit, sollicitudin vel lectus ut, viverra eleifend purus. Nullam sed quam sapien. Nullam id semper urna, ut euismod odio. Morbi et neque in nulla pretium mollis. Curabitur magna nibh, accumsan et diam convallis, gravida efficitur ligula. Nulla et quam risus. Fusce lacinia justo sem, ut commodo metus condimentum ac. Maecenas non tellus pellentesque, tincidunt magna a, pharetra diam. Aliquam sit amet euismod odio. Vestibulum ultrices vel magna eget aliquet. Nunc elit est, pulvinar sit amet lorem porttitor, luctus scelerisque lorem. Ut orci justo, lobortis eget commodo eu, iaculis id quam. Duis augue augue, mollis ac dictum fringilla, suscipit sit amet sapien. Nulla sit amet gravida dolor. Suspendisse nec odio mollis, vehicula neque ac, finibus sapien.

                            Cras eu maximus lectus. Curabitur cursus cursus tellus, et commodo massa volutpat sed. Nunc sollicitudin placerat mauris in semper. Suspendisse ac suscipit justo. Proin ac diam suscipit, aliquam nunc facilisis, iaculis lectus. Donec volutpat egestas sapien, eu luctus risus eleifend a. Etiam efficitur nulla dignissim odio feugiat luctus. Pellentesque ultrices rhoncus mauris, gravida efficitur felis elementum pellentesque. Aliquam erat volutpat. Duis rhoncus, ex hendrerit venenatis semper, massa nisl tempor orci, ac ultricies lectus elit ultrices orci. In ut massa nec arcu ultricies malesuada in sit amet ligula. Quisque hendrerit blandit felis id posuere. Aliquam erat volutpat. Maecenas eu porta purus. Proin eget venenatis libero. Suspendisse scelerisque, magna eu rhoncus porta, est mi egestas dolor, eu faucibus mauris ipsum ut nisi.

                            Nullam aliquet fringilla mattis. Praesent sodales urna eros, non facilisis mauris viverra nec. Quisque cursus scelerisque risus, eu dapibus ex cursus vitae. Maecenas luctus quam non augue vestibulum tristique. Duis sit amet tempor augue. Sed finibus nec ligula ac interdum. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Proin consequat, metus quis iaculis laoreet, nisl odio tincidunt justo, id scelerisque neque massa a turpis. Nulla consectetur ligula dui, quis ultricies lorem euismod vitae. Nulla ut diam vel libero auctor commodo et ut nisi. 
                        </div>
                    </div>    
                </div>
            </div>
        </div>
    )
}
export default SyaratPage;