import { useCallback, useContext, useEffect, useState } from "react";
import { useGlobalFunction } from "../../../../generalFunction";
import config from "../../../../config";
import api from "../../../../api";
import { pageObj } from "../../../../pageObj";
import { ThemeContext } from "../../../../context/ThemeContext";


export function useHapusDataRegistrasi(){
    const { showLoading, closeLoading, alertValue, setAlertValue, handleCloseAlert, modalValue, setModalValue, handleCloseModal,
        setIsLoading, pageValue, setPageValue, catchFetching, isLoading
    } = useGlobalFunction();
    const [isShowAkta, setIsShowAkta] = useState(false)
    const [isShowPernyataan, setIsShowPernyataan] = useState(false);
    const [isShowKuasa, setIsShowKuasa] = useState(false);
    const [listData, setListData] = useState({
        data : [],
        isLoading: true,        
    })
    const { setTitle } =useContext(ThemeContext);
    const fetchData = useCallback(async(q='', offset=0, limit=config.itemPerPage, status_compete='', is_lock='', show_akta=false, show_kuasa=false, show_pernyataan=false) => {
        setIsLoading(true);
        showLoading();
        setListData(listData => ({...listData, isLoading: true}));
        try{
            let params = {
                offset : offset,
                limit : limit,
                tipe : 'peserta'
            }
            if (q){
                params['q'] = q;
            }
            if (status_compete){
                params['status_complete'] = status_compete
            }
            if (is_lock){
                params['is_lock'] = is_lock                
            }
                        
            const res = await api.get(`${config.endPoint.dataRegistrasi}`, {params: params}).then(res => res.data );                        
            if (res){
                let tmp = [];
                res.results.map(post => {
                    let obj = {...post};                    
                    obj['show_alasan_akta'] = show_akta;                    
                    obj['show_alasan_pernyataan'] = show_pernyataan;
                    obj['show_alasan_kuasa'] = show_kuasa;
                    tmp.push(obj);
                    return true;
                })
                setListData(listData => ({...listData,
                    isLoading: false,
                    data : tmp
                }))
                setPageValue(pageValue => ({...pageValue,
                    obj : pageObj(res.count, limit, offset),
                    lastPage : Math.ceil(parseInt(res.count) / parseInt(limit))
                }));
                // setLockState(res.status_lock)
            }else{
                setListData(listData => ({...listData, isLoading: false}));
            }
            setIsLoading(false);
            closeLoading();
        }catch(error){
            closeLoading();
            setListData(listData => ({...listData, isLoading: false}));
            setIsLoading(false);                        
            let msg = 'Unable to save Data';
            let { response } = error;            
            
            catchFetching(error, 'admin')            
            if (response && response.data && response.data.message){
                msg = response.data.message
            }
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }));
            }, config.timeOutValue)            
        }
        // eslint-disable-next-line 
    },[setIsLoading, setAlertValue])

    useEffect(() => {
        setTitle('Hapus Data Registrasi')
    }, [setTitle])

    useEffect(() => {
        fetchData();
    },[fetchData])

    const handleDelete = (post) => {
        setModalValue(modalValue => ({...modalValue,
            show: true, 
            id : post.id, 
            text : `Apakah anda yakin akan menghapus peserta an. ${post.nama} ?`, 
            title : "Konfirmasi", 
            tipe : 'delete'
        }))
    }

    const handleSubmitDelete = async() => {
        setIsLoading(true);
        try{
            const res = await api.delete(`${config.endPoint.dataRegistrasi}/${modalValue.id}/`).then(res => res.data);
            if (res){
                fetchData(pageValue.search, pageValue.offset, config.itemPerPage, pageValue.complete, pageValue.is_lock);
                // setPageValue(pageValue => ({...pageValue, page : 1, offset : 1, }))
                handleCloseModal();
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Data dihapus', color: 'danger' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue)   
            }
            setIsLoading(false);            
        }catch(error){
            setIsLoading(false);            

            let msg = 'Unable to save Data';
            let { response } = error;            
            
            catchFetching(error, 'admin')            
            if (response && response.data && response.data.message){
                msg = response.data.message
            }
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }));
            }, config.timeOutValue)   
        }
    }

    const handlePaginate = (page) => {
        let myOffset = (parseInt(page) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
        setPageValue(pageValue => ({...pageValue, page : page, offset: myOffset}))
        fetchData(pageValue.search, myOffset, config.itemPerPage, pageValue.complete, pageValue.is_lock,  isShowAkta, isShowKuasa, isShowPernyataan);               
    }

    const handleOpenAlasanAkta = (index) => {
        let array = [...listData.data];
        array[index] = {...array[index], show_alasan_akta : !array[index].show_alasan_akta};
        setListData(listData => ({...listData,
            data : array
        })) 
    }
    
    const handleOpenAlasanPernyataan = (index) => {
        let array = [...listData.data];
        array[index] = {...array[index], show_alasan_pernyataan : !array[index].show_alasan_pernyataan};        
        setListData(listData => ({...listData,
            data : array
        })) 
    }
    
    const handleOpenAlasanKuasa = (index) => {
        let array = [...listData.data];
        array[index] = {...array[index], show_alasan_kuasa : !array[index].show_alasan_kuasa};        
        setListData(listData => ({...listData,
            data : array
        })) 
    }

    const handleChangeSearch = (e) => {
        const { name, value } = e.target;
        setPageValue(pageValue => ({...pageValue, [name] : value}))
        if (name === 'complete'){
            fetchData(pageValue.search, 0, config.itemPerPage, value, pageValue.is_lock, isShowAkta, isShowKuasa, isShowPernyataan);
            setPageValue(pageValue => ({...pageValue, page : 1, offset : 0}))
        }
        if (name === 'is_lock'){
            fetchData(pageValue.search, 0, config.itemPerPage, pageValue.complete, value, isShowAkta, isShowKuasa, isShowPernyataan);
            setPageValue(pageValue => ({...pageValue, page : 1, offset : 0}))
        }
    }

    const handleSearch = () => {
        setPageValue(pageValue => ({...pageValue, offset: 0, page : 1}));
        fetchData(pageValue.search, 0, config.itemPerPage, pageValue.complete, pageValue.is_lock, isShowAkta, isShowKuasa, isShowPernyataan);
        // fetchData = useCallback(async(q='', offset=0, limit=config.itemPerPage, status_compete='', is_lock='', show_akta=false, show_kuasa=false, show_pernyataan=false
    }

    const handleKeyDownSearch = (e) => {
        if (e.key === "Enter"){
            handleSearch();
        }
    }

    const handleShowAlasanAkta = () => {
        setIsShowAkta(!isShowAkta)
        let tmp = []
        listData.data.map(post => {
            let obj = {...post}
            obj['show_alasan_akta'] = !isShowAkta
            tmp.push(obj);
            return true;
        })
        setListData(listData => ({...listData, data : tmp}));
    }
    
    const handleShowAlasanPernyataan = () => {
        setIsShowPernyataan(!isShowPernyataan)
        let tmp = []
        listData.data.map(post => {
            let obj = {...post}
            obj['show_alasan_pernyataan'] = !isShowPernyataan
            tmp.push(obj);
            return true;
        })
        setListData(listData => ({...listData, data : tmp}));
    }
    
    const handleShowAlasanKuasa = () => {
        setIsShowKuasa(!isShowKuasa)
        let tmp = []
        listData.data.map(post => {
            let obj = {...post}
            obj['show_alasan_kuasa'] = !isShowKuasa
            tmp.push(obj);
            return true;
        })
        setListData(listData => ({...listData, data : tmp}));
    }


    return {
        showLoading, closeLoading, alertValue, setAlertValue, handleCloseAlert, modalValue, setModalValue, handleCloseModal,
        listData, pageValue, handlePaginate, handleOpenAlasanAkta, handleOpenAlasanKuasa, handleOpenAlasanPernyataan, handleChangeSearch,
        handleSearch, handleKeyDownSearch, handleShowAlasanAkta, handleShowAlasanPernyataan, handleShowAlasanKuasa,
        handleSubmitDelete, handleDelete, isLoading, isShowAkta, isShowKuasa, isShowPernyataan
    }
}