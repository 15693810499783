
const NotFoundPage = () => {
    const handleRedirect = () => {
        window.location.href = "https://www.freepik.com"
    }
    return(
        <div className='flex flex-col justify-center items-center h-screen w-full font-poppins'>
            <div className='h-[60%] min-h-[500px] flex flex-col justify-end items-end -mt-8'>
                <img src={require('../../assets/images/404.jpg')} alt="not found" className='h-full bg-red-50'/>
                <div className='-mt-8 text-sm'>Design by <button className='text-blue-500' onClick={handleRedirect}>Freepik</button></div>
            </div>
        </div>
    )
}
export default NotFoundPage