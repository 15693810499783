import PropTypes from 'prop-types';

const Input = (props) => {
    const { 
        type, placeholder, id, onChange, onBlur, value,
        className, name, onKeyDown, pattern, textareaRows, disabled, ref
    } = props;

    
    return(
        <>
            { type === 'textarea' ?
                <textarea 
                    className={`text-sm appearance-none 
                        block w-full
                        text-gray-700
                        border-gray-400 
                        rounded py-3 
                        px-2
                        border 
                        leading-tight 
                        focus:outline-1 
                        focus:outline-none 
                        focus:outline-blue-400 
                        ${disabled ? 'bg-gray-200' : 'bg-white'}
                        focus:border-none ${className}`
                    }
                    id={id}
                    type={type} 
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    name={name}
                    rows={textareaRows ? textareaRows : 3}
                    onKeyDown={onKeyDown}
                    disabled={disabled}
                    ref={ref}
                ></textarea>
                :
                <input 
                    className={`text-sm appearance-none 
                        block w-full
                        text-gray-700
                        border-gray-400 
                        rounded py-3 
                        px-2
                        border 
                        leading-tight 
                        focus:outline-1 
                        focus:outline-none 
                        focus:outline-blue-400 
                        ${disabled ? 'bg-gray-200' : 'bg-white'}
                        focus:border-none ${className}`
                    }
                    onKeyDown={onKeyDown}
                    pattern={pattern}
                    id={id}
                    type={type} 
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    name={name}
                    disabled={disabled}
                    ref={ref}
                />
            }
        </>
    )
}
Input.propTypes = {
    type: PropTypes.string,
    placeholder : PropTypes.string,
    id : PropTypes.string,
    value : PropTypes.any,
    onChange : PropTypes.func,
    onBlur : PropTypes.func,
    className : PropTypes.string,
    name : PropTypes.string,
    onKeyDown : PropTypes.func,
    pattern : PropTypes.any,
    textareaRows : PropTypes.number,
    disabled : PropTypes.bool,
}

Input.defaultProps = {
    placeholder : '',
    type: 'text',
    id : '',
    className : '',
    disabled : false,
}
export default Input;