import { useCallback, useContext, useEffect, useState } from "react";
import { useGlobalFunction } from "../../../../generalFunction";
import config from "../../../../config";
import api from "../../../../api";
import { pageObj } from "../../../../pageObj";
import axios from "axios";
import Cookies from 'universal-cookie';
import { ThemeContext } from "../../../../context/ThemeContext";

export function useFileUpload(){
    const cookies = new Cookies();
    const { isLoading, showLoading, closeLoading, setIsLoading,
        modalValue, setModalValue, handleCloseModal,
        pageValue, setPageValue, alertValue, setAlertValue, handleCloseAlert
    } = useGlobalFunction();
    const { setTitle } = useContext(ThemeContext);
    const [mimeDisplay, setMimeDisplay] = useState('');
    const [listData, setListData] = useState([]);
    const [view, setView] = useState('list');
    const [isEdit, setIsEdit] = useState(false);
    const [formData, setFormData] = useState({
        id: {
            name: 'id',
            label: 'ID',
            value: '',
            type: 'text',
            col: 2,
            required: false,
            show: false,
            errorMsg: '',
            showError: false,
            flexType : 'row',
            readOnly : false,
        },
        nama: {
            name: 'nama',
            label: 'Nama',
            value: '',
            type: 'text',
            col: 2,
            required: true,
            show: true,
            errorMsg: '',
            showError: false,
            flexType : 'row',
        },
        path: {
            name: 'path',
            label: 'File Upload',
            value: '',
            type: 'file',
            col: 2,
            required: true,
            show: true,
            errorMsg: '',
            showError: false,
            flexType : 'row',
        },
        is_show: {
            name: 'is_show',
            label: 'File Pendukung',
            value: false,
            type: 'checked',
            col: 2,
            required: false,
            show: true,
            errorMsg: '',
            showError: false,
            flexType : 'row',
        },
        type_file: {
            name: 'type_file',
            label: 'Sebagai Dokumen',
            value: '',
            type: 'select',
            col: 2,
            required: false,
            show: true,
            errorMsg: '',
            showError: false,
            flexType : 'row',
            obj : [
                {value : null, label : '-- Pilih --'},
                {value : 1, label : 'Template Surat Pernyataan'},
                {value : 2, label : 'Template Surat Kuasa'},
            ]
        },
    })
    

    const [availableForm, setAvailableForm] = useState({
        web_page: {
            name: 'web_page',
            label: 'Web page',
            value: false,
            type: 'checked',
            col: 2,
            required: false,
            show: true,
            errorMsg: '',
            showError: false,
            flexType : 'row',
        },
        member_page: {
            name: 'member_page',
            label: 'Member page',
            value: false,
            type: 'checked',
            col: 2,
            required: false,
            show: true,
            errorMsg: '',
            showError: false,
            flexType : 'row',
        },
    })


    useEffect(() => {
        setTitle('File Upload');
        // eslint-disable-next-line
    },[])
    const fetchData = useCallback(async(q='', offset=0, limit=config.itemPerPage) => {
        setIsLoading(true);
        try {
            let params = {
                offset: offset,
                limit: limit
            }
            if (q) {
                params['q'] = q;
            }
            const res = await api.get(`${config.endPoint.fileUpload}`, { params: params }).then(res => res.data);
            if (res) {
                setListData(res.results);
                setPageValue(pageValue => ({
                    ...pageValue,
                    obj: pageObj(res.count, limit, offset),
                    lastPage: Math.ceil(parseInt(res.count) / parseInt(limit)),                    
                }))
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            let status = error && error.response && error.response.status ? error.response.status : '';

            if (status && status === 401) {
                return window.location.href = "/admin/login"
            } else {
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Unable to fetch Data', color: 'error' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue)
            }
        }
    },[setAlertValue, setIsLoading, setPageValue])

    useEffect(() => {
        fetchData();
    },[fetchData])

    const handleCreate = () => {
        setView('create');
        setIsEdit(false);
        setFormData(formData => ({...formData,
            nama : {...formData.nama, value : ''},
            path : {...formData.path, value : '' },
            is_show : {...formData.is_show, value : ''},
            id : {...formData.id, value : ''},
        }))
        setAvailableForm(availableForm => ({...availableForm,
            web_page : {...availableForm.web_page, value : false},
            member_page : {...availableForm.member_page, value : false},
        }))
    }

    const handleCancel = () => {
        setView('list');
    }

    const handleEdit = (post) => {        
        
        let tmpAvailable = {...availableForm}
        tmpAvailable['web_page'] = {...tmpAvailable['web_page'], value : false}
        tmpAvailable['member_page'] = {...tmpAvailable['member_page'], value : false}
        post.list_available.map(post => {
            if (post.page === 1){
                tmpAvailable['web_page'] = {...tmpAvailable['web_page'], value : true}
            }
            if (post.page === 2){
                tmpAvailable['member_page'] = {...tmpAvailable['member_page'], value : true}
            }
            return true;
        })
        setAvailableForm(tmpAvailable);
        setMimeDisplay(post.mime_display)
        

        setFormData(formData => ({...formData,
            nama : {...formData.nama, value : post.nama},
            path : {...formData.path, value : post.path },
            is_show : {...formData.is_show, value : post.is_show},
            id : {...formData.id, value : post.id},
            type_file : {...formData.type_file, value : tmpAvailable['member_page'].value === true ? post.type_file : ''},
        }))

        setView('create');
        setIsEdit(true);
    }

    const handlePaginate = (page) => {
        let myOffset = (parseInt(page) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
        setPageValue(pageValue => ({...pageValue, page : page, offset: myOffset}))
        fetchData(pageValue.search, myOffset, config.itemPerPage);
    }

    const handleChangeSearch = (e) => {
        const { name, value } = e.target;
        setPageValue(pageValue => ({...pageValue, [name] : value}))
    }

    const handleSearch = () => {
        setPageValue(pageValue => ({...pageValue, offset: 0, page : 1}));
        fetchData(pageValue.search, 0, config.itemPerPage)
    }

    const handleKeyDownSearch = (e) => {
        if (e.key === "Enter"){
            handleSearch();
        }
    }

    const handleDelete = (post) => {
        setModalValue(modalValue => ({
            ...modalValue, 
            show: true, 
            text : `Apakah anda yakin akan menghapus file upload ${post.nama} ? `, 
            title : 'Konfirmasi', 
            id : post.id
        }))
    }

    const handleSubmitDelete = async() => {
        setIsLoading(true);
        try {                                  
            let res = await api.delete(`${config.endPoint.fileUpload}${modalValue.id}/`).then(res => res.data);
            
            if (res){
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Data dihapus', color: 'danger' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue);
                fetchData(pageValue.search, pageValue.offset);
                handleCloseModal();
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            let msg = 'Unable to save data';
            let { response } = error;
            if (response && response.data && response.data.message) {
                msg = response.data.message
            }

            setIsLoading(false);
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }));
            }, config.timeOutValue)
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            let tmpForm = {}
            let countError = 0;
            let obj = {...formData}
            let tmpFormAvailable = {}
            let objAvailable = {...availableForm}

            Object.entries(obj).map(([index, post]) => {
                if (post.required && post.value === ''){
                    countError++;
                    post.showError = true;
                    console.log('index post >>>>', index, post)
                }else{
                    tmpForm[index] = post.value
                }
                return true;
            })

            Object.entries(objAvailable).map(([index, post]) => {
                if (post.required && post.value === ''){
                    countError++;
                    post.showError = true;                    
                }else{
                    tmpFormAvailable[index] = post.value
                }
                return true;
            })

            let headers = {
                'Content-Type': 'multipart/form-data',                
            }
            const myCookies = cookies.get(config.cookiesName);             
            if (myCookies && myCookies.token) {                    
                headers['Authorization'] = 'Token ' + myCookies.token;
            }
            

            if (countError > 0){
                setAlertValue(alertValue => ({...alertValue, show: true, text : 'Lengkapi Data', color : 'danger'}));
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue)                
                return;
            }

            

            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmpForm));            
            newForm.append('formAvailable', JSON.stringify(tmpFormAvailable));            
            newForm.append('path', formData.path.file);   
            let res;
            if (isEdit){
                res = await axios.put(`${config.apiBaseUrl[process.env.NODE_ENV]}${config.endPoint.fileUpload}${formData.id.value}/`, newForm, {headers: headers}).then(res => res.data);
            }else{
                res = await axios.post(`${config.apiBaseUrl[process.env.NODE_ENV]}${config.endPoint.fileUpload}`, newForm, {headers: headers}).then(res => res.data);
            }                  
            
            if (res){
                fetchData(pageValue.search, pageValue.offset, config.itemPerPage);
                setView('list');
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            let msg = 'Unable to save data';
            let { response } = error;
            if (response && response.data && response.data.message) {
                msg = response.data.message
            }

            setIsLoading(false);
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }));
            }, config.timeOutValue)
        }
    }   


    return {
        isLoading, showLoading, closeLoading, setIsLoading, listData,
        formData, setFormData,  setListData, handleSubmit,
        handleDelete, modalValue, setModalValue, handleCloseModal, handleSubmitDelete,
        pageValue, setPageValue, view,
        handleCreate, handleCancel, alertValue, handleCloseAlert,
        handlePaginate, handleChangeSearch, handleKeyDownSearch, handleSearch, 
        availableForm, setAvailableForm, handleEdit, isEdit, mimeDisplay
    }
}