import { useCallback, useEffect, useState } from "react";
import { useGlobalFunction } from "../../../generalFunction";
import { useCookies } from "react-cookie";
import config from "../../../config";
import api from "../../../api";
import { pageObj } from "../../../pageObj";
import * as XLSX from "xlsx";
import { encryptedResponse } from "../../../utils/crypto";

export function useTakdiundang({ tipe = "takdiundang" }) {
    const { isLoading, setIsLoading, alertValue, setAlertValue, handleCloseAlert,
        modalValue, setModalValue, handleCloseModal, pageValue, setPageValue,
        showLoading, closeLoading
    } = useGlobalFunction();
    const [listData, setListData] = useState({ isLoading: isLoading, data: [] });
    const [view, setView] = useState('list');
    const [isEdit, setIsEdit] = useState(false);
    const [cookies, setCookie] = useCookies(config.cookiesNamePassword);
    const getcookie = cookies[config.cookiesNamePassword];
    const [formPassword, setFormPassword] = useState({
        password: {
            name: 'password',
            type: 'password',
            inputType: 'password',
            showPassword: false,
            label: 'Input Your Password',
            value: '',
            required: true,
            showError: false,
            show: true,
        },
    })

    useEffect(() => {
        if (getcookie) {
            if (tipe === 'takdiundang') {
                if (getcookie.jelangkung === '') {
                    setView('password')
                }
            } else {
                if (getcookie.jelangkungdate === '') {
                    setView('password')
                }
            }
        } else {
            setView('password')
        }
    }, [getcookie, tipe])


    const handleSubmitPassword = async () => {
        setIsLoading(false);
        try {
            let obj = { ...formPassword }
            let countError = 0;
            let tmp = {}
            Object.entries(obj).map(([index, post]) => {
                if (post.required && post.value === '') {
                    post.showError = true;
                    countError++;
                } else {
                    tmp[index] = post.value
                }
                return true;
            })

            if (countError > 0) {
                setAlertValue(alertValue => ({
                    ...alertValue,
                    show: true,
                    text: 'Error',
                    subText: 'Lengkapi data',
                    color: 'danger'
                }))
                setIsLoading(false);
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }))
                }, config.timeOutValue)
            }
            if (tipe === 'takdiundang') {
                tmp['kode'] = 1;
            } else {
                tmp['kode'] = 3;
            }
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmp));

            const res = await api.post(`${config.endPoint.loginDownload}`, newForm).then(res => res.data);
            if (res) {
                console.log('tmpPassword >>>>', tmp.password)
                let tmpcookie;
                if (tipe === 'takdiundang') {
                    tmpcookie = {
                        'jelangkung': tmp.password,
                    };
                } else {
                    tmpcookie = {
                        'jelangkungdate': tmp.password,
                    };
                }
                setCookie(config.cookiesNamePassword, tmpcookie, { path: '/', maxAge: config.expiresSession });
                if (tipe === 'takdiundang') {
                    window.location.href = '/jelangkung/takdiundang';
                } else {
                    window.location.href = '/jelangkung/all';
                }
            }
        } catch (error) {
            setIsLoading(false);
            const { response } = error;
            let msg = 'Unable to save data'

            if (response && response.data && response.data.message) {
                msg = response.data.message;
            }

            setAlertValue(alertValue => ({
                ...alertValue,
                show: true,
                text: 'Error',
                subText: msg,
                color: 'danger'
            }))

            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }))
            }, config.timeOutValue)
        }
    }


    const fetchData = useCallback(async (q = '', offset = 0, limit = 25) => {
        showLoading()
        setListData(listData => ({ ...listData, isLoading: true, data: [] }))

        try {
            const params = {
                offset: offset,
                limit: limit,
            }
            if (q) {
                params['q'] = q
            }
            if (tipe === 'all') {
                params['sort_by'] = 'tanggal_approve'
                params['password'] = encryptedResponse(getcookie['jelangkungdate']);
                params['kode'] = encryptedResponse('3')
            } else {
                params['password'] = encryptedResponse(getcookie['jelangkung']);
                params['kode'] = encryptedResponse('1')
            }


            const res = await api.get(`${config.endPoint.pesertaCompleteWithHotel}`, { params: params }).then(res => res.data);
            if (res) {
                setListData(listData => ({ ...listData, isLoading: false, data: res.results }))
                setPageValue(pageValue => ({
                    ...pageValue,
                    obj: pageObj(res.count, limit, offset),
                    lastPage: Math.ceil(parseInt(res.count) / parseInt(limit))
                }))
            } else {
                setListData(listData => ({ ...listData, isLoading: false }))
            }
            closeLoading()
        } catch (error) {
            setListData(listData => ({ ...listData, isLoading: false }))
            closeLoading()
            const { response } = error;
            if (response && response.status === 403) {
                let tmp;
                if (!config.cookiesNamePassword) {
                    tmp = {
                        'jelangkung': ''
                    }
                } else {
                    tmp = { ...config.cookiesNamePassword }
                    tmp['jelangkung'] = ''
                }

                setCookie(config.cookiesNamePassword, tmp, { path: '/', maxAge: config.expiresSession });
                // window.location.href = '/jelangkung/takdiundang'
                setView('password')
            } else {

                let msg = 'Unable to fetch data'
                if (response && response.data && response.data.message) {
                    msg = response.data.message;
                }
                setAlertValue(alertValue => ({
                    ...alertValue,
                    show: true,
                    text: 'Error',
                    subText: msg,
                    color: 'danger'
                }))
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }))
                }, config.timeOutValue)
            }

        }
        // eslint-disable-next-line 
    }, [tipe])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    const handlePaginate = (page) => {
        let myOffset = (parseInt(page) * parseInt(25)) - parseInt(25)
        setPageValue(pageValue => ({ ...pageValue, page: page, offset: myOffset }))
        fetchData(pageValue.search, myOffset, 25);
    }

    const handleDownload = async () => {
        setIsLoading(true);
        try {
            let params = {
                offset: 0,
                limit: 2000,
            }

            if (tipe === 'all') {
                params['sort_by'] = 'tanggal_approve'
                params['password'] = encryptedResponse(getcookie['jelangkungdate']);
                params['kode'] = encryptedResponse('3')
            } else {
                params['password'] = encryptedResponse(getcookie['jelangkung']);
                params['kode'] = encryptedResponse('1')
            }





            const res = await api.get(`${config.endPoint.pesertaCompleteWithHotel}`, { params: params }).then(res => res.data);

            var ws = XLSX.utils.aoa_to_sheet([]);

            const header = [
                "nama_perusahaan",
                "nama_peserta",
                "email_peserta"
            ]

            if (tipe === 'all') {
                header.push('hp')
            }

            header.push("status_daftar")
            header.push("hotel")

            if (tipe === 'all') {
                header.push('wilayah')
                header.push('updated_at')
            }
            let tmp = [];
            if (res) {
                res.results.map(post => {
                    let obj = {
                        "nama_perusahaan": post.perusahaan_display,
                        'nama_peserta': post.nama,
                        'email_peserta': post.email,
                    }
                    if (tipe === 'all') {
                        obj['hp'] = post.hp

                    }

                    obj['status_daftar'] = post.jabatan_display
                    obj['hotel'] = post.hotel_display
                    if (tipe === 'all') {
                        obj['wilayah'] = post.wilayah_display
                        obj['updated_at'] = post.updated_at_display
                    }
                    tmp.push(obj)
                    return true;
                })
            }
            XLSX.utils.sheet_add_json(ws, tmp, { header: header, origin: -1 });
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, ws, "Sheet1");
            XLSX.writeFile(workbook, `data-hotel-peserta.xlsx`);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            let msg = 'Unable to save Data';
            let { response } = error;

            if (response && response.data && response.data.message) {
                msg = response.data.message
            }
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }));
            }, config.timeOutValue)
        }
        // eslint-disable-next-line 
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmitPassword();
        }
    }

    return {
        isLoading, setIsLoading, alertValue, setAlertValue, handleCloseAlert,
        modalValue, setModalValue, handleCloseModal,
        isEdit, setIsEdit, view, setView, listData, setListData,
        formPassword, setFormPassword, setCookie, handleSubmitPassword,
        pageValue, handlePaginate, handleDownload, handleKeyDown
    }
}