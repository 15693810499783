import { useCallback, useContext, useEffect, useState } from "react";
import { useGlobalFunction } from "../../../../generalFunction";
import config from "../../../../config";
import { pageObj } from "../../../../pageObj";
import api from "../../../../api";
import { ThemeContext } from "../../../../context/ThemeContext";
import { isEmptyObject } from "../../../../utils/isEmptyObject";


export function useActivtyLog(props){
    const kontakId = props && props.kontakId ? props.kontakId : null;
    const { showLoading, closeLoading, pageValue, setPageValue, catchFetching, alertValue, setAlertValue, handleCloseAlert} = useGlobalFunction();
    const [listData, setListData] = useState([]);
    const { setTitle } = useContext(ThemeContext);

    const fetchData = useCallback(async(q='', offset=0, limit=config.itemPerPage) => {
        showLoading();
        try{
            let params = {
                limit : limit, 
                offset : offset
            }
            if (kontakId){
                params['kontak'] = kontakId
            }
            if (q) {
                params['q'] = q;
            }
            const res = await api.get(`${config.endPoint.approveLog}`, {params : params}).then(res => res.data);
            
            if (res){
                setListData(res.results);
                setPageValue(pageValue => ({...pageValue,
                    obj : pageObj(res.count, limit, offset),
                    lastPage : Math.ceil(parseInt(res.count) / parseInt(limit))
                }))
            }
            closeLoading();
        }catch(error){
            closeLoading();            
            catchFetching(error, 'admin');
            setAlertValue(alertValue => ({...alertValue,
                show: true, text : 'unable to fetch data', color : 'danger'
            }))
            setTimeout(() => {
                setAlertValue(alertValue => ({alertValue, show : false}))
            }, config.timeOutValue)

        }
        // eslint-disable-next-line
    },[setPageValue, setAlertValue])

    useEffect(() => {
        if(isEmptyObject(props?.props)){
            setTitle('Activity Log')
        }
    }, [setTitle, props])

    useEffect(() => {
        fetchData();
    },[fetchData]);

    const handleChangeSearch = (e) => {
        const { name, value } = e.target;
        setPageValue(pageValue => ({...pageValue, [name] : value}))
    }

    const handleSearch = () => {
        fetchData(pageValue.search, 0, config.itemPerPage)
        setPageValue(pageValue => ({...pageValue, page : 1, offset: 0}))
    }

    const handleKeyDownSearch = (e) => {
        if (e.key === 'Enter'){
            handleSearch();
        }
    }

    const handlePaginate = (page) => {
        let myOffset = (parseInt(page) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
        setPageValue(pageValue => ({...pageValue, page : page, offset: myOffset}))
        fetchData(pageValue.search, myOffset, config.itemPerPage);
    }

    return {
        showLoading, closeLoading, pageValue, setPageValue, catchFetching, listData, alertValue, handleCloseAlert,
        handleChangeSearch, handleSearch, handleKeyDownSearch, handlePaginate
    }
}