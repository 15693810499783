import DashboardMember from "../Dashboard/DashboardMember";


const EditDataPesertaPage = () => {
    return(
        <div>
            <DashboardMember isEdit={true} />
        </div>
    )
}
export default EditDataPesertaPage;