import { Fragment, useContext } from "react";
import { UserContext } from "../../context/UserContext";



const LoadingTop = () => {
    const myState = useContext(UserContext);;
    const [myLoading] = myState.myLoading
    return(
        <Fragment>
            { myLoading &&
                <div 
                    className='absolute top-0 left-0 w-full bg-black z-[80] h-[3px] progress-bar'
                >
                </div>
            }
        </Fragment>
    )
}
export default LoadingTop;