import { Icon } from "@iconify/react";
import SiteSettingPage from "../views/Admin/Master/SiteSetting/SiteSettingPage";
import FileUploadPage from "../views/Admin/Master/FileUpload/FileUploadPage";
import PerusahaanPage from "../views/Admin/Master/Perusahaan/PerusahaanPage";
import HotelPage from "../views/Admin/Master/Hotel/HotelPage";
import HotelHistoryPage from "../views/Admin/Master/HotelHistory/HotelHistoryPage";
// import BackupDatabasePage from "../views/Admin/Master/BackupDatabase/BackupDatabasePage";
import KategoriSponsorShip from "../views/Admin/Master/KategoriSponsorShip/KategoriSponsorShip";
import SponsorShipPage from "../views/Admin/Master/SponsorShip/SponsorShipPage";
import GroupPermissionPage from "../views/Admin/Master/GroupPermission/GroupPermissinPage";
import UserPage from "../views/Admin/Master/User/UserPage";
import LockSettingPage from "../views/Admin/Master/LockSetting/LockSettingPage";
import PasswordDownloadPage from "../views/Admin/Master/PasswordDownload/PasswordDownloadPage";
import DataRegistrasiPage from "../views/Admin/Registrasi/DataRegistrasi/DataRegistrasiPage";
import EmailTemplatePage from "../views/Admin/Master/EmailTemplate/EmailTemplatePage";
import ActivityLogPage from "../views/Admin/Registrasi/ActivityLog/ActivityLogPage";
import EmailLogPage from "../views/Admin/Registrasi/EmailLog/EmailLogPage";
import DuplicatePesertaPage from "../views/Admin/Registrasi/DuplicatePeserta/DuplicatePesertaPage";
import HotelPesertaPage from "../views/Admin/Registrasi/HotelPeserta/HotelPesertaPage";
import HapusDataRegistrasiPage from "../views/Admin/Registrasi/HapusDataRegistrasi/HapusDataRegistrasiPage";
import RegistrasiPerusahaanPage from "../views/Admin/Registrasi/RegistrasiPerusahaan/RegistrasiPerusahaanPage";
import AgendaAdminPage from "../views/Admin/Master/Agenda/AgendaAdminPage";
import KandidatKetumPage from "../views/Admin/Master/Kandidat/KandidatKetumPage";
import KandidatDewasPage from "../views/Admin/Master/KandidatDewas/KandidatDewasPage";
import KategoriPanitiaPage from "../views/Admin/Master/KategoriPanitia/KategoriPanitiaPage";
import PanitiaPage from "../views/Admin/Master/Panitia/PanitiaPage";
import { useCallback, useContext, useEffect, useState } from "react";
import { UserContext } from "../context/UserContext";
import api from "../api";
import config from "../config";
import { menu } from "./menu";
import Cookies from "universal-cookie";
import { decryptedResponse } from "../utils/crypto";
import { TbTags } from "react-icons/tb";
import IdCardPage from "../views/Admin/Registrasi/PersiapanIdCard/PersiapanIdCardPage";
// import ExportDataPage from "../views/Admin/Master/ExportData/ExportDataPages";


export function useMenu() {
  // eslint-disable-next-line
  const oldmenu = [
    {
      icon: <Icon icon="ant-design:setting-filled" />,
      path: "/admin/master",
      name: "master",
      title: "Master",
      element: null,
      permission_group: ["Administrator"],
      sub: [
        {
          icon: null,
          path: "/admin/master/site-setting",
          name: "site-setting",
          title: "Site Setting",
          element: <SiteSettingPage />,
          permission_name: "view_site_setting",
          permission_group: ["Administrator"],
        },
        {
          icon: null,
          path: "/admin/master/email-template",
          name: "email-template",
          title: "Email Template",
          element: <EmailTemplatePage />,
          permission_name: "view_email_template",
          permission_group: ["Administrator"],
        },
        {
          icon: null,
          path: "/admin/master/file-upload",
          name: "site-setting",
          title: "File Upload",
          element: <FileUploadPage />,
          permission_name: "view_file_upload",
          permission_group: ["Administrator"],
        },
        {
          icon: null,
          path: "/admin/master/perusahaan",
          name: "perusahaan",
          title: "Perusahaan",
          element: <PerusahaanPage />,
          permission_name: "view_perusahaan",
          permission_group: ["Administrator"],
        },
        {
          icon: null,
          path: "/admin/master/hotel",
          name: "hotel",
          title: "Hotel",
          element: <HotelPage />,
          permission_name: "view_hotel",
          permission_group: ["Administrator"],
        },
        {
          icon: null,
          path: "/admin/master/hotel-history",
          name: "hotel-history",
          title: "Hotel History",
          element: <HotelHistoryPage />,
          permission_name: "view_hotel_log",
          permission_group: ["Administrator"],
        },
        // {
        //   icon: null,
        //   path: "/admin/master/backup-database",
        //   name: "backup-database",
        //   title: "Backup Database",
        //   element: <BackupDatabasePage />,
        //   permission_name: "is_superuser",
        //   permission_group: ["Administrator"],
        // },
        {
          icon: null,
          path: "/admin/master/kategori-sponsorship",
          name: "kategori-sponsorship",
          title: "Kategori Sponsorship",
          element: <KategoriSponsorShip />,
          permission_name: "view_kategori_sponsor",
          permission_group: ["Administrator"],
        },
        {
          icon: null,
          path: "/admin/master/sponsorship",
          name: "sponsorship",
          title: "Sponsorship",
          element: <SponsorShipPage />,
          permission_name: "view_sponsorship",
          permission_group: ["Administrator"],
        },
        {
          icon: null,
          path: "/admin/master/group-permission",
          name: "group-permission",
          title: "Group Permission",
          element: <GroupPermissionPage />,
          permission_name: "view_group",
          permission_group: ["Administrator"],
        },
        {
          icon: <Icon icon="fa-solid:user-tie" fontSize={18} />,
          path: "/admin/master/kategori-panitia",
          name: "kategori-panitia",
          title: "Kategori Panitia",
          element: <KategoriPanitiaPage />,
          permission_name: "view_kategori_panitia",
          permission_group: ["Administrator"],
        },
        {
          icon: <Icon icon="fa-solid:user-tie" fontSize={18} />,
          path: "/admin/master/panitia",
          name: "panitia",
          title: "Panitia",
          element: <PanitiaPage />,
          permission_name: "view_kategori_panitia",
          permission_group: ["Administrator"],
        },
        {
          icon: <Icon icon="fa-solid:user-edit" fontSize={"16"} />,
          path: "/admin/master/user",
          name: "user",
          title: "User",
          element: <UserPage />,
          permission_name: "view_user",
          permission_group: ["Administrator"],
        },
        {
          icon: null,
          path: "/admin/master/lock-setting",
          name: "user",
          title: "Lock Setting",
          element: <LockSettingPage />,
          permission_name: "view_setting_disable",
          permission_group: ["Administrator"],
        },
        // {
        //   icon: <TbFileDownload />,
        //   path: "/admin/master/export-data",
        //   name: "user",
        //   title: "Export Data",
        //   element: <ExportDataPage />,
        //   permission_name: "view_setting_disable",
        //   permission_group: ["Administrator", "is_superuser"],
        // },
        {
          icon: <Icon icon="carbon:download" />,
          path: "/admin/master/password-download",
          name: "password-download",
          title: "Password Download",
          element: <PasswordDownloadPage />,
          permission_name: "view_password_download",
          permission_group: ["Administrator"],
        },
        {
          icon: <Icon icon="cil:calendar" />,
          path: "/admin/master/agenda-acara",
          name: "agenda-acara",
          title: "Agenda Acara",
          element: <AgendaAdminPage />,
          permission_name: "view_agenda_acara",
          permission_group: ["Administrator"],
        },
        {
          icon: <Icon icon="fa-solid:user-tie" fontSize={18} />,
          path: "/admin/master/kandidat-ketum",
          name: "kandidat-ketum",
          title: "Kandidat Ketum",
          element: <KandidatKetumPage />,
          permission_name: "view_kandidat_ketum",
          permission_group: ["Administrator"],
        },
        {
          icon: <Icon icon="raphael:users" fontSize={"16"} />,
          path: "/admin/master/kandidat-dewas",
          name: "kandidat-dewas",
          title: "Kandidat Dewas",
          element: <KandidatDewasPage />,
          permission_name: "view_kandidat_dewas",
          permission_group: ["Administrator"],
        },
      ],
    },
    {
      icon: <Icon icon="medical-icon:i-registration" fontSize={18} />,
      path: "/admin/registrasi",
      name: "registrasi",
      title: "Registrasi",
      permission_group: ["Administrator", "Operator"],
      element: null,
      sub: [
        {
          icon: <Icon icon="fa-solid:user-tie" fontSize={18} />,
          path: "/admin/registrasi/data-registrasi-peserta",
          name: "data-peserta",
          title: "Data Registrasi Peserta",
          element: <DataRegistrasiPage />,
          permission_name: "view_kontak",
          permission_group: ["Administrator", "Operator", "Read-Only"],
        },
        {
          icon: <Icon icon="fa-solid:user-tie" fontSize={18} />,
          path: "/admin/registrasi/hapus-registrasi-peserta",
          name: "hapus-peserta",
          title: "Hapus Registrasi Peserta",
          element: <HapusDataRegistrasiPage />,
          permission_name: "delete_kontak",
          permission_group: ["Administrator", "Operator"],
        },
        {
          icon: <Icon icon="humbleicons:activity" fontSize={18} />,
          path: "/admin/registrasi/activity-log",
          name: "activity-log",
          title: "Activity Log",
          element: <ActivityLogPage />,
          permission_name: "delete_kontak",
          permission_group: ["Administrator", "Operator"],
        },
        {
          icon: <Icon icon="icon-park-twotone:timed-mail" fontSize={18} />,
          path: "/admin/registrasi/email-log",
          name: "email-log",
          title: "Email Log",
          element: <EmailLogPage />,
          permission_name: "view_kontak_approve_log",
          permission_group: ["Administrator", "Operator"],
        },
        {
          icon: <Icon icon="mi:users" fontSize={18} />,
          path: "/admin/registrasi/duplicate-peserta",
          name: "duplicate-peserta",
          title: "Duplikat Peserta",
          element: <DuplicatePesertaPage />,
          permission_name: "view_duplicate_peserta",
          permission_group: ["Administrator", "Operator"],
        },
        {
          icon: <Icon icon="fontisto:hotel" fontSize={18} />,
          path: "/admin/registrasi/hotel-peserta",
          name: "hotel-peserta",
          title: "Hotel Peserta",
          element: <HotelPesertaPage />,
          permission_name: "view_hotel_peserta",
          permission_group: ["Administrator", "Operator"],
        },
        {
          icon: <Icon icon="material-symbols:remove-done" fontSize={18} />,
          path: "/admin/registrasi/unfinish-signup",
          name: "unfinish-signup",
          title: "Unfinish Signup",
          element: <RegistrasiPerusahaanPage />,
          permission_name: "view_kontak",
          permission_group: ["Administrator", "Operator", "Read-Only"],
        },
        {
          icon: <TbTags fontSize={18} />,
          path: "/admin/registrasi/persiapan-idcard",
          name: "persiapan-idcard",
          title: "Persiapan ID Card",
          element: <IdCardPage />,
          permission_group: ["Administrator", "Operator"],
        },
      ],
    },
  ];

  const myState = useContext(UserContext);
  const cookies = new Cookies();
  const getcookie = cookies.get(config.cookiesName);
  const [isSuperUser, setIsSuperUser] = myState.isSuperUser;
  const [listPermissions, setListPermissions] = useState([]);
  const [readyMenu, setReadyMenu] = useState(false);
  const [readyToRender, setReadyToRender] = useState(false);
  const [myMenu, setMyMenu] = useState([]);
  const [listGroup, setListGroup] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const res = await api
        .get(`${config.endPoint.userRole}`)
        .then((res) => res);

      const dekrip = decryptedResponse(res.data?.result)
      if (dekrip === null) {
        //logout
        cookies.remove(config.cookiesName, { path: "/" });
      }
      setListPermissions(dekrip.data);
      setIsSuperUser(dekrip.is_superuser);
      setListGroup(dekrip.groups);
      setReadyMenu(true);
    } catch (error) {
      console.log(error)
      cookies.remove(config.cookiesName, { path: "/" });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (getcookie) {
      fetchData();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (readyMenu) {
      if (isSuperUser) {
        setMyMenu(menu);
      } else {
        let tmp = [];
        menu.map((post) => {
          if (post.sub.length > 0) {
            let tmp_sub_menu = [];
            post.sub.map((submenu) => {
              if (listGroup.some((x) => submenu.permission_group.includes(x))) {
                tmp_sub_menu.push(submenu);
              }
              return true;
            });
            post["sub"] = tmp_sub_menu;
            tmp.push(post);
          } else {
            if (listGroup.some((r) => post.permission_group.includes(r))) {
              tmp.push(post);
            }
          }
          return true;
        });
        let array_index = [];
        tmp.map((post, index) => {
          if (!post.element && post.sub.length === 0) {
            array_index.push(index);
          }
          return true;
        });

        array_index.map((index) => {
          tmp.splice(index, 1);
          return true;
        });
        setMyMenu(tmp);
      }
      setReadyToRender(true);
    }
    // eslint-disable-next-line
  }, [listPermissions, readyMenu, isSuperUser, listGroup]);

  return {
    listGroup,
    isSuperUser,
    listPermissions,
    menu,
    readyToRender,
    myMenu,
    readyMenu
  };
}
