import { useCallback, useContext, useEffect, useState } from "react";
import { useGlobalFunction } from "../../../../generalFunction";
import config from "../../../../config";
import api from "../../../../api";
import { pageObj } from "../../../../pageObj";
import { ThemeContext } from "../../../../context/ThemeContext";


export function useSponsorShip(){
    const { isLoading, showLoading, closeLoading, setIsLoading,
        modalValue, setModalValue, handleCloseModal,
        pageValue, setPageValue, catchFetching, alertValue, handleCloseAlert, setAlertValue,
    } = useGlobalFunction();
    const [listData, setListData] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const { setTitle } = useContext(ThemeContext);
    const [view, setView] = useState('list');
    const [formData, setFormData] = useState({
        id : {
            name : 'id',
            label : 'ID',
            value : '',
            type : 'text',
            col : 2,
            required : false,
            show : false,
            errorMsg : '',
            showError : false,
            flexType : 'row'
        },
        kategori : {
            name : 'kategori',
            label : 'Kategori',
            value : '',
            type : 'select',
            col : 2,
            obj : [{value : 1, label : 'Platinum'}, {value : 2, label : 'Diamond'}],
            required : true,
            show : true,
            errorMsg : '',
            showError : false,
            flexType : 'row'
        },
        nama : {
            name : 'nama',
            label : 'Nama Sponsor',
            value : '',
            type : 'text',
            col : 2,
            required : true,
            show : true,
            errorMsg : '',
            showError : false,
            flexType : 'row'
        },
        logo : {            
            name: 'logo',
            label: 'Logo',
            value: '',
            type: 'image',
            col: 2,
            required: true,
            show: true,
            errorMsg: '',
            showError: false,
            file: null,
            flexType : 'row',
        },
        prioritas : {
            name : 'prioritas',
            label : 'Prioritas',
            value : 1,
            type : 'number',
            inputType : 'number',
            col : 2,
            required : true,
            show : true,
            errorMsg : '',
            showError : false,
            flexType : 'row',
            className : 'text-right'
        },
    })

    useEffect(() => {
        setTitle('Sponsorship')
    }, [setTitle])
    const fetchData = useCallback(async(q='', offset=0, limit=config.itemPerPage) => {
        setIsLoading(true);
        try{
            let params = {
                offset : offset,
                limit : limit
            }
            if (q){
                params['q'] = q;
            }
            const res = await api.get(`${config.endPoint.sponsorShip}`, {params : params}).then(res => res.data);
            if (res){
                setListData(res.results);
                setPageValue(pageValue => ({...pageValue,
                    obj : pageObj(res.count, limit, offset),
                    lastPage : Math.ceil(parseInt(res.count) / parseInt(limit))
                }))
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            catchFetching(error, 'admin');
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to fetch data', color : 'danger'}))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
        // eslint-disable-next-line
    },[setIsLoading, setAlertValue, setPageValue])

    const fetchKategori = useCallback(async() => {
        setIsLoading(true);
        try{
            const res = await api.get(`${config.endPoint.kategoriSponsor}`).then(res => res.data);
            let tmp = [{value : '', label : '-- Pilih Kategori --'}];
            if (res){
                res.results.map(post => tmp.push({value : post.id, label : post.nama}))
            }
            setFormData(formData => ({...formData, kategori : {...formData.kategori, obj : tmp}}))
            // console.log('res sponsorship is >>>>', res);
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            catchFetching(error, 'admin');
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to fetch data', color : 'danger'}))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
        // eslint-disable-next-line
    },[setIsLoading, setAlertValue])

    useEffect(() => {
        fetchData();
        fetchKategori();
    },[fetchData, fetchKategori])


    const handleSubmit = async() => {
        setIsLoading(true);
        showLoading();
        try{
            let countError = 0;
            let form = {...formData};
            let tmpForm = {}
            Object.entries(form).map(([index, post]) => {
                if (index !== 'redaksional'){
                    if (post.required && (post.value === '' || post.value === null)){
                        post.showError = true;
                        countError++;
                    }else{
                        tmpForm[index] = post.value;
                    }
                }
                return true;
            });
            
            if (countError > 0){
                setFormData(form);
                setIsLoading(false);
                closeLoading();
                setAlertValue(alertValue => ({...alertValue, show: true, text : 'Lengkapi Data', color: 'danger'}));
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue);
                return;
            }
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmpForm));
            
                        
           
            let res;
            if (isEdit){
                res = await api.put(`${config.endPoint.sponsorShip}${formData.id.value}/`, newForm).then(res => res.data);
            }else{
                res = await api.post(`${config.endPoint.sponsorShip}`, newForm).then(res => res.data);
            }
            if (res){
                handleCancel();                
            }
            setIsLoading(false);
            closeLoading();
        }catch(error){
            closeLoading();
            setIsLoading(false);
            catchFetching(error, 'admin')

            let msg = "Unable to save data";
            if (error.response && error.response.data && error.response.data.message){
                msg = error.response.data.message
            }
            setAlertValue(alertValue => ({...alertValue, show: true, text : msg, color : 'danger'}))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))                
            }, config.timeOutValue);
        }
    }

    const handleDelete = (post) => {
        setModalValue(modalValue => ({...modalValue,
            show: true,
            text : `Apakah anda yakin akan menghapus sponsorship ${post.nama} ?`,
            id : post.id,
            tipe : 'delete',
            title: 'Konfirmasi',
        }))
    }

    const handleSubmitDelete = async() => {
        setIsLoading(true)
        try{
            const res = await api.delete(`${config.endPoint.sponsorShip}${modalValue.id}/`).then(res => res.data);
            if (res){
                fetchData(pageValue.search, pageValue.offset, config.itemPerPage);
                setAlertValue(alertValue => ({...alertValue, show: true, text : 'Data dihapus', color : 'success'}))
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue)
            }
            handleCloseModal();
        }catch(error){
            setIsLoading(false);
            catchFetching(error, 'admin')
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to delete data', color : 'danger'}))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }

    const handleCreate = () => {
        setView('create');
        setIsEdit(false);
        setFormData(formData => ({...formData,
            id : {...formData.id, value : ''},
            nama : {...formData.nama, value : ''},
            logo : {...formData.logo, value : ''},
            kategori : {...formData.kategori, value : ''},
            // prioritas : {...formData.prioritas, value : 1},
        }))
    }

    const handleEdit = (post) => {
        setView('create');
        setIsEdit(true);
        setFormData(formData => ({...formData,
            id : {...formData.id, value : post.id},
            nama : {...formData.nama, value : post.nama},
            logo : {...formData.logo, value : post.logo},
            prioritas : {...formData.prioritas, value : post.prioritas},
            kategori : {...formData.kategori, value : post.kategori},
        }))
    }

    const handleCancel = () => {
        setView('list');
        fetchData(pageValue.search, pageValue.offset, config.itemPerPage)
    }

    const handleChangeSearch = (e) => {
        const { name, value } = e.target;
        setPageValue(pageValue => ({...pageValue, [name] : value}))
    }

    const handleSearch = () => {
        fetchData(pageValue.search, 0, config.itemPerPage);
        setPageValue(pageValue => ({...pageValue, offset : 0, page : 1}))
    }

    const handleKeyDownSearch = (e) => {
        if (e.key === "Enter"){
            handleSearch();
        }
    }

    const handlePagination = (page) => {
        let myOffset = (parseInt(page) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
        setPageValue(pageValue => ({...pageValue, page : page, offset: myOffset}))
        fetchData(pageValue.search, myOffset, config.itemPerPage);
    }

    const handlePaginate = (page) => {
        let myOffset = (parseInt(page) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
        setPageValue(pageValue => ({...pageValue, page : page, offset: myOffset}))
        fetchData(pageValue.search, myOffset, config.itemPerPage);
    }


    return {
        isLoading, showLoading, closeLoading, setIsLoading, listData,
        formData, setFormData,  setListData, handleSubmit,
        handleDelete, modalValue, setModalValue, handleCloseModal, handleSubmitDelete,
        pageValue, setPageValue, view,
        handleCreate, handleCancel, alertValue, handleCloseAlert,
        handleKeyDownSearch, handleSearch, handleChangeSearch, handlePagination,
        handleEdit, handlePaginate
    }
}