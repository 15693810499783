import Proptypes from 'prop-types';
import Search from '../Atoms/Search';
import { Fragment, useContext } from 'react';
import { ThemeContext } from '../../context/ThemeContext';

import { Icon } from '@iconify/react/dist/iconify.js';
import Button from '../Atoms/Button';
// import { motion } from "framer-motion"


const Card = (props) => {    
    const { children, 
        // title, 
        isSearch, 
        isCreate,
        handleSubmit, 
        handleCancel,
        onChangeSearch, 
        handleSearch, 
        searchValue,
        handleCreate,                
        className,
        btnCreateText,
        disableIconButtonCreate,
        ChildButton,        
        bodyStyle,
    } = props;

    const { themeColor } = useContext(ThemeContext)
    const bgColor = {
        "#7367f0" : `bg-[#7367f0]`,
        "#f43f5e" : `bg-[#f43f5e]`,
        "#3b82f6" : `bg-[#3b82f6]`,
        "#14b8a6" : `bg-[#14b8a6]`,
        "#eab308" : `bg-[#eab308]`,
        "#982A18" : `bg-[#982A18]`,
    }


    
    return(
        <div className={`shadow-lg bg-white dark:bg-base-600  rounded-md relative ${className}`}>                                   
            <div className='p-6'>
                { (isSearch || handleCreate) &&
                    <div className={`flex flex-col md:flex-row w-full ${isSearch ? 'justify-between' : 'justify-end'} mb-5`}>
                        { isSearch && !isCreate &&
                            <div className='w-full md:w-60'>
                                <Search
                                    placeholder={"Cari"}
                                    onChange={(value) => onChangeSearch(value)}
                                    handleSearch={handleSearch}
                                    value={searchValue}
                                />    
                            </div>
                        }
                        { !isCreate && handleCreate &&
                            <Fragment>                            

                                <div className="md:flex  justify-end md:justify-end gap-1 items-center">                                
                                    <button size="md" onClick={handleCreate} className={`px-4 py-[10px] leading-3  flex flex-row items-center  ${bgColor[themeColor]} text-white text-[14px] rounded`}>
                                        { !disableIconButtonCreate &&
                                            <Icon icon="ic:round-plus" />
                                        }
                                        &nbsp;<div className='md:visible  md:w-fit'>
                                            { btnCreateText }
                                        </div>                                    
                                    </button>
                                    {/* </motion.div> */}
                                    { ChildButton && 
                                        <Fragment>
                                            <ChildButton />                                                
                                        </Fragment>
                                    }
                                </div>       
                            </Fragment>
                        }

                    </div>
                }                
                <div style={bodyStyle}>

                    { children }
                </div>
                { isCreate && (handleSubmit || handleCancel) &&
                    <div className='flex flex-row w-full border-t border-t-gray-200 mt-6 pt-4'>
                        { isCreate &&
                            <div className='flex flex-row justify-start w-full gap-2'>
                                { handleSubmit &&
                                    // <motion.div
                                        //     whileHover={{
                                        //         scale: 1.1,                                            
                                        //         transition: { duration: 0.5 },
                                        //     }}
                                        //     whileTap={{ scale: 0.9 }}
                                        // >
                                        <Button size="md" onClick={handleSubmit}  className={`${bgColor[themeColor]} text-white`}>Simpan</Button>
                                        // </motion.div>
                                }
                                { handleCancel &&
                                    // <motion.div                                       
                                    //     whileHover={{
                                    //         // scale: 1.2,
                                    //         rotate: 360,
                                    //         transition: { duration: 0.5 },
                                    //     }}
                                    //     whileTap={{ scale: 0.9 }}
                                    // >

                                        <Button size="md" onClick={handleCancel}  className='bg-gray-400 text-white'>Cancel</Button>
                                    // </motion.div>
                                }
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    )
}
Card.propTypes = {
    children : Proptypes.any,
    title : Proptypes.any,    
    isCreate : Proptypes.bool,
    isSearch : Proptypes.bool,
    handleSubmit : Proptypes.func,
    handleCancel : Proptypes.func,
    onChangeSearch : Proptypes.func,
    handleSearch : Proptypes.func, 
    searchValue : Proptypes.string,
    handleCreate : Proptypes.func,     
    loadingHeight : Proptypes.number,
    loadingWidth : Proptypes.number,
    loadingColor : Proptypes.string,
    loadingClassName : Proptypes.string,
    className : Proptypes.string,
    btnCreateText : Proptypes.string,
    disableIconButtonCreate : Proptypes.bool,
    ChildButton : Proptypes.any,
    bodyStyle : Proptypes.any
    // createChildButton : Proptypes.bool,
    // createChildTextButton : Proptypes.string,
    // createChildIconButton : Proptypes.string,
    // createChildActionButton : Proptypes.func,
}

Card.defaultProps = {    
    isCreate : false,
    isSearch : true,
    btnCreateText : "Create", 
    disableIconButtonCreate : false
}
export default Card