import { Icon } from "@iconify/react/dist/iconify.js";
import { useLivePendaftaran } from "./livePendaftaranFunction";
import FormTextSingleComp from "../../../Components/forms/FormTextSingleComp";

const LivePendaftaranPage = () => {
    const { info, isOnline, alertValue, formPassword, setFormPassword, handleSubmitPassword, view,
        handleKeyDown
    } = useLivePendaftaran();


    return (
        <div className='mt-[80px] md:mt-[100px] px-[10px] md:px-[100px]'>
            {view === 'password' &&
                <div className='min-h-[calc(100vh-120px)] h-full flex flex-col justify-center items-center'>
                    <div className='w-[300px]'>
                        {alertValue.show &&
                            <div className='w-full px-5 py-2 bg-red-100 text-red-500 text-xs rounded-md text-center'>{alertValue.subText}</div>
                        }
                        <FormTextSingleComp
                            {...formPassword.password}
                            formData={formPassword}
                            setFormData={setFormPassword}
                            onKeyDown={handleKeyDown}

                        />
                        <button onClick={handleSubmitPassword} className='w-full bg-blue-800 text-white py-3 rounded-md'>Submit</button>
                    </div>
                </div>
            }
            {view === 'list' &&
                <div>
                    <div className='my-5  text-[25px] flex flex-row justify-end items-center'>
                        {!isOnline &&
                            <>
                                <Icon icon="clarity:disconnected-solid" />
                                <div>{" "}Disconnect</div>
                            </>
                        }
                    </div>
                    <div className="relative flex justify-center items-center space-x-20">
                        {/* Div pertama */}
                        <div className='relative text-white w-[350px] h-[350px] rounded-full shadow-lg bg-gradient-to-r from-blue-700 to-blue-300 flex flex-col justify-center items-center'>
                            <div className="absolute inset-0 flex justify-center items-center">
                                <div className="absolute top-[165px] animate-spin-around" style={{ animationDelay: '0s' }}>
                                    <div className="h-5 w-5 rounded-full bg-blue-700"></div>
                                </div>
                                <div className="absolute top-[165px] animate-spin-around" style={{ animationDelay: '0.3s' }}>
                                    <div className="h-4 w-4 rounded-full bg-blue-700"></div>
                                </div>
                                <div className="absolute top-[165px] animate-spin-around" style={{ animationDelay: '0.6s' }}>
                                    <div className="h-3 w-3 rounded-full bg-blue-700"></div>
                                </div>
                                <div className="absolute top-[165px] animate-spin-around" style={{ animationDelay: '0.9s' }}>
                                    <div className="h-2 w-2 rounded-full bg-blue-700"></div>
                                </div>
                                <div className="absolute top-[165px] animate-spin-around" style={{ animationDelay: '1.2s' }}>
                                    <div className="h-1 w-1 rounded-full bg-blue-700"></div>
                                </div>
                                <div className="absolute top-[165px] animate-spin-around" style={{ animationDelay: '1.5s' }}>
                                    <div className="h-0.5 w-0.5 rounded-full bg-blue-700"></div>
                                </div>
                            </div>
                            <div className='text-[24px] font-semibold'>Total Anggota Eligible</div>
                            <div className='text-[44px] font-bold mt-5'>{info?.data?.total_perusahaan}</div>
                        </div>

                        {/* Div ketiga */}
                        <div className='relative text-white w-[350px] h-[350px] rounded-full shadow-lg bg-gradient-to-r from-green-700 to-green-300 flex flex-col justify-center items-center'>
                            <div className="absolute inset-0 flex justify-center items-center">
                                <div className="absolute top-[165px] animate-spin-around" style={{ animationDelay: '1.5s' }}>
                                    <div className="h-5 w-5 rounded-full bg-green-700"></div>
                                </div>
                                <div className="absolute top-[165px] animate-spin-around" style={{ animationDelay: '1.8s' }}>
                                    <div className="h-4 w-4 rounded-full bg-green-700"></div>
                                </div>
                                <div className="absolute top-[165px] animate-spin-around" style={{ animationDelay: '2.1s' }}>
                                    <div className="h-3 w-3 rounded-full bg-green-700"></div>
                                </div>
                                <div className="absolute top-[165px] animate-spin-around" style={{ animationDelay: '2.4s' }}>
                                    <div className="h-2 w-2 rounded-full bg-green-700"></div>
                                </div>
                                <div className="absolute top-[165px] animate-spin-around" style={{ animationDelay: '2.7s' }}>
                                    <div className="h-1 w-1 rounded-full bg-green-700"></div>
                                </div>
                                <div className="absolute top-[165px] animate-spin-around" style={{ animationDelay: '3.0s' }}>
                                    <div className="h-0.5 w-0.5 rounded-full bg-green-700"></div>
                                </div>
                            </div>
                            <div className='text-[24px] font-semibold'>Sudah Lengkap Berkas</div>
                            <div className='text-[44px] font-bold mt-5'>{info?.data?.sudah_lengkap}</div>
                        </div>

                        {/* Div kedua */}
                        {/* <div className='relative text-white w-[400px] h-[400px] rounded-full shadow-lg bg-gradient-to-r from-yellow-700 to-yellow-300 flex flex-col justify-center items-center'> */}
                        <div className='relative text-white w-[350px] h-[350px] rounded-full shadow-lg bg-gradient-to-r from-yellow-700 to-yellow-300 flex flex-col justify-center items-center'>
                            <div className="absolute inset-0 flex justify-center items-center">
                                <div className="absolute top-[165px] animate-spin-around" style={{ animationDelay: '3.0s' }}>
                                    <div className="h-5 w-5 rounded-full bg-yellow-700"></div>
                                </div>
                                <div className="absolute top-[165px] animate-spin-around" style={{ animationDelay: '3.3s' }}>
                                    <div className="h-4 w-4 rounded-full bg-yellow-700"></div>
                                </div>
                                <div className="absolute top-[165px] animate-spin-around" style={{ animationDelay: '3.6s' }}>
                                    <div className="h-3 w-3 rounded-full bg-yellow-700"></div>
                                </div>
                                <div className="absolute top-[165px] animate-spin-around" style={{ animationDelay: '3.9s' }}>
                                    <div className="h-2 w-2 rounded-full bg-yellow-700"></div>
                                </div>
                                <div className="absolute top-[165px] animate-spin-around" style={{ animationDelay: '4.2s' }}>
                                    <div className="h-1 w-1 rounded-full bg-yellow-700"></div>
                                </div>
                                <div className="absolute top-[165px] animate-spin-around" style={{ animationDelay: '4.5s' }}>
                                    <div className="h-0.5 w-0.5 rounded-full bg-yellow-700"></div>
                                </div>
                            </div>
                            <div className='text-[24px] font-semibold'>Sudah Daftar</div>
                            <div className='text-[44px] font-bold mt-5'>{info?.data?.sudah_daftar}</div>
                        </div>


                    </div>
                </div>
            }

        </div>
    )
}
export default LivePendaftaranPage;