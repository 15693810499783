import BookMarkLeft from "../../Bookmarks/BookMarksLeft"



const LokasiCover = ({goToPage, page}) => {
    return(
        <div
            className="page demoPage h-full overflow-y-hidden font-poppins
                w-full
            "
        >
            <div className="w-full h-full">
                <BookMarkLeft goToPage={goToPage} page={page}/>
                <div className="relative flex flex-col justify-center items-center h-full w-full bg-[url('./assets/images/homepage/bglokasi.png')] bg-cover bg-center bg-no-repeat">
                    <div className='text-white text-[32px] font-bold'>Lokasi Acara</div>
                    <div className='text-white text-[18px]'>Tempat Berlangsungnya Acara Musyawarah Nasional APJII 2024</div>
                </div>
            </div>
        </div>
    )
}
export default LokasiCover