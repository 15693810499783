import { useCallback, useContext, useEffect, useState } from "react";
import { useGlobalFunction } from "../../../../generalFunction"
import config from "../../../../config";
import api from "../../../../api";
import { pageObj } from "../../../../pageObj";
import Moment from 'moment';
import { ThemeContext } from "../../../../context/ThemeContext";

export function useAgendaAdmin(){
    const { isLoading, setIsLoading, alertValue, setAlertValue, handleCloseAlert,
        pageValue, setPageValue, modalValue, setModalValue, handleCloseModal, showLoading, closeLoading,
        catchFetching
    } = useGlobalFunction();
    const [isEdit, setIsEdit] = useState(false);
    const [view, setView] = useState('list');
    const { setTitle } = useContext(ThemeContext);
    const [listData, setListData] = useState({
        data : [],
        isLoading : true
    })
    const [formData, setFormData] = useState({
        id : {
            name : 'id',
            label : 'Agenda',
            value : '',
            type : 'text',
            col : 2,
            required : false,
            show : false,
            errorMsg : '',
            showError : false,
            flexType : 'row',    
            readOnly: true
        },
        nama : {
            name : 'nama',
            label : 'Agenda',
            value : '',
            type : 'text',
            col : 2,
            required : true,
            show : true,
            errorMsg : '',
            showError : false,
            flexType : 'row',  
            placeholder : 'Nama Agenda'    
        },
        tanggal : {
            name : 'tanggal',
            label : 'Tanggal',
            value : '',
            type : 'date',
            col : 2,
            required : true,
            show : true,
            errorMsg : '',
            showError : false,
            flexType : 'row',    
            placeholder : 'Tanggal'  
        },
        start_time : {
            name : 'start_time',
            label : 'Jam Mulai',
            value : '',
            type : 'text',
            col : 2,
            required : true,
            show : true,
            errorMsg : '',
            showError : false,
            flexType : 'row',  
            placeholder : '08.00 (string)'  
        },
        end_time : {
            name : 'end_time',
            label : 'Jam Selesai',
            value : '',
            type : 'text',
            col : 2,
            required : true,
            show : true,
            errorMsg : '',
            showError : false,
            flexType : 'row',    
            placeholder : 'selesai (string)'  
        },
        color_label : {
            name : 'color_label',
            label : 'Warna Label',
            value : '',
            type : 'select',
            col : 2,
            required : true,
            show : true,
            errorMsg : '',
            showError : false,
            flexType : 'row',    
            placeholder : 'selesai (string)',
            obj : [{value : '#EB3636', label : 'Merah'}, {value : '#4bae33', label : 'Hijau'}]
        },
        lokasi : {
            name : 'lokasi',
            label : 'Lokasi',
            value : '',
            type : 'text',
            col : 2,
            required : true,
            show : true,
            errorMsg : '',
            showError : false,
            flexType : 'row',    
            placeholder : 'Lokasi ex: Ball Room Sangrila Hotel',
        },
        description : {
            name : 'description',
            label : 'Detail Acara',
            value : '',
            type : 'editor',
            col : 2,
            required : false,
            show : true,
            errorMsg : '',
            showError : false,   
        }
    })

    const fetchData = useCallback(async(q='', offset=0, limit=config.itemPerPage) => {
        setListData(listData => ({...listData, isLoading: true}))
        showLoading();
        try{
            let params = {
                offset : offset,
                limit : limit
            }
            if (q){
                params['q'] = q;
            }
            const res = await api.get(`${config.endPoint.agendaAcara}`, {params: params}).then(res => res.data);
            if (res){
                setListData(listData => ({...listData, isLoading: false, data : res.results}))
                setPageValue(pageValue => ({...pageValue,
                    obj: pageObj(res.count, limit, offset),
                    lastPage: Math.ceil(parseInt(res.count) / parseInt(limit)),                    
                }))
            }else{
                setListData(listData => ({...listData, isLoading: false}))
            }
            closeLoading();
        }catch(error){
            setListData(listData => ({...listData, isLoading: false}))
            closeLoading();
            let msg = "Unable to fetch data";
            const { response } = error;
            if (response && response.data && response.data.message) {
                msg = response.data.message;
            }
            setAlertValue(alertValue => ({...alertValue,
                show : true,
                text : 'error',
                subText : msg,
                color : 'red',
                icon : 'error'
            }))
            catchFetching(error, 'admin')

            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue,
                    show : false
                }))
            }, config.timeOutValue);
        }
        // eslint-disable-next-line 
    },[setPageValue])

    useEffect(() => {
        setTitle('Agenda Acara')
    }, [setTitle])

    useEffect(() => {
        fetchData();
    },[fetchData])

    const handleChangeSearch = (e) => {
        const { name, value } = e.target;
        setPageValue(pageValue => ({...pageValue,
            [name] : value
        }))
    }

    const handleSearch = () => {
        setPageValue(pageValue => ({...pageValue, offset: 0, page : 1}))
        fetchData(pageValue.search, 0, config.itemPerPage)
    }

    const handleKeyDownSearch = (e) => {
        if (e.key === "Enter"){
            handleSearch();
        }
    }

    const handlePaginate = (page) => {
        let myOffset = (parseInt(page) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
        setPageValue(pageValue => ({...pageValue, page : page, offset: myOffset}))
        fetchData(pageValue.search, myOffset, config.itemPerPage);
    }


    const handleCreate = () => {
        setView('create');
        setIsEdit(false);
        setFormData(formData => ({...formData,
            id : {...formData.id, value : ""},
            nama : {...formData.nama, value : ""},
            tanggal : {...formData.tanggal, value : ""},
            start_time : {...formData.start_time, value : ""},
            end_time : {...formData.end_time, value : ""},
            color_label : {...formData.color_label, value : "#EB3636"},
            description : {...formData.description, value : ""},
            lokasi : {...formData.lokasi, value : ""},
        }))
    }


    const handleSubmit = async() => {
        showLoading();
        try{
            let countError = 0;
            let form = {...formData};
            let tmpForm = {}
            Object.entries(form).map(([index, post]) => {
                if (index !== 'redaksional'){
                    if (post.required && (post.value === '' || post.value === null)){
                        post.showError = true;
                        countError++;
                        console.log('ada error ', index, post);
                    }else{
                        tmpForm[index] = post.value;
                    }
                }
                return true;
            });
            
            if (countError > 0){
                setFormData(form);
                closeLoading(false);
                // setAlertValue(alertValue => ({...alertValue, show: true, text : 'Lengkapi Data', color: 'danger'}));
                setAlertValue(alertValue => ({...alertValue, show: true, subText : 'Lengkapi Data', color: 'danger'}));
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue);
                return;
            }            
            

            tmpForm['tanggal'] = Moment(formData.tanggal.value).format('YYYY-MM-DD');
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmpForm));
            
           
            let res;
            if (isEdit){
                res = await api.put(`${config.endPoint.agendaAcara}${formData.id.value}/`, newForm).then(res => res.data);
            }else{
                res = await api.post(`${config.endPoint.agendaAcara}`, newForm).then(res => res.data);
            }
            if (res){
                setView('list');
                fetchData();
            }
            closeLoading();     
        }catch(error){
            closeLoading();                 
            catchFetching(error, 'admin')

            let msg = "Unable to save data";
            if (error.response && error.response.data && error.response.data.message){
                msg = error.response.data.message
            }
            setAlertValue(alertValue => ({...alertValue, show: true, text : "Error", subText : msg, color : 'danger'}))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))                
            }, config.timeOutValue);
        }
    }

    const handleCancel = () => {
        setView('list');
    }

    const handleDelete = (post) => {
        setModalValue(modalValue => ({...modalValue, show: true, id: post.id,
            title: 'Konfirmasi',
            text: `Apakah anda yakin akan menghapus agenda ${post.nama} ?`
        }))
    }

    const handleSubmitDelete = async() => {
        showLoading();
        try{    
            let res = await api.delete(`${config.endPoint.agendaAcara}${modalValue.id}/`).then(res => res.data);
            if (res){
                setModalValue(modalValue => ({...modalValue, show: false}));
                fetchData(pageValue.search, pageValue.offset, config.itemPerPage);
                setAlertValue(alertValue => ({...alertValue,
                    show: true,
                    text : 'Success',
                    subText: 'Data berhasil dihapus',
                    color : 'success'
                }))
            }
            closeLoading();
        }catch(error){
            closeLoading()
        }
    }

    const handleEdit = (post) => {
        setIsEdit(true);
        setView('create')
        setFormData(formData => ({...formData,
            id : {...formData.id, value : post.id},
            nama : {...formData.nama, value : post.nama},
            tanggal : {...formData.tanggal, value : post.tanggal},
            start_time : {...formData.start_time, value : post.start_time},
            end_time : {...formData.end_time, value : post.end_time},
            color_label : {...formData.color_label, value : post.color_label},
            description : {...formData.description, value : post.description},
            lokasi : {...formData.lokasi, value : post.lokasi},
        }))
    }

    

    
    return {
        isLoading, setIsLoading, alertValue, setAlertValue, handleCloseAlert,
        handleCreate, handleSubmit, handleCancel, listData, handleChangeSearch, 
        handleKeyDownSearch, handleSearch, handlePaginate, isEdit, view, pageValue,
        handleEdit, handleDelete, modalValue, handleCloseModal, formData, setFormData,
        setModalValue, handleSubmitDelete
    }
}