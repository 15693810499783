import { useCallback, useContext, useEffect, useState } from "react";
import { useGlobalFunction } from "../../../../generalFunction";
import { useLocation } from "react-router-dom";
import config from "../../../../config";
import api from "../../../../api";
import { pageObj } from "../../../../pageObj";
import * as XLSX from "xlsx";
import { ThemeContext } from "../../../../context/ThemeContext";

export function useHotelPeserta(){
    const { showLoading, closeLoading, alertValue, setAlertValue, handleCloseAlert,
        pageValue, setPageValue, catchFetching, isLoading, setIsLoading, modalValue, setModalValue, handleCloseModal
    } = useGlobalFunction();
    const location = useLocation()
    const { setTitle } = useContext(ThemeContext);

    const [listData, setListData] = useState([]);

    const fetchHotel = useCallback(async() => {
        showLoading();         
        try{
            const res = await api.get(`${config.endPoint.hotel}`).then(res => res.data);
            let tmp = [{value : '', label : '-- Filter Hotel --'}]
            if (res){
                res.results.map(post => tmp.push({value : post.encrypt, label : post.nama}))
            }
            setPageValue(pageValue => ({...pageValue, 
                hotel_obj: tmp,
                hotel : location && location.search ? location.search.replace('?q=', '') : ''
            }))
            closeLoading();                   
        }catch(error){
            closeLoading();                               
            let msg = 'Unable to save Data';
            let { response } = error;            
            
            catchFetching(error, 'admin')            
            if (response && response.data && response.data.message){
                msg = response.data.message
            }
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }));
            }, config.timeOutValue)  
        }
        // eslint-disable-next-line 
    },[setIsLoading, setAlertValue])

    useEffect(() => {
        fetchHotel();
    },[fetchHotel])

    useEffect(() => {
        setTitle('Hotel Peserta')
    }, [setTitle])

    const fetchData = useCallback(async(q='', offset=0, limit=config.itemPerPage, hotel_encrypt='') => {
        showLoading();
        setListData([]);
        try{
            let params = {
                offset : offset,
                limit : limit,
                tipe : 'hotel'
            }
            if (q){
                params['q'] = q;
            }
            if (hotel_encrypt){
                params['hotel_encrypt'] = hotel_encrypt
            }
            
            const res = await api.get(`${config.endPoint.hotelPeserta}`, {params: params}).then(res => res.data );
            if (res){
                setListData(res.results);
                setPageValue(pageValue => ({...pageValue,
                    obj : pageObj(res.count, limit, offset),
                    lastPage : Math.ceil(parseInt(res.count) / parseInt(limit))
                }))
            }
            closeLoading();
            setIsLoading();
        }catch(error){
            closeLoading();
            setIsLoading(false);
            let msg = 'Unable to save Data';
            let { response } = error;            
            
            catchFetching(error, 'admin')            
            if (response && response.data && response.data.message){
                msg = response.data.message
            }
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }));
            }, config.timeOutValue)            
        }
        // eslint-disable-next-line 
    },[setIsLoading, setAlertValue])

    // useEffect(() => {
    //     fetchData();
    // },[fetchData])

    useEffect(() => {
        if (location && location.search){                       
            fetchData('', 0, config.itemPerPage,  location.search.replace('?q=', ''))
        }else{
            fetchData();
        }
    },[fetchData, location])

    const handleDelete = (post) => {
        console.log('post is >>>>', post);
        setModalValue(modalValue => ({...modalValue,
            show: true, text : `Apakah anda yakin akan melakukan revoke perusahaan ${post.nama} an. peserta ${post.peserta_display} pada  ${post.hotel_display}`,
            title : 'konfirmasi',
            id : post.id
        }))
    }

    const handleSubmitDelete = async() => {
        setIsLoading(true);
        try{
            const res = await api.delete(`${config.endPoint.hotelPeserta}${modalValue.id}/`).then(res => res.data);
            if (res){
                fetchData(pageValue.search, pageValue.offset, config.itemPerPage, pageValue.hotel);
                handleCloseModal();
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            catchFetching(error, 'admin');
            setAlertValue(alertValue => ({...alertValue,
                show: true,
                text : 'Unable to delete data',
                color : 'danger'
            }))
        }
    }

    const handleChangeSearch = (e) => {
        const { name, value } = e.target;
        setPageValue(pageValue => ({...pageValue, [name] : value}))        
        if (name === 'hotel'){
            fetchData(pageValue.search, pageValue.offset, config.itemPerPage, value)
        }
    }



    const handleSearch = () => {
        setPageValue(pageValue => ({...pageValue, offset: 0, page : 1}));
        fetchData(pageValue.search, 0, config.itemPerPage, pageValue.hotel)
    }

    const handleKeyDownSearch = (e) => {
        if (e.key === "Enter"){
            handleSearch();
        }
    }

    const handlePaginate = (page) => {
        let myOffset = (parseInt(page) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
        setPageValue(pageValue => ({...pageValue, page : page, offset: myOffset}))
        fetchData(pageValue.search, myOffset, config.itemPerPage, pageValue.hotel);
    }

    const handleDownload = useCallback(async(myOffset=0, limit=2000) => {
        setIsLoading(true);
        try{
            let params = {
                offset : myOffset,
                limit : limit,
                tipe : 'hotel'
            }      
            
            
            const res = await api.get(`${config.endPoint.hotelPeserta}`, {params: params}).then(res => res.data );
            
            var ws = XLSX.utils.aoa_to_sheet([]);
            
            const header = [                
                "Hotel",   
                "Perusahaan",   
                "Peserta",                
            ]
            let tmp = [];            
            if (res){
                res.results.map(post => {                    
                    tmp.push({
                        "Hotel" : post.hotel_display,
                        'Perusahaan' : post.perusahaan_display,
                        'Peserta' : post.peserta_display,
                    })
                    return true;
                })
            }
            XLSX.utils.sheet_add_json(ws, tmp, {header:header, origin:-1});
            const workbook = XLSX.utils.book_new();            
            XLSX.utils.book_append_sheet(workbook, ws, "Sheet1");            
            XLSX.writeFile(workbook, `data-hotel-peserta.xlsx`);
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);                        
            let msg = 'Unable to save Data';
            let { response } = error;            
            
            catchFetching(error, 'admin')            
            if (response && response.data && response.data.message){
                msg = response.data.message
            }
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }));
            }, config.timeOutValue)            
        }
        // eslint-disable-next-line 
    },[setIsLoading, setAlertValue])


    return {
        showLoading, closeLoading, alertValue, setAlertValue, handleCloseAlert, listData,
        pageValue, handleDelete, handleSubmitDelete, handleChangeSearch, handleKeyDownSearch, handlePaginate,
        handleDownload, isLoading, modalValue, handleCloseModal, setModalValue,handleSearch
    }
}