import { Fragment } from "react"
import { useEmailLog } from "./emailLogFunction";
import AlertComp from "../../../../Components/Atoms/AlertComp";
import Card from "../../../../Components/Layout/Card";
import Tables from "../../../../Components/molecules/Tables";
import PagePagination from "../../../../Components/Atoms/PagePagination";
import { Icon } from "@iconify/react/dist/iconify.js";
import Modal from "../../../../Components/Atoms/Modal";


const EmailLogPage = (props) => {
    const kontakId = props && props.kontakId ? props.kontakId : null;
    const perusahaanId = props && props.perusahaanId ? props.perusahaanId : null
    const { alertValue, handleCloseAlert, listData, pageValue, handleChangeSearch, handleSearch,
        handlePaginate, handleDetail, modalValue, setModalValue, emailInfo
    } = useEmailLog({ kontakId: kontakId, perusahaanId: perusahaanId, props: props });
    return (
        <Fragment>
            <Modal
                show={modalValue.show}
                setShow={() => {
                    setModalValue(modalValue => ({ ...modalValue, show: false }))
                }}
                width="xl"
                persistent={false}
                btnClose={true}
                title={"Detail"}
                withHeader={true}
            >
                <div className='p-[20px]'>
                    <div>
                        <table className='table-preview-email-log'>
                            <tbody>
                                <tr>
                                    <td>Tanggal</td>
                                    <td>:</td>
                                    <td>{emailInfo && emailInfo.tanggal_display}</td>
                                </tr>
                                <tr>
                                    <td>Alamat Email</td>
                                    <td>:</td>
                                    <td>{emailInfo && emailInfo.email_json.email}</td>
                                </tr>
                                <tr>
                                    <td>Tujuan</td>
                                    <td>:</td>
                                    <td>{emailInfo && emailInfo.email_json.kepada}</td>
                                </tr>
                                <tr>
                                    <td>Subject</td>
                                    <td>:</td>
                                    <td>{emailInfo && emailInfo.email_json.subject}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='text-[14px] mt-8 bg-gray-200 px-5 py-5'>
                        {emailInfo && emailInfo.email_json &&
                            <div className="content-ckeditor" dangerouslySetInnerHTML={{ __html: emailInfo.email_json.body }} />
                        }
                    </div>
                </div>
            </Modal>
            <AlertComp
                isOpen={alertValue.show}
                onClose={handleCloseAlert}
                text={alertValue.text}
                subText={alertValue.subText}
                color={alertValue.color}
                icon={alertValue.icon}
            />

            <Card
                isCreate={false}
                isSearch={true}
                className={'w-full'}
                onChangeSearch={handleChangeSearch}
                handleSearch={handleSearch}
            >
                <Fragment>
                    <Tables className='border-separate rounded-sm'>
                        <Tables.Head className='bg-[#efeeee]'>
                            <tr>
                                <Tables.Header className='font-bold text-[13px] py-[10px] text-right'>#</Tables.Header>
                                <Tables.Header className='font-bold text-[13px] py-[10px]'>Tanggal</Tables.Header>
                                <Tables.Header className='font-bold text-[13px] py-[10px]'>Perusahaan</Tables.Header>
                                <Tables.Header className='font-bold text-[13px] py-[10px]'>Alamat Email</Tables.Header>
                                <Tables.Header className='font-bold text-[13px] py-[10px]'>Subject</Tables.Header>
                                <Tables.Header className='font-bold text-[13px] py-[10px]'>Action</Tables.Header>
                            </tr>
                        </Tables.Head>
                        <Tables.Body>
                            {listData.length === 0 ?
                                <Tables.Row>
                                    <Tables.Data colspan={7} className='text-center'>Belum ada data</Tables.Data>
                                </Tables.Row>
                                :
                                <Fragment>
                                    {listData.map((post, index) => {
                                        return (
                                            <Tables.Row key={index}>
                                                <Tables.Data className='text-right'>{pageValue.offset + index + 1}</Tables.Data>
                                                <Tables.Data>{post.tanggal_display}</Tables.Data>
                                                <Tables.Data>{post.perusahaan_display}</Tables.Data>
                                                <Tables.Data>{post.email_json.email}</Tables.Data>
                                                <Tables.Data>{post.email_json.subject}</Tables.Data>
                                                <Tables.Data>
                                                    <button className='p-[8px] bg-purple-100 text-purple-600 rounded shadow-lg text-[18px]' onClick={() => handleDetail(post)}><Icon icon='solar:eye-outline' /></button>
                                                </Tables.Data>
                                            </Tables.Row>
                                        )
                                    })}
                                </Fragment>
                            }
                        </Tables.Body>
                    </Tables>
                    <div className='mt-5 flex flex-row justify-start w-full'>
                        <PagePagination
                            data={pageValue.obj}
                            handlePagination={handlePaginate}
                            page={pageValue.page}
                            lastPage={pageValue.lastPage}
                        />
                    </div>
                </Fragment>
            </Card>
        </Fragment>
    )
}
export default EmailLogPage;