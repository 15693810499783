import { Fragment } from "react";
import Card from "../../../../Components/Layout/Card";
import { useRegistrasiDetail } from "./dataRegistrasiDetailFunction";
import AlertComp from "../../../../Components/Atoms/AlertComp";
import Button from "../../../../Components/Atoms/Button";
import Modal from "../../../../Components/Atoms/Modal";
import FormTextSingleComp from "../../../../Components/forms/FormTextSingleComp";
import Tables from "../../../../Components/molecules/Tables";
import PagePagination from "../../../../Components/Atoms/PagePagination";
import { useMenu } from "../../../../constants/menuFunction";

const DataRegistrasiDetailPage = () => {
  const {
    alertValue,
    handleCloseAlert,
    info,
    handleChangeView,
    view,
    akta,
    kuasa,
    foto,
    pernyataan,
    handleReject,
    handleActionApprove,
    modalValue,
    setModalValue,
    formEmail,
    handleSubmitReject,
    handleCloseModal,
    loadingAkta,
    loadingDokumen,
    loadingFoto,
    listApproveHistory,
    handlePaginateHistoryApprove,
    listEmailHistory,
    handleCancel,
    setFormEmail,
  } = useRegistrasiDetail();

  const { listPermissions, isSuperUser } = useMenu();

  const getColorLocal = (params) => {
    if (params === 1) {
      return "text-green-600 bg-green-100";
    } else if (params === 2) {
      return "text-red-600 bg-red-100";
    } else if (params === 3) {
      return "text-white bg-gray-500";
    } else {
      return "text-white bg-black/40";
    }
  };

  return (
    <Fragment>

      <Modal
        show={modalValue.show}
        setShow={() => {
          setModalValue((modalValue) => ({ ...modalValue, show: false }));
        }}
        width="xl"
        persistent={false}
        btnClose={false}
        title={modalValue.title}
        withHeader={true}
      >
        <div className="flex items-center justify-center m-1">
          <FormTextSingleComp
            {...formEmail.alasan}
            formData={formEmail}
            flexType={'column'}
            setFormData={setFormEmail}
            hideLabel={true}
            className="bg-blue-500 ml-10"
          />
        </div>
        <div className="border-t border-t-gray-200 p-[20px]">
          <div className="flex flex-row justify-end gap-2">
            <Button
              disabled={loadingFoto || loadingAkta || loadingDokumen}
              className="bg-red-500 text-white"
              onClick={handleSubmitReject}
            >
              Submit
            </Button>
            <Button
              disabled={loadingFoto || loadingAkta || loadingDokumen}
              className="bg-gray-400 text-white"
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
      <AlertComp
        text={alertValue.text}
        subText={alertValue.subText}
        color={alertValue.color}
        isOpen={alertValue.show}
        onClose={handleCloseAlert}
      />
      <Card isSearch={false} isCreate={false} title="Data Registrasi Detail">
        <div className="flex flex-row gap-3">
          <div className="w-40">
            <img src={info?.data && info?.data.foto} alt="foto" />
          </div>
          <div className="">
            <table className="table-registrasi-detail">
              <tbody>
                <tr>
                  <td>Nama</td>
                  <td>:</td>
                  <td>{info?.data && info?.data.nama}</td>
                </tr>
                <tr>
                  <td>Nomor HP</td>
                  <td>:</td>
                  <td>{info?.data && info?.data.hp}</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>:</td>
                  <td>{info?.data && info?.data.email}</td>
                </tr>
                <tr>
                  <td>Jabatan</td>
                  <td>:</td>
                  <td>{info?.data && info?.data.jabatan_display}</td>
                </tr>
                <tr>
                  <td>Perusahaan</td>
                  <td>:</td>
                  <td>{info?.data && info?.data.perusahaan_display}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Card>

      <div className="my-5 flex flex-row gap-2 justify-between">
        <div className="flex flex-row flex-wrap gap-2">

          <Button
            size="sm"
            onClick={() => handleChangeView("dokumen")}
            color={view === "dokumen" ? "primary" : "base"}
          >
            Dokumen
          </Button>
          <Button
            size="sm"
            onClick={() => handleChangeView("approval")}
            color={view === "approval" ? "primary" : "base"}
          >
            Approval History
          </Button>
          <Button
            size="sm"
            onClick={() => handleChangeView("email")}
            color={view === "email" ? "primary" : "base"}
          >
            Email History
          </Button>
          <Button size="sm" onClick={handleCancel} color={"warning"}>
            Kembali
          </Button>
        </div>
        <div className="text-sm font-semibold text-black">Menampilkan Data Akta, Surat Pernyataan & Foto</div>
      </div>
      {view === "email" && (
        <Card isCreate={false} isSearch={false}>
          <div className="pb-4 border-b border-b-gray-200 mb-4">
            <div className="font-semibold">Email History</div>
          </div>
          <Tables className="border-separate rounded-sm">
            <Tables.Head className="bg-[#efeeee]">
              <tr>
                <Tables.Header className="font-bold text-[13px] py-[10px] text-right">
                  #
                </Tables.Header>
                <Tables.Header className="font-bold text-[13px] py-[10px]">
                  Tanggal
                </Tables.Header>
                <Tables.Header className="font-bold text-[13px] py-[10px]">
                  Kepada
                </Tables.Header>
                <Tables.Header className="font-bold text-[13px] py-[10px]">
                  Alamat Email
                </Tables.Header>
                <Tables.Header className="font-bold text-[13px] py-[10px]">
                  Subject
                </Tables.Header>
              </tr>
            </Tables.Head>
            <Tables.Body>
              {listEmailHistory?.data?.length === 0 ? (
                <Tables.Row>
                  <Tables.Data colspan={6} className="text-center">
                    Belum ada data
                  </Tables.Data>
                </Tables.Row>
              ) : (
                <Fragment>
                  {listEmailHistory?.data?.map((post, index) => {
                    return (
                      <Tables.Row key={index}>
                        <Tables.Data className="text-right">
                          {listEmailHistory.offset + index + 1}
                        </Tables.Data>
                        <Tables.Data>{post?.tanggal_display}</Tables.Data>
                        <Tables.Data>{post?.email_json?.kepada}</Tables.Data>
                        <Tables.Data>{post?.email_json?.email}</Tables.Data>
                        <Tables.Data>{post?.email_json?.subject}</Tables.Data>
                      </Tables.Row>
                    );
                  })}
                </Fragment>
              )}
            </Tables.Body>
          </Tables>
        </Card>
      )}
      {view === "approval" && (
        <Card isCreate={false} isSearch={false}>
          <div className="pb-4 border-b border-b-gray-200 mb-4">
            <div className="font-semibold">Approval History</div>
          </div>
          <Tables className="border-separate rounded-sm">
            <Tables.Head className="bg-[#efeeee]">
              <tr>
                <Tables.Header className="font-bold text-[13px] py-[10px] text-right">
                  #
                </Tables.Header>
                <Tables.Header className="font-bold text-[13px] py-[10px]">
                  Tanggal
                </Tables.Header>
                <Tables.Header className="font-bold text-[13px] py-[10px]">
                  Diskripsi
                </Tables.Header>
                <Tables.Header className="font-bold text-[13px] py-[10px]">
                  Peserta
                </Tables.Header>
                <Tables.Header className="font-bold text-[13px] py-[10px]">
                  Status
                </Tables.Header>
                <Tables.Header className="font-bold text-[13px] py-[10px]">
                  Alasan
                </Tables.Header>
                <Tables.Header className="font-bold text-[13px] py-[10px]">
                  Pic
                </Tables.Header>
              </tr>
            </Tables.Head>
            <Tables.Body>
              {listApproveHistory?.data?.length === 0 ? (
                <Tables.Row>
                  <Tables.Data colspan={6} className="text-center">
                    Belum ada data
                  </Tables.Data>
                </Tables.Row>
              ) : (
                <Fragment>
                  {listApproveHistory?.data?.map((post, index) => {
                    return (
                      <Tables.Row key={index}>
                        <Tables.Data className="text-right">
                          {listApproveHistory.offset + index + 1}
                        </Tables.Data>
                        <Tables.Data>{post.tanggal_display}</Tables.Data>
                        <Tables.Data>{post.diskripsi}</Tables.Data>
                        <Tables.Data>{post.kontak_display}</Tables.Data>
                        <Tables.Data>{post.status_display}</Tables.Data>
                        <Tables.Data><div className="content-ckeditor" dangerouslySetInnerHTML={{ __html: post?.alasan }}></div></Tables.Data>
                        <Tables.Data>{post.pic_display}</Tables.Data>
                      </Tables.Row>
                    );
                  })}
                </Fragment>
              )}
            </Tables.Body>
          </Tables>
          <div className="mt-5 flex flex-row justify-start w-full">
            <PagePagination
              data={listApproveHistory.obj}
              handlePagination={handlePaginateHistoryApprove}
              page={listApproveHistory.page}
              lastPage={listApproveHistory.lastPage}
            />
          </div>
        </Card>
      )}
      {view === "dokumen" && (
        <div className="grid grid-cols-12 gap-2 w-full ">

          <div className="col-span-full md:col-span-6">
            {loadingAkta && (
              <div className="absolute top-0 left-0 w-full bg-black z-[80] h-[3px] progress-bar rounded-t-md"></div>
            )}
            <Card
              isSearch={false}
              isCreate={false}
              className={"w-full"}
              bodyStyle={{ marginTop: 0 }}
            >
              <div className="pb-4 border-b border-b-gray-200 mb-4">
                <div className="font-semibold">Akta</div>
              </div>
              {akta ? (
                <iframe
                  src={`${akta}`}
                  width="100%"
                  height="500px"
                  title="akta"
                ></iframe>
              ) : (
                <div className="text-[16px]">Belum ada akta di upload</div>
              )}
              <div className="border-t border-t-gray-200 pt-5 pb-2">
                <div className="flex flex-row justify-between items-start">
                  <div className="flex flex-col items-start justify-start flex-1">
                    <div>
                      <div className="text-[13px]">Status</div>
                      <div
                        className={`${getColorLocal(
                          info?.data?.status_akta
                        )} px-2 text-sm roundex`}
                      >
                        {info?.data?.status_akta_display}
                      </div>
                    </div>
                    {info?.data?.detail_display?.alasan_akta && (
                      <div className="mt-2">
                        <div className="text-[13px]">Alasan</div>
                        <div className="content-ckeditor" dangerouslySetInnerHTML={{ __html: info?.data?.detail_display?.alasan_akta }}></div>
                      </div>
                    )}
                  </div>
                  {(listPermissions.includes("change_kontak") ||
                    isSuperUser) && (
                      <div className="flex flex-row flex-1 justify-end gap-1">
                        <Button
                          disabled={loadingAkta}
                          color="primary"
                          onClick={() => handleActionApprove(info?.data, "akta")}
                        >
                          Setujui
                        </Button>
                        <Button
                          disabled={loadingAkta}
                          color="danger"
                          onClick={() =>
                            handleReject(
                              info?.data,
                              info?.data?.status_akta,
                              "akta"
                            )
                          }
                        >
                          Tolak
                        </Button>
                      </div>
                    )}
                </div>
              </div>
            </Card>
          </div>

          <div className="col-span-full md:col-span-6">
            {loadingDokumen && (
              <div className="absolute top-0 left-0 w-full bg-black z-[80] h-[3px] progress-bar rounded-t-md"></div>
            )}
            <Card isSearch={false} isCreate={false} className={"w-full"}>
              <div className="pb-4 border-b border-b-gray-200 mb-4">
                <div className="font-semibold">
                  {info && info?.data?.jabatan === 1
                    ? "Surat Pernyataan"
                    : "Surat Kuasa"}
                </div>
              </div>
              {pernyataan || kuasa ? (
                <iframe
                  src={`${info?.data?.jabatan === 1 ? pernyataan : kuasa}`}
                  width="100%"
                  height="500px"
                  title={
                    info?.data?.jabatan === 1
                      ? "surat-pernyataan"
                      : "surat-kuasa"
                  }
                ></iframe>
              ) : (
                <div className="text-[16px]">
                  Belum ada{" "}
                  {info?.data?.jabatan === 1
                    ? "Surat Pernyataan"
                    : "Surat Kuasa"}{" "}
                  di upload
                </div>
              )}
              <div className="border-t border-t-gray-200 pt-5 pb-2">
                <div className="flex flex-row justify-between items-start">
                  <div className="flex flex-col items-start justify-start flex-1">
                    <div>
                      <div className="text-[13px]">Status</div>
                      <div
                        className={`${getColorLocal(
                          info?.data?.jabatan === 1
                            ? info?.data?.detail_display
                              ?.status_surat_pernyataan
                            : info?.data?.detail_display?.status_surat_kuasa
                        )} px-2 text-sm roundex`}
                      >
                        {info?.data?.jabatan === 1
                          ? info?.data?.detail_display
                            ?.status_surat_pernyataan_display
                          : info?.data?.detail_display
                            ?.status_surat_kuasa_display}
                      </div>
                    </div>
                    {(info?.data?.detail_display?.alasan_surat_kuasa ||
                      info?.data?.detail_display?.alasan_surat_pernyataan) && (
                        <div className="mt-2">
                          <div className="text-[13px]">Alasan</div>
                          <div
                            className="content-ckeditor"
                            dangerouslySetInnerHTML={{
                              __html: info?.data?.jabatan === 1
                                ? info?.data?.detail_display?.alasan_surat_pernyataan
                                : info?.data?.detail_display?.alasan_surat_kuasa,
                            }}
                          ></div>
                        </div>

                      )}
                  </div>
                  {(listPermissions.includes("change_kontak") ||
                    isSuperUser) && (
                      <div className="flex flex-row flex-1 justify-end gap-1">
                        <Button
                          disabled={loadingDokumen}
                          color="primary"
                          onClick={() =>
                            handleActionApprove(
                              info?.data,
                              info?.data?.jabatan === 1
                                ? "surat_pernyataan"
                                : "surat_kuasa"
                            )
                          }
                        >
                          Setujui
                        </Button>
                        <Button
                          disabled={loadingDokumen}
                          color="danger"
                          onClick={() =>
                            handleReject(
                              info?.data,
                              info?.data?.jabatan === 1
                                ? info?.data?.detail_display
                                  ?.status_surat_pernyataan
                                : info?.data?.detail_display
                                  ?.status_surat_pernyataan,
                              info?.data?.jabatan === 1
                                ? "surat_pernyataan"
                                : "surat_kuasa"
                            )
                          }
                        >
                          Tolak
                        </Button>
                      </div>
                    )}
                </div>
              </div>
            </Card>
          </div>

          <div className="col-span-full md:col-span-6">
            {loadingFoto && (
              <div className="absolute top-0 left-0 w-full bg-black z-[80] h-[3px] progress-bar rounded-t-md"></div>
            )}
            <Card isSearch={false} isCreate={false} className={"w-full"}>
              <div className="pb-4 border-b border-b-gray-200 mb-4">
                <div className="font-semibold">
                  Foto
                </div>
              </div>
              {foto ? (
                <img
                  src={`${foto}`}
                  width="50%"
                  className="mx-auto"
                  alt="foto"
                ></img>
              ) : (
                <div className="text-[16px]">
                  Belum ada foto yang
                  di upload
                </div>
              )}
              <div className="border-t border-t-gray-200 pt-5 pb-2">
                <div className="flex flex-row justify-between items-start">
                  <div className="flex flex-col items-start justify-start flex-1">
                    <div>
                      <div className="text-[13px]">Status</div>
                      <div
                        className={`${getColorLocal(info?.data?.detail_display?.status_foto
                        )} px-2 text-sm roundex`}
                      >
                        {info?.data?.detail_display?.status_foto_display}

                      </div>
                    </div>
                    {info?.data?.detail_display?.alasan_foto && (
                      <div className="mt-2">
                        <div className="text-[13px]">Alasan</div>
                        <div className="content-ckeditor" dangerouslySetInnerHTML={{ __html: info?.data?.detail_display?.alasan_foto }}></div>
                      </div>
                    )}
                  </div>
                  {(listPermissions.includes("change_kontak") ||
                    isSuperUser) && (
                      <div className="flex flex-row flex-1 justify-end gap-1">
                        <Button
                          disabled={loadingFoto}
                          color="primary"
                          onClick={() =>
                            handleActionApprove(info?.data, "foto")
                          }
                        >
                          Setujui
                        </Button>
                        <Button
                          disabled={loadingFoto}
                          color="danger"
                          onClick={() =>
                            handleReject(
                              info?.data,
                              info?.data?.status_foto,
                              "foto"
                            )
                          }
                        >
                          Tolak
                        </Button>
                      </div>
                    )}
                </div>
              </div>
            </Card>
          </div>
        </div>
      )}
    </Fragment>
  );
};
export default DataRegistrasiDetailPage;
