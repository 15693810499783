import { useAgenda } from "./agendaFunction";
import Modal from "../../../Components/Atoms/Modal";
import Button from "../../../Components/Atoms/Button";
import { Helmet } from "react-helmet";
import JadwalHtml from "../../../Components/Atoms/JadwalHtml";

const AgendaPage = () => {
  const {
    modalValue,
    setModalValue,
    handleCloseModal,
  } = useAgenda();

  return (
    <div className="w-full h-fit text-black relative flex flex-col items-center mt-[80px] md:mt-[100px]">
      <Modal
        show={modalValue.show}
        setShow={() => {
          setModalValue((modalValue) => ({ ...modalValue, show: false }));
        }}
        width="xl"
        persistent={false}
        btnClose={false}
        title={modalValue.title}
        withHeader={true}
      >
        <div className="p-[20px] text-sm w-full">
          <div className="w-full mb-[30px] border-b border-b-gray-300 pb-[30px]">
            <table className="w-full">
              <tbody>
                <tr>
                  <td>Agenda</td>
                  <td>:</td>
                  <td>{modalValue.content?.nama}</td>
                </tr>
                <tr>
                  <td>Tanggal</td>
                  <td>:</td>
                  <td>
                    {modalValue.content?.tanggal_display}{" "}
                    {modalValue.content?.start_time} s.d{" "}
                    {modalValue.content?.end_time}
                  </td>
                </tr>
                <tr>
                  <td>Lokasi</td>
                  <td>:</td>
                  <td>{modalValue.content?.lokasi}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="mb-[20px] font-bold text-[15px]">Detail Agenda</div>
          <div
            className="content-ckeditor"
            dangerouslySetInnerHTML={{ __html: modalValue.content.description }}
          />
          <Helmet>
            <style type="text/css">
              {`
                ol {
                  list-style-type: decimal; 
                  margin-left: 15px;
                }
              `}
            </style>
          </Helmet>
        </div>
        <div className="border-t border-t-gray-200 p-[20px]">
          <div className="flex flex-row justify-end gap-2">
            <Button
              className="bg-gray-400 text-white"
              onClick={handleCloseModal}
            >
              Close
            </Button>
          </div>
        </div>
      </Modal>
      <div className="w-full text-center max-w-homeWidth px-[40px] md:px-[100px] mb-5">
        <div className="font-bold title md:text-[24px] text-[14px]">
          Agenda Musyawarah Nasional XII APJII Tahun 2024
        </div>
      </div>
      <div className="w-full flex justify-center p-5">
        <JadwalHtml />
      </div>
    </div>
  );
};

export default AgendaPage;
