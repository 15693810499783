import { 
    useCallback,
    useEffect,
    // useCallback, useEffect, 
useState } from "react";
import { useGlobalFunction } from "../../../generalFunction";
import config from "../../../config";
import { pageObj } from "../../../pageObj";
import api from "../../../api";
// import config from "../../../config";
// import api from "../../../api";
// import { pageObj } from "../../../pageObj";


export function useMemberCalon({fetchTipe='dewas'}){    
    const { isLoading, setIsLoading, pageValue, setPageValue, alertValue, setAlertValue, handleCloseAlert } = useGlobalFunction();
    const [listData, setListData] = useState({isLoading: false, data: []})

    const fetchData = useCallback(async(q='', offset=0, limit=10) => {        
        try{
            const params = {
                offset : offset,
                limit : limit,
            }
            if (q) {
                params['q'] = q
            }
            let res
            if (fetchTipe === 'dewas'){
                res = await api.get(`${config.endPoint.kandidatDewas}`, {params: params}).then(res => res.data);
            }else{
                res = await api.get(`${config.endPoint.kandidatKetum}`, {params: params}).then(res => res.data);
            }

            if (res){
                setListData(listData => ({...listData, isLoading: false, data: res.results}))
                setPageValue(pageValue => ({...pageValue,
                    obj : pageObj(res.count, limit, offset),
                    lastPage : Math.ceil(parseInt(res.count) / parseInt(limit))
                }))
            }else{
                setListData(listData => ({...listData, isLoading: false}))
            }            
        }catch(error){
            setListData(listData => ({...listData, isLoading: false}))            
            const { response } = error;
            let msg = 'Unable to fetch data'
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            setAlertValue(alertValue => ({...alertValue,
                show: true,
                text : 'Error',
                subText: msg,
                color: 'danger'
            }))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
        // eslint-disable-next-line 
    },[fetchTipe])

    useEffect(() => {
        fetchData();
    },[fetchData])

    const handlePaginate = (page) => {
        let myOffset = (parseInt(page) * parseInt(10)) - parseInt(10)
        setPageValue(pageValue => ({...pageValue, page : page, offset: myOffset}))
        fetchData(pageValue.search, myOffset, 10)
        // fetchData(pageValue.search, myOffset, 10);
    }

    return {
        isLoading, setIsLoading, pageValue, setPageValue, alertValue, setAlertValue, handleCloseAlert, listData,
        handlePaginate, setListData
    }
}