import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { TbSearch } from "react-icons/tb";

const Search = (props) => {
    const { placeholder,
        onChange,
        handleSearch,
        value,
        inputClassName,
        className
    } = props;
    return(
        <Fragment>
            <div className={`relative  w-full flex justify-end ${className}`}>
                <input 
                    className={`border border-gray-300 rounded-[6px] py-[10px] pl-[14px]  w-full ${inputClassName} text-[14px] block leading-tight 
                    focus:outline-1 
                    focus:outline-none 
                    focus:outline-blue-400 focus:border-none placehodel:pl-1 placeholder:opacity-50`}
                    placeholder={placeholder} 
                    onChange={onChange}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter'){
                            handleSearch()
                        }
                    }}
                    value={value}     
                    name="search"               
                />
                <button className="absolute bottom-[0px] right-[10px] h-full" onClick={handleSearch}>
                    <div className='flex flex-row items-center'>
                        <TbSearch className='text-xl'/>
                    </div>
                </button>
            </div>
        </Fragment>
    )
}

Search.propTypes = {
    placeholder : PropTypes.string,
    onChange: PropTypes.func.isRequired,
    handleSearch: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    inputClassName: PropTypes.string,
    className: PropTypes.string,
}

export default Search