import { useEffect, useState, useRef } from "react";
import { CKEditor } from "ckeditor4-react";

const CKEditorComponent = ({ value, onChangeEditor, name, toolbarConfig }) => {
    const [isLoadingCkeditor, setIsLoadingCkeditor] = useState(true); // State untuk loading indicator
    const editorRef = useRef(null); // Reference untuk CKEditor instance

    // Fungsi ketika CKEditor siap
    const onEditorReady = (event) => {
        setIsLoadingCkeditor(false); // Matikan loading indicator
        editorRef.current = event.editor; // Simpan instance editor ke ref
    };

    // useEffect untuk memperbarui data editor ketika prop `value` berubah
    useEffect(() => {
        if (editorRef.current && value !== editorRef.current.getData()) {
            editorRef.current.setData(value); // Set konten editor secara manual ketika value berubah
        }
    }, [value]); // Hanya dijalankan ketika prop `value` berubah

    // Fungsi ketika ada perubahan di CKEditor
    const handleEditorChange = (event) => {
        const data = event.editor.getData();
        onChangeEditor(data, name); // Kirim perubahan ke handler parent
    };

    return (
        <>
            {isLoadingCkeditor && <div className="animate-pulse">Loading ....</div>}

            <CKEditor
                config={toolbarConfig}
                type="classic"
                initData={value} // Inisialisasi pertama kali dengan value
                onInstanceReady={onEditorReady} // Simpan instance CKEditor ke ref
                onChange={handleEditorChange} // Ketika nilai editor berubah
            />
        </>
    );
};

export default CKEditorComponent;
