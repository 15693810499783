import { useCallback, useContext, useEffect, useState } from "react";
import { useGlobalFunction } from "../../../../generalFunction";
import config from "../../../../config";
import api from "../../../../api";
import { pageObj } from "../../../../pageObj";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../../context/UserContext";
import { ThemeContext } from "../../../../context/ThemeContext";

export function useDataRegistrasi() {
    const {
        alertValue, setAlertValue, handleCloseAlert, showLoading, closeLoading, isLoading, setIsLoading, catchFetching,
        pageValue, setPageValue, modalValue, setModalValue, handleCloseModal
    } = useGlobalFunction();
    const [listData, setListData] = useState({
        data: [],
        isLoading: true,
    })
    const { setTitle } = useContext(ThemeContext);
    const navigate = useNavigate();
    const [dokumen, setDokumen] = useState({
        tipe: '',
        url: ''
    });
    const myState = useContext(UserContext);
    const [modalState, modalDispatch] = myState.modalState
    const [dataSelected, setDataSelected] = useState({
        data: null,
        tipe: '',
        status: '',
        tipe_display: '',
        status_display: '',
        alasan: '',
    })
    const [isShowAkta, setIsShowAkta] = useState(true)
    const [isShowPernyataan, setIsShowPernyataan] = useState(true);
    const [isShowKuasa, setIsShowKuasa] = useState(true);
    const [loadingReject, setLoadingReject] = useState(false);
    const [cropData, setCropData] = useState(null)

    // Callback untuk menangani hasil crop
    const handleCropComplete = (completedCrop) => {
        setCropData(completedCrop)
    }

    useEffect(() => {
        setTitle('Data Registrasi Peserta')
    }, [setTitle])

    const fetchData = useCallback(async (q = '', offset = 0, limit = config.itemPerPage, status_compete = '', is_lock = '', show_akta = false, show_kuasa = false, show_pernyataan = false) => {
        setIsLoading(true);
        showLoading();
        setListData(listData => ({ ...listData, isLoading: true }));
        try {
            let params = {
                offset: offset,
                limit: limit,
                tipe: 'peserta'
            }
            if (q) {
                params['q'] = q;
            }
            if (status_compete) {
                params['status_complete'] = status_compete
            }
            if (is_lock) {
                params['is_lock'] = is_lock
            }

            // if (isComplete){
            //     params['status_complete'] = "complete"
            // }
            const res = await api.get(`${config.endPoint.dataRegistrasi}`, { params: params }).then(res => res.data);
            if (res) {
                let tmp = [];
                res.results.map(post => {
                    let obj = { ...post };
                    obj['show_alasan_akta'] = show_akta;
                    obj['show_alasan_pernyataan'] = show_pernyataan;
                    obj['show_alasan_kuasa'] = show_kuasa;
                    tmp.push(obj);
                    return true;
                })
                setListData(listData => ({
                    ...listData,
                    isLoading: false,
                    data: tmp
                }))
                setPageValue(pageValue => ({
                    ...pageValue,
                    obj: pageObj(res.count, limit, offset),
                    lastPage: Math.ceil(parseInt(res.count) / parseInt(limit))
                }));
                // setLockState(res.status_lock)
            } else {
                setListData(listData => ({ ...listData, isLoading: false }));
            }
            setIsLoading(false);
            closeLoading();
        } catch (error) {
            closeLoading();
            setListData(listData => ({ ...listData, isLoading: false }));
            setIsLoading(false);
            let msg = 'Unable to save Data';
            let { response } = error;

            catchFetching(error, 'admin')
            if (response && response.data && response.data.message) {
                msg = response.data.message
            }
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }));
            }, config.timeOutValue)
        }
        // eslint-disable-next-line 
    }, [setIsLoading, setAlertValue])

    useEffect(() => {
        let { tmpPage, tmpSearch, tmpLock, tmpComplete, myOffset } = ''
        if (sessionStorage.getItem('statePage')) {
            tmpPage = sessionStorage.getItem('statePage') || '';
            myOffset = (parseInt(tmpPage) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
            setPageValue(pageValue => ({ ...pageValue, offset: myOffset, page: tmpPage }))
        }
        if (sessionStorage.getItem('stateSearch')) {
            tmpSearch = sessionStorage.getItem('stateSearch') || '';
            setPageValue(pageValue => ({ ...pageValue, search: tmpSearch }))
        }
        if (sessionStorage.getItem('stateLock')) {
            tmpLock = sessionStorage.getItem('stateLock') || '';
            setPageValue(pageValue => ({ ...pageValue, is_lock: tmpLock }))
            // setPageValue(pageValue => ({...pageValue, search : tmpSearch}))
        }
        if (sessionStorage.getItem('stateComplete')) {
            tmpComplete = sessionStorage.getItem('stateComplete') || '';
            setPageValue(pageValue => ({ ...pageValue, complete: tmpComplete }))
        }
        
        fetchData(tmpSearch, myOffset ? myOffset : 0, config.itemPerPage, tmpComplete, tmpLock, isShowAkta, isShowKuasa, isShowPernyataan);
        // eslint-disable-next-line
    }, [fetchData])


    const handleOpenAlasanAkta = (index) => {
        let array = [...listData.data];
        array[index] = { ...array[index], show_alasan_akta: !array[index].show_alasan_akta };
        setListData(listData => ({
            ...listData,
            data: array
        }))
    }

    const handleOpenAlasanPernyataan = (index) => {
        let array = [...listData.data];
        array[index] = { ...array[index], show_alasan_pernyataan: !array[index].show_alasan_pernyataan };
        setListData(listData => ({
            ...listData,
            data: array
        }))
    }

    const handleOpenAlasanKuasa = (index) => {
        let array = [...listData.data];
        array[index] = { ...array[index], show_alasan_kuasa: !array[index].show_alasan_kuasa };
        setListData(listData => ({
            ...listData,
            data: array
        }))
    }

    const handlePaginate = (page) => {
        let myOffset = (parseInt(page) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
        setPageValue(pageValue => ({ ...pageValue, page: page, offset: myOffset }))
        fetchData(pageValue.search, myOffset, config.itemPerPage, pageValue.complete, pageValue.is_lock, isShowAkta, isShowKuasa, isShowPernyataan);
        sessionStorage.setItem('statePage', page)
        // fetchData = useCallback(async(q='', offset=0, limit=config.itemPerPage, status_compete='', is_lock='', show_akta=false, show_kuasa=false, show_pernyataan=false 
    }

    const handleShowAlasanAkta = () => {
        setIsShowAkta(!isShowAkta)
        let tmp = []
        listData.data.map(post => {
            let obj = { ...post }
            obj['show_alasan_akta'] = !isShowAkta
            tmp.push(obj);
            return true;
        })
        setListData(listData => ({ ...listData, data: tmp }));
    }

    const handleShowAlasanPernyataan = () => {
        setIsShowPernyataan(!isShowPernyataan)
        let tmp = []
        listData.data.map(post => {
            let obj = { ...post }
            obj['show_alasan_pernyataan'] = !isShowPernyataan
            tmp.push(obj);
            return true;
        })
        setListData(listData => ({ ...listData, data: tmp }));
    }

    const handleShowAlasanKuasa = () => {
        setIsShowKuasa(!isShowKuasa)
        let tmp = []
        listData.data.map(post => {
            let obj = { ...post }
            obj['show_alasan_kuasa'] = !isShowKuasa
            tmp.push(obj);
            return true;
        })
        setListData(listData => ({ ...listData, data: tmp }));
    }

    const handleChangeSearch = (e) => {
        const { name, value } = e.target;
        setPageValue(pageValue => ({ ...pageValue, [name]: value }))
        // console.log('pageValue >>>>>', pageValue.is_lock)
        if (name === 'complete') {
            sessionStorage.setItem('stateComplete', value)
            sessionStorage.setItem('statePage', 1)
            fetchData(pageValue.search, 0, config.itemPerPage, value, pageValue.is_lock, isShowAkta, isShowKuasa, isShowPernyataan);
            setPageValue(pageValue => ({ ...pageValue, page: 1, offset: 0 }))
        }
        if (name === 'is_lock') {
            sessionStorage.setItem('stateLock', value)
            sessionStorage.setItem('statePage', 1)
            fetchData(pageValue.search, 0, config.itemPerPage, pageValue.complete, value, isShowAkta, isShowKuasa, isShowPernyataan);
            setPageValue(pageValue => ({ ...pageValue, page: 1, offset: 0 }))
        }
        if (name === 'search') {
            sessionStorage.setItem('stateSearch', value)
        }
    }

    const handleSearch = () => {
        setPageValue(pageValue => ({ ...pageValue, offset: 0, page: 1 }));
        fetchData(pageValue.search, 0, config.itemPerPage, pageValue.complete, pageValue.is_lock, isShowAkta, isShowKuasa, isShowPernyataan);

        // fetchData = useCallback(async(q='', offset=0, limit=config.itemPerPage, status_compete='', is_lock='', show_akta=false, show_kuasa=false, show_pernyataan=false
    }

    const handleKeyDownSearch = (e) => {
        if (e.key === "Enter") {
            handleSearch();
        }
    }

    const handleShowDocument = (tipe, params) => {
        let obj = { ...params }
        if (tipe === 'akta') {
            obj['myUrl'] = params.akta_url
        } else if (tipe === 'surat_pernyataan') {
            obj['myUrl'] = params.detail_display.surat_pernyataan
        } else if (tipe === 'surat_kuasa') {
            obj['myUrl'] = params.detail_display.surat_kuasa
        }

        let data = JSON.stringify(obj);
        localStorage.setItem('documentInfo', data);
        let url = "/admin/registrasi/showdocument"
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null

    }

    const handleViewDocument = (tipe, params) => {
        let title = ""
        if (tipe === 'akta') {
            setDokumen(dokumen => ({
                ...dokumen,
                tipe: 'dokumen',
                url: params.akta_url,
                alasan: [2, 3].includes(params.status_akta) ? params.detail_display.alasan_akta : ''
            }))
            setDataSelected(dataSelected => ({
                ...dataSelected,
                data: params,
                tipe: 'akta',
                tipe_display: 'Akta',
                status: params.status_akta,
                status_display: params.status_akta_display
            }))
            title = `Akta Perusahaan ${params.perusahaan_display}`
            tipe = 'dokumen'
        } else if (tipe === 'surat_pernyataan') {
            setDokumen(dokumen => ({
                ...dokumen,
                tipe: 'dokumen',
                url: params.surat_pernyataan_url,
                alasan: [2, 3].includes(params.detail_display.status_surat_pernyataan) ? params.detail_display.alasan_surat_pernyataan : ''
            }))
            setDataSelected(dataSelected => ({
                ...dataSelected,
                data: params,
                tipe: 'surat_pernyataan',
                tipe_display: 'Surat Pernyataan',
                status: params.detail_display.status_surat_pernyataan,
                status_display: params.detail_display.status_surat_pernyataan_display,
            }))
            title = `Surat Pernyataan ${params.nama}`
            tipe = 'dokumen'
        } else if (tipe === 'surat_kuasa') {
            setDokumen(dokumen => ({
                ...dokumen,
                tipe: 'dokumen',
                url: params.surat_kuasa_url,
                alasan: [2, 3].includes(params.detail_display.status_surat_kuasa) ? params.detail_display.alasan_surat_kuasa : ''
            }))
            setDataSelected(dataSelected => ({
                ...dataSelected,
                data: params,
                tipe: 'surat_kuasa',
                tipe_display: 'Surat Kuasa',
                status: params.detail_display.status_surat_kuasa,
                status_display: params.detail_display.status_surat_kuasa_display,
            }))
            title = `Surat Kuasa ${params.nama}`
            tipe = 'dokumen'
        } else if (tipe === 'ktp') {
            setDokumen(dokumen => ({
                ...dokumen,
                tipe: 'image',
                url: params.ktp_url,
                alasan: [2, 3].includes(params.detail_display.status_ktp) ? params.detail_display.alasan_ktp : ''
            }))
            setDataSelected(dataSelected => ({
                ...dataSelected,
                data: params,
                tipe: 'ktp',
                tipe_display: 'KTP Peserta',
                status: params.detail_display.status_ktp,
                status_display: params.detail_display.status_ktp_display,
            }))
            title = `KTP ${params.nama}`
            tipe = 'dokumen'
        } else if (tipe === 'ktp_kuasa') {
            setDokumen(dokumen => ({
                ...dokumen,
                tipe: 'image',
                url: params.ktp_kuasa_url,
                alasan: [2, 3].includes(params.detail_display.status_ktp_kuasa) ? params.detail_display.alasan_ktp_kuasa : ''
            }))
            setDataSelected(dataSelected => ({
                ...dataSelected,
                data: params,
                tipe: 'ktp_kuasa',
                tipe_display: 'KTP Pemberi Kuasa',
                status: params.detail_display.status_ktp_kuasa,
                status_display: params.detail_display.status_ktp_kuasa_display,
            }))
            title = `KTP Kuasa ${params.nama}`
            tipe = 'dokumen'
        } else if (tipe === 'foto') {
            setDokumen(dokumen => ({
                ...dokumen,
                tipe: 'image',
                url: params.foto_url,
                alasan: [2, 3].includes(params.detail_display.status_foto) ? params.detail_display.alasan_foto : ''
            }))
            setDataSelected(dataSelected => ({
                ...dataSelected,
                data: params,
                tipe: 'foto',
                tipe_display: 'Foto',
                status: params.detail_display.status_foto,
                status_display: params.detail_display.status_foto_display,
            }))
            title = `Foto ${params.nama}`
            tipe = 'dokumen'
        }
        setModalValue(modalValue => ({ ...modalValue, show: true, title: title, tipe: tipe }))
    }

    const handleApprove = async () => {
        setIsLoading(true);
        try {
            let data = { ...dataSelected.data };
            data['tipe'] = dataSelected.tipe;

            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(data))
            const res = await api.post(`${config.endPoint.actionApproveDoc}`, newForm).then(res => res.data);
            if (res) {
                fetchData(pageValue.search, pageValue.offset, config.itemPerPage, pageValue.complete, pageValue.is_lock)
                handleCloseModal();
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: `Dokumen ${dataSelected.tipe_display} telah disetujui`, color: 'success' }));
                handleClearDataSelected();
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue)
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            let msg = 'Unable to save Data';
            let { response } = error;

            catchFetching(error, 'admin')
            if (response && response.data && response.data.message) {
                msg = response.data.message
            }
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }));
            }, config.timeOutValue)
        }
    }

    const handleClearDataSelected = () => {
        setDataSelected(dataSelected => ({
            ...dataSelected,
            data: null,
            tipe: '',
            status: '',
            tipe_display: '',
            status_display: '',
            alasan: '',
        }))
    }

    const handleReject = () => {
        setModalValue(modalValue => ({ ...modalValue, show: true, tipe: 'reject' }))
        // setRejectModal(rejectModal => ({...rejectModal, show: true}))
    }

    const handleDetail = (params) => {
        sessionStorage.setItem('kontakId', params.id);
        navigate('/admin/registrasi/data-registrasi-peserta/detail')
    }

    const handleEdit = (post) => {
        sessionStorage.setItem('pesertaId', post.id);
        navigate('/admin/registrasi/data-registrasi-peserta/edit')
    }

    const handleChangeDataSelected = (e) => {
        const { name, value } = e.target;
        setDataSelected(dataSelected => ({ ...dataSelected, [name]: value }))
    }

    const handleCloseModalReject = () => {
        setModalValue(modalValue => ({ ...modalValue, show: true, tipe: 'dokumen' }))
    }

    const handleSubmitReject = async () => {
        setLoadingReject(true);
        try {
            const tmp = {
                'tipe': dataSelected.tipe,
                'alasan': dataSelected.alasan,
                'kontak_id': dataSelected.data.id
            }
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmp));
            const res = await api.post(`${config.endPoint.actionRejectDoc}`, newForm).then(res => res.data);

            if (res) {
                fetchData(pageValue.search, pageValue.offset, config.itemPerPage, pageValue.complete, pageValue.is_lock);
                handleCloseModal();
                // handleCloseRejectModal();
                handleClearDataSelected();

                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Email telah dikirim', color: 'success' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue);
            }

            setLoadingReject(false);
        } catch (error) {
            setLoadingReject(false);

            let msg = 'Unable to save Data';
            let { response } = error;

            catchFetching(error, 'admin')
            if (response && response.data && response.data.message) {
                msg = response.data.message
            }
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }));
            }, config.timeOutValue)
        }
    }

    const handleHistoryApproval = (post) => {
        modalDispatch({
            type: 'APPROVAL_LOG_PAGE',
            onClose: closeModalHandler,
            title: 'Activity Log',
            kontakId: post.id,
        })
    }

    const closeModalHandler = () => {
        modalDispatch({ type: 'CLOSE' })
    }

    const handleShowHistoryEmail = (post) => {
        modalDispatch({
            type: 'EMAIL_LOG',
            onClose: closeModalHandler,
            title: 'Email Log',
            kontakId: post.id
        })
    }

    // resend email
    const [resendEmailForm, setResendEmailForm] = useState({
        id: {
            name: 'id',
            type: 'text',
            value: '',
            show: false,
            required: true,
            errorMsg: '',
            showError: false,
            label: 'Email Template',
            readOnly: true,
        },
        email_template: {
            name: 'email_template',
            type: 'select',
            obj: [],
            value: '',
            show: true,
            required: true,
            errorMsg: '',
            showError: false,
            label: 'Email Template',
            readOnly: false,
            disabled: false,
        },
        perusahaan: {
            name: 'perusahaan',
            type: 'text',
            value: '',
            show: true,
            required: true,
            errorMsg: '',
            showError: false,
            label: 'Perusahaan',
            readOnly: true,
            disabled: true,
        },
        to: {
            name: 'to',
            type: 'text',
            value: '',
            show: true,
            required: true,
            errorMsg: '',
            showError: false,
            label: 'Tujuan',
            readOnly: true,
            disabled: true,
        },
        subject: {
            name: 'subject',
            type: 'text',
            value: '',
            show: true,
            required: true,
            errorMsg: '',
            showError: false,
            label: 'Subject'
        },
        body_email: {
            name: 'body_email',
            type: 'editor',
            value: '',
            show: true,
            required: true,
            errorMsg: '',
            showError: false,
            label: 'Body Email'
        },
    })

    const fetchEmailTemplate = useCallback(async () => {
        setIsLoading(true);
        try {
            const res = await api.get(`${config.endPoint.masterEmailTemplate}`).then(res => res.data);
            let tmp = [{ value: '', label: '' }];
            if (res) {
                res.results.map(post => tmp.push({ value: post.id, label: `${post.kode_display} | Subject : ${post.subjek}` }))
            }
            setResendEmailForm(resendEmailForm => ({
                ...resendEmailForm,
                email_template: { ...resendEmailForm.email_template, obj: tmp }
            }))
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            let msg = 'Unable to save Data';
            let { response } = error;

            catchFetching(error, 'admin')
            if (response && response.data && response.data.message) {
                msg = response.data.message
            }
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }));
            }, config.timeOutValue)
        }
        // eslint-disable-next-line
    }, [setIsLoading, setAlertValue])

    const handleResendEmail = (post) => {
        setModalValue(modalValue => ({
            ...modalValue,
            show: true, tipe: 'resend_email', title: 'Resend Email', id: post.id
        }))
        fetchEmailTemplate();
        setResendEmailForm(resendEmailForm => ({
            ...resendEmailForm,
            id: { ...resendEmailForm.id, value: post.id },
            to: { ...resendEmailForm.to, value: post.email_perusahaan },
            perusahaan: { ...resendEmailForm.perusahaan, value: post.perusahaan_display },
            email_template: { ...resendEmailForm.email_template, value: "" },
            body_email: { ...resendEmailForm.body_email, value: "" },
            subject: { ...resendEmailForm.subject, value: "" },
        }))
    }

    const replaceString = async (id = 0) => {
        setIsLoading(false);
        try {
            let tmp = {
                'template_id': id,
                'kontak_id': modalValue.id
            }
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmp));
            const res = await api.post(`${config.endPoint.replaceString}`, newForm).then(res => res.data);
            if (res) {
                setResendEmailForm(resendEmailForm => ({
                    ...resendEmailForm,
                    subject: { ...resendEmailForm.subject, value: res.subject },
                    body_email: { ...resendEmailForm.body_email, value: res.results }
                }))
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            let msg = 'Unable to save Data';
            let { response } = error;

            catchFetching(error, 'admin')
            if (response && response.data && response.data.message) {
                msg = response.data.message
            }
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }));
            }, config.timeOutValue)
        }
    }

    const handleChangeResendEmailForm = (e) => {
        const { name, value } = e.target;
        if (name === 'email_template') {
            replaceString(value);
        }
    }

    const handleSubmitResendEmail = async () => {
        setIsLoading(true);
        try {
            let countError = 0;
            let form = { ...resendEmailForm };
            let tmpForm = {}
            Object.entries(form).map(([index, post]) => {
                if (post.required && (post.value === '' || post.value === null)) {
                    post.showError = true;
                    countError++;
                } else {
                    tmpForm[index] = post.value;
                }
                return true;
            });

            if (countError > 0) {
                setResendEmailForm(resendEmailForm);
                setIsLoading(false);
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Lengkapi Data', color: 'danger' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }))
                }, config.timeOutValue);
                return;
            }

            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmpForm));

            let res = await api.post(`${config.endPoint.resendEmail}`, newForm).then(res => res.data);
            if (res) {
                handleCloseModal();
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Email terkirim', color: 'success' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue)
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            let msg = 'Unable to save Data';
            let { response } = error;

            catchFetching(error, 'admin')
            if (response && response.data && response.data.message) {
                msg = response.data.message
            }
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }));
            }, config.timeOutValue)
        }
    }

    const handleSynchronize = async(post) => {
        setIsLoading(true);        
        try{
            let tmp = {
                'perusahaan_id' : post.perusahaan
            }
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmp))
            const res = await api.post(`${config.endPoint.syncMyapjii}`, newForm).then(res => res.data);
            if (res){
                setIsLoading(false);
                setAlertValue(alertValue => ({...alertValue,
                    show: true,
                    text : 'Successfully',
                    subText: 'Synchronize akta ke MYAPJII success',
                    color: 'success',
                }))

                setTimeout(() => {
                    handleCloseAlert()
                }, config.timeOutValue)
            }
            // console.log('res is >>>', res);
        }catch(error){
            setIsLoading(false);
            const { response } = error;
            let msg = 'Unable to save data'
            
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            
            setAlertValue(alertValue => ({...alertValue,
                show: true,
                text : 'Error',
                subText: msg,
                color: 'danger'
            }))
            
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }

    return {
        alertValue, setAlertValue, handleCloseAlert, showLoading, closeLoading, isLoading, setIsLoading, catchFetching,
        pageValue, listData, handleOpenAlasanAkta, handleOpenAlasanPernyataan, handleOpenAlasanKuasa, handlePaginate,
        handleShowAlasanAkta, isShowAkta, handleShowAlasanPernyataan, isShowPernyataan, handleShowAlasanKuasa, isShowKuasa,
        handleChangeSearch, handleKeyDownSearch, handleSearch,
        handleShowDocument, handleViewDocument, modalValue, handleCloseModal,
        dataSelected, setModalValue, dokumen, handleApprove, handleReject, handleDetail, handleEdit, handleChangeDataSelected,
        handleCloseModalReject, handleSubmitReject, loadingReject, handleHistoryApproval,
        modalState, modalDispatch, handleShowHistoryEmail, fetchData,
        handleResendEmail, resendEmailForm, setResendEmailForm, handleChangeResendEmailForm, handleSubmitResendEmail, cropData, setCropData, handleCropComplete,
        handleSynchronize
    }
}