import { Fragment, useRef, useState } from "react"
import PropTypes from 'prop-types';

// import Datepicker from "tailwind-datepicker-react"
import TimePickerComp from "../forms/TimePickerComp";
import DatePicker from "react-datepicker";
import Select from 'react-select'
// import { Editor } from '@tinymce/tinymce-react';
// import Editor from 'react-simple-wysiwyg';

import "react-datepicker/dist/react-datepicker.css";
import Label from "./Label";
import Input from "./Input";
import { Icon } from "@iconify/react/dist/iconify.js";
import CKEditorComponent from "./CkEditorComponent";
// import config from "../../config";
// import config from "../config";
// import Button from "./Button";



const InputComp = (
    {
        type,
        value,
        onChange,
        onBlur,
        placeholder,
        name,
        className,
        obj,
        showPassword,
        handleShowPassword,
        inputType,
        onChangeTime,
        onKeyDown,
        onChangeDate,
        dateFormat,
        showTimeSelectOnly,
        onClickInputButton,
        textareaRows,
        onChangeFile,
        onChangeSelectInput,
        disabled,
        onChangeEditor,
        // onChangeEditor,
        // onChangeEditor,
        file_helper,
        accept_file,
        onKeyUp,
        showImage,
        handleShowImage,
        label,
        show,
        ref,
    }
) => {
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [isError, setIsError] = useState(false);
    // const editorRef = useRef(null);
    // const [isLoadingEditor, setIsLoadingEditor] = useState(true);

    const onChangeInput = (e) => {
        if (e.length > 0) {
            setMenuIsOpen(true);
        } else {
            setMenuIsOpen(false);
        }
    }
    const selectRef = useRef(null)

    const handleBlur = () => {
        let array = selectRef.current.getValue()
        if (array && array.length === 0) {
            setIsError(true);
        } else {
            setIsError(false);
        }
    }

    const toolbarConfig = {
        extraPlugins: "font,colorbutton,colordialog",
        removePlugins: "about",
        skin: "moono",
    };

    return (
        <>
            {['editor'].includes(type) &&
                <>
                    <div className="w-full px-5 overflow-hidden">
                        <CKEditorComponent name={name} onChangeEditor={onChangeEditor} toolbarConfig={toolbarConfig} value={value} />
                    </div>
                    {/* <div className='w-full px-5 overflow-hidden'>
                        <Editor
                            containerProps={{ style: { minHeight: '200px' } }}
                            value={value}
                            // onChange={(e) => onChangeEditor(e, name)} 
                            onChange={onChange}
                            name={name}
                        />
                    </div> */}
                    {/* <Editor
                        apiKey={config.tinyApiKey}
                        onInit={(evt, editor) => {
                            editorRef.current = editor;
                            setIsLoadingEditor(false);
                        }}
                        init={{
                            selector: 'textarea',
                            plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                            toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                            tinycomments_mode: 'embedded',
                            tinycomments_author: 'Author name',
                            mergetags_list: [
                                { value: 'First.Name', title: 'First Name' },
                                { value: 'Email', title: 'Email' },
                            ],
                            // ai_request: (request, respondWith) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant"))
                        }}
                        onEditorChange={(e) => onChangeEditor(e, name)}
                        value={value}
                    /> */}
                </>
            }
            {['image', 'file', 'dokumen'].includes(type) &&
                <Fragment>
                    {!disabled &&
                        <>
                            {['image'].includes(type) &&
                                <input
                                    className={`text-sm appearance-none 
                                        block w-full bg-white
                                        text-gray-700
                                        border-gray-400 
                                        rounded py-3 
                                        px-2
                                        border 
                                        leading-tight 
                                        focus:outline-1 
                                        focus:outline-none 
                                        focus:outline-blue-400 
                                        focus:border-none`
                                    }
                                    accept={type === 'image' ? 'image/*' : accept_file ? accept_file : '.pdf'}
                                    type={"file"}
                                    name={name}
                                    onChange={onChangeFile}
                                // onChange={(e) => type === 'image' ? !['image/jpeg', 'image/png', 'image/jpg'].includes(e.target.files[0].type) ? null : onChangeFile(e) : e.target.files[0].type !== 'application/pdf' ? null : onChangeFile(e)}
                                // onChange={(e) =>  e.target.files[0].type !== 'application/pdf' ? null : onChangeFile(e)}
                                />
                            }
                            {['dokumen'].includes(type) &&
                                <input
                                    className={`text-sm appearance-none 
                                        block w-full bg-white
                                        text-gray-700
                                        border-gray-400 
                                        rounded py-3 
                                        px-2
                                        border 
                                        leading-tight 
                                        focus:outline-1 
                                        focus:outline-none 
                                        focus:outline-blue-400 
                                        focus:border-none`
                                    }
                                    accept={type === 'image' ? 'image/*' : accept_file ? accept_file : '.pdf'}
                                    type={"file"}
                                    name={name}
                                    onChange={onChangeFile}
                                // onChange={(e) =>  e.target.files[0].type !== 'application/pdf' ? null : onChangeFile(e)}
                                // onChange={(e) =>  e.target.files[0].type !== 'application/pdf' ? 'ini ga dapetttttt' : onChangeFile(e)}
                                // onChange={(e) =>  e.target.files[0].type !== 'application/pdf' ? null : onChangeFile(e)}
                                />
                            }
                            {['file'].includes(type) &&
                                <input
                                    className={`text-sm appearance-none 
                                        block w-full bg-white
                                        text-gray-700
                                        border-gray-400 
                                        rounded py-3 
                                        px-2
                                        border 
                                        leading-tight 
                                        focus:outline-1 
                                        focus:outline-none 
                                        focus:outline-blue-400 
                                        focus:border-none`
                                    }
                                    accept={type === 'image' ? 'image/*' : accept_file ? accept_file : '.pdf'}
                                    type={"file"}
                                    name={name}
                                    onChange={onChangeFile}
                                // onChange={(e) =>  e.target.files[0].type !== 'application/pdf' ? null : onChangeFile(e)}
                                // onChange={(e) =>  e.target.files[0].type !== 'application/pdf' ? 'ini ga dapetttttt' : onChangeFile(e)}
                                // onChange={(e) =>  e.target.files[0].type !== 'application/pdf' ? null : onChangeFile(e)}
                                />
                            }
                        </>
                    }
                </Fragment>
            }
            {type === 'radio' &&
                <Fragment>
                    {obj.map((post, index) => {
                        return (
                            <div key={index}>
                                <input
                                    id="default-radio-1"
                                    type="radio"
                                    value={post.value}
                                    name={name}
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    onChange={onChange}
                                />
                                <Label className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{post.label}</Label>
                            </div>
                        )
                    })}
                </Fragment>
            }

            {['text', 'password', 'textarea', 'number', 'hp'].includes(type) &&
                <Fragment>
                    {['password'].includes(inputType) &&
                        <div className="relative">
                            <Input
                                placeholder={placeholder}
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                className={`block ${className}`}
                                name={name}
                                type={type}
                                onKeyDown={onKeyDown}
                                disabled={disabled}
                            />
                            {inputType === 'password' &&
                                <div style={{ position: 'absolute', bottom: 15, right: 12 }} className="cursor-pointer" onClick={() => handleShowPassword(name)}>
                                    <Icon icon={showPassword ? "ph:eye-closed-duotone" : "ph:eye-duotone"} />
                                </div>
                            }
                        </div>

                    }
                    {['text', 'textarea'].includes(inputType) &&
                        <div className="relative">
                            <Input
                                placeholder={placeholder}
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                className={`block ${className}`}
                                name={name}
                                type={type}
                                onKeyDown={onKeyDown}
                                textareaRows={textareaRows}
                                disabled={disabled}
                                ref={ref}
                            />
                        </div>
                    }
                    {['number'].includes(inputType) &&
                        <div className="relative">
                            <Input
                                placeholder={placeholder}
                                value={value}
                                onChange={(e) => !isNaN(e.target.value) ? onChange(e) : null}
                                onBlur={onBlur}
                                className={`block ${className}`}
                                name={name}
                                // type={type}
                                type={"text"}
                                onKeyDown={onKeyDown}
                                // pattern="^[0-9]*$"
                                pattern="^-?[0-9]\d*\.?\d*$"
                                disabled={disabled}
                            />
                        </div>
                    }
                    {['hp'].includes(inputType) &&
                        <div className="relative">
                            <div className="flex flex-row w-full border rounded text-gray-500 border-gray-400 items-center">
                                <div className='px-3'>+62</div>
                                <input
                                    className={`text-sm appearance-none 
                                        block
                                        w-full
                                        text-gray-700                                     
                                        rounded py-3 
                                        px-2
                                        leading-tight 
                                        focus:outline-1 
                                        focus:outline-none 
                                        focus:outline-blue-400 
                                        ${disabled ? 'bg-gray-200' : 'bg-white'}
                                        focus:border-none ${className}`
                                    }
                                    onKeyDown={onKeyDown}
                                    onKeyUp={onKeyUp}
                                    // onKeyUp={onKeyUp}
                                    // pattern="^-?[0-9]\d*\.?\d*$"  
                                    // pattern="/[0-9]+/"                              
                                    type={"text"}
                                    placeholder={placeholder}
                                    value={value}
                                    onChange={(e) => !isNaN(e.target.value) ? onChange(e) : null}
                                    onBlur={onBlur}
                                    name={name}
                                    disabled={disabled}
                                />
                            </div>
                        </div>
                    }

                </Fragment>
            }
            {['time'].includes(type) &&
                <TimePickerComp
                    onChange={onChangeTime}
                    timeValue={value}
                    name={name}
                />
            }
            {type === 'select' &&
                <select
                    onChange={onChange}
                    onBlur={onBlur}
                    name={name}
                    value={value}
                    id="countries"
                    disabled={disabled}
                    className={`
                        ${disabled ? 'bg-gray-200' : 'bg-white'} border 
                        border-gray-300 
                        text-gray-900 text-sm rounded-lg focus:ring-blue-500 
                        focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 
                        dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 
                        dark:focus:border-blue-500
                    `}>
                    {obj.map((post, index) => {
                        return (
                            <option value={post.value} key={index}>{post.label}</option>
                        )
                    })}
                </select>
            }
            {type === 'select_input' &&
                <Select
                    options={obj}
                    menuIsOpen={menuIsOpen}
                    onInputChange={(e) => onChangeInput(e)}
                    onChange={onChangeSelectInput}
                    className="text-sm"
                    onBlur={handleBlur}
                    ref={selectRef}
                    placeholder={placeholder}
                />

            }
            {type === 'date' &&
                <>
                    <div className="relative w-full">
                        <DatePicker
                            selected={value}
                            onChange={onChangeDate}
                            dateFormat={dateFormat ? dateFormat : "dd-MM-yyyy"}
                            // dateFormat={"HH:mm"}
                            timeFormat={"HH:mm"}
                            showTimeSelect={dateFormat ? true : false}
                            showTimeSelectOnly={showTimeSelectOnly ? showTimeSelectOnly : false}
                            className={`text-sm appearance-none 
                                block
                                w-[100%]                         
                                bg-white
                                text-gray-700
                                border-gray-400 
                                rounded py-3 
                                px-2
                                border 
                                leading-tight 
                                focus:outline-1 
                                focus:outline-none 
                                focus:outline-blue-400                                 
                                focus:border-none ${className}`
                            }
                        />
                    </div>
                </>

            }
            {type === 'checked' &&
                <input
                    id="default-checkbox"
                    type="checkbox"
                    value=""
                    className="w-4 h-4 
                        text-gray-600 
                        bg-gray-100 
                        border-gray-300 
                        rounded 
                        
                        dark:ring-offset-gray-800 
                        focus:ring-2 
                        dark:bg-gray-700 
                        dark:border-gray-600"
                    checked={value}
                    onChange={onChange}
                    name={name}
                />
            }
            {type === 'switch' &&
                <input
                    className="mr-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchChecked"
                    defaultChecked={value}
                    // defaultChecked={true} 
                    onChange={onChange}
                    name={name}
                // value={formData.registrasi_disable.value}
                />
            }
            {type === 'input_button' &&
                <div className="flex">
                    <input
                        className={`text-sm appearance-none 
                            block w-full bg-gray-200
                            text-gray-700
                            border-gray-400 
                            rounded-l py-3 
                            px-2
                            border 
                            leading-tight 
                            focus:outline-1 
                            focus:outline-none 
                            focus:outline-blue-400 
                            focus:border-none `
                        }
                        onKeyDown={onKeyDown}
                        type={type}
                        placeholder={placeholder}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        name={name}
                        readOnly={true}
                    />
                    <button className='bg-blue-500 px-3 rounded-r text-white border border-l-0 border-gray-400' onClick={onClickInputButton}>
                        <Icon icon="wpf:search" />
                    </button>
                </div>
            }
            {file_helper && show && !disabled &&
                <div className='italic text-[11px]'>Masukan file berformat {file_helper}</div>
            }
            {isError &&
                <p className="text-red-500 text-xs italic mt-1">Wajib diisi</p>
            }

            {/* { ['image'].includes(type) && value && 
                <div className='mt-3'>
                    <Button  className='btn-custom bg-blue-500 text-white rounded' size="sm" onClick={() => handleShowImage(name)}>Lihat {label}</Button>
                </div>
            } */}
            {['image'].includes(type) && value && showImage &&
                <div className='bg-slate-200 mt-3 p-3'>
                    <img src={value} alt='img' />
                </div>
            }
        </>
    )
}

const FormTextSingleComp = (props) => {
    const { type, onChange, onBlur, value, name, show,
        flexType,
        label,
        placeholder, showError, className, obj, showPassword, handleShowPassword, inputType,
        setFormData, formData, onChangeTime,
        hideLabel, onChangeDate, children,
        dateFormat, showTimeSelectOnly,
        onClick,
        textareaRows,
        handleChangeFile,
        onKeyDown,
        disabled,
        file_helper,
        errorMsg,
        accept_file,
        onKeyUp,
        showImage,
        gridClass,
        labelClass,
        ref,
        helper,
    } = props;



    const handleChange = (e) => {
        const { name, value } = e.target;
        let nilai = value;
        if (setFormData) {
            if (['checked', 'switch'].includes(formData[name].type)) {
                setFormData(formData => ({
                    ...formData,
                    [name]: { ...formData[name], value: e.target.checked }
                }))
            } else {
                setFormData(formData => ({
                    ...formData,
                    [name]: { ...formData[name], value: nilai }
                }))
                // setFormData(formData => ({
                //     ...formData,
                //     [name]: { ...formData[name], value: nilai }
                // }))
            }
        }
        if (onChange) {
            onChange(e);
        }
    }

    const handleChangeSelectInput = (e) => {
        if (setFormData) {
            setFormData(formData => ({
                ...formData,
                [name]: { ...formData[name], value: e.value }
            }))
        }
        if (onChange) {
            onChange(e)
        }
    }

    const onChangeFile = (e) => {
        if (setFormData) {
            const { name } = e.target;
            var nfile = e.target.files[0];
            if (formData[name].type === 'dokumen' && nfile.type !== 'application/pdf') {
                setFormData(formData => ({
                    ...formData,
                    [name]: { ...formData[name], value: '' }
                }))
                return;
            }
            if (formData[name].type === 'image' && !['image/jpeg', 'image/png', 'image/jpg'].includes(nfile.type)) {
                setFormData(formData => ({
                    ...formData,
                    [name]: { ...formData[name], value: '' }
                }))
                return;
            }
            // console.log(nfile.type, name)                  
            var reader = new FileReader();
            reader.readAsDataURL(nfile);
            reader.onloadend = function (e) {
                setFormData(formData => ({
                    ...formData,
                    [name]: {
                        ...formData[name],
                        value: reader.result,
                        file: nfile,
                        showImage: true
                    },
                }));
            }
        }
        if (handleChangeFile) {
            handleChangeFile(e)
        }
    }

    const handleChangeDate = (e, name) => {
        if (formData && setFormData) {
            setFormData(formData => ({
                ...formData,
                [name]: { ...formData[name], value: e }
            }))
        }
        if (onChangeDate) {
            onChangeDate(e, name)
        }
    }

    const handleChangeEditor = (data, name) => {
        console.log('CKEditor Data:', data);
        if (setFormData) {
            setFormData((prevData) => ({
                ...prevData,
                [name]: { ...prevData[name], value: data }
            }));
        }
    };

    const handleBlur = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        if (setFormData) {
            if (formData[name].required && value === '') {
                setFormData(formData => ({
                    ...formData,
                    [name]: { ...formData[name], showError: true }
                }))
            } else {
                setFormData(formData => ({
                    ...formData,
                    [name]: { ...formData[name], showError: false }
                }))
            }
        }
        if (onBlur) {
            onBlur(e);
        }
    }

    const onShowPassword = (name) => {
        if (setFormData) {
            setFormData(formData => ({
                ...formData,
                [name]: {
                    ...formData[name],
                    showPassword: !formData[name].showPassword,
                    type: !formData[name].showPassword ? 'text' : 'password'
                }
            }))
        }
        if (handleShowPassword) {
            handleShowPassword(name)
        }
    }


    const handleShowImage = (name) => {
        if (formData) {
            setFormData(formData => ({
                ...formData,
                [name]: { ...formData[name], showImage: !formData[name].showImage }
            }))
        }
    }

    const handleKeyDown = (event) => {
        const { name } = event.target;
        // console.log('number >>>>>>>>>>>> <<<<<<<', formData[name].type)
        if (formData) {
            if (formData[name].type === 'number') {
                var key = window.event ? event.keyCode : event.which;
                if (event.keyCode === 8 || event.keyCode === 46
                    || event.keyCode === 37 || event.keyCode === 39) {
                    return true;
                }
                else if (key < 48 || key > 57) {
                    return false;
                }
                else return true;
            }
        }

        if (onKeyDown) {
            onKeyDown(event);
        }
    }

    const handleKeyUp = (event) => {
        if (onKeyUp) {
            onKeyUp(event);
        }
    }


    return (
        <Fragment>
            {show &&
                <>
                    {flexType === 'row' &&
                        <div className={`w-full grid grid-cols-12 gap-4 mb-6 form-input`}>
                            {!hideLabel &&
                                <div className='col-span-4'>
                                    <Label className={labelClass}>{label}</Label>
                                </div>
                            }
                            <div className='col-span-8'>
                                <InputComp
                                    placeholder={placeholder}
                                    value={value}
                                    onChange={(e) => type === 'date' ? handleChangeDate(e, name) : handleChange(e)}
                                    onChangeSelectInput={handleChangeSelectInput}
                                    onBlur={handleBlur}
                                    type={type}
                                    name={name}
                                    className={className}
                                    obj={obj}
                                    showPassword={showPassword}
                                    handleShowPassword={onShowPassword}
                                    inputType={inputType}
                                    onChangeTime={(e) => onChangeTime(e, name)}
                                    onChangeDate={(e) => handleChangeDate(e, name)}
                                    dateFormat={dateFormat}
                                    showTimeSelectOnly={showTimeSelectOnly}
                                    onClickInputButton={onClick}
                                    textareaRows={textareaRows}
                                    onKeyDown={handleKeyDown}
                                    onKeyUp={handleKeyUp}
                                    onChangeFile={onChangeFile}
                                    disabled={disabled}
                                    onChangeEditor={handleChangeEditor}
                                    file_helper={file_helper}
                                    accept_file={accept_file}
                                    showImage={showImage}
                                    handleShowImage={handleShowImage}
                                    label={label}
                                    show={show}
                                    ref={ref}
                                />
                                {children}

                                {showError &&
                                    <p className="text-red-500 text-xs italic mt-2">Wajib diisi</p>
                                }
                            </div>
                        </div>
                    }
                    {flexType === 'column' &&
                        <div className={`grid form-input ${gridClass ? gridClass : 'mb-2 mt-2'} w-full`}>
                            {!hideLabel &&
                                <>
                                    <Label className={`text-lg font-semibold ${labelClass}`}>{label}</Label>
                                </>
                            }

                            <InputComp
                                placeholder={placeholder}
                                value={value}
                                onChange={(e) => type === 'date' ? handleChangeDate(e, name) : handleChange(e)}
                                onChangeSelectInput={handleChangeSelectInput}
                                onBlur={handleBlur}
                                type={type}
                                name={name}
                                className={className}
                                obj={obj}
                                showPassword={showPassword}
                                handleShowPassword={onShowPassword}
                                inputType={inputType}
                                onChangeTime={(e) => onChangeTime(e, name)}
                                onChangeDate={(e) => handleChangeDate(e, name)}
                                dateFormat={dateFormat}
                                showTimeSelectOnly={showTimeSelectOnly}
                                onClickInputButton={onClick}
                                textareaRows={textareaRows}
                                onChangeFile={onChangeFile}
                                onKeyDown={handleKeyDown}
                                onKeyUp={handleKeyUp}
                                disabled={disabled}
                                onChangeEditor={handleChangeEditor}
                                file_helper={file_helper}
                                accept_file={accept_file}
                                showImage={showImage}
                                handleShowImage={handleShowImage}
                                label={label}
                                show={show}
                                ref={ref}
                            />
                            {children}

                            {showError &&
                                <p className="text-red-500 text-xs italic mt-2">{errorMsg ? errorMsg : 'Wajib diisi'}</p>
                            }
                            {helper &&
                                <>{helper}</>
                            }
                        </div>
                    }
                </>
            }
        </Fragment>
    )
}

FormTextSingleComp.propTypes = {
    type: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.any,
    name: PropTypes.string,
    show: PropTypes.bool,
    flexType: PropTypes.string,
    placeholder: PropTypes.string,
    showError: PropTypes.bool,
    className: PropTypes.string,
    obj: PropTypes.array,
    showPassword: PropTypes.bool,
    handleShowPassword: PropTypes.func,
    inputType: PropTypes.string,
    setFormData: PropTypes.any,
    formData: PropTypes.any,
    onChangeTime: PropTypes.func,
    onChangeDate: PropTypes.func,
    hideLabel: PropTypes.bool,
    children: PropTypes.any,
    dateFormat: PropTypes.string,
    showTimeSelectOnly: PropTypes.bool,
    onClick: PropTypes.func,
    textareaRows: PropTypes.number,
    handleChangeFile: PropTypes.func,
    onKeyDown: PropTypes.func,
    disabled: PropTypes.bool,
    errorMsg: PropTypes.string,
    accept_file: PropTypes.any,
    showImage: PropTypes.bool,
    gridClass: PropTypes.string,
    labelClass: PropTypes.string,
    ref: PropTypes.any,
    helper: PropTypes.any,
}

FormTextSingleComp.defaultProps = {
    show: true,
    value: '',
    type: 'text',
    placeholder: '',
    flexType: 'column',
    showError: false,
    className: '',
    obj: [],
    showPassword: true,
    inputType: 'text',
    onChangeTime: null,
    hideLabel: false,
    disabled: false,
    showImage: true
}

export default FormTextSingleComp;