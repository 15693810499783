import { Icon } from "@iconify/react";
import SiteSettingPage from "../views/Admin/Master/SiteSetting/SiteSettingPage";
import FileUploadPage from "../views/Admin/Master/FileUpload/FileUploadPage";
import PerusahaanPage from "../views/Admin/Master/Perusahaan/PerusahaanPage";
import HotelPage from "../views/Admin/Master/Hotel/HotelPage";
import HotelHistoryPage from "../views/Admin/Master/HotelHistory/HotelHistoryPage";
// import BackupDatabasePage from "../views/Admin/Master/BackupDatabase/BackupDatabasePage";
import KategoriSponsorShip from "../views/Admin/Master/KategoriSponsorShip/KategoriSponsorShip";
import SponsorShipPage from "../views/Admin/Master/SponsorShip/SponsorShipPage";
import GroupPermissionPage from "../views/Admin/Master/GroupPermission/GroupPermissinPage";
import UserPage from "../views/Admin/Master/User/UserPage";
import LockSettingPage from "../views/Admin/Master/LockSetting/LockSettingPage";
import PasswordDownloadPage from "../views/Admin/Master/PasswordDownload/PasswordDownloadPage";
import DataRegistrasiPage from "../views/Admin/Registrasi/DataRegistrasi/DataRegistrasiPage";
import EmailTemplatePage from "../views/Admin/Master/EmailTemplate/EmailTemplatePage";
import ActivityLogPage from "../views/Admin/Registrasi/ActivityLog/ActivityLogPage";
import EmailLogPage from "../views/Admin/Registrasi/EmailLog/EmailLogPage";
import DuplicatePesertaPage from "../views/Admin/Registrasi/DuplicatePeserta/DuplicatePesertaPage";
import HotelPesertaPage from "../views/Admin/Registrasi/HotelPeserta/HotelPesertaPage";
import HapusDataRegistrasiPage from "../views/Admin/Registrasi/HapusDataRegistrasi/HapusDataRegistrasiPage";
import RegistrasiPerusahaanPage from "../views/Admin/Registrasi/RegistrasiPerusahaan/RegistrasiPerusahaanPage";
import AgendaAdminPage from "../views/Admin/Master/Agenda/AgendaAdminPage";
import KandidatKetumPage from "../views/Admin/Master/Kandidat/KandidatKetumPage";
import KandidatDewasPage from "../views/Admin/Master/KandidatDewas/KandidatDewasPage";
import KategoriPanitiaPage from "../views/Admin/Master/KategoriPanitia/KategoriPanitiaPage";
import PanitiaPage from "../views/Admin/Master/Panitia/PanitiaPage";
import ServerCalonPage from "../views/Admin/Master/ServerCalon/ServerCalonPage";
import HomeAdmin from "../views/Admin/HomeAdmin/HomeAdmin";
import RevokeHotelPage from "../views/Admin/Master/RevokeHotel/RevokeHotelPage";
import { TbTags, TbTagStarred } from "react-icons/tb";
import PersiapanIdCardPage from "../views/Admin/Registrasi/PersiapanIdCard/PersiapanIdCardPage";
import PersiapanIdCardPanitiaPage from "../views/Admin/Registrasi/PersiapanIdCardPanitia/PersiapanIdCardPanitiaPage";
import IDCardPage from "../views/Admin/Registrasi/IdCard/IdCardPage";
import IDCardPanitiaPage from "../views/Admin/Registrasi/IdCardPanitia/IdCardPanitiaPage";
import KartuTandaPemilih from "../views/Admin/Registrasi/KartuTandaPemilih/KartuTandaPemilih";
import TpsPage from "../views/Admin/Master/TpsPage/TpsPage";
import SesiPemilihanPage from "../views/Admin/Master/SesiPemilihan/SesiPemilihanPage";
// import ExportDataPage from "../views/Admin/Master/ExportData/ExportDataPages";

export const menu = [
  {
    icon: <Icon icon="material-symbols:dashboard" fontSize={18} />,
    path: "/admin/dashboard",
    name: "dashboard",
    title: "Dashboard",
    permission_group: ["Administrator", "Operator", "Read-Only"],
    element: <HomeAdmin />,
    sub: []
  },
  {
    icon: <Icon icon="ant-design:setting-filled" />,
    path: "/admin/master",
    name: "master",
    title: "Master",
    element: null,
    permission_group: ["Administrator"],
    sub: [
      {
        icon: null,
        path: "/admin/master/site-setting",
        name: "site-setting",
        title: "Site Setting",
        element: <SiteSettingPage />,
        permission_name: "view_site_setting",
        permission_group: ["Administrator"],
      },
      {
        icon: null,
        path: "/admin/master/email-template",
        name: "email-template",
        title: "Email Template",
        element: <EmailTemplatePage />,
        permission_name: "view_email_template",
        permission_group: ["Administrator"],
      },
      {
        icon: null,
        path: "/admin/master/file-upload",
        name: "site-setting",
        title: "File Upload",
        element: <FileUploadPage />,
        permission_name: "view_file_upload",
        permission_group: ["Administrator"],
      },
      {
        icon: null,
        path: "/admin/master/perusahaan",
        name: "perusahaan",
        title: "Perusahaan",
        element: <PerusahaanPage />,
        permission_name: "view_perusahaan",
        permission_group: ["Administrator"],
      },
      {
        icon: null,
        path: "/admin/master/hotel",
        name: "hotel",
        title: "Hotel",
        element: <HotelPage />,
        permission_name: "view_hotel",
        permission_group: ["Administrator"],
      },
      {
        icon: null,
        path: "/admin/master/hotel-history",
        name: "hotel-history",
        title: "Hotel History",
        element: <HotelHistoryPage />,
        permission_name: "view_hotel_log",
        permission_group: ["Administrator"],
      },
      {
        icon: null,
        path: "/admin/master/revoke-hotel",
        name: "revoke-hotel",
        title: "Revoke Hotel",
        element: <RevokeHotelPage />,
        permission_name: "is_superuser",
        permission_group: ["is_superuser"],
      },
      // {
      //   icon: null,
      //   path: "/admin/master/backup-database",
      //   name: "backup-database",
      //   title: "Backup Database",
      //   element: <BackupDatabasePage />,
      //   permission_name: "is_superuser",
      //   permission_group: ["is_superuser"],
      // },
      {
        icon: null,
        path: "/admin/master/kategori-sponsorship",
        name: "kategori-sponsorship",
        title: "Kategori Sponsorship",
        element: <KategoriSponsorShip />,
        permission_name: "view_kategori_sponsor",
        permission_group: ["Administrator"],
      },
      {
        icon: null,
        path: "/admin/master/sponsorship",
        name: "sponsorship",
        title: "Sponsorship",
        element: <SponsorShipPage />,
        permission_name: "view_sponsorship",
        permission_group: ["Administrator"],
      },
      {
        icon: null,
        path: "/admin/master/group-permission",
        name: "group-permission",
        title: "Group Permission",
        element: <GroupPermissionPage />,
        permission_name: "view_group",
        permission_group: ["is_superuser"],
      },
      {
        icon: <Icon icon="fa-solid:user-tie" fontSize={18} />,
        path: "/admin/master/kategori-panitia",
        name: "kategori-panitia",
        title: "Kategori Panitia",
        element: <KategoriPanitiaPage />,
        permission_name: "view_kategori_panitia",
        permission_group: ["Administrator"],
      },
      {
        icon: <Icon icon="fa-solid:user-tie" fontSize={18} />,
        path: "/admin/master/panitia",
        name: "panitia",
        title: "Panitia",
        element: <PanitiaPage />,
        permission_name: "view_kategori_panitia",
        permission_group: ["Administrator"],
      },
      {
        icon: <Icon icon="fa-solid:user-edit" fontSize={"16"} />,
        path: "/admin/master/user",
        name: "user",
        title: "User",
        element: <UserPage />,
        permission_name: "view_user",
        permission_group: ["Administrator"],
      },
      {
        icon: null,
        path: "/admin/master/lock-setting",
        name: "user",
        title: "Lock Setting",
        element: <LockSettingPage />,
        permission_name: "view_setting_disable",
        permission_group: ["Administrator"],
      },
      // {
      //   icon: <TbFileDownload />,
      //   path: "/admin/master/export-data",
      //   name: "user",
      //   title: "Export Data",
      //   element: <ExportDataPage />,
      //   permission_name: "view_setting_disable",
      //   permission_group: ["Administrator", "is_superuser"],
      // },
      {
        icon: <Icon icon="carbon:download" />,
        path: "/admin/master/password-download",
        name: "password-download",
        title: "Password Download",
        element: <PasswordDownloadPage />,
        permission_name: "view_password_download",
        permission_group: ["Administrator"],
      },
      {
        icon: <Icon icon="cil:calendar" />,
        path: "/admin/master/agenda-acara",
        name: "agenda-acara",
        title: "Agenda Acara",
        element: <AgendaAdminPage />,
        permission_name: "view_agenda_acara",
        permission_group: ["Administrator"],
      },
      {
        icon: <Icon icon="raphael:users" fontSize={"16"} />,
        path: "/admin/master/server-calon",
        name: "server-calon",
        title: "Server Calon",
        element: <ServerCalonPage />,
        permission_name: "is_superuser",
        permission_group: ["is_superuser"],
      },
      {
        icon: <Icon icon="fa-solid:user-tie" fontSize={18} />,
        path: "/admin/master/kandidat-ketum",
        name: "kandidat-ketum",
        title: "Kandidat Ketum",
        element: <KandidatKetumPage />,
        permission_name: "view_kandidat_ketum",
        permission_group: ["Administrator"],
      },
      {
        icon: <Icon icon="raphael:users" fontSize={"16"} />,
        path: "/admin/master/kandidat-dewas",
        name: "kandidat-dewas",
        title: "Kandidat Dewas",
        element: <KandidatDewasPage />,
        permission_name: "view_kandidat_dewas",
        permission_group: ["Administrator"],
      },
      {
        icon: <Icon icon="ic:sharp-meeting-room" fontSize={"16"} />,
        path: "/admin/master/tps",
        name: "master-tps",
        title: "Master TPS",
        element: <TpsPage />,
        permission_name: "view_tps",
        permission_group: ["Administrator"],
      },
      {
        icon: <Icon icon="fluent:clock-bill-24-filled" fontSize={"16"} />,
        path: "/admin/master/sesi-pemilihan",      
        name: "sesi-pemilihan",
        title: "Waktu Sesi Pemilihan",
        element: <SesiPemilihanPage />,
        permission_name: "view_sesi_pemilihan",
        permission_group: ["Administrator"],
      },
    ],
  },
  {
    icon: <Icon icon="medical-icon:i-registration" fontSize={18} />,
    path: "/admin/registrasi",
    name: "registrasi",
    title: "Registrasi",
    permission_group: ["Administrator", "Operator", "Read-Only"],
    element: null,
    sub: [
      {
        icon: <Icon icon="fa-solid:user-tie" fontSize={18} />,
        path: "/admin/registrasi/data-registrasi-peserta",
        name: "data-peserta",
        title: "Data Registrasi Peserta",
        element: <DataRegistrasiPage />,
        permission_name: "view_kontak",
        permission_group: ["Administrator", "Operator", "Read-Only"],
      },
      {
        icon: <Icon icon="mdi:user-off" fontSize={24} />,
        path: "/admin/registrasi/hapus-registrasi-peserta",
        name: "hapus-peserta",
        title: "Hapus Registrasi Peserta",
        element: <HapusDataRegistrasiPage />,
        permission_name: "delete_kontak",
        permission_group: ["Administrator", "Operator"],
      },
      {
        icon: <Icon icon="humbleicons:activity" fontSize={18} />,
        path: "/admin/registrasi/activity-log",
        name: "activity-log",
        title: "Activity Log",
        element: <ActivityLogPage />,
        permission_name: "delete_kontak",
        permission_group: ["Administrator", "Operator"],
      },
      {
        icon: <Icon icon="icon-park-twotone:timed-mail" fontSize={18} />,
        path: "/admin/registrasi/email-log",
        name: "email-log",
        title: "Email Log",
        element: <EmailLogPage />,
        permission_name: "view_kontak_approve_log",
        permission_group: ["Administrator", "Operator"],
      },
      {
        icon: <Icon icon="mi:users" fontSize={18} />,
        path: "/admin/registrasi/duplicate-peserta",
        name: "duplicate-peserta",
        title: "Duplikat Peserta",
        element: <DuplicatePesertaPage />,
        permission_name: "view_duplicate_peserta",
        permission_group: ["Administrator", "Operator"],
      },
      {
        icon: <Icon icon="fontisto:hotel" fontSize={16} />,
        path: "/admin/registrasi/hotel-peserta",
        name: "hotel-peserta",
        title: "Hotel Peserta",
        element: <HotelPesertaPage />,
        permission_name: "view_hotel_peserta",
        permission_group: ["Administrator", "Operator"],
      },
      {
        icon: <Icon icon="material-symbols:remove-done" fontSize={18} />,
        path: "/admin/registrasi/unfinish-signup",
        name: "unfinish-signup",
        title: "Unfinish Signup",
        element: <RegistrasiPerusahaanPage />,
        permission_name: "view_kontak",
        permission_group: ["Administrator", "Operator", "Read-Only"],
      },
      {
        icon: <TbTags fontSize={18} />,
        path: "/admin/registrasi/persiapan-idcard",
        name: "persiapan-idcard",
        title: "Persiapan ID Card",
        element: <PersiapanIdCardPage />,
        permission_group: ["Administrator", "Operator"],
      },
      {
        icon: <TbTagStarred fontSize={18} />,
        path: "/admin/registrasi/persiapan-idcard-panitia",
        name: "persiapan-idcard-panitia",
        title: "Persiapan ID Card Panitia",
        element: <PersiapanIdCardPanitiaPage />,
        permission_group: ["Administrator", "Operator"],
      },
      {
        icon: <Icon icon="mingcute:idcard-line" fontSize={18} />,
        path: "/admin/registrasi/idcard",
        name: "idcard",
        title: "ID Card",
        element: <IDCardPage />,
        permission_group: ["Administrator", "Operator"],
      },
      {
        icon: <Icon icon="mingcute:idcard-line" fontSize={18} />,
        path: "/admin/registrasi/idcard-panitia",
        name: "idcard-panitia",
        title: "ID Card Panitia",
        element: <IDCardPanitiaPage />,
        permission_group: ["Administrator", "Operator"],
      },
      {
        icon: <Icon icon="mingcute:idcard-line" fontSize={18} />,
        path: "/admin/registrasi/kartu-tanda-pemilih",
        name: "kartu-tanda-pemilih",
        title: "Kartu Tanda Pemilih",
        element: <KartuTandaPemilih />,
        permission_group: ["Administrator", "Operator"],
      },
    ],
  },
];
