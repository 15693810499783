import { Fragment } from "react";
import Card from "../../../../Components/Layout/Card";

import Tables from "../../../../Components/molecules/Tables";
import PagePagination from "../../../../Components/Atoms/PagePagination";
import FormTextSingleComp from "../../../../Components/forms/FormTextSingleComp";
import { useGroupPermissions } from "./groupPermissionFunction";
import { Icon } from "@iconify/react/dist/iconify.js";
import Tooltip from "../../../../Components/Atoms/Tooltip";
import Button from "../../../../Components/Atoms/Button";
import Modal from "../../../../Components/Atoms/Modal";
import AlertComp from "../../../../Components/Atoms/AlertComp";


const GroupPermissions = () => {
    const { view, pageValue, handleCreate, handleSubmit, handleCancel, handleChangeSearch, handleSearch,
        listData, handlePaginate, formData, setFormData, listAvailablePermissions,
        handleSelectedAvailable, selectedPermissions, handleSelectedToGranted, selectedGranted,
        handleSelectedGranted, listGranted, handleAvailableToGranted,
        handleSelectedGrantedToAvailable, handleGrantedToAvailable, handleEdit, handleDelete,
        modalValue, setModalValue, handleCloseModal, handleSubmitDelete, alertValue, handleCloseAlert
    } = useGroupPermissions();
    return(
        <Fragment>
            <AlertComp
                isOpen={alertValue.show}
                onClose={handleCloseAlert}
                text={alertValue.text}
                subText={alertValue.subText}
                color={alertValue.color}
                icon={alertValue.icon}
            />
            <Modal
                show={modalValue.show}
                setShow={() => {
                    setModalValue(modalValue => ({...modalValue, show: false}))
                }}
                width='xl'
                persistent={false}
                btnClose={false}
            >
                <div className='py-[20px] px-[30px] border-b border-b-gray-200 font-bold'>
                    {modalValue.title}
                </div>
                <div className='py-[50px] px-[30px]'>
                    { modalValue.text }
                </div>
                <div className='py-[20px] px-[30px] border-t border-t-gray-200 flex flex-row justify-end gap-2'>
                    <Button color='danger' size='md' onClick={handleSubmitDelete}>Hapus</Button>
                    <Button color='base' size='md' onClick={handleCloseModal}>Cancel</Button>
                </div>
            </Modal>
            <Card
                title='Master Kandidat Ketua Umum'
                isCreate={view === 'create'}
                isSearch={view === 'list'}
                searchValue={pageValue.search}
                handleCreate={handleCreate}
                handleSubmit={handleSubmit}
                handleCancel={handleCancel}
                onChangeSearch={handleChangeSearch}
                handleSearch={handleSearch}
            >
                { view === 'create' &&
                    <Fragment>
                        <div className="grid grid-cols-2 gap-5">
                            <div className="col-span-2 md:col-span-1">
                                <FormTextSingleComp
                                    {...formData.nama}
                                    formData={formData}
                                    setFormData={setFormData}
                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-2 gap-5 mt-[30px]">
                            <div className="col-span-1">
                                <div className='font-bold my-2'>Available Permissions</div>
                                <div className='block h-96  overflow-auto overflow-y-auto border border-gray-200'>

                                    <table className='table w-full border-separate border-spacing-0 table-auto'>
                                        <thead className='sticky bg-blue-900  text-white top-0 left-0'>
                                            <tr>
                                                <th className='py-2 px-2'>Permission</th>
                                                <th className='py-2 px-2 border-l border-l-gray-200'>Model</th>
                                                <th className='py-2 px-2 border-l border-l-gray-200'>Label</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { listAvailablePermissions?.data?.map((post, index) => {
                                                return(
                                                    <tr key={index}>
                                                        <td onClick={() => handleSelectedAvailable(post)} className={`${selectedPermissions.map(x => x.id).includes(post.id) ? 'bg-blue-400 text-white' : 'bg-white text-inherit'} cursor-pointer py-1 text-sm border-b border-b-gray-200 px-2`}>{post.name}</td>
                                                        <td onClick={() => handleSelectedAvailable(post)} className={`${selectedPermissions.map(x => x.id).includes(post.id) ? 'bg-blue-400 text-white' : 'bg-white text-inherit'} cursor-pointer py-1 text-sm border-b border-b-gray-200 border-l border-l-gray-200 px-2`}>{post.model_name}</td>
                                                        <td onClick={() => handleSelectedAvailable(post)} className={`${selectedPermissions.map(x => x.id).includes(post.id) ? 'bg-blue-400 text-white' : 'bg-white text-inherit'} cursor-pointer py-1 text-sm border-b border-b-gray-200 border-l border-l-gray-200 px-2 border-r border-r-gray-200`}>{post.label}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="flex flex-row justify-end mt-5 gap-1">
                                    <button className='border bg-blue-400 px-3 py-2 text-white rounded' onClick={handleAvailableToGranted}><Icon icon="gg:chevron-double-right"/></button>
                                    <button className='border border-blue-400 px-3 py-2 text-blue-400 rounded' onClick={handleSelectedToGranted}><Icon icon="ic:baseline-chevron-right"/></button>
                                </div>
                            </div>
                            <div className="col-span-1">
                                <div className='font-bold my-2'>Selected Permissions</div>
                                <div className='block h-96  overflow-auto overflow-y-auto border border-gray-200'>

                                    <table className='table w-full border-separate border-spacing-0 table-auto'>
                                        <thead className='sticky bg-blue-900  text-white top-0 left-0'>
                                            <tr>
                                                <th className='py-2 px-2'>Permission</th>
                                                <th className='py-2 px-2 border-l border-l-gray-200'>Model</th>
                                                <th className='py-2 px-2 border-l border-l-gray-200'>Label</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { listGranted?.data?.map((post, index) => {
                                                return(
                                                    <tr key={index}>
                                                        <td onClick={() => handleSelectedGranted(post)} className={`${selectedGranted.map(x => x.id).includes(post.id) ? 'bg-blue-400 text-white' : 'bg-white text-inherit'} cursor-pointer py-1 text-sm border-b border-b-gray-200  px-2`}>{post.name}</td>
                                                        <td onClick={() => handleSelectedGranted(post)} className={`${selectedGranted.map(x => x.id).includes(post.id) ? 'bg-blue-400 text-white' : 'bg-white text-inherit'} cursor-pointer py-1 text-sm border-b border-b-gray-200 border-l border-l-gray-200 px-2`}>{post.model_name}</td>
                                                        <td onClick={() => handleSelectedGranted(post)} className={`${selectedGranted.map(x => x.id).includes(post.id) ? 'bg-blue-400 text-white' : 'bg-white text-inherit'} cursor-pointer py-1 text-sm border-b border-b-gray-200 border-l border-l-gray-200 px-2 border-r border-r-gray-200`}>{post.label}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="flex flex-row justify-start mt-5 gap-1">
                                    <button className='border bg-blue-400 px-3 py-2 text-white rounded' onClick={handleGrantedToAvailable}><Icon icon="gg:chevron-double-left"/></button>
                                    <button className='border border-blue-400 px-3 py-2 text-blue-400 rounded' onClick={handleSelectedGrantedToAvailable}><Icon icon="ic:baseline-chevron-left"/></button>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                }
                { view === 'list' &&
                    <Fragment>
                        <Tables className='border-separate rounded-sm'>
                            <Tables.Head className='bg-[#efeeee]'>
                                <tr>
                                <Tables.Header className='font-bold text-[13px] py-[10px] text-right'>#</Tables.Header>
                                <Tables.Header className='font-bold text-[13px] py-[10px]'>Nama</Tables.Header>                            
                                <Tables.Header className='font-bold text-[13px] py-[10px] text-center'>Action</Tables.Header>
                                </tr>
                            </Tables.Head>
                            <Tables.Body>
                                { listData?.data?.length === 0 ?
                                    <Tables.Row>
                                        <Tables.Data colspan={3} className='text-center'>Belum ada data</Tables.Data>
                                    </Tables.Row>
                                    :
                                    <Fragment>
                                        {listData?.data?.map((post, index) => {
                                            return (
                                                <Tables.Row key={index}>
                                                    <Tables.Data className='text-right align-top'>{pageValue.offset + index + 1}</Tables.Data>
                                                    <Tables.Data>{post.name}</Tables.Data>
                                                    <Tables.Data className='text-center'>
                                                        <div className='flex flex-row gap-1 justify-center'>
                                                            <Tooltip
                                                                tooltip={'Edit'}
                                                            >
                                                                <button onClick={() => handleEdit(post)} className='p-1 bg-orange-500 text-white rounded'><Icon icon='line-md:edit' /></button>
                                                            </Tooltip>
                                                            <Tooltip
                                                                tooltip={'Hapus'}
                                                            >
                                                                <button onClick={() => handleDelete(post)} className='p-1 bg-red-500 text-white rounded'><Icon icon='fluent-mdl2:delete'  /></button>
                                                            </Tooltip>
                                                        </div>
                                                        
                                                    </Tables.Data>
                                                </Tables.Row>
                                            )
                                        })}
                                    </Fragment>
                                }
                            </Tables.Body>
                        </Tables>
                        <div className='mt-5'>
                            <PagePagination
                                data={pageValue.obj}
                                handlePagination={handlePaginate}
                                page={pageValue.page}
                                lastPage={pageValue.lastPage}
                            />
                        </div>
                    </Fragment>
                }
            </Card>
        </Fragment>
    )
}
export default GroupPermissions