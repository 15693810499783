import { Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import config from "../../config";

const Nav = () => {
  const [cookies] = useCookies([config.cookiesName]);
  const [isLogin, setIsLogin] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (cookies && cookies[config.cookiesName]) {
      setIsLogin(true);
    }
    // eslint-disable-next-line
  }, []);
  return (
    <Fragment>
      <div className="invisible md:visible flex flex-row justify-end ml-[39px] items-center w-full gap-5 text-white">
        <Link
          to="/"
          className={`${location.pathname === "/" ? "bg-sky-600 px-2 py-2 rounded" : ""
            }`}
        >
          Home
        </Link>
        <Link
          className={`${location.pathname === "/syarat-dan-ketentuan"
              ? "bg-sky-600 px-2 py-2 rounded"
              : ""
            }`}
          to="/syarat-dan-ketentuan"
        >
          Syarat & Ketentuan
        </Link>
        <Link
          className={`${location.pathname === "/tata-tertib"
              ? "bg-sky-600 px-2 py-2 rounded"
              : ""
            }`}
          to="/tata-tertib"
        >
          Tata Tertib
        </Link>
        <Link
          className={`${location.pathname === "/download-area"
              ? "bg-sky-600 px-2 py-2 rounded"
              : ""
            }`}
          to="/download-area"
        >
          Download Area
        </Link>
        <Link
          className={`${location.pathname === "/agenda"
              ? "bg-sky-600 px-2 py-2 rounded"
              : ""
            }`}
          to="/agenda"
        >
          Agenda
        </Link>
        <Link
          className={`${location.pathname === "/peserta"
              ? "bg-sky-600 px-2 py-2 rounded"
              : ""
            }`}
          to="/peserta"
        >
          Daftar Peserta
        </Link>
        <Link
          className={`${location.pathname === "/lokasi"
              ? "bg-sky-600 px-2 py-2 rounded"
              : ""
            }`}
          to="/lokasi"
        >
          Lokasi
        </Link>
        {isLogin ? (
          <Link
            className={`${location.pathname === "/member"
                ? "bg-sky-600 px-2 py-2 rounded"
                : ""
              }`}
            to="/member"
          >
            Member Area
          </Link>
        ) : (
          <Link
            className={`${location.pathname === "/login"
                ? "bg-sky-600 px-2 py-2 rounded"
                : ""
              }`}
            to="/login"
          >
            Login
          </Link>
        )}
      </div>
    </Fragment>
  );
};

export default Nav;
