import { Fragment, useRef } from "react";
// import * as React from "react";
import HTMLFlipBook from "react-pageflip";
import BlankPage from "./Pages/BlankPage";
import HomeComponent from "./Pages/HomeComponent";
import SyaratPageCover from "./Pages/SyaratPage/SyaratPageCover";
import SyaratPage from "./Pages/SyaratPage/SyaratPage";

import DownloadPageCover from "./Pages/DownloadPage/DownloadPageCover";
import DownloadPage from "./Pages/DownloadPage/DownloadPage";
import AgendaPageCover from "./Pages/Agenda/AgendaCover";
import AgendaPage from "./Pages/Agenda/AgendaPage";
import PesertaCover from "./Pages/Peserta/PesertaCover";
import PesertaPage from "./Pages/Peserta/PesertaPage";
import SponsorCover from "./Pages/Sponsor/SponsorCover";
import SponsorPage from "./Pages/Sponsor/SponsorPage";
import LokasiCover from "./Pages/Lokasi/LokasiCover";
import LokasiPage from "./Pages/Lokasi/LokasiPage";

const HomePage = () => {
  const { innerWidth: width, innerHeight: height } = window;
  const padding = 20;
  const myWidth = width / 2 - padding;
  const myHeight = height - padding * 2;
  const pageRef = useRef(null);

  const goToPage = (params) => {
    // if (params){
    //     params?.pageFlip()?.turnToPage(params)
    // }
    // console.log('pageRef >>>>>', pageRef)
    pageRef.current?.pageFlip()?.turnToPage(params);
    console.log("params gotopage >>>>", params);
    console.log(
      "current index >>>>",
      pageRef.current?.pageFlip()?.pages?.currentPageIndex
    );
  };

  return (
    <Fragment>
      <div className="w-full h-screen bg-green-300 flex flex-row justify-center items-center bg-woods bg-no-repeat bg-cover overflow-hidden">
        <HTMLFlipBook
          width={myWidth}
          height={myHeight}
          showCover={true}
          size="fixed"
          usePortrait={true}
          useMouseEvents={true}
          //    startPage={0}
          ref={(el) => {
            // console.log('el >>>>', el);
            goToPage(el);
            // goToPage(el.pageFlip())
            // setIsReady(true);
            pageRef.current = el;
          }}
        >
          {/* cover */}
          <div className="page demoPage " data-density="hard">
            <div className="relateive w-full h-full">
              <div className="absolute">
                <img
                  src={require("../../assets/images/bookCover.png")}
                  alt="bookCover"
                  className="h-[calc(100vh-35px)]"
                />
              </div>
            </div>
          </div>

          {/* halaman 1 / 2 */}
          <div className="page demoPage">
            <BlankPage />
          </div>

          <div className="page demoPage over" data-density="soft">
            {/* <HomeComponent goToPage={goToPage} page={pageRef.current?.pageFlip()?.pages?.currentPageIndex} data="home"/> */}
            <HomeComponent goToPage={goToPage} page={2} data="home" />
          </div>
          {/* halaman 3 / 4 */}
          <div className="page demoPage" data-density="soft">
            {/* <SyaratPageCover goToPage={goToPage} page={pageRef.current?.pageFlip()?.pages?.currentPageIndex} /> */}
            <SyaratPageCover goToPage={goToPage} page={3} />
          </div>
          <div className="page demoPage">
            {/* <SyaratPage goToPage={goToPage} page={pageRef.current?.pageFlip()?.pages?.currentPageIndex}/> */}
            <SyaratPage goToPage={goToPage} page={4} />
          </div>

          {/* halaman 5 / 6 */}
          <div className="page demoPage">
            {/* <DownloadPageCover goToPage={goToPage} page={pageRef.current?.pageFlip()?.pages?.currentPageIndex} /> */}
            <DownloadPageCover goToPage={goToPage} page={5} />
          </div>
          <div className="page demoPage ">
            {/* <DownloadPage goToPage={goToPage} page={pageRef.current?.pageFlip()?.pages?.currentPageIndex}/> */}
            <DownloadPage goToPage={goToPage} page={6} />
          </div>

          {/* halaman 7 / 8 */}
          <div className="page demoPage">
            <AgendaPageCover goToPage={goToPage} page={7} />
          </div>
          <div className="page demoPage">
            <AgendaPage goToPage={goToPage} page={8} />
          </div>
          {/* halaman 9 / 10 */}
          <div className="page demoPage">
            <PesertaCover goToPage={goToPage} page={9} />
          </div>
          <div className="page demoPage">
            <PesertaPage goToPage={goToPage} page={10} />
          </div>

          {/* halaman 11 / 12 */}
          <div className="page demoPage">
            <SponsorCover goToPage={goToPage} page={11} />
          </div>
          <div className="page demoPage">
            <SponsorPage goToPage={goToPage} page={12} />
          </div>

          {/* halaman 13 / 14 */}
          <div className="page demoPage">
            <LokasiCover goToPage={goToPage} page={13} />
          </div>
          <div className="page demoPage">
            <LokasiPage goToPage={goToPage} page={14} />
          </div>
        </HTMLFlipBook>
      </div>
    </Fragment>
  );
};
export default HomePage;
