import { useState } from "react";
import { useGlobalFunction } from "../../generalFunction";
import api from "../../api";
import config from "../../config";
import { useCookies } from 'react-cookie';


export function useLogin() {
    const { isLoading, setIsLoading, alertValue, setAlertValue } = useGlobalFunction();
    const [cookies, setCookie] = useCookies([config.cookiesName]);
    const [formData, setFormData] = useState({
        username: {
            name: 'username',
            type: 'text',
            label: 'Username',
            value: '',
            require: true,
            errorMsg: '',
            showError: false,
            show: true
        },
        password: {
            name: 'password',
            type: 'password',
            label: 'Password',
            value: '',
            require: true,
            errorMsg: '',
            showError: false,
            show: true,
            showPassword: false,
            inputType: 'password'
        },
    })


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(formData => ({
            ...formData,
            [name]: { ...formData[name], value: value }
        }))
    }

    const handleBlur = (e) => {
        const { name, value } = e.target;
        if (formData[name].require && value === '') {
            setFormData(formData => ({
                ...formData,
                [name]: { ...formData[name], showError: true }
            }))
        } else {
            setFormData(formData => ({
                ...formData,
                [name]: { ...formData[name], showError: false }
            }))
        }
    }

    const handleShowPassword = (name) => {
        setFormData(formData => ({
            ...formData,
            [name]: {
                ...formData[name],
                showPassword: !formData[name].showPassword,
                type: !formData[name].showPassword ? 'text' : 'password'
            }
        }))
    }



    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            let countError = 0;
            let tmp = {}
            let obj = { ...formData };

            Object.entries(obj).map(([index, post]) => {
                if (post.require && post.value === '') {
                    post['showError'] = true;
                    countError++;
                } else {
                    tmp[index] = post.value;
                }
                return true;
            })

            if (countError > 0) {
                setIsLoading(false);
                return;
            }


            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmp))

            const res = await api.post(`${config.endPoint.login}`, newForm).then(res => res.data);
            if (res) {
                let tmpcookie = {
                    'token': res.token,
                    'user_info': res.nama,
                    'is_superuser': res.is_superuser,
                    // 'development' : res.development,
                    // 'user_perm' : res.user_perm,
                    'is_login': true
                };
                setCookie(config.cookiesName, tmpcookie, { path: '/', maxAge: config.expiresSession });
                return window.location.href = '/admin/dashboard'
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            let msg = 'Kami sedang mengalami gangguan';
            let { response } = error;
            if (response && response.data && response.data.message) {
                msg = response.data.message
            }

            setIsLoading(false);
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }));
            }, config.timeOutValue)
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    }

    return {
        isLoading, setIsLoading, formData, setFormData, handleChange, handleBlur, handleShowPassword,
        alertValue, handleSubmit, cookies, handleKeyDown
    }
}