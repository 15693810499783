import { useCallback, useEffect, useState } from "react";
import { useGlobalFunction } from "../../../generalFunction";

import config from "../../../config";
import Moment from "moment";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export function useHomeNew() {
  const {
    showLoading,
    closeLoading,
    isLoading,
    setIsLoading,
    alertValue,
    setAlertValue,
  } = useGlobalFunction();
  const [info, setInfo] = useState(null);
  const [eventTime, setEventTime] = useState(null);
  const [listAgenda, setListAgenda] = useState([]);
  const [listKategoriSponsor, setListKategoriSponsor] = useState([]);
  const [kategoriSelected, setkategoriSelected] = useState(0);
  const [canRegister, setCanRegister] = useState(false);
  const [listSponsor, setListSponsor] = useState({ isLoading: true, data: [] });
  const navigate = useNavigate();

  const [statusPendaftaran, setStatusPendaftaran] = useState(
    "Sedang Berlangsung"
  );
  const [runningPendaftaran, setRunningPendaftaran] = useState(
    "Sedang Berlangsung"
  );

  const fetchInfo = useCallback(async () => {
    try {
      const newRes = await axios
        .get(
          `${config.apiBaseUrl[process.env.NODE_ENV]}${config.endPoint.eventResume
          }`
        )
        .then((res) => res.data);
      if (newRes) {
        setInfo(newRes.results);

        setEventTime(
          Moment(newRes.results.date_display, "DD-MM-YYYY HH:mm:ss")
            .add(9, 'hours') // Menambahkan 9 jam secara Hardcode
            .toDate()
        );


        setCanRegister(newRes.results.can_register);

        setListAgenda(newRes.agenda);

        setStatusPendaftaran(newRes.results.status_pendaftaran);

        setRunningPendaftaran(newRes.results.running_pendaftaran);

        setListKategoriSponsor(newRes.kategori_sponsor);

        if (newRes.kategori_sponsor.length > 0) {
          setkategoriSelected(newRes.kategori_sponsor[0].id);
        }
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      setIsLoading(false);
      let msg = "Kami sedang mengalami gangguan";
      let { response } = error;
      if (response && response.data && response.data.message) {
        msg = response.data.message;
      }

      setIsLoading(false);
      setAlertValue((alertValue) => ({
        ...alertValue,
        show: true,
        text: msg,
        color: "danger",
      }));

      setTimeout(() => {
        setAlertValue((alertValue) => ({ ...alertValue, show: false }));
      }, config.timeOutValue);
    }
  }, [setAlertValue, setIsLoading]);

  useEffect(() => {
    fetchInfo();
  }, [fetchInfo]);

  // ============ countdown ===================
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const leading0 = (num) => {
    return num < 10 ? "0" + num : num;
  };

  const getTimeUntil = (deadline) => {
    const time = Date.parse(deadline) - Date.parse(new Date());
    if (time < 0) {
      setDays(0);
      setHours(0);
      setMinutes(0);
      setSeconds(0);
    } else {
      setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
      setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
      setMinutes(Math.floor((time / 1000 / 60) % 60));
      setSeconds(Math.floor((time / 1000) % 60));
    }
  };

  useEffect(() => {
    if (eventTime) {
      setInterval(() => getTimeUntil(eventTime), 1000);

      return () => getTimeUntil(eventTime);
    }
  }, [eventTime]);

  const fetchSponsor = useCallback(async (kategori = 0) => {
    setListSponsor((listSponsor) => ({ ...listSponsor, isLoading: true }));
    try {
      let params = {
        // kategori: kategori,
        offset: 0,
        limit: 300,
      };
      const res = await axios
        .get(
          `${config.apiBaseUrl[process.env.NODE_ENV]}${config.endPoint.sponsorShipLogin
          }`,
          { params: params }
        )
        .then((res) => res.data);
      if (res) {
        setListSponsor((listSponsor) => ({
          ...listSponsor,
          isLoading: false,
          data: res.results,
        }));
      } else {
        setListSponsor((listSponsor) => ({ ...listSponsor, isLoading: false }));
      }
    } catch (error) {
      setListSponsor((listSponsor) => ({ ...listSponsor, isLoading: false }));
    }
  }, []);

  const handleChangeTabSponsor = (params) => {
    setkategoriSelected(params.id);
  };

  // useEffect(() => {
  //   if (kategoriSelected) {
  //     fetchSponsor(kategoriSelected);
  //   }
  // }, [kategoriSelected, fetchSponsor]);

  useEffect(() => {
    fetchSponsor()
  }, [fetchSponsor])

  const handleRegister = () => {
    navigate("/member/login");
  };

  // Mengelompokkan sponsor berdasarkan kategori
  const groupByCategory = (sponsors = []) => {
    return sponsors.reduce((acc, sponsor) => {
      const category = sponsor.kategori_display;
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(sponsor);
      return acc;
    }, {});
  };

  const groupedSponsors = groupByCategory(listSponsor?.data);


  return {
    showLoading,
    closeLoading,
    alertValue,
    days,
    minutes,
    hours,
    seconds,
    leading0,
    info,
    eventTime,
    statusPendaftaran,
    isLoading,
    listAgenda,
    listKategoriSponsor,
    listSponsor,
    kategoriSelected,
    handleChangeTabSponsor,
    handleRegister,
    canRegister,
    runningPendaftaran,
    groupByCategory,
    groupedSponsors
  };
}
