import { Icon } from "@iconify/react/dist/iconify.js";
import { useDownloadPage } from "./downloadPageFunction";

const DownloadPage = () => {
  const { listData, handleDownload } = useDownloadPage();


  const getIcon = (icon) => {
    if (['pdf'].includes(icon)) {
      return "vscode-icons:file-type-pdf2"
    } else if (['docx', 'doc'].includes(icon)) {
      return "vscode-icons:file-type-word"
    } else if (['xlsx', 'xls'].includes(icon)) {
      return "vscode-icons:file-type-excel2"
    } else {
      return "el:download-alt"
    }
  }
  return (
    <div className="w-full min-h-screen h-fit  text-black relative flex flex-col items-center mt-[80px] md:mt-[100px]">
      <div className="w-full text-center max-w-homeWidth px-[10px] md:px-[100px]">
        <div className="font-bold title md:text-[24px] text-[14px]">
          Dokumen Persyaratan & Laporan Musyawarah Nasional XII APJII Tahun 2024
        </div>
        <div className="flex flex-row mt-[100px] gap-5 flex-wrap justify-center md:justify-start">
          {listData.map((post, index) => {
            return (
              <button
                onClick={() => handleDownload(post)}
                download={post.nama}
                className="flex flex-col items-center border border-gray-500 shadow-lg rounded-lg bg-slate-100 justify-center w-[300px]"
                key={index}
              >
                <div className="h-[200px] flex flex-row items-center justify-center">
                  <Icon icon={getIcon(post?.meta?.format)} fontSize={"100"} />
                </div>
                <div className="px-[26px] py-[20px] justify-center h-[100px]  w-full bg-white rounded-b-lg flex flex-row items-center gap-4 text-blue-600">
                  <span><Icon icon="icon-park-outline:download-two" fontSize={"24"} /></span>{" "} {post.nama}
                </div>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default DownloadPage;
