export const capitalAllLetters = (string) => {
  try {
    if (typeof string !== "string") {
      throw new Error("Input must be a string");
    }
    return string.toUpperCase();
  } catch (error) {
    console.error("An error occurred:", error.message);
    return ""; // Atau bisa mengembalikan nilai default lainnya
  }
};
