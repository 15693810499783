import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import PesertaLoginPage from "../../views/Member/PageLoginComponent/Peserta/PesertaLoginPage";
import SponsorLoginPage from "../../views/Member/PageLoginComponent/Sponsor/SponsorLoginPage";
import AgendaLoginPage from "../../views/Member/PageLoginComponent/Agenda/AgendaLoginPage";
import LocationPage from "../../views/Member/PageLoginComponent/Location/LocationPage";
import CoverLoginPage from "../../views/Member/PageLoginComponent/Cover/CoverLoginPage";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Outlet } from "react-router-dom";
// import Moment from 'moment';
import api from "../../api";
import config from "../../config";
import { UserContext } from "../../context/UserContext";
import Cookies from 'universal-cookie';

const LayoutMemberLogin = () => {
  const pageRef = useRef([]);
  const btnRef = useRef([]);
  const [selected, setSelected] = useState(-1);
  const [page, setPage] = useState(0);
  const cookies = new Cookies();
  const myState = useContext(UserContext);
  // eslint-disable-next-line
  const [canRegister, setCanRegister] = myState.canRegister;

  const backReserve = () => {
    pageRef.current[selected].classList.add("z-50");
    pageRef.current[selected].classList.remove("turn");
    pageRef.current.map((post, index) => {
      // post.classList.remove('turn');
      if (index !== selected) {
        post.classList.remove("z-50");
      }
      return true;
    });
    pageRef.current[selected].classList.toggle("flip-back");
    if (selected > 0) {
      setSelected(selected - 1);
    }
    setPage(page - 1);
  };

  const setAllPages = (params) => {
    pageRef.current.map((post) => {
      post.classList.remove("z-50");
      post.classList.remove("flip-back");
      return true;
    });
  };

  const setClickedPageForward = (params) => {
    if (params + 1 === pageRef.current.length) {
      pageRef.current[params - 1].classList.add("z-50");
    } else {
      pageRef.current[params + 1].classList.add("z-50");
    }
  };

  const togglePageFlip = (params) => {
    pageRef.current[params].classList.toggle("turn");

    setAllPages(params);
    setSelected(params);
    setTimeout(() => {
      setClickedPageForward(params);
    }, 600);
    setPage(page + 1);

    // setTimeout(() => {
    //     pageRef.current[params].classList.remove('turn');
    // }, 1500)
  };

  const fetchRegisterDisable = useCallback(async () => {
    try {
      const res = await api
        .get(`${config.endPoint.settingDisableGuest}`)
        .then((res) => res.data);
      // console.log('res layout member is >>>>', res);
      if (res) {
        // let tmpRegister = false;
        // let tanggal = Moment(res.results.open_until_display, 'DD-MM-YYYY HH:mm').toDate();
        // let today = new Date()

        // tmpRegister = !res.results.registrasi_disable;

        // tmpRegister = !res.results.lock_all

        // if (tanggal >= today){
        //     tmpRegister = true
        // }
        setCanRegister(res.results.can_register);
        // setCanRegister(tmpRegister)
      }
    } catch (error) {
      const { response } = error;
      if (response && response.status === 401){
        cookies.remove(config.cookiesName, { path: '/' });	
        setCanRegister(false);
      }
      // console.log('error can register >>>>')
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchRegisterDisable();
  }, [fetchRegisterDisable]);

  // useEffect(() => {
  //     // return () => {
  //     //     clearInterval(interval);
  //     // };

  //     if (pageRef){
  //         // const interval = setInterval(() => {
  //         //     togglePageFlip(0)
  //         // }, 3000);
  //         // return () => {
  //         //     clearInterval(interval);
  //         // };

  //         const interval = setInterval(() => {
  //             if (selected  + 1 !== pageRef.current.length){
  //                 togglePageFlip(selected)
  //             }else{
  //                 backReserve();
  //             }
  //         }, 1000);

  //         return () => {
  //             clearInterval(interval);
  //         };

  //     }
  // }, [pageRef, togglePageFlip, backReserve, selected    ]);

  return (
    <Fragment>
      <div className="w-full h-screen font-poppins overflow-hidden">
        <div className="grid grid-cols-12 h-screen">
          <div className="col-span-full hidden md:block md:col-span-7 bg-gradient-to-b from-blue-900 to-blue-500 md:min-h-screen md:bg-cover md:bg-no-repeat md:bg-top md:relative ">
            <div
              className="absolute px-[60px] z-[100] cursor-pointer"
              onClick={backReserve}
            >
              <img
                src={require("../../assets/images/binder2.png")}
                alt="binder"
              />
            </div>
            <div className="w-full h-screen pl-[20px] py-[20px] perspective flex flex-col ">
              <PesertaLoginPage
                myRef={(el) => (pageRef.current[3] = el)}
                dataPage={4}
              />
              <SponsorLoginPage
                myRef={(el) => (pageRef.current[3] = el)}
                dataPage={3}
              />
              <AgendaLoginPage
                myRef={(el) => (pageRef.current[2] = el)}
                dataPage={2}
              />
              <LocationPage
                myRef={(el) => (pageRef.current[1] = el)}
                dataPage={1}
              />
              <CoverLoginPage
                myRef={(el) => (pageRef.current[0] = el)}
                dataPage={0}
              />

              <div className="absolute bottom-8 left-0 w-full px-[20px]">
                <div
                  className={`flex flex-row ${
                    page === 0 ? "justify-center" : "justify-between"
                  } items center`}
                >
                  {page === 0 ? (
                    <button
                      className={`text-white animate-pulse text-[16px] font-bold flex flex-row gap-2 items-center`}
                      onClick={() => {
                        togglePageFlip(0);
                      }}
                    >
                      <Icon icon="lucide:book-open-text" /> Buka
                    </button>
                  ) : (
                    <Fragment>
                      <button
                        ref={btnRef[0]}
                        className="flex flex-row text-white items-center gap-2 btn-left"
                        onClick={() => {
                          backReserve();
                        }}
                      >
                        <Icon
                          icon="icon-park-outline:invert-right"
                          fontSize={24}
                          className="rotate-180"
                        />{" "}
                        Kembali{" "}
                      </button>
                      {/* <button className='flex flex-row text-white items-center gap-2 btn-right' onClick={() => togglePageFlip(page === 1 ? selected : page-1)}><Icon icon="icon-park-outline:invert-right" fontSize={24}/> Selanjutnya </button> */}
                      {pageRef && page < pageRef.current.length && (
                        <button
                          className="flex flex-row text-white items-center gap-2 btn-right"
                          onClick={() => togglePageFlip(selected + 1)}
                        >
                          <Icon
                            icon="icon-park-outline:invert-right"
                            fontSize={24}
                          />{" "}
                          Selanjutnya{" "}
                        </button>
                      )}
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-full md:col-span-5 md:bg-white  flex flex-col pl-[5%] justify-center  pr-[5%] ">
            <Outlet />
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default LayoutMemberLogin;
