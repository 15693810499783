import { useCallback, useEffect, useState } from "react";
import { useGlobalFunction } from "../../../../generalFunction";
import config from "../../../../config";
import api from "../../../../api";
import { pageObj } from "../../../../pageObj";

export function useGroupPermissions(){
    const { isLoading, setIsLoading, alertValue, setAlertValue, handleCloseAlert,
        modalValue, setModalValue, handleCloseModal, showLoading, closeLoading,
        pageValue, setPageValue, catchFetching
    } = useGlobalFunction();
    const [listData, setListData] = useState({isLoading : isLoading, data: []});
    const [view, setView] = useState('list');
    const [isEdit, setIsEdit] = useState(false);
    const [formData, setFormData] = useState({
        id : {
            name : 'id',
            type: 'text',
            label : 'ID',
            value : '',
            required : true,
            showError : false,
            show: true,
        },
        nama : {
            name : 'nama',
            type: 'text',
            label : 'Nama Group',
            value : '',
            required : true,
            showError : false,
            show: true,
            flexType : 'row',
        },
    })
    const [listAvailablePermissions, setListAvailablePermissions] = useState({data : [], isLoading: false});    
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [selectedGranted, setSelectedGranted] = useState([]);
    const [listGranted, setListGranted] = useState({isLoading : false, data:  []});

    const fetchData = useCallback(async(q='', offset=0, limit=config.itemPerPage) => {
        showLoading()
        try{
            const params = {
                offset : offset,
                limit : limit,
            }
            if (q) {
                params['q'] = q
            }
            const res = await api.get(`${config.endPoint.permissionGroup}`, {params: params}).then(res => res.data);
            if (res){
                setListData(listData => ({...listData, isLoading: false, data: res.results}))
                setPageValue(pageValue => ({...pageValue,
                    obj : pageObj(res.count, limit, offset),
                    lastPage : Math.ceil(parseInt(res.count) / parseInt(limit))
                }))
            }else{
                setListData(listData => ({...listData, isLoading: false}))
            }
            closeLoading()
        }catch(error){
            setListData(listData => ({...listData, isLoading: false}))
            closeLoading()
            const { response } = error;
            let msg = 'Unable to fetch data'
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            setAlertValue(alertValue => ({...alertValue,
                show: true,
                text : 'Error',
                subText: msg,
                color: 'danger'
            }))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
        // eslint-disable-next-line 
    },[])

    const fetchPermissions = useCallback(async(q='', offset=0, limit=1000) => {
        showLoading()
        try{
            const params = {
                offset : offset,
                limit : limit,
            }
            if (q) {
                params['q'] = q
            }
            const res = await api.get(`${config.endPoint.permissions}`, {params: params}).then(res => res.data);
            if (res){
                setListAvailablePermissions(listAvailablePermissions => ({...listAvailablePermissions, isLoading: false, data: res.results}))
                setPageValue(pageValue => ({...pageValue,
                    obj : pageObj(res.count, limit, offset),
                    lastPage : Math.ceil(parseInt(res.count) / parseInt(limit))
                }))
            }else{
                setListAvailablePermissions(listAvailablePermissions => ({...listAvailablePermissions, isLoading: false}))
            }
            closeLoading()
        }catch(error){
            setListAvailablePermissions(listAvailablePermissions => ({...listAvailablePermissions, isLoading: false}))
            closeLoading()
            const { response } = error;
            let msg = 'Unable to fetch data'
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            setAlertValue(alertValue => ({...alertValue,
                show: true,
                text : 'Error',
                subText: msg,
                color: 'danger'
            }))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
        // eslint-disable-next-line 
    },[])


    
    
    useEffect(() => {
        if (!isEdit){
            fetchPermissions();
        }
    },[fetchPermissions, isEdit])

    const handleCreate = () => {
        setView('create')
    }


    
    useEffect(() => {
        fetchData();
    },[fetchData])

    const handleChangeSearch = (e) => {
        const { name, value } = e.target;
        setPageValue(pageValue => ({...pageValue, [name] : value}))
    }
    
    const handleKeyDownSearch = (e) => {
        if (e.key === 'Enter'){
            handleSearch();
        }
    }
    
    const handleSearch = () => {
        fetchData(pageValue.search, 0, config.itemPerPage)
    }

    const handlePaginate = (page) => {
        let myOffset = (parseInt(page) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
        setPageValue(pageValue => ({...pageValue, page : page, offset: myOffset}))
        fetchData(pageValue.search, myOffset, config.itemPerPage);
    }

    const handleSelectedAvailable = (post) => {
        let tmp = [...selectedPermissions];
        let index = tmp.findIndex(x => x.id === post.id)
        
        
        if (index === -1){
            tmp.push(post)            
        }else{
            tmp.splice(index, 1)            
        }        
        setSelectedPermissions(tmp);
    }

    const handleSelectedGranted = (post) => {
        let tmp = [...selectedGranted];
        let index = tmp.findIndex(x => x.id === post.id)
        
        
        if (index === -1){
            tmp.push(post)            
        }else{
            tmp.splice(index, 1)            
        }        
        setSelectedGranted(tmp);
    }


    const handleSelectedToGranted = () => {
        let tmpGranted = [...listGranted.data];        
        tmpGranted = tmpGranted.concat(selectedPermissions);
        
        const tmpAvailablePermisiions = listAvailablePermissions.data.filter(x => !selectedPermissions.some(a => a.id === x.id));
        setListAvailablePermissions(listAvailablePermissions => ({...listAvailablePermissions, data: tmpAvailablePermisiions}))
        setListGranted(listGranted => ({...listGranted, data: tmpGranted}))

        setSelectedPermissions([]);
    }

    const handleAvailableToGranted = () => {
        setListGranted(listGranted => ({...listGranted, data: [...listGranted.data, ...listAvailablePermissions.data]}));
        setListAvailablePermissions(listAvailablePermissions => ({...listAvailablePermissions, data : []}))
    }

    const handleSelectedGrantedToAvailable = () => {
        let tmpAvailable = [...listAvailablePermissions.data];
        tmpAvailable = tmpAvailable.concat(selectedGranted);

        const tmpGranted = listGranted.data.filter(x => !selectedGranted.some(a => a.id === x.id));
        setListGranted(listGranted => ({...listGranted, data: tmpGranted}))
        setListAvailablePermissions(listAvailablePermissions => ({...listAvailablePermissions, data: tmpAvailable}))

        setSelectedGranted([]);
    }

    const handleGrantedToAvailable = () => {
        let tmpSelectedGranted = [...listGranted.data];


        setListAvailablePermissions(listAvailablePermissions => ({...listAvailablePermissions, data: [...listAvailablePermissions.data, ...tmpSelectedGranted]}));
        setListGranted(listGranted => ({...listGranted, data : []}))
    }

    const handleSubmit = async() => {
        try{
            let obj = {...formData}
            let countError = 0;
            let tmp = {}
            Object.entries(obj).map(([index, post]) => {
                if (post.required && post.value === ''){
                    post.showError= true;
                    countError++;
                }else{
                    tmp[index] = post.value
                }
                    return true;
            })
            
            if (countError > 0){
                setAlertValue(alertValue => ({...alertValue,
                    show: true,
                    text : 'Error',
                    subText: 'Lengkapi data',
                    color: 'danger'
                }))
                setIsLoading(false);
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue)
            }
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmp));
            newForm.append('permissions', JSON.stringify(listGranted.data));
            
            let res;
            if (isEdit){
                res = await api.put(`${config.endPoint.permissionGroup}${formData.id.value}/`, newForm).then(res => res.data);
            }else{
                res = await api.post(`${config.endPoint.permissionGroup}`, newForm).then(res => res.data);
            }
            if (res){
                setAlertValue(alertValue => ({...alertValue,
                    show: true,
                    text : 'Success',
                    subText: 'Data berhasil disimpan',
                    color: 'success'
                }))
            
                fetchData(pageValue.search, pageValue.offset, config.itemPerPage)
            
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue)
            }
            setView('list');
        }catch(error){
            setIsLoading(false);
            const { response } = error;
            let msg = 'Unable to save data'
            catchFetching(error, 'admin');
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            
            setAlertValue(alertValue => ({...alertValue,
                show: true,
                text : 'Error',
                subText: msg,
                color: 'danger'
            }))
            
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }

    const handleEdit = async(post) => {
        showLoading();
        setIsLoading(true);
        try{
            const res = await api.get(`${config.endPoint.permissionGroup}${post.id}/`).then(res => res.data);
            if (res){
                setFormData(formData => ({...formData,
                    nama : {...formData.nama, value : res.results.name},
                    id : {...formData.id, value : res.results.id}
                }))
                setListAvailablePermissions(listAvailablePermissions => ({...listAvailablePermissions, data : res.results.list_permissions}))
                setListGranted(listGranted => ({...listGranted, data : res.results.detail}));
                setView('create');
                setIsEdit(true);
            }
            closeLoading();            
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            closeLoading();
            const { response } = error;
            let msg = 'Unable to fetchData data'
            
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            
            setAlertValue(alertValue => ({...alertValue,
                show: true,
                text : 'Error',
                subText: msg,
                color: 'danger'
            }))
            
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }

    const handleDelete = (params) => {
        setModalValue(modalValue => ({...modalValue,
            show: true,
            text: `Apakah anda yakin akan menghapus group permissions ${params.name} ?`,
            id : params.id,
            title : 'Konfirmasi',
        }))
    }
    
    const handleSubmitDelete = async() => {
        setIsLoading(true);
        try{
            const res = await api.delete(`${config.endPoint.permissionGroup}${modalValue.id}/`).then(res => res.data);
            if (res){
                fetchData(pageValue.search, pageValue.offset, config.itemPerPage);
                setAlertValue(alertValue => ({...alertValue,
                    show: true,
                    text : 'Delete Success',
                    subText: 'Data berhasil di delete',
                    color: 'success'
                }))
    
                setTimeout(() => {
                    handleCloseAlert();
                }, config.timeOutValue)
            }
            handleCloseModal()
        }catch(error){
            setIsLoading(false);
            const { response } = error;
            let msg = 'Unable to delete data'
    
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            setAlertValue(alertValue => ({...alertValue,
                show: true,
                text : 'Error',
                subText: msg,
                color: 'danger'
            }))
    
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }

    return {
        isLoading, setIsLoading, alertValue, setAlertValue, handleCloseAlert, 
        modalValue, setModalValue, handleCloseModal, formData, setFormData,
        isEdit, setIsEdit, view, setView, listData, setListData, pageValue,
        handleEdit, handleCreate, handleChangeSearch, handleKeyDownSearch, handleSearch,
        handlePaginate, listAvailablePermissions, handleSelectedAvailable, 
        handleSelectedToGranted, selectedPermissions, selectedGranted, handleSelectedGranted,
        listGranted, handleAvailableToGranted, handleSelectedGrantedToAvailable,
        handleGrantedToAvailable, handleSubmit,  handleDelete, handleSubmitDelete
    }
}