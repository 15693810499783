import { Fragment } from "react"
import { useHapusDataRegistrasi } from "./hapusDataRegistrasiFunction";
import Modal from "../../../../Components/Atoms/Modal";
import Button from "../../../../Components/Atoms/Button";
import Card from "../../../../Components/Layout/Card";
import Tables from "../../../../Components/molecules/Tables";
import Tooltip from "../../../../Components/Atoms/Tooltip";
import { Icon } from "@iconify/react/dist/iconify.js";
import PagePagination from "../../../../Components/Atoms/PagePagination";
import Search from "../../../../Components/Atoms/Search";
import FormTextSingleComp from "../../../../Components/forms/FormTextSingleComp";


const HapusDataRegistrasiPage = () => {
    const { modalValue, setModalValue, handleSubmitDelete, isLoading, handleCloseModal,
        isShowAkta, isShowPernyataan, isShowKuasa, handleShowAlasanAkta, handleShowAlasanKuasa, handleShowAlasanPernyataan,
        listData, pageValue, handleDelete, handlePaginate, handleSearch, handleChangeSearch
    } = useHapusDataRegistrasi();

    const getColorLocal = (params) => {
        if (params === 1) {
            return "text-green-600 bg-green-100"
        } else if (params === 2) {
            return "text-red-600 bg-red-100"
        } else if (params === 3) {
            return "text-white bg-gray-500"
        } else {
            return "text-white bg-black/40"
        }
    }

    const getIcon = (params) => {
        if (params === 1){
            return "ri:check-double-fill"
        }else if(params === 2){
            return "typcn:minus"
        }else if(params === 3){
            return "svg-spinners:clock"
        }else {
            return "ic:baseline-lock"
        }
    }

    return(
        <Fragment>
            <Modal
                show={modalValue.show}
                setShow={() => {                        
                    setModalValue(modalValue => ({...modalValue, show: false}))
                }}
                width="xl"
                persistent={false}   
                btnClose={false}   
                title={modalValue.title}
                withHeader={true}                
            >
                <div className='p-[20px]'>
                    {modalValue.text}
                    <div className='flex flex-row justify-end border-t border-t-gray-200 pt-[10px] mt-[20px] items-start'>
                        <div className='flex flex-row justify-end border-t-gray-200 pt-[10px] mt-[20px] items-start gap-2'>
                            <Button color="base" onClick={handleCloseModal}>Close</Button>
                            <Button color="danger" onClick={handleSubmitDelete}  disabled={isLoading}>Submit</Button>
                        </div>
                    </div>
                </div>
            </Modal>
            <div className='flex flex-row gap-1 mb-5'>
                <Button  className={`${isShowAkta ? "bg-primaryweb" : "bg-disabled"} text-white`} onClick={handleShowAlasanAkta}>Lihat Alasan Akta</Button>
                <Button  className={`${isShowPernyataan ? "bg-primaryweb" : "bg-disabled"} text-white`} onClick={handleShowAlasanPernyataan}>Lihat Alasan Surat Pernyataan</Button>
                <Button  className={`${isShowKuasa ? "bg-primaryweb" : "bg-disabled"} text-white`} onClick={handleShowAlasanKuasa}>Lihat Alasan Surat Kuasa</Button>
            </div>
            <Card
                isSearch={false}
                isCreate={false}
                title="Data Peserta"
                bodyStyle={{marginTop: 0}}
            >
                <div className="flex flex-row justify-between">
                    <div className='w-full md:w-60 mb-3'>
                        <Search
                            onChange={handleChangeSearch}
                            handleSearch={handleSearch}
                            value={pageValue.search}
                            placeholder="Search"
                        />
                    </div>
                    <div className="flex flex-row gap-2">
                        <div className='relative'>
                            <FormTextSingleComp
                                name="complete"
                                value={pageValue.complete}
                                obj={pageValue.complete_obj}
                                onChange={handleChangeSearch}
                                show={true}
                                required={false}
                                type={"select"}
                                hideLabel={true}
                            />
                            <div className="absolute -top-0 left-3 text-[11px] bg-white px-1 text-primaryweb">
                                Filter Complete
                            </div>
                        </div>
                        <div className='relative'>
                            <FormTextSingleComp
                                name="is_lock"
                                value={pageValue.is_lock}
                                obj={pageValue.is_lock_obj}
                                onChange={handleChangeSearch}
                                show={true}
                                required={false}
                                type={"select"}
                                hideLabel={true}
                            />
                            <div className="absolute -top-0 left-3 text-[11px] bg-white px-1 text-primaryweb">
                                Filter Lock
                            </div>
                        </div>
                    </div>
                </div>
                <Tables className='border-separate rounded-sm'>
                    <Tables.Head className='bg-[#efeeee]'>
                        <tr>
                            <Tables.Header className='font-bold text-[13px] py-[10px] text-right'>#</Tables.Header>                            
                            <Tables.Header className='font-bold text-[13px] py-[10px]'>Perusahaan</Tables.Header>            
                            <Tables.Header className='font-bold text-[13px] py-[10px]'>Akta</Tables.Header>
                            <Tables.Header className='font-bold text-[13px] py-[10px]'>Peserta</Tables.Header>
                            <Tables.Header className='font-bold text-[13px] py-[10px]'>Jabatan</Tables.Header>
                            <Tables.Header className='font-bold text-[13px] py-[10px]'>Surat Pernyataan / Kuasa</Tables.Header>                            
                            <Tables.Header className='font-bold text-[13px] py-[10px]'>Surat Foto</Tables.Header>
                            <Tables.Header className='font-bold text-[13px] py-[10px]'>Complete</Tables.Header>
                            <Tables.Header className='font-bold text-[13px] py-[10px]'>Action</Tables.Header>
                        </tr>
                    </Tables.Head>
                    <Tables.Body>
                        { listData.isLoading && Array(16).fill(0).map((_, i) => {
                            return(
                                <Tables.Row className='animate-pulse' key={i}>
                                    <Tables.Data className='text-right' style={{textAlign : 'right'}}>
                                        <div className='h-4 w-[10px] bg-slate-200 rounded'></div>                                    
                                    </Tables.Data>
                                    <Tables.Data>
                                        <div className='h-4 bg-slate-200 rounded'></div>
                                    </Tables.Data>
                                    <Tables.Data>
                                        <div className='h-4 bg-slate-200 rounded'></div>
                                    </Tables.Data>
                                    <Tables.Data>
                                        <div className='h-4 bg-slate-200 rounded'></div>
                                    </Tables.Data>
                                    <Tables.Data>
                                        <div className='h-4 bg-slate-200 rounded'></div>
                                    </Tables.Data>
                                    <Tables.Data>
                                        <div className='h-4 bg-slate-200 rounded'></div>
                                    </Tables.Data>
                                    <Tables.Data>
                                        <div className='h-4 bg-slate-200 rounded'></div>
                                    </Tables.Data>
                                    <Tables.Data>
                                        <div className='h-4 bg-slate-200 rounded'></div>
                                    </Tables.Data>
                                    <Tables.Data>
                                        <div className='h-4 bg-slate-200 rounded'></div>
                                    </Tables.Data>
                                </Tables.Row>
                            )
                        })}
                        { !listData.isLoading && listData?.data?.map((post, index) => {
                            return(
                                <Tables.Row key={index} className={`${post.is_lock && 'row-lock'}`}>
                                    <Tables.Data className='text-right align-top'>{pageValue.offset + index + 1}</Tables.Data>
                                    <Tables.Data className='align-top'>
                                        <div className='flex flex-col items-start'>
                                            <div>{post.perusahaan_display}</div>
                                            { post.hotel_display &&
                                                <div className='italic text-blue-600 bg-blue-100 px-2 text-left rounded'>{post.hotel_display}</div>
                                            }
                                        </div>
                                    </Tables.Data>
                                    <Tables.Data className='w-[15%] align-top'>
                                        <div className="flex flex-col items-start">
                                            <div className="flex flex-row justify-center gap-1">
                                                { post.akta_url &&                                                    
                                                    <div 
                                                        className={`${getColorLocal(post.status_akta)} text-[12px] px-2 py-1 flex flex-row gap-2 justify-center rounded items-center`}                                                            
                                                    >
                                                        <Icon icon={getIcon(post.status_akta)} />
                                                        {post.status_akta_display}
                                                    </div>
                                                }
                                            </div>
                                            { post.show_alasan_akta && [2,3].includes(post.status_akta) &&
                                                <div>{ post?.detail_display?.alasan_akta }</div>
                                            }
                                        </div>
                                    </Tables.Data>
                                    <Tables.Data className='align-top'>
                                        <div className='flex flex-col items-start'>
                                            <div>
                                                {post.nama}
                                            </div>
                                            { post.duplicate > 1 &&
                                                <div 
                                                    className="px-3 py-1 italic mt-1
                                                        text-[10px]  text-red-500 bg-red-100 
                                                        rounded-full
                                                        dark:border-gray-900"
                                                >
                                                    Duplicate {post.duplicate}
                                                </div>                                            
                                            }
                                        </div>
                                    </Tables.Data>
                                    <Tables.Data className='align-top'>{post.jabatan_display}</Tables.Data>
                                    <Tables.Data className='w-[20%] align-top'>
                                        { post.jabatan === 1 &&
                                            <div className="flex flex-col items-start">
                                                <div 
                                                    className={`${getColorLocal(post.detail_display?.status_surat_pernyataan)} text-[12px] px-2 py-1 flex flex-row gap-2 justify-center rounded items-center`}
                                                >
                                                    <Icon icon={getIcon(post.detail_display?.status_surat_pernyataan)} />
                                                   { post.detail_display?.status_surat_pernyataan_display}
                                                </div>
                                                { post.show_alasan_pernyataan && [2,3].includes(post.detail_display.status_surat_pernyataan) &&
                                                    <div>{ post?.detail_display?.alasan_surat_pernyataan }</div>
                                                }
                                            </div>
                                        }
                                        { post.jabatan === 2 &&
                                            <div className='flex flex-col items-start'>
                                                <div 
                                                    className={`${getColorLocal(post.detail_display?.status_surat_kuasa)} text-[12px] px-2 py-1 flex flex-row gap-2 justify-center rounded items-center`}                                                            
                                                >
                                                    <Icon icon={getIcon(post.detail_display?.status_surat_kuasa)} />
                                                    {post.detail_display?.status_surat_kuasa_display}
                                                </div>  
                                                { post.show_alasan_kuasa && [2,3].includes(post.detail_display.status_surat_kuasa) &&
                                                    <div>{ post?.detail_display?.alasan_surat_kuasa }</div>
                                                }                                              
                                            </div>
                                        }
                                    </Tables.Data>
                                    
                                    <Tables.Data className='align-top'>
                                        <div className={`px-1 bg-gray-100 w-full flex flex-col items-center`}>
                                            <div className=''>
                                                <img src={post.foto_url} alt='foto' className='h-14 w-auto object-contain' />
                                            </div>
                                        </div>
                                    </Tables.Data>
                                    <Tables.Data className='align-top'>
                                        <div className='flex flex-row justify-center items-center w-full h-full'>
                                            <div className={`${post.is_complete ? 'bg-green-100 text-green-600' : 'bg-red-100 text-red-500'} py-0.5 px-2 text-[11px] rounded-full`}>{ post.is_complete ? "Aktif" : "Incomplete" }</div>                                            
                                        </div>
                                    </Tables.Data>
                                    <Tables.Data className="align-top">
                                        <div className='flex flex-col items-center gap-2'>
                                            <div className='flex flex-row gap-1'>
                                                <Tooltip tooltip={"Detail"} delay={500}>                                                
                                                    <button className='p-[8px] bg-red-100 text-red-600 rounded shadow-lg text-[18px]'  onClick={() => handleDelete(post)}><Icon  icon='ph:trash-bold' /></button>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </Tables.Data>
                                </Tables.Row>
                            )
                        })}
                    </Tables.Body>
                </Tables>
                <div className='mt-5 flex flex-row justify-start w-full'>
                    <PagePagination
                        data={pageValue.obj}
                        handlePagination={handlePaginate}
                        page={pageValue.page}
                        lastPage={pageValue.lastPage}
                    />
                </div>
            </Card>
        </Fragment>
    )
}
export default HapusDataRegistrasiPage;