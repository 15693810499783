import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../../../../api";
import config from "../../../../config";
import { useGlobalFunction } from "../../../../generalFunction";


export function useResertPassword(){
    const { alertValue, setAlertValue, handleCloseAlert} = useGlobalFunction();
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [view, setView] = useState('ready');
    const [formData, setFormData] = useState({
        password : {
            name : 'password',
            value : '',
            type : 'password',
            show : true,
            label : 'Password',
            inputType : 'password',
            errorMsg : '',
            showError : false,
            required : true,
            showPassword : false,
        },
        confirm_password : {
            name : 'confirm_password',
            value : '',
            type : 'password',
            show : true,
            label : 'Confirm Password',
            inputType : 'password',
            errorMsg : '',
            showError : false,
            required : true,
            showPassword : false,
        },
    })

    const getExpiredTime = useCallback(async() => {
        setIsLoading(true)
        try{
            let params = {
                encrypt :location.search.replace('?q=', '')
            }
            const res = await api.get(`${config.endPoint.getExpiredVerification}`, {params: params}).then(res => res.data);            
            setIsLoading(false);
            if (res){                
                if (res.is_expired){
                    setView('expired')
                }
                if (res.is_email_verification){
                    setView('already')
                }
            }
        }catch(error){
            setIsLoading(false);
            let msg = 'Unable to save Data';
            let { response } = error;
            if (response && response.data && response.data.message){
                msg = response.data.message
            }            
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }));
            }, config.timeOutValue)
        }
    },[setIsLoading, setAlertValue, location.search])

    useEffect(() => {
        getExpiredTime();
    },[getExpiredTime])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(formData => ({
            ...formData,
            [name]: { ...formData[name], value: value }
        }))
    }

    const handleBlur = (e) => {
        const { name, value } = e.target;
        if (formData[name].require && value === ''){
            setFormData(formData => ({
                ...formData,
                [name]: { ...formData[name], showError: true }
            }))
        }else{
            setFormData(formData => ({
                ...formData,
                [name]: { ...formData[name], showError: false }
            }))
        }
    }

    const handleShowPassword = (name) => {
        setFormData(formData => ({
            ...formData,
            [name]: {
                ...formData[name],
                showPassword: !formData[name].showPassword,
                type: !formData[name].showPassword ? 'text' : 'password'
            }
        }))
    }

    const handleSubmit = async() => {
        setIsLoading(true);
        try{
            let obj = {...formData};
            let countError = 0;
            let tmpForm = {}

            Object.entries(obj).map(([index, post]) => {
                if (post.required && post.value === ''){
                    countError++;
                    post.showError = true;
                }else{
                    post.showError = false;
                    tmpForm[index] = post.value;
                }
                return true;
            })

            if (countError > 0){
                setAlertValue(alertValue => ({...alertValue,
                    show: true, text : 'Lengkapi data', color : 'danger'
                }));
                setFormData(obj);
                setIsLoading(false);
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue,
                        show: false
                    }));                    
                }, config.timeOutValue)
                return;
            }

            if (tmpForm['password'] !== tmpForm['confirm_password']){
                setAlertValue(alertValue => ({...alertValue,
                    show: true, text : 'Error! Confirm password not match', color : 'danger'
                }));
                setFormData(obj);
                setIsLoading(false);
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue,
                        show: false
                    }));                    
                }, config.timeOutValue)
                return;
            }
            
            // tmpForm['encrypt'] = searchParams.get('q')
            tmpForm['encrypt'] = location.search.replace('?q=', '')
            
            
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmpForm));            
            let res = await api.post(`${config.endPoint.resetPassword}`, newForm).then(res => res.data);            
            setIsLoading(false);

            if (res){
                if (res.is_expired){
                    return setView('expired')
                }
                navigate('/member/login')
            }
        }catch(error){
            setIsLoading(false);
            let msg = 'Unable to save Data';
            let { response } = error;
            if (response && response.data && response.data.message){
                msg = response.data.message
            }            
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }));
            }, config.timeOutValue)
        }
    }
    return {
        alertValue, isLoading, navigate, view, handleCloseAlert, formData, setFormData,
        handleChange, handleShowPassword, handleBlur, handleSubmit
    }
}