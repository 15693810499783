import { useCallback, useEffect, useRef, useState } from "react";
import { useGlobalFunction } from "../../../generalFunction";
import config from "../../../config";
import api from "../../../api";
import { useCookies } from "react-cookie";
import { encryptedResponse } from "../../../utils/crypto";

export function useLivePendaftaran(){
    const { isLoading, setIsLoading, alertValue, setAlertValue, handleCloseAlert,
        modalValue, setModalValue, handleCloseModal, showLoading, closeLoading
    } = useGlobalFunction();
    const [info, setInfo] = useState({isLoading : isLoading, data: null});
    const [view, setView] = useState('list');
    const [cookies, setCookie] = useCookies(config.cookiesNamePassword);
    const getcookie = cookies[config.cookiesNamePassword];
    const [isOnline, setIsOnline] = useState(false);
    const [waitingToReconnect, setWaitingToReconnect] = useState(null);
    const ws = useRef(null);
    
    
    const fetchData = useCallback(async(q='', offset=0, limit=config.itemPerPage) => {
        showLoading()
        try{
            const params = {
                offset : offset,
                limit : limit,
                password : encryptedResponse(getcookie['livepreview']),
                kode : encryptedResponse('2')
            }
            if (q) {
                params['q'] = q
            }
            const res = await api.get(`${config.endPoint.livePreview}`, {params: params}).then(res => res.data);
            
            if (res){
                setInfo(info => ({...info, isLoading: false, data: res.results}))
                
            }else{
                setInfo(info => ({...info, isLoading: false}))
            }
            closeLoading()
        }catch(error){
            setInfo(info => ({...info, isLoading: false}))
            closeLoading()
            const { response } = error;
            if (response && response.status === 403){                
                let tmp ;
                if (!config.cookiesNamePassword){
                    tmp = {
                        'livepreview' : ''
                    }
                }else{
                    tmp = {...config.cookiesNamePassword}
                    tmp['livepreview'] = ''
                }
                
                setCookie(config.cookiesNamePassword, tmp, { path: '/', maxAge: config.expiresSession });                    
                setView('password')
            }else{

                let msg = 'Unable to fetch data'
                if (response && response.data && response.data.message){
                    msg = response.data.message;
                }
                setAlertValue(alertValue => ({...alertValue,
                    show: true,
                    text : 'Error',
                    subText: msg,
                    color: 'danger'
                }))
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue)
            }
        }
        // eslint-disable-next-line 
    },[])
    
    useEffect(() => {
        fetchData();
    },[fetchData])


    const [formPassword, setFormPassword] = useState({
        password : {
            name : 'password',
            type: 'password',
            inputType : 'password',
            showPassword : false,
            label : 'Input Your Password',
            value : '',
            required : true,
            showError : false,
            show: true,
        },
    })

    useEffect(() => {
        if (getcookie){
            if (getcookie.livepreview === ''){
                setView('password')
            }
        }else{
            setView('password')
        }        
    },[getcookie])
    

    const handleSubmitPassword = async() => {
        setIsLoading(false);
        try{
            let obj = {...formPassword}
            let countError = 0;
            let tmp = {}
            Object.entries(obj).map(([index, post]) => {
                if (post.required && post.value === ''){
                    post.showError= true;
                    countError++;
                }else{
                    tmp[index] = post.value
                }
                    return true;
            })
            
            if (countError > 0){
                setAlertValue(alertValue => ({...alertValue,
                    show: true,
                    text : 'Error',
                    subText: 'Lengkapi data',
                    color: 'danger'
                }))
                setIsLoading(false);
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue)
            }

            tmp['kode'] = 2;
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmp));

            const res = await api.post(`${config.endPoint.loginDownload}`, newForm).then(res => res.data);
            if (res){
                let tmpcookie;
                if (!getcookie){
                    tmpcookie = {
                        'livepreview' : tmp.password,                     
                    };
                }else{
                    tmpcookie = {...getcookie};
                    tmpcookie['livepreview'] = tmp.password
                }                
			    setCookie(config.cookiesNamePassword, tmpcookie, { path: '/', maxAge: config.expiresSession });    
                window.location.href = '/live-preview';
            }
        }catch(error){
            setIsLoading(false);
            const { response } = error;
            let msg = 'Unable to save data'
            
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            
            setAlertValue(alertValue => ({...alertValue,
                show: true,
                text : 'Error',
                subText: msg,
                color: 'danger'
            }))
            
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }
    

    const addMessage = useCallback((myMsg) =>  {
        try {
            let message = JSON.parse(myMsg);            
            if (message && message.text && message.text !== "ping") {
                console.log('message >>>', message.text)
                // let tmp = [...listAbsen?.data]
                fetchData()
            }
            
        } catch (e) {
            console.log('error coy >>>>', e);
            return false;
        }
        return true;     
        // eslint-disable-next-line
    }, []);

    // const setReceiveMessage

    useEffect(() => {           
        if (waitingToReconnect) {
            return;
        }

        // Only set up the websocket once
        if (!ws.current) {                
            const client = new WebSocket(config.wsServer[process.env.NODE_ENV] + 'livepreview/');
            ws.current = client;

            window.client = client;

            client.onerror = (e) => console.error(e);

            client.onopen = () => {
                setIsOnline(true);
                console.log('ws opened');
                client.send(JSON.stringify({message : 'ping'}));
            };

            client.onclose = () => {

                if (ws.current) {
                    // Connection failed
                    console.log('ws closed by server');
                } else {
                    // Cleanup initiated from app side, can return here, to not attempt a reconnect
                    console.log('ws closed by app component unmount');
                    return;
                }

                if (waitingToReconnect) {
                    return;
                };

                // Parse event code and log
                setIsOnline(false);
                console.log('ws closed');

                // Setting this will trigger a re-run of the effect,
                // cleaning up the current websocket, but not setting
                // up a new one right away
                setWaitingToReconnect(true);

                // This will trigger another re-run, and because it is false,
                // the socket will be set up again
                setTimeout(() => setWaitingToReconnect(null), 3000);
            };

            client.onmessage = message => {
                addMessage(message.data);
            };


            return () => {

                console.log('Cleanup');
                // Dereference, so it will set up next time
                ws.current = null;

                client.close();
            }
        }
        // eslint-disable-next-line
    }, [waitingToReconnect]);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter'){
            handleSubmitPassword();
        }
    }

    return {
        isLoading, setIsLoading, alertValue, setAlertValue, handleCloseAlert, 
        modalValue, setModalValue, handleCloseModal, view, setView, info, setInfo,
        isOnline, setFormPassword, handleSubmitPassword, formPassword, handleKeyDown
        
    }
}