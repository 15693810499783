import { Fragment } from "react";
import Card from "../../../../Components/Layout/Card";
import Tables from "../../../../Components/molecules/Tables";
import { useDataRegistrasi } from "./dataRegistrasiFunction";
import { Icon } from "@iconify/react/dist/iconify.js";
import Tooltip from "../../../../Components/Atoms/Tooltip";
import PagePagination from "../../../../Components/Atoms/PagePagination";
import Button from "../../../../Components/Atoms/Button";
import Search from "../../../../Components/Atoms/Search";
import FormTextSingleComp from "../../../../Components/forms/FormTextSingleComp";
import Modal from "../../../../Components/Atoms/Modal";
import { useMenu } from "../../../../constants/menuFunction";
import { capitalAllLetters } from "../../../../utils/capitalAllLetters";
import moment from "moment";
import { ThreeCircles } from 'react-loader-spinner'
import AlertComp from "../../../../Components/Atoms/AlertComp";

const DataRegistrasiPage = () => {
  const {
    listData,
    pageValue,
    handleOpenAlasanAkta,
    handleOpenAlasanPernyataan,
    handleOpenAlasanKuasa,
    handlePaginate,
    handleShowAlasanAkta,
    isShowAkta,
    isShowPernyataan,
    handleShowAlasanPernyataan,
    handleShowAlasanKuasa,
    isShowKuasa,
    handleChangeSearch,
    handleSearch,
    handleViewDocument,
    modalValue,
    setModalValue,
    dokumen,
    dataSelected,
    handleApprove,
    handleReject,
    isLoading,
    handleShowDocument,
    handleCloseModal,
    handleDetail,
    handleEdit,
    handleChangeDataSelected,
    handleCloseModalReject,
    handleSubmitReject,
    loadingReject,
    handleHistoryApproval,
    modalState,
    modalDispatch,
    handleShowHistoryEmail,
    handleResendEmail,
    resendEmailForm,
    setResendEmailForm,
    handleChangeResendEmailForm,
    handleSubmitResendEmail,
    handleSynchronize,
    alertValue,
    handleCloseAlert
  } = useDataRegistrasi();

  const { listPermissions, isSuperUser } = useMenu();

  const getColorLocal = (params) => {
    if (params === 1) {
      return "text-green-600 bg-green-100";
    } else if (params === 2) {
      return "text-red-600 bg-red-100";
    } else if (params === 3) {
      return "text-white bg-gray-500";
    } else {
      return "text-white bg-black/40";
    }
  };

  const getIcon = (params) => {
    if (params === 1) {
      return "ri:check-double-fill";
    } else if (params === 2) {
      return "typcn:minus";
    } else if (params === 3) {
      return "svg-spinners:clock";
    } else {
      return "ic:baseline-lock";
    }
  };



  return (
    <Fragment>
      <AlertComp
        isOpen={alertValue.show}
        onClose={handleCloseAlert}
        text={alertValue.text}
        subText={alertValue.subText}
        color={alertValue.color}
        icon={alertValue.icon}
      />
      <Modal
        show={modalState.isOpen}
        setShow={() => {
          modalDispatch({ type: "CLOSE" });
        }}
        width="xl"
        persistent={false}
        btnClose={true}
        title={modalState.title}
        withHeader={true}
      >
        {modalState.content}
      </Modal>
      <Modal
        show={modalValue.show}
        setShow={() => {
          !isLoading && setModalValue((modalValue) => ({ ...modalValue, show: false }));
        }}
        width="xl"
        persistent={false}
        btnClose={false}
        title={modalValue.title}
        withHeader={true}
        isLoading={loadingReject}
      >
        <div className="p-[20px]">
          {modalValue.tipe === "reject" && (
            <FormTextSingleComp
              name={"alasan"}
              value={dataSelected.alasan}
              type="textarea"
              label="Alasan"
              onChange={handleChangeDataSelected}
              show={true}
              required={true}
              inputType={"textarea"}
              textareaRows={3}
            />
          )}
          {modalValue.tipe === "resend_email" && (
            <div>
              <div>
                <FormTextSingleComp
                  {...resendEmailForm.to}
                  formData={resendEmailForm}
                  setFormData={setResendEmailForm}
                />
              </div>
              <div className="mt-5">
                <FormTextSingleComp
                  {...resendEmailForm.perusahaan}
                  formData={resendEmailForm}
                  setFormData={setResendEmailForm}
                />
              </div>
              <div className="mt-5">
                <FormTextSingleComp
                  {...resendEmailForm.email_template}
                  formData={resendEmailForm}
                  setFormData={setResendEmailForm}
                  onChange={handleChangeResendEmailForm}
                />
              </div>
              <div className="mt-5">
                <FormTextSingleComp
                  {...resendEmailForm.subject}
                  formData={resendEmailForm}
                  setFormData={setResendEmailForm}
                />
              </div>
              <div className="mt-5">
                <FormTextSingleComp
                  {...resendEmailForm.body_email}
                  formData={resendEmailForm}
                  setFormData={setResendEmailForm}
                />
              </div>
              <div className="flex flex-row justify-end gap-2 mt-5">
                <Button
                  disabled={isLoading}
                  loading={isLoading}
                  color="primary"
                  onClick={handleSubmitResendEmail}
                >
                  Kirim Email
                </Button>
                <Button
                  disabled={isLoading}
                  loading={isLoading}
                  color="base"
                  onClick={handleCloseModal}
                >
                  Cancel
                </Button>
              </div>
            </div>
          )}
          {modalValue.tipe === "dokumen" && (
            <div className="flex flex-col items-center">
              {dokumen.tipe === "dokumen" && (
                <iframe
                  src={`${dokumen.url}`}
                  // src={`https://docs.google.com/gview?url=${dokumen.url}&embedded=true`}
                  width="100%"
                  height="500px"
                  title="akta"
                ></iframe>
              )}
              {dokumen.tipe === "image" && (
                <>
                  <img src={dokumen.url} alt="img" />
                </>
              )}
              <div className="flex flex-col w-full justify-start items-start mt-3">
                <div className="text-sm">Status</div>
                <div
                  className={`${getColorLocal(
                    dataSelected.status
                  )} text-sm px-[20px] py-[3px] rounded`}
                >
                  {dataSelected.status_display}
                </div>
                {dokumen.alasan && (
                  <Fragment>
                    <div className="text-sm mt-3">Alasan</div>
                    <div className="content-ckeditor" dangerouslySetInnerHTML={{ __html: dokumen?.alasan }}></div>
                  </Fragment>
                )}
              </div>
            </div>
          )}
          {["dokumen"].includes(modalValue.tipe) && (
            <div className="flex flex-row justify-end border-t border-t-gray-200 pt-[10px] mt-[20px] items-start">
              <div className="flex flex-row gap-2">
                {(listPermissions.includes("change_kontak") || isSuperUser) && (
                  <>
                    {dataSelected &&
                      [1, "1", 3, "3"].includes(dataSelected.status) && (
                        <Button
                          color="danger"
                          onClick={handleReject}
                          size="sm"
                          className="disabled:opacity-65"
                          disabled={isLoading}
                        >
                          Reject
                        </Button>
                      )}

                    {dataSelected &&
                      [3, "3", 2, "2"].includes(dataSelected.status) && (
                        <Button
                          color="success"
                          size="sm"
                          onClick={handleApprove}
                          className="disabled:opacity-65"
                          disabled={isLoading}
                        >
                          Approve
                        </Button>
                      )}
                  </>
                )}
                {dataSelected.tipe_display !== "Foto" && (
                  <Button
                    color="warning"
                    onClick={() =>
                      handleShowDocument(dataSelected.tipe, dataSelected.data)
                    }
                  >
                    Open in new tab
                  </Button>
                )}
                <Button
                  color="base"
                  onClick={() =>
                    handleCloseModal(dataSelected.tipe, dataSelected.data)
                  }
                  disabled={isLoading}
                  className="disabled:opacity-65"
                >
                  Close
                </Button>
              </div>
            </div>
          )}
          {["reject"].includes(modalValue.tipe) && (
            <div className="flex flex-row justify-end border-t border-t-gray-200 pt-[10px] mt-[20px] items-start">
              <div className="flex flex-row justify-end border-t-gray-200 pt-[10px] mt-[20px] items-start gap-2">
                <Button color="base" onClick={handleCloseModalReject}>
                  Close
                </Button>
                <Button
                  color="danger"
                  onClick={handleSubmitReject}
                  disabled={loadingReject}
                >
                  Submit
                </Button>
              </div>
            </div>
          )}
        </div>
      </Modal>
      <div className="flex flex-row gap-1 mb-5">
        <Button
          className={`${isShowAkta ? "bg-primaryweb" : "bg-disabled"
            } text-white`}
          onClick={handleShowAlasanAkta}
        >
          Lihat Alasan Akta
        </Button>
        <Button
          className={`${isShowPernyataan ? "bg-primaryweb" : "bg-disabled"
            } text-white`}
          onClick={handleShowAlasanPernyataan}
        >
          Lihat Alasan Surat Pernyataan
        </Button>
        <Button
          className={`${isShowKuasa ? "bg-primaryweb" : "bg-disabled"
            } text-white`}
          onClick={handleShowAlasanKuasa}
        >
          Lihat Alasan Surat Kuasa
        </Button>
      </div>
      <Card
        isSearch={false}
        isCreate={false}
        title="Data Peserta"
        bodyStyle={{ marginTop: 0 }}
      >
        <div className="flex flex-row justify-between">
          <div className="w-full md:w-60 mb-3">
            <Search
              onChange={handleChangeSearch}
              handleSearch={handleSearch}
              value={pageValue.search}
              placeholder="Search"
            />
          </div>
          <div className="flex flex-row gap-2">
            <div className="relative">
              <FormTextSingleComp
                name="complete"
                value={sessionStorage.getItem('stateComplete') ? sessionStorage.getItem('stateComplete') : pageValue.complete}
                obj={pageValue.complete_obj}
                onChange={handleChangeSearch}
                show={true}
                required={false}
                type={"select"}
                hideLabel={true}
              />
              <div className="absolute -top-0 left-3 text-[11px] bg-white px-1 text-primaryweb">
                Filter Complete
              </div>
            </div>
            <div className="relative">
              <FormTextSingleComp
                name="is_lock"
                value={sessionStorage.getItem('stateLock') ? sessionStorage.getItem('stateLock') : pageValue.is_lock}
                obj={pageValue.is_lock_obj}
                onChange={handleChangeSearch}
                show={true}
                required={false}
                type={"select"}
                hideLabel={true}
              />
              <div className="absolute -top-0 left-3 text-[11px] bg-white px-1 text-primaryweb">
                Filter Lock
              </div>
            </div>
          </div>
        </div>
        <Tables className="border-separate rounded-sm">
          <Tables.Head className="bg-[#efeeee]">
            <tr>
              <Tables.Header className="font-bold text-[13px] py-[10px] text-right">
                #
              </Tables.Header>
              <Tables.Header className="font-bold text-[13px] py-[10px]">
                Perusahaan
              </Tables.Header>
              <Tables.Header className="font-bold text-[13px] py-[10px]">
                Akta
              </Tables.Header>
              <Tables.Header className="font-bold text-[13px] py-[10px]">
                Peserta
              </Tables.Header>
              <Tables.Header className="font-bold text-[13px] py-[10px]">
                Jabatan
              </Tables.Header>
              <Tables.Header className="font-bold text-[13px] py-[10px]">
                Surat Pernyataan / Kuasa
              </Tables.Header>
              <Tables.Header className="font-bold text-[13px] py-[10px]">
                Foto
              </Tables.Header>
              <Tables.Header className="font-bold text-[13px] py-[10px]">
                Last Update
              </Tables.Header>
              <Tables.Header className="font-bold text-[13px] py-[10px]">
                Complete
              </Tables.Header>
              <Tables.Header className="font-bold text-[13px] py-[10px]">
                Action
              </Tables.Header>
            </tr>
          </Tables.Head>
          <Tables.Body>
            {listData.isLoading &&
              Array(16)
                .fill(0)
                .map((_, i) => {
                  return (
                    <Tables.Row className="animate-pulse" key={i}>
                      <Tables.Data
                        className="text-right"
                        style={{ textAlign: "right" }}
                      >
                        <div className="h-4 w-[10px] bg-slate-200 rounded"></div>
                      </Tables.Data>
                      <Tables.Data>
                        <div className="h-4 bg-slate-200 rounded"></div>
                      </Tables.Data>
                      <Tables.Data>
                        <div className="h-4 bg-slate-200 rounded"></div>
                      </Tables.Data>
                      <Tables.Data>
                        <div className="h-4 bg-slate-200 rounded"></div>
                      </Tables.Data>
                      <Tables.Data>
                        <div className="h-4 bg-slate-200 rounded"></div>
                      </Tables.Data>
                      <Tables.Data>
                        <div className="h-4 bg-slate-200 rounded"></div>
                      </Tables.Data>
                      <Tables.Data>
                        <div className="h-4 bg-slate-200 rounded"></div>
                      </Tables.Data>
                      <Tables.Data>
                        <div className="h-4 bg-slate-200 rounded"></div>
                      </Tables.Data>
                      <Tables.Data>
                        <div className="h-4 bg-slate-200 rounded"></div>
                      </Tables.Data>
                      <Tables.Data>
                        <div className="h-4 bg-slate-200 rounded"></div>
                      </Tables.Data>
                      <Tables.Data>
                        <div className="h-4 bg-slate-200 rounded"></div>
                      </Tables.Data>
                    </Tables.Row>
                  );
                })}
            {!listData.isLoading &&
              listData?.data?.map((post, index) => {
                return (
                  <Tables.Row
                    key={index}
                    className={`${post.is_lock && "row-lock"}`}
                  >
                    <Tables.Data className="text-right align-top">
                      {pageValue.offset + index + 1}
                    </Tables.Data>
                    <Tables.Data className="align-top">
                      <div className="flex flex-col items-start">
                        <div>{post.perusahaan_display}</div>
                        {post.hotel_display && (
                          <div className="italic text-blue-600 bg-blue-100 px-2 text-left rounded">
                            {post.hotel_display}
                          </div>
                        )}
                      </div>
                    </Tables.Data>
                    <Tables.Data className="w-[15%] align-top">
                      <div className="flex flex-col items-start">
                        <div className="flex flex-row justify-center gap-1">
                          {post.akta_url && (
                            <Tooltip
                              tooltip={post.status_akta_display}
                              delay={500}
                            >
                              <button
                                className={`${getColorLocal(
                                  post.status_akta
                                )} text-[12px] px-2 py-1 flex flex-row gap-2 justify-center rounded shadow-lg items-center`}
                                onClick={() => handleViewDocument("akta", post)}
                              >
                                <Icon icon={getIcon(post.status_akta)} />
                                Lihat Akta
                              </button>
                            </Tooltip>
                          )}
                          {[2, 3].includes(post.status_akta) && (
                            <Tooltip tooltip={"Lihat alasan akta"} delay={500}>
                              <button
                                className="bg-blue-500 text-white px-2 py-[6px] rounded shadow-lg"
                                onClick={() => handleOpenAlasanAkta(index)}
                              >
                                <Icon
                                  icon={
                                    post.show_alasan_akta
                                      ? "ion:arrow-up"
                                      : "ion:arrow-down"
                                  }
                                />
                              </button>
                            </Tooltip>
                          )}
                        </div>
                        {post.show_alasan_akta &&
                          [2, 3].includes(post.status_akta) && (
                            <div className="content-ckeditor" dangerouslySetInnerHTML={{ __html: post?.detail_display?.alasan_akta }}></div>
                          )}
                      </div>
                    </Tables.Data>
                    <Tables.Data className="align-top">
                      <div className="flex flex-col items-start">
                        <div>{capitalAllLetters(post.nama)}</div>
                        {post.duplicate > 1 && (
                          <div
                            className="px-3 py-1 italic mt-1
                                                        text-[10px]  text-red-500 bg-red-100 
                                                        rounded-full
                                                        dark:border-gray-900"
                          >
                            Duplicate {post.duplicate}
                          </div>
                        )}
                      </div>
                    </Tables.Data>
                    <Tables.Data className="align-top">
                      {post.jabatan_display}
                    </Tables.Data>
                    <Tables.Data className="w-[20%] align-top">
                      {post.jabatan === 1 && (
                        <div className="flex flex-col items-start">
                          <div className="flex flex-row justify-start gap-1">
                            <Tooltip
                              tooltip={
                                post.detail_display
                                  ?.status_surat_pernyataan_display
                              }
                            >
                              <button
                                className={`${getColorLocal(
                                  post.detail_display?.status_surat_pernyataan
                                )} text-[12px] px-2 py-1 flex flex-row gap-2 justify-center rounded items-center shadow-lg`}
                                onClick={() =>
                                  handleViewDocument("surat_pernyataan", post)
                                }
                              >
                                <Icon
                                  icon={getIcon(
                                    post.detail_display?.status_surat_pernyataan
                                  )}
                                />
                                Lihat Surat Pernyataan
                              </button>
                            </Tooltip>
                            {[2, 3].includes(
                              post.detail_display.status_surat_pernyataan
                            ) && (
                                <Tooltip
                                  tooltip={"Lihat alasan surat pernyataan"}
                                  delay={500}
                                >
                                  <button
                                    className="bg-blue-500 text-white px-2 py-[6px] rounded shadow-lg"
                                    onClick={() =>
                                      handleOpenAlasanPernyataan(index)
                                    }
                                  >
                                    <Icon
                                      icon={
                                        post.show_alasan_pernyataan
                                          ? "ion:arrow-up"
                                          : "ion:arrow-down"
                                      }
                                    />
                                  </button>
                                </Tooltip>
                              )}
                          </div>
                          {post.show_alasan_pernyataan &&
                            [2, 3].includes(
                              post.detail_display.status_surat_pernyataan
                            ) && (
                              <div className="content-ckeditor" dangerouslySetInnerHTML={{ __html: post?.detail_display?.alasan_surat_pernyataan }}></div>
                            )}
                        </div>
                      )}
                      {post.jabatan === 2 && (
                        <div className="flex flex-col items-start">
                          <div className="flex flex-row justify-start gap-1">
                            <Tooltip
                              tooltip={
                                post.detail_display?.status_surat_kuasa_display
                              }
                            >
                              <button
                                className={`${getColorLocal(
                                  post.detail_display?.status_surat_kuasa
                                )} text-[12px] px-2 py-1 flex flex-row gap-2 justify-center rounded items-center shadow-lg`}
                                onClick={() =>
                                  handleViewDocument("surat_kuasa", post)
                                }
                              >
                                <Icon
                                  icon={getIcon(
                                    post.detail_display?.status_surat_kuasa
                                  )}
                                />
                                Lihat Surat kuasa
                              </button>
                            </Tooltip>
                            {[2, 3].includes(
                              post.detail_display.status_surat_kuasa
                            ) && (
                                <Tooltip
                                  tooltip={"Lihat alasan surat kuasa"}
                                  delay={500}
                                >
                                  <button
                                    className="bg-blue-500 text-black/80 px-2 py-[6px] rounded shadow-lg"
                                    onClick={() => handleOpenAlasanKuasa(index)}
                                  >
                                    <Icon
                                      icon={
                                        post.show_alasan_kuasa
                                          ? "ion:arrow-up"
                                          : "ion:arrow-down"
                                      }
                                    />
                                  </button>
                                </Tooltip>
                              )}
                          </div>
                          {post.show_alasan_kuasa &&
                            [2, 3].includes(
                              post.detail_display.status_surat_kuasa
                            ) && (
                              <div className="content-ckeditor" dangerouslySetInnerHTML={{ __html: post?.detail_display?.alasan_surat_kuasa }}></div>
                            )}
                        </div>
                      )}
                    </Tables.Data>

                    <Tables.Data className="align-top">
                      <div
                        className={`px-1 bg-gray-100 w-full flex flex-col items-center`}
                      >
                        <div className="">
                          <img
                            src={post.foto_url}
                            alt="foto"
                            className="h-14 w-auto object-contain"
                          />
                        </div>
                        <button
                          className={`flex flex-row w-fi ${getColorLocal(
                            post.detail_display?.status_foto
                          )} px-2 py-1 rounded`}
                          onClick={() => handleViewDocument("foto", post)}
                        >
                          <Icon
                            icon={getIcon(post.detail_display?.status_foto)}
                          />
                          <div className="text-[12px] w-fit">Ubah Satus</div>
                        </button>
                      </div>
                    </Tables.Data>

                    <Tables.Data className="align-top">
                      <div className="flex flex-row justify-center items-center w-full h-full">
                        <div
                          className="py-0.5 px-2 text-[11px] rounded-full"
                        >
                          {moment(post?.updated_at).format('DD-MM-YYYY, HH:mm.[WIB]')}
                        </div>
                      </div>
                    </Tables.Data>

                    <Tables.Data className="align-top">
                      <div className="flex flex-row justify-center items-center w-full h-full">
                        <div
                          className={`${post.is_complete
                            ? "bg-green-100 text-green-600"
                            : "bg-red-100 text-red-500"
                            } py-0.5 px-2 text-[11px] rounded-full`}
                        >
                          {post.is_complete ? "Complete" : "Incomplete"}
                        </div>
                      </div>
                    </Tables.Data>

                    <Tables.Data className="align-top">
                      <div className="flex flex-col items-center gap-2">
                        <div className="flex flex-row gap-1">
                          <Tooltip tooltip={"Synchronize MYAPJII"} delay={500}>
                            <button
                              className="p-[8px] bg-lime-200 text-lime-700 rounded shadow-lg text-[18px]"
                              onClick={() => handleSynchronize(post)}
                              disabled={isLoading}
                            >
                              {isLoading ?
                                <ThreeCircles
                                  visible={true}
                                  height="16"
                                  width="16"
                                  color="#4fa94d"
                                  ariaLabel="three-circles-loading"
                                  wrapperStyle={{}}
                                  wrapperClass=""
                                />
                                :
                                <Icon icon="ic:round-sync" />
                              }
                            </button>
                          </Tooltip>
                          {(listPermissions.includes("view_kontak") ||
                            isSuperUser) && (
                              <Tooltip tooltip={"Detail"} delay={500}>
                                <button
                                  className="p-[8px] bg-purple-100 text-purple-600 rounded shadow-lg text-[18px]"
                                  onClick={() => handleDetail(post)}
                                >
                                  <Icon icon="solar:eye-outline" />
                                </button>
                              </Tooltip>
                            )}
                          {(listPermissions.includes("change_kontak") ||
                            isSuperUser) && (
                              <>
                                <Tooltip tooltip={"Edit"} delay={500}>
                                  <button
                                    className="p-[8px] bg-orange-100 text-orange-500 rounded shadow-lg text-[18px]"
                                    onClick={() => handleEdit(post)}
                                  >
                                    <Icon icon="bxs:edit" />
                                  </button>
                                </Tooltip>
                                <Tooltip tooltip={"History Approval"} delay={500}>
                                  <button
                                    className="text-[18px] p-[8px] bg-green-100 text-green-600 shadow-lg"
                                    onClick={() => handleHistoryApproval(post)}
                                  >
                                    <Icon icon="ph:gavel" />
                                  </button>
                                </Tooltip>
                              </>
                            )}

                          {(listPermissions.includes("view_kontak") ||
                            isSuperUser) && (
                              <>
                                <Tooltip tooltip={"History Email"} delay={500}>
                                  <button
                                    className="text-[18px] p-[8px] bg-gray-200 text-gray-600 shadow-lg"
                                    onClick={() => handleShowHistoryEmail(post)}
                                  >
                                    <Icon icon="ri:mail-check-fill" />
                                  </button>
                                </Tooltip>
                                <Tooltip tooltip={"Resend Email"} delay={500}>
                                  <button
                                    className="text-[18px] p-[8px] bg-sky-100 text-sky-600 shadow-lg rounded-r"
                                    onClick={() => handleResendEmail(post)}
                                  >
                                    <Icon icon="streamline:mail-send-email-message-solid" />
                                  </button>
                                </Tooltip>
                              </>
                            )}
                        </div>
                      </div>
                    </Tables.Data>
                  </Tables.Row>
                );
              })}
          </Tables.Body>
        </Tables>
        <div className="mt-5 flex flex-row justify-start w-full">
          <PagePagination
            data={pageValue.obj}
            handlePagination={handlePaginate}
            page={pageValue.page}
            lastPage={pageValue.lastPage}
          />
        </div>
      </Card>
    </Fragment>
  );
};
export default DataRegistrasiPage;
