import { useCallback, useContext, useEffect, useState } from "react";
import { useGlobalFunction } from "../../../../generalFunction";
import config from "../../../../config";
import { pageObj } from "../../../../pageObj";
import api from "../../../../api";
import { ThemeContext } from "../../../../context/ThemeContext";


export function useKategoriSponsorShip(){
    const { isLoading, showLoading, closeLoading, setIsLoading,
        modalValue, setModalValue, handleCloseModal,
        pageValue, setPageValue, catchFetching, alertValue, setAlertValue, handleCloseAlert
    } = useGlobalFunction();
    const { setTitle } = useContext(ThemeContext)
    const [listData, setListData] = useState([]);
    const [view, setView] = useState('list');
    const [isEdit, setIsEdit] = useState(false);
    const [formData, setFormData] = useState({
        id : {
            name : 'id',
            label : 'ID',
            value : '',
            type : 'text',
            col : 2,
            required : false,
            show : false,
            errorMsg : '',
            showError : false,
            flexType : 'row'
        },
        nama : {
            name : 'nama',
            label : 'Nama Kategori',
            value : 1,
            type : 'text',
            col : 2,            
            required : true,
            show : true,
            errorMsg : '',
            showError : false,
            flexType : 'row',
            className:"text center"
        },               
        prioritas : {
            name : 'prioritas',
            label : 'Prioritas',
            value : 1,
            type : 'number',
            inputType : 'number',
            col : 2,
            required : true,
            show : true,
            errorMsg : '',
            showError : false,
            flexType : 'row',
            className : 'text-right'
        },
    })

    const fetchData = useCallback(async(q='', offset=0, limit=config.itemPerPage) => {
        setIsLoading(true);
        showLoading();
        try{
            let params = {
                offset : offset,
                limit : limit
            }
            if (q){
                params['q'] = q;
            }
            const res = await api.get(`${config.endPoint.kategoriSponsor}`, {params : params}).then(res => res.data);
            if (res){
                setListData(res.results);
                setPageValue(pageValue => ({...pageValue,
                    obj : pageObj(res.count, limit, offset),
                    lastPage : Math.ceil(parseInt(res.count) / parseInt(limit))
                }))
            }
            closeLoading();
            setIsLoading(false);
        }catch(error){
            closeLoading();
            setIsLoading(false);
            catchFetching(error, 'admin');
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to fetch data', color : 'danger'}))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
        // eslint-disable-next-line
    },[setIsLoading, setAlertValue, setPageValue])


    useEffect(() => {
        setTitle('Kategori Sponsor')
    }, [setTitle])


    useEffect(() => {
        fetchData();
    },[fetchData])

    const handleSubmit = async() => {
        setIsLoading(true);
        showLoading();
        try{
            let countError = 0;
            let form = {...formData};
            let tmpForm = {}
            Object.entries(form).map(([index, post]) => {
                if (index !== 'redaksional'){
                    if (post.required && (post.value === '' || post.value === null)){
                        post.showError = true;
                        countError++;
                    }else{
                        tmpForm[index] = post.value;
                    }
                }
                return true;
            });
            
            if (countError > 0){
                setFormData(form);
                setIsLoading(false);
                closeLoading();
                setAlertValue(alertValue => ({...alertValue, show: true, text : 'Lengkapi Data', color: 'danger'}));
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue);
                return;
            }
            setIsLoading(false);

            // console.log('timp is >>>', tmpForm);
            // return
            
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmpForm));
            
           
            let res;
            if (isEdit){
                res = await api.put(`${config.endPoint.kategoriSponsor}${formData.id.value}/`, newForm).then(res => res.data);
            }else{
                res = await api.post(`${config.endPoint.kategoriSponsor}`, newForm).then(res => res.data);
            }
            if (res){
                handleCancel();                
            }
            setIsLoading(false);    
            closeLoading();        
        }catch(error){
            setIsLoading(false);
            closeLoading();
            catchFetching(error, 'admin')

            let msg = "Unable to save data";
            if (error.response && error.response.data && error.response.data.message){
                msg = error.response.data.message
            }
            setAlertValue(alertValue => ({...alertValue, show: true, text : msg, color : 'danger'}))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))                
            }, config.timeOutValue);
        }
    }

    const handleDelete = (post) => {
        setModalValue(modalValue => ({...modalValue,
            show: true,
            text : `Apakah anda yakin akan menghapus Kategori Sponsorship ${post.nama} ?`,
            id : post.id,
            tipe : 'delete'
        }))
    }

    const handleSubmitDelete = async() => {
        setIsLoading(true)
        try{
            const res = await api.delete(`${config.endPoint.kategoriSponsor}${modalValue.id}/`).then(res => res.data);
            if (res){
                fetchData(pageValue.search, pageValue.offset, config.itemPerPage);
                setAlertValue(alertValue => ({...alertValue, show: true, text : 'Data dihapus', color : 'success'}))
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue)
            }
            handleCloseModal();
        }catch(error){
            setIsLoading(false);
            catchFetching(error, 'admin')
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to delete data', color : 'danger'}))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }

    const handleCreate = () => {
        setView('create');
        setIsEdit(false);
        setFormData(formData => ({...formData,
            id : {...formData.id, value : ''},
            nama : {...formData.nama, value : ''},
            prioritas : {...formData.prioritas, value : ''},            
        }))
    }

    const handleEdit = (post) => {
        setView('create');
        setIsEdit(true);
        setFormData(formData => ({...formData,
            id : {...formData.id, value : post.id},
            nama : {...formData.nama, value : post.nama},            
            prioritas : {...formData.prioritas, value : post.prioritas || 1},            
        }))
    }



    const handleCancel = () => {
        setView('list');
        fetchData(pageValue.search, pageValue.offset, config.itemPerPage)
    }

    const handleChangeSearch = (e) => {
        const { name, value } = e.target;
        setPageValue(pageValue => ({...pageValue, [name] : value}))
    }

    const handleSearch = () => {
        fetchData(pageValue.search, 0, config.itemPerPage);
        setPageValue(pageValue => ({...pageValue, offset : 0, page : 1}))
    }

    const handleKeyDownSearch = (e) => {
        if (e.key === "Enter"){
            handleSearch();
        }
    }

    const handlePagination = (page) => {
        let myOffset = (parseInt(page) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
        setPageValue(pageValue => ({...pageValue, page : page, offset: myOffset}))
        fetchData(pageValue.search, myOffset, config.itemPerPage);
    }

    return {
        isLoading, showLoading, closeLoading, setIsLoading, listData,
        formData, setFormData,  setListData, handleSubmit,
        handleDelete, modalValue, setModalValue, handleCloseModal, handleSubmitDelete,
        pageValue, setPageValue, view,
        handleCreate, handleCancel, alertValue, handleCloseAlert, handleEdit,
        handleChangeSearch, handleSearch, handleKeyDownSearch, handlePagination

    }
}