import { Fragment } from "react";
import PropTypes from 'prop-types';

import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";

const PerusahaanAtom = (props) => {
    const { nilai,  description, sub_description, bgColor, textColor, borderColor, icon, isLoading, link, disableLink } = props;
    const navigate = useNavigate();
    return(
        <Fragment>
            { isLoading ?
                <div className="card-body  text-sm">
                    <div className={`bg-white flex px-4 py-7 justify-between items-center rounded-t border border-gray-100 w-full`}>
                        <div className='h-[35px] w-[42px] bg-gray-100 rounded-xl'></div>
                        <div className='text-right'>
                            <div className={`text-white text-[40px] w-[68px] h-[35px] bg-gray-100  rounded-md`}></div>
                            <div className={`text-white text-[40px] w-[106px] h-[13px] bg-gray-100  rounded-md mt-[20px]`}></div>
                            <div className={`text-white text-[40px] w-[80px] h-[13px] bg-gray-100  rounded-md mt-[9px]`}></div>
                            {/* <div className={`text-white text-[14px] font-thin`}>{sub_description}</div> */}
                        </div>
                    </div>
                    <div className={`px-4 py-2 border-gray-100 border-l border-r border-b rounded-b bg-gray-50`}>
                        <div className={`flex justify-between text-gray-100`}>
                            <div className='w-[40px] h-[13px] bg-gray-100  rounded-md'></div>
                            <div><Icon icon="typcn:chevron-right"/></div>
                        </div>
                    </div>
                </div>            
                :
                <div className="card-body  text-sm">
                    <div className={`${bgColor} flex px-4 py-6 justify-between items-center rounded-t ${disableLink && 'rounded-b'}`}>
                        <div className='text-[30px]'><Icon icon={icon} color="white"/></div>
                        <div className='text-right'>
                            <div className={`text-white text-[40px]`}>{nilai}</div>
                            <div className={`text-white text-[14px] font-thin mt-5`}>{description}</div>
                            <div className={`text-white text-[14px] font-thin`}>{sub_description}</div>
                        </div>
                    </div>
                    { !disableLink &&
                        <div onClick={() => link ? navigate(link) : null} className={`px-4 py-2 ${borderColor} border-l border-r border-b rounded-b bg-gray-50 ${link && 'cursor-pointer'}`}>
                            <div className={`flex justify-between ${textColor}`}>
                                <div>View Detail</div>
                                <div><Icon icon="typcn:chevron-right"/></div>
                            </div>
                        </div>
                    }
                </div>            
            }            
        </Fragment>
    )
}

PerusahaanAtom.propTypes = {
    nilai : PropTypes.number.isRequired,    
    description : PropTypes.string.isRequired,
    sub_description : PropTypes.string.isRequired,
    bgColor : PropTypes.string.isRequired,
    textColor : PropTypes.string.isRequired,
    borderColor : PropTypes.string.isRequired,
    icon : PropTypes.any.isRequired,
    isLoading : PropTypes.bool,
    link : PropTypes.string,
    disableLink : PropTypes.string,
}

PerusahaanAtom.defaultProps = {
    link : '',
    disableLink : false,
}

export default PerusahaanAtom;