import { useCallback, useEffect, useState } from "react";
import api from "../../../api";
import config from "../../../config";
import { useGlobalFunction } from "../../../generalFunction";
import axios from "axios";

export function useAgenda(){
    const { modalValue, setModalValue, handleCloseModal } = useGlobalFunction();
    const [listData, setListData] = useState({
        data : [],
        isLoading : false
    })
    const [tabSelected, setTabSelected] = useState(null)
    const [tabs, setTabs] = useState({data : [], isLoading : false});
    const color = ['#62B5FF', '#2194FA', '#3584CC']

    const fetchTabs = useCallback(async() => {
        setTabs(tabs => ({...tabs, isLoading: true}));
        try{
            const res = await axios.get(`${config.apiBaseUrl[process.env.NODE_ENV]}${config.endPoint.agendaAcaraGroup}`).then(res => res.data);
            if (res){
                let tmp = [];
                res.results.map((post, index) => {
                    let myIndex = index + 1;
                    let myNum = index + 1;
                    myNum = (Math.ceil(myNum/color.length))                    
                    myNum = myNum-1
                    let hasil = myIndex - (color.length*myNum)
                    hasil = hasil -1 
                                        
                    
                    tmp.push({
                        hari : `Hari ke - ${post.harike}`,
                        tanggal : post.tanggal,
                        tanggal_display : post.tanggal_display,
                        id : post.harike,
                        color : `${color[hasil]}`,
                        classActive : `text-[${color[hasil]}] border-l-[${color[hasil]}]`,
                        classNotActice : `bg-[${color[hasil]}] text-white`
                    })
                    return true;
                })
                if (tmp.length > 0){
                    setTabSelected(tmp[0].tanggal)
                }
                setTabs(tabs => ({...tabs, data: tmp, isLoading: false}));
            }else{
                setTabs(tabs => ({...tabs, isLoading: false}));
            }
        }catch(error){
            setTabs(tabs => ({...tabs, isLoading: false}));
        }
        // eslint-disable-next-line 
    },[])    

    useEffect(() => {
        fetchTabs();
    },[fetchTabs])

    const fetchData = useCallback(async(tanggal='') => {
        setListData(listData => ({...listData, isLoading : true}))
        try{
            let params = {
                'tanggal' : tanggal
            }
            const res = await api.get(`${config.endPoint.agendaAcaraWeb}`, {params: params}).then(res => res.data);
            if (res){
                setListData(listData => ({...listData, isLoading: true, data : res.results}))
            }else{            
                setListData(listData => ({...listData, isLoading : false}))
            }
        }catch(error){
            setListData(listData => ({...listData, isLoading : false}))

        }
    },[])

    const handleChangeTab = (params) => {        
        setTabSelected(params.tanggal)
    }

    useEffect(() => {
        if (tabSelected){
            fetchData(tabSelected);
        }
    },[tabSelected, fetchData])

    const handleDetail = (post) => {        
        setModalValue(modalValue => ({...modalValue,
            content : post,
            title : 'Detail Acara',
            show: true,            
        }))
        console.log('post detail >>>>', post)
    }

    return {
        tabSelected, setTabSelected, handleChangeTab, tabs, listData, handleDetail, modalValue, setModalValue, handleCloseModal
    }
}