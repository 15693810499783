import { useCallback, useEffect, useState } from "react";
import { useGlobalFunction } from "../../../generalFunction";
import config from "../../../config";
import axios from "axios";

export function useDownloadPage(){
    const { isLoading, setIsLoading } = useGlobalFunction();
    const [listData, setListData] = useState([]);

    const fetchData = useCallback(async(q='', offset=0, limit=config.itemPerPage) => {
        setIsLoading(true);
        try{
            let params = {
                offset : offset,
                limit : limit,
                requestor : 'web'
            }
            const res = await axios.get(`${config.apiBaseUrl[process.env.NODE_ENV]}${config.endPoint.fileUploadWeb}`, {params: params}).then(res => res.data);
            
            if (res){
                setListData(res.results);
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
        }
    },[setIsLoading])



    useEffect(() => {
        fetchData();
    },[fetchData])

    const handleDownload = (post) => {
        fetch(post.path, {
            method: 'GET',
            headers: {
            'Content-Type': 'application/pdf',
            },
        })
        .then((response) => response.blob())
        .then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(
            new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            // link.target = '_blank';
            link.setAttribute(
                'download',
                `${post.nama}.${post?.meta?.format}`,
            );

            // Append to html link element page
            // document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            // link.parentNode.removeChild(link);
        });
    }


    return {
        isLoading, setIsLoading, listData, handleDownload
    }

    
}