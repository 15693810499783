import { Fragment } from "react"
import PagePagination from "../../../../Components/Atoms/PagePagination"
import Card from "../../../../Components/Layout/Card"
import Tables from "../../../../Components/molecules/Tables"
import { usePerusahaanMendaftar } from "./perusahaanMendaftarFunction"


const PerusahaanMendaftarPage = () => {
    const { pageValue, handleChangeSearch,  handleSearch,
        listData, handlePaginate
    } = usePerusahaanMendaftar()

    const getColor = (params) => {
        if (params === 3){
            return 'bg-blue-100 text-blue-500'
        }else if(params === 1){
            return 'bg-green-100 text-green-500'
        }else if(params === 2){
            return 'bg-red-100 text-red-500'
        }else{
            return 'bg-gray-500 text-white'
        }
    }
    return(
        <div>
            <Card
                title='Master Kandidat Ketua Umum'
                isCreate={false}
                isSearch={true}
                searchValue={pageValue.search}                
                onChangeSearch={handleChangeSearch}
                handleSearch={handleSearch}
            >
                
                
                <Tables className='border-separate rounded-sm'>
                    <Tables.Head className='bg-[#efeeee]'>
                        <tr>
                            <Tables.Header className='font-bold text-[13px] py-[10px] text-right'>#</Tables.Header>
                            <Tables.Header className='font-bold text-[13px] py-[10px]'>Nama Perusahaan</Tables.Header>
                            <Tables.Header className='font-bold text-[13px] py-[10px]'>Email</Tables.Header>
                            <Tables.Header className='font-bold text-[13px] py-[10px]'>Status Pendaftaran</Tables.Header>
                        </tr>
                    </Tables.Head>
                    <Tables.Body>
                        { listData?.data?.length === 0 ?
                            <Tables.Row>
                                <Tables.Data colspan={4} className='text-center'>Belum ada data</Tables.Data>
                            </Tables.Row>
                            :
                            <Fragment>
                                {listData?.data?.map((post, index) => {
                                    
                                    return (
                                        <Tables.Row key={index}>
                                            <Tables.Data className='text-right align-top'>{pageValue.offset + index + 1}</Tables.Data>
                                            <Tables.Data className='align-top'>{post.nama}</Tables.Data>
                                            <Tables.Data className='align-top'>{post.email}</Tables.Data>
                                            <Tables.Data className='align-top'>
                                                <div className='flex'>
                                                    <div className={`${getColor(post.status_pendaftaran?.status_code)} px-5 text-xs py-1 rounded-full`}>
                                                        {post.status_pendaftaran?.status_display}
                                                    </div>
                                                </div>
                                            </Tables.Data>
                                        </Tables.Row>
                                    )
                                })}
                            </Fragment>
                        }
                    </Tables.Body>
                </Tables>
                <div className='mt-5'>
                    <PagePagination
                        data={pageValue.obj}
                        handlePagination={handlePaginate}
                        page={pageValue.page}
                        lastPage={pageValue.lastPage}
                    />
                </div>                
                
            </Card>
        </div>
    )
}
export default PerusahaanMendaftarPage