import { useCallback, useEffect, useState } from "react";
import { useGlobalFunction } from "../../../../generalFunction";
import config from "../../../../config";
import api from "../../../../api";


export function useServerCalon(){
    const { isLoading, setIsLoading, showLoading, closeLoading, alertValue, setAlertValue, handleCloseAlert} = useGlobalFunction();
    const [isEdit, setIsEdit] = useState(false);
    const [formData, setFormData] = useState({
        id : {
            name : 'id',
            type: 'text',
            label : 'ID',
            value : '',
            required : false,
            showError : false,
            show: true,
        },
        host : {
            name : 'host',
            type: 'text',
            label : 'Host',
            value : '',
            required : true,
            showError : false,
            show: true,
        },
        path_ketum : {
            name : 'path_ketum',
            type: 'text',
            label : 'Path Ketua Umum',
            value : '',
            required : true,
            showError : false,
            show: true,
        },
        path_dewas : {
            name : 'path_dewas',
            type: 'text',
            label : 'Path Dewan Pengawas',
            value : '',
            required : true,
            showError : false,
            show: true,
        },
        username : {
            name : 'username',
            type: 'text',
            label : 'Username',
            value : '',
            required : true,
            showError : false,
            show: true,
        },
        password : {
            name : 'password',
            type: 'password',
            inputType: 'password',
            showPassword : false,
            label : 'Password',
            value : '',
            required : true,
            showError : false,
            show: true,
        },
    })

    const fetchData = useCallback(async(q='', offset=0, limit=config.itemPerPage) => {
        showLoading()
        try{
            const params = {
                offset : offset,
                limit : limit,
            }
            if (q) {
                params['q'] = q
            }
            const res = await api.get(`${config.endPoint.serverCalon}`, {params: params}).then(res => res.data);
            if (res){
                if (res.results && res.results.length > 0){
                    const results = res.results[0]
                    setFormData(formData => ({...formData,
                        id : {...formData.id, value : results.id},
                        host : {...formData.host, value : results.host},
                        path_ketum : {...formData.path_ketum, value : results.path_ketum},
                        path_dewas : {...formData.path_dewas, value : results.path_dewas},
                        username : {...formData.username, value : results.username},
                        password : {...formData.password, value : '', required: false},
                    }));
                    setIsEdit(true);
                }
            }
            closeLoading()
        }catch(error){            
            closeLoading()
            const { response } = error;
            let msg = 'Unable to fetch data'
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            setAlertValue(alertValue => ({...alertValue,
                show: true,
                text : 'Error',
                subText: msg,
                color: 'danger'
            }))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
        // eslint-disable-next-line 
    },[])
    
    useEffect(() => {
        fetchData();
    },[fetchData])

    const handleSubmit = async() => {
        setIsLoading(true);
        try{
            let obj = {...formData}
            let countError = 0;
            let tmp = {}
            Object.entries(obj).map(([index, post]) => {
                if (post.required && post.value === ''){
                    post.showError= true;
                    countError++;
                }else{
                    tmp[index] = post.value
                }
                return true;
            })
            
            if (countError > 0){
                setAlertValue(alertValue => ({...alertValue,
                    show: true,
                    text : 'Error',
                    subText: 'Lengkapi data',
                    color: 'danger'
                }))
                setIsLoading(false);
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue);
                return;
            }
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmp));
            let res;
            if (isEdit){
                res = await api.put(`${config.endPoint.serverCalon}${formData.id.value}/`, newForm).then(res => res.data);
            }else{
                res = await api.post(`${config.endPoint.serverCalon}`, newForm).then(res => res.data);
            }
            if (res){
                setAlertValue(alertValue => ({...alertValue,
                    show: true,
                    text : 'Success',
                    subText: 'Data berhasil disimpan',
                    color: 'success'
                }))
                fetchData();
                // fetchData(pageValue.search, pageValue.offset, config.itemPerPage)
            
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue)
            }
            setIsLoading(false);            
        }catch(error){
            setIsLoading(false);                        
            const { response } = error;
            let msg = 'Unable to save data'
            
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            
            setAlertValue(alertValue => ({...alertValue,
                show: true,
                text : 'Error',
                subText: msg,
                color: 'danger'
            }))
            
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }
    return {
        isLoading, setIsLoading, showLoading, closeLoading, alertValue, setAlertValue,
        handleSubmit, formData, setFormData, handleCloseAlert
    }
}