import { Fragment } from "react"
import { useSiteSetting } from "./siteSettingFunction";
import Card from "../../../../Components/Layout/Card";
import FormTextSingleComp from "../../../../Components/forms/FormTextSingleComp";
import Moment from 'moment';
import AlertComp from "../../../../Components/Atoms/AlertComp";
import GoogleMapReact from 'google-map-react';
import config from "../../../../config";
import { Icon } from "@iconify/react/dist/iconify.js";
import Loading from "../../../../Components/Atoms/Loading";
// import Button from "../../../../Components/Atoms/Button";


const SiteSettingPage = () => {
    const { isEdit, formData, setFormData, handleSubmit, handleEdit,
        handleCancel, alertValue, setAlertValue, truncate, expanded, setExpanded,
        handleClickMap, isReady
    } = useSiteSetting();

    const defaultProps = {
        center: {
          lat: -7.280359387734726,
          lng: 112.7023834451269
        },
        zoom: 15
    };

    console.log('lat lon >>>>', formData.lat.value, formData.lon.value)
    
    
    return(
        <Fragment>
            <AlertComp
                isOpen={alertValue.show}
                onClose={() => setAlertValue(alertValue => ({...alertValue, show: false}))}
                text={alertValue.text}
                subText={alertValue.subText}
            >
                {alertValue.text}
            </AlertComp>
            { isReady ?            
                <Card
                    title="Site Setting"
                    isCreate={isEdit}
                    isSearch={false}
                    handleSubmit={handleSubmit}
                    handleCreate={handleEdit}
                    btnCreateText="Edit"
                    handleCancel={handleCancel}
                    disableIconButtonCreate={true}
                >
                    { isEdit &&
                        <div className='grid grid-cols-2 gap-5'>
                            <div className="col-span-2 md:col-span-1 w-full">
                                <FormTextSingleComp
                                    {...formData.site_name}
                                    formData={formData}
                                    setFormData={setFormData}
                                />
                                <div className="mt-5">
                                    <FormTextSingleComp
                                        {...formData.diskripsi}
                                        formData={formData}
                                        setFormData={setFormData}
                                    />
                                </div>
                                <div className="mt-5">
                                    <FormTextSingleComp
                                        {...formData.tempat_event}
                                        formData={formData}
                                        setFormData={setFormData}
                                    />
                                </div>
                                <div className="mt-5">
                                    <FormTextSingleComp
                                        {...formData.kota}
                                        formData={formData}
                                        setFormData={setFormData}
                                    />
                                </div>
                                <div className="mt-5 w-full">
                                    <FormTextSingleComp
                                        {...formData.tanggal_mulai}
                                        formData={formData}
                                        setFormData={setFormData}
                                    />
                                </div>
                                <div className="mt-5">
                                    <FormTextSingleComp
                                        {...formData.tanggal_selesai}
                                        formData={formData}
                                        setFormData={setFormData}
                                    />
                                </div>
                                <div className="mt-5">
                                    <FormTextSingleComp
                                        {...formData.tanggal_event}
                                        formData={formData}
                                        setFormData={setFormData}
                                    />
                                </div>
                                <div className="mt-5">
                                    <FormTextSingleComp
                                        {...formData.tanggal_selesai_event}
                                        formData={formData}
                                        setFormData={setFormData}
                                    />
                                </div>
                                <div className="mt-5">
                                    <FormTextSingleComp
                                        {...formData.site_footer}
                                        formData={formData}
                                        setFormData={setFormData}
                                    />
                                </div>
                                <div className="mt-5">
                                    <FormTextSingleComp
                                        {...formData.wa}
                                        formData={formData}
                                        setFormData={setFormData}
                                    />
                                </div>
                            </div>
                            <div className="col-span-2 md:col-span-1">
                                <FormTextSingleComp
                                    {...formData.host}
                                    formData={formData}
                                    setFormData={setFormData}
                                />
                                <div className="mt-5">
                                    <FormTextSingleComp
                                        {...formData.user}
                                        formData={formData}
                                        setFormData={setFormData}
                                    />
                                </div>
                                <div className="mt-5">
                                    <FormTextSingleComp
                                        {...formData.password}
                                        formData={formData}
                                        setFormData={setFormData}
                                    />
                                </div>
                                <div className="mt-5">
                                    <FormTextSingleComp
                                        {...formData.port}
                                        formData={formData}
                                        setFormData={setFormData}
                                    />
                                </div>
                                <div className="mt-5">
                                    <FormTextSingleComp
                                        {...formData.lat}
                                        formData={formData}
                                        setFormData={setFormData}
                                    />
                                </div>
                                <div className="mt-5">
                                    <FormTextSingleComp
                                        {...formData.lon}
                                        formData={formData}
                                        setFormData={setFormData}
                                    />
                                </div>
                                <div className='text-justify mt-[30px] h-[calc(100vh-500px)] w-full'>
                                    <div className='text-sm font-bold mb-3'>Lokasi</div>
                                    <GoogleMapReact
                                        bootstrapURLKeys={{ key: config.googleMapKey }}
                                        defaultCenter={ formData?.lat?.value && formData.lon?.value ? {
                                            lat : parseFloat(formData?.lat?.value),
                                            lng : parseFloat(formData?.lon?.value),
                                        } : defaultProps.center}
                                        defaultZoom={defaultProps.zoom}
                                        onClick={(obj) => handleClickMap(obj)}                                        
                                    >
                                        {/* -7.280359387734726, 112.7023834451269 */}
                                        <div 
                                            className='rounded-full -top-[20px] -left-[20px] absolute'  
                                            lat={formData?.lat?.value}
                                            lng={formData?.lon?.value}
                                        >

                                            <Icon icon="carbon:location-filled"
                                                text="Hotel"
                                                fontSize={"35"}
                                                color='red'
                                            />
                                        </div>
                                        {/* <Marker
                                            lat={formData?.lat?.value}
                                            lng={formData?.lon?.value}
                                        /> */}
                                    </GoogleMapReact>
                                </div>
                            </div>
                        </div>
                    }
                    { !isEdit &&
                        <div className='grid grid-cols-2 gap-5 h-fit min-h-[calc(100vh-300px)]'>
                            <div className="col-span-2 md:col-span-1">
                                
                                <table className='text-[13px]'>
                                    <thead>
                                        <tr>
                                            <td className='pr-2 w-[50%] align-top'>Nama Event</td>
                                            <td className='px-2 align-top'>:</td>
                                            <td className='px-2 align-top'>{formData?.site_name?.value}</td>
                                        </tr>
                                        <tr>
                                            <td className='pr-2 w-[50%] align-top'>Deskripsi Event</td>
                                            <td className='px-2 align-top'>:</td>
                                            <td className='px-2 align-top'>{expanded ? formData?.diskripsi?.value : truncate(formData?.diskripsi?.value, 150)} 
                                                { formData.diskripsi?.value &&
                                                    <button className='text-blue-500' onClick={() => setExpanded(!expanded)}>{!expanded ? 'More' : 'Less'}</button>
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='pr-2 w-[50%] align-top'>Tempat</td>
                                            <td className='px-2 align-top'>:</td>
                                            <td className='px-2 align-top'>{formData?.tempat_event?.value}</td>
                                        </tr>
                                        <tr>
                                            <td className='pr-2 w-[50%] align-top'>Tanggal Mulai Pendaftaran</td>
                                            <td className='px-2 align-top'>:</td>
                                            <td className='px-2 align-top'>{Moment(formData?.tanggal_mulai.value).format('DD-MM-YYYY')}</td>
                                        </tr>
                                        <tr>
                                            <td className='pr-2 w-[50%] align-top'>Tanggal Selesai Pendaftaran</td>
                                            <td className='px-2 align-top'>:</td>
                                            <td className='px-2 align-top'>{Moment(formData?.tanggal_selesai.value).format('DD-MM-YYYY')}</td>
                                        </tr>
                                        <tr>
                                            <td className='pr-2 w-[50%] align-top'>Tanggal Event</td>
                                            <td className='px-2 align-top'>:</td>
                                            <td className='px-2 align-top'>{Moment(formData?.tanggal_event.value).format('DD-MM-YYYY')}</td>
                                        </tr>
                                        <tr>
                                            <td className='pr-2 w-[50%] align-top'>Tanggal Selesai Event</td>
                                            <td className='px-2 align-top'>:</td>
                                            <td className='px-2 align-top'>{Moment(formData?.tanggal_selesai_event.value).format('DD-MM-YYYY')}</td>
                                        </tr>
                                        <tr>
                                            <td className='pr-2 w-[50%] align-top'>Site Footer</td>
                                            <td className='px-2 align-top'>:</td>
                                            <td className='px-2 align-top'>{formData?.site_footer.value}</td>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                            <div className="col-span-2 md:col-span-1 ">
                                <table className='text-[13px]'>
                                    <tbody>
                                        <tr>
                                            <td className='pr-2 w-[50%] align-top'>Email</td>
                                            <td className='px-2 align-top'>:</td>
                                            <td className='px-2 align-top'>{formData?.host?.value}</td>
                                        </tr>
                                        <tr>
                                            <td className='pr-2 w-[50%] align-top'>User</td>
                                            <td className='px-2 align-top'>:</td>
                                            <td className='px-2 align-top'>{formData?.user?.value}</td>
                                        </tr>
                                        <tr>
                                            <td className='pr-2 w-[50%] align-top'>Port</td>
                                            <td className='px-2 align-top'>:</td>
                                            <td className='px-2 align-top'>{formData?.port?.value}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className='text-justify mt-[30px] h-[calc(100vh-500px)] w-full'>
                                    <div className='text-sm font-bold mb-3'>Lokasi</div>
                                    <GoogleMapReact
                                        bootstrapURLKeys={{ key: config.googleMapKey }}                                        
                                        defaultCenter={ formData?.lat?.value && formData.lon?.value ? {
                                            lat : parseFloat(formData?.lat?.value),
                                            lng : parseFloat(formData?.lon?.value),
                                        } : defaultProps.center}
                                        defaultZoom={defaultProps.zoom}
                                    >
                                        <div 
                                            className='rounded-full -top-[20px] -left-[20px] absolute'  
                                            lat={formData?.lat?.value}
                                            lng={formData?.lon?.value}
                                        >

                                            <Icon icon="carbon:location-filled"
                                                text="Hotel"
                                                fontSize={"35"}
                                                color='red'
                                            />
                                        </div>
                                    </GoogleMapReact>
                                </div>
                            </div>

                        </div>
                    }
                </Card>
                :
                <Loading/>
            }
        </Fragment>
    )
}
export default SiteSettingPage;