import { useEffect, useState } from "react";
import { useGlobalFunction } from "../../../generalFunction";
import config from "../../../config";
import api from "../../../api";
import { useCookies } from "react-cookie";
import api_download from "../../../api_download";
import { encryptedResponse } from "../../../utils/crypto";

export function useExportDataExcel() {
    const { isLoading, setIsLoading, alertValue, setAlertValue, handleCloseAlert,
        modalValue, setModalValue, handleCloseModal
    } = useGlobalFunction();
    const [info, setInfo] = useState({ isLoading: isLoading, data: null });
    const [view, setView] = useState('list');
    const [cookies, setCookie] = useCookies(config.cookiesNamePassword);
    const getcookie = cookies[config.cookiesNamePassword];

    const handleDownloadAllFile = async () => {
        if (!getcookie['export_data']) {
            setView("password")
        }

        setIsLoading(true)
        try {
            const params = {
                password: getcookie['exportdata'],
                kode: '4'
            }
            const response = await api_download.downloadFile(`${config.endPoint.exportData}`, params);

            // Membuat URL dan mengkliknya untuk mengunduh file
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;

            // Nama file sudah ditentukan dari backend, gunakan nama file dari header Content-Disposition
            const contentDisposition = response.headers['content-disposition'];
            let fileName = 'export_data.xlsx';
            if (contentDisposition) {
                console.log(contentDisposition)
                const fileNameMatch = contentDisposition.split('filename=')[1].replace(/"/g, '');
                if (fileNameMatch) {
                    fileName = fileNameMatch;
                }
            }

            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();

            // Bersihkan elemen setelah selesai
            document.body.removeChild(link);
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            const { response } = error;
            let msg = "Unable to fetch data";
            if (response && response.data) {
                msg = response.statusText
            }
            setAlertValue((alertValue) => ({
                ...alertValue,
                show: true,
                text: "Error",
                subText: msg,
                color: "danger",
            }));
            setTimeout(() => {
                setAlertValue((alertValue) => ({ ...alertValue, show: false }));
            }, config.timeOutValue);
        }
    }


    const [formPassword, setFormPassword] = useState({
        password: {
            name: 'password',
            type: 'password',
            inputType: 'password',
            showPassword: false,
            label: 'Input Your Password',
            value: '',
            required: true,
            showError: false,
            show: true,
        },
    })

    useEffect(() => {
        if (getcookie) {
            if (!getcookie.exportdata) {
                setView('password')
            }
        } else {
            setView('password')
        }
    }, [getcookie])


    const handleSubmitPassword = async () => {
        setIsLoading(false);
        try {
            let obj = { ...formPassword }
            let countError = 0;
            let tmp = {}
            Object.entries(obj).map(([index, post]) => {
                if (post.required && post.value === '') {
                    post.showError = true;
                    countError++;
                } else {
                    tmp[index] = post.value
                }
                return true;
            })

            if (countError > 0) {
                setAlertValue(alertValue => ({
                    ...alertValue,
                    show: true,
                    text: 'Error',
                    subText: 'Lengkapi data',
                    color: 'danger'
                }))
                setIsLoading(false);
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }))
                }, config.timeOutValue)
            }

            tmp['kode'] = 4;
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmp));

            const res = await api.post(`${config.endPoint.loginDownload}`, newForm).then(res => res.data);
            if (res) {
                let tmpcookie;
                if (!getcookie) {
                    tmpcookie = {
                        'exportdata': encryptedResponse(tmp.password),
                    };
                } else {
                    tmpcookie = { ...getcookie };
                    tmpcookie['exportdata'] = encryptedResponse(tmp.password)
                }
                setCookie(config.cookiesNamePassword, tmpcookie, { path: '/', maxAge: config.expiresSession });
                window.location.href = '/jelangkung/exportdata';
            }
        } catch (error) {
            setIsLoading(false);
            const { response } = error;
            let msg = 'Unable to save data'

            if (response && response.data && response.data.message) {
                msg = response.data.message;
            }

            setAlertValue(alertValue => ({
                ...alertValue,
                show: true,
                text: 'Error',
                subText: msg,
                color: 'danger'
            }))

            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }))
            }, config.timeOutValue)
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmitPassword();
        }
    }

    return {
        isLoading, setIsLoading, alertValue, setAlertValue, handleCloseAlert,
        modalValue, setModalValue, handleCloseModal, view, setView, info, setInfo,
        setFormPassword, handleSubmitPassword, formPassword, handleKeyDown, handleDownloadAllFile

    }
}