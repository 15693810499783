import { useCallback, useContext, useEffect, useState } from "react";
import { useGlobalFunction } from "../../../../generalFunction";
import api from "../../../../api";
import config from "../../../../config";
import { pageObj } from "../../../../pageObj";
import { Icon } from "@iconify/react/dist/iconify.js";
import { ThemeContext } from "../../../../context/ThemeContext";


export function usePerusahaan(){
    const { isLoading, showLoading, closeLoading, setIsLoading,
        modalValue, setModalValue, handleCloseModal,
        pageValue, setPageValue, alertValue, setAlertValue, handleCloseAlert,
        catchFetching, 
    } = useGlobalFunction();
    const [isEdit, setIsEdit] = useState(false);
    const { setTitle } = useContext(ThemeContext);
    const [listData, setListData] = useState([
        {
            id : 1,
            nama : 'PT 3D TECH',
            provinsi : 'Kepulauan Riau',
            email : 'donal@3dtech.net.id',
            bisa_mendaftar : 'Ya'
        }
    ]);
    const [view, setView] = useState('list');
    const [formData, setFormData] = useState({
        id : {
            name : 'id',
            label : 'ID',
            value : '',
            type : 'text',
            col : 2,
            required : false,
            show : false,
            errorMsg : '',
            showError : false,
            flexType : 'row'
        },       
        nama : {
            name : 'nama',
            label : 'Nama',
            value : '',
            type : 'text',
            col : 2,
            required : true,
            show : true,
            errorMsg : '',
            showError : false,
            flexType : 'row'
        },
        provinsi : {
            name : 'provinsi',
            label : 'Provinsi',
            value : '',
            type : 'text',
            col : 2,
            required : true,
            show : true,
            errorMsg : '',
            showError : false,
            flexType : 'row'
        },
        email : {
            name : 'email',
            label : 'Email',
            value : '',
            type : 'text',
            col : 2,
            required : false,
            show : true,
            errorMsg : '',
            showError : false,
            flexType : 'row'
        },
        is_takeout : {
            name : 'is_takeout',
            label : 'Is Takeout ?',
            value : false,
            type : 'checked',
            col : 2,
            required : false,
            show : true,
            errorMsg : '',
            showError : false,
            flexType : 'row',
            helper : 'is_takeout'
        },
    })
    const [aktaUrl, setAktaUrl] = useState(null);

    useEffect(() => {
        setTitle('Master Perusahaan')
    }, [setTitle])

    const fetchData = useCallback(async (q = '', offset = 0, limit = config.itemPerPage, is_eligible='', is_registrasi='') => {
        setIsLoading(true);
        showLoading();
        try {
            let params = {
                offset: offset,
                limit: limit
            }
            if (q) {
                params['q'] = q;
            }
            if (is_eligible){
                params['is_eligible'] = is_eligible
            }
            if (is_registrasi){
                params['is_registrasi'] = is_registrasi
            }
            const res = await api.get(`${config.endPoint.perusahaan}`, { params: params }).then(res => res.data);
            let tmp = []
            if (res) {
                res.results.map(post => {
                    let obj = {...post};                    
                    tmp.push(obj);
                    return true;
                })
                setPageValue(pageValue => ({
                    ...pageValue,
                    obj: pageObj(res.count, limit, offset),
                    lastPage: Math.ceil(parseInt(res.count) / parseInt(limit)),                                      
                }))
            }
            setListData(tmp);
            setIsLoading(false);
            closeLoading();
        } catch (error) {
            setIsLoading(false);
            closeLoading();
            let status = error && error.response && error.response.status ? error.response.status : '';
            catchFetching(error, 'admin')

            if (status && status === 401) {
                return window.location.href = "/admin/login"
            } else {
                setAlertValue(alertValue => ({...alertValue, show : true, text : 'Unable to fetch data', color : 'danger'}));
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show : false}));                    
                }, 5000)         
            }
        }
        // eslint-disable-next-line
    }, [setAlertValue, setIsLoading, setPageValue])

    useEffect(() => {
        fetchData();
    },[fetchData])

    const handleDelete = (post) => {
        setModalValue(modalValue => ({...modalValue,
            show: true,
            id : post.id,
            text : `Apakah anda yakin ingin menghapus perusahaan ${post.nama} ?`,
            tipe : 'delete'
        }))
    }

    const handleSubmitDelete = async() => {
        setIsLoading(true);
        showLoading();
        try{
            const res = await api.delete(`${config.endPoint.perusahaan}${modalValue.id}/`).then(res => res.data);
            if(res){
                fetchData(pageValue.search, pageValue.offset, config.itemPerPage, pageValue.is_eligible, pageValue.is_registrasi);
                setAlertValue(alertValue => ({...alertValue, show : true, text : 'Perusahaan dihapus', color: 'success'}));
                setTimeout(() => {
                    handleCloseAlert();
                }, 5000)                
            }
            handleCloseModal();
            setIsLoading(false);
            closeLoading();
        }catch(error){
            setIsLoading(false);
            closeLoading();
            setAlertValue(alertValue => ({...alertValue, show : true, text : 'Unable to delete data', color: 'danger'}));
            setTimeout(() => {
                handleCloseAlert();
            }, 5000)
        }
    }

    const handleSubmitResetPendaftaran = async() => {
        setIsLoading(true);
        try{
            
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            setIsLoading(false);
            const { response } = error;
            let msg = 'Unable to save data'
            
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            
            setAlertValue(alertValue => ({...alertValue,
                show: true,
                text : 'Error',
                subText: msg,
                color: 'danger'
            }))
            
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }

    const handleCreate = () => {
        setView('create');
        setIsEdit(false);
        setFormData(formData => ({...formData,
            id : {...formData.id, value : ''},
            nama : {...formData.nama, value : ''},
            provinsi : {...formData.provinsi, value : ''},
            email : {...formData.email, value : ''},
            is_takeout : {...formData.is_takeout, value : false},
        }));
        setAktaUrl(null);
    }

    const handleCancel = () => {
        setView('list');
    }

    const handleResetPerusahaan = (post) => {
        setModalValue(modalValue => ({...modalValue,
            show : true,
            tipe : 'reset',
            id : post.id,
            text : `Apakah yakin akan melakukan reset perusahaan ${post.nama} ? reset akan menghapus user Perusahaan, menghapus peserta dan revoke hotel`
        }))
    }

    const handleSubmitResetPerusahaan = async() => {
        setIsLoading(true);
        try{
            const res = await api.put(`${config.endPoint.resetPerusahaan}${modalValue.id}/`).then(res => res.data);
            if (res){
                setAlertValue(alertValue => ({...alertValue,
                    show : true,
                    text : 'Successfully',
                    subText: 'Reset Perusahaan Berhasil'
                }))

                setTimeout(() => {
                    handleCloseAlert();
                }, config.timeOutValue)
                fetchData(pageValue.search, pageValue.offset, config.itemPerPage)
            }
            handleCloseModal();
        }catch(error){
            setIsLoading(false);
            const { response } = error;
            let msg = 'Unable to save data'
            
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            
            setAlertValue(alertValue => ({...alertValue,
                show: true,
                text : 'Error',
                subText: msg,
                color: 'danger'
            }))
            
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }


     // ============== form Data ================
     const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(formData => ({...formData,
            [name] : {...formData[name], value : value}
        }))
    }

    const handleBlur = (e) => {
        const { name, value } = e.target;
        if (formData[name].required && value === ''){
            setFormData(formData => ({...formData,
                [name] : {...formData[name], showError: true}
            }))
        }else{
            setFormData(formData => ({...formData,
                [name] : {...formData[name], showError: false}
            }))
        }
    }


    const handleSubmit = async() => {
        setIsLoading(true);
        showLoading();
        try{
            let obj = {...formData};
            let countError = 0;
            let tmpForm = {}

            Object.entries(formData).map(([index, post]) => {
                if (post.required && post.value === ''){
                    countError++;
                    post.showError = true;
                    console.log('errornya >>>>>', post.value, index)
                }else{
                    post.showError = false;
                    tmpForm[index] = post.value;
                }
                return true;
            })

            if (countError > 0){
                setAlertValue(alertValue => ({...alertValue,
                    show: true, text : 'Error', subText : 'Lengkapi data', color : 'danger',
                    icon : <Icon icon="fa:times" />
                }));
                setFormData(obj);
                setIsLoading(false);
                closeLoading();
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue,
                        show: false
                    }));                    
                }, config.timeOutValue)
                return;
            }

            console.log('tmpForm >>>>', tmpForm)
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmpForm));
            let res;
            if (isEdit) {
                res = await api.put(`${config.endPoint.perusahaan}${formData.id.value}/`, newForm).then(res => res.data);
            }else{
                res = await api.post(`${config.endPoint.perusahaan}`, newForm).then(res => res.data);
            }
            if (res){
                setView('list');
                fetchData(pageValue.search, pageValue.offset, config.itemPerPage, pageValue.is_eligible, pageValue.is_registrasi);
            }
            setIsLoading(false);
        }catch(error){
            closeLoading();
            setIsLoading(false);
            let status = error && error.response && error.response.status ? error.response.status : '';

            if (status && status === 401) {
                return window.location.href = "/admin/login"
            } else {
                setAlertValue(alertValue => ({...alertValue, show : true, text : 'Unable to fetch data', color : 'danger'}));
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show : false}));                    
                }, 5000)         
            }
        }
    }

    const handleEdit = (post) => {
        setAktaUrl(post.akta_display)
        setFormData(formData => ({...formData,
            nama : {...formData.nama, value : post.nama},
            id : {...formData.id, value : post.id},
            provinsi : {...formData.provinsi, value : post.provinsi},
            is_takeout : {...formData.is_takeout, value : post.is_takeout},
            email : {...formData.email, value : post.email},
        }))
        setIsEdit(true);
        setView('create');
    }

    const handlePaginate = (page) => {
        let myOffset = (parseInt(page) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
        setPageValue(pageValue => ({...pageValue, page : page, offset: myOffset}))
        fetchData(pageValue.search, myOffset, config.itemPerPage, pageValue.is_eligible, pageValue.is_registrasi);
    }

    const handleChangeSearch = (e) => {
        const { name, value } = e.target;
        setPageValue(pageValue => ({...pageValue, [name] : value}))
        if (name === 'is_eligible'){
            fetchData(pageValue.search, 0, config.itemPerPage, value, pageValue.is_registrasi);
            setPageValue(pageValue => ({...pageValue, page : 1, offset : 0}))
        }
        if (name === 'is_registrasi'){
            fetchData(pageValue.search, 0, config.itemPerPage, pageValue.is_eligible, value);
            setPageValue(pageValue => ({...pageValue, page : 1, offset : 0}))
        }
    }

    const handleSearch = () => {
        setPageValue(pageValue => ({...pageValue, offset: 0, page : 1}));
        fetchData(pageValue.search, 0, config.itemPerPage, pageValue.is_eligible, pageValue.is_registrasi)
    }

    const handleKeyDownSearch = (e) => {
        if (e.key === "Enter"){
            handleSearch();
        }
    }

    const handleImport = async() => {
        setIsLoading(true);
        setPageValue(pageValue => ({...pageValue, obj : []}))
        try{
            const res = await api.post(`${config.endPoint.importPerusahaanNew}`).then(res => res.data);
            if (res){
                fetchData(pageValue.search, pageValue.offset)
                setAlertValue(alertValue => ({...alertValue, show : true, text : 'Perusahaan di perbaharui dari MY APJII', color : 'success'}));
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show : false}));                    
                }, 10000)   
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show : true, text : 'Error', subText : 'Unable to import data', color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show : false}));                    
            }, 5000)   
        }
    }

    return {
        isLoading, showLoading, closeLoading, setIsLoading, listData,
        formData, setFormData,  setListData, handleSubmit,
        handleDelete, modalValue, setModalValue, handleCloseModal, handleSubmitDelete,
        pageValue, setPageValue, view,
        handleCreate, handleCancel, handleChange, handleBlur, alertValue, catchFetching, handleCloseAlert,
        setIsEdit, aktaUrl, handleEdit, handlePaginate,
        handleChangeSearch, handleSearch, handleKeyDownSearch, handleImport, 
        handleSubmitResetPendaftaran, handleResetPerusahaan, handleSubmitResetPerusahaan
    }
}