import { Icon } from "@iconify/react/dist/iconify.js";
import { Fragment } from "react";
// import Nav from "../Atoms/Nav";

const FooterHome = () => {
  return (
    <Fragment>
      <div className="absolute px-[30px] md:px-[100px] pb-[30px] bottom-0 left-0 border-t w-full border-t-[#FFFFFF] pt-[40px]">
        <div className=" flex flex-row justify-between">
          <div className="flex flex-row items-center">
            <Icon icon="solar:copyright-outline" />
            <div className="ml-[20px]">2024 APJII. ALL RIGHT RESERVED</div>
          </div>
          <div className="flex flex-row justify-between">{/* <Nav/> */}</div>
        </div>
      </div>
    </Fragment>
  );
};
export default FooterHome;
