import { useCallback, useContext, useEffect, useState } from "react";
import { useGlobalFunction } from "../../../../generalFunction";
import api from "../../../../api";
import config from "../../../../config";
import { pageObj } from "../../../../pageObj";
import { ThemeContext } from "../../../../context/ThemeContext";

export function usePerusahaanMendaftar(){
    const { isLoading, setIsLoading, alertValue, setAlertValue, handleCloseAlert,
        modalValue, setModalValue, handleCloseModal, pageValue, setPageValue, closeLoading, showLoading
    } = useGlobalFunction();
    const [listData, setListData] = useState({isLoading : isLoading, data: []});
    const [view, setView] = useState('list');
    const [isEdit, setIsEdit] = useState(false);
    const { setTitle } = useContext(ThemeContext);

    
    useEffect(() => {
        setTitle('Perusahaan Mendaftar')
    }, [setTitle])

    const fetchData = useCallback(async(q='', offset=0, limit=config.itemPerPage) => {
        showLoading()
        try{
            const params = {
                offset : offset,
                limit : limit,
            }
            if (q) {
                params['q'] = q
            }
            const res = await api.get(`${config.endPoint.perusahaanMendaftar}`, {params: params}).then(res => res.data);
            if (res){
                setListData(listData => ({...listData, isLoading: false, data: res.results}))
                setPageValue(pageValue => ({...pageValue,
                    obj : pageObj(res.count, limit, offset),
                    lastPage : Math.ceil(parseInt(res.count) / parseInt(limit))
                }))
            }else{
                setListData(listData => ({...listData, isLoading: false}))
            }
            closeLoading()
        }catch(error){
            setListData(listData => ({...listData, isLoading: false}))
            closeLoading()
            const { response } = error;
            let msg = 'Unable to fetch data'
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            setAlertValue(alertValue => ({...alertValue,
                show: true,
                text : 'Error',
                subText: msg,
                color: 'danger'
            }))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
        // eslint-disable-next-line 
    },[])
    
    useEffect(() => {
        fetchData();
    },[fetchData])

    const handleChangeSearch = (e) => {
        const { name, value } = e.target;
        setPageValue(pageValue => ({...pageValue, [name] : value}))
    }
    
    const handleKeyDownSearch = (e) => {
        if (e.key === 'Enter'){
            handleSearch();
        }
    }
    
    const handleSearch = () => {
        fetchData(pageValue.search, 0, config.itemPerPage)
    }

    const handlePaginate = (page) => {
        let myOffset = (parseInt(page) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
        setPageValue(pageValue => ({...pageValue, page : page, offset: myOffset}))
        fetchData(pageValue.search, myOffset, config.itemPerPage);
    }
    
    return {
        isLoading, setIsLoading, alertValue, setAlertValue, handleCloseAlert, 
        modalValue, setModalValue, handleCloseModal, 
        isEdit, setIsEdit, view, setView, listData, setListData,
        pageValue, setPageValue, closeLoading, showLoading,
        handleChangeSearch, handleKeyDownSearch, handleSearch,
        handlePaginate

    }
}