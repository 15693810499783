import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import { NavLink, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import config from "../../config";
import { Icon } from "@iconify/react/dist/iconify.js";

const SideBarRightHome = () => {
  const { showMobileMenu, setShowMobileMenu } = useContext(ThemeContext);
  const [cookies] = useCookies([config.cookiesName]);
  const [isLogin, setIsLogin] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (cookies && cookies[config.cookiesName]) {
      setIsLogin(true);
    }
    // eslint-disable-next-line
  }, []);

  const handleCloseNav = () => {
    setShowMobileMenu(false);
  };
  return (
    <aside
      className={`fixed md:hidden lg:right-0 z-50 h-screen flex flex-col  bg-white dark:bg-base-600 transition-[width,left] duration-500 tracking-wide ${showMobileMenu ? "w-64 -right-0" : "w-20 -right-96"
        } 
                rounded-l-2xl shadow-xl text-gray-500 font-admin px-[.875rem] py-[0.75rem]
            `}
    >
      <div className="flex flex-row justify-end mt-3 text-[24px]">
        <button onClick={() => setShowMobileMenu(!showMobileMenu)}>
          <Icon icon="clarity:times-line" />
        </button>
      </div>
      <div className="mt-8">
        <NavLink onClick={handleCloseNav} to="/">
          <div
            className={`${location.pathname === "/" ? "bg-sky-100" : ""
              } w-full py-2 hover:bg-sky-50 px-[18px] font-admin text-[.875rem] font-light rounded`}
          >
            Home
          </div>
        </NavLink>

        <NavLink onClick={handleCloseNav} to={"/syarat-dan-ketentuan"}>
          <div
            className={`${location.pathname === "/syarat-dan-ketentuan" ? "bg-sky-100" : ""
              } w-full py-2 hover:bg-sky-50 px-[18px] font-admin text-[.875rem] font-light rounded`}
          >
            Syarat & Ketentuan
          </div>
        </NavLink>

        <NavLink onClick={handleCloseNav} to={"/tata-tertib"}>
          <div
            className={`${location.pathname === "/tata-tertib" ? "bg-sky-100" : ""
              } w-full py-2 hover:bg-sky-50 px-[18px] font-admin text-[.875rem] font-light rounded`}
          >
            Tata Tertib
          </div>
        </NavLink>

        <NavLink onClick={handleCloseNav} to="/download-area">
          <div
            className={`${location.pathname === "/download-area" ? "bg-sky-100" : ""
              } w-full py-2 hover:bg-sky-50 px-[18px] font-admin text-[.875rem] font-light rounded`}
          >
            Download Area
          </div>
        </NavLink>
        <NavLink onClick={handleCloseNav} to="/agenda">
          <div
            className={`${location.pathname === "/agenda" ? "bg-sky-100" : ""
              } w-full py-2 hover:bg-sky-50 px-[18px] font-admin text-[.875rem] font-light rounded`}
          >
            Agenda
          </div>
        </NavLink>
        <NavLink onClick={handleCloseNav} to="/peserta">
          <div
            className={`${location.pathname === "/peserta" ? "bg-sky-100" : ""
              } w-full py-2 hover:bg-sky-50 px-[18px] font-admin text-[.875rem] font-light rounded`}
          >
            Daftar Peserta
          </div>
        </NavLink>
        <NavLink onClick={handleCloseNav} to="/lokasi">
          <div
            className={`${location.pathname === "/lokasi" ? "bg-sky-100" : ""
              } w-full py-2 hover:bg-sky-50 px-[18px] font-admin text-[.875rem] font-light rounded`}
          >
            Lokasi
          </div>
        </NavLink>
        {isLogin ? (
          <NavLink onClick={handleCloseNav} to="/member">
            <div
              className={`${location.pathname === "/member" ? "bg-sky-100" : ""
                } w-full py-2 hover:bg-sky-50 px-[18px] font-admin text-[.875rem] font-light rounded`}
            >
              Member Area
            </div>
          </NavLink>
        ) : (
          <NavLink onClick={handleCloseNav} to="/login">
            <div
              className={`${location.pathname === "/login" ? "bg-sky-100" : ""
                } w-full py-2 hover:bg-sky-50 px-[18px] font-admin text-[.875rem] font-light rounded`}
            >
              Login
            </div>
          </NavLink>
        )}
      </div>
    </aside>
  );
};
// export default SideBarRightHome
export default SideBarRightHome;
