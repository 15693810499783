import { useCallback, useContext, useEffect, useState } from "react";
import { useGlobalFunction } from "../../../../generalFunction";
import api from "../../../../api";
import config from "../../../../config";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../../context/UserContext";

export function useRegister(){
    const { isLoading, setIsLoading, alertValue, setAlertValue } = useGlobalFunction();
    const navigate = useNavigate();
    const [isSubmit, setIsSubmit] = useState(false);    
    const myState = useContext(UserContext);

    const [canRegister] = myState.canRegister
    const [signUpForm, setSignUpForm] = useState({
        perusahaan : {
            name : 'perusahaan',
            type : 'select_input',
            show : true,            
            label : 'Perusahaan',
            errorMsg : '',
            showError : false,
            required : true,
            obj : [],
            value : '',
            placeholder : 'Ketik & Pilih Perusahaan'
        },
        email : {
            name : 'email',
            type : 'text',
            show : true,            
            label : 'Email',
            errorMsg : '',
            showError : false,
            required : true,
            value : '',
            placeholder: 'Email'
        },        
        hp : {
            name : 'hp',
            label : 'Nomor Handphone',
            value : '',
            type : 'number',
            inputType : 'hp',
            col : 2,
            required : true,
            show : true,
            errorMsg : '',
            showError : false,   
            placeholder : 'Nomor HP'  ,       
        },
    })

    const fetchPerusahaan = useCallback(async() => {
        setIsLoading(true);
        try{
            let params = {
                unregister : 'yes'
            }
            const res = await api.get(`${config.endPoint.perusahaanGuest}`, {params: params}).then(res => res.data);
            let tmp = [];
            if (res){
                res.results.map(post => tmp.push({value : post.id_display, label : post.nama}))
            }
            setSignUpForm(signUpForm => ({...signUpForm,
                perusahaan : {...signUpForm.perusahaan, obj : tmp}
            }))
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            let status = error && error.response && error.response.status ? error.response.status : '';
            
            let msg = 'Unable to save Data';
            let { response } = error;
            if (response && response.data && response.data.message){
                msg = response.data.message
            }
            
            if (status && status === 401) {
                console.log('ga dapet akses')
                // return window.location.href = "/admin/login"
            } else {
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue)
            }
        }
    },[setIsLoading, setAlertValue])

    useEffect(() => {
        fetchPerusahaan();
    },[fetchPerusahaan])

    const handleSubmitRegister = async() => {
        setIsLoading(true);
        let base_url = `${window.location.protocol}//${window.location.host}/member/email-verification`
        
        try{
            let obj = {...signUpForm};
            let countError = 0;
            let tmpForm = {}

            Object.entries(obj).map(([index, post]) => {
                if (post.required && post.value === ''){
                    countError++;
                    post.showError = true;
                    post.errorMsg = '';
                }else{
                    post.showError = false;
                    tmpForm[index] = post.value;
                }
                if (post.name === 'email' && post.value.includes('gmail', 'yahoo', 'ymail', 'zoho', 'mail.com', 'outlook', 'aol', 'yandex', 'lycos', 'protonmail', 'icloud') ) {                
                    post.showError = true;
                    countError++;
                    post.errorMsg = "Gunakan email perusahaan"
                }
                return true;
            })

            if (countError > 0){
                setAlertValue(alertValue => ({...alertValue,
                    show: true, text : 'Lengkapi data', color : 'danger'
                }));
                setSignUpForm(obj);
                setIsLoading(false);
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue,
                        show: false
                    }));                    
                }, config.timeOutValue)
                return;
            }            
            tmpForm['callback_url'] = base_url            

            let newForm = new FormData();

            newForm.append('formData', JSON.stringify(tmpForm));
            let res = await api.post(`${config.endPoint.signUp}`, newForm).then(res => res.data);
            
            if (res){
                // setView('checkemail')
                setIsSubmit(true);
            }
            
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            let msg = 'Unable to save Data';
            let { response } = error;
            if (response && response.data && response.data.message){
                msg = response.data.message
            }            
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }));
            }, config.timeOutValue)
        }
    }

    const handleLogin = () => {
        navigate('/login')
    }

    

    return {
        signUpForm, setSignUpForm, isLoading, setIsLoading, alertValue, handleSubmitRegister, handleLogin, isSubmit, canRegister
    }
}