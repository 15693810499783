import { useCallback, useEffect, useState } from "react";
import api from "../../../../api";
import config from "../../../../config";
import { useGlobalFunction } from "../../../../generalFunction";
import PerusahaanAtom from "./PerusahaanAtom";
import { useMenu } from "../../../../constants/menuFunction";
// import config from "../../../config";
// import { UserContext } from "../../../context/UserContext";

const PerusahaanComp = (props) => {
    // const myState = useContext(UserContext);
    // const [userPermState, setUserPermState] = myState.userPermState
    // const [userPermState] = myState.userPermState

    // console.log('userPermState >>>>>', userPermState)
    const { isLoading, setIsLoading, catchFetching } = useGlobalFunction();
    const [info, setInfo] = useState(null);
    const { listGroup, isSuperUser } = useMenu();


    const fetchData = useCallback(async () => {
        setIsLoading(true);
        try {
            const res = await api.get(`${config.endPoint.resumePeserta}`).then(res => res.data);
            if (res) {
                setInfo(res.results);
            }
            setIsLoading(false);
        } catch (error) {
            catchFetching(error, 'admin')
            setIsLoading(false);
        }
        // eslint-disable-next-line
    }, [setIsLoading])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    return (
        <div className="grid grid-cols-1 md:grid-cols-5 gap-4 mb-4">

            {(isSuperUser || ['Administrator', 'Operator'].some(r => listGroup.includes(r))) &&
                <div>
                    <PerusahaanAtom
                        title="Perusahaan Non Outstanding"
                        nilai={info && info.perusahaan_valid}
                        description="Total Perusahaan"
                        // sub_description="Eligible"
                        sub_description="Anggota APJII"
                        bgColor="bg-blue-500"
                        textColor="text-blue-700"
                        borderColor="border-blue-500"
                        icon={"fa-solid:sign-in-alt"}
                        isLoading={isLoading}
                        link='/admin/master/perusahaan'
                    // link={userPermState && userPermState.includes('view_site_setting') ? '/admin/master/perusahaan' : ''}
                    />
                </div>
            }
            <div>
                <PerusahaanAtom
                    title="Perusahaan Mendaftar"
                    nilai={info && info.perusahaan_mendaftar}
                    description="Total Perusahaan"
                    sub_description="Mendaftar"
                    bgColor="bg-orange-400"
                    textColor="text-orange-600"
                    borderColor="border-orange-400"
                    icon={"fluent:building-32-filled"}
                    isLoading={isLoading}
                    link={(isSuperUser || ['Administrator', 'Operator'].some(r => listGroup.includes(r))) ? '/admin/wani/perusahaan-mendaftar' : null}
                // link = {userPermState && userPermState.includes('view_site_setting') ? '/admin/master/perusahaan' : ''}
                />
            </div>
            <div>
                <PerusahaanAtom
                    title="Peserta Lengkap"
                    nilai={info && info.peserta_complete}
                    description="Peserta"
                    sub_description="Dokumen Lengkap"
                    bgColor="bg-green-500"
                    textColor="text-green-600"
                    borderColor="border-green-500"
                    icon={"ic:outline-fact-check"}
                    isLoading={isLoading}
                    // link={`/admin/registrasi/data-registrasi-peserta?peserta_state=complete`}
                    link={`/admin/wani/peserta-complete`}
                />
            </div>
            <div>
                <PerusahaanAtom
                    title="Peserta Dikunci"
                    nilai={info && info.peserta_lock}
                    description="Peserta"
                    sub_description="Dikunci"
                    bgColor="bg-stone-400"
                    textColor="text-stone-700"
                    borderColor="border-stone-400"
                    icon={"mage:lock-fill"}
                    isLoading={isLoading}
                    // link={`/admin/registrasi/data-registrasi-peserta?is_lock=lock`}
                    link={`/admin/wani/peserta-complete`}
                />
            </div>
            <div>
                <PerusahaanAtom
                    title="Peserta Dapat Kamar Hotel"
                    nilai={info && info.hotel_peserta}
                    description="Peserta"
                    sub_description="Dapat Kamar Hotel"
                    bgColor="bg-violet-400"
                    textColor="text-violet-700"
                    borderColor="border-violet-400"
                    icon={"fluent:building-32-filled"}
                    isLoading={isLoading}
                    // link={`/admin/registrasi/data-registrasi-peserta?is_lock=lock`}
                    link={`/admin/registrasi/hotel-peserta`}
                />
            </div>
        </div>
    )
}
export default PerusahaanComp;