import { Fragment } from "react"
import AlertComp from "../../../../Components/Atoms/AlertComp";
import { useAgendaAdmin } from "./agendaAdminFunction";
import Card from "../../../../Components/Layout/Card";
import Tables from "../../../../Components/molecules/Tables";
import { Icon } from "@iconify/react/dist/iconify.js";
import PagePagination from "../../../../Components/Atoms/PagePagination";
import FormTextSingleComp from "../../../../Components/forms/FormTextSingleComp";
import Modal from "../../../../Components/Atoms/Modal";
import Button from "../../../../Components/Atoms/Button";


const AgendaAdminPage = () => {
    const { alertValue, handleCloseAlert, view, handleSubmit, handleCreate, handleCancel, handleChangeSearch, handleSearch,
        listData, pageValue, handleEdit, handleDelete, handlePaginate, formData, setFormData,
        modalValue, setModalValue, handleSubmitDelete, handleCloseModal,
    } = useAgendaAdmin();
    return(
        <Fragment>
            <AlertComp
                isOpen={alertValue.show}
                onClose={handleCloseAlert}
                text={alertValue.text}
                subText={alertValue.subText}
                color={alertValue.color}
                icon={alertValue.icon}
            />
            <Modal
                show={modalValue.show}
                setShow={() => {                        
                    setModalValue(modalValue => ({...modalValue, show: false}))
                }}
                width="xl"
                persistent={false}   
                btnClose={false}   
                title={modalValue.title}
                withHeader={true}
            >

                <div className='p-[20px]'>
                    { modalValue.text}
                </div>
                <div className='border-t border-t-gray-200 p-[20px]'>
                    <div className="flex flex-row justify-end gap-2">
                        <Button className='bg-red-500 text-white' onClick={handleSubmitDelete}>Hapus</Button>
                        <Button className='bg-gray-400 text-white' onClick={handleCloseModal}>Cancel</Button>
                    </div>
                </div>
            </Modal>
            <Card
                isCreate={view === 'create'}
                isSearch={true}
                className={'w-full'}
                handleCreate={handleCreate}
                handleCancel={handleCancel}
                handleSubmit={handleSubmit}
                onChangeSearch={handleChangeSearch}
                handleSearch={handleSearch}
            >
                { view === 'create' &&
                    <Fragment>
                        <div className='grid grid-cols-2'>
                            <div className="col-span-1">
                                <FormTextSingleComp
                                    {...formData.nama}
                                    formData={formData}
                                    setFormData={setFormData}
                                />
                                <div className="mt-5">
                                    <FormTextSingleComp
                                        {...formData.tanggal}
                                        formData={formData}
                                        setFormData={setFormData}
                                    />
                                </div>
                                <div className="mt-5">
                                    <FormTextSingleComp
                                        {...formData.start_time}
                                        formData={formData}
                                        setFormData={setFormData}
                                    />
                                </div>
                                <div className="mt-5">
                                    <FormTextSingleComp
                                        {...formData.end_time}
                                        formData={formData}
                                        setFormData={setFormData}
                                    />
                                </div>
                                <div className="mt-5">
                                    <FormTextSingleComp
                                        {...formData.lokasi}
                                        formData={formData}
                                        setFormData={setFormData}
                                    />
                                </div>
                                <div className="mt-5">
                                    <FormTextSingleComp
                                        {...formData.color_label}
                                        formData={formData}
                                        setFormData={setFormData}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='border-t border-t-gray-200 pt-[20px] mt-[80px]'>
                            <div className='text-[14px] font-bold'>Detail Acara</div>
                            <FormTextSingleComp
                                {...formData.description}
                                formData={formData}
                                setFormData={setFormData}
                                hideLabel={true}
                            />
                        </div>
                    </Fragment>
                }
                { view === 'list' &&
                    <Fragment>
                         <Tables className='border-separate rounded-sm'>
                            <Tables.Head className='bg-[#efeeee]'>
                                <tr>
                                    <Tables.Header className='font-bold text-[13px] py-[10px] text-right'>#</Tables.Header>                            
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>Agenda</Tables.Header>            
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>Tanggal</Tables.Header>
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>Mulai</Tables.Header>
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>Selesai</Tables.Header>
                                    <Tables.Header className='font-bold text-[13px] py-[10px] text-center'>Action</Tables.Header>
                                </tr>
                            </Tables.Head>
                            <Tables.Body>
                                { listData.data.length === 0 ?
                                    <Tables.Row>
                                        <Tables.Data colspan={4} className='text-center'>Belum ada data</Tables.Data>
                                    </Tables.Row>
                                    :                                    
                                    <Fragment>
                                        {listData.data.map((post, index) => {
                                            return (
                                                <Tables.Row key={index}>
                                                    <Tables.Data className='text-right'>{pageValue.offset + index + 1}</Tables.Data>
                                                    <Tables.Data>{post.nama}</Tables.Data>
                                                    <Tables.Data>{post.tanggal_display}</Tables.Data>
                                                    <Tables.Data>{post.start_time}</Tables.Data>
                                                    <Tables.Data>{post.end_time}</Tables.Data>
                                                    <Tables.Data className='text-center'>
                                                        <div className="flex flex-row justify-center gap-1">
                                                            <button onClick={() => handleDelete(post)} className='px-2 py-1 bg-red-500 text-white rounded'>
                                                                <Icon icon="ph:trash-fill" />
                                                            </button>
                                                            <button onClick={() => handleEdit(post)} className='px-2 py-1 bg-indigo-500 text-white rounded'>
                                                                <Icon icon="mage:edit" />
                                                            </button>
                                                        </div>
                                                    </Tables.Data>
                                                </Tables.Row>
                                            )
                                        })}
                                    </Fragment>
                                }
                            </Tables.Body> 
                        </Tables>
                        <PagePagination
                            data={pageValue.obj}
                            handlePagination={handlePaginate}
                            page={pageValue.page}
                            lastPage={pageValue.lastPage}
                        />
                    </Fragment>
                }
            </Card>
        </Fragment>
    )
}
export default AgendaAdminPage;