import { Fragment } from "react"
import Card from "../../../../Components/Layout/Card";
import Tables from "../../../../Components/molecules/Tables";
import { Icon } from "@iconify/react/dist/iconify.js";
import Modal from "../../../../Components/Atoms/Modal";
import Button from "../../../../Components/Atoms/Button";
import FormTextSingleComp from "../../../../Components/forms/FormTextSingleComp";
import { usePerusahaan } from "./perusahaanFunction";
import AlertComp from "../../../../Components/Atoms/AlertComp";
import PagePagination from "../../../../Components/Atoms/PagePagination";
import Tooltip from "../../../../Components/Atoms/Tooltip";


const PerusahaanPage = () => {
    const { listData, pageValue,
        handleDelete, handleSubmit, modalValue, 
        setModalValue, handleSubmitDelete, handleCloseModal,
        handleCancel, handleCreate, view, formData, handleEdit,
        handleChange, handleBlur, aktaUrl, alertValue, handleCloseAlert,
        handlePaginate, handleChangeSearch, handleSearch, handleImport,
        setFormData, handleResetPerusahaan, handleSubmitResetPerusahaan
    } = usePerusahaan();

    const ChildButton = () => {
        return(
            <Button color="success" onClick={handleImport}>Import</Button>
        )
    }
    return(
        <Fragment>
            <AlertComp
                isOpen={alertValue.show}
                onClose={handleCloseAlert}
                text={alertValue.text}
                subText={alertValue.subText}
                color={alertValue.color}
                icon={alertValue.icon}
            />
            <Modal
                    show={modalValue.show}
                    setShow={() => {                        
                        setModalValue(modalValue => ({...modalValue, show: false}))
                    }}
                    width="xl"
                    persistent={false}   
                    btnClose={false}   
                    title="Konfirmasi"
                    withHeader={true}
                >

                    <div className='p-[20px]'>
                        { modalValue.text}
                    </div>
                    <div className='border-t border-t-gray-200 p-[20px]'>
                        <div className="flex flex-row justify-end gap-2">
                            <Button className='bg-red-500 text-white' onClick={() => modalValue.tipe === 'reset' ? handleSubmitResetPerusahaan() : handleSubmitDelete()}>Hapus</Button>
                            <Button className='bg-gray-400 text-white' onClick={handleCloseModal}>Cancel</Button>
                        </div>
                    </div>
            </Modal>
            <Card
                title="Perusahaan"
                isCreate={view === 'create'}
                isSearch={true}
                handleCreate={handleCreate}
                searchValue={pageValue.search}
                handleSubmit={handleSubmit}
                handleCancel={handleCancel}
                onChangeSearch={handleChangeSearch}
                handleSearch={handleSearch}
                ChildButton={ChildButton}
            >
                { view === 'create' &&
                    
                    <div className='grid grid-cols-2'>
                        <div className="col-span-1 pb-6">
                            <FormTextSingleComp
                                {...formData.nama}
                                onChange={handleChange}
                                onBlur={handleBlur}                                
                            />
                            <div className="mt-5">
                                <FormTextSingleComp
                                    {...formData.provinsi}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </div>
                            <div className="mt-5">
                                <FormTextSingleComp
                                    {...formData.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </div>
                            <div className="mt-5">
                                <FormTextSingleComp
                                    {...formData.is_takeout}
                                    formData={formData}
                                    setFormData={setFormData}
                                />
                            </div>
                        </div>
                        {aktaUrl && 
                            <div className='col-span-2 border-t border-t-gray-300'>
                                <div className='font-bold text-[15px] mt-[20px] mb-[10px]'>Akta</div>
                                <iframe 
                                    src={aktaUrl}
                                    width="100%"
                                    height="400px"
                                    title="akta"
                                ></iframe>
                            </div>
                        }
                    </div>
                }
                { view === 'list' &&
                    <Fragment>
                        <Tables className='border-separate rounded-sm'>
                            <Tables.Head className='bg-[#efeeee]'>
                                <tr>
                                    <Tables.Header className='font-bold text-[13px] py-[10px] text-right'>#</Tables.Header>                            
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>Nama</Tables.Header>            
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>Provinsi</Tables.Header>
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>Email</Tables.Header>
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>Bisa Mendaftar</Tables.Header>
                                    <Tables.Header className='font-bold text-[13px] py-[10px] text-center'>Action</Tables.Header>
                                </tr>
                            </Tables.Head>
                            <Tables.Body>
                                { listData.length === 0 ?
                                    <Tables.Row>
                                        <Tables.Data colspan={6} className='text-center'>Belum ada data</Tables.Data>
                                    </Tables.Row>
                                    :
                                    <Fragment>
                                        {listData.map((post, index) => {
                                            return (
                                                <Tables.Row key={index}>
                                                    <Tables.Data className='text-right'>{pageValue.offset + index + 1}</Tables.Data>
                                                    <Tables.Data>{post.nama}</Tables.Data>
                                                    <Tables.Data>{post.provinsi}</Tables.Data>
                                                    <Tables.Data>{post.email}</Tables.Data>
                                                    <Tables.Data>{post.is_takeout ? "Tidak" : "Ya"}</Tables.Data>
                                                    <Tables.Data className='text-center'>
                                                        <div className="flex flex-row justify-center gap-1">
                                                            <Tooltip tooltip="Delete">
                                                                <button onClick={() => handleDelete(post)} className='px-2 py-1 bg-red-500 text-white rounded'>
                                                                    <Icon icon="ph:trash-fill" />
                                                                </button>
                                                            </Tooltip>
                                                            <Tooltip tooltip="Edit">
                                                                <button onClick={() => handleEdit(post)} className='px-2 py-1 bg-indigo-500 text-white rounded'>
                                                                    <Icon icon="mage:edit" />
                                                                </button>
                                                            </Tooltip>
                                                            <Tooltip tooltip="Reset Pendaftaran Perusahaan">
                                                                <button onClick={() => handleResetPerusahaan(post)} className='px-2 py-1 bg-orange-500 text-white rounded'>
                                                                    <Icon icon="material-symbols:reset-shadow-outline-rounded" />
                                                                </button>
                                                            </Tooltip>
                                                        </div>
                                                    </Tables.Data>
                                                </Tables.Row>
                                            )
                                        })}
                                    </Fragment>
                                }
                            </Tables.Body>
                        </Tables>
                        <div className='mt-5 flex flex-row justify-start w-full'>
                            <PagePagination
                                data={pageValue.obj}
                                handlePagination={handlePaginate}
                                page={pageValue.page}
                                lastPage={pageValue.lastPage}
                            />
                        </div>
                    </Fragment>
                }
            </Card>
        </Fragment>
    )
}
export default PerusahaanPage;