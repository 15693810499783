import { useCallback, useContext, useEffect, useState } from "react";
import { useGlobalFunction } from "../../../generalFunction";
import config from "../../../config";
import api from "../../../api";
import { useNavigate } from "react-router-dom";
import Resizer from "react-image-file-resizer";
import axios from "axios";
import { UserContext } from "../../../context/UserContext";

export function useEditDataPeserta(){
    const { isLoading, setIsLoading, setAlertValue, catchFetching, getColor, alertValue, handleCloseAlert } = useGlobalFunction();
    const navigate = useNavigate();
    const [info, setInfo] = useState(null);
    const myState = useContext(UserContext);
	const [isSuperUser, setIsSuperUser] = myState.isSuperUser
    const [formData, setFormData] = useState({
        id : {
            name : 'id',
            label : 'ID',
            value : '0',
            type : 'text',
            col : 2,
            required : false,
            show : false,
            errorMsg : '',
            showError : false,
        },
        akta : {
            name : 'akta',
            label : 'Akta',
            value : '',
            type : 'dokumen',
            col : 2,
            required : true,
            show : true,
            errorMsg : '',
            showError : false,
            file : null,
            file_label : '',
            status : 0,
            is_lock: false,
            file_helper : "pdf",
            placeholder : 'Akta (Format PDF)'
        },
        nama : {
            name : 'nama',
            label : 'Nama Peserta',
            value : '',
            type : 'text',
            col : 2,
            required : true,
            show : true,
            errorMsg : '',
            showError : false,
            is_lock: false,
            placeholder: 'Nama lengkap sesuai KTP / SIM / Paspor'
        },        
        email : {
            name : 'email',
            label : 'Email',
            value : '',
            type : 'text',
            col : 2,
            required : true,
            show : true,
            errorMsg : '',
            showError : false,
            is_lock: false,
            email : 'Email'
        },        
        jabatan : {
            name : 'jabatan',
            label : 'Jabatan',
            value : '',
            type : 'select',
            obj : [
                {value : '', label : '-- Pilih Jabatan --'},
                {value : '1', label : 'Direksi'},
                {value : '2', label : 'Non Direksi'},
            ],
            col : 2,
            required : true,
            show : true,
            errorMsg : '',
            showError : false,
            is_lock: false,
        },
        hp : {
            name : 'hp',
            label : 'HP',
            value : '',
            type : 'number',
            inputType : 'hp',
            col : 2,
            required : true,
            show : true,
            errorMsg : '',
            showError : false,
            is_lock: false,
            placeholder : 'HP'
        },
        surat_pernyataan : {
            name : 'surat_pernyataan',
            label : 'Surat Pernyataan',
            value : '',
            type : 'dokumen',
            col : 2,
            required : true,
            show : true,
            errorMsg : '',
            showError : false,
            file : null,
            file_label : '',
            status : 0,
            is_lock: false,
            file_helper : "pdf",
            placeholder : 'Surat Pernyataan format PDF',
        },        
        foto : {
            name : 'foto',
            label : 'Pas Foto',
            value : '',
            type : 'image',
            col : 2,
            required : true,
            show : true,
            errorMsg : '',
            showError : false,
            file : null,
            is_lock: false,
            file_helper : "jpg, jpeg, png",
            showImage : false,
            // placeholder : 'KTP'
        },
        is_edit : {
            name : 'is_edit',
            label : 'Pas Foto',
            value : false,
            type : 'text',
            col : 2,
            required : false,
            show : false,
            errorMsg : '',
            showError : false,
            file : null,
            is_lock: false,
        },
        jabatan_display : {
            name : 'jabatan_display',
            value : '',
            type : 'text',
            show : true,
            label : 'Jabatan',            
            errorMsg : '',
            showError : false,
            required : false,      
            is_lock: false,      
        },
    })
    const [perusahaanInfo, setPerusahaanInfo] = useState(null);
    const [downloadFileUrl, setDownloadFileUrl] = useState({
        suratPernyataan : null,
        suratKuasa : null,
    }) 

    const fetchDownloadFile = useCallback(async() => {
        setIsLoading(true)
        try{
            let params = {
                // offset : offset,
                // limit : limit,
                requestor : 'member'
            }
            const res = await axios.get(`${config.apiBaseUrl[process.env.NODE_ENV]}${config.endPoint.fileUploadWeb}`, {params: params}).then(res => res.data);
            
            // let params = {
            //     'is_show' : 'yes'
            // }
            // const res = await api.get(`${config.endPoint.fileUploadMember}`, {params: params}).then(res => res.data);
            if (res){
                let suratPernyataan = null;
                let suratKuasa = null;
                res.results.map(post => {
                    if (post.type_file === 1){
                        suratPernyataan = post.path
                    }else if (post.type_file === 2){
                        suratKuasa = post.path
                    }
                    return true;
                })
                setDownloadFileUrl(downloadFileUrl => ({...downloadFileUrl,
                    suratPernyataan : suratPernyataan,
                    suratKuasa : suratKuasa
                }))
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            let status = error && error.response && error.response.status ? error.response.status : '';

            let msg = 'Unable to save Data';
            let { response } = error;            
            
            catchFetching(error, 'member')
            if (status && status === 401) {                
                return navigate('/signup')
            } else {
                if (response && response.data && response.data.message){
                    msg = response.data.message
                }
                    setAlertValue(alertValue => ({ ...alertValue, show: true, text : 'Error', subText: msg, color: 'danger' }));
                    setTimeout(() => {
                        setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue)
            }
        }
        // eslint-disable-next-line
    },[setAlertValue, setIsLoading])

    

    useEffect(() => {        
        fetchDownloadFile();
    },[fetchDownloadFile])


    const fetchMemberInfo = useCallback(async(myIsSuperUSer='') => {
        setIsLoading(true);
        try{
            const res = await api.get(`${config.endPoint.getMemberInfo}`).then(res => res.data);
            let obj = {}
            obj = {...formData}
            
            
            if (res && res.perusahaan_info){
                setPerusahaanInfo(res.perusahaan_info);
                obj['akta'] = {...obj['akta'],

                    file : res.perusahaan_info.akta_url || '', 
                    file_label :  res.perusahaan_info.akta_name,
                    value :  res.perusahaan_info.status_akta === 1 ? res.perusahaan_info.akta_url : '',
                    status : res.perusahaan_info.status_akta,                
                    is_lock : [1,4].includes(res.perusahaan_info.status_akta) ? true : false,
                    required : res.perusahaan_info?.akta_name ? false : true,                    
                }
            }
            if (res && res.peserta_info){
                obj['id'] = {...obj['id'], value : res.peserta_info.id}                
                obj['nama'] = {...obj['nama'], value : res.peserta_info.nama}
                obj['foto'] = {...obj['foto'], file : res.peserta_info.foto, value : res.peserta_info.foto, is_lock : res.peserta_info.is_lock }
                obj['email'] = {...obj['email'], value : res.peserta_info.email}
                obj['hp'] = {...obj['hp'], value : res.peserta_info.hp}
                obj['jabatan'] = {...obj['jabatan'], value : res.peserta_info.jabatan}
                obj['surat_pernyataan'] = {...obj['surat_pernyataan'], 
                    file : res.peserta_info.surat_pernyataan || '', 
                    file_label : res.peserta_info.jabatan === 2 ? res.peserta_info.surat_kuasa_name : res.peserta_info.surat_pernyataan_name,
                    value : res.peserta_info.jabatan === 2 ? res.peserta_info.surat_kuasa ? res.peserta_info.surat_kuasa : '' : res.peserta_info.surat_pernyataan ? res.peserta_info.surat_pernyataan : '',
                    label : res.peserta_info.jabatan === 2 ? 'Surat Kuasa' : 'Surat Pernyataan',
                    status : res.peserta_info.jabatan === 2 ? res.peserta_info.status_surat_kuasa ? res.peserta_info.status_surat_kuasa : 0 : res.peserta_info.status_surat_pernyataan ? res.peserta_info.status_surat_pernyataan : 0,
                    is_lock : res.peserta_info.is_lock,
                }
                obj['is_edit'] = {...obj['is_edit'], value : true}
                obj['jabatan_display'] = {...obj['jabatan_display'], value : res.peserta_info.jabatan_display}

                setInfo(res.peserta_info)
                
            }
            
            setIsLoading(false);
            setFormData(obj);
            
        }catch(error){
            setIsLoading(false);
            let status = error && error.response && error.response.status ? error.response.status : '';

            let msg = 'Unable to save Data';
            let { response } = error;            
            
            // catchFetching(error, 'member')
            if (status && status === 401) {     
                // console.log('isSuperuser catch <<<<<<<<<<<<<< >>>', isSuperUser)
                if (!myIsSuperUSer){
                    // console.log('isSuperuser catch >>>', isSuperUser)
                    return navigate('/member/login')
                }         
            } else {
                if (response && response.data && response.data.message){
                    msg = response.data.message
                }
                setAlertValue(alertValue => ({ ...alertValue, show: true, text : 'Error', subText: msg, color: 'danger' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue)
            }
        } 
    
        // eslint-disable-next-line
    },[setIsLoading, setAlertValue,isSuperUser])

    useEffect(() => {
        if (isSuperUser !== ''){
            fetchMemberInfo(isSuperUser)
        }
    },[fetchMemberInfo, isSuperUser])
    

    const resizeFile = (file) => new Promise(resolve => {
        Resizer.imageFileResizer(file, 800, 800, 'JPEG', 100, 0,
        uri => {
          resolve(uri);
        }, 'file' );
    });


    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'jabatan'){
            if ([1,'1'].includes(value)){                
                setFormData(formData => ({
                    ...formData,
                    surat_pernyataan: { ...formData.surat_pernyataan,  label : 'Surat Pernyataan' },
                    // ktp_kuasa : {...formData.ktp_kuasa, required : false}
                }))
            }else{
                setFormData(formData => ({
                    ...formData,
                    surat_pernyataan: { ...formData.surat_pernyataan,  label : 'Surat Kuasa' },
                    // ktp_kuasa : {...formData.ktp_kuasa, required : true}
                }))                
            }
        }
    }

    const handleSubmit = async() => {
        setIsLoading(true);
        try{            
            let tmpForm = [];
            let countError = 0;
            let obj  ={...formData}
            let tmp = {};
            Object.entries(obj).map(([index, post]) => {
                if (post.required && [null, ''].includes(post.value)){
                    post.showError = true;
                    countError++;
                      
                }else{
                    post.showError = false;
                    if (['file', 'image', 'dokumen'].includes(post.type)){
                        tmp[index] = post.file
                    }else{
                        tmp[index] = post.value ? post.value : '';
                    }
                }
                return true;
            })       
            tmpForm.push(tmp);         
                        
            

            if (countError > 0){
                setFormData(obj);
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Error', subText: "Lengkapi data", color: 'danger' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue)
                setIsLoading(false);
                return
            }       
            
            
            
            // return
            let newForm = new FormData();            
            if (tmpForm.length > 0 && tmpForm[0].foto ){                
                if (! tmpForm[0].foto.toString().includes('http')){
                    console.log('ada apa yaa >>>')
                    const image = await resizeFile(tmpForm[0].foto);
                    newForm.append(`foto[0]`, image)                                
                }
            }
                        
            tmpForm.map((post, index) => {                                
                newForm.append(`surat_pernyataan[${index}]`, post.surat_pernyataan)
                newForm.append(`akta[${index}]`, post.akta)
                newForm.append(`foto[${index}]`, post.foto)                                            
                return true;
            })
            newForm.append('formData', JSON.stringify(tmpForm))            
            
            
            const res = await api.post(`${config.endPoint.addPesertaByMember}`, newForm).then(res => res.data);
            if (res){
                // fetchMemberInfo();
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Success', subText: 'Data tersimpan, redirect ke member menu dalam 3 detik', color: 'success' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue)
                setTimeout(() => {
                    setIsLoading(false);
                    navigate('/member')
                }, 3000);
            }
            // const res = await api.post(`${config.endPoint.addPesertaByMember}`, tmpForm).then(res => res.data);            
        }catch(error){
            setIsLoading(false);
            let status = error && error.response && error.response.status ? error.response.status : '';

            let msg = 'Unable to save Data';
            let { response } = error;            
            
            catchFetching(error, 'member')
            if (status && status === 401) {                
                return navigate('/member/login')
            } else {
                if (response && response.data && response.data.message){
                msg = response.data.message
            }
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Error', subText: msg, color: 'danger' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue)
            }
        }
    }

    const handleDownload = (params) => {
        const link = document.createElement('a');
        link.href = params;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    }

    return {
        isLoading, setIsLoading, formData, setFormData, handleChange, downloadFileUrl, info, getColor, navigate,
        handleSubmit, alertValue, handleCloseAlert, perusahaanInfo, handleDownload, isSuperUser, setIsSuperUser
    }
}