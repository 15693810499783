import config from "../../../../config"
import GoogleMapReact from 'google-map-react';
import { Icon } from "@iconify/react/dist/iconify.js";
import { useLokasi } from "../../../HomeNew/LokasiPage/lokasiFunction";
import Loading from "../../../../Components/Atoms/Loading";


const LocationPage = ({myRef, dataPage}) => {
    const { info, defaultProps, isReady, handleShare } = useLokasi();    

    return(
        <div
            className='page absolute duration-1000 origin-left transition transform  
                w-calendar h-[calc(100%-100px)]  rounded-[30px] bg-white shadow-lg flex flex-col justify-center items-center
            '
            data-page={dataPage}
            ref = {myRef}
        >
            <div className='front absolute front w-full h-full flex flex-col  text-black p-[25px]'>
                <div className="w-full text-center font-bold text-3xl my-5 mt-marginTopLogin">
                    Lokasi Acara
                </div>
                {/* <div className='text-[#A1A1A1] text-xl mb-[40px] leading-3 text-center'>Tempat Berlangsungnya Acara Musyawarah Nasional APJII 2024</div> */}
                <div className='w-full h-full'> 
                    {isReady ? (
                        <GoogleMapReact
                        bootstrapURLKeys={{ key: config.googleMapKey }}
                        defaultCenter={defaultProps.center}
                        yesIWantToUseGoogleMapApiInternals
                        defaultZoom={defaultProps.zoom}
                        on
                        >
                        <div
                            className="rounded-full -top-[20px] -left-[20px] absolute"
                            lat={info?.lat}
                            lng={info?.lon}
                        >
                            <Icon
                            icon="carbon:location-filled"
                            text="Hotel"
                            fontSize={"35"}
                            color="red"
                            onClick={() => handleShare()}
                            />
                        </div>
                        </GoogleMapReact>
                    ) : (
                        <div className="flex flex-row justify-center items-center">
                            <Loading />
                        </div>
                    )}
                </div>

            </div>
        </div>
    )
}
export default LocationPage;