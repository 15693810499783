import { useCallback, useEffect, useState } from "react";
import { useGlobalFunction } from "../../../../generalFunction";
import config from "../../../../config";
import api from "../../../../api";
import { useNavigate } from "react-router-dom";

export function useAgenda() {
  const {
    isLoading,
    setIsLoading,
    setAlertValue,
    getColor,
    alertValue,
    handleCloseAlert,
  } = useGlobalFunction();
  const navigate = useNavigate();
  const [dataAgenda, setDataAgenda] = useState([]);

  const fetchAgenda = useCallback(async () => {
    setIsLoading(true);
    try {
      const params = {
        limit: 3,
      };
      const res = await api
        .get(`${config.endPoint.agendaAcaraLogin}`, { params: params })
        .then((res) => res.data);
      
      setDataAgenda(res.results);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      let msg = "Unable to save Data";
      let { response } = error;
      if (response && response.data && response.data.message) {
        msg = response.data.message;
      }
      setAlertValue((alertValue) => ({
        ...alertValue,
        show: true,
        text: "Error",
        subText: msg,
        color: "danger",
      }));
      setTimeout(() => {
        setAlertValue((alertValue) => ({ ...alertValue, show: false }));
      }, config.timeOutValue);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchAgenda();
  }, [fetchAgenda]);

  return {
    setDataAgenda,
    dataAgenda,
    isLoading,
    setIsLoading,
    getColor,
    navigate,
    alertValue,
    handleCloseAlert,
  };
}
