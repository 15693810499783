import { Icon } from "@iconify/react/dist/iconify.js";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import config from "../../config";

const SidebarMember = () => {
  const cookies = new Cookies();
  const navigate = useNavigate();

  const handleLogOut = () => {
    cookies.remove(config.cookiesName, { path: "/" });
    navigate("/member/login");
  };
  const location = useLocation();

  return (
    <div className="shadow border border-slate-100 rounded-xl bg-white">
      <Link
        to="/member"
        className={`flex flex-row gap-5 items-center p-5 border-b border-b-gray-200 ${
          location.pathname === "/member" ? "bg-slate-200" : ""
        }`}
      >
        <div>
          <Icon fontSize="18" icon="heroicons-outline:menu-alt-2" />
        </div>
        <div>Member Menu</div>
      </Link>
      <Link
        to="/member/peserta"
        className={`flex flex-row gap-5 items-center px-5 py-3 mt-5 ${
          location.pathname === "/member/peserta" ? "bg-slate-200" : ""
        }`}
      >
        <div>
          <Icon fontSize="18" icon="ph:user-bold" />
        </div>
        <div>Edit Data Peserta</div>
      </Link>
      <Link
        to="/member/download"
        className={`flex flex-row gap-5 items-center px-5 py-3 ${
          location.pathname === "/member/download" ? "bg-slate-200" : ""
        }`}
      >
        <div>
          <Icon fontSize="18" icon="mingcute:download-3-line" />
        </div>
        <div>Download</div>
      </Link>
      <Link
        to="/member/calon-dewas"
        className={`flex flex-row gap-5 items-center px-5 py-3 ${
          location.pathname === "/member/calon-dewas" ? "bg-slate-200" : ""
        }`}
      >
        <div>
          <Icon fontSize="18" icon="raphael:users" />
        </div>
        <div>Daftar Calon Dewan Pengawas</div>
      </Link>
      <Link
        to="/member/calon-ketum"
        className={`flex flex-row gap-5 items-center px-5 py-3 ${
          location.pathname === "/member/calon-ketum" ? "bg-slate-200" : ""
        }`}
      >
        <div>
          <Icon fontSize="18" icon="entypo:users" />
        </div>
        <div>Daftar Calon Ketua Umum</div>
      </Link>
      <Link
        to="/member/change-password"
        className={`flex flex-row gap-5 items-center px-5 py-3 ${
          location.pathname === "/member/change-password" ? "bg-slate-200" : ""
        }`}
      >
        <div>
          <Icon fontSize="18" icon="fluent:key-20-regular" />
        </div>
        <div>Change Password</div>
      </Link>
      <button
        className={`flex flex-row gap-5 items-center px-5 py-3 mb-5 ${
          location.pathname === "/member/logout" ? "bg-slate-200" : ""
        }`}
        onClick={handleLogOut}
      >
        <div>
          <Icon fontSize="18" icon="material-symbols:logout-rounded" />
        </div>
        <div>Logout</div>
      </button>
    </div>
  );
};
export default SidebarMember;
