import { Fragment, useContext } from "react"
import AlertComp from "../../../../Components/Atoms/AlertComp";
import { useHotelPeserta } from "./hotelPesertaFunction";
import Card from "../../../../Components/Layout/Card";
import Tables from "../../../../Components/molecules/Tables";
import { Icon } from "@iconify/react/dist/iconify.js";
import FormTextSingleComp from "../../../../Components/forms/FormTextSingleComp";
import Search from "../../../../Components/Atoms/Search";
import PagePagination from "../../../../Components/Atoms/PagePagination";
import { UserContext } from "../../../../context/UserContext";
import Modal from "../../../../Components/Atoms/Modal";
import Button from "../../../../Components/Atoms/Button";


const HotelPesertaPage = () => {
    const { alertValue, handleCloseAlert, listData, pageValue, 
        // handleDelete,
        handleChangeSearch, handleSearch,  
        handleDownload, handlePaginate, isLoading, modalValue, setModalValue, handleSubmitDelete, handleCloseModal
    } = useHotelPeserta();
    const myState = useContext(UserContext);
    const [myLoading] = myState.myLoading
    return(
        <Fragment>
            <AlertComp
                text={alertValue.text}
                subText={alertValue.subText}
                color={alertValue.color}
                isOpen={alertValue.show}
                onClose={handleCloseAlert}
            />
            <Modal
                show={modalValue.show}
                setShow={() => {
                    setModalValue(modalValue => ({...modalValue, show: false}))
                }}
                width='xl'
                persistent={false}
                btnClose={false}
            >
                <div className='py-[20px] px-[30px] border-b border-b-gray-200 font-bold'>
                    {modalValue.title}
                </div>
                <div className='py-[50px] px-[30px]'>
                    { modalValue.text }
                </div>
                <div className='py-[20px] px-[30px] border-t border-t-gray-200 flex flex-row justify-end gap-2'>
                    <Button color='danger' size='md' onClick={handleSubmitDelete}>Hapus</Button>
                    <Button color='base' size='md' onClick={handleCloseModal}>Cancel</Button>
                </div>
            </Modal>
            <Card
                isCreate={false}
                isSearch={false}
                className={'w-full'}
            >
                <div className='flex flex-row justify-between gap-3 w-full mb-5'>
                    <div className='flex flex-col md:flex-row w-full gap-3 justify-between'>
                        <div className='w-full'>
                            <FormTextSingleComp
                                name={"hotel"}
                                type="select"
                                obj={pageValue.hotel_obj}
                                show={true}
                                value={pageValue.hotel}
                                hideLabel={true}
                                onChange={handleChangeSearch}
                                gridClass='-mt-0 -mb-0'
                            />        
                        </div>
                        <div className='w-full'>
                            <Search
                                placeholder={"Cari Perusahaan atau Nama Peserta"}
                                onChange={(value) => handleChangeSearch(value)}
                                handleSearch={handleSearch}
                                value={pageValue.search}
                            />    
                        </div>
                    </div>
                    <div>
                        <div className="flex flex-row justify-end items-end flex-1">
                            <button disabled={isLoading} className='border text-green-600 border-green-600 bg-white px-3 py-2 rounded-md flex flex-row  w-full items-center justify-center gap-2' onClick={() => handleDownload()}>                                
                                <Icon icon={isLoading ? "line-md:loading-twotone-loop" :  "vscode-icons:file-type-excel"} /> Download
                            </button>
                        </div>
                    </div>
                </div>
                <Tables className='border-separate rounded-sm'>
                    <Tables.Head className='bg-[#efeeee]'>
                        <tr>
                            <Tables.Header className='font-bold text-[13px] py-[10px] text-right'>#</Tables.Header>                            
                            <Tables.Header className='font-bold text-[13px] py-[10px]'>Hotel</Tables.Header>            
                            <Tables.Header className='font-bold text-[13px] py-[10px]'>Perusahaan</Tables.Header>
                            <Tables.Header className='font-bold text-[13px] py-[10px]'>Peserta</Tables.Header>
                            {/* <Tables.Header className='font-bold text-[13px] py-[10px]'>Action</Tables.Header> */}
                        </tr>
                    </Tables.Head>
                    <Tables.Body>
                        { myLoading && Array(16).fill(0).map((_, i) => {
                            return(
                                <Tables.Row className='animate-pulse' key={i}>
                                    <Tables.Data className='text-right' style={{textAlign : 'right'}}>
                                        <div className='h-4 w-[10px] bg-slate-200 rounded text-right'></div>                                    
                                    </Tables.Data>
                                    <Tables.Data>
                                        <div className='h-4 bg-slate-200 rounded'></div>
                                    </Tables.Data>
                                    <Tables.Data>
                                        <div className='h-4 bg-slate-200 rounded'></div>
                                    </Tables.Data>
                                    <Tables.Data>
                                        <div className='h-4 bg-slate-200 rounded'></div>
                                    </Tables.Data>
                                    <Tables.Data>
                                        <div className='h-4 bg-slate-200 rounded'></div>
                                    </Tables.Data>
                                </Tables.Row>
                            )
                        })}
                        <Fragment>
                            {listData.map((post, index) => {
                                return (
                                    <Tables.Row key={index}>
                                        <Tables.Data className='text-right'>{pageValue.offset + index + 1}</Tables.Data>
                                        <Tables.Data>{post.hotel_display}</Tables.Data>
                                        <Tables.Data>{post.perusahaan_display}</Tables.Data>
                                        <Tables.Data>{post.peserta_display}</Tables.Data>
                                        {/* <Tables.Data>
                                            <button className='p-[8px] bg-red-100 text-red-600 rounded shadow-lg text-[18px]'  onClick={() => handleDelete(post)}><Icon  icon='ph:trash-fill' /></button>
                                        </Tables.Data> */}
                                    </Tables.Row>
                                )
                            })}
                        </Fragment>
                    </Tables.Body> 
                </Tables>
                <div className="mt-5">
                    <PagePagination
                        data={pageValue.obj}
                        handlePagination={handlePaginate}
                        page={pageValue.page}
                        lastPage={pageValue.lastPage}
                    />
                </div>
            </Card>
        </Fragment>
    )
}
export default HotelPesertaPage;