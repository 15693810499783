import { Fragment } from "react"
import Card from "../../../../Components/Layout/Card";
import Tables from "../../../../Components/molecules/Tables";
import { Icon } from "@iconify/react/dist/iconify.js";
import Modal from "../../../../Components/Atoms/Modal";
import Button from "../../../../Components/Atoms/Button";
import FormTextSingleComp from "../../../../Components/forms/FormTextSingleComp";
import { useSponsorShip } from "./sponsorShipFunction";
import AlertComp from "../../../../Components/Atoms/AlertComp";
import PagePagination from "../../../../Components/Atoms/PagePagination";


const SponsorShipPage = () => {
    const { listData, pageValue,
        handleDelete, handleSubmit, modalValue, 
        setModalValue, handleSubmitDelete, handleCloseModal,
        handleCancel, handleCreate, view, formData, setFormData,
        alertValue, handleCloseAlert, handlePaginate, handleChangeSearch, handleSearch,
        handleEdit
    } = useSponsorShip();

    return(
        <Fragment>
            <AlertComp
                isOpen={alertValue.show}
                onClose={handleCloseAlert}
                text={alertValue.text}
                subText={alertValue.subText}
                color={alertValue.color}
                icon={alertValue.icon}
            />
            <Modal
                show={modalValue.show}
                setShow={() => {                        
                    setModalValue(modalValue => ({...modalValue, show: false}))
                }}
                width="xl"
                persistent={false}   
                btnClose={false}   
                title={modalValue.title}
                withHeader={true}
            >

                <div className='p-[20px]'>
                    { modalValue.text}
                </div>
                <div className='border-t border-t-gray-200 p-[20px]'>
                    <div className="flex flex-row justify-end gap-2">
                        <Button className='bg-red-500 text-white' onClick={handleSubmitDelete}>Hapus</Button>
                        <Button className='bg-gray-400 text-white' onClick={handleCloseModal}>Cancel</Button>
                    </div>
                </div>
            </Modal>
            <Card
                title="Hotel"
                isCreate={view === 'create'}
                isSearch={true}
                handleCreate={handleCreate}
                searchValue={pageValue.search}
                handleSubmit={handleSubmit}
                handleCancel={handleCancel}
                onChangeSearch={handleChangeSearch}
                handleSearch={handleSearch}
            >
                { view === 'create' &&
                    <div className='grid grid-cols-2'>
                        <div className="col-span-1">
                            {Object.entries(formData).map(([index, post]) => {
                                if (post.show) {
                                    return(
                                        <div key={index} className='mb-5'>
                                            <FormTextSingleComp
                                                {...post}
                                                formData={formData}
                                                setFormData={setFormData}
                                            />
                                        </div>
                                    )
                                }
                                return true;
                            })}
                        </div>
                    </div>
                }
                { view === 'list' &&
                    <Fragment>
                        <Tables className='border-separate rounded-sm'>
                            <Tables.Head className='bg-[#efeeee]'>
                                <tr>
                                    <Tables.Header className='font-bold text-[13px] py-[10px] text-right'>#</Tables.Header>                            
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>Kategori</Tables.Header>            
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>Nama</Tables.Header>            
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>Logo</Tables.Header>            
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>Prioritas</Tables.Header>
                                    <Tables.Header className='font-bold text-[13px] py-[10px] text-center'>Action</Tables.Header>
                                </tr>
                            </Tables.Head>
                            <Tables.Body>
                                { listData.length === 0 ?
                                    <Tables.Row>
                                        <Tables.Data colspan={6} className='text-center'>Belum ada data</Tables.Data>
                                    </Tables.Row>
                                    :
                                    <Fragment>
                                        {listData.map((post, index) => {
                                            return (
                                                <Tables.Row key={index}>
                                                    <Tables.Data className='text-right'>{pageValue.offset + index + 1}</Tables.Data>
                                                    <Tables.Data>{post.kategori_display}</Tables.Data>                                                    
                                                    <Tables.Data>{post.nama}</Tables.Data>           
                                                    <Tables.Data className="flex">
                                                        <img src={post.logo} alt="img" className="w-24"/>
                                                    </Tables.Data>                                         
                                                    <Tables.Data>{post.prioritas}</Tables.Data>
                                                    <Tables.Data className='text-center'>
                                                        <div className="flex flex-row justify-center gap-1">
                                                            <button onClick={() => handleDelete(post)} className='px-2 py-1 bg-red-500 text-white rounded'>
                                                                <Icon icon="ph:trash-fill" />
                                                            </button>
                                                            <button onClick={() => handleEdit(post)} className='px-2 py-1 bg-indigo-500 text-white rounded'>
                                                                <Icon icon="mage:edit" />
                                                            </button>
                                                        </div>
                                                    </Tables.Data>
                                                </Tables.Row>
                                            )
                                        })}
                                    </Fragment>
                                }
                            </Tables.Body>
                        </Tables>
                        <div className='mt-5 flex flex-row justify-start w-full'>
                            <PagePagination
                                data={pageValue.obj}
                                handlePagination={handlePaginate}
                                page={pageValue.page}
                                lastPage={pageValue.lastPage}
                            />
                        </div>
                    </Fragment>
                }
            </Card>
        </Fragment>
    )
}
export default SponsorShipPage