import { Fragment } from "react";
import Button from "../../../../Components/Atoms/Button";
import Modal from "../../../../Components/Atoms/Modal";
import FormTextSingleComp from "../../../../Components/forms/FormTextSingleComp";
import Card from "../../../../Components/Layout/Card";
import Tables from "../../../../Components/molecules/Tables";
import { useMasterTps } from "./tpsFunction"
import PagePagination from "../../../../Components/Atoms/PagePagination";
import Tooltip from "../../../../Components/Atoms/Tooltip";
import { Icon } from "@iconify/react/dist/iconify.js";


const TpsPage = () => {
    const { view, pageValue, handleCreate, handleCancel, handleSubmit, handleChangeSearch, handleSearch,
        modalValue, handleCloseModal, setModalValue, formData, setFormData, 
        handleDelete, handleSubmitDelete, listData, handlePaginate, handleEdit
    } = useMasterTps();
    return(
        <div>
            <Modal
                show={modalValue.show}
                setShow={() => {
                    setModalValue(modalValue => ({...modalValue, show: false}))
                }}
                width='xl'
                persistent={false}
                btnClose={false}
            >
                <div className='py-[20px] px-[30px] border-b border-b-gray-200 font-bold'>
                    {modalValue.title}
                </div>
                <div className='py-[50px] px-[30px]'>
                    { modalValue.tipe === 'create' ?
                        <div>
                            <FormTextSingleComp
                                {...formData.nama}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </div>
                        :
                        <div>
                            { modalValue.text }
                        </div>
                    }
                </div>
                <div className='py-[20px] px-[30px] border-t border-t-gray-200 flex flex-row justify-end gap-2'>
                    <Button color={modalValue.tipe === 'create' ? 'primary'  : 'danger'} size='md' onClick={modalValue.tipe === 'delete' ? () => handleSubmitDelete() : () => handleSubmit()}>
                        { modalValue.tipe === 'create' ? 'Simpan' : 'Delete'}
                    </Button>
                    <Button color='base' size='md' onClick={handleCloseModal}>Cancel</Button>
                </div>
            </Modal>
            <Card
                title='Master Kandidat Ketua Umum'
                isCreate={view === 'create'}
                isSearch={view === 'list'}
                searchValue={pageValue.search}
                handleCreate={handleCreate}
                handleSubmit={handleSubmit}
                handleCancel={handleCancel}
                onChangeSearch={handleChangeSearch}
                handleSearch={handleSearch}
            >                
                <Tables className='border-separate rounded-sm'>
                    <Tables.Head className='bg-[#efeeee]'>
                        <tr>
                            <Tables.Header className='font-bold text-[13px] py-[10px] text-right'>#</Tables.Header>
                            <Tables.Header className='font-bold text-[13px] py-[10px]'>Nama Bilik / TPS</Tables.Header>
                            <Tables.Header className='font-bold text-[13px] py-[10px]'>Action</Tables.Header>
                        </tr>
                    </Tables.Head>
                    <Tables.Body>
                        { listData?.data?.length === 0 ?
                            <Tables.Row>
                                <Tables.Data colspan={4} className='text-center'>Belum ada data</Tables.Data>
                            </Tables.Row>
                            :
                            <Fragment>
                                {listData?.data?.map((post, index) => {
                                    return (
                                        <Tables.Row key={index}>
                                            <Tables.Data className='text-right align-top'>{pageValue.offset + index + 1}</Tables.Data>
                                            <Tables.Data className='align-top'>{post.nama}</Tables.Data>
                                            <Tables.Data className='align-top'>
                                                <div className='flex flex-row gap-1'>
                                                    <Tooltip
                                                        tooltip={'Edit'}
                                                    >
                                                        <button onClick={() => handleEdit(post)} className='p-1 bg-orange-500 text-white rounded'><Icon icon='line-md:edit' /></button>
                                                    </Tooltip>
                                                    <Tooltip
                                                        tooltip={'Hapus'}
                                                    >
                                                        <button onClick={() => handleDelete(post)} className='p-1 bg-red-500 text-white rounded'><Icon icon='fluent-mdl2:delete'  /></button>
                                                    </Tooltip>
                                                </div>
                                            </Tables.Data>
                                        </Tables.Row>
                                    )
                                })}
                            </Fragment>
                        }
                    </Tables.Body>
                </Tables>
                <div className='mt-5'>
                    <PagePagination
                        data={pageValue.obj}
                        handlePagination={handlePaginate}
                        page={pageValue.page}
                        lastPage={pageValue.lastPage}
                    />
                </div>                
            </Card>
        </div>
    )
}
export default TpsPage