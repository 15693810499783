import axios from "axios";
import config from "./config";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json", // Default content type
};

const api_download = axios.create({
  baseURL: config.apiBaseUrl[process.env.NODE_ENV],
  headers: headers,
  timeout: 50000,
});

api_download.interceptors.request.use(
  function (configure) {
    const myCookies = cookies.get(config.cookiesName);
    if (myCookies && myCookies.token) {
      configure.headers["Authorization"] = "Token " + myCookies.token;
      // Hanya set multipart/form-data jika permintaan menggunakan form data
      if (configure.method === 'post' || configure.method === 'put') {
        if (configure.data instanceof FormData) {
          configure.headers["Content-Type"] = "multipart/form-data";
        }
      }
    }
    return configure;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Fungsi khusus untuk download file
api_download.downloadFile = async function (url, params = "") {
  try {
    const response = await api_download.get(url, {
      responseType: 'blob', // Mengatur respons sebagai blob untuk file download
      params: params // Kirim parameter sebagai query string
    });
    return response;
  } catch (error) {
    console.error("Error during file download:", error);
    throw error;
  }
};


export default api_download;
