import { Fragment, useState } from "react"
import { useRegistrasiEdit } from "./dataRegistrasiEditFunction"
import AlertComp from "../../../../Components/Atoms/AlertComp"
import Card from "../../../../Components/Layout/Card"
import FormTextSingleComp from "../../../../Components/forms/FormTextSingleComp"
import Button from "../../../../Components/Atoms/Button"


const DataRegistrasiEditPage = () => {
    const { alertValue, setAlertValue, formData, setFormData, handleSubmit, handleCancel } = useRegistrasiEdit()

    const [showDokumen, setShowDokumen] = useState({
        akta : false,
        surat_pernyataan : false,
        surat_kuasa : false,
        ktp : false,
        ktp_peserta : false
    })


    return(
        <Fragment>
            <AlertComp
                isOpen={alertValue.show}
                onClose={setAlertValue}
                text={alertValue.text}
                subText={alertValue.subText}
                color={alertValue.color}
                icon={alertValue.icon}
            />
            <Card
                isCreate={true}
                handleSubmit={handleSubmit}
                handleCancel={handleCancel}
                isSearch={false}
            >
                <div className='grid grid-cols-12'>
                    <div className='col-start-4 col-span-6'>
                        <div>
                            <FormTextSingleComp
                                {...formData.nama}
                                formData={formData}
                                setFormData={setFormData}
                                disabled={formData.nama.readOnly}
                            />
                        </div>
                        <div>
                            <FormTextSingleComp
                                {...formData.perusahaan_display}
                                formData={formData}
                                setFormData={setFormData}
                                disabled={formData.perusahaan_display.readOnly}
                            />
                        </div>
                        <div>
                            <FormTextSingleComp
                                {...formData.jabatan_display}
                                formData={formData}
                                setFormData={setFormData}
                                disabled={formData.jabatan_display.readOnly}
                            />
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-12">
                    <div className="col-span-12">
                        <div>
                            <FormTextSingleComp
                                {...formData.akta}
                                formData={formData}
                                setFormData={setFormData}
                                disabled={formData.akta.readOnly}
                            >
                                {  formData.akta.value.includes('http') &&
                                    <div className='mt-2'>
                                        <Button  className='btn-custom bg-blue-500 text-white rounded' size="sm" onClick={() => setShowDokumen(showDokumen => ({...showDokumen, akta : !showDokumen.akta}))}>Lihat Akta</Button>
                                        { showDokumen.akta &&                                                
                                            // <DynamicShowDocument url={formData.akta.value} />
                                            <iframe
                                                src={`${formData.akta.value}`}                                                
                                                width="100%"
                                                height="500px"
                                                title="akta"
                                                className='mt-5'
                                            >
                                            </iframe>
                                        }
                                    </div>
                                }
                            </FormTextSingleComp>
                        </div>
                        <div>
                            <FormTextSingleComp
                                {...formData.foto}
                                formData={formData}
                                setFormData={setFormData}
                                disabled={formData.foto.readOnly}
                            />
                        </div>
                        { formData.jabatan.value === 1 &&                            
                            <div>
                                <FormTextSingleComp
                                    {...formData.surat_pernyataan}
                                    formData={formData}
                                    setFormData={setFormData}
                                    disabled={formData.surat_pernyataan.readOnly}
                                >
                                    {  formData.surat_pernyataan.value.includes('http') &&
                                        <div className='mt-2'>
                                            <Button  className='btn-custom bg-blue-500 text-white rounded' size="sm" onClick={() => setShowDokumen(showDokumen => ({...showDokumen, surat_pernyataan : !showDokumen.surat_pernyataan}))}>Lihat Surat Pernyataan</Button>
                                            { showDokumen.surat_pernyataan &&                                                                                                
                                                <iframe
                                                    src={`${formData.surat_pernyataan.value}`}
                                                    width="100%"
                                                    height="500px"
                                                    title="akta"
                                                    className='mt-5'
                                                >
                                                </iframe>
                                            }
                                        </div>
                                    }
                                </FormTextSingleComp>
                            </div>
                        }
                        { formData.jabatan.value === 2 &&
                            <div>
                                <FormTextSingleComp
                                    {...formData.surat_kuasa}
                                    formData={formData}
                                    setFormData={setFormData}
                                    disabled={formData.surat_kuasa.readOnly}
                                >
                                    {  formData.surat_kuasa.value.includes('http') &&
                                        <div className='mt-2'>
                                            <Button  className='btn-custom bg-blue-500 text-white rounded' size="sm" onClick={() => setShowDokumen(showDokumen => ({...showDokumen, surat_kuasa : !showDokumen.surat_kuasa}))}>Lihat Surat Kuasa</Button>
                                            { showDokumen.surat_kuasa &&
                                                <iframe
                                                    src={`${formData.surat_kuasa.value}`}
                                                    width="100%"
                                                    height="500px"
                                                    title="akta"
                                                    className='mt-5'
                                                >
                                                </iframe>
                                            }
                                        </div>
                                    }
                                </FormTextSingleComp>
                            </div>
                        }
                    </div>
                </div>
            </Card>
        </Fragment>
    )
}
export default DataRegistrasiEditPage