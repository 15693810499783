const SyaratKetentuanPage = () => {
  return (
    <div className="w-full min-h-screen h-fit  text-black relative flex flex-col items-center mt-[80px] md:mt-[100px] px-[20px]">
      <div className="w-full text-center max-w-homeWidth px-[10px] md:px-[100px]">
        <div className="font-bold title md:text-[24px] text-[14px]">
          <div className="whitespace-nowrap">
            SYARAT DAN KETENTUAN KEPESERTAAN
          </div>
          <div className="">
            MUSYAWARAH NASIONAL XII APJII TAHUN 2024
          </div>
        </div>
        <div className="text-justify mt-[30px]">
          <div>
            Syarat dan Ketentuan Kepesertaan Musyawarah Nasional XII APJII Tahun 2024 adalah sebagai berikut:
          </div>
        </div>
        <div className="mt-[30px] text-left">
          <ul className="list-decimal">
            <li className="ml-[14px]">
              <div className="ml-[20px]">
                Peserta Musyawarah Nasional XII APJII Tahun 2024 {" "} <span className="font-bold">(“MUNAS XII APJII”)</span> adalah Anggota APJII yang memiliki Akta Perusahaan terakhir yang masa jabatan Direksi tidak dalam masa kadaluarsa dan masih berlaku setidaknya hingga tanggal 24 September 2024.
              </div>
            </li>
            <li className="ml-[14px] mt-[10px]">
              <div className="ml-[20px]">
                Masa pendaftaran kepesertaan <span className="font-bold">MUNAS XII APJII</span> dimulai pada hari Jumat, 23 Agustus 2024 Pukul 10.00 WIB hingga hari <span className="font-bold">Kamis, 12 September 2024</span> Pukul 17.00 WIB.
              </div>
            </li>
            <li className="ml-[14px] mt-[10px]">
              <div className="ml-[20px]">
                Penghitungan batas akhir jumlah Anggota yang dapat mengikuti <span className="font-bold">MUNAS XII APJII</span> adalah data keanggotaan APJII per tanggal 24 Agustus 2024.
              </div>
            </li>
            <li className="ml-[14px] mt-[10px]">
              <div className="ml-[20px]">
                Setiap Anggota hanya dapat mendaftarkan 1 (satu) orang peserta pada <span className="font-bold">MUNAS XII APJII.</span>
              </div>
            </li>
            <li className="ml-[14px] mt-[10px]">
              <div className="ml-[20px]">
                1 (satu) orang peserta sebagaimana dimaksud poin 4 hanya dapat mewakili 1 (satu) Anggota pada <span className="font-bold">MUNAS XII APJII.</span>
              </div>
            </li>
            <li className="ml-[14px] mt-[10px]">
              <div className="ml-[20px]">
                Anggota yang menghadiri <span className="font-bold">MUNAS XII APJII</span> adalah Direksi/Non Direksi yang memiliki kewenangan untuk mewakili perusahaan menghadiri <span className="font-bold">
                  MUNAS XII APJII.
                </span>
              </div>
            </li>
            <li className="ml-[14px] mt-[10px]">
              <div className="ml-[20px]">
                <span className="font-bold">Direksi</span> sebagaimana dimaksud poin 6 wajib untuk menyertakan Surat Pernyataan yang disediakan oleh Panitia.
              </div>
            </li>
            <li className="ml-[14px] mt-[10px]">
              <div className="ml-[20px]">
                <span className="font-bold">Non Direksi</span> sebagaimana dimaksud poin 6 wajib untuk menyertakan Surat Kuasa dari Direksi yang disediakan oleh Panitia.
              </div>
            </li>
            <li className="ml-[14px] mt-[10px]">
              <div className="ml-[20px]">
                Calon Peserta <span className="font-bold">MUNAS XII APJII</span> wajib menyertakan foto wajah terbaru.
              </div>
            </li>
            <li className="ml-[14px] mt-[10px]">
              <div className="ml-[20px]">
                Bakal Calon Anggota Dewan Pengawas atau Bakal Calon Ketua Umum yang telah ditetapkan oleh Panitia Pemilihan <span className='italic'>(Election Committee)</span> sebagai Calon Anggota Dewan Pengawas atau Calon Ketua Umum, wajib mendaftar sebagai Peserta <span className="font-bold">MUNAS XII APJII</span> dari perusahaan yang sama.
              </div>
            </li>
            <li className="ml-[14px] mt-[10px]">
              <div className="ml-[20px]">
                Anggota yang telah mendaftarkan diri sebagai Calon Anggota Dewan Pengawas atau Calon Ketua Umum tidak diperkenankan untuk mendaftarkan orang lain sebagai peserta pada <span className="font-bold">MUNAS XII APJII.</span>
              </div>
            </li>
            <li className="ml-[14px] mt-[10px]">
              <div className="ml-[20px]">
                Seluruh dokumen Surat Pernyataan/Surat Kuasa dan foto wajah terbaru wajib diunggah ke website: <a href="https://registrasi.apjii.or.id/" className='text-blue-600'>registrasi.apjii.or.id</a>.
              </div>
            </li>
            <li className="ml-[14px] mt-[10px]">
              <div className="ml-[20px]">
                Peserta <span className="font-bold">MUNAS XII APJII</span> diwajibkan melakukan {" "}
                <span className="font-bold">registrasi ulang </span> dengan membawa dokumen Surat Pernyataan/Surat Kuasa asli dan menunjukan kartu identitas asli di Vasa Hotel, Surabaya, Jawa Timur dengan detail waktu sebagai berikut:
                <div className='ml-[5px] mt-3'>
                  <ul className="list-none">
                    <li><span className='mr-[10px]'>a. </span> Tanggal 22 September 2024 dimulai dari pukul 09.00 – 21.00 WIB. </li>
                    <li><span className='mr-[10px]'>b. </span>Tanggal 23 September 2024 dimulai dari pukul 08.30 {"–"} 09.00 WIB.</li>
                  </ul>
                </div>
              </div>
            </li>
            <li className="ml-[14px] mt-[10px]">
              <div className="ml-[20px]">
                Peserta <span className="font-bold">MUNAS XII APJII</span> akan diberikan fasilitas akomodasi penginapan selama 3 (tiga) malam dengan keterangan waktu sebagai berikut:
                <div className='ml-[5px] mt-3'>
                  <ul className="list-none">
                    <li><span className='mr-[10px]'>a. </span> Check in Hotel tanggal 22 September 2024; dan</li>
                    <li><span className='mr-[10px]'>b. </span>Check out Hotel tanggal 25 September 2024. </li>
                  </ul>
                </div>
              </div>
            </li>
            <li className="ml-[14px] mt-[20px] mb-8">
              <div className="ml-[20px]">
                Peserta <span className="font-bold">MUNAS XII APJII</span> wajib mematuhi seluruh syarat dan ketentuan yang telah ditetapkan oleh Panitia <span className="font-bold">MUNAS XII APJII</span>, baik syarat dan ketentuan Peserta maupun syarat dan ketentuan selama mengikuti <span className="font-bold">MUNAS XII APJII</span>.
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default SyaratKetentuanPage;
