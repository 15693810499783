import { useState } from "react";
import { useGlobalFunction } from "../../../../generalFunction";
import api from "../../../../api";
import config from "../../../../config";
import { useNavigate } from "react-router-dom";

export function useForgotPassword(){
    const { isLoading, setIsLoading, alertValue, setAlertValue, handleCloseAlert,        
    } = useGlobalFunction();
    const [formEmail, setFormEmail] = useState('')
    const [errorFormEmail, setErrorFormEmail] = useState(false);
    const [view, setView] = useState('email');
    const navigate = useNavigate();

    const handleChangeFormEmail = (e) => {
        const { value } = e.target;
        setFormEmail(value);
    }

    const handleSubmitResetPassword = async() => {
        setIsLoading(true);
        try{
            if (formEmail === ''){
                setErrorFormEmail(true);
                setIsLoading(false);                
                return;
            }

            
            let tmp = {
                'email' : formEmail,
                'callback_url' : `${window.location.protocol}//${window.location.host}/member/reset-password/`
            }
            
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmp));
            const res = await api.post(`${config.endPoint.forgotPassword}`, newForm).then(res => res.data);
            if (res){
                setView('checkemail_reset')
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            let msg = 'Unable to save Data';
            let { response } = error;
            if (response && response.data && response.data.message){
                msg = response.data.message
            }            
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }));
            }, config.timeOutValue)
            
        }
    }

    const handleLogin = () => {
        navigate('/login')
    }

    return {
        isLoading, setIsLoading, alertValue, setAlertValue, handleCloseAlert, 
        handleSubmitResetPassword, errorFormEmail, view, handleChangeFormEmail, formEmail, handleLogin
    }
}