import { useCallback, useContext, useEffect, useState } from "react";
import { useGlobalFunction } from "../../../../generalFunction";
import api from "../../../../api";
import config from "../../../../config";
import { pageObj } from "../../../../pageObj";
import { ThemeContext } from "../../../../context/ThemeContext";

// eslint-disable-next-line
export function useDuplicatePeserta() {
    const { showLoading, closeLoading, alertValue, setAlertValue, handleCloseAlert,
        modalValue, setModalValue, handleCloseModal, pageValue, setPageValue, catchFetching,
        setIsLoading, isLoading
    } = useGlobalFunction();
    const [listData, setListData] = useState([]);
    const { setTitle } = useContext(ThemeContext);
    const [listGroup, setlistGroup] = useState([]);
    const [listGroupDetail, setListGroupDetail] = useState([]);
    const [groupSelected, setGroupSelected] = useState({nama : '', id: ''});
    const [kontakSelected, setKontakSelected] = useState(null);
    const [formGroup, setFormGroup] = useState({
        nama : {
            name : 'nama',
            label : 'Group Name',
            value : '',
            required : true,
            showError : false,
            errorMsg : '',
            show : true,
            type: 'text',
            placeholder : 'Masukan Nama Group'
        }
    })

    useEffect(() => {
        setTitle('Duplicate Peserta')
    }, [setTitle])
    const fetchData = useCallback(async(q='', offset=0, limit=100) => {        
        showLoading();        
        try{
            let params = {
                offset : offset,
                limit : limit,                
            }
            if (q) {
                params['q'] = q;
            }
            const res = await api.get(`${config.endPoint.kontakDuplicate}`, {params: params}).then(res => res.data);            
            if (res){
                setListData(res.results);                
                setPageValue(pageValue => ({...pageValue,
                    obj : pageObj(res.count, limit, offset),
                    lastPage : Math.ceil(parseInt(res.count) / parseInt(limit))
                }))
            }
            closeLoading();
        }catch(error){
            closeLoading();            
            let msg = 'Unable to save Data';
            let { response } = error;            
            
            catchFetching(error, 'admin')            
            if (response && response.data && response.data.message){
                msg = response.data.message
            }
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }));
            }, config.timeOutValue)
        }
        // eslint-disable-next-line
    },[])

    useEffect(() => {
        fetchData();
    },[fetchData])

    const handleChangeSearch = (e) => {
        const { name, value } = e.target;
        setPageValue(pageValue => ({...pageValue, [name] : value}))        
        if (name === 'hotel'){
            fetchData(pageValue.search, pageValue.offset, 100, value)
        }
    }

    const handleSearch = () => {
        setPageValue(pageValue => ({...pageValue, offset: 0, page : 1}));
        fetchData(pageValue.search, 0, 100)
    }

    const handleKeyDownSearch = (e) => {
        if (e.key === "Enter"){
            handleSearch();
        }
    }

    const handlePaginate = (page) => {
        let myOffset = (parseInt(page) * parseInt(100)) - parseInt(100)
        setPageValue(pageValue => ({...pageValue, page : page, offset: myOffset}))
        fetchData(pageValue.search, myOffset, 100);
    }

    const handleDelete = (post) => {
        setModalValue(modalValue => ({...modalValue,
            show: true, 
            text : `Apakah anda yakin akan melakukan revoke perusahaan ${post.perusahaan_display} an. peserta ${post.nama} pada ${post.hotel_display}`,
            id : post.kontak_perusahaan,
            tipe: 'revoke'
        }))
    }

    const handleSubmitDelete = async() => {
        setIsLoading(true);
        try{
            const res = await api.delete(`${config.endPoint.hotelPeserta}${modalValue.id}/`).then(res => res.data);
            if (res){
                fetchData(pageValue.search, pageValue.offset, 100);
                handleCloseModal();
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            catchFetching(error, 'admin');
            setAlertValue(alertValue => ({...alertValue,
                show: true,
                text : 'Unable to delete data',
                color : 'danger'
            }))
        }
    }

    // =============================== group ======================================

    const fetchKontakGroup = useCallback(async() => {
        setIsLoading(true);
        try{
            const res = await api.get(`${config.endPoint.kontakGrouop}`).then(res => res.data);
            
            if (res){
                setlistGroup(res.results);
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            let { response } = error;
            let msg = "Unable to fetch data"
            if (response && response.data && response.data.message){
                msg = response.data.message
            }
            setAlertValue(alertValue => ({...alertValue, show: true, text : msg, color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    },[setIsLoading, setAlertValue]);

    const handleGrouping = (post) => {
        handleCloseAlert();
        setGroupSelected({nama : '', id : ''});
        setModalValue(modalValue => ({...modalValue, 
            show: true, 
            title : 'Group Kontak',
            tipe : 'grouping',
            id : post.id
        }));
        fetchKontakGroup();
        setKontakSelected(post);
        setFormGroup(formGroup => ({...formGroup,
            nama : {...formGroup.nama, value : ''}
        }))
    }

    const handleCreateGroup = async() => {
        setIsLoading(true);
        try{
            let countError = 0;
            let form = {...formGroup};
            let tmpForm = {}
            Object.entries(form).map(([index, post]) => {                
                if (post.required && (post.value === '' || post.value === null)){
                    post.showError = true;
                    countError++;
                }else{
                    tmpForm[index] = post.value;
                }            
                return true;
            });

            
            
            if (countError > 0){
                setFormGroup(form);
                setIsLoading(false);
                setAlertValue(alertValue => ({...alertValue, show: true, text : 'Lengkapi Data', color: 'danger'}));
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue);
                return;
            }
            setIsLoading(false);

            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmpForm));
            
            const res = await api.post(`${config.endPoint.kontakGrouop}`, newForm).then(res => res.data);
            if (res){
                fetchKontakGroup();
                setAlertValue(alertValue => ({...alertValue, show: true, text : 'Group Created', color : 'success'}));
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue);
                setFormGroup(formGroup => ({...formGroup,
                    nama : {...formGroup.nama, value : ''}
                }))
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            let { response } = error;
            let msg = "Unable to fetch data"
            if (response && response.data && response.data.message){
                msg = response.data.message
            }
            setAlertValue(alertValue => ({...alertValue, show: true, text : msg, color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }

    const handleClickGroup = async(post) => {
        setListGroupDetail([]);
        if (post.id === groupSelected.id){
            setGroupSelected({nama : '', id : ''});
        }else{
            setIsLoading(true);
            setGroupSelected({nama : post.nama, id : post.id});
            try{
                const res = await api.get(`${config.endPoint.kontakGrouop}${post.id}/`).then(res => res.data);
                if (res){
                    setListGroupDetail(res.results.detail_display);
                }
                setIsLoading(false);
            }catch(error){
                setIsLoading(false);
                let { response } = error;
                let msg = "Unable to fetch data"
                if (response && response.data && response.data.message){
                    msg = response.data.message
                }
                setAlertValue(alertValue => ({...alertValue, show: true, text : msg, color : 'danger'}));
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue)
            }
        }        
    }

    const handleReclickGroup = async(post) => {
        setIsLoading(true);
        setGroupSelected({nama : post.nama, id : post.id});
        try{
            const res = await api.get(`${config.endPoint.kontakGrouop}${post.id}/`).then(res => res.data);
            if (res){
                setListGroupDetail(res.results.detail_display);
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            let { response } = error;
            let msg = "Unable to fetch data"
            if (response && response.data && response.data.message){
                msg = response.data.message
            }
            setAlertValue(alertValue => ({...alertValue, show: true, text : msg, color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }

    const handleSubmitGroup = async() => {
        setIsLoading(true);
        try{
            let tmp = {}
            tmp['kontak_id'] = modalValue.id;
            tmp['nama'] = groupSelected.nama

            if (groupSelected.id === ''){
                setAlertValue(alertValue => ({...alertValue, show: true, text : 'Pilih salah satu group', color : 'danger'}));
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue)
                setIsLoading(false);
                return ;
            }
            
            

            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmp));

            const res = await api.put(`${config.endPoint.kontakGrouop}${groupSelected.id}/`, newForm).then(res => res.data);
            if (res){
                handleCloseModal();
                fetchData();
            }
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Kontak Grouped', color : 'success'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)

        }catch(error){
            setIsLoading(false);
            let { response } = error;
            let msg = "Unable to fetch data"
            if (response && response.data && response.data.message){
                msg = response.data.message
            }
            setAlertValue(alertValue => ({...alertValue, show: true, text : msg, color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }

    const handleDeleteKontakGroup = async(post) => {
        setIsLoading(true);
        try{    
            const res = await api.put(`${config.endPoint.kontakGroupDeleteKontak}${post.id}/`).then(res => res.data);
            if (res){
                setAlertValue(alertValue => ({...alertValue, show: true, text : 'Kontak di ungroup', color : 'success'}));
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue);
                handleReclickGroup(groupSelected)
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            let { response } = error;
            let msg = "Unable to fetch data"
            if (response && response.data && response.data.message){
                msg = response.data.message
            }
            setAlertValue(alertValue => ({...alertValue, show: true, text : msg, color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }

    const handleDeleteGroup = async(post) => {
        setIsLoading(true);        
        try{
            const res = await api.delete(`${config.endPoint.kontakGrouop}${post.id}/`).then(res => res.data);
            if (res){
                fetchKontakGroup();
                setAlertValue(alertValue => ({...alertValue, show: true, text : 'Group deleted', color : 'danger'}));
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue)     
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            let { response } = error;
            let msg = "Unable to fetch data"
            if (response && response.data && response.data.message){
                msg = response.data.message
            }
            setAlertValue(alertValue => ({...alertValue, show: true, text : msg, color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }

    return {
        alertValue, setAlertValue,  handleCloseAlert, modalValue, setModalValue, handleCloseModal,
        showLoading, closeLoading, listData, pageValue, handleChangeSearch, handleKeyDownSearch, handleSearch,
        handlePaginate, handleDelete, handleGrouping, formGroup, listGroup, isLoading, 
        listGroupDetail, groupSelected, kontakSelected, setFormGroup, handleCreateGroup,
        handleClickGroup, handleSubmitGroup, 
        handleDeleteGroup, handleDeleteKontakGroup, handleSubmitDelete
    }
}