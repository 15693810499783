import { useCallback, useContext, useEffect, useState } from "react";
import { useGlobalFunction } from "../../../../generalFunction";
import api from "../../../../api";
import config from "../../../../config";
import Moment from 'moment';
import { ThemeContext } from "../../../../context/ThemeContext";


export function useSiteSetting(){
    const {
        alertValue, setAlertValue, handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal,
        isLoading, setIsLoading, catchFetching, showLoading, closeLoading
    } = useGlobalFunction();    
    const { setTitle } = useContext(ThemeContext)
    const [isEdit, setIsEdit] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [formData, setFormData] = useState({
        site_name: {
            name: 'site_name',
            label: 'Nama Event',
            value: '',
            type: 'text',
            col: 2,
            required: true,
            show: true,
            errorMsg: '',
            showError: false,
        },
        diskripsi: {
            name: 'diskripsi',
            label: 'Diskripsi Event',
            value: '',
            type: 'textarea',
            col: 2,
            required: true,
            show: true,
            errorMsg: '',
            showError: false,
        },
        maksimal_peserta: {
            name: 'maksimal_peserta',
            label: 'Jumlah Maksimal Peserta',
            value: '1',
            type: 'number',
            col: 2,
            required: true,
            show: true,
            errorMsg: '',
            showError: false,
        },
        site_footer: {
            name: 'site_footer',
            label: 'Site Footer',
            value: '',
            type: 'text',
            col: 2,
            required: true,
            show: true,
            errorMsg: '',
            showError: false,
        },
        kota: {
            name: 'kota',
            label: 'Kota',
            value: '',
            type: 'text',
            col: 2,
            required: true,
            show: true,
            errorMsg: '',
            showError: false,
        },
        // logo: {
        //     name: 'logo',
        //     label: 'Logo',
        //     value: '',
        //     type: 'image',
        //     col: 2,
        //     required: true,
        //     show: true,
        //     errorMsg: '',
        //     showError: false,
        //     file: null,
        // },
        // landing_page: {
        //     name: 'landing_page',
        //     label: 'Landing Page',
        //     value: '',
        //     type: 'text',
        //     col: 2,
        //     required: true,
        //     show: false,
        //     errorMsg: '',
        //     showError: false,            
        // },
        // landing_page_display: {
        //     name: 'landing_page_display',
        //     label: 'Landing Page',
        //     value: '',
        //     type: 'input_button',
        //     col: 2,
        //     required: true,
        //     show: true,
        //     errorMsg: '',
        //     showError: false,
        //     file: null,
        // },
        // download_page: {
        //     name: 'download_page',
        //     label: 'Download Page',
        //     value: '',
        //     type: 'text',
        //     col: 2,
        //     required: true,
        //     show: false,
        //     errorMsg: '',
        //     showError: false,            
        // },
        // download_page_display: {
        //     name: 'download_page_display',
        //     label: 'Download Page',
        //     value: '',
        //     type: 'input_button',
        //     col: 2,
        //     required: true,
        //     show: true,
        //     errorMsg: '',
        //     showError: false,
        //     file: null,
        // },
        host: {
            name: 'host',
            label: 'Email Host',
            value: '',
            type: 'text',
            col: 2,
            required: true,
            show: true,
            errorMsg: '',
            showError: false,
            placeholder: 'smtp.gmail.com'
        },
        user: {
            name: 'user',
            label: 'Email User',
            value: '',
            type: 'text',
            col: 2,
            required: true,
            show: true,
            errorMsg: '',
            showError: false,
            placeholder: 'yourmail@domain.com'
        },
        password: {
            name: 'password',
            label: 'Password',
            value: '',
            type: 'password',
            col: 2,
            required: true,
            show: true,
            errorMsg: '',
            showError: false,
            inputType: 'password',
        },
        port: {
            name: 'port',
            label: 'Port',
            value: '',
            type: 'number',
            col: 2,
            required: true,
            show: true,
            errorMsg: '',
            showError: false,
            placeholder: '465'
        },
        tanggal_mulai: {
            name: 'tanggal_mulai',
            label: 'Tanggal Mulai Pendaftaran',
            value: '',
            type: 'date',
            col: 2,
            required: true,
            show: true,
            errorMsg: '',
            showError: false,
            dateFormat : 'dd-MM-yyyy HH:mm'
        },
        tanggal_selesai: {
            name: 'tanggal_selesai',
            label: 'Tanggal Selesai Pendaftaran',
            value: '',
            type: 'date',
            col: 2,
            required: true,
            show: true,
            errorMsg: '',
            showError: false,
            dateFormat : 'dd-MM-yyyy HH:mm'
        },
        wa: {
            name: 'wa',
            label: 'Nomor WA Helpdesk',
            value: '',
            type: 'number',
            col: 2,
            required: true,
            show: true,
            errorMsg: '',
            showError: false,            
        },
        lat : {
            name : 'lat',
            label : 'latitude',
            value : '',
            type : 'text',
            col : 2,
            required : true,
            show : true,
            errorMsg : '',
            showError : false,
        },
        lon : {
            name : 'lon',
            label : 'longitude',
            value : '',
            type : 'text',
            col : 2,
            required : true,
            show : true,
            errorMsg : '',
            showError : false,
        },
        tanggal_event : {
            name: 'tanggal_event',
            label: 'Tanggal Event',
            value: '',
            type: 'date',
            col: 2,
            required: true,
            show: true,
            errorMsg: '',
            showError: false,
            // dateFormat : 'dd-MM-yyyy HH:mm'               
        },
        tanggal_selesai_event : {
            name: 'tanggal_selesai_event',
            label: 'Tanggal Selesai Event',
            value: '',
            type: 'date',
            col: 2,
            required: true,
            show: true,
            errorMsg: '',
            showError: false,
            // dateFormat : 'dd-MM-yyyy HH:mm'               
        },
        tempat_event : {
            name: 'tempat_event',
            label: 'Tempat',
            value: '',
            type: 'textarea',
            col: 2,
            required: true,
            show: true,
            errorMsg: '',
            showError: false,
            // dateFormat : 'dd-MM-yyyy HH:mm'               
        },
    })
    const [showMarker, setShowMarker] = useState(false);  

    const fetchSiteSetting = useCallback(async () => {
        setIsLoading(true);
        showLoading();
        try {
            const res = await api.get(`${config.endPoint.siteSetting}`).then(res => res.data);
            if (res) {
                let lat =  parseFloat(res.results.lat) >= -90 && parseFloat(res.results.lat) <= 90 ? parseFloat(res.results.lat) : ''
                let lon =  parseFloat(res.results.lon) >= -180 && parseFloat(res.results.lon) <= 180 ? parseFloat(res.results.lon) : ''
                
                let obj = {...formData}
                if (res.results) {
                    obj['site_name'] = {...obj['site_name'], value : res.results.site_name}
                    obj['diskripsi'] = {...obj['diskripsi'], value : res.results.diskripsi}
                    obj['site_footer'] = {...obj['site_footer'], value : res.results.site_footer}
                    obj['logo'] = {...obj['logo'], value : res.results.logo}
                    obj['landing_page'] = {...obj['landing_page'], value : res.results.landing_page}
                    obj['tempat_event'] = {...obj['tempat_event'], value : res.results.tempat_event}
                    obj['landing_page_display'] = {...obj['landing_page_display'], value : res.results.landing_page_display}
                    obj['download_page'] = {...obj['download_page'], value : res.results.download_page}
                    obj['download_page_display'] = {...obj['download_page_display'], value : res.results.download_page_display}
                    obj['maksimal_peserta'] = {...obj['maksimal_peserta'], value : res.results.maksimal_peserta}
                    // obj['tanggal_mulai'] = {...obj['tanggal_mulai'], value : res.results.maksimal_peserta}
                    obj['tanggal_mulai'] = {...obj['tanggal_mulai'], value : res.results.tanggal_mulai ? Moment(res.results.tanggal_mulai_display, 'DD-MM-YYYY HH:mm').toDate() : ''}
                    obj['tanggal_selesai'] = {...obj['tanggal_selesai'], value : res.results.tanggal_selesai ? Moment(res.results.tanggal_selesai_display, 'DD-MM-YYYY HH:mm').toDate() : ''}
                    obj['tanggal_event'] = {...obj['tanggal_event'], value : res.results.tanggal_event ? Moment(res.results.tanggal_event_display, 'DD-MM-YYYY').toDate() : ''}
                    obj['tanggal_selesai_event'] = {...obj['tanggal_selesai_event'], value : res.results.tanggal_selesai_event ? Moment(res.results.tanggal_selesai_event_display, 'DD-MM-YYYY').toDate() : ''}
                    obj['lat'] = {...obj['lat'], value : lat || ''}
                    obj['lon'] = {...obj['lon'], value : lon || ''}
                    obj['kota'] = {...obj['kota'], value : res.results.kota}
                    obj['wa'] = {...obj['wa'], value : res.results.wa}
                }

                if (res.results?.lat && res.results?.lon){                    
                    setShowMarker(true);                    
                }
                
                if (res.mail_server) {
                    obj['host'] = {...obj['host'], value : res.mail_server.host};
                    obj['user'] = {...obj['user'], value : res.mail_server.user};
                    obj['password'] = {...obj['password'], value : res.mail_server.password};
                    obj['port'] = {...obj['port'], value : res.mail_server.port};                    
                }                
                setFormData(obj);
            }           
            setIsLoading(false);
            closeLoading()
            setIsReady(true);
        } catch (error) {
            setIsLoading(false);
            closeLoading();
            catchFetching(error, 'admin');
            let status = error && error.response && error.response.status ? error.response.status : '';

            if (status && status === 401) {
                // return window.location.href = "/admin/login"
            } else {
                setAlertValue(alertValue => ({
                    ...alertValue,
                    text: 'Unable to fetch data',
                    color: 'danger',
                    show: true
                }))
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }))
                }, config.timeOutValue)
            }
        }
        // eslint-disable-next-line
    }, [setIsLoading, setAlertValue])

    const handleEdit = () => {
        setIsEdit(!isEdit);
    }

    useEffect(() => {
        setTitle('Site Settings')
    }, [setTitle])

    useEffect(() => {
        fetchSiteSetting();
    }, [fetchSiteSetting])

    const handleSubmit = async () => {
        setIsLoading(true);
        showLoading();
        try {
            let obj = { ...formData };
            let countError = 0;
            let tmpForm = {}

            if (formData.lat.value === '' || formData.lon.value === ''){         
                
                setAlertValue(alertValue => ({
                    ...alertValue,
                    show: true, text: 'Isi lokasi kegiatan dengan klik kanan pada map', color: 'danger'
                }));
                setIsLoading(false);
                // closeLoading();
                return
            }
            

            Object.entries(formData).map(([index, post]) => {                
                if (post.required && post.value === '') {
                    countError++;
                    post.showError = true;
                    console.log('post index', post, index)
                } else {
                    post.showError = false;
                    tmpForm[index] = post.value;
                }
                return true;
            })

            if (countError > 0) {                
                setAlertValue(alertValue => ({
                    ...alertValue,
                    show: true, text: 'Lengkapi data', color: 'danger'
                }));
                setFormData(obj);
                setIsLoading(false);
                setTimeout(() => {
                    setAlertValue(alertValue => ({
                        ...alertValue,
                        show: false
                    }));
                }, config.timeOutValue)
                return;
            }

            tmpForm['tanggal_mulai'] = Moment(tmpForm['tanggal_mulai']).format('YYYY-MM-DD HH:mm')
            tmpForm['tanggal_selesai'] = Moment(tmpForm['tanggal_selesai']).format('YYYY-MM-DD HH:mm')
            tmpForm['tanggal_event'] = Moment(tmpForm['tanggal_event']).format('YYYY-MM-DD')
            tmpForm['tanggal_selesai_event'] = Moment(tmpForm['tanggal_selesai_event']).format('YYYY-MM-DD')
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmpForm));
            let res = await api.post(`${config.endPoint.siteSetting}`, newForm).then(res => res.data);
            
            if (res){                
                setAlertValue(alertValue => ({
                    ...alertValue,
                    text: 'Data diperbaharui',
                    color: 'success',
                    show: true
                }))
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }))
                }, config.timeOutValue)
                setIsEdit(false);
            }
            setIsLoading(false);
            closeLoading()
        } catch (error) {
            console.log('error bawah')
            setIsLoading(false);
            closeLoading();
            let status = error && error.response && error.response.status ? error.response.status : '';

            if (status && status === 401) {
                return window.location.href = "/admin/login"
            } else {
                setAlertValue(alertValue => ({
                    ...alertValue,
                    text: 'Unable to fetch data',
                    color: 'danger',
                    show: true
                }))
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }))
                }, config.timeOutValue)
            }
        }
    }



    const onMapClick = (t, map, coord) => {
        const { latLng } = coord;
        setShowMarker(true);		
		setFormData(formData => ({...formData, 
			lat : {...formData.lat, value : latLng.lat()},
			lon : {...formData.lon, value : latLng.lng()}
		}))
	}

    const handleCancel = () => {
        setIsEdit(false);
    }

    const truncate = (str, n) =>  {
        return str?.length > n ? str.substr(0, n - 1) + '...' : str;
    }


    const handleClickMap = (obj) => {
        // console.log('obj is >>>>', obj);
        setFormData(formData => ({...formData,
            lat : {...formData.lat, value : obj.lat},
            lon : {...formData.lon, value: obj.lng},
        }))
    }


    return {
        alertValue, setAlertValue, handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal,
        isLoading, setIsLoading, setFormData, formData,  handleSubmit, 
        onMapClick,
        showMarker, handleEdit, isEdit, handleCancel, truncate, expanded, setExpanded, handleClickMap, isReady
    }
}