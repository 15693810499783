import { Fragment } from "react"
import AlertComp from "../../../../Components/Atoms/AlertComp"
import { useActivtyLog } from "./activityLogFunction"
import Card from "../../../../Components/Layout/Card";
import Tables from "../../../../Components/molecules/Tables";
import PagePagination from "../../../../Components/Atoms/PagePagination";


const ActivityLogPage = (props) => {
    const { kontakId } = props;
    const { alertValue, handleCloseAlert, listData, pageValue, handleChangeSearch, handleSearch,
        handlePaginate
    } = useActivtyLog({ kontakId: kontakId, props: props });
    return (
        <Fragment>
            <AlertComp
                isOpen={alertValue.show}
                onClose={handleCloseAlert}
                text={alertValue.text}
                subText={alertValue.subText}
                color={alertValue.color}
                icon={alertValue.icon}
            />
            <Card
                isCreate={false}
                isSearch={true}
                className={'w-full'}
                onChangeSearch={handleChangeSearch}
                handleSearch={handleSearch}
                title={"Activity Log YA"}
            >
                <Fragment>
                    <Tables className='border-separate rounded-sm'>
                        <Tables.Head className='bg-[#efeeee]'>
                            <tr>
                                <Tables.Header className='font-bold text-[13px] py-[10px] text-right'>#</Tables.Header>
                                <Tables.Header className='font-bold text-[13px] py-[10px]'>Tanggal</Tables.Header>
                                <Tables.Header className='font-bold text-[13px] py-[10px]'>Perusahaan</Tables.Header>
                                <Tables.Header className='font-bold text-[13px] py-[10px]'>Deskripsi</Tables.Header>
                                <Tables.Header className='font-bold text-[13px] py-[10px]'>Alasan</Tables.Header>
                                <Tables.Header className='font-bold text-[13px] py-[10px]'>Status</Tables.Header>
                                <Tables.Header className='font-bold text-[13px] py-[10px]'>PIC</Tables.Header>
                            </tr>
                        </Tables.Head>
                        <Tables.Body>
                            {listData.length === 0 ?
                                <Tables.Row>
                                    <Tables.Data colspan={7} className='text-center'>Belum ada data</Tables.Data>
                                </Tables.Row>
                                :
                                <Fragment>
                                    {listData.map((post, index) => {
                                        return (
                                            <Tables.Row key={index}>
                                                <Tables.Data className='text-right'>{pageValue.offset + index + 1}</Tables.Data>
                                                <Tables.Data>{post.tanggal_display}</Tables.Data>
                                                <Tables.Data>{post.perusahaan_display}</Tables.Data>
                                                <Tables.Data>{post.diskripsi}</Tables.Data>
                                                <Tables.Data><div className="content-ckeditor" dangerouslySetInnerHTML={{ __html: post.alasan }}></div></Tables.Data>

                                                <Tables.Data>
                                                    {post.status === 3 ?
                                                        <>Upload Data</>
                                                        :
                                                        <>
                                                            {post.status_display}
                                                        </>
                                                    }
                                                </Tables.Data>
                                                <Tables.Data>{post.pic_display}</Tables.Data>
                                            </Tables.Row>
                                        )
                                    })}
                                </Fragment>
                            }
                        </Tables.Body>
                    </Tables>
                    <div className='mt-5 flex flex-row justify-start w-full'>
                        <PagePagination
                            data={pageValue.obj}
                            handlePagination={handlePaginate}
                            page={pageValue.page}
                            lastPage={pageValue.lastPage}
                        />
                    </div>
                </Fragment>
            </Card>
        </Fragment>
    )
}
export default ActivityLogPage