

import { useCallback, useContext, useEffect, useState } from "react";
import { useGlobalFunction } from "../../../../generalFunction";
import api from "../../../../api";
import config from "../../../../config";
import { pageObj } from "../../../../pageObj";
import { ThemeContext } from "../../../../context/ThemeContext";


export function useHotelHistory(){
    const { isLoading, showLoading, closeLoading, setIsLoading,
        modalValue, setModalValue, handleCloseModal,
        pageValue, setPageValue, alertValue, handleCloseAlert, setAlertValue, catchFetching
    } = useGlobalFunction();
    const [listData, setListData] = useState([]);
    const { setTitle } = useContext(ThemeContext);
    
    const [formData, setFormData] = useState({
        id : {
            name : 'id',
            label : 'ID',
            value : '',
            type : 'text',
            col : 2,
            required : false,
            show : false,
            errorMsg : '',
            showError : false,
            flexType : 'row'
        },
        hotel : {
            name : 'hotel',
            label : 'Nama Hotel',
            value : '',
            type : 'select',
            col : 2,
            required : true,
            show : true,
            errorMsg : '',
            showError : false,
            flexType : 'row',
            obj : [],
        },
        peserta : {
            name : 'peserta',
            label : 'Peserta',
            value : '',
            type : 'input_button',
            col : 2,
            required : true,
            show : true,
            errorMsg : '',
            showError : false,
            flexType : 'row',
            className : 'text-right'
        },
        type : {
            name : 'type',
            label : 'Type',
            value : '',
            type : 'select',
            col : 2,
            required : true,
            show : true,
            errorMsg : '',
            showError : false,
            flexType : 'row',
            className : 'text-right',
            obj : [
                {value : 'insert', label : "Insert"},
                {value : 'delete', label : "Delete"},
            ]
        },
        diskripsi : {
            name : 'diskripsi',
            label : 'Diskripsi',
            value : '',
            type : 'text',
            col : 2,
            required : true,
            show : true,
            errorMsg : '',
            showError : false,
            flexType : 'row',
            className : 'text-right',
            readOnly: true
        },
    });

    const fetchHotel = useCallback(async() => {
        setIsLoading(true);
        showLoading();
        try{
            const res = await api.get(`${config.endPoint.hotel}`).then(res => res.data);
            let tmp = [{value : '', label : '-- Pilih Hotel --'}];
            if (res){
                res.results.map(post => tmp.push({value : post.id, label : post.nama}));
            }
            setFormData(formData => ({...formData,
                hotel : {...formData.hotel, obj : tmp}
            }))
            setIsLoading(false);
            closeLoading();
        }catch(error){
            setIsLoading(false);
            closeLoading();
            catchFetching(error, 'admin')
            let msg = "Unable to fetch data";
            if (error.response && error.response.data && error.response.data.message){
                msg = error.response.data.message
            }
            setAlertValue(alertValue => ({...alertValue, show: true, text : msg, color : 'danger'}))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))                
            }, config.timeOutValue);
        }
        // eslint-disable-next-line 
    },[setIsLoading])

    useEffect(() => {
        setTitle('Hotel History')
    }, [setTitle])

    useEffect(() => {
        fetchHotel();
    },[fetchHotel])

    const fetchData = useCallback(async(q='', offset=0, limit=config.itemPerPage, state='') => {
        setIsLoading(true);
        showLoading();
        try{
            let params = {
                'offset' : offset,
                'limit' : limit
            }
            if (q){
                params['q'] = q;
            }
            if (state){
                params['state'] = state
            }
            const res = await api.get(`${config.endPoint.hotelLog}`, {params: params}).then(res => res.data);            
            if (res){                
                setListData(res.results);
                setPageValue(pageValue => ({...pageValue,
                    obj : pageObj(res.count, limit, offset),
                    lastPage: Math.ceil(parseInt(res.count) / parseInt(limit))
                }))
            }
            closeLoading();            
            setIsLoading(false);
        }catch(error){
            closeLoading();            
            setIsLoading(false);
            catchFetching(error, 'admin')
            let msg = "Unable to fetch data";
            if (error.response && error.response.data && error.response.data.message){
                msg = error.response.data.message
            }
            setAlertValue(alertValue => ({...alertValue, show: true, text : msg, color : 'danger'}))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))                
            }, config.timeOutValue);
        }
        // eslint-disable-next-line 
    },[setIsLoading, setAlertValue, setPageValue])

    useEffect(() => {
        fetchData();
    },[fetchData])


    const handleChangeSearch = (e) => {
        const { name, value } = e.target;
        setPageValue(pageValue => ({...pageValue,
            [name] : value
        }))
        if (name === 'hotel_state'){
            console.log('name value >>>>>', name, value)
            fetchData(pageValue.search, 0, config.itemPerPage, value);
            setPageValue(pageValue => ({...pageValue, offset: 0, page : 1}))
        }
    }

    const handleSearch = () => {
        setPageValue(pageValue => ({...pageValue, offset: 0, page : 1}))
        fetchData(pageValue.search, 0, config.itemPerPage, pageValue.hotel_state)
    }

    const handleKeyDownSearch = (e) => {
        if (e.key === "Enter"){
            handleSearch();
        }
    }

    const handlePaginate = (page) => {
        let myOffset = (parseInt(page) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
        setPageValue(pageValue => ({...pageValue, page : page, offset: myOffset}))
        fetchData(pageValue.search, myOffset, config.itemPerPage, pageValue.hotel_state);
    }

   

    return {
        isLoading, showLoading, closeLoading, setIsLoading, listData,
        formData, setFormData,  setListData, 
        modalValue, setModalValue, handleCloseModal, 
        pageValue, setPageValue, alertValue, handleCloseAlert, setAlertValue,
        handleSearch, handleKeyDownSearch, handlePaginate, handleChangeSearch
    }
}