import { Fragment } from "react"
import PropTypes from 'prop-types';

import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";

const HotelDashboardAtom = (props) => {
    const navigate = useNavigate();
    const { title, terisi, kapasitas, color, persen, link } = props;


    return (
        <Fragment>
            <div className='px-5 py-[20px] pb-[20px] border-b border-gray-200'>
                <div className='text-[24px] font-bold'>{title}</div>
                <div className="flex justify-between mt-[6px]">
                    <div className='text-[14px] font-semibold'>Peserta <span className='text-[24px]'>{terisi}</span></div>
                    <div className='text-[14px] font-semibold'>Kapasitas <span className='text-[24px]'>{kapasitas}</span></div>
                </div>
                <div className='bg-gray-200 rounded-lg'>
                    <div className={`${color || 'bg-gray-700'}  h-[10px] rounded-lg`} style={{ width: persen }}></div>
                </div>
                <div className="flex justify-end mt-[6px]">
                    <button className={`${color || 'bg-gray-700'} text-white px-[12px] py-[3px] rounded-xl text-[12px flex flex-row gap-4 items-center`} onClick={() => navigate(link)}>Lihat Detail <Icon icon="typcn:chevron-right" /></button>
                </div>
            </div>
        </Fragment>
    )
}

HotelDashboardAtom.propTypes = {
    title: PropTypes.string.isRequired,
    terisi: PropTypes.number.isRequired,
    kapasitas: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired,
    persen: PropTypes.number.isRequired,
    link: PropTypes.string,
}


export default HotelDashboardAtom;