import { Fragment } from "react"
import Card from "../../../../Components/Layout/Card";
import { useServerCalon } from "./serverCalonFunction";
import FormTextSingleComp from "../../../../Components/forms/FormTextSingleComp";
import AlertComp from "../../../../Components/Atoms/AlertComp";


const ServerCalonPage = () => {
    const { handleSubmit, formData, setFormData, alertValue, handleCloseAlert } = useServerCalon();
    return(
        <Fragment>
            <AlertComp
                isOpen={alertValue.show}
                onClose={handleCloseAlert}
                text={alertValue.text}
                subText={alertValue.subText}
                color={alertValue.color}
                icon={alertValue.icon}
            />
            <Card
                title='Master Kandidat Ketua Umum'
                isCreate={true}
                isSearch={false}
                // searchValue={pageValue.search}
                // handleCreate={handleCreate}
                handleSubmit={handleSubmit}
                // handleCancel={handleCancel}
                // onChangeSearch={handleChangeSearch}
                // handleSearch={handleSearch}
            >
                <div className='grid grid-cols-12'>
                    <div className="col-span-12 md:col-span-6">
                        <FormTextSingleComp
                            {...formData.host}
                            formData={formData}
                            setFormData={setFormData}
                        />
                        <div className="mt-5">
                            <FormTextSingleComp
                                {...formData.path_ketum}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </div>
                        <div className="mt-5">
                            <FormTextSingleComp
                                {...formData.path_dewas}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </div>                        
                        <div className="mt-5 w-full">
                            <div className="flex flex-col md:flex-row gap-5 w-full">
                                <div className='w-full'>
                                    <FormTextSingleComp
                                        {...formData.username}
                                        formData={formData}
                                        setFormData={setFormData}
                                    />
                                </div>
                                <div className='w-full'>
                                    <FormTextSingleComp
                                        {...formData.password}
                                        formData={formData}
                                        setFormData={setFormData}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
        </Fragment>
    )
}
export default ServerCalonPage;