import PropTypes from 'prop-types';

const Label = (props) => {    
    const { className } = props;
    return(
        <label className={`tracking-wide text-gray-700 text-xs font-medium ${className} ${className && !className.includes('mb') && 'mb-2' }`} htmlFor="grid-first-name">
            {props.children}
        </label>
    )
}

Label.propTypes = {
    className : PropTypes.string
}
export default Label;