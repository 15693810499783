import { Outlet, useNavigate } from "react-router-dom";
import SidebarMember from "./SidebarMember";
import config from "../../config";
import Cookies from "universal-cookie";
import { useEffect } from "react";

const LayoutMember = () => {
  const cookies = new Cookies();
  const getcookie = cookies.get(config.cookiesName);
  const navigate = useNavigate();

  useEffect(() => {
    if (!getcookie) {
      navigate('/login'); // Redirect ke halaman login member jika cookie tidak ada
    }
  }, [getcookie, navigate]);

  if (!getcookie) {
    return null
  }

  return (
    <div className="w-full min-h-screen h-fit  text-black relative flex flex-col items-start bg-slate-100 text-[14px] pb-[100px]">
      {/* <div className='w-full max-w-homeWidth px-[100px] flex flex-row gap-5 mt-[150px]'> */}
      <div className="grid grid-cols-12 mt-[100px] lg:mt-[150px] px-[20px] lg:px-[100px] gap-5 w-full">
        <div className="col-span-full lg:col-span-3 w-full">
          <SidebarMember />
        </div>
        <div className="col-span-full lg:col-span-9 shadow border border-slate-100 rounded-xl bg-white p-5">
          <Outlet />
        </div>
      </div>
    </div>
  );
};
export default LayoutMember;
