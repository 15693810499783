import { Icon } from "@iconify/react/dist/iconify.js";
import GoogleMapReact from "google-map-react";
import config from "../../../config";
import { useLokasi } from "./lokasiFunction";
import Loading from "../../../Components/Atoms/Loading";

const LokasiPage = () => {
  const { info, defaultProps, isReady, handleShare } = useLokasi();
  // console.log('info is >>>>', info);

  return (
    <div className="w-full min-h-screen h-fit  text-black relative flex flex-col items-center mt-[80px] md:mt-[100px]">
      <div className="w-full text-center max-w-homeWidth px-10 md:px-[100px]">
        <div className="font-bold title md:text-[24px] text-[14px]">Lokasi Acara Musyawarah Nasional XII APJII Tahun 2024</div>
        <div className="text-justify mt-[30px] h-[calc(100vh-300px)] w-full">
          {isReady ? (
            <GoogleMapReact
              bootstrapURLKeys={{ key: config.googleMapKey }}
              defaultCenter={defaultProps.center}
              yesIWantToUseGoogleMapApiInternals
              defaultZoom={defaultProps.zoom}
              on
            >
              <div
                className="rounded-full -top-[20px] -left-[20px] absolute"
                lat={info?.lat}
                lng={info?.lon}
              >
                <Icon
                  icon="carbon:location-filled"
                  text="Hotel"
                  fontSize={"35"}
                  color="red"
                  onClick={() => handleShare()}
                />
              </div>
            </GoogleMapReact>
          ) : (
            <div className="flex flex-row justify-center items-center">
              <Loading />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LokasiPage;
