import { useCallback, useEffect, useRef, useState } from "react";
import config from "../../../../config";
import { useGlobalFunction } from "../../../../generalFunction";
import { pageObj } from "../../../../pageObj";
import api from "../../../../api";
import api_download from "../../../../api_download";


export function useKartuTandaPemilih(){
    const { showLoading, closeLoading, pageValue, setPageValue, alertValue, setAlertValue,
        modalValue, setModalValue, handleCloseModal, isLoading, setIsLoading,
        handleCloseAlert
    } = useGlobalFunction();
    const ws = useRef(null);
    const [view, setView] = useState('list');
    const [isEdit, setIsEdit] = useState(false);
    const [isOnline, setIsOnline] = useState(false);
    const [waitingToReconnect, setWaitingToReconnect] = useState(null);
    const [listData, setListData] = useState({isLoading: true, data : []})
    const [formData, setFormData] = useState({
        id : {
            name : 'id',
            type: 'text',
            label : 'id',
            value : '',
            required : false,
            showError : false,
            show: false,
            readOnly : true,
        },
        kontak : {
            name : 'kontak',
            type: 'text',
            label : 'Kontak',
            value : '',
            required : true,
            showError : false,
            show: false,            
        },
        tps : {
            name : 'tps',
            type: 'select',
            label : 'TPS',
            value : '',
            required : true,
            showError : false,
            show: false,            
        },
        waktu : {
            name : 'waktu',
            type: 'select',
            label : 'Waktu Sesi Pemilihan',
            value : '',
            required : true,
            showError : false,
            show: false,            
        },
    })

    const fetchData = useCallback(async(q='', offset=0, limit=config.itemPerPage) => {
        showLoading()
        try{
            const params = {
                offset : offset,
                limit : limit,
            }
            if (q) {
                params['q'] = q
            }
            const res = await api.get(`${config.endPoint.clusterTps}`, {params: params}).then(res => res.data);
            if (res){
                setListData(listData => ({...listData, isLoading: false, data: res.results}))
                setPageValue(pageValue => ({...pageValue,
                    obj : pageObj(res.count, limit, offset),
                    lastPage : Math.ceil(parseInt(res.count) / parseInt(limit))
                }))
            }else{
                setListData(listData => ({...listData, isLoading: false}))
            }
            closeLoading()
        }catch(error){
            setListData(listData => ({...listData, isLoading: false}))
            closeLoading()
            const { response } = error;
            let msg = 'Unable to fetch data'
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            setAlertValue(alertValue => ({...alertValue,
                show: true,
                text : 'Error',
                subText: msg,
                color: 'danger'
            }))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
        // eslint-disable-next-line 
    },[])
    
    useEffect(() => {
        fetchData();
    },[fetchData])

    const handleChangeSearch = (e) => {
        const { name, value } = e.target;
        setPageValue(pageValue => ({...pageValue, [name] : value}))
    }
    
    const handleKeyDownSearch = (e) => {
        if (e.key === 'Enter'){
            handleSearch();
        }
    }
    
    const handleSearch = () => {
        fetchData(pageValue.search, 0, config.itemPerPage)
    }

    const handleGenerateVoucer = () => {
        setModalValue(modalValue => ({...modalValue,
            show: true,
            title : 'Konfirmasi',
            tipe : 'generate',
            text : 'Bulk Create akan menghapus semua voucer yang sudah ada dan akan dibuat kembali, apakah anda yakin ?'
        }))
    }

    const handleSubmitGenerateVoucer = async() => {
        setIsLoading(true);
        try{
            const res = await api.post(`${config.endPoint.generateVoucerBulky}`).then(res => res.data);
            if (res){
                setListData(listData => ({...listData, data: []}));
                setPageValue(pageValue => ({...pageValue,
                    obj : [],
                    lastPage : 1
                }))
            }
            handleCloseModal();
            setIsLoading(false);
        }catch(error){
            handleCloseModal();
            setIsLoading(false);
            const { response } = error;
            let msg = 'Unable to save data'
            
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            
            setAlertValue(alertValue => ({...alertValue,
                show: true,
                text : 'Error',
                subText: msg,
                color: 'danger'
            }))
            
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }

    const handlePaginate = (page) => {
        let myOffset = (parseInt(page) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
        setPageValue(pageValue => ({...pageValue, page : page, offset: myOffset}))
        fetchData(pageValue.search, myOffset, config.itemPerPage);
    }

    const handleEdit = (post) => {
        setView('create');
        setIsEdit('true');
        setFormData(formData => ({...formData,
            id : {...formData.id, value : post.id},
            kontak : {...formData.kontak, value : post.kontak},
            tps : {...formData.tps, value : post.tps},
            waktu : {...formData.waktu, value : post.waktu},
        }))
    }

    const handleDelete = (params) => {
        setModalValue(modalValue => ({...modalValue,
            show: true,
            text: `Apakah anda yakin akan menghapus daftar pemilih ${params.nama} ?`,
            id : params.id,
            title : 'Konfirmasi',
        }))
    }
    
    const handleSubmitDelete = async() => {
        setIsLoading(true);
        try{
            const res = await api.delete(`${config.endPoint.clusterTps}${modalValue.id}/`).then(res => res.data);
            if (res){
                fetchData(pageValue.search, pageValue.offset, config.itemPerPage);
                setAlertValue(alertValue => ({...alertValue,
                    show: true,
                    text : 'Delete Success',
                    subText: 'Data berhasil di delete',
                    color: 'success'
                }))
    
                setTimeout(() => {
                    handleCloseAlert();
                }, config.timeOutValue)
            }
            handleCloseModal()
        }catch(error){
            setIsLoading(false);
            const { response } = error;
            let msg = 'Unable to delete data'
    
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            setAlertValue(alertValue => ({...alertValue,
                show: true,
                text : 'Error',
                subText: msg,
                color: 'danger'
            }))
    
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }

    const downloadExcel = async() => {
        setIsLoading(true);
        showLoading()
        try{
            const res = await api_download.downloadFile(`${config.endPoint.downloadTps}`)

            // Membuat URL dan mengkliknya untuk mengunduh file
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;

            // Nama file sudah ditentukan dari backend, gunakan nama file dari header Content-Disposition
            const contentDisposition = res.headers['content-disposition'];
            let fileName = 'cluster_tps.xlsx';
            if (contentDisposition) {
                console.log(contentDisposition)
                const fileNameMatch = contentDisposition.split('filename=')[1].replace(/"/g, '');
                if (fileNameMatch) {
                    fileName = fileNameMatch;
                }
            }

            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();

            // Bersihkan elemen setelah selesai
            document.body.removeChild(link);
            setIsLoading(false);
            closeLoading()
        }catch(error){
            closeLoading()
            setIsLoading(false);
            const { response } = error;
            let msg = 'Unable to save data'
            
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            
            setAlertValue(alertValue => ({...alertValue,
                show: true,
                text : 'Error',
                subText: msg,
                color: 'danger'
            }))
            
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }
    

    // ws
    const [importCount, setImportCount] = useState(0);
    const [totalImport, setTotalImport] = useState(0);
    const [showCounting, setShowCounting] = useState(false);
    const [successCouting, setSuccessCouting] = useState(false)

    const addMessage = useCallback((myMsg) => {
        try {
            let message = JSON.parse(myMsg);
            if (message && message.text && message.text !== "ping") {
                console.log('message >>>>', message.text)
                setTotalImport(message.text.total_record)
                setImportCount(message.text.counting)

                if (message.text && message.text.total_record === message.text.counting) {
                    setShowCounting(false);
                    setSuccessCouting(true);
                    setTimeout(() => {
                        setSuccessCouting(false);
                    }, config.timeOutValue)
                } else {
                    setShowCounting(true);
                    setSuccessCouting(false);
                }

                let index = listData.data.findIndex(x => x.id === message.text.data.id);
                if (index === -1) {
                    let tmpObj = { ...message.text.data }
                    tmpObj['isNew'] = true;
                    setListData(listData => ({ ...listData, data: [tmpObj, ...listData.data] }));
                }
            }

        } catch (e) {
            console.log('error coy >>>>', e);
            return false;
        }
        return true;
        // eslint-disable-next-line   
    }, []);

    useEffect(() => {
        // if (token){        
        if (waitingToReconnect) {
            return;
        }

        // Only set up the websocket once
        if (!ws.current) {
            const client = new WebSocket(config.wsServer[process.env.NODE_ENV] + 'kartupemilih/');
            ws.current = client;

            window.client = client;

            client.onerror = (e) => console.error(e);

            client.onopen = () => {
                setIsOnline(true);
                console.log('ws opened');
                client.send(JSON.stringify({ message: 'ping' }));
            };

            client.onmessage = message => {
                addMessage(message.data);
            };

            client.onclose = () => {

                if (ws.current) {
                    // Connection failed
                    console.log('ws closed by server');
                } else {
                    // Cleanup initiated from app side, can return here, to not attempt a reconnect
                    console.log('ws closed by app component unmount');
                    return;
                }

                if (waitingToReconnect) {
                    return;
                };

                // Parse event code and log
                setIsOnline(false);
                console.log('ws closed');

                // Setting this will trigger a re-run of the effect,
                // cleaning up the current websocket, but not setting
                // up a new one right away
                setWaitingToReconnect(true);

                // This will trigger another re-run, and because it is false,
                // the socket will be set up again
                setTimeout(() => setWaitingToReconnect(null), 3000);
            };

            return () => {

                console.log('Cleanup');
                // Dereference, so it will set up next time
                ws.current = null;

                client.close();
            }
        }
        // }
        // eslint-disable-next-line
    }, [waitingToReconnect]);


    useEffect(() => {
        if (waitingToReconnect) {



            // Only set up the websocket once
            if (!ws.current) {
                const client = new WebSocket(config.wsServer[process.env.NODE_ENV] + 'kartupemilih/');
                ws.current = client;

                window.client = client;

                client.onerror = (e) => console.error(e);



                client.onmessage = message => {
                    addMessage(message.data);
                };


                return () => {

                    console.log('Cleanup');
                    // Dereference, so it will set up next time
                    ws.current = null;

                    client.close();
                }
            }
        }
        // eslint-disable-next-line
    }, [waitingToReconnect, addMessage, listData]);

    return {
        isOnline, listData, pageValue, alertValue, handleChangeSearch, handleSearch, handleKeyDownSearch,
        handleGenerateVoucer, modalValue, setModalValue, handleCloseModal, handleSubmitGenerateVoucer, isLoading,
        importCount, totalImport, showCounting, successCouting, handlePaginate,
        formData, setFormData, handleEdit, handleDelete, handleSubmitDelete, view, isEdit, downloadExcel
    }
}