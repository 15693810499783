import { Icon } from "@iconify/react/dist/iconify.js";
import FooterHome from "../../../Components/Layout/FooterHome";
import { useHomeNew } from "./homeNewFunction";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import logo_default from "../../../assets/images/logo_default.png";
import coming_sun from "../../../assets/images/coming_sun.png";
import JadwalHtml from "../../../Components/Atoms/JadwalHtml";


const HomeNew = () => {
  const {
    info,
    leading0,
    days,
    hours,
    minutes,
    statusPendaftaran,
    listKategoriSponsor,
    handleRegister,
    canRegister,
    groupedSponsors
  } = useHomeNew();

  return (
    <Fragment>
      <div className="w-full h-screen  text-black relative flex flex-col items-center  bg-white">
        <div className="bg-[url('./assets/images/homenew/bgtopbatikmobile.png')] md:bg-[url('./assets/images/homenew/bgtopbatik.png')] h-[500px] bg-cover mt-0 bg-center bg-no-repeat w-full  md:min-h-[600px] flex flex-col justify-center items-center">
          <div className="px-[20px] md:px-[100px] relative w-full max-w-homeWidth flex flex-row md:mt-0 mt-[450px]">
            <div className="flex flex-col w-full h-full md:flex-row  text-white justify-start md:justify-between md:text-left text-center">
              <div className="mt-[20px] md:mt-[190px]">
                <div className="font-bold text-[30px] md:text-[35px]">
                  <div>{info?.site_name} </div>
                </div>
                <div className="font-medium text-[20px] ">
                  {info?.kota}
                </div>
                <div className="font-medium mt-[40px] md:mt-[105px] text-[18px] md:text-[22px]">
                  {statusPendaftaran === "Akan Dibuka" ? (
                    <div>Pendaftaran Akan Dibuka Pada </div>
                  ) : (
                    <>
                      {statusPendaftaran === "Ditutup" ? (
                        <div>Pendaftaran Sudah Ditutup Sejak </div>
                      ) : (
                        <div>Pendaftaran Akan Ditutup Pada </div>
                      )}
                    </>
                  )}

                  <div className="text-red-500 font-bold bg-white/40 text-center rounded-md">
                    {statusPendaftaran === "Akan Dibuka"
                      ? info?.tanggal_pendaftaran_event
                      : info?.tanggal_penutupan_event}
                  </div>
                </div>
                <div className="font-medium mt-[10px] md:mt-[30px]  text-[14px] flex flex-row items-center gap-3 md:justify-start justify-center">
                  <div className="text-green-500">
                    <Icon icon="ic:sharp-circle" />
                  </div>
                  <div className='text-white'>
                    Status Pendaftaran{" "}
                    {statusPendaftaran === "Ditutup"
                      ? `Sudah ${statusPendaftaran}`
                      : statusPendaftaran === "Akan Dibuka"
                        ? "Belum Dibuka"
                        : statusPendaftaran}
                  </div>
                </div>
              </div>

              <div className="mt-[0px] md:mt-[120px] z-40 relative flex flex-row justify-center">
                <div className="md:mt-0 mt-2 relative md:absolute w-full md:w-[400px] h-[440px] rounded-[10px] bg-[#fff] shadow-md z-10">
                  <div className="flex flex-col items-center w-full h-full py-[14px] px-[48px]">
                    <div className="flex flex-col items-center pb-[14px] border-b border-b-[#dddddd] w-full">
                      <div className="text-[28px] text-[#151515] font-thin">
                        {info?.bulan}
                      </div>
                      <div className="text-[36px] text-[#000] font-normal leading-7">
                        {info?.tanggal}
                      </div>
                      <div className="text-[14px] text-[#151515] font-thin">
                        {info?.hari}
                      </div>
                    </div>
                    <div className="mt-[32px] flex flex-row justify-start">
                      <div className="flex flex-col items-center gap-[10px]">
                        <div className="flex flex-row">
                          <div className="w-[40px] flex justify-center py-[12px] text-[#383838] text-[24px] bg-[#F0F0F0] rounded-[5px]">
                            {leading0(days).toString().length > 2
                              ? leading0(days).toString().substring(0, 2)
                              : leading0(days).toString().substring(0, 1)}
                          </div>
                          <div className="w-[40px] flex justify-center py-[12px] text-[#383838] text-[24px] bg-[#F0F0F0] ml-[8px] rounded-[5px]">
                            {leading0(days)
                              .toString()
                              .substr(leading0(days).toString().length - 1)}
                          </div>
                        </div>
                        <div className="text-[14px] text-[#707070]">Days</div>
                      </div>
                      <div className="text-[#707070] text-[24px] mx-[12px]">
                        :
                      </div>
                      <div className="flex flex-col items-center gap-[10px]">
                        <div className="flex flex-row">
                          <div className="w-[40px] flex justify-center py-[12px] text-[#383838] text-[24px] bg-[#F0F0F0] rounded-[5px]">
                            {leading0(hours).toString().length > 2
                              ? leading0(hours).toString().substring(0, 2)
                              : leading0(hours).toString().substring(0, 1)}
                          </div>
                          <div className="w-[40px] flex justify-center py-[12px] text-[#383838] text-[24px] bg-[#F0F0F0] rounded-[5px] ml-[8px]">
                            {leading0(hours)
                              .toString()
                              .substr(leading0(hours).toString().length - 1)}
                          </div>
                        </div>
                        <div className="text-[14px] text-[#707070]">Hours</div>
                      </div>
                      <div className="text-[#707070] text-[24px] mx-[12px]">
                        :
                      </div>
                      <div className="flex flex-col items-center gap-[10px]">
                        <div className="flex flex-row">
                          <div className="w-[40px] flex justify-center py-[12px] text-[#383838] text-[24px] bg-[#F0F0F0] rounded-[5px]">
                            {leading0(minutes).toString().length > 2
                              ? leading0(minutes).toString().substring(0, 2)
                              : leading0(minutes).toString().substring(0, 1)}
                          </div>
                          <div className="w-[40px] flex justify-center py-[12px] text-[#383838] text-[24px] bg-[#F0F0F0] rounded-[5px] ml-[8px]">
                            {leading0(minutes)
                              .toString()
                              .substr(leading0(minutes).toString().length - 1)}
                          </div>
                        </div>
                        <div className="text-[14px] text-[#707070]">
                          Minutes
                        </div>
                      </div>
                    </div>
                    <div className='text-[#707070] mt-3'>Menuju Pelaksanaan Munas</div>
                    <div className="absolute bottom-0 left-0 bg-[#F0F0F0] w-full h-[150px] rounded-b-[10px]">
                      <div className="flex flex-col h-full w-full py-[12px] px-[46px]">
                        <div className="w-full flex flex-row  gap-[18px] items-center">
                          <div className="text-[#B2ACAC] text-[28px]">
                            <Icon icon="subway:location-3" />
                          </div>
                          <div className="text-[#767575]">
                            <div className="text-[18px]">{info?.tempat}</div>
                            <div className="text-[14px]">{info?.kota}</div>
                            <div className="text-[14px] text-[#006CD0]">
                              <Link to="/lokasi">View Map</Link>
                            </div>
                          </div>
                        </div>
                        {canRegister && !info?.registrasi_disable && (
                          <div className="w-full mt-[10px]">
                            <button
                              className="bg-[#0075DD] text-white w-full py-[10px] rounded"
                              onClick={handleRegister}
                            >
                              REGISTRASI
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="hidden md:absolute w-[380px] h-[420px] rounded-[10px] bg-[#fff] shadow-md mt-[25px]"></div>
                <div className="hidden md:block w-[360px] h-[400px] rounded-[10px] bg-[#fff] shadow-md mt-[50px]"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="md:mt-0 mt-[300px] py-[100px] relative flex flex-col md:flex-row h-fit w-full justify-between items-start px-[20px] md:px-[100px] text-[#2C2C2C] gap-14 max-w-homeWidth">
          <div className="flex  flex-col w-full md:w-2/3 h-fit justify-center">
            <div className="font-light text-[25px] md:text-[40px]">
              EVENT OVERVIEW
            </div>
            <div className="text-[16px] md:text-[18px] font-light mt-[18px]">
              {info?.overview}
            </div>
          </div>
          <div className=" md:relative w-full md:w-1/3 h-fit flex-col ">
            <div className="bg-[url('./assets/images/homenew/halfcircle.png')] bg-contain bg-center bg-no-repeat flex flex-col items-center justify-center  h-[300px] ">
              <div className="font-bold text-[50px]  mt-[50px] ml-[13px] leading-9">
                {info?.total_perusahaan}
              </div>
              <div className="ml-[13px]">Total Perusahaan</div>
              <div className="ml-[13px]">Yang Sudah Mendaftar</div>
            </div>
          </div>
        </div>
        <div className="bg-[url('./assets/images/homenew/middlebg.png')] bg-cover bg-center bg-no-repeat w-full h-fit min-h-[500px] md:min-h-[800px] flex flex-col justify-start items-center">
          <div className="w-full max-w-homeWidth px-[30px] md:px-[100px] h-full">
            <div className="text-white font-light text-[25px] md:text-[40px] text-center">
              AGENDA
            </div>
            <div className="w-full h-[90%] bg-white flex justify-center overflow-auto p-5 rounded">
              <JadwalHtml />
            </div>
          </div>
        </div>
        <div className=" py-[40px] md:py-[80px] px-[30px] md:px-[100px] w-full max-w-homeWidth">
          <div className="font-light text-[25px] md:text-[40px] mb-4 md:mb-10 text-[#2C2C2C] text-center tracking-widest">
            SPONSORSHIP
          </div>

          <div className="relative w-full">
            <div className="flex flex-col flex-wrap text-[18px] font-light text-[#A1A1A1]">
              {listKategoriSponsor && listKategoriSponsor.map((kategori, index) => {
                const sponsorList = groupedSponsors[kategori?.nama] || []; // Dapatkan daftar sponsor untuk kategori ini

                // Tentukan ukuran berdasarkan index kategori, semakin kecil index semakin besar ukuran
                const baseWidth = 250 - index * 15;
                const baseHeight = 200 - index * 10;

                return (
                  <div key={index}>
                    {/* Kategori Title */}
                    <div className="text-gray-900 font-semibold flex justify-center mb-6 md:mb-12 text-2xl uppercase tracking-wide">
                      {kategori?.nama}
                    </div>

                    {/* Sponsor Grid */}
                    <div className="mb-8 md:mb-16">
                      <div className="flex justify-center flex-wrap gap-8 items-center">
                        {sponsorList.length > 0 ? (
                          sponsorList.map((sponsor) => (
                            <div
                              key={sponsor.id}
                              style={{ width: `${baseWidth}px`, height: `${baseHeight}px` }} // Gaya dinamis berdasarkan index kategori
                              className="bg-white shadow-lg rounded-lg p-4 flex items-center justify-center transition-transform duration-300 hover:scale-105"
                            >
                              <img
                                src={sponsor.logo}
                                alt={sponsor.nama}
                                className="max-w-full max-h-full object-contain"
                                onError={(e) => (e.target.src = logo_default)} // Ganti dengan logo default jika ada error
                              />
                            </div>
                          ))
                        ) : (
                          // Jika tidak ada sponsor, tampilkan logo "Coming Soon"
                          <div style={{ width: `${baseWidth}px`, height: `${baseHeight}px` }} className=" bg-white shadow-lg rounded-lg p-4 flex items-center justify-center transition-transform duration-300 hover:scale-105">
                            <img
                              src={coming_sun}
                              alt="Coming Soon"
                              className="max-w-full max-h-full object-contain"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>



        </div>



        <div className="bg-[url('./assets/images/homenew/bgfooternew.png')] bg-cover bg-center bg-no-repeat w-full h-fit min-h-[488px] flex flex-col text-white items-center px-[30px] md:px-[100px] relative">
          <FooterHome />
        </div>
      </div>
    </Fragment>
  );
};
export default HomeNew;
