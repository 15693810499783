import { useCallback, useContext, useEffect, useState } from "react";
import { useGlobalFunction } from "../../../../generalFunction";
import { ThemeContext } from "../../../../context/ThemeContext";
import config from "../../../../config";
import api from "../../../../api";
import { pageObj } from "../../../../pageObj";


export function useEmailTemplate(){
    const { showLoading, closeLoading, alertValue, setAlertValue, handleCloseAlert, modalValue, setModalValue, handleCloseModal,
        pageValue, setPageValue, catchFetching
    } = useGlobalFunction();
    const [view, setView] = useState('list');
    const [listData, setListData] = useState([]);
    const { setTitle } = useContext(ThemeContext)
    const [isEdit, setIsEdit] = useState(false);
    const [editorView, setEditorView] = useState('editor');
    const [htmlPreview, setHtmlPreview] = useState('');
    const [formData, setFormData] = useState({
        id : {
            name : 'id',
            label : 'ID',
            value : '',
            type : 'text',
            col : 2,
            required : false,
            show : false,
            errorMsg : '',
            showError : false,
            flexType : 'row'
        },
        kode : {
            name : 'kode',
            label : 'Kode',
            value : 1,
            type : 'select',
            col : 2,
            required : true,
            show : true,
            errorMsg : '',
            showError : false,
            flexType : 'row',
            obj : [
                {value : 1, label : 'Kekurangan Dokumen'},
                {value : 2, label : 'Verifikasi Email'},
                {value : 3, label : 'Dokumen Lengkap'},
                {value : 4, label : 'Informasi Login'},
                {value : 5, label : 'Reset Password'},
                {value : 6, label : 'Others'},
                {value : 7, label : 'Revisi Dokumen & Alasannya'},
            ]
        },
        subjek : {
            name : 'subjek',
            label : 'Subject',
            value : '',
            type : 'text',
            col : 2,
            required : true,
            show : true,
            errorMsg : '',
            showError : false,
            flexType : 'row',            
        },        
        css : {
            name : 'css',
            label : 'CSS',
            value : '',
            type : 'text',
            col : 2,
            required : false,
            show : false,
            errorMsg : '',
            showError : false,
            flexType : 'row',
        },
        text_body : {
            name : 'text_body',
            label : 'Body',
            value : '',
            type : 'editor',
            col : 2,
            required : false,
            show : true,
            errorMsg : '',
            showError : false,
        }
    });



    const data_helper = [
        {value : '[[daftar_dokumen_salah]]', label : 'Daftar Dokumen', tipe : 'kekurangan_dokumen'},
        {value : '[[nama_perusahaan]]', label : 'Nama Perusahaan', tipe : 'kekurangan_dokumen'},
        {value : '[[nama_perusahaan]]', label : 'Nama Perusahaan', tipe : 'verifikasi'},
        {value : '[[nama_event]]', label : 'Nama Event', tipe : 'verifikasi'},
        {value : '[[link_verifikasi]]', label : 'Link Verifikasi', tipe : 'verifikasi'},
        {value : '[[nama_perusahaan]]', label : 'Nama Perusahaan', tipe : 'lengkap'},
        {value : '[[nama_hotel]]', label : 'Nama Hotel', tipe : 'lengkap'},
        {value : '[[tempat_registrasi]]', label : 'Tempat Registrasi', tipe : 'lengkap'},
        {value : '[[nama_event]]', label : 'Nama Event', tipe : 'lengkap'},
        {value : '[[data_peserta]]', label : 'Data Peserta', tipe : 'lengkap'},
        {value : '[[username]]', label : 'Data Peserta', tipe : 'create_password'},
        {value : '[[nama_event]]', label : 'Data Peserta', tipe : 'create_password'},
        {value : '[[nama_perusahaan]]', label : 'Data Peserta', tipe : 'create_password'},
        {value : '[[nama_perusahaan]]', label : 'Data Peserta', tipe : 'reset_password'},
        {value : '[[link_verifikasi]]', label : 'Data Peserta', tipe : 'reset_password'},
        {value : '[[nama_event]]', label : 'Data Peserta', tipe : 'reset_password'},
        {value : '[[nama_perusahaan]]', label : 'Nama Perusahaan', tipe : 'others'},
        {value : '[[nama_event]]', label : 'Nama Event', tipe : 'others'},
        {value : '[[nama_hotel]]', label : 'Nama Hotel', tipe : 'others'},
        {value : '[[username]]', label : 'Data Peserta', tipe : 'others'},
        {value : '[[kekurangan_dokumen]]', label : 'Kekurangan Dokumen', tipe : 'others'},
        {value : '[[daftar_dokumen_salah]]', label : 'Daftar Dokumen', tipe : 'revisi_dokumen_alasan'},
        {value : '[[nama_perusahaan]]', label : 'Nama Perusahaan', tipe : 'revisi_dokumen_alasan'},
        {value : '[[alasan]]', label : 'Alasan', tipe : 'revisi_dokumen_alasan'},
    ]



    const fetchData = useCallback(async(q='', offset=0, limit=config.itemPerPage) => {
        showLoading();
        try{
            let params = {
                'offset' : offset,
                'limit' : limit
            }
            if (q){
                params['q'] = q;
            }
            const res = await api.get(`${config.endPoint.masterEmailTemplate}`, {params: params}).then(res => res.data);
            if (res){
                setListData(res.results);
                setPageValue(pageValue => ({...pageValue,
                    obj : pageObj(res.count, limit, offset),
                    lastPage: Math.ceil(parseInt(res.count) / parseInt(limit))
                }))
            }
            closeLoading();
        }catch(error){
            closeLoading();            
            let msg = "Unable to fetch data";
            if (error.response && error.response.data && error.response.data.message){
                msg = error.response.data.message
            }
            setAlertValue(alertValue => ({...alertValue, show: true, text : msg, color : 'danger'}))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))                
            }, config.timeOutValue);
        }
        // eslint-disable-next-line
    },[setAlertValue, setPageValue])

    useEffect(() => {
        fetchData();
    },[fetchData])

    useEffect(() => {
        setTitle('Email Template')
    }, [setTitle])

    const handleCreate = () => {
        setView('create');
        setIsEdit(false);
        setFormData(formData => ({...formData,
            id : {...formData.id, value : ''},
            kode : {...formData.kode, value : 1},
            subjek : {...formData.subjek, value : ''},
            css : {...formData.css, value : ''},
            text_body : {...formData.text_body, value : ''},
        }));
        setEditorView('editor');
    }

    const handleCancel = () => {
        setView('list');
    }

    const handleSubmit = async() => {
        showLoading();
        try{
            let countError = 0;
            let form = {...formData};
            let tmpForm = {}
            Object.entries(form).map(([index, post]) => {
                if (index !== 'redaksional'){
                    if (post.required && (post.value === '' || post.value === null)){
                        post.showError = true;
                        countError++;
                        console.log('ada error ', index, post);
                    }else{
                        tmpForm[index] = post.value;
                    }
                }
                return true;
            });
            
            if (countError > 0){
                setFormData(form);
                closeLoading(false);
                setAlertValue(alertValue => ({...alertValue, show: true, text : 'Lengkapi Data', color: 'danger'}));
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue);
                return;
            }            
            
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmpForm));
            
           
            let res;
            if (isEdit){
                res = await api.put(`${config.endPoint.masterEmailTemplate}${formData.id.value}/`, newForm).then(res => res.data);
            }else{
                res = await api.post(`${config.endPoint.masterEmailTemplate}`, newForm).then(res => res.data);
            }
            if (res){
                setView('list');
                fetchData();
            }
            closeLoading();     
        }catch(error){
            closeLoading();                 
            catchFetching(error, 'admin')

            let msg = "Unable to save data";
            if (error.response && error.response.data && error.response.data.message){
                msg = error.response.data.message
            }
            setAlertValue(alertValue => ({...alertValue, show: true, text : msg, color : 'danger'}))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))                
            }, config.timeOutValue);
        }
    }


    const handleEdit = (params) => {
        setIsEdit(true);
        setFormData(formData => ({...formData,
            id : {...formData.id, value : params.id},
            kode : {...formData.kode, value : params.kode},
            subjek : {...formData.subjek, value : params.subjek},
            css : {...formData.css, value : params.css},
            text_body : {...formData.text_body, value : params.text_body}
        }));        
        setView('create');
        setEditorView('editor');
    }

    const handleDelete = (params) => {
        setModalValue(modalValue => ({...modalValue,
            show: true,
            text : `Apakah anda akan menghapus template email dengan subject ${params.subjek}`,
            id : params.id,
            title : 'Konfirmasi'
        }))
    }
    
    const handleSubmitDelete = async() => {
        showLoading();
        try{
            const res = await api.delete(`${config.endPoint.masterEmailTemplate}${modalValue.id}/`).then(res => res.data);
            if(res){
                fetchData(pageValue.search, pageValue.offset);
                handleCloseModal();
                setAlertValue(alertValue => ({...alertValue, show: true, text : 'Data dihapus', color : 'danger'}))
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))                
                }, config.timeOutValue);
            }
            closeLoading();
        }catch(error){
            closeLoading();            
            catchFetching(error, 'admin')

            let msg = "Unable to save data";
            if (error.response && error.response.data && error.response.data.message){
                msg = error.response.data.message
            }
            setAlertValue(alertValue => ({...alertValue, show: true, text : msg, color : 'danger'}))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))                
            }, config.timeOutValue);
        }
    }

    const handleChangeSearch = (e) => {
        const { name, value } = e.target;
        setPageValue(pageValue => ({...pageValue,
            [name] : value
        }))
    }

    const handleSearch = () => {
        setPageValue(pageValue => ({...pageValue, offset: 0, page : 1}))
        fetchData(pageValue.search, 0, config.itemPerPage)
    }

    const handleKeyDownSearch = (e) => {
        if (e.key === "Enter"){
            handleSearch();
        }
    }

    const handlePaginate = (page) => {
        let myOffset = (parseInt(page) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
        setPageValue(pageValue => ({...pageValue, page : page, offset: myOffset}))
        fetchData(pageValue.search, myOffset, config.itemPerPage);
    }

    const handleChangeEditor = async(params) => {
        setEditorView(params);
        showLoading();
        if (params === 'preview'){
            try{
                let tmpForm = {
                    'string' : formData.text_body.value,
                    'link_url' : `${window.location.protocol}//${window.location.host}/member/email-verification`
                }
                let newForm = new FormData()
                newForm.append('formData', JSON.stringify(tmpForm))
                const res = await api.post(`${config.endPoint.replaceString}`, newForm).then(res => res.data);
                if (res){
                    setHtmlPreview(res.results);
                }
            }catch(error){
                closeLoading();
                // catchFetching(error, 'admin');
                let status = error && error.response && error.response.status ? error.response.status : '';

                if (status && status === 401) {
                    return window.location.href = "/admin/login"
                } else {
                    setAlertValue(alertValue => ({
                        ...alertValue,
                        text: 'Unable to fetch data',
                        color: 'danger',
                        show: true
                    }))
                    setTimeout(() => {
                        setAlertValue(alertValue => ({ ...alertValue, show: false }))
                    }, config.timeOutValue)
                }
            }
        }
        
        // setHtmlPreview(tmp);
    }

    return {
        showLoading, closeLoading, alertValue, setAlertValue, handleCloseAlert, modalValue, setModalValue, handleCloseModal, view,
        handleCreate, listData, isEdit, data_helper, pageValue, handleCancel,
        handleEdit, handleDelete, handleSubmit, handleSubmitDelete, handleChangeSearch, handleSearch, handleKeyDownSearch, handlePaginate,
        handleChangeEditor, editorView, htmlPreview, formData, setFormData
    }
}