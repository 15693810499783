import { Fragment } from "react"
import Card from "../../../../Components/Layout/Card";
import Tables from "../../../../Components/molecules/Tables";


import { useHotelHistory } from "./hotelHistoryFunction";
import FormTextSingleComp from "../../../../Components/forms/FormTextSingleComp";
import Search from "../../../../Components/Atoms/Search";
import AlertComp from "../../../../Components/Atoms/AlertComp";
import PagePagination from "../../../../Components/Atoms/PagePagination";


const HotelHistoryPage = () => {
    const { listData, pageValue,        
        alertValue, handleCloseAlert, handleChangeSearch,
        handleSearch, handlePaginate
    } = useHotelHistory();
    return(
        <Fragment>
            <AlertComp
                isOpen={alertValue.show}
                onClose={handleCloseAlert}
                text={alertValue.text}
                subText={alertValue.subText}
                color={alertValue.color}
                icon={alertValue.icon}
            />
            
            <Card
                title="Hotel History"
                isCreate={false}
                isSearch={false}  
                handleSearch={handleSearch}
                searchValue={pageValue.search}  
                onChangeSearch={handleChangeSearch}            
            >
                <div className='w-full flex flex-row justify-between mb-5 gap-2 h-fit items-center'>
                    <div>
                        <FormTextSingleComp
                            type={"select"}
                            obj={pageValue.hotel_state_obj}
                            name="hotel_state"
                            show={true}
                            value={pageValue.hotel_state}
                            gridClass={"-mt-2"}
                            onChange={handleChangeSearch}
                        />
                    </div>
                    <div>
                        <Search
                            placeholder={"Cari"}
                            value={pageValue.search}                            
                            onChange={handleChangeSearch}
                            handleSearch={handleSearch}
                        />
                    </div>
                </div>                
                <Tables className='border-separate rounded-sm'>
                    <Tables.Head className='bg-[#efeeee]'>
                        <tr>
                            <Tables.Header className='font-bold text-[13px] py-[10px] text-right'>#</Tables.Header>                            
                            <Tables.Header className='font-bold text-[13px] py-[10px]'>Tanggal</Tables.Header>            
                            <Tables.Header className='font-bold text-[13px] py-[10px]'>Nama Hotel</Tables.Header>            
                            <Tables.Header className='font-bold text-[13px] py-[10px]'>Nama Perusahaan</Tables.Header>
                            <Tables.Header className='font-bold text-[13px] py-[10px]'>Nama Peserta</Tables.Header>
                            <Tables.Header className='font-bold text-[13px] py-[10px]'>Deskripsi</Tables.Header>
                        </tr>
                    </Tables.Head>
                    <Tables.Body>
                        { listData.length === 0 ?
                            <Tables.Row>
                                <Tables.Data colspan={6} className='text-center'>Belum ada data</Tables.Data>
                            </Tables.Row>
                            :
                            <Fragment>
                                {listData.map((post, index) => {
                                    return (
                                        <Tables.Row key={index}>
                                            <Tables.Data className='text-right'>{pageValue.offset + index + 1}</Tables.Data>
                                            <Tables.Data>{post.tanggal}</Tables.Data>
                                            <Tables.Data>{post.hotel_display}</Tables.Data>
                                            <Tables.Data>{post.kontak_display}</Tables.Data>
                                            <Tables.Data>{post.peserta_display}</Tables.Data>
                                            <Tables.Data>{post.diskripsi}</Tables.Data>
                                        </Tables.Row>
                                    )
                                })}
                            </Fragment>
                        }
                    </Tables.Body>
                </Tables>
                <div className='mt-5 flex flex-row justify-start w-full'>
                    <PagePagination
                        data={pageValue.obj}
                        handlePagination={handlePaginate}
                        page={pageValue.page}
                        lastPage={pageValue.lastPage}
                    />
                </div>
            </Card>
        </Fragment>
    )
}
export default HotelHistoryPage;