import React, { useState, useRef } from 'react'
import ReactCrop, { centerCrop, makeAspectCrop, convertToPixelCrop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

import { canvasPreview } from '../../utils/canvasPreview'
import { useDebounceEffect } from '../../utils/useDebounceEffect'

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    )
}

function CropImage({ src, preview = false, useToggleAcpect = false, onCropComplete, handleDownload = false }) {
    const previewCanvasRef = useRef(null)
    const imgRef = useRef(null)
    const [crop, setCrop] = useState(null)
    const [completedCrop, setCompletedCrop] = useState(null)
    const [scale, setScale] = useState(1)
    const [rotate, setRotate] = useState(0)
    // const [aspect, setAspect] = useState(undefined)
    const [aspect, setAspect] = useState(3 / 4)

    function onImageLoad(e) {
        if (aspect) {
            const { width, height } = e.currentTarget
            setCrop(centerAspectCrop(width, height, aspect))
        }
    }


    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                await canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale,
                    rotate,
                )

                // Generate the blob and send it via onCropComplete callback
                previewCanvasRef.current.toBlob(
                    (blob) => {
                        if (blob && onCropComplete) {
                            // Assuming imgRef.current.currentSrc contains the URL
                            const url = imgRef.current.currentSrc;
                            // Extract the file name from the URL
                            const fileName = url.split('/').pop();

                            const file = new File([blob], fileName, { type: 'image/png' })
                            onCropComplete(file)
                        }
                    },
                    'image/png',
                    1
                )
            }
        },
        100,
        [completedCrop, scale, rotate],
    )

    function handleToggleAspectClick() {
        if (aspect) {
            setAspect(undefined)
        } else {
            setAspect(16 / 9)

            if (imgRef.current) {
                const { width, height } = imgRef.current
                const newCrop = centerAspectCrop(width, height, 16 / 9)
                setCrop(newCrop)
                setCompletedCrop(convertToPixelCrop(newCrop, width, height))
            }
        }
    }

    function onDownloadCropClick() {
        if (!previewCanvasRef.current) return;

        previewCanvasRef.current.toBlob(
            (blob) => {
                // Assuming imgRef.current.currentSrc contains the URL
                const url = imgRef.current.currentSrc;

                // Extract the file name from the URL
                const fileName = url.split('/').pop();

                
                if (!blob) return;
                
                // Convert the blob to a file object
                const file = new File([blob], fileName, { type: 'image/png' });
                
                console.log(file);
                // Do something with the file, like upload to server

                // Create a temporary link for downloading the image
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob); // Convert the blob to an object URL
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(link.href); // Release the object URL after download
            },
            'image/png',
            1 // quality (from 0 to 1, where 1 is the highest quality)
        );
    }

    return (
        <div className="p-4">
            <div className='grid grid-cols-12 gap-4'>
                <div className='col-span-full md:col-span-2'><div className="space-y-2">
                    <div className='font-semibold'>Kalibrasi posisi foto :</div>
                    <hr className='mb-4' />

                    <div>
                        <label htmlFor="scale-input" className="block text-sm font-medium text-gray-700">Scale:</label>
                        <input
                            id="scale-input"
                            autoFocus
                            type="number"
                            step="0.1"
                            value={scale}
                            disabled={!src}
                            onChange={(e) => setScale(Number(e.target.value))}
                            className="border border-gray-300 rounded-md p-2 text-sm w-full"
                        />
                    </div>
                    <div>
                        <label htmlFor="rotate-input" className="block text-sm font-medium text-gray-700">Rotate:</label>
                        <input
                            id="rotate-input"
                            type="number"
                            value={rotate}
                            disabled={!src}
                            onChange={(e) =>
                                setRotate(Math.min(180, Math.max(-180, Number(e.target.value))))
                            }
                            className="border border-gray-300 rounded-md p-2 text-sm w-full"
                        />
                    </div>
                    {useToggleAcpect && (
                        <div>
                            <button
                                onClick={handleToggleAspectClick}
                                className={`px-4 py-2 text-white rounded-md focus:outline-none focus:ring-2 ${aspect ? 'focus:ring-blue-500 bg-blue-500 hover:bg-blue-600' : 'focus:ring-violet-500 bg-violet-500 hover:bg-violet-600'}`}
                            >
                                Toggle aspect {aspect ? 'off' : 'on'}
                            </button>
                        </div>
                    )}
                </div></div>
                <div className='col-span-full md:col-span-6'> {!!src && (
                    <div className="relative">
                        <div className='font-semibold mb-2'>Silakan lakukan ( tekan klik kiri mouse dan geser mouse pada gambar) untuk melakukan crop foto:</div>
                        <hr className='mb-4' />
                        <ReactCrop
                            crop={crop}
                            onChange={(_, percentCrop) => setCrop(percentCrop)}
                            onComplete={(c) => setCompletedCrop(c)}
                            aspect={aspect}
                        >
                            <img
                                ref={imgRef}
                                crossOrigin="anonymous"
                                alt="Crop me"
                                src={src}
                                style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                                onLoad={onImageLoad}
                                // className="max-w-full max-h-full object-contain"
                                className="w-[25rem] object-contain"
                            />
                        </ReactCrop>
                    </div>
                )}</div>
                <div className='col-span-full md:col-span-2'>
                    {preview && (
                        <>
                            {!!completedCrop && (
                                <div className="mt-4">
                                    <div className='font-semibold mb-2'>Hasil Crop Foto :</div>
                                    <canvas
                                        ref={previewCanvasRef}
                                        crossorigin="anonymous"
                                        style={{
                                            border: '1px solid black',
                                            objectFit: 'contain',
                                            width: completedCrop.width,
                                            height: completedCrop.height,
                                        }}
                                        className="border border-gray-300 w-full"
                                    />

                                    {handleDownload && (
                                        <div className="mt-4">
                                            <button
                                                onClick={onDownloadCropClick}
                                                className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500"
                                            >
                                                Download Crop
                                            </button>
                                        </div>
                                    )}
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default CropImage
