import { useCallback, useContext, useEffect, useState } from "react";
import { useGlobalFunction } from "../../../../generalFunction";
import api from "../../../../api";
import config from "../../../../config";
import { pageObj } from "../../../../pageObj";
import { ThemeContext } from "../../../../context/ThemeContext";


export function useKandidatKetum(){
    const { isLoading, setIsLoading, pageValue, setPageValue, showLoading, closeLoading, 
        alertValue, setAlertValue, modalValue, setModalValue, handleCloseModal, handleCloseAlert,
    } = useGlobalFunction();
    const [view, setView] = useState('list');
    const [listData, setListData] = useState({isLoading : false, data:[]});
    const [listVisi, setListVisi] = useState([]);
    const [listMisi, setListMisi] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const { setTitle } =useContext(ThemeContext);

    useEffect(() => {
        setTitle('Kandidat Ketua Umum')
    }, [setTitle])

    const [formData, setFormData] = useState({
        id : {
            name : 'id',
            type: 'text',
            label : 'ID',
            value : '',
            required : false,
            showError : false,
            show: false,
            readOnly: true,
        },
        nama : {
            name : 'nama',
            type: 'text',
            label : 'Nama',
            value : '',
            required : true,
            showError : false,
            show: true,
            readOnly: true,
        },
        perusahaan : {
            name : 'perusahaan',
            type: 'text',
            label : 'Perusahaan',
            value : '',
            required : true,
            showError : false,
            show: true,
            readOnly: true,
        },
        foto : {
            name : 'foto',
            type: 'image',
            label : 'Foto',
            value : '',
            required : true,
            showError : false,
            show: true,
            readOnly: true,
        },
    })

    const fetchData = useCallback(async(q='', offset=0, limit=config.itemPerPage) => {
        showLoading()
        try{
            const params = {
                offset : offset,
                limit : limit,
            }
            if (q) {
                params['q'] = q
            }
            const res = await api.get(`${config.endPoint.kandidatKetum}`, {params: params}).then(res => res.data);
            if (res){
                setListData(listData => ({...listData, isLoading: false, data: res.results}))
                setPageValue(pageValue => ({...pageValue,
                    obj : pageObj(res.count, limit, offset),
                    lastPage : Math.ceil(parseInt(res.count) / parseInt(limit))
                }))
            }else{
                setListData(listData => ({...listData, isLoading: false}))
            }
            closeLoading()
        }catch(error){
            setListData(listData => ({...listData, isLoading: false}))
            closeLoading()
            const { response } = error;
            let msg = 'Unable to fetch data'
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            setAlertValue(alertValue => ({...alertValue,
                show: true,
                text : 'Error',
                subText: msg,
                color: 'danger'
            }))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
        // eslint-disable-next-line 
    },[])
    
    useEffect(() => {
        fetchData();
    },[fetchData])

    const handleCreate = async() => {
        setIsLoading(true);
        try{
            const res = await api.post(`${config.endPoint.fetchingCalon}`).then(res => res.data);
            if (res){
                setAlertValue(alertValue => ({...alertValue,
                    show: true,
                    text : 'Process',
                    subText : 'Silakan tunggu, kami sedang memproses import data. refresh browser setelah 5 menit'
                }))

                setTimeout(() => {
                    handleCloseAlert();
                },10000)
            }

        }catch(error){
            setIsLoading(false);
            setIsLoading(false);
            const { response } = error;
            let msg = 'Unable to save data'
            
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            
            setAlertValue(alertValue => ({...alertValue,
                show: true,
                text : 'Error',
                subText: msg,
                color: 'danger'
            }))
            
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }

    const handleSubmit = async() => {
        setIsLoading(true);
        try{
            
            
            let obj = {...formData}
            let countError = 0;
            let tmp = {}
            Object.entries(obj).map(([index, post]) => {
                if (post.required && post.value === ''){
                    post.showError= true;
                    countError++;
                }else{
                    tmp[index] = post.value
                }
                    return true;
            })
            
            if (countError > 0){
                setAlertValue(alertValue => ({...alertValue,
                    show: true,
                    text : 'Error',
                    subText: 'Lengkapi data',
                    color: 'danger'
                }))
                setIsLoading(false);
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue)
            }
            
            // tmp['foto'] = resizeFile(tmp['foto'], 'base64')


            let newForm = new FormData();        
            newForm.append('formData', JSON.stringify(tmp));
            newForm.append('formVisi', JSON.stringify(listVisi.filter(x => x.value !== '').map(x => x.value)));
            newForm.append('formMisi', JSON.stringify(listMisi.filter(x => x.value !== '').map(x => x.value)));

            let res ;
            if (isEdit){
                res = await api.put(`${config.endPoint.kandidatKetum}${formData.id.value}/`, newForm).then(res => res.data);
            }else{
                res = await api.post(`${config.endPoint.kandidatKetum}`, newForm).then(res => res.data);
            }
            if (res){
                setAlertValue(alertValue => ({...alertValue,
                    show: true,
                    text : 'Success',
                    subText: 'Data berhasil disimpan',
                    color: 'success'
                }))
            
                fetchData(pageValue.search, pageValue.offset, config.itemPerPage)
            
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue)
            }
            setView('list');
        }catch(error){
            setIsLoading(false);
            const { response } = error;
            let msg = 'Unable to save data'
            
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            
            setAlertValue(alertValue => ({...alertValue,
                show: true,
                text : 'Error',
                subText: msg,
                color: 'danger'
            }))
            
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }

    const handleCancel = () => {
        setView('list')
    }

    const handleAddVisi = () => {
        let tmpForm = {
            name : 'nama',
            type: 'text',
            label : 'Nama',
            value : '',
            required : true,
            showError : false,
            show: true,
            readOnly: true,
        }
        let array = [...listVisi];
        array.push(tmpForm)
        setListVisi(array);
    }

    const handleAddMisi = () => {
        let tmpForm = {
            name : 'nama',
            type: 'text',
            label : 'Nama',
            value : '',
            required : true,
            showError : false,
            show: true,
            readOnly: true,
        }
        let array = [...listMisi];
        array.push(tmpForm)
        setListMisi(array);
    }

    const  handleDeleteVisiMisi = (params, index) => {
        if (params === 'visi') {
            let array = [...listVisi];
            array.splice(index, 1);
            setListVisi(array);
        } else if (params === 'misi') {
            let array = [...listMisi];
            array.splice(index, 1);
            setListMisi(array);
        }
    }

    const handleChangeVisi = (e, index) => {
        const { value } = e.target;
        let array = [...listVisi];
        array[index] = {...array[index], value : value}
        setListVisi(array);
    }

    const handleChangeMisi = (e, index) => {
        const { value } = e.target;
        let array = [...listMisi];
        array[index] = {...array[index], value : value}
        setListMisi(array);
    }
        
    const handleEdit = (post) => {
        setView('create');
        setIsEdit('true');
        setFormData(formData => ({...formData,
            id : {...formData.id, value : post.id},
            nama : {...formData.nama, value : post.nama},
            perusahaan : {...formData.perusahaan, value : post.perusahaan},
            foto : {...formData.foto, value : post.foto},
        }))

        
        let tmpVisi = []
        post.visi.map(post => {
            let tmpForm = {
                name : 'nama',
                type: 'text',
                label : 'Nama',
                value : '',
                required : true,
                showError : false,
                show: true,
                readOnly: true,
            }
            tmpForm['value'] = post
            tmpVisi.push(tmpForm);
            return true;
        })
        setListVisi(tmpVisi)

        let tmpMisi = []
        post.misi.map(post => {
            let tmpForm = {
                name : 'nama',
                type: 'text',
                label : 'Nama',
                value : '',
                required : true,
                showError : false,
                show: true,
                readOnly: true,
            }
            tmpForm['value'] = post;
            tmpMisi.push(tmpForm);
            return true;
        })
        setListMisi(tmpMisi)
    }       

    const handleDelete = (params) => {
        setModalValue(modalValue => ({...modalValue,
            show: true,
            text: `Apakah anda yakin akan menghapus daftar pemilih ${params.nama} ?`,
            id : params.id,
            title : 'Konfirmasi',
        }))
    }
    
    const handleSubmitDelete = async() => {
        setIsLoading(true);
        try{
            const res = await api.delete(`${config.endPoint.kandidatKetum}${modalValue.id}/`).then(res => res.data);
            if (res){
                fetchData(pageValue.search, pageValue.offset, config.itemPerPage);
                setAlertValue(alertValue => ({...alertValue,
                    show: true,
                    text : 'Delete Success',
                    subText: 'Data berhasil di delete',
                    color: 'success'
                }))
    
                setTimeout(() => {
                    handleCloseAlert();
                }, config.timeOutValue)
            }
            handleCloseModal()
        }catch(error){
            setIsLoading(false);
            const { response } = error;
            let msg = 'Unable to delete data'
    
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            setAlertValue(alertValue => ({...alertValue,
                show: true,
                text : 'Error',
                subText: msg,
                color: 'danger'
            }))
    
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }

    const handleChangeSearch = (e) => {
        const { name, value } = e.target;
        setPageValue(pageValue => ({...pageValue, [name] : value}))
    }
    
    const handleKeyDownSearch = (e) => {
        if (e.key === 'Enter'){
            handleSearch();
        }
    }
    
    const handleSearch = () => {
        fetchData(pageValue.search, 0, config.itemPerPage)
    }

    const handlePaginate = (page) => {
        let myOffset = (parseInt(page) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
        setPageValue(pageValue => ({...pageValue, page : page, offset: myOffset}))
        fetchData(pageValue.search, myOffset, config.itemPerPage);
    }

    

    return {
        isLoading, setIsLoading, view, handleCreate, formData, setFormData, pageValue, setPageValue,
        listData, setListData, handleSubmit, handleCancel, handleAddMisi, handleAddVisi,
        handleDeleteVisiMisi, listVisi, listMisi, handleChangeVisi, handleChangeMisi, alertValue,
        handleEdit, handleDelete, handleSubmitDelete,
        handleKeyDownSearch, handleSearch, handlePaginate, handleChangeSearch, modalValue, setModalValue,
        handleCloseModal, handleCloseAlert

    }
}