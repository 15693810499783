import { Fragment } from "react"
import PagePagination from "../../../../Components/Atoms/PagePagination"
import Card from "../../../../Components/Layout/Card"
import Tables from "../../../../Components/molecules/Tables"

import { usePesertaLengkap } from "./pesertaLengkapFunction"


const PesertaLengkapPage = () => {
    const { pageValue, handleChangeSearch,  handleSearch,
        listData, handlePaginate
    } = usePesertaLengkap()

  
    return(
        <div>
            <Card
                title='Master Kandidat Ketua Umum'
                isCreate={false}
                isSearch={true}
                searchValue={pageValue.search}
                // handleCreate={handleCreate}
                // handleSubmit={handleSubmit}
                // handleCancel={handleCancel}
                onChangeSearch={handleChangeSearch}
                handleSearch={handleSearch}
            >
                
                
                <Tables className='border-separate rounded-sm'>
                    <Tables.Head className='bg-[#efeeee]'>
                        <tr>
                            <Tables.Header className='font-bold text-[13px] py-[10px] text-right'>#</Tables.Header>
                            <Tables.Header className='font-bold text-[13px] py-[10px]'>Nama Perusahaan</Tables.Header>
                            <Tables.Header className='font-bold text-[13px] py-[10px]'>Nama Peserta</Tables.Header>
                            <Tables.Header className='font-bold text-[13px] py-[10px]'>Hotel</Tables.Header>
                        </tr>
                    </Tables.Head>
                    <Tables.Body>
                        { listData?.data?.length === 0 ?
                            <Tables.Row>
                                <Tables.Data colspan={4} className='text-center'>Belum ada data</Tables.Data>
                            </Tables.Row>
                            :
                            <Fragment>
                                {listData?.data?.map((post, index) => {
                                    
                                    return (
                                        <Tables.Row key={index}>
                                            <Tables.Data className='text-right align-top'>{pageValue.offset + index + 1}</Tables.Data>
                                            <Tables.Data className='align-top'>{post.perusahaan_display}</Tables.Data>
                                            <Tables.Data className='align-top'>{post.nama}</Tables.Data>
                                            <Tables.Data className='align-top'>{post.hotel_display}</Tables.Data>
                                            
                                        </Tables.Row>
                                    )
                                })}
                            </Fragment>
                        }
                    </Tables.Body>
                </Tables>
                <div className='mt-5'>
                    <PagePagination
                        data={pageValue.obj}
                        handlePagination={handlePaginate}
                        page={pageValue.page}
                        lastPage={pageValue.lastPage}
                    />
                </div>                
                
            </Card>
        </div>
    )
}
export default PesertaLengkapPage