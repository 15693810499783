import { Fragment } from "react"
import Card from "../../../../Components/Layout/Card";
import Tables from "../../../../Components/molecules/Tables";
import { Icon } from "@iconify/react/dist/iconify.js";
import Modal from "../../../../Components/Atoms/Modal";
import Button from "../../../../Components/Atoms/Button";
import FormTextSingleComp from "../../../../Components/forms/FormTextSingleComp";
import { useUser } from "./userFunction";
import PagePagination from "../../../../Components/Atoms/PagePagination";


const UserPage = () => {
    const { listData, pageValue,
        handleDelete, handleSubmit, modalValue, 
        setModalValue, handleSubmitDelete, handleCloseModal,
        handleCancel, handleCreate, view, formData, setFormData, handleEdit,
        listGroup, selectedGroup, handleSelectGroup, handlePaginate, handleChangeSearch,
        handleSearch
    } = useUser();
    
    return(
        <Fragment>
            <Modal
                show={modalValue.show}
                setShow={() => {                        
                    setModalValue(modalValue => ({...modalValue, show: false}))
                }}
                width="xl"
                persistent={false}   
                btnClose={false}   
                title="Konfirmasi"
                withHeader={true}
            >

                <div className='p-[20px]'>
                    { modalValue.text}
                </div>
                <div className='border-t border-t-gray-200 p-[20px]'>
                    <div className="flex flex-row justify-end gap-2">
                        <Button className='bg-red-500 text-white' onClick={handleSubmitDelete}>Hapus</Button>
                        <Button className='bg-gray-400 text-white' onClick={handleCloseModal}>Cancel</Button>
                    </div>
                </div>
            </Modal>
            <Card
                title='Master Kandidat Ketua Umum'
                isCreate={view === 'create'}
                isSearch={view === 'list'}
                searchValue={pageValue.search}
                handleCreate={handleCreate}
                handleSubmit={handleSubmit}
                handleCancel={handleCancel}
                onChangeSearch={handleChangeSearch}
                handleSearch={handleSearch}
            >
                { view === 'create' &&
                    <div className='grid grid-cols-2 gap-5'>
                        <div className="col-span-2 md:col-span-1">
                            <FormTextSingleComp
                                {...formData.first_name}
                                formData={formData}
                                setFormData={setFormData}
                            />
                            <div className="mt-5">
                                <FormTextSingleComp
                                    {...formData.username}
                                    formData={formData}
                                    setFormData={setFormData}
                                />
                            </div>
                            <div className="mt-5">
                                <FormTextSingleComp
                                    {...formData.password}
                                    formData={formData}
                                    setFormData={setFormData}
                                />
                            </div>
                            <div className="mt-5">
                                <FormTextSingleComp
                                    {...formData.confirm_password}
                                    formData={formData}
                                    setFormData={setFormData}
                                />
                            </div>
                        </div>
                        <div className="col-span-2 md:col-span-1">
                            <div className='font-bold'>Group Permissions</div>
                            <div className='mt-4'>
                                { listGroup?.data?.map((post, index) => {
                                    return(
                                        <div className='flex flex-row gap-4 items-center'>
                                            <input
                                                id="default-checkbox"
                                                type="checkbox"
                                                value=""
                                                className="w-4 h-4 
                                                    text-gray-600 
                                                    bg-gray-100 
                                                    border-gray-300 
                                                    rounded 
                                                    
                                                    dark:ring-offset-gray-800 
                                                    focus:ring-2 
                                                    dark:bg-gray-700 
                                                    dark:border-gray-600"
                                                checked={selectedGroup.includes(post.id)}
                                                onChange={() => handleSelectGroup(post)}
                                                name={"permissions_group"}
                                            />
                                            <div>{post.name}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                }
                { view === 'list' &&
                    <Fragment>
                        <Tables className='border-separate rounded-sm'>
                            <Tables.Head className='bg-[#efeeee]'>
                                <tr>
                                    <Tables.Header className='font-bold text-[13px] py-[10px] text-right'>#</Tables.Header>                            
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>Nama</Tables.Header>            
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>Username</Tables.Header>
                                    <Tables.Header className='font-bold text-[13px] py-[10px] text-center'>Action</Tables.Header>
                                </tr>
                            </Tables.Head>
                            <Tables.Body>
                                { listData?.data?.length === 0 ?
                                    <Tables.Row>
                                        <Tables.Data colspan={4} className='text-center'>Belum ada data</Tables.Data>
                                    </Tables.Row>
                                    :
                                    <Fragment>
                                        {listData?.data?.map((post, index) => {
                                            return (
                                                <Tables.Row key={index}>
                                                    <Tables.Data className='text-right'>{pageValue.offset + index + 1}</Tables.Data>
                                                    <Tables.Data>{post.first_name}</Tables.Data>                                                    
                                                    <Tables.Data>{post.username}</Tables.Data>
                                                    <Tables.Data className='text-center'>
                                                        <div className="flex flex-row justify-center gap-1">
                                                            <button onClick={() => handleDelete(post)} className='px-2 py-1 bg-red-500 text-white rounded'>
                                                                <Icon icon="ph:trash-fill" />
                                                            </button>
                                                            <button onClick={() => handleEdit(post)} className='px-2 py-1 bg-indigo-500 text-white rounded'>
                                                                <Icon icon="mage:edit" />
                                                            </button>
                                                        </div>
                                                    </Tables.Data>
                                                </Tables.Row>
                                            )
                                        })}
                                    </Fragment>
                                }
                            </Tables.Body>
                        </Tables>
                        <div className="mt-5">
                            <PagePagination
                                data={pageValue.obj}
                                handlePagination={handlePaginate}
                                page={pageValue.page}
                                lastPage={pageValue.lastPage}
                            />
                        </div>
                    </Fragment>
                }
            </Card>
        </Fragment>
    )
}
export default UserPage