import { Fragment, useCallback, useEffect, useState } from "react";
// import { useGlobalFunction } from "../../../../generalFunction/generalFunction";
import config from "../../../../config";
import api from "../../../../api";
// import { pageObj } from "../../../generalFunction/pageObj";
import HotelDashboardAtom from "./HotelDashboardAtom";
import AlertComp from "../../../../Components/Atoms/AlertComp";
import { useGlobalFunction } from "../../../../generalFunction";
// import AlertComp from "../../../../component/alert/AlertComp";



const HotelDashboardComp = () => {

    const [listData, setListData] = useState([]);
    const { alertValue, setAlertValue,
        // pageValue, setPageValue, 
        catchFetching,
        handleCloseAlert
    } = useGlobalFunction();


    const color = ['bg-green-500', 'bg-orange-400', 'bg-cyan-500', 'bg-blue-500', 'bg-violet-500', 'bg-indigo-500', 'bg-red-500', 'bg-orange-600']

    const fetchData = useCallback(async (offset = 0, limit = 9999) => {
        // setIsLoading(true);
        try {
            let params = {
                offset: offset,
                limit: limit
            }
            const res = await api.get(`${config.endPoint.hotel}`, { params: params }).then(res => res.data);
            let tmp = []
            if (res) {
                res.results.map(post => {
                    let obj = { ...post };
                    let persen = (parseInt(post.terisi) / parseInt(post.jumlah_kamar)) * 100
                    persen = Math.ceil(persen)
                    obj['persen'] = persen
                    // obj['link'] = userPermState &&  userPermState.includes('view_site_setting') ? `/admin/master/hotel?q=${post.encrypt}` : `/admin/registrasi/hotel?q=${post.encrypt}`
                    obj['link'] = `/admin/registrasi/hotel-peserta?q=${post.encrypt}`
                    // obj['persen'] = 0;
                    // obj['link'] = ''
                    tmp.push(obj);
                    return true;
                })
                setListData(tmp);
                // setPageValue(pageValue => ({...pageValue,
                //     obj : pageObj(res.count, limit, offset),
                //     lastPage : Math.ceil(parseInt(res.count) / parseInt(limit))
                // }))
            }
        } catch (error) {
            catchFetching(error, 'admin');
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Unable to fetch data', color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Unable to fetch data', color: 'danger' }));
            }, config.timeOutValue)
        }
        // eslint-disable-next-line
    }, [setAlertValue])

    useEffect(() => {
        fetchData();
    }, [fetchData])




    return (
        <Fragment>
            <AlertComp
                text={alertValue.text}
                subText={alertValue.subText}
                color={alertValue.color}
                isOpen={alertValue.show}
                onClose={handleCloseAlert}
            />
            <div className="grid bg-white">
                <div className='px-5 py-2 font-bold border border-gray-100 rounded-t'>Hotel</div>
                <div className="grid grid-cols-2 py-5 border border-gray-100 rounded-b">
                    {/* Bagian kiri untuk elemen dengan index ganjil */}
                    <div className='border-r border-r-gray-200 px-8'>
                        {Array.from(listData)
                            .filter((_, index) => index % 2 === 0)
                            .map((post, index) => {
                                return (
                                    <HotelDashboardAtom
                                        key={index}
                                        title={post.nama}
                                        terisi={post.terisi}
                                        kapasitas={post.jumlah_kamar}
                                        color={color[index]}
                                        persen={`${post.persen}%`}
                                        link={`${post.link}`}
                                    />
                                );
                            })}
                    </div>

                    {/* Bagian kanan untuk elemen dengan index genap */}
                    <div className='px-8'>
                        {Array.from(listData)
                            .filter((_, index) => index % 2 !== 0)
                            .map((post, index) => {
                                return (
                                    <HotelDashboardAtom
                                        key={index}
                                        title={post.nama}
                                        terisi={post.terisi}
                                        kapasitas={post.jumlah_kamar}
                                        color={color[index]}
                                        persen={`${post.persen}%`}
                                        link={`${post.link}`}
                                    />
                                );
                            })}
                    </div>

                </div>
            </div>
        </Fragment>
    )
}
// eslint-disable-next-line 
export default HotelDashboardComp;