import BookMarksRight from "../../Bookmarks/BookmarksRight"


const LokasiPage = ({goToPage, page}) => {
    return(
        <div
            className="page demoPage h-full overflow-y-hidden font-poppins
                w-full
            "
        >
            <div className="w-full h-full">
                <BookMarksRight goToPage={goToPage} page={page}/>
                <div 
                    className='bg-[linear-gradient(-90deg,rgba(236,236,236,1)0%,rgba(237,237,237,1)26%,rgba(250,250,250,1)77%,rgba(240,240,240,1)83%,rgba(232,232,232,1)87%,rgba(216,216,216,1)92%,rgba(200,200,200,1)96%,rgba(155,155,155,1)99%,rgba(179,179,179,1)100%)] w-full h-full'
                >
                    Lokasi Page Page 14
                </div>
            </div>
        </div>
    )
}

export default LokasiPage