import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useGlobalFunction } from "../../../../generalFunction";
import config from "../../../../config";
import { pageObj } from "../../../../pageObj";
import api from "../../../../api";
import { UserContext } from "../../../../context/UserContext";

export function useIdCardPanitia() {
  const {
    isLoading,
    setIsLoading,
    alertValue,
    setAlertValue,
    handleCloseAlert,
    modalValue,
    setModalValue,
    handleCloseModal,
    pageValue,
    setPageValue,
    closeLoading,
    showLoading,
    catchFetching,
  } = useGlobalFunction();
  const { setting } = useContext(UserContext)
  const [listData, setListData] = useState({ isLoading: isLoading, data: [] });
  const [view, setView] = useState("list");
  const fetchData = useCallback(
    async (q = "", offset = 0, limit = config.itemPerPage) => {
      showLoading();
      setListData((listData) => ({ ...listData, isLoading: true }));
      try {
        const params = {
          offset: offset,
          limit: limit,
          tipe: "idcard-panitia",
        };
        if (q) {
          params["q"] = q;
        }
        const res = await api
          .get(`${config.endPoint.kontak}`, { params: params })
          .then((res) => res.data);
        if (res) {
          setListData((listData) => ({
            ...listData,
            isLoading: false,
            data: res.results,
          }));
          setPageValue((pageValue) => ({
            ...pageValue,
            obj: pageObj(res.count, limit, offset),
            lastPage: Math.ceil(parseInt(res.count) / parseInt(limit)),
          }));
        } else {
          setListData((listData) => ({ ...listData, isLoading: false }));
        }
        closeLoading();
      } catch (error) {
        setListData((listData) => ({ ...listData, isLoading: false }));
        closeLoading();
        const { response } = error;
        let msg = "Unable to fetch data";
        if (response && response.data) {
          msg = response.data?.message;
        }
        setAlertValue((alertValue) => ({
          ...alertValue,
          show: true,
          text: "Error",
          subText: msg,
          color: "danger",
        }));
        setTimeout(() => {
          setAlertValue((alertValue) => ({ ...alertValue, show: false }));
        }, config.timeOutValue);
      }
    },
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleChangeSearch = (e) => {
    const { name, value } = e.target;
    setPageValue((pageValue) => ({ ...pageValue, [name]: value }));
  };

  const handleKeyDownSearch = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleSearch = () => {
    fetchData(pageValue.search, 0, config.itemPerPage);
  };

  const handlePaginate = (page) => {
    let myOffset =
      parseInt(page) * parseInt(config.itemPerPage) -
      parseInt(config.itemPerPage);
    setPageValue((pageValue) => ({
      ...pageValue,
      page: page,
      offset: myOffset,
    }));
    fetchData(pageValue.search, myOffset, config.itemPerPage);
  };

  // ws
  const ws = useRef(null);
  const [isOnline, setIsOnline] = useState(false);
  const [waitingToReconnect, setWaitingToReconnect] = useState(null);
  const [showCounting, setShowCounting] = useState(false);
  const [successCouting, setSuccessCouting] = useState(false);
  const [importCount, setImportCount] = useState(0);
  const [totalImport, setTotalImport] = useState(0);

  const addMessage = useCallback((myMsg) => {
    try {
      let message = JSON.parse(myMsg);
      if (message && message.text && message.text !== "ping") {
        setTotalImport(message.text.total_count);
        setImportCount(message.text.counting);

        if (
          message.text &&
          message.text.total_count === message.text.counting
        ) {
          setShowCounting(false);
          setSuccessCouting(true);
          setTimeout(() => {
            setSuccessCouting(false);
          }, config.timeOutValue);
        } else {
          setShowCounting(true);
          setSuccessCouting(false);
        }

        let index = listData.data.findIndex(
          (x) => x.id === message.text.data.id
        );
        if (index === -1) {
          let tmpObj = { ...message.text.data };
          tmpObj["isNew"] = true;
          setListData((listData) => ({
            ...listData,
            data: [tmpObj, ...listData.data],
          }));
        }
      }
    } catch (e) {
      console.log("error coy >>>>", e);
      return false;
    }
    return true;
    // eslint-disable-next-line
  }, []);

  // const setReceiveMessage

  useEffect(() => {
    // if (token){
    if (waitingToReconnect) {
      return;
    }

    // Only set up the websocket once
    if (!ws.current) {
      const client = new WebSocket(
        config.wsServer[process.env.NODE_ENV] + "idcard-panitia/"
      );
      ws.current = client;

      window.client = client;

      client.onerror = (e) => console.error(e);

      client.onopen = () => {
        setIsOnline(true);
        console.log("ws opened");
        client.send(JSON.stringify({ message: "ping" }));
      };

      client.onmessage = (message) => {
        addMessage(message.data);
      };

      client.onclose = () => {
        if (ws.current) {
          // Connection failed
          console.log("ws closed by server");
        } else {
          // Cleanup initiated from app side, can return here, to not attempt a reconnect
          console.log("ws closed by app component unmount");
          return;
        }

        if (waitingToReconnect) {
          return;
        }

        // Parse event code and log
        setIsOnline(false);
        console.log("ws closed");

        // Setting this will trigger a re-run of the effect,
        // cleaning up the current websocket, but not setting
        // up a new one right away
        setWaitingToReconnect(true);

        // This will trigger another re-run, and because it is false,
        // the socket will be set up again
        setTimeout(() => setWaitingToReconnect(null), 3000);
      };

      return () => {
        console.log("Cleanup");
        // Dereference, so it will set up next time
        ws.current = null;

        client.close();
      };
    }
    // }
    // eslint-disable-next-line
  }, [waitingToReconnect]);

  useEffect(() => {
    if (waitingToReconnect) {
      // Only set up the websocket once
      if (!ws.current) {
        const client = new WebSocket(
          config.wsServer[process.env.NODE_ENV] + "idcard-panitia/"
        );
        ws.current = client;

        window.client = client;

        client.onerror = (e) => console.error(e);

        client.onmessage = (message) => {
          addMessage(message.data);
        };

        return () => {
          console.log("Cleanup");
          // Dereference, so it will set up next time
          ws.current = null;

          client.close();
        };
      }
    }
    // eslint-disable-next-line
  }, [waitingToReconnect, addMessage, listData]);

  const createIdCard = async (is_force = false) => {
    setIsLoading(true);
    try {
      let tmp = {
        isForce: is_force,
      };
      await api
        .get(`${config.endPoint.createIdCardPanitia}`, { params: tmp })
        .then((res) => res.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      catchFetching(error, "admin");
      setIsLoading(false);
      const { response } = error;
      let msg = "Unable to save data";

      if (response && response.data) {
        msg = response.data?.message;
      }

      setAlertValue((alertValue) => ({
        ...alertValue,
        show: true,
        text: "Error",
        subText: msg,
        color: "danger",
      }));

      setTimeout(() => {
        setAlertValue((alertValue) => ({ ...alertValue, show: false }));
      }, config.timeOutValue);
    }
  };

  return {
    isLoading,
    setIsLoading,
    alertValue,
    setAlertValue,
    handleCloseAlert,
    modalValue,
    setModalValue,
    handleCloseModal,
    view,
    setView,
    listData,
    setListData,
    pageValue,
    setPageValue,
    closeLoading,
    showLoading,
    handleChangeSearch,
    handlePaginate,
    handleSearch,
    handleKeyDownSearch,
    createIdCard,
    isOnline,
    showCounting,
    successCouting,
    importCount,
    totalImport,
    setting
  };
}
