import PagePagination from "../../../../Components/Atoms/PagePagination";
import { usePesertaLogin } from "./pesertaLoginPageFunction";
import default_user from "../../../../assets/images/default_user.png";
import AlertComp from "../../../../Components/Atoms/AlertComp";

const PesertaLoginPage = ({ dataPage, myRef }) => {
  const {
    listData,
    pageValue,
    handlePaginate,
    alertValue,
    handleCloseAlert,
  } = usePesertaLogin();

  return (
    <div
      className="page absolute duration-1000 origin-left transition transform  
                w-calendar h-[calc(100%-100px)]  rounded-[30px] bg-white shadow-lg flex flex-col justify-center items-center
            "
      data-page={dataPage}
      ref={myRef}
    >
      <AlertComp
        isOpen={alertValue.show}
        onClose={handleCloseAlert}
        text={alertValue.text}
        subText={alertValue.subText}
        color={alertValue.color}
        icon={alertValue.icon}
      />

      <div className="front absolute front w-full h-full flex flex-col  text-black p-[20px] overflow-hidden">
        <div className="w-full text-center font-bold text-2xl  2xl:text-3xl mt-marginTopLogin mb-3">
          Daftar Peserta
        </div>
        <div className="mt-[40px]">
          <div className="grid grid-cols-3 gap-5">
            {listData.isLoading
              ? Array.from({ length: 6 }).map((_, index) => (
                  <div
                    key={index}
                    className="animate-pulse flex flex-row gap-5 border shadow-xl rounded-lg p-[18px]"
                  >
                    <div className="w-24 h-24 rounded-full p-1 bg-slate-200 border border-gray-200 flex justify-center items-center">
                      {/* <div className="w-24 h-24 rounded-full bg-slate-200" /> */}
                    </div>

                    <div className="w-28 font-poppins">
                      <div className="h-2.5 mt-1 bg-slate-200 rounded"></div>
                      <div className="h-2 bg-slate-200 rounded mt-2"></div>
                    </div>
                  </div>
                ))
              : listData &&
                listData.data.map((post, index) => {
                  return (
                    <div
                      className=" flex flex-row gap-5 border shadow-xl rounded-lg p-[18px]"
                      key={index}
                    >
                      <div className="w-24 aspect-square rounded-full p-1 border border-gray-200 flex justify-center items-center">
                        <img
                          className="w-full h-full rounded-full flex object-contain"
                          src={post.foto}
                          alt={post.nama}
                          onError={(e) => (e.target.src = default_user)}
                        />
                      </div>

                      <div className="w-28 font-poppins">
                        <div className="text-[10px] font-semibold">
                          {post.perusahaan_display}
                        </div>
                        <div className="text-[12px]">{post.nama}</div>
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>

        {!listData.isLoading && (
          <div className="mt-[20px] mb-[30px]">
            <PagePagination
              data={pageValue.obj}
              handlePagination={handlePaginate}
              page={pageValue.page}
              lastPage={pageValue.lastPage}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PesertaLoginPage;
