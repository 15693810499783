

const BookMarksRight = ({goToPage, page, data}) => {
    return (
        <div className="flex flex-row">
            {/* { page <= 2 && 
                <button onClick={() => goToPage(2)} className='h-[30px] px-[15px] w-fit rounded-tr-xl bg-red-500 text-white flex items-center justify-center'>Home</button>
            } */}
            <button onClick={() => goToPage(2)} className={`h-[30px] flex-1 px-[15px] w-fit rounded-tr-xl ${page <= 2 ?'bg-red-500 text-white' : 'bg-transparent text-transparent'} flex items-center justify-center`}>Home</button>
            {/* <button onClick={() => goToPage(2)} className={`h-[30px] px-[15px] w-fit rounded-tr-xl  bg-red-500 text-white flex items-center justify-center`}>Home</button> */}
            <button onClick={() => goToPage(4)} className={`h-[30px] px-[15px] w-fit rounded-t-xl ${page <= 4 ? 'bg-indigo-400 text-white' : 'bg-transparent text-transparent'} flex items-center justify-center`}>S & K</button>
            <button onClick={() => goToPage(6)} className={`h-[30px] px-[15px] w-fit rounded-t-xl ${page <= 6 ? 'bg-amber-400 text-white' : 'bg-transparent text-transparent'} flex items-center justify-center`}>Download Area</button>
            <button onClick={() => goToPage(8)} className={`h-[30px] px-[15px] w-fit rounded-t-xl ${page <= 8 ? 'bg-blue-500 text-white' : 'bg-transparent text-transparent'} flex items-center justify-center`}>Agenda</button>
            <button onClick={() => goToPage(10)} className={`h-[30px] px-[15px] w-fit rounded-t-xl ${page <= 10 ? 'bg-green-500 text-white' : 'bg-transparent text-transparent'} flex items-center justify-center`}>Peserta</button>
            <button onClick={() => goToPage(12)} className={`h-[30px] px-[15px] rounded-t-xl ${page <= 12 ? 'bg-indigo-600 text-white' : 'bg-transparent text-transparent'} flex items-center justify-center`}>Sponsor</button>
            <button onClick={() => goToPage(14)} className={`h-[30px] px-[15px] flex-1 rounded-tl-xl ${page <= 14 ? 'bg-cyan-500 text-white' : 'bg-transparent text-transparent'} flex items-center justify-center`}>Lokasi</button>
        </div>
    )
}

export default BookMarksRight