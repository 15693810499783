import { ThemeContext } from "../../context/ThemeContext";
import { Fragment, useCallback, useContext, useEffect, useState } from "react";
// import { HiOutlineMenu } from "react-icons/hi";
// import {
// 	Avatar,
// 	Badge,
// 	Button,
// 	// ButtonDarkMode,
// 	// ButtonRipple,
// 	Popover,
// 	// Tooltip,
// } from "..";
// import { TbBell } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import Popover from "../Atoms/Popover";
import Badge from "../Atoms/Badge";
import Avatar from "../Atoms/Avatar";
import Button from "../Atoms/Button";
import Cookies from "universal-cookie";
import config from "../../config";
import { Icon } from "@iconify/react/dist/iconify.js";
import api from "../../api";

const HeaderAdmin = ({ setSideOpen }) => {
  const {
    themeSkin,
    navbarType,
    title,
    setListUncompleteSettings,
  } = useContext(ThemeContext);
  const myState = useContext(UserContext);
  const [showHeader] = myState.showHeader;
  const [countNotif, setCountNotif] = useState(0);

  const navigate = useNavigate();

  const cookies = new Cookies();
  const myCookies = cookies.get(config.cookiesName);

  const handleLogOut = () => {
    cookies.remove(config.cookiesName, { path: "/" });
    navigate("/admin/login");
  };

  const checkSettings = useCallback(async () => {
    try {
      const res = await api
        .get(`${config.endPoint.checkCompleteSettings}`)
        .then((res) => res.data);
      if (res.results) {
        setCountNotif(res.results.length);
        setListUncompleteSettings(res.results);
      }
    } catch (error) {}
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    checkSettings();
  }, [checkSettings]);

  const handleToNotifSetting = () => {
    navigate("/admin/uncomplete-settings");
  };

  // console.log('cookies >>>', cookies.get(config.cookiesName))

  return (
    <Fragment>
      {showHeader && (
        <header
          className={`bg-base-50/30 dark:bg-neutral-900/10 backdrop-blur-sm h-20 px-6 pt-4 pb-0 top-0 w-full z-30   ${navbarType} h-0 md:h-20 flex flex-col`}
        >
          <div
            className={`w-full h-full flex justify-between items-center px-6 bg-white/80 dark:bg-base-600/80 backdrop-blur-sm rounded-md ${
              themeSkin === "default" ? "shadow-lg" : themeSkin
            }`}
          >
            <div className="hidden md:block font-semibold">{title}</div>
            <div className="lg:hidden flex items-start  w-full">
              <div
                onClick={() => setSideOpen(true)}
                className="cursor-pointer block"
              >
                {/* <HiOutlineMenu className="text-2xl" /> */}
                <Icon icon="mingcute:menu-fill" />
              </div>
            </div>

            <div className="flex items-center gap-4">
              <div className="flex items-center">
                {/* Dark Mode */}
                {/* <Tooltip tooltip="Dark Mode" delay={500}>
									<ButtonDarkMode />
								</Tooltip> */}

                {/* Notification */}
                <Popover
                  placement="bottom-end"
                  spacing={20}
                  button={
                    <div className="cursor-pointer">
                      <Badge
                        size="lg"
                        placement="right-end"
                        color="danger"
                        hidden={countNotif === 0}
                        value={countNotif}
                      >
                        <Icon icon="ph:bell-bold" fontSize={28} />
                      </Badge>
                    </div>
                  }
                >
                  {countNotif > 0 && (
                    <div className="text-sm min-w-[200px] rounded-lg p-2">
                      <Button
                        onClick={handleToNotifSetting}
                        variant="text"
                        block
                        color="danger"
                      >
                        Lengkapi pengaturan untuk bisa menggunakan aplikasi
                      </Button>
                    </div>
                  )}
                </Popover>
              </div>

              {/* Profile */}
              <Popover
                placement="bottom-end"
                spacing={20}
                button={
                  <div className="cursor-pointer">
                    <Badge size="sm" placement="right-end" color="success">
                      <Avatar color="primary">AD</Avatar>
                    </Badge>
                  </div>
                }
              >
                <div className="text-sm min-w-[200px] text-center p-2 py-4 border-b border-b-gray-200">
                  {myCookies.user_info}
                </div>
                <div className="text-sm min-w-[200px] rounded-lg p-2">
                  <Button
                    onClick={handleLogOut}
                    variant="text"
                    block
                    color="danger"
                  >
                    Logout
                  </Button>
                </div>
              </Popover>
            </div>
          </div>
        </header>
      )}
    </Fragment>
  );
};

export default HeaderAdmin;
