import AlertComp from "../../../Components/Atoms/AlertComp";
import FormTextSingleComp from "../../../Components/forms/FormTextSingleComp";
import { useChangePassword } from "./changePasswordFunction";


const ChangePasswordPage = () => {
    const { alertValue, handleCloseAlert, formData, setFormData, handleSubmit} = useChangePassword();
    return(
        <div className='flex flex-col'>
            <AlertComp
                isOpen={alertValue.show}
                onClose={handleCloseAlert}
                text={alertValue.text}
                subText={alertValue.subText}
                color={alertValue.color}
            />
            <FormTextSingleComp
                {...formData.old_password}
                formData={formData}
                setFormData={setFormData}
            />
            <FormTextSingleComp
                {...formData.new_password}
                formData={formData}
                setFormData={setFormData}
            />
            <FormTextSingleComp
                {...formData.confirm_password}
                formData={formData}
                setFormData={setFormData}
            />
            <div className='py-[10px] border-t border-t-gray-300 flex flex-row justify-end'>
                <button className='bg-blue-500 text-white py-[8px] text-sm px-[12px]  rounded' onClick={handleSubmit}>Submit</button>
            </div>
        </div>
    )
}
export default ChangePasswordPage;