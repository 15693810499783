import { Icon } from '@iconify/react/dist/iconify.js';
import BookMarksRight from '../Bookmarks/BookmarksRight';




const HomeComponent = ({goToPage, page, data}) => {
    return(        
        <div
            className="page demoPage h-full overflow-y-hidden font-poppins
                w-full
            "
        >
            <div className="w-full h-full">
                <BookMarksRight goToPage={goToPage} page={page} data={data}/>
                <div className="relative h-full w-full bg-[url('./assets/images/homepage/bghome.png')] bg-cover bg-center bg-no-repeat">
                    <div className="flex flex-col items-center w-full h-full">
                        <div className='text-[50px] font-bold text-white relative mt-[20px]'>Musyawarah Nasional</div>
                        <div className='text-[50px] font-bold text-white relative leading-3'>APJII 2024</div>

                        <div className='mt-[45px] z-50 relative flex flex-row justify-center'>
                            <div className='absolute w-[400px] h-[450px] rounded-[10px] bg-[#fff] shadow-md z-10'>
                                <div className="flex flex-col items-center w-full h-full py-[14px] px-[48px]">
                                    <div className='flex flex-col items-center pb-[14px] border-b border-b-[#dddddd] w-full'>
                                        <div className='text-[28px] text-[#151515] font-thin'>SEP</div>
                                        <div className='text-[36px] font-normal leading-7'>23</div>
                                        <div className='text-[14px] text-[#151515] font-thin'>Monday</div>
                                    </div>
                                    <div className='mt-[32px] flex flex-row justify-start'>
                                        <div className='flex flex-col items-center gap-[10px]'>
                                            <div className="flex flex-row">
                                                <div className='w-[40px] flex justify-center py-[12px] text-[#383838] text-[24px] bg-[#F0F0F0]'>3</div>
                                                <div className='w-[40px] flex justify-center py-[12px] text-[#383838] text-[24px] bg-[#F0F0F0] ml-[8px]'>2</div>
                                            </div>
                                            <div className='text-[14px] text-[#707070]'>Days</div>
                                        </div>
                                        <div className='text-[#707070] text-[24px] mx-[12px]'>:</div>
                                        <div className='flex flex-col items-center gap-[10px]'>
                                            <div className="flex flex-row">
                                                <div className='w-[40px] flex justify-center py-[12px] text-[#383838] text-[24px] bg-[#F0F0F0]'>1</div>
                                                <div className='w-[40px] flex justify-center py-[12px] text-[#383838] text-[24px] bg-[#F0F0F0] ml-[8px]'>8</div>
                                            </div>
                                            <div className='text-[14px] text-[#707070]'>Hours</div>
                                        </div>
                                        <div className='text-[#707070] text-[24px] mx-[12px]'>:</div>
                                        <div className='flex flex-col items-center gap-[10px]'>
                                            <div className="flex flex-row">
                                                <div className='w-[40px] flex justify-center py-[12px] text-[#383838] text-[24px] bg-[#F0F0F0]'>3</div>
                                                <div className='w-[40px] flex justify-center py-[12px] text-[#383838] text-[24px] bg-[#F0F0F0] ml-[8px]'>2</div>
                                            </div>
                                            <div className='text-[14px] text-[#707070]'>Minutes</div>
                                        </div>
                                    </div>
                                    <div className="absolute bottom-0 left-0 bg-[#F0F0F0] w-full h-[150px] rounded-b-[10px]">
                                        <div className="flex flex-col h-full w-full py-[12px] px-[46px]">
                                            <div className="w-full flex flex-row  gap-[18px] items-center">
                                                <div className='text-[#B2ACAC] text-[28px]'><Icon icon="subway:location-3" /></div>
                                                <div className='text-[#767575]'>
                                                    <div className='text-[18px]'>Hotel Lorem Ipsum</div>
                                                    <div className='text-[14px]'>Surabaya</div>
                                                    <div className='text-[14px] text-[#006CD0]'>View Map</div>
                                                </div>
                                            </div>
                                            <div className='w-full mt-[10px]'>
                                                <button className='bg-[#0075DD] text-white w-full py-[10px] rounded'>REGISTRASI</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='absolute w-[380px] h-[420px] rounded-[10px] bg-[#fff] shadow-md mt-[35px]'></div>
                            <div className='w-[360px] h-[400px] rounded-[10px] bg-[#fff] shadow-md mt-[60px]'></div>
                        </div>
                        <div className='w-full  flex flex-col h-full justify-end px-[30px] text-white pb-[38px] font-light'>
                            <div>19 Agustus 2024</div>
                            <div className='flex flex-row items-center gap-[10px]'><Icon icon="ic:round-circle" className='text-green-500'/>Pendaftaran sedang Berlangsung</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default HomeComponent;