import { Outlet, Navigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import config from "../../config";



const PrivateRoute = () => {
  const [cookies] = useCookies([config.cookiesName]);
  const isAuthenticated = () => {
    return !!cookies[config.cookiesName]; 
  };

  if (isAuthenticated()) {
    return <Outlet />;
  } else {
    return <Navigate to="/admin/login" replace />;
  }
};

export default PrivateRoute;
