import PagePagination from "../../../Components/Atoms/PagePagination";
import { usePeserta } from "./pesertaFunctioni";
import { capitalAllLetters } from "../../../utils/capitalAllLetters";
import Search from "../../../Components/Atoms/Search";
const PesertaPage = () => {
  const { listData, pageValue, handlePaginate, handleSearch, handleChangeSearch, handleChangeLimit } = usePeserta();
  return (
    <div className="w-full min-h-screen h-fit  text-black relative flex flex-col items-center mt-[80px] md:mt-[100px]">
      <div className="w-full text-center max-w-homeWidth px-[10px] md:px-[100px]">
        <div className="font-bold title md:text-[24px] text-[14px]">Daftar Peserta Musyawarah Nasional XII APJII Tahun 2024</div>

        <div className='flex justify-between mt-[30px] mb-5 w-full'>
          <div className="flex items-center gap-2">
            <label htmlFor="limit" className="hidden md:block">Tampilkan</label>
            {console.log(pageValue?.limit)}
            <select
              id="limit"
              className="p-1.5 border border-gray-300 rounded"
              value={pageValue.limit}
              onChange={handleChangeLimit}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="200">200</option>
              <option value="500">500</option>
            </select>
          </div>
          <div className=''>
            <Search
              placeholder={"Cari"}
              value={pageValue.search}
              onChange={handleChangeSearch}
              handleSearch={handleSearch}
            />
          </div>
        </div>
        <div className="text-justify  w-full text-xs md:text-base">
          <table className="table table-peserta table-fixed w-full">
            <thead>
              <tr>
                <th className="bg-[#2194FA] w-[80px] text-right">#</th>
                <th className="bg-[#0A4A9C]">Perusahaan</th>
                <th className="bg-[#2194FA] ">Nama</th>
              </tr>
            </thead>
            <tbody>
              {listData?.data?.map((post, index) => {
                return (
                  <tr key={index}>
                    <td className="text-right">
                      {index + 1 + pageValue.offset}
                    </td>
                    <td className="bg-[#FBFBFB]">{post.perusahaan_display}</td>
                    <td className="bg-[#F3F3F3]">{capitalAllLetters(post.nama)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="mt-[20px] mb-[30px]">
          <PagePagination
            data={pageValue.obj}
            handlePagination={handlePaginate}
            page={pageValue.page}
            lastPage={pageValue.lastPage}
          />
        </div>
      </div>
    </div>
  );
};
export default PesertaPage;
