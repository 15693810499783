import "../../../../assets/styles/memberLogin.css";
import { LineWave } from "react-loader-spinner";
import AlertComp from "../../../../Components/Atoms/AlertComp";
import { useAgenda } from "./agendaFunction";
import JadwalHtml from "../../../../Components/Atoms/JadwalHtml";

const AgendaLoginPage = ({ myRef, dataPage }) => {
  const { isLoading, alertValue, handleCloseAlert } = useAgenda();
  return (
    <div
      className="page absolute duration-1000 origin-left transition transform  
                w-calendar h-[calc(100%-100px)]  rounded-[30px] bg-white shadow-lg flex flex-col justify-center items-center
            "
      data-page={dataPage}
      ref={myRef}
    >
      {isLoading && (
        <div className=" pt-[9rem] fixed inset-0 z-[40] flex justify-center items-center ">
          <LineWave
            visible={true}
            height="200"
            width="200"
            color="#5B99C2"
            ariaLabel="line-wave-loading"
            wrapperStyle={{}}
            wrapperClass=""
            firstLineColor=""
            middleLineColor=""
            lastLineColor=""
          />
        </div>
      )}

      <AlertComp
        isOpen={alertValue.show}
        onClose={handleCloseAlert}
        text={alertValue.text}
        subText={alertValue.subText}
        color={alertValue.color}
        icon={alertValue.icon}
      />

      <div className="front absolute front w-full h-full flex flex-col  text-black p-[15px] overflow-hidden">
        <div className="w-full text-center font-bold text-2xl  2xl:text-3xl mt-marginTopLogin mb-5 ">
          Agenda Acara
        </div>
        <div className="bg-white rounded-[10px] py-10  w-full font-light overflow-hidden overflow-y-auto">
          <div className="w-full h-[55vh] flex justify-center overflow-auto p-5">
            <JadwalHtml />
          </div>
        </div>
      </div>
    </div>
  );
};
export default AgendaLoginPage;
