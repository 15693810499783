import { LineWave } from "react-loader-spinner";
import { useSponsorLoginPage } from "./sponsorLoginFunction";
import AlertComp from "../../../../Components/Atoms/AlertComp";
import logo_default from "../../../../assets/images/logo_default.png";
import coming_sun from "../../../../assets/images/coming_sun.png";

const SponsorLoginPage = ({ myRef, dataPage }) => {
  const {
    alertValue,
    dataKategori,
    dataSponsor,
    isLoading,
    setPilihKategori,
    pilihKategori,
    handleCloseAlert,
  } = useSponsorLoginPage();
  return (
    <div
      className="page absolute duration-1000 origin-left transition transform  
                w-calendar h-[calc(100%-100px)]  rounded-[30px] bg-white shadow-lg flex flex-col justify-center items-center cursor-default
            "
      data-page={dataPage}
      ref={myRef}
    >
      {isLoading && (
        <div className=" pt-[9rem] fixed inset-0 z-[40] flex justify-center items-center ">
          <LineWave
            visible={true}
            height="200"
            width="200"
            color="#5B99C2"
            ariaLabel="line-wave-loading"
            wrapperStyle={{}}
            wrapperClass=""
            firstLineColor=""
            middleLineColor=""
            lastLineColor=""
          />
        </div>
      )}

      <AlertComp
        isOpen={alertValue.show}
        onClose={handleCloseAlert}
        text={alertValue.text}
        subText={alertValue.subText}
        color={alertValue.color}
        icon={alertValue.icon}
      />

      <div className="absolute w-full h-full flex flex-col text-black p-5 bg-white overflow-hidden">
        <div className="w-full text-center font-bold text-2xl 2xl:text-3xl mt-5 mb-5">
          Sponsor
        </div>

        {/* Kategori List */}
        <div className="flex flex-row border-b border-b-[#dddddd] gap-5 justify-center mb-5">
          {dataKategori.map((item, index) => (
            <div
              key={index}
              onClick={() => {
                setPilihKategori(item.id);
              }}
              className={`pb-2 cursor-pointer transition-colors duration-300 ${pilihKategori === item.id
                ? "border-b-4 border-blue-500 text-blue-600"
                : "hover:text-blue-500"
                }`}
            >
              {item.nama}
            </div>
          ))}
        </div>

        {/* Sponsor Grid */}
        <div className="flex-grow mt-5 w-full">
          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-8 justify-items-center">
            {dataSponsor.length > 0 ? (
              dataSponsor.map((item, index) => (
                <div key={index} className="flex flex-col items-center">
                  <div className="w-[150px] h-[100px] p-4 bg-white border border-gray-200 rounded-md shadow-md flex items-center justify-center">
                    <img
                      className="w-full h-full object-contain"
                      src={item.logo}
                      alt={item.nama}
                      onError={(e) => (e.target.src = logo_default)}
                    />
                  </div>
                </div>
              ))
            ) : (
              // Jika tidak ada sponsor, tampilkan logo "Coming Soon"
              <div className="flex flex-col items-center">
                <div className="w-[150px] h-[100px] p-4 bg-white border border-gray-200 rounded-md shadow-md flex items-center justify-center">
                  <img
                    className="w-full h-full object-contain"
                    src={coming_sun}
                    alt="Coming Soon"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>


    </div>
  );
};
export default SponsorLoginPage;
