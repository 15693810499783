import { Fragment } from "react"
import { usePanitia } from "./panitiaFunction";
import Card from "../../../../Components/Layout/Card";
import Tables from "../../../../Components/molecules/Tables";
import PagePagination from "../../../../Components/Atoms/PagePagination";
import FormTextSingleComp from "../../../../Components/forms/FormTextSingleComp";
import Modal from "../../../../Components/Atoms/Modal";
import Tooltip from "../../../../Components/Atoms/Tooltip";
import { Icon } from "@iconify/react/dist/iconify.js";
import Button from "../../../../Components/Atoms/Button";

const PanitiaPage = () => {
    const { view, pageValue, handleCreate, handleChangeSearch, handleSearch,
        handleCancel, handleSubmit, listData, handlePaginate,
        formData, setFormData, modalState, modalDispatch, 
        showPanitia, handleEdit, handleDelete, handleSubmitDelete,
        modalValue, handleCloseModal, setModalValue
    } = usePanitia();
    return(
        <Fragment>
            <Modal
                show={modalValue.show}
                setShow={() => {
                    setModalValue(modalValue => ({...modalValue, show: false}))
                }}
                width='xl'
                persistent={false}
                btnClose={false}
            >
                <div className='py-[20px] px-[30px] border-b border-b-gray-200 font-bold'>
                    {modalValue.title}
                </div>
                <div className='py-[50px] px-[30px]'>
                    { modalValue.text }
                </div>
                <div className='py-[20px] px-[30px] border-t border-t-gray-200 flex flex-row justify-end gap-2'>
                    <Button color='danger' size='md' onClick={handleSubmitDelete}>Hapus</Button>
                    <Button color='base' size='md' onClick={handleCloseModal}>Cancel</Button>
                </div>
            </Modal>
            <Modal
                show={modalState.isOpen}
                setShow={() => {
                    modalDispatch({type: 'CLOSE'})
                }}
                width="xl"
                persistent={false}
                btnClose={true}                                
                title={modalState.title}
                withHeader={true}
            >
                {modalState.content}
            </Modal>
            <Card
                title='Master Kandidat Ketua Umum'
                isCreate={view === 'create'}
                isSearch={view === 'list'}
                searchValue={pageValue.search}
                handleCreate={handleCreate}
                handleSubmit={handleSubmit}
                handleCancel={handleCancel}
                onChangeSearch={handleChangeSearch}
                handleSearch={handleSearch}
            >
                { view === 'create' &&
                    <Fragment>
                        <FormTextSingleComp
                            {...formData.nama}
                            formData={formData}
                            setFormData={setFormData}
                        />
                        <div className="mt-5">
                            <FormTextSingleComp
                                {...formData.kategori_panitia_display}
                                formData={formData}
                                setFormData={setFormData}
                                onClick={showPanitia}
                            />
                        </div>
                        <div className="mt-5">
                            <FormTextSingleComp
                                {...formData.foto_url}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </div>
                    </Fragment>
                }
                { view === 'list' &&
                    <Fragment>
                        <Tables className='border-separate rounded-sm'>
                            <Tables.Head className='bg-[#efeeee]'>
                                <tr>
                                <Tables.Header className='font-bold text-[13px] py-[10px] text-right'>#</Tables.Header>
                                <Tables.Header className='font-bold text-[13px] py-[10px]'>Foto</Tables.Header>
                                <Tables.Header className='font-bold text-[13px] py-[10px]'>Kategori</Tables.Header>
                                <Tables.Header className='font-bold text-[13px] py-[10px]'>Nama</Tables.Header>
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>Action</Tables.Header>
                                </tr>
                            </Tables.Head>
                            <Tables.Body>
                                { listData?.data?.length === 0 ?
                                    <Tables.Row>
                                        <Tables.Data colspan={5} className='text-center'>Belum ada data</Tables.Data>
                                    </Tables.Row>
                                    :
                                    <Fragment>
                                        {listData?.data?.map((post, index) => {
                                            return (
                                                <Tables.Row key={index}>
                                                    <Tables.Data className='text-right align-top'>{pageValue.offset + index + 1}</Tables.Data>
                                                    <Tables.Data className='align-top'>
                                                        <div className='w-[100px] rounded p-2 bg-gray-100'>
                                                            <img src={post.foto_url ? post.foto_url : require('../../../../assets/images/default_user.png')} alt="foto"/>
                                                        </div>
                                                    </Tables.Data>
                                                    <Tables.Data className='align-top'>{post.kategori_display}</Tables.Data>
                                                    <Tables.Data className='align-top'>{post.nama}</Tables.Data>
                                                    <Tables.Data className='align-top'>
                                                        <div className='flex flex-row gap-1'>
                                                            <Tooltip
                                                                tooltip={'Edit'}
                                                            >
                                                                <button onClick={() => handleEdit(post)} className='p-1 bg-orange-500 text-white rounded'><Icon icon='line-md:edit' /></button>
                                                            </Tooltip>
                                                            <Tooltip
                                                                tooltip={'Hapus'}
                                                            >
                                                                <button onClick={() => handleDelete(post)} className='p-1 bg-red-500 text-white rounded'><Icon icon='fluent-mdl2:delete'  /></button>
                                                            </Tooltip>
                                                        </div>
                                                    </Tables.Data>
                                                </Tables.Row>
                                            )
                                        })}
                                    </Fragment>
                                }
                            </Tables.Body>
                        </Tables>
                        <div className='mt-5'>
                            <PagePagination
                                data={pageValue.obj}
                                handlePagination={handlePaginate}
                                page={pageValue.page}
                                lastPage={pageValue.lastPage}
                            />
                        </div>
                    </Fragment>
                }
            </Card>
        </Fragment>
    )
}
export default PanitiaPage;