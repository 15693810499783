// eslint-disable-next-line
export default {
  fetchStatus: {
    READY: "READY",
    FETCHING: "FETCHING",
  },
  apiBaseUrl: {
    development: "http://127.0.0.1:8000",
    // development: "https://belakangregistrasi.apjii.or.id",    
    production: "https://belakangregistrasi.apjii.or.id",
  },
  webPage: {
    development: "http://localhost:3000",
    // development : "https://beregistrasi2024.myappscenter.com",
    production: "https://belakangregistrasi.apjii.or.id",
    // production : "https://beregistrasi.identifikasi.com"
  },
  tinyApiKey: "t8gfjoqtatjczsydjihqzwjh8o3g4sgi2khc4ij6gtpsnqab",
  wsServer: {
    development: "ws://127.0.0.1:8000/ws/",
    // development : 'wss://beregistrasi2024.myappscenter.com/ws/',
    production: "wss://belakangregistrasi.apjii.or.id/ws/",
    // production : 'wss://beregistrasi.identifikasi.com/ws/',
  },
  localStorageKey: {
    USER: "__apjiiClientUser",
  },
  cookiesNamePassword: "_passwordDownloadMunas",
  ENCODE_KEY: "siapadirinya_dia",
  ENCODE_SERIAL: "pray_bofore_work",
  cookiesName: "fww_media_registrasi",
  cookiesNameToken: "fwwmedia_token",
  googleMapKey: "AIzaSyDm751ucwcFMFw6dztSqEfgCKeYoz2hTYc",
  // expiresSession : 86400,
  expiresSession: 186400,
  itemPerPage: 10,
  timeOutValue: 10000,
  initLocation: {
    lat: -7.257307420487421,
    lng: 112.73453117589429,
  },
  isProduction: {
    // development : true,
    development: false,
    production: true,
  },
  maxDewas: 3,
  endPoint: {
    // auth
    permissionGroup: "/auth/api/v1/group-permissions/",
    permissions: "/auth/api/v1/permissions/",
    login: `/auth/api/login`,
    loginMember: `/auth/api/login-member/`,
    addPesertaByMember: `/munas/api/v1/add-peserta-bymember/`,
    hotelPeserta: `/kontak/api/v1/hotel-peserta/`,
    hotelLog: `/master/api/v1/hotel-log/`,
    forgotPassword: `/auth/api/forgot-password/`,
    kontakUnlock: "/kontak/api/v1/kontak-unlock/",
    kontakGrouop: "/kontak/api/v1/kontak-group/",
    kontakGroupDeleteKontak: "/kontak/api/v1/kontak-group/delete-kontak/",
    signUp: `/event/api/v1/signup-registrasi/`,
    verificationEmail: `/auth/api/verification-email/`,
    settingDisable: `/master/api/v1/setting-disable/`,
    settingDisableGuest: `/master/api/v1/setting-disable-guest/`,
    getExpiredVerification: `/auth/api/expired-verification-email/`,
    resetPassword: `/auth/api/reset-password/`,
    changePassword: `/auth/api/change-password/`,
    getMemberInfo: `/auth/api/get-member-info/`,
    showPeserta: `/kontak/api/v1/show-peserta/`,
    user: `/auth/api/v1/user/`,
    userRole: "/auth/api/get-all-role/",

    // registrasi ulang dan goodiebag
    registrasiUlang: `/absen/api/v1/registrasi-ulang/`,
    getKontakByQr: `/absen/api/v1/get-kontak-byqr/`,
    postGoodieBag: `/absen/api/v1/post-goodie-bag/`,


    pesertaWeb: `/kontak/api/v1/peserta-web/`,

    actionApproveDoc: `/kontak/api/v1/action-approve-doc/`,
    actionRejectDoc: `/kontak/api/v1/action-reject-doc/`,
    actionComplete: `/kontak/api/v1/action-complete/`,
    actionCropFoto: `/kontak/api/v1/action-crop-foto/`,
    getCropFoto: `/kontak/api/v1/get-crop-foto/`,
    masterEmailTemplate: `/master/api/v1/email-template/`,
    emailLog: `/kontak/api/v1/kontak-email-log/`,
    approveLog: `/kontak/api/v1/kontak-approve-log/`,
    replaceString: `/event/api/v1/replace-string/`,
    replaceStringPerusahaan: `/event/api/v1/replace-string-perusahaan/`,
    perusahaanGroupByProvinsi: `/master/api/v1/perusahaan-group-provinsi/`,
    perusahaanWilayah: `/master/api/v1/perusahaan-wilayah/`,
    perusahaanWilayahDownload: `/master/api/v1/perusahaan-wilayah-download/`,
    backupDb: `/master/api/v1/backup-db/`,
    daftarRevokeHotel: `/master/api/v1/revoke-peserta-hotel/`,
    passwordDownload: `/master/api/v1/password-download/`,
    loginDownload: `/master/api/v1/login-download/`,

    // registrasi
    dataRegistrasi: `/kontak/api/v1/kontak`,
    kontak: `/kontak/api/v1/kontak/`,
    panitia: `/kontak/api/v1/panitia/`,
    kategoriPanitia: `/kontak/api/v1/kategori-panitia/`,
    dataRegistrasiExport: `/kontak/api/v1/kontak-export-registrasi/`,
    kontakDuplicate: `/kontak/api/v1/kontak-duplicate/`,
    kontakDuplicateGuest: `/kontak/api/v1/ngopimase/`,
    createIdCard: `/kontak/api/v1/create-idcard/`,
    createIdCardPanitia: `/kontak/api/v1/create-idcard-panitia/`,
    perusahaanMendaftar: `/kontak/api/v1/perusahaan-mendaftar/`,
    //sync my apjii
    syncMyapjii: `/kontak/api/v1/sync-myapjii/`,

    // dashboard
    resumePeserta: `/event/api/v1/resume-peserta/`,
    resumePesertaCampurSari: `/event/api/v1/resume-peserta-campursari/`,

    // absen
    masterSesiAbsen: "/absen/api/v1/master-sesi/",
    inputAbsen: "/absen/api/v1/absen/",
    availableSesi: "/absen/api/v1/get-available-sesi/",

    // master
    perusahaan: `/master/api/v1/perusahaan/`,
    importPerusahaanNew: `/master/api/v1/import-perusahaan/`,
    sponsorShip: `/master/api/v1/sponsorship/`,
    kategoriSponsor: `/master/api/v1/kategori-sponsor/`,
    agendaAcara: `/master/api/v1/agenda-acara/`,
    agendaAcaraWeb: `/master/api/v1/agenda-acara-web/`,
    agendaAcaraGroup: `/master/api/v1/agenda-acara-group/`,
    hotel: `/master/api/v1/hotel/`,
    perusahaanGuest: `/master/api/v1/perusahaan-guest/`,
    uploadAktaPerusahaan: `/master/api/v1/upload-akta-perusahaan/`,
    importPerusahaan: `/kontak/api/v1/import-perusahaan/`,
    saveImportPerusahaan: `/kontak/api/v1/save-import-perusahaan/`,
    lockKontak: `/kontak/api/v1/lock-kontak/`,
    importKontak: `/kontak/api/v1/import-kontak/`,
    emailSender: `/master/api/v1/mail-server/`,
    siteSetting: `/master/api/v1/site-setting/`,
    siteSettingGuest: `/master/api/v1/site-setting-guest/`,
    kandidatKetum: "/master/api/v1/kandidat-ketum/",
    kandidatDewas: "/master/api/v1/kandidat-dewas/",
    checkCompleteSettings: "/master/api/v1/check-complete-settings/",
    resetPerusahaan: "/master/api/v1/reset-perusahaan/",
    masterTps: `/master/api/v1/tps/`,
    sesiPemilihan: `/master/api/v1/sesi-pemilihan/`,

    // preview
    pesertaCompleteWithHotel: "/kontak/api/v1/perserta-complete-withhotel/",
    livePreview: "/kontak/api/v1/live-preview/",

    eventResume: `/master/api/v1/event-resume/`,

    fileUpload: `/master/api/v1/file-upload/`,
    fileUploadMember: `/master/api/v1/file-upload-member/`,
    provinsi: `/master/api/v1/provinsi/`,
    kabupaten: `/master/api/v1/kabupaten/`,
    dynamicPage: `/event/api/v1/registrasi-page/`,
    registrasiMenu: "/event/api/v1/registrasi-menu/",
    copyRegistrasiPage: "/event/api/v1/copy-registrasi-page/",
    webRegistrasi: "/event/api/v1/web-registrasi/",
    resendEmail: `/event/api/v1/resend-email-registrasi/`,

    fileUploadWeb: `/master/api/v1/file-upload-public/`,

    //Halaman Login Member
    kategoriSponsorLogin: `/master/api/v1/kategori-sponsor-login/`,
    sponsorShipLogin: `/master/api/v1/sponsorship-login/`,
    agendaAcaraLogin: `/master/api/v1/agenda-acara-login/`,

    // server calon
    serverCalon: `/master/api/v1/server-calon/`,
    fetchingCalon: `/master/api/v1/fetching-calon/`,

    // download export data perusahaan
    exportData: '/master/api/v1/perusahaan/download/',

    clusterTps: '/kontak/api/v1/cluster-tps/',
    generateVoucerBulky: '/kontak/api/v1/generate-voucer-bulky/',
    downloadTps: '/kontak/api/v1/download-cluster-tps/',
  },
};
