
import { useContext } from "react";
import { TbX } from "react-icons/tb";
import { ThemeContext } from "../../context/ThemeContext";

/**
 *
 * @param {{
 * color: "primary" | "base" | "success" | "warning" | "danger" | "info";
 * rounded: "none" | "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "full";
 * icon: React.ReactNode;
 * variant: "solid" | "outline" | "tonal";
 * density: "tight" | "normal" | "loose";
 * closable: boolean;
 * show: boolean;
 * setShow: React.Dispatch<React.SetStateAction<boolean>>;
 * className: string;
 * }}
 *
 *
 */

const Alert = ({
	children,
	color,
	rounded,
	icon,
	variant,
	density,
	closable,
	show = true,
	setShow,
}) => {
	const { themeColor } = useContext(ThemeContext);

	// Color
	const alertColor =
		{
			primary: themeColor,
			base: "#BABCBD",
			success: "#4ED17E",
			warning: "#EEC239",
			danger: "#F26969",
			info: "#629BF8",
		}[color] || color;

	// Rounded
	const alertRounded =
		{
			none: "rounded-none",
			sm: "rounded-sm",
			md: "rounded-md",
			lg: "rounded-lg",
			xl: "rounded-xl",
			"2xl": "rounded-2xl",
			"3xl": "rounded-3xl",
			full: "rounded-full",
		}[rounded] || "rounded-md";

	const alertDensity =
		{
			tight: "py-2",
			normal: "py-3",
			loose: "py-4",
		}[density] || "py-3";

	// Style
	let alertStyle = {};
	if (variant === "tonal") {
		alertStyle = {
			backgroundColor: `${alertColor}30`,
			color: alertColor,
		};
	} else if (variant === "outline") {
		alertStyle = {
			backgroundColor: "transparent",
			color: alertColor,
			border: `1px solid ${alertColor}`,
		};
	} else {
		alertStyle = {
			backgroundColor: alertColor,
			color: "white",
		};
	}

	if (show) {
		return (
			<div
				style={alertStyle}
				className={`px-4 w-full flex gap-3 items-center text-sm overflow-hidden ${alertDensity} ${alertRounded}`}
			>
				<div className="flex items-center gap-3">
					{icon} {children}
				</div>

				{closable && (
					<div
						onClick={() => setShow(false)}
						className="ml-auto cursor-pointer"
					>
						<TbX />
					</div>
				)}
			</div>
		);
	}

	return null;
};

export default Alert;
