import { useContext, useState } from "react";
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom";
import config from "./config";
import { UserContext } from "./context/UserContext";
import Resizer from "react-image-file-resizer";

export function useGlobalFunction() {
    const [isLoading, setIsLoading] = useState(false);

    const cookies = new Cookies();
    const navigate = useNavigate();
    const myState = useContext(UserContext);
    const [myLoading, setMyLoading] = myState.myLoading

    const [alertValue, setAlertValue] = useState({
        show: false,
        text: 'Error',
        color: 'danger',
        subText: 'Lengkapi Data',
        icon: '',
    });
    const [modalValue, setModalValue] = useState({
        show: false,
        tipe: '',
        content: 'list',
        text: '',
        title: '',
        id: 0,
        params: null,
        url: null,
        isEdit: false,
    })

    const handleCloseAlert = () => {
        setAlertValue(alertValue => ({ ...alertValue, show: false }))
    }

    const [pageValue, setPageValue] = useState({
        page: 1,
        limit: 10,
        search: '',
        offset: 0,
        obj: [],
        lastPage: 1,
        totalRecord: 1,
        state_flag: '',
        stateFlagObj: [{ value: 1, label: 'Tidak Kenal' }, { value: 2, label: 'Cukup Kenal' }, { value: 3, label: 'Sangat Kenal' }, { value: 4, label: 'Unflag' }],
        state_koalisi: '',
        stateKoalisiObj: [{ value: 1, label: 'Koalisi' }, { value: 2, label: 'Non Koalisi' }, { value: 3, label: 'Swing Voter' }],
        hotel_state: '',
        hotel_state_obj: [
            { value: '', label: '-- Tipe history -- ' },
            { value: 'assign', label: 'Assign' },
            { value: 'revoke', label: 'Revoke' },
        ],
        complete: '',
        complete_obj: [
            { value: '', label: '-- Status Complete --' },
            { value: 'waiting', label: 'Waiting' },
            { value: 'reject', label: 'Reject' },
            { value: 'complete', label: 'Complete' },
        ],
        is_lock: '',
        is_lock_obj: [
            { value: '', label: '-- Status Lock --' },
            { value: 'lock', label: 'Lock' },
            { value: 'unlock', label: 'Unlock' },
        ],
    });


    const handleCloseModal = () => {
        setModalValue({
            show: false,
            tipe: '',
            content: 'list',
            text: '',
            title: '',
        })
    }

    const catchFetching = (error, page) => {
        let status = error && error.response && error.response.status ? error.response.status : '';
        if (status === 401) {
            cookies.remove(config.cookiesName, { path: '/' });
        }
        if (status && [401, 403].includes(status)) {
            if (page === 'admin') {
                // return console.log("ga login")
                // return window.location.href = "/admin/login"
                return navigate('/admin/login')
            } else if (page === 'absen') {
                console.log('kena catch fetching')
                return navigate('/absen/login')

            } else {
                // return console.log("ga login member")
                // return window.location.href = "/member/login"
                return navigate('/member/login')
            }
        }
    }

    const closeLoading = () => {
        setMyLoading(false)
    }

    const showLoading = () => {
        setMyLoading(true)
    }

    const getColor = (params) => {
        if (params === 3) {
            return {
                backgroundColor: '#D9D9D9',
                color: '#A9A9A9'
            }
        } else if (params === 2) {
            return {
                backgroundColor: 'rgb(255, 205, 205)',
                color: 'rgb(255, 15, 15)'
            }
        } else if (params === 1) {
            return {
                backgroundColor: 'rgb(224, 248, 229)',
                color: 'rgb(1, 184, 26)'
            }
        } else {
            return {
                backgroundColor: '#5c5c5c',
                color: '#fff'
            }
        }
    }

    const toTitleCase = (str) => {
        return str.replace(
            /\w\S*/g,
            text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
        );
    }

    const resizeFile = (file, type) => new Promise(resolve => {
        Resizer.imageFileResizer(file, 800, 800, 'JPEG', 100, 0,
            uri => {
                resolve(uri);
            }, type);
    });

    return {
        alertValue, setAlertValue, handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal,
        isLoading, setIsLoading, catchFetching, closeLoading, myLoading, showLoading, getColor, toTitleCase,
        resizeFile
    }
}