import { useState } from "react";
import { useEditDataPeserta } from "../EditDataPeserta/editDataPesertaFunction";
import FormTextSingleComp from "../../../Components/forms/FormTextSingleComp";
import AlertComp from "../../../Components/Atoms/AlertComp";

const DynamicShowDocument = ({ url }) => {
  return (
    <iframe
      src={`${url}`}
      // src={`https://docs.google.com/gview?url=${info.akta_url}&embedded=true`}
      width="100%"
      height="500px"
      title="akta"
      className="mt-5"
    ></iframe>
  );
};

const DashboardMember = ({ isEdit = false }) => {
  const {
    info,
    getColor,
    formData,
    navigate,
    handleSubmit,
    setFormData,
    handleChange,
    alertValue,
    handleCloseAlert,
    perusahaanInfo,
    handleDownload,
    downloadFileUrl,
    isLoading
  } = useEditDataPeserta();
  const [showDokumen, setShowDokumen] = useState({
    akta: false,
    surat_pernyataan: false,
    surat_kuasa: false,
    ktp: false,
    ktp_peserta: false,
  });

  return (
    <div className="flex flex-col">
      <AlertComp
        isOpen={alertValue.show}
        onClose={handleCloseAlert}
        text={alertValue.text}
        subText={alertValue.subText}
        color={alertValue.color}
      />
      <div className="flex flex-row">
        <button
          className="bg-[#0A2136] py-[10px] lg:py-[20px] px-[12px] text-white rounded"
          onClick={() => downloadFileUrl.suratPernyataan ? handleDownload(downloadFileUrl.suratPernyataan) : null}
        >
          Template Surat Pernyataan
        </button>
        <button
          className="bg-[#FAB95B] ml-[20px] py-[10px] lg:py-[20px] px-[12px] text-white rounded"
          onClick={() => downloadFileUrl.suratKuasa ? handleDownload(downloadFileUrl.suratKuasa) : null}
        >
          Template Surat Kuasa
        </button>
      </div>
      <div className="mt-[30px] pb-[30px] border-b border-b-gray-200">
        <table className="table w-full">
          <tbody>
            <tr>
              <td className="w-[20%]">Nama Perusahaan</td>
              <td className="align-top">:</td>
              <td className="align-top">{perusahaanInfo?.perusahaan_name}</td>
            </tr>
            <tr>
              <td className="w-[20%]">Email Perusahaan</td>
              <td className="align-top">:</td>
              <td className="align-top">{perusahaanInfo?.email} </td>
            </tr>
            <tr>
              <td className="w-[20%]">Nomor HP</td>
              <td className="align-top">:</td>
              <td className="align-top">{perusahaanInfo?.hp} </td>
            </tr>
            <tr>
              <td className="w-[20%] align-top">Akta Perusahaan</td>
              <td className="align-top">:</td>
              <td className="align-top">
                {isEdit && (
                  <>
                    <FormTextSingleComp
                      {...formData.akta}
                      formData={formData}
                      setFormData={setFormData}
                      hideLabel={true}
                      disabled={formData.akta.is_lock}
                    />
                  </>
                )}

                {perusahaanInfo && perusahaanInfo.akta_url.includes("http") && (
                  <div>
                    <button
                      className="px-5 py-2 btn-custom bg-blue-500 text-white rounded"
                      size="sm"
                      onClick={() =>
                        setShowDokumen((showDokumen) => ({
                          ...showDokumen,
                          akta: !showDokumen.akta,
                        }))
                      }
                    >
                      Lihat Akta
                    </button>
                    {showDokumen.akta && (
                      <DynamicShowDocument url={perusahaanInfo.akta_url} />
                    )}
                  </div>
                )}
                {perusahaanInfo && perusahaanInfo.status_akta && (
                  <div
                    className="w-fit px-5 py-1 mt-2 text-[11px]"
                    style={getColor(
                      perusahaanInfo && perusahaanInfo.status_akta
                        ? perusahaanInfo.status_akta
                        : 0
                    )}
                  >
                    {perusahaanInfo.status_akta_display}
                  </div>
                )}
                {perusahaanInfo &&
                  perusahaanInfo?.list_peserta &&
                  perusahaanInfo?.status_akta && (
                    <div>
                      {perusahaanInfo?.alasan_akta &&
                        perusahaanInfo?.status_akta === 2 && (
                          <div className="w-fit py-1 text-[12px] italic text-red-400">
                            Alasan ditolak : {info?.alasan_akta}
                          </div>
                        )}
                    </div>
                  )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="mt-[30px] pb-[30px]">
        <table className="table w-full">
          <tbody>
            <tr>
              <td className="w-[20%]">Nama Peserta</td>
              <td>:</td>
              <td className="align-top">
                {isEdit ? (
                  <FormTextSingleComp
                    {...formData.nama}
                    hideLabel={true}
                    formData={formData}
                    setFormData={setFormData}
                    disabled={formData.nama.is_lock}
                  />
                ) : (
                  <>
                    {/* {info?.nama} */}
                    {formData.nama.value}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td className="w-[20%]">Foto</td>
              <td>:</td>
              <td className="align-top">
                {isEdit ? (
                  <>
                    {formData.foto.is_lock && info.foto && (
                      <img src={info?.foto} alt="foto" className="w-[150px]" />
                    )}
                    <FormTextSingleComp
                      {...formData.foto}
                      hideLabel={true}
                      formData={formData}
                      setFormData={setFormData}
                      disabled={formData.foto.is_lock}
                    />
                    <div>
                      <div
                        className="w-fit px-5 py-1 mt-2 text-[11px]"
                        style={getColor(info?.status_foto)}
                      >
                        {info?.status_foto_display}
                      </div>
                      {info?.alasan_foto && info?.status_foto === 2 && (
                        <div className="w-fit py-1 text-[12px] italic text-red-400 flex flex-wrap">
                          Alasan ditolak : <div className="content-ckeditor" dangerouslySetInnerHTML={{ __html: info?.alasan_foto }}></div>
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    {info && info.foto && (
                      <>
                        <img
                          src={info?.foto}
                          alt="foto"
                          className="w-[150px]"
                        />
                        <div>
                          <div
                            className="w-fit px-5 py-1 mt-2 text-[11px]"
                            style={getColor(info?.status_foto)}
                          >
                            {info?.status_foto_display}
                          </div>
                          {info?.alasan_foto && info?.status_foto === 2 && (
                            <div className="w-fit py-1 text-[12px] italic text-red-400 flex">
                              Alasan ditolak : <div className="content-ckeditor" dangerouslySetInnerHTML={{ __html: info?.alasan_foto }}></div>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td className="w-[20%]">Email</td>
              <td>:</td>
              <td className="align-top">
                {isEdit ? (
                  <>
                    <FormTextSingleComp
                      {...formData.email}
                      hideLabel={true}
                      formData={formData}
                      setFormData={setFormData}
                      disabled={formData.email.is_lock}
                    />
                  </>
                ) : (
                  <>{info?.email}</>
                )}
              </td>
            </tr>
            <tr>
              <td className="w-[20%]">HP</td>
              <td>:</td>
              <td className="align-top">
                {isEdit ? (
                  <FormTextSingleComp
                    {...formData.hp}
                    hideLabel={true}
                    disabled={formData.hp.is_lock}
                    formData={formData}
                    setFormData={setFormData}
                  />
                ) : (
                  <>{info?.hp}</>
                )}
              </td>
            </tr>
            <tr>
              <td className="w-[20%]">Jabatan</td>
              <td>:</td>
              <td className="align-top">
                {isEdit ? (
                  <FormTextSingleComp
                    {...formData.jabatan}
                    formData={formData}
                    setFormData={setFormData}
                    hideLabel={true}
                    disabled={formData.jabatan.is_lock}
                    onChange={handleChange}
                  />
                ) : (
                  <>{info?.jabatan_display}</>
                )}
              </td>
            </tr>
            {isEdit && (
              <tr>
                <td className="align-top">{formData.surat_pernyataan.label}</td>
                <td className="align-top">:</td>
                <td className="align-top">
                  <FormTextSingleComp
                    {...formData.surat_pernyataan}
                    disabled={formData.surat_pernyataan.is_lock}
                    formData={formData}
                    setFormData={setFormData}
                  />
                  {formData.surat_pernyataan.value.includes("http") && (
                    <div>
                      <button
                        className="px-5 py-2 btn-custom bg-blue-500 text-white rounded"
                        size="sm"
                        onClick={() =>
                          setShowDokumen((showDokumen) => ({
                            ...showDokumen,
                            surat_pernyataan: !showDokumen.surat_pernyataan,
                          }))
                        }
                      >
                        Lihat Surat Pernyataan
                      </button>
                      {showDokumen.surat_pernyataan && (
                        <DynamicShowDocument
                          url={formData.surat_pernyataan.value}
                        />
                      )}
                    </div>
                  )}
                  <div
                    className="w-fit px-5 py-1 mt-2 text-[11px]"
                    style={getColor(info?.status_surat_pernyataan)}
                  >
                    {info?.jabatan === 1
                      ? info?.status_surat_pernyataan_display
                      : info?.status_surat_kuasa_display}
                  </div>
                  {info?.jabatan === 1 ? (
                    <>
                      {info?.alasan_surat_pernyataan &&
                        info?.status_surat_pernyataan === 2 && (
                          <div className="w-fit py-1 text-[12px] italic text-red-400 flex flex-wrap">
                            Alasan ditolak :
                            <div className="content-ckeditor" dangerouslySetInnerHTML={{ __html: info?.alasan_surat_pernyataan }}></div>
                          </div>
                        )}
                    </>
                  ) : (
                    <>
                      {info?.alasan_surat_kuasa &&
                        info?.status_surat_kuasa === 2 && (
                          <div className="w-fit py-1 text-[12px] italic text-red-400 flex-wrap">
                            Alasan ditolak :
                            <div className="content-ckeditor" dangerouslySetInnerHTML={{ __html: info?.alasan_surat_kuasa }}></div>
                          </div>
                        )}
                    </>
                  )}
                </td>
              </tr>
            )}
            {!isEdit && (
              <>
                {info?.jabatan === 1 && (
                  <tr>
                    <td className="align-top">Surat Pernyataan</td>
                    <td className="align-top">:</td>
                    <td className="align-top">
                      <button
                        className="px-5 py-2 btn-custom bg-blue-500 text-white rounded"
                        size="sm"
                        onClick={() =>
                          setShowDokumen((showDokumen) => ({
                            ...showDokumen,
                            surat_pernyataan: !showDokumen.surat_pernyataan,
                          }))
                        }
                      >
                        Lihat Surat Pernyataan
                      </button>
                      {showDokumen.surat_pernyataan && (
                        <DynamicShowDocument url={info?.surat_pernyataan} />
                      )}
                      <div>
                        <div
                          className="w-fit px-5 py-1 mt-2 text-[11px]"
                          style={getColor(info?.status_surat_pernyataan)}
                        >
                          {info?.status_surat_pernyataan_display}
                        </div>
                        {info?.status_surat_pernyataan === 2 &&
                          info?.alasan_surat_pernyataan && (
                            <div className="w-fit py-1 text-[12px] italic text-red-400 flex flex-wrap">
                              Alasan ditolak :
                              <div className="content-ckeditor" dangerouslySetInnerHTML={{ __html: info?.alasan_surat_pernyataan }}></div>
                            </div>
                          )}
                      </div>
                    </td>
                  </tr>
                )}
                {info?.jabatan === 2 && (
                  <tr>
                    <td className="align-top">Surat Kuasa</td>
                    <td className="align-top">:</td>
                    <td className="align-top">
                      <button
                        className="px-5 py-2 btn-custom bg-blue-500 text-white rounded"
                        size="sm"
                        onClick={() =>
                          setShowDokumen((showDokumen) => ({
                            ...showDokumen,
                            surat_kuasa: !showDokumen.surat_kuasa,
                          }))
                        }
                      >
                        Lihat Surat Kuasa
                      </button>
                      {showDokumen.surat_kuasa && (
                        <DynamicShowDocument url={info?.surat_kuasa} />
                      )}
                      <div>
                        <div
                          className="w-fit px-5 py-1 mt-2 text-[11px]"
                          style={getColor(info?.status_surat_kuasa)}
                        >
                          {info?.status_surat_kuasa_display}
                        </div>
                        {info?.status_surat_kuasa === 2 &&
                          info?.alasan_surat_kuasa && (
                            <div className="w-fit py-1 text-[12px] italic text-red-400">
                              {info?.alasan_surat_kuasa}
                            </div>
                          )}
                      </div>
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
      {isEdit &&
        <div className="flex flex-row justify-end gap-2">
          <button
            disabled={info?.is_lock || isLoading}
            className={`${info?.is_lock || isLoading
              ? "bg-gray-300 opacity-50 text-gray-600"
              : "bg-blue-500 text-white"
              } shadow px-5 py-2 text-sm  rounded`}
            size="sm"
            onClick={handleSubmit}
          >
            Simpan
          </button>
          <button
            disabled={info?.is_lock || isLoading}
            className={`${info?.is_lock || isLoading
              ? "bg-gray-300 opacity-50 text-gray-600"
              : "bg-blue-500 text-white"
              } shadow px-5 py-2 text-sm  rounded`}
            onClick={() => navigate("/member")}
            size="sm"
          >
            Cancel
          </button>
        </div>
      }
    </div>
  );
};
export default DashboardMember;
