import { Fragment } from "react"
import { usePasswordDownload } from "./passwordDownloadFunction";
import Card from "../../../../Components/Layout/Card";
import FormTextSingleComp from "../../../../Components/forms/FormTextSingleComp";
import AlertComp from "../../../../Components/Atoms/AlertComp";
import Tables from "../../../../Components/molecules/Tables";
import PagePagination from "../../../../Components/Atoms/PagePagination";
import Tooltip from "../../../../Components/Atoms/Tooltip";
import { Icon } from "@iconify/react/dist/iconify.js";


const PasswordDownloadPage = () => {
    const {formData, setFormData, handleSubmit, alertValue, handleCloseAlert,
        view, handleCancel, handleCreate, pageValue, listData, handlePaginate,
        handleEdit, handleDelete
    } = usePasswordDownload();
    return(
        <Fragment>
            <AlertComp
                isOpen={alertValue.show}
                onClose={handleCloseAlert}
                text={alertValue.text}
                subText={alertValue.subText}
                color={alertValue.color}
                icon={alertValue.icon}
            />
            
                {/* title='Master Kandidat Ketua Umum'
                isCreate={view === 'create'}
                isSearch={view === 'list'}
                searchValue={pageValue.search}
                handleCreate={handleCreate}
                handleSubmit={handleSubmit}
                handleCancel={handleCancel}
                onChangeSearch={handleChangeSearch}
                handleSearch={handleSearch} */}
            
            <Card
                isSearch={false}
                isCreate={view === 'create'}
                handleSubmit={handleSubmit}
                title="Password Download"
                searchValue={pageValue.search}
                handleCreate={handleCreate}                
                handleCancel={handleCancel}                
            >
                { view === 'list' &&
                    <Fragment>
                        <Tables className='border-separate rounded-sm'>
                            <Tables.Head className='bg-[#efeeee]'>
                                <tr>
                                    <Tables.Header className='font-bold text-[13px] py-[10px] text-right'>#</Tables.Header>
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>Jenis Password</Tables.Header>
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>Password</Tables.Header>
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>Action</Tables.Header>
                                </tr>
                            </Tables.Head>
                            <Tables.Body>
                                { listData?.data?.length === 0 ?
                                    <Tables.Row>
                                        <Tables.Data colspan={4} className='text-center'>Belum ada data</Tables.Data>
                                    </Tables.Row>
                                    :
                                    <Fragment>
                                        {listData?.data?.map((post, index) => {
                                            return (
                                                <Tables.Row key={index}>
                                                    <Tables.Data className='text-right align-top'>{pageValue.offset + index + 1}</Tables.Data>
                                                    <Tables.Data className='align-top'>{post.kode_display}</Tables.Data>
                                                    <Tables.Data className='align-top'>{post.showPassword ? post.password : '******'}</Tables.Data>
                                                    <Tables.Data className='align-top'>
                                                        <div className='flex flex-row gap-1'>
                                                            <Tooltip
                                                                tooltip={'Edit'}
                                                            >
                                                                <button onClick={() => handleEdit(post)} className='p-1 bg-orange-500 text-white rounded'><Icon icon='line-md:edit' /></button>
                                                            </Tooltip>
                                                            <Tooltip
                                                                tooltip={'Hapus'}
                                                            >
                                                                <button onClick={() => handleDelete(post)} className='p-1 bg-red-500 text-white rounded'><Icon icon='fluent-mdl2:delete'  /></button>
                                                            </Tooltip>
                                                        </div>
                                                    </Tables.Data>

                                                </Tables.Row>
                                            )
                                        })}
                                    </Fragment>
                                }
                            </Tables.Body>
                        </Tables>
                        <div className='mt-5'>
                            <PagePagination
                                data={pageValue.obj}
                                handlePagination={handlePaginate}
                                page={pageValue.page}
                                lastPage={pageValue.lastPage}
                            />
                        </div>
                    </Fragment>
                }
                { view === 'create' &&
                    <div className="grid grid-cols-2">
                        <div className="col-span-2 md:col-span-1">
                            <FormTextSingleComp
                                {...formData.kode}
                                formData={formData}
                                setFormData={setFormData}
                            />
                            <div className="mt-5">
                                <FormTextSingleComp
                                    {...formData.password}
                                    formData={formData}
                                    setFormData={setFormData}
                                />
                            </div>
                        </div>
                    </div>
                }
            </Card>
        </Fragment>
    )
}
export default PasswordDownloadPage;