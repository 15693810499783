import { useCallback, useContext, useEffect, useState } from "react";
import { useGlobalFunction } from "../../../../generalFunction";
import config from "../../../../config";
import api from "../../../../api";
import { ThemeContext } from "../../../../context/ThemeContext";
import { pageObj } from "../../../../pageObj";


export function usePasswordDownload(){
    const { 
        alertValue, setAlertValue,  handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal, 
        isLoading, setIsLoading, catchFetching, getStatusColors, getColor, showLoading, closeLoading
    } = useGlobalFunction();
    const [isEdit, setIsEdit] = useState(false);
    const { setTitle } = useContext(ThemeContext)
    const [view, setView] = useState('list');
    const [listData, setListData] = useState({data : [], isLoading: false})

    const [formData, setFormData] = useState({
        id : {
            name : 'id',
            value : '',
            type : 'text',
            show : true,
            label : 'ID',
            inputType : 'text',
            errorMsg : '',
            showError : false,
            required : false,
            showPassword : false,
            placeholder : 'Isi password untuk download file'
        },
        kode : {
            name : 'kode',
            value : '',
            type : 'select',
            show : true,
            label : 'Jenis Password',            
            errorMsg : '',
            showError : false,
            required : true,
            showPassword : false,
            placeholder : 'Pilih salah satu jenis password',
            obj : []
        },
        password : {
            name : 'password',
            value : '',
            type : 'password',
            show : true,
            label : 'Password',
            inputType : 'password',
            errorMsg : '',
            showError : false,
            required : true,
            showPassword : false,
            placeholder : 'Isi password untuk download file'
        }
    })

    const fetchData = useCallback(async(q='', offset=0, limit=config.itemPerPage) => {
        showLoading()
        try{
            const params = {
                offset : offset,
                limit : limit,
            }
            if (q) {
                params['q'] = q
            }
            const res = await api.get(`${config.endPoint.passwordDownload}`, {params: params}).then(res => res.data);
            let tmp = [{value : '', label : '-- Pilih Jenis Password --'}]
            if (res){
                let tmpData = [];
                res.results.map(post => {
                    let obj = {...post};
                    obj['showPassword'] = false;
                    tmpData.push(obj);
                    return true;
                })
                setListData(listData => ({...listData, isLoading: false, data: tmpData}))
                setPageValue(pageValue => ({...pageValue,
                    obj : pageObj(res.count, limit, offset),
                    lastPage : Math.ceil(parseInt(res.count) / parseInt(limit))
                }))
                if (res.list_option){
                    // console.log('res >>>', res.list_option)
                    tmp = [...tmp, ...res.list_option]                    
                }                
            }else{
                setListData(listData => ({...listData, isLoading: false}))
            }
            setFormData(formData => ({...formData,
                kode : {...formData.kode, obj : tmp}
            }))
            closeLoading()
        }catch(error){
            setListData(listData => ({...listData, isLoading: false}))
            closeLoading()
            const { response } = error;
            let msg = 'Unable to fetch data'
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            setAlertValue(alertValue => ({...alertValue,
                show: true,
                text : 'Error',
                subText: msg,
                color: 'danger'
            }))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
        // eslint-disable-next-line 
    },[])
    
    useEffect(() => {
        fetchData();
    },[fetchData])


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(formData => ({...formData,
            [name] : {...formData[name], value : value}
        })) 
    }

    useEffect(() => {
        setTitle('Password Download')
    }, [setTitle])

    const handleBlur = (e) => {
        const { name, value } = e.target;
        if (formData[name].required && value === ''){
            setFormData(formData => ({...formData,
                [name] : {...formData[name], showError : true}
            })) 
        }else{
            setFormData(formData => ({...formData,
                [name] : {...formData[name], showError : false}
            })) 
        }
    }

    const handleSubmit = async() => {
        setIsLoading(true);
        showLoading();
        try{    
            let obj = {...formData};
            let countError = 0;
            let tmpForm = {}

            Object.entries(formData).map(([index, post]) => {
                if (post.required && post.value === ''){
                    countError++;
                    post.showError = true;
                }else{
                    post.showError = false;
                    tmpForm[index] = post.value;
                }
                return true;
            })

            if (countError > 0){
                setAlertValue(alertValue => ({...alertValue,
                    show: true, text : 'Lengkapi data', color : 'danger'
                }));
                setFormData(obj);
                setIsLoading(false);
                closeLoading();
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue,
                        show: false
                    }));                    
                }, config.timeOutValue)
                return;
            }

            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmpForm));
            let res;            
            if (isEdit){
                res = await api.put(`${config.endPoint.passwordDownload}${formData.id.value}/`, newForm).then(res => res.data);            
            }else{
                res = await api.post(`${config.endPoint.passwordDownload}`, newForm).then(res => res.data);            
            }
            if (res){
                setAlertValue(alertValue => ({...alertValue, show : true, text :  isEdit ? 'Password Changed' : 'Password saved', color : 'success', subText: ''}));
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show : false}));                    
                }, 5000) 
                fetchData(pageValue.obj, pageValue.offset, config.itemPerPage);
                setView('list');
            }
            setIsLoading(false);
            closeLoading();
        }catch(error){
            setIsLoading(false);
            closeLoading();
            const { response } = error;
            let status = error && error.response && error.response.status ? error.response.status : '';
            catchFetching(error, 'admin')

            let subMsg = ""
            if (response.data && response.data.message){
                subMsg = response.data.message
            }

            if (status && status === 401) {
                return window.location.href = "/admin/login"
            } else {
                setAlertValue(alertValue => ({...alertValue, show : true, text : 'Unable to fetch data', color : 'danger', subText: subMsg}));
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show : false}));                    
                }, 5000)         
            }
        }
    }    

    const handleCreate = () => {
        setView('create');
        setIsEdit(false);
        setFormData(formData => ({...formData,
            id : {...formData.id, value : ''},
            kode : {...formData.kode, value : ''},
            password : {...formData.password, value : '', showPassword: false, inputType: 'password', type: 'password'},
        }))
    }

    const handleCancel = () => {
        setView('list')
    }

    const handlePaginate = (page) => {
        let myOffset = (parseInt(page) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
        setPageValue(pageValue => ({...pageValue, page : page, offset: myOffset}))
        fetchData(pageValue.search, myOffset, config.itemPerPage);
    }

    const handleEdit = (post) => {
        setView('create');
        setIsEdit('true');
        setFormData(formData => ({...formData,
            id : {...formData.id, value : post.id},
            kode : {...formData.kode, value : post.kode},
            password : {...formData.password, value : post.password, showPassword: false, inputType: 'password', type: 'password'},            
        }))
    }

    const handleDelete = (params) => {
        setModalValue(modalValue => ({...modalValue,
            show: true,
            text: `Apakah anda yakin akan menghapus daftar pemilih ${params.nama} ?`,
            id : params.id,
            title : 'Konfirmasi',
        }))
    }
    
    const handleSubmitDelete = async() => {
        setIsLoading(true);
        try{
            const res = await api.delete(`${config.endPoint.passwordDownload}${modalValue.id}/`).then(res => res.data);
            if (res){
                fetchData(pageValue.search, pageValue.offset, config.itemPerPage);
                setAlertValue(alertValue => ({...alertValue,
                    show: true,
                    text : 'Delete Success',
                    subText: 'Data berhasil di delete',
                    color: 'success'
                }))
    
                setTimeout(() => {
                    handleCloseAlert();
                }, config.timeOutValue)
            }
            handleCloseModal()
        }catch(error){
            setIsLoading(false);
            const { response } = error;
            let msg = 'Unable to delete data'
    
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            setAlertValue(alertValue => ({...alertValue,
                show: true,
                text : 'Error',
                subText: msg,
                color: 'danger'
            }))
    
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }

    return {
        alertValue, setAlertValue,  handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal, 
        isLoading, setIsLoading, catchFetching, getStatusColors, getColor,
        handleChange, handleBlur, formData, setFormData, handleSubmit, listData,
        view, handleCancel, handleCreate, handlePaginate, handleEdit, handleDelete, handleSubmitDelete
    }
}