import { Fragment } from "react";
import Card from "../../../../Components/Layout/Card";
import Tables from "../../../../Components/molecules/Tables";
import { Icon } from "@iconify/react/dist/iconify.js";
import PagePagination from "../../../../Components/Atoms/PagePagination";
import Button from "../../../../Components/Atoms/Button";
import Search from "../../../../Components/Atoms/Search";
import Modal from "../../../../Components/Atoms/Modal";
import { useMenu } from "../../../../constants/menuFunction";
import { capitalAllLetters } from "../../../../utils/capitalAllLetters";
import CropImage from "../../../../Components/Atoms/CropImage";
import {  usePersiapanIdCardPanitia } from "./persiapanIdCardPanitiaFunction"
import foto_default_peserta from "../../../../assets/images/foto_default_peserta.png"
import default_user from "../../../../assets/images/default_user.png";
import AlertComp from "../../../../Components/Atoms/AlertComp";
const PersiapanIdCardPanitiaPage = () => {
    const {
        listData,
        pageValue,
        handlePaginate,
        handleChangeSearch,
        handleSearch,
        handleViewDocument,
        modalValue,
        setModalValue,
        dokumen,
        dataSelected,
        handleApprove,
        isLoading,
        handleCloseModal,
        loadingReject,
        modalState,
        modalDispatch,
        handleCropComplete,
        alertValue,
        handleCloseAlert
    } = usePersiapanIdCardPanitia();

    const { listPermissions, isSuperUser } = useMenu();

    const getColorLocal = (params) => {
        if (params === 1) {
            return "text-green-600 bg-green-100";
        } else if (params === 2) {
            return "text-red-600 bg-red-100";
        } else if (params === 3) {
            return "text-white bg-gray-500";
        } else {
            return "text-white bg-black/40";
        }
    };

    const getIcon = (params) => {
        if (params === 1) {
            return "ri:check-double-fill";
        } else if (params === 2) {
            return "typcn:minus";
        } else if (params === 3) {
            return "svg-spinners:clock";
        } else {
            return "ic:baseline-lock";
        }
    };


    return (
        <Fragment>
            <AlertComp
                isOpen={alertValue.show}
                onClose={handleCloseAlert}
                text={alertValue.text}
                subText={alertValue.subText}
                color={alertValue.color}
                icon={alertValue.icon}
            />

            <Modal
                show={modalState.isOpen}
                setShow={() => {
                    modalDispatch({ type: "CLOSE" });
                }}
                width="xl"
                persistent={false}
                btnClose={true}
                title={modalState.title}
                withHeader={true}
            >
                {modalState.content}
            </Modal>
            <Modal
                show={modalValue.show}
                setShow={() => {
                    !isLoading && setModalValue((modalValue) => ({ ...modalValue, show: false }));
                }}
                width={`${dokumen.width ? dokumen.width : "md"}`}
                persistent={false}
                btnClose={false}
                title={modalValue.title}
                withHeader={true}
                isLoading={loadingReject}
            >
                <div className="p-[20px]">
                    {modalValue.tipe === "crop_foto" && (
                        <div>
                            {dokumen.tipe === "crop_foto" && (
                                <>
                                    {dokumen.url ? (
                                        <CropImage src={dokumen.url} onCropComplete={handleCropComplete} useToggleAcpect preview />
                                    ) : (
                                        <p className="mb-4">Foto Belum Ada</p>
                                    )}

                                    <div className="">
                                        {(listPermissions.includes("change_kontak") || isSuperUser) && (
                                            <div className="flex justify-between gap-2">
                                                {dataSelected &&
                                                    (
                                                        <Button
                                                            color="danger"
                                                            onClick={
                                                                handleCloseModal
                                                            }
                                                            size="sm"
                                                            className="disabled:opacity-65"
                                                            disabled={isLoading}
                                                        >
                                                            Batal
                                                        </Button>
                                                    )}

                                                {dataSelected &&
                                                    (
                                                        <Button
                                                            color="success"
                                                            size="sm"
                                                            onClick={handleApprove}
                                                            className="disabled:opacity-65"
                                                            disabled={isLoading}
                                                        >
                                                            Simpan
                                                        </Button>
                                                    )}
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                    )}

                    {modalValue.tipe === "foto" && (
                        <>
                            {dokumen.url ? (
                                <img src={dokumen.url} alt="foto" onError={(e) => e.target.src = foto_default_peserta} width={'50%'} className="mx-auto border-2 border-dashed p-2" />
                            ) : (
                                <p className="mb-4">Foto Belum Ada</p>
                            )}

                            <div className="">

                                <div className="flex justify-end gap-2">
                                    <Button
                                        color="danger"
                                        onClick={
                                            handleCloseModal
                                        }
                                        size="sm"
                                        className="disabled:opacity-65"
                                        disabled={isLoading}
                                    >
                                        Close
                                    </Button>
                                </div>
                            </div>
                        </>
                    )}

                    {modalValue.tipe === "lihat_crop_foto" && (
                        <>
                            {dokumen.url ? (
                                <img src={dokumen.url} alt="foto" onError={(e) => e.target.src = foto_default_peserta} width={'50%'} className="mx-auto border-2 border-dashed p-2" />
                            ) : (
                                <p className="mb-4">Foto Belum Ada</p>
                            )}

                            <div className="">

                                <div className="flex justify-end gap-2">
                                    <Button
                                        color="danger"
                                        onClick={
                                            handleCloseModal
                                        }
                                        size="sm"
                                        className="disabled:opacity-65"
                                        disabled={isLoading}
                                    >
                                        Close
                                    </Button>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </Modal>

            <Card
                isSearch={false}
                isCreate={false}
                title="Data Peserta"
                bodyStyle={{ marginTop: 0 }}
            >
                <div className="flex flex-row justify-between">
                    <div className="w-full md:w-60 mb-3">
                        <Search
                            onChange={handleChangeSearch}
                            handleSearch={handleSearch}
                            value={pageValue.search}
                            placeholder="Search"
                        />
                    </div>
                </div>
                <Tables className="border-separate rounded-sm">
                    <Tables.Head className="bg-[#efeeee]">
                        <tr>
                            <Tables.Header className="font-bold text-[13px] py-[10px] text-right">
                                #
                            </Tables.Header>
                            <Tables.Header className="font-bold text-[13px] py-[10px]">
                                Crop Foto
                            </Tables.Header>
                            <Tables.Header className="font-bold text-[13px] py-[10px]">
                                Nama
                            </Tables.Header>
                            <Tables.Header className="font-bold text-[13px] py-[10px]">
                                Kategori Panitia
                            </Tables.Header>
                            <Tables.Header className="font-bold text-[13px] py-[10px]">
                                Foto Asli
                            </Tables.Header>
                        </tr>
                    </Tables.Head>
                    <Tables.Body>
                        {listData.isLoading &&
                            Array(16)
                                .fill(0)
                                .map((_, i) => {
                                    return (
                                        <Tables.Row className="animate-pulse" key={i}>
                                            <Tables.Data
                                                className="text-right"
                                                style={{ textAlign: "right" }}
                                            >
                                                <div className="h-4 w-[10px] bg-slate-200 rounded"></div>
                                            </Tables.Data>
                                            <Tables.Data>
                                                <div className="h-4 bg-slate-200 rounded"></div>
                                            </Tables.Data>
                                            <Tables.Data>
                                                <div className="h-4 bg-slate-200 rounded"></div>
                                            </Tables.Data>
                                            <Tables.Data>
                                                <div className="h-4 bg-slate-200 rounded"></div>
                                            </Tables.Data>
                                            <Tables.Data>
                                                <div className="h-4 bg-slate-200 rounded"></div>
                                            </Tables.Data>
                                        </Tables.Row>
                                    );
                                })}
                        {!listData.isLoading &&
                            listData?.data?.map((post, index) => {
                                return (
                                    <Tables.Row
                                        key={index}
                                        className={`${post.is_lock && "row-lock"}`}
                                    >
                                        <Tables.Data className="text-right align-top">
                                            {pageValue.offset + index + 1}
                                        </Tables.Data>

                                        <Tables.Data className="align-top">
                                            <div
                                                className={`px-1 bg-gray-100 w-full flex flex-col items-center`}
                                            >
                                                <div className={`${post?.crop_foto_url ? "border-2 border-dashed p-2 border-green-500" : ""}`}>
                                                    <img
                                                        src={(post && post.crop_foto_url) || foto_default_peserta}
                                                        onError={(e) => { e.target.src = foto_default_peserta; }}
                                                        alt="foto"
                                                        className="h-28 w-auto object-contain cursor-pointer"
                                                        onClick={() => handleViewDocument("lihat_crop_foto", post)}
                                                    />
                                                </div>
                                                <button
                                                    className={`flex flex-row w-fi ${getColorLocal(
                                                        post?.status_crop_foto
                                                    )} px-2 py-1 rounded`}
                                                    onClick={() => handleViewDocument("crop_foto", post)}
                                                >
                                                    <Icon
                                                        icon={getIcon(post?.status_crop_foto)}
                                                    />
                                                    <div className="text-[12px] w-fit">Edit Crop Foto</div>
                                                </button>
                                            </div>
                                        </Tables.Data>

                                        <Tables.Data className="align-top">
                                            <div className="flex flex-col items-start">
                                                <div>{capitalAllLetters(post.nama)}</div>
                                            </div>
                                        </Tables.Data>

                                        <Tables.Data className="align-top">
                                            <div className="flex flex-col items-start">
                                                <div>{post.kategori_display}</div>
                                            </div>
                                        </Tables.Data>

                                        <Tables.Data className="align-top">
                                            <div
                                                className={`px-1 bg-gray-100 w-full flex flex-col items-center`}
                                            >
                                                <div className="">
                                                    <img
                                                        src={(post && post.foto_url) || default_user}
                                                        onError={(e) => { e.target.src = default_user }}
                                                        alt="foto"
                                                        className="h-16 w-auto object-contain cursor-pointer"
                                                        onClick={() => handleViewDocument("foto", post)}
                                                    />
                                                </div>
                                            </div>
                                        </Tables.Data>
                                    </Tables.Row>
                                );
                            })}
                    </Tables.Body>
                </Tables>
                <div className="mt-5 flex flex-row justify-start w-full">
                    <PagePagination
                        data={pageValue.obj}
                        handlePagination={handlePaginate}
                        page={pageValue.page}
                        lastPage={pageValue.lastPage}
                    />
                </div>
            </Card>
        </Fragment>
    );
};
export default PersiapanIdCardPanitiaPage;
