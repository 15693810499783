

const CoverLoginPage = ({myRef, dataPage}) => {
    return(
        <div 
            className='page absolute duration-1000 transition transform
            w-calendar h-[calc(100%-100px)]  rounded-[30px] bg-gradient-to-r  from-blue-900 to-blue-500 shadow-lg' 
            data-page={dataPage}
            ref={myRef}
        >
            <div className="front absolute 
                flex flex-col items-center w-full h-full justify-center"
            >
                <div className='w-[38%]'>
                    <img src={require('../../../../assets/images/logo_barong.png')} alt="logo" />
                </div>
                <div className='text-white text-[24px] mt-[40px]'>Musyawarah Nasional XII APJII </div>
                <div className='text-white text-[48px] font-bold'>2024</div>
            </div>
        </div>
    )
}
export default CoverLoginPage;