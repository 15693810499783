import { menu } from "../../constants/menu";
import { ThemeContext } from "../../context/ThemeContext";
import { Disclosure, Transition } from "@headlessui/react";
import { useCallback, useContext, useEffect, useState } from "react";
import { RxBorderDashed } from "react-icons/rx";
import { TbChevronRight, TbCircle } from "react-icons/tb";
import { NavLink, useLocation, } from "react-router-dom";
import api from "../../api";
import { UserContext } from "../../context/UserContext";
import { decryptedResponse } from "../../utils/crypto";
import Cookies from 'universal-cookie';
import config from "../../config";

const Menu = ({ sideOpen, openHover }) => {
	const [initNav, setInitNav] = useState([]);
	const [nav, setNav] = useState([]);
	const { pathname } = useLocation();
	const cookies = new Cookies();
	const [myMenu, setMyMenu] = useState([]);
	const { themeColor } = useContext(ThemeContext);
	const myState = useContext(UserContext);
	const [isSuperUser, setIsSuperUser] = myState.isSuperUser
	const [listPermissions, setListPermissions] = useState([]);
	const [readyMenu, setReadyMenu] = useState(false);
	const [readyToRender, setReadyToRender] = useState(false);
	const [listGroup, setListGroup] = useState([]);

	const navOpen = (data) => {
		setNav({ ...initNav, [data]: !nav[data] });
	};

	const navClose = (data) => {
		if (data) {
			setNav({ ...initNav, [data]: true });
		} else {
			setNav(initNav);
		}
	};

	const fetchData = useCallback(async () => {
		try {
			const res = await api.get(`${config.endPoint.userRole}`).then((res) => res);
			const dekrip = decryptedResponse(res.data?.result)
			if (dekrip === null) {
				//logout
				cookies.remove(config.cookiesName, { path: "/" });
			}
			setListPermissions(dekrip.data);
			setIsSuperUser(dekrip.is_superuser)
			setListGroup(dekrip.groups)
			setReadyMenu(true);
		} catch (error) {
			console.log(error)
			cookies.remove(config.cookiesName, { path: "/" });
		}
		// eslint-disable-next-line 
	}, [])


	useEffect(() => {
		fetchData();
	}, [fetchData])

	useEffect(() => {
		if (readyMenu) {
			if (isSuperUser) {
				setMyMenu(menu);
			} else {
				let tmp = [];
				menu.map((post) => {
					if (post.sub.length > 0) {
						let tmp_sub_menu = [];
						post.sub.map((submenu) => {
							if (listGroup.some(x => submenu.permission_group.includes(x))) {
								tmp_sub_menu.push(submenu)
							}
							return true;
						})
						post['sub'] = tmp_sub_menu
						tmp.push(post);
					} else {
						if (listGroup.some(r => post.permission_group.includes(r))) {
							tmp.push(post)
						}
					}
					return true;
				})
				let array_index = []
				tmp.map((post, index) => {
					if (!post.element && post.sub.length === 0) {
						array_index.push(index)
					}
					return true;
				})


				array_index.map(index => {
					tmp.splice(index, 1)
					return true;
				})
				setMyMenu(tmp);
			}
			setReadyToRender(true);
		}
		// eslint-disable-next-line	
	}, [listPermissions, readyMenu, isSuperUser, listGroup])

	useEffect(() => {
		const initNav = myMenu
			.filter((item) => item.sub?.length > 0)
			.reduce((acc, item) => {
				acc[item.name] = false;
				return acc;
			}, {});

		setNav(initNav);
		setInitNav(initNav);
	}, [myMenu]);

	useEffect(() => {
		if (!openHover) {
			if (!sideOpen) setNav(initNav);
		}
	}, [openHover]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (!sideOpen) {
			if (openHover) {
				const menuActive = pathname.split("/")[1];
				setNav({ ...initNav, [menuActive]: true });
			}
		}
	}, [openHover]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="my-3">
			{readyToRender && myMenu.map((item, itemIdx) => {
				if (item.label) {
					return (
						<div
							key={itemIdx}
							className={`px-3 mt-6 mb-3 m-3 text-xs text-base-200 whitespace-nowrap h-3 ${sideOpen || openHover ? "" : "flex items-center justify-center"
								}`}
						>
							{sideOpen || openHover ? item.label : <RxBorderDashed />} asdfasdf
						</div>
					);
				}

				if (item?.sub?.length === 0) {
					return (
						<NavLink onClick={navClose} key={itemIdx} to={item.path}>
							{({ isActive }) => (
								<div className="mb-1 px-3 w-full text-sm">
									<div
										style={{
											background: isActive
												? `linear-gradient(90deg, ${themeColor} 0%, ${themeColor}90 100%)`
												: "",
											boxShadow: isActive
												? `0px 0px 10px 0px ${themeColor}60`
												: "",
										}}
										className={`flex items-center justify-between w-full h-10 px-[18px] py-2 rounded-md overflow-hidden ${isActive
											? "text-white"
											: "hover:bg-base-50 dark:hover:bg-base-500"
											}`}
									>
										<span className="flex items-center gap-2">
											<span className="text-xl w-5">{item.icon}</span>
											<span
												className={`${sideOpen || openHover ? "opacity-100" : "opacity-0"
													}`}
											>
												{item.title}
											</span>
										</span>
									</div>
								</div>
							)}
						</NavLink>
					);
				}

				if (item?.sub?.length > 0) {
					return (
						<Disclosure key={itemIdx}>
							<Disclosure.Button
								onClick={() => navOpen(item.name)}
								className="w-full px-3 mb-1 text-sm"
							>
								<div
									className={`flex items-center justify-between w-full h-10 px-[18px] py-2 rounded-md overflow-hidden ${nav[item.name]
										? "bg-base-50 dark:bg-base-500"
										: "hover:bg-base-50 dark:hover:bg-base-500"
										} ${pathname.split("/")[1] === item.name
											? "bg-base-50 dark:bg-base-500"
											: ""
										}`}
								>
									<span className="flex items-center gap-2">
										<span className="text-xl w-5">{item.icon}</span>
										<span
											className={`tracking-wide ${sideOpen || openHover ? "opacity-100" : "opacity-0"
												}`}
										>
											{item.title}
										</span>
									</span>

									<div
										className={`flex items-center gap-2 transition-[transform,opacity] ${nav[item.name] ? "rotate-90" : ""
											} ${sideOpen || openHover ? "opacity-100" : "opacity-0"}`}
									>
										<TbChevronRight />
									</div>
								</div>
							</Disclosure.Button>
							<Transition
								show={nav[item.name]}
								className="overflow-hidden"
								enter="transition-[max-height] duration-300 ease-in"
								enterFrom="max-h-0"
								enterTo="max-h-screen"
								leave="transition-[max-height] duration-300 ease-out"
								leaveFrom="max-h-screen"
								leaveTo="max-h-0"
							>
								<Disclosure.Panel as="div" className="pb-2">
									{item?.sub?.map((subItem, subItemIdx) => (
										<div key={subItemIdx} className="mb-1 px-3 w-full text-sm">
											<NavLink
												onClick={() => navClose(item.name)}
												to={subItem.path}
											>
												{({ isActive }) => (
													<div
														style={{
															background: isActive
																? `linear-gradient(90deg, ${themeColor} 0%, ${themeColor}90 100%)`
																: "",
															boxShadow: isActive
																? `0px 0px 10px 0px ${themeColor}60`
																: "",
														}}
														className={`flex items-center justify-between w-full h-10 px-[18px] py-2 rounded-md overflow-hidden ${isActive
															? "text-white"
															: "hover:bg-base-50 dark:hover:bg-base-500"
															}`}
													>
														<span className="flex items-center gap-2">
															<span className="text-[10px] w-[18px]">
																{subItem.icon ? subItem.icon : <TbCircle />}
															</span>
															<span
																className={`${sideOpen || openHover
																	? "opacity-100"
																	: "opacity-0"
																	}`}
															>
																{subItem.title}
															</span>
														</span>
													</div>
												)}
											</NavLink>
										</div>
									))}
								</Disclosure.Panel>
							</Transition>
						</Disclosure>
					);
				}

				return null;
			})}
		</div>
	);
};

export default Menu;
