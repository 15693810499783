import { useCallback, useEffect, useState } from "react";
import { useGlobalFunction } from "../../../../generalFunction";
import config from "../../../../config";
import api from "../../../../api";
import { useNavigate } from "react-router-dom";

export function useSponsorLoginPage() {
  const {
    isLoading,
    setIsLoading,
    setAlertValue,
    getColor,
    alertValue,
    handleCloseAlert,
  } = useGlobalFunction();
  const navigate = useNavigate();
  const [dataSponsor, setDataSponsor] = useState([]);
  const [dataKategori, setDataKategori] = useState([]);
  const [pilihKategori, setPilihKategori] = useState("");

  const fetchSponsor = useCallback(async () => {
    if (pilihKategori) {
      setIsLoading(true);
      try {
        const params = {
          kategori: pilihKategori,
        };
        const res = await api
          .get(`${config.endPoint.sponsorShipLogin}`, { params: params })
          .then((res) => res.data);
        setDataSponsor(res.results);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        let msg = "Unable to save Data";
        let { response } = error;
        if (response && response.data && response.data.message) {
          msg = response.data.message;
        }
        setAlertValue((alertValue) => ({
          ...alertValue,
          show: true,
          text: "Error",
          subText: msg,
          color: "danger",
        }));
        setTimeout(() => {
          setAlertValue((alertValue) => ({ ...alertValue, show: false }));
        }, config.timeOutValue);
      }
    }
    // eslint-disable-next-line
  }, [pilihKategori]);

  const fetchKategori = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await api
        .get(`${config.endPoint.kategoriSponsorLogin}`)
        .then((res) => res.data);
      setDataKategori(res.results);
      if (res.results[0]) {
        const data = res.results[0];
        setPilihKategori(data.id);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      let msg = "Unable to save Data";
      let { response } = error;
      if (response && response.data && response.data.message) {
        msg = response.data.message;
      }
      setAlertValue((alertValue) => ({
        ...alertValue,
        show: true,
        text: "Error",
        subText: msg,
        color: "danger",
      }));
      setTimeout(() => {
        setAlertValue((alertValue) => ({ ...alertValue, show: false }));
      }, config.timeOutValue);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchSponsor();
  }, [fetchSponsor, pilihKategori]);

  useEffect(() => {
    fetchKategori();
  }, [fetchKategori]);

  return {
    isLoading,
    setIsLoading,
    dataKategori,
    dataSponsor,
    getColor,
    navigate,
    alertValue,
    handleCloseAlert,
    pilihKategori,
    setPilihKategori,
  };
}
