import { useCallback, useContext, useEffect, useState } from "react";
import { useGlobalFunction } from "../../../../generalFunction";
import config from "../../../../config";
import api from "../../../../api";
import { pageObj } from "../../../../pageObj";
import { ThemeContext } from "../../../../context/ThemeContext";


export function useHotel(){
    const { isLoading, showLoading, closeLoading, setIsLoading,
        modalValue, setModalValue, handleCloseModal,
        pageValue, setPageValue, catchFetching, alertValue, setAlertValue, handleCloseAlert
    } = useGlobalFunction();
    const [isEdit, setIsEdit] = useState(false);
    const { setTitle } = useContext(ThemeContext);
    const [listData, setListData] = useState([
        {
            id : 1,
            nama : 'Hotel Alila',
            jumlah_kamar : '17',
            perioritas : 1,
            tempat_registrasi : 'Hotel Alila'
        }
    ]);
    const [view, setView] = useState('list');
    const [formData, setFormData] = useState({
        id : {
            name : 'id',
            label : 'ID',
            value : '',
            type : 'text',
            col : 2,
            required : false,
            show : false,
            errorMsg : '',
            showError : false,
            // flexType : 'row'
        },
        nama : {
            name : 'nama',
            label : 'Nama Hotel',
            value : '',
            type : 'text',
            col : 2,
            required : true,
            show : true,
            errorMsg : '',
            showError : false,
            // flexType : 'row'
        },
        jumlah_kamar : {
            name : 'jumlah_kamar',
            label : 'Jumlah Kamar',
            value : '',
            type : 'number',
            col : 2,
            required : true,
            show : true,
            errorMsg : '',
            showError : false,
            // flexType : 'row',
            className : 'text-right'
        },
        sequence : {
            name : 'sequence',
            label : 'Perioritas Pemilihan Hotel',
            value : '',
            type : 'number',
            col : 2,
            required : true,
            show : true,
            errorMsg : '',
            showError : false,
            // flexType : 'row',
            className : 'text-right'
        },        
        registrasi : {
            name : 'registrasi',
            label : 'Tempat Registrasi',
            value : '',
            type : 'select',
            col : 2,
            required : false,
            show : true,
            errorMsg : '',
            showError : false,
            // flexType : 'row',
            obj : [],
        },
    });
    
    useEffect(() => {
        setTitle('Master Hotel')
    }, [setTitle])

    const fetchData = useCallback(async(q='', offset=0, limit=config.itemPerPage) => {
        setIsLoading(true);
        showLoading()
        try{
            let params = {
                'offset' : offset,
                'limit' : limit
            }
            if (q){
                params['q'] = q;
            }
            const res = await api.get(`${config.endPoint.hotel}`, {params: params}).then(res => res.data);
            let tmp = [{value : '', label : '-- Pilih Tempat Registrasi --'}];
            if (res){
                res.results.map(x => tmp.push({value : x.id, label : x.nama}));
                setListData(res.results);
                setPageValue(pageValue => ({...pageValue,
                    obj : pageObj(res.count, limit, offset),
                    lastPage: Math.ceil(parseInt(res.count) / parseInt(limit))
                }))
            }
            setFormData(formData => ({...formData,
                registrasi : {...formData.registrasi, obj : tmp}
            }))
            closeLoading();
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            closeLoading();
            catchFetching(error, 'admin')
            let msg = "Unable to fetch data";
            if (error.response && error.response.data && error.response.data.message){
                msg = error.response.data.message
            }
            setAlertValue(alertValue => ({...alertValue, show: true, text : msg, color : 'danger'}))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))                
            }, config.timeOutValue);
        }
        // eslint-disable-next-line
    },[setIsLoading, setAlertValue, setPageValue])

    useEffect(() => {
        fetchData();
    },[fetchData])

    const handleSubmit = async(params) => {
        setIsLoading(true);
        showLoading();
        try{
            let countError = 0;
            let form = {...formData};
            let tmpForm = {}
            Object.entries(form).map(([index, post]) => {
                if (index !== 'redaksional'){
                    if (post.required && (post.value === '' || post.value === null)){
                        post.showError = true;
                        countError++;
                    }else{
                        tmpForm[index] = post.value;
                    }
                }
                return true;
            });
            
            if (countError > 0){
                setFormData(form);
                setIsLoading(false);
                closeLoading();
                setAlertValue(alertValue => ({...alertValue, show: true, text : 'Lengkapi Data', color: 'danger'}));
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue);
                return;
            }
            setIsLoading(false);

            
            
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmpForm));
            
           
            let res;
            if (isEdit){
                res = await api.put(`${config.endPoint.hotel}${formData.id.value}/`, newForm).then(res => res.data);
            }else{
                res = await api.post(`${config.endPoint.hotel}`, newForm).then(res => res.data);
            }
            if (res){
                if (params === 'save'){
                    setFormData(formData => ({...formData,
                        id : {...formData.id, value : res.result.id},
                        nama : {...formData.nama, value : res.result.nama},
                        jumlah_kamar : {...formData.jumlah_kamar, value : res.result.jumlah_kamar},
                        sequence : {...formData.sequence, value : res.result.sequence},
                        registrasi_display : {...formData.registrasi_display, value : res.result.registrasi_display},
                        registrasi : {...formData.registrasi, value : res.result.registrasi || ''},
                    }));
                    setIsEdit(true);
                }else{
                    setView('list');
                    fetchData();
                }
            }
            setIsLoading(false);      
            closeLoading();      
        }catch(error){
            setIsLoading(false);
            closeLoading();
            catchFetching(error, 'admin')

            let msg = "Unable to save data";
            if (error.response && error.response.data && error.response.data.message){
                msg = error.response.data.message
            }
            setAlertValue(alertValue => ({...alertValue, show: true, text : msg, color : 'danger'}))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))                
            }, config.timeOutValue);
        }
    }

    const handleDelete = (post) => {
        setModalValue(modalValue => ({...modalValue,
            show: true,
            id : post.id,
            text : `Apakah anda yakin ingin menghapus hotel ${post.nama} ?`,
            title : 'Konfirmasi',
        }))
    }

    const handleSubmitDelete = async() => {
        setIsLoading(true);
        showLoading();
        try{
            const res = await api.delete(`${config.endPoint.hotel}${modalValue.id}/`).then(res => res.data);
            if(res){
                fetchData(pageValue.search, pageValue.offset);
                handleCloseModal();
                setAlertValue(alertValue => ({...alertValue, show: true, text : 'Data dihapus', color : 'danger'}))
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))                
                }, config.timeOutValue);
            }
            setIsLoading(false);
            closeLoading();
        }catch(error){
            setIsLoading(false);
            closeLoading();
            catchFetching(error, 'admin')

            let msg = "Unable to save data";
            if (error.response && error.response.data && error.response.data.message){
                msg = error.response.data.message
            }
            setAlertValue(alertValue => ({...alertValue, show: true, text : msg, color : 'danger'}))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))                
            }, config.timeOutValue);
        }
    }

    const handleCreate = () => {
        setView('create');        
        setIsEdit(false);
        setFormData(formData => ({...formData,
            id : {...formData.id, value : ''},
            nama : {...formData.nama, value : ''},
            jumlah_kamar : {...formData.jumlah_kamar, value : ''},
            sequence : {...formData.sequence, value : ''},
            registrasi : {...formData.registrasi, value : ''},
            // registrasi_display : {...formData.registrasi_display, value : ''},
        }));
    }

    const handleCancel = () => {
        setView('list');
    }

    const handleEdit = (params) => {
        setIsEdit(true);
        setFormData(formData => ({...formData,
            id : {...formData.id, value : params.id},
            nama : {...formData.nama, value : params.nama},
            jumlah_kamar : {...formData.jumlah_kamar, value : params.jumlah_kamar},
            sequence : {...formData.sequence, value : params.sequence},
            registrasi_display : {...formData.registrasi_display, value : params.registrasi_display},
            registrasi : {...formData.registrasi, value : params.registrasi || ''},
        }));        
        setView('create');        
    }

    const handleChangeSearch = (e) => {
        const { name, value } = e.target;
        setPageValue(pageValue => ({...pageValue,
            [name] : value
        }))
    }

    const handleSearch = () => {
        setPageValue(pageValue => ({...pageValue, offset: 0, page : 1}))
        fetchData(pageValue.search, 0, config.itemPerPage)
    }

    const handleKeyDownSearch = (e) => {
        if (e.key === "Enter"){
            handleSearch();
        }
    }

    const handlePaginate = (page) => {
        let myOffset = (parseInt(page) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
        setPageValue(pageValue => ({...pageValue, page : page, offset: myOffset}))
        fetchData(pageValue.search, myOffset, config.itemPerPage);
    }

    

    return {
        isLoading, showLoading, closeLoading, setIsLoading, listData,
        formData, setFormData,  setListData, handleSubmit,
        handleDelete, modalValue, setModalValue, handleCloseModal, handleSubmitDelete,
        pageValue, setPageValue, view,
        handleCreate, handleCancel, alertValue, handleCloseAlert, isEdit,
        handleEdit, handleChangeSearch, handleSearch,
        handleKeyDownSearch, handlePaginate
    }
}