import { Fragment } from "react"
import { useEmailTemplate } from "./emailTemplateFunction";
import AlertComp from "../../../../Components/Atoms/AlertComp";
import Card from "../../../../Components/Layout/Card";
import Tables from "../../../../Components/molecules/Tables";
import FormTextSingleComp from "../../../../Components/forms/FormTextSingleComp";
import Button from "../../../../Components/Atoms/Button";
import { Helmet } from "react-helmet";
import { Icon } from "@iconify/react/dist/iconify.js";
import Modal from "../../../../Components/Atoms/Modal";
import PagePagination from "../../../../Components/Atoms/PagePagination";


const EmailTemplatePage = () => {
    const { alertValue, handleCloseAlert, listData, view,
        pageValue, handleCreate, handleCancel, handleSubmit, formData, setFormData,
        data_helper, handleChangeEditor, editorView, htmlPreview, handleDelete, handleEdit,
        modalValue, setModalValue, handleSubmitDelete, handleCloseModal, handleChangeSearch, handleSearch,
        handlePaginate
    } = useEmailTemplate();
    return(
        <Fragment>
            <AlertComp
                isOpen={alertValue.show}
                onClose={handleCloseAlert}
                text={alertValue.text}
                subText={alertValue.subText}
                color={alertValue.color}
                icon={alertValue.icon}
            />
            <Modal
                show={modalValue.show}
                setShow={() => {                        
                    setModalValue(modalValue => ({...modalValue, show: false}))
                }}
                width="xl"
                persistent={false}   
                btnClose={false}   
                title={modalValue.title}
                withHeader={true}
            >

                <div className='p-[20px]'>
                    { modalValue.text}
                </div>
                <div className='border-t border-t-gray-200 p-[20px]'>
                    <div className="flex flex-row justify-end gap-2">
                        <Button className='bg-red-500 text-white' onClick={handleSubmitDelete}>Hapus</Button>
                        <Button className='bg-gray-400 text-white' onClick={handleCloseModal}>Cancel</Button>
                    </div>
                </div>
            </Modal>
            <Card
                isCreate={view === 'create'}
                isSearch={true}
                className={'w-full'}
                handleCreate={handleCreate}
                handleCancel={handleCancel}
                handleSubmit={handleSubmit}
                onChangeSearch={handleChangeSearch}
                handleSearch={handleSearch}
            >
                { view === 'create' &&
                    <Fragment>
                        <div className='grid grid-cols-12'>
                            <div className='col-start-4 col-span-6'>
                                {Object.entries(formData).map(([index, post]) => {
                                    // console.log('post is >>>', post);
                                    if (post.show && post.type !== 'editor') {
                                        return(
                                            <div key={index} className='mb-3'>
                                                <FormTextSingleComp
                                                    {...post}
                                                    formData={formData}
                                                    setFormData={setFormData}
                                                />
                                            </div>
                                        )
                                    }
                                    return true;
                                })}
                            </div>
                        </div>
                        <div>
                            <div className="flex flex-row justify-between">
                                <div>
                                    <div className='mb-2 font-bold'>Helper</div>
                                    <div className='flex flex-row px-4 py-2 bg-gray-200'>
                                        { [1, '1'].includes(formData.kode.value) &&
                                            <div>{data_helper.filter(x => x.tipe ===  'kekurangan_dokumen' ).map(x => x.value).join()}</div>
                                        }
                                        { [2, '2'].includes(formData.kode.value) &&
                                            <div>{data_helper.filter(x => x.tipe ===  'verifikasi' ).map(x => x.value).join()}</div>
                                        }
                                        { [3, '3'].includes(formData.kode.value) &&
                                            <div>{data_helper.filter(x => x.tipe ===  'lengkap' ).map(x => x.value).join()}</div>
                                        }
                                        { [4, '4'].includes(formData.kode.value) &&
                                            <div>{data_helper.filter(x => x.tipe ===  'create_password' ).map(x => x.value).join()}</div>
                                        }
                                        { [5, '5'].includes(formData.kode.value) &&
                                            <div>{data_helper.filter(x => x.tipe ===  'reset_password' ).map(x => x.value).join()}</div>
                                        }
                                        { [6, '6'].includes(formData.kode.value) &&
                                            <div>{data_helper.filter(x => x.tipe ===  'others' ).map(x => x.value).join()}</div>
                                        }
                                        { [7, '7'].includes(formData.kode.value) &&
                                            <div>{data_helper.filter(x => x.tipe ===  'revisi_dokumen_alasan' ).map(x => x.value).join()}</div>
                                        }
                                    </div>
                                </div>
                                <div className="text-right mb-5 mt-5">
                                    <div className='flex flex-row gap-2'>
                                        <Button color={"primary"} variant="outline" onClick={() => handleChangeEditor('editor')} size="sm">Editor</Button>                                        
                                        <Button color={"danger"} variant="outline" onClick={() => handleChangeEditor('preview')} size="sm">Preview</Button>
                                    </div>
                                </div>
                            </div>
                            { editorView === 'editor' &&
                                <div className="grid grid-cols-10 gap-4 pb-8">
                                    <div className='col-span-10'>
                                        <FormTextSingleComp
                                            {...formData.text_body}
                                            formData={formData}
                                            setFormData={setFormData}
                                            hideLabel={true}                                        
                                        />                                    
                                    </div>
                                    
                                </div>
                            }                        
                            { editorView === 'preview' &&
                                <div className='grid grid-cols-12 border border-gray-200 rounded mt-5 gap-5'>
                                    <div className='col-span-2'>
                                        <div className=''>
                                            <div className="bg-sky-300 rounded-r-full pl-5 py-1 mt-5">
                                                <div className='pl-5 flex flex-row gap-2 items-center font-semibold text-black'>
                                                    <div><Icon icon="ic:baseline-inbox" /></div>
                                                    <div>Inbox</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className=''>
                                            <div className="rounded-r-full pl-5 py-1 mt-1">
                                                <div className='pl-5 flex flex-row gap-2 items-center'>
                                                    <div className='w-[150px] h-[20px] bg-gray-200 rounded-full' />                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className=''>
                                            <div className="rounded-r-full pl-5 py-1 mt-1">
                                                <div className='pl-5 flex flex-row gap-2 items-center'>
                                                    <div className='w-[150px] h-[20px] bg-gray-200 rounded-full' />                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-span-10 p-5 border-l border-l-gray-200'>
                                        <div dangerouslySetInnerHTML={{ __html: htmlPreview }} />
                                        <Helmet>
                                            <style type="text/css">{`
                                                ${formData.css.value}
                                            `}</style>
                                        </Helmet>
                                    </div>
                                </div>
                            }
                        </div>
                    </Fragment>
                }
                { view === 'list' &&
                    <Fragment>
                         <Tables className='border-separate rounded-sm'>
                            <Tables.Head className='bg-[#efeeee]'>
                                <tr>
                                    <Tables.Header className='font-bold text-[13px] py-[10px] text-right'>#</Tables.Header>                            
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>Kode</Tables.Header>            
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>Subject</Tables.Header>
                                    <Tables.Header className='font-bold text-[13px] py-[10px] text-center'>Action</Tables.Header>
                                </tr>
                            </Tables.Head>
                            <Tables.Body>
                                { listData.length === 0 ?
                                    <Tables.Row>
                                        <Tables.Data colspan={4} className='text-center'>Belum ada data</Tables.Data>
                                    </Tables.Row>
                                    :                                    
                                    <Fragment>
                                        {listData.map((post, index) => {
                                            return (
                                                <Tables.Row key={index}>
                                                    <Tables.Data className='text-right'>{pageValue.offset + index + 1}</Tables.Data>
                                                    <Tables.Data>{post.kode_display}</Tables.Data>
                                                    <Tables.Data>{post.subjek}</Tables.Data>                                                    
                                                    <Tables.Data className='text-center'>
                                                        <div className="flex flex-row justify-center gap-1">
                                                            <button onClick={() => handleDelete(post)} className='px-2 py-1 bg-red-500 text-white rounded'>
                                                                <Icon icon="ph:trash-fill" />
                                                            </button>
                                                            <button onClick={() => handleEdit(post)} className='px-2 py-1 bg-indigo-500 text-white rounded'>
                                                                <Icon icon="mage:edit" />
                                                            </button>
                                                        </div>
                                                    </Tables.Data>
                                                </Tables.Row>
                                            )
                                        })}
                                    </Fragment>
                                }
                            </Tables.Body> 
                        </Tables>
                        <PagePagination
                            data={pageValue.obj}
                            handlePagination={handlePaginate}
                            page={pageValue.page}
                            lastPage={pageValue.lastPage}
                        />
                    </Fragment>
                }
            </Card>
        </Fragment>
    )
}
export default EmailTemplatePage;