import { Fragment } from "react";
import FormTextSingleComp from "../../../Components/forms/FormTextSingleComp";
import Tables from "../../../Components/molecules/Tables";
import { useTakdiundang } from "./takDiundangFunction";
import PagePagination from "../../../Components/Atoms/PagePagination";
import { capitalAllLetters } from "../../../utils/capitalAllLetters";
import { Rings } from "react-loader-spinner";



const TakDiundangPage = ({ tipe = "takdiundang" }) => {
    const { view, formPassword, setFormPassword, handleSubmitPassword,
        alertValue, listData, pageValue, handlePaginate, handleKeyDown,
        handleDownload, isLoading
    } = useTakdiundang({ tipe: tipe });

    return (
        <div className='mt-[80px] md:mt-[100px] px-[10px] md:px-[100px]'>

            {view === 'password' &&
                <div className='min-h-[calc(100vh-120px)] h-full flex flex-col justify-center items-center'>
                    <div className='w-[300px]'>
                        {alertValue.show &&
                            <div className='w-full px-5 py-2 bg-red-100 text-red-500 text-xs rounded-md text-center'>{alertValue.subText}</div>
                        }
                        <FormTextSingleComp
                            {...formPassword.password}
                            formData={formPassword}
                            setFormData={setFormPassword}
                            onKeyDown={handleKeyDown}
                        />
                        <button onClick={handleSubmitPassword} className='w-full bg-blue-800 text-white py-3 rounded-md'>Submit</button>
                    </div>
                </div>
            }
            {view === 'list' &&
                <div>
                    <div className="font-bold title md:text-[24px] text-[14px] text-center">Daftar Peserta Musyawarah Nasional XII APJII Tahun 2024</div>
                    <div className="mt-10">
                        <div className="flex flex-row w-full justify-end">
                            <button
                                className='py-2  rounded-lg mb-5 px-8 bg-green-700 text-white  flex flex-row gap-4 items-center'
                                onClick={handleDownload}
                                disabled={isLoading}
                            >
                                <Rings
                                    visible={isLoading}
                                    height="25"
                                    width="25"
                                    color="#fff"
                                    ariaLabel="rings-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                />
                                Download
                            </button>
                        </div>
                        <Tables className='border-separate rounded-sm'>
                            <Tables.Head className='bg-[#efeeee]'>
                                <tr>
                                    <Tables.Header className='font-bold text-[13px] py-[10px] text-right'>#</Tables.Header>
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>Nama Perusahaan</Tables.Header>
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>Nama Peserta</Tables.Header>
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>Email Peserta</Tables.Header>
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>Status Daftar</Tables.Header>
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>Hotel</Tables.Header>
                                    {tipe === 'all' &&
                                        <Tables.Header className='font-bold text-[13px] py-[10px]'>Wilayah</Tables.Header>
                                    }
                                    {tipe === 'all' &&
                                        <Tables.Header className='font-bold text-[13px] py-[10px]'>Updated At</Tables.Header>
                                    }
                                </tr>
                            </Tables.Head>
                            <Tables.Body>
                                {listData.isLoading && Array(10).fill(0).map((_, i) => {
                                    return (
                                        <Tables.Row className='animate-pulse' key={i}>
                                            <Tables.Data className='text-right' style={{ textAlign: 'right' }}>
                                                <div className='h-4 w-[10px] bg-slate-200 rounded'></div>
                                            </Tables.Data>
                                            <Tables.Data>
                                                <div className='h-4 bg-slate-200 rounded'></div>
                                            </Tables.Data>
                                            <Tables.Data>
                                                <div className='h-4 bg-slate-200 rounded'></div>
                                            </Tables.Data>
                                            <Tables.Data>
                                                <div className='h-4 bg-slate-200 rounded'></div>
                                            </Tables.Data>
                                            <Tables.Data>
                                                <div className='h-4 bg-slate-200 rounded'></div>
                                            </Tables.Data>
                                            {tipe === 'all' &&
                                                <Tables.Data>
                                                    <div className='h-4 bg-slate-200 rounded'></div>
                                                </Tables.Data>
                                            }
                                            {tipe === 'all' &&
                                                <Tables.Data>
                                                    <div className='h-4 bg-slate-200 rounded'></div>
                                                </Tables.Data>
                                            }
                                        </Tables.Row>
                                    )
                                })}
                                {!listData.isLoading && listData?.data?.length === 0 ?
                                    <Tables.Row>
                                        <Tables.Data colspan={4} className='text-center'>Belum ada data</Tables.Data>
                                    </Tables.Row>
                                    :
                                    <Fragment>
                                        {listData?.data?.map((post, index) => {
                                            return (
                                                <Tables.Row key={index}>
                                                    <Tables.Data className='text-right align-top'>{pageValue.offset + index + 1}</Tables.Data>
                                                    <Tables.Data className='align-top'>{post.perusahaan_display}</Tables.Data>
                                                    <Tables.Data className='align-top'>
                                                        <div>
                                                            {capitalAllLetters(post.nama)}
                                                        </div>
                                                        {tipe === 'all' &&
                                                            <div className='italic'>0{post.hp}</div>
                                                        }
                                                    </Tables.Data>
                                                    <Tables.Data className='align-top'>{post.email}</Tables.Data>
                                                    <Tables.Data className='align-top'>{post.jabatan_display}</Tables.Data>
                                                    <Tables.Data className='align-top'>{post.hotel_display}</Tables.Data>
                                                    {tipe === 'all' &&
                                                        <Tables.Data className='align-top'>{post.wilayah_display}</Tables.Data>
                                                    }
                                                    {tipe === 'all' &&
                                                        <Tables.Data className='align-top'>{post.updated_at_display}</Tables.Data>
                                                    }
                                                </Tables.Row>
                                            )
                                        })}
                                    </Fragment>
                                }
                            </Tables.Body>
                        </Tables>
                        {!listData.isLoading &&
                            <div className='mt-5 mb-10'>
                                <PagePagination
                                    data={pageValue.obj}
                                    handlePagination={handlePaginate}
                                    page={pageValue.page}
                                    lastPage={pageValue.lastPage}
                                />
                            </div>
                        }
                    </div>
                </div>

            }

        </div>

    )
}
export default TakDiundangPage;