import { useCallback, useContext, useEffect, useState } from "react";
import { useGlobalFunction } from "../../../../generalFunction";
import config from "../../../../config";
import api from "../../../../api";
import { pageObj } from "../../../../pageObj";
import { UserContext } from "../../../../context/UserContext";
import { ThemeContext } from "../../../../context/ThemeContext";

export function usePersiapanIdCard() {
    const {
        alertValue, setAlertValue, handleCloseAlert, showLoading, closeLoading, isLoading, setIsLoading, catchFetching,
        pageValue, setPageValue, modalValue, setModalValue, handleCloseModal
    } = useGlobalFunction();
    const [listData, setListData] = useState({
        data: [],
        isLoading: true,
    })
    const { setTitle } = useContext(ThemeContext);
    const [dokumen, setDokumen] = useState({
        tipe: '',
        url: '',
        width: ''
    });
    const myState = useContext(UserContext);
    const [modalState, modalDispatch] = myState.modalState
    const [dataSelected, setDataSelected] = useState({
        data: null,
        tipe: '',
        status: '',
        tipe_display: '',
        status_display: '',
        alasan: '',
    })

    //eslint-disable-next-line
    const [isShowAkta, setIsShowAkta] = useState(false)
    //eslint-disable-next-line
    const [isShowPernyataan, setIsShowPernyataan] = useState(false);
    //eslint-disable-next-line
    const [isShowKuasa, setIsShowKuasa] = useState(false);
    const [cropData, setCropData] = useState(null)

    // Callback untuk menangani hasil crop
    const handleCropComplete = (completedCrop) => {
        setCropData(completedCrop)
    }

    useEffect(() => {
        setTitle('Persiapan Id Card')
    }, [setTitle])


    const fetchData = useCallback(async (q = '', offset = 0, limit = pageValue.limit, status_compete = '', is_lock = '', show_akta = false, show_kuasa = false, show_pernyataan = false) => {
        console.log(offset)
        setIsLoading(true);
        showLoading();
        setListData(listData => ({ ...listData, isLoading: true }));
        try {
            let params = {
                offset: offset,
                limit: limit,
                tipe: 'crop_foto'
            }
            if (q) {
                params['q'] = q;
            }

            const res = await api.get(`${config.endPoint.getCropFoto}`, { params: params }).then(res => res.data);
            if (res) {
                let tmp = [];
                res.results.map(post => {
                    let obj = { ...post };
                    tmp.push(obj);
                    return true;
                })
                setListData(listData => ({
                    ...listData,
                    isLoading: false,
                    data: tmp
                }))
                setPageValue(pageValue => ({
                    ...pageValue,
                    obj: pageObj(res.count, limit, offset),
                    lastPage: Math.ceil(parseInt(res.count) / parseInt(limit))
                }));
                // setLockState(res.status_lock)
            } else {
                setListData(listData => ({ ...listData, isLoading: false }));
            }
            setIsLoading(false);
            closeLoading();
        } catch (error) {
            closeLoading();
            setListData(listData => ({ ...listData, isLoading: false }));
            setIsLoading(false);
            let msg = 'Unable to save Data';
            let { response } = error;

            catchFetching(error, 'admin')
            if (response && response.data && response.data.message) {
                msg = response.data.message
            }
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }));
            }, config.timeOutValue)
        }
        // eslint-disable-next-line 
    }, [setIsLoading, setAlertValue])

    const handlePaginate = (page) => {
        let myOffset = (parseInt(page) * parseInt(pageValue.limit)) - parseInt(pageValue.limit)
        setPageValue(pageValue => ({ ...pageValue, page: page, offset: myOffset }))
        fetchData(pageValue.search, myOffset, pageValue.limit, pageValue.complete, pageValue.is_lock, isShowAkta, isShowKuasa, isShowPernyataan);
        sessionStorage.setItem('statePage', page)
        // fetchData = useCallback(async(q='', offset=0, limit=pageValue.limit, status_compete='', is_lock='', show_akta=false, show_kuasa=false, show_pernyataan=false 
    }

    const handleChangeSearch = (e) => {
        const { name, value } = e.target;
        setPageValue(pageValue => ({ ...pageValue, [name]: value }))
        // console.log('pageValue >>>>>', pageValue.is_lock)
        if (name === 'complete') {
            sessionStorage.setItem('stateComplete', value)
            sessionStorage.setItem('statePage', 1)
            fetchData(pageValue.search, 0, pageValue.limit, value, pageValue.is_lock, isShowAkta, isShowKuasa, isShowPernyataan);
            setPageValue(pageValue => ({ ...pageValue, page: 1, offset: 0 }))
        }
        if (name === 'is_lock') {
            sessionStorage.setItem('stateLock', value)
            sessionStorage.setItem('statePage', 1)
            fetchData(pageValue.search, 0, pageValue.limit, pageValue.complete, value, isShowAkta, isShowKuasa, isShowPernyataan);
            setPageValue(pageValue => ({ ...pageValue, page: 1, offset: 0 }))
        }
        if (name === 'search') {
            sessionStorage.setItem('stateSearch', value)
        }
    }

    const handleSearch = () => {
        setPageValue(pageValue => ({ ...pageValue, offset: 0, page: 1 }));
        fetchData(pageValue.search, 0, pageValue.limit, pageValue.complete, pageValue.is_lock, isShowAkta, isShowKuasa, isShowPernyataan);
    }

    const handleKeyDownSearch = (e) => {
        if (e.key === "Enter") {
            handleSearch();
        }
    }

    const handleViewDocument = (tipe, params) => {
        let title = ""
        if (tipe === 'lihat_crop_foto') {
            setDokumen(dokumen => ({
                ...dokumen,
                tipe: 'lihat_crop_foto',
                url: params.crop_foto_url,
                width: 'md',
            }))
            setDataSelected(dataSelected => ({
                ...dataSelected,
                data: params,
                tipe: 'lihat_crop_foto',
                tipe_display: 'crop_foto',
                status: params.detail_display.status_foto,
                status_display: params.detail_display.status_foto_display,
            }))
            title = `Crop Foto ${params.nama}`
        } else if (tipe === 'foto') {
            setDokumen(dokumen => ({
                ...dokumen,
                tipe: 'foto',
                url: params.foto_url,
                width: 'md',
                alasan: [2, 3].includes(params.detail_display.status_foto) ? params.detail_display.alasan_foto : ''
            }))
            setDataSelected(dataSelected => ({
                ...dataSelected,
                data: params,
                tipe: 'foto',
                tipe_display: 'Foto',
                status: params.detail_display.status_foto,
                status_display: params.detail_display.status_foto_display,
            }))
            title = `Foto ${params.nama}`
        } else if (tipe === 'crop_foto') {
            setDokumen(dokumen => ({
                ...dokumen,
                tipe: 'crop_foto',
                url: params.foto_url,
                width: 'full'
            }))
            setDataSelected(dataSelected => ({
                ...dataSelected,
                data: params,
                tipe: 'crop_foto',
            }))
            title = `Crop Foto ${params.nama}`
            tipe = 'crop_foto'
        }
        setModalValue(modalValue => ({ ...modalValue, show: true, title: title, tipe: tipe }))
    }

    const handleApprove = async () => {
        setIsLoading(true);
        try {
            const crop_foto = cropData;
            const kontak_id = dataSelected.data.id


            let newForm = new FormData();
            newForm.append('kontak_id', kontak_id)
            newForm.append('crop_foto', crop_foto)

            const res = await api.post(`${config.endPoint.actionCropFoto}`, newForm).then(res => res.data);
            if (res) {
                fetchData(pageValue.search, pageValue.offset, pageValue.limit, pageValue.complete, pageValue.is_lock)
                handleCloseModal();
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: "Berhasil", subText: `Crop Telah Berhasil`, color: 'success' }));
                handleClearDataSelected();
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue)
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            let msg = 'Unable to save Data';
            let { response } = error;


            if (response && response.data && response.data.message) {
                msg = response.data.message
            }
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }));
            }, config.timeOutValue)
        }
    }

    const handleClearDataSelected = () => {
        setDataSelected(dataSelected => ({
            ...dataSelected,
            data: null,
            tipe: '',
            status: '',
            tipe_display: '',
            status_display: '',
            alasan: '',
        }))
    }

    useEffect(() => {
        sessionStorage.setItem('statePage', 1)
        fetchData(null, 0, pageValue.limit, null, null)
        //eslint-disable-next-line
    }, []);

    // Fungsi untuk mengubah limit
    const handleChangeLimit = (e) => {
        const newLimit = parseInt(e.target.value);
        setPageValue(pageValue => ({ ...pageValue, limit: newLimit, page: 1, offset: 0 }));
        fetchData(pageValue.search, 0, newLimit); // Muat ulang data dengan limit baru
    }

    return {
        alertValue, setAlertValue, handleCloseAlert, showLoading, closeLoading, isLoading, setIsLoading, catchFetching,
        pageValue, listData, handlePaginate, handleChangeSearch, handleKeyDownSearch, handleSearch, handleViewDocument, modalValue, handleCloseModal,
        dataSelected, setModalValue, dokumen, handleApprove, handleChangeLimit,
        modalState, modalDispatch, fetchData, cropData, setCropData, handleCropComplete
    }
}