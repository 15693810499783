import CryptoJS from "crypto-js";
import config from "../config";

export function encryptedResponse(plainText) {
  var key = CryptoJS.enc.Utf8.parse(config.ENCODE_KEY);
  var iv = CryptoJS.enc.Utf8.parse(config.ENCODE_SERIAL);
  var encrypted = CryptoJS.AES.encrypt(JSON.stringify(plainText), key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
  });
  var base64 = encrypted.toString();

  // Ubah Base64 ke Base64URL
  var base64url = base64
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");

  return base64url;
}

export function decryptedResponse(encrypted) {
  if (typeof encrypted !== "string") {
    console.warn("Invalid input type. Expected string.");
    return null; // Early return if input is not a string
  }

  try {
    var base64 =
      encrypted.replace(/-/g, "+").replace(/_/g, "/") +
      "==".substring(0, (3 * encrypted.length) % 4);

    var key = CryptoJS.enc.Utf8.parse(config.ENCODE_KEY);
    var iv = CryptoJS.enc.Utf8.parse(config.ENCODE_SERIAL);
    var decrypted;
    var decryptedBytes = CryptoJS.AES.decrypt(base64, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
    });
    var decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);
    decrypted = JSON.parse(decryptedText); //
  } catch (e) {
    // Handle error
    console.error("Error decrypting or parsing JSON:", e);
    return null;
  }
  return decrypted;
}
