import { Fragment } from "react"
import { useSettingLock } from "./lockSettingFunction"
import AlertComp from "../../../../Components/Atoms/AlertComp";
import FormTextSingleComp from "../../../../Components/forms/FormTextSingleComp";
import Card from "../../../../Components/Layout/Card";


const LockSettingPage = () => {
    const { 
        handleSubmit, formData, setFormData,
        alertValue, handleCloseAlert, 
        isLoading
    } = useSettingLock();

    console.log('lock all', formData.lock_all.value)
    return(
        <Fragment>
            <AlertComp
                isOpen={alertValue.show}
                onClose={handleCloseAlert}
                text={alertValue.text}
                subText={alertValue.subText}
                color={alertValue.color}
                icon={alertValue.icon}
            />
            <Card
                isSearch={false}
                isCreate={true}
                handleSubmit={handleSubmit}
                title={"Lock Settings"}                
            >
                <div className="grid grid-cols-2">
                { !isLoading &&
                    <div className="col-span-2 md:col-span-1">
                        <div className='mb-5'>
                            <FormTextSingleComp
                                {...formData.registrasi_disable}
                                formData={formData}
                                setFormData={setFormData}
                            />                             
                        </div>
                        <div className='mb-5'>
                            <FormTextSingleComp
                                {...formData.lock_all}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </div>
                        <div className='mb-5'>
                            <FormTextSingleComp
                                {...formData.open_until}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </div>
                    </div>
                }
                </div>
            </Card>
        </Fragment>
    )
}
export default LockSettingPage