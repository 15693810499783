import FormTextSingleComp from "../../../Components/forms/FormTextSingleComp";
import { TbFileTypeXls } from "react-icons/tb";
import Button from "../../../Components/Atoms/Button";
import { useExportDataExcel } from "./exportDataExcelFunction";
import AlertComp from "../../../Components/Atoms/AlertComp";

const ExportDataExcelPage = () => {
    const { isLoading, alertValue, formPassword, setFormPassword, handleSubmitPassword, view,
        handleKeyDown, handleDownloadAllFile, handleCloseAlert
    } = useExportDataExcel();


    return (
        <div className='mt-[80px] md:mt-[100px] px-[10px] md:px-[100px]'>
            <AlertComp
                isOpen={alertValue.show}
                onClose={handleCloseAlert}
                text={alertValue.text}
                subText={alertValue.subText}
                color={alertValue.color}
                icon={alertValue.icon}
            />
            {view === 'password' &&
                <div className='min-h-[calc(100vh-120px)] h-full flex flex-col justify-center items-center'>
                    <div className='w-[300px]'>
                        {alertValue.show &&
                            <div className='w-full px-5 py-2 bg-red-100 text-red-500 text-xs rounded-md text-center'>{alertValue.subText}</div>
                        }
                        <FormTextSingleComp
                            {...formPassword.password}
                            formData={formPassword}
                            setFormData={setFormPassword}
                            onKeyDown={handleKeyDown}

                        />
                        <button onClick={handleSubmitPassword} className='w-full bg-blue-800 text-white py-3 rounded-md'>Submit</button>
                    </div>
                </div>
            }
            {view === 'list' &&
                <div>
                    <div className="grid grid-cols-12">
                        <div className="col-span-full text-center">
                            <div className="mb-4">

                                Export Data Perusahaan yang Signup, Unregister, Reject, Approve
                            </div>
                            <div className="mb-4">
                                <Button disabled={isLoading} onClick={handleDownloadAllFile} color="primary" size="md">Download Export <TbFileTypeXls size={20} /></Button>
                            </div>

                        </div>
                    </div>

                </div>
            }

        </div>
    )
}
export default ExportDataExcelPage;