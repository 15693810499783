import { useCallback, useContext, useEffect, useState } from "react";
import { useGlobalFunction } from "../../../../generalFunction";
import api from "../../../../api";
import config from "../../../../config";
import { pageObj } from "../../../../pageObj";
import { UserContext } from "../../../../context/UserContext";
import { ThemeContext } from "../../../../context/ThemeContext";

export function usePanitia(){
    const { isLoading, setIsLoading, alertValue, setAlertValue, handleCloseAlert,
        modalValue, setModalValue, handleCloseModal, pageValue, setPageValue, closeLoading, showLoading,
        catchFetching
    } = useGlobalFunction();
    const [listData, setListData] = useState({isLoading : isLoading, data: []});
    const [view, setView] = useState('list');
    const [isEdit, setIsEdit] = useState(false);
    const { setTitle } = useContext(ThemeContext);
    const [formData, setFormData] = useState({
        id : {
            name : 'id',
            type: 'text',
            label : '',
            value : '',
            required : false,
            showError : false,
            show: true,
        },
        kategori_panitia : {
            name : 'kategori_panitia',
            type: 'text',
            label : 'Kategori Panitia',
            value : '',
            required : true,
            showError : false,
            show: false,
            readOnly : true,
        },
        kategori_panitia_display : {
            name : 'kategori_panitia_display',
            type: 'input_button',
            label : 'Kategori Panitia',
            value : '',
            required : true,
            showError : false,
            show: true,
            readOnly : true,
        },
        nama : {
            name : 'nama',
            type: 'text',
            label : 'Nama',
            value : '',
            required : true,
            showError : false,
            show: true,
        },
        foto_url : {
            name : 'foto_url',
            type: 'image',
            label : 'Foto',
            value : '',
            required : true,
            showError : false,
            show: true,
            readOnly: true,
        },
    })
    const myState = useContext(UserContext);
    const [modalState, modalDispatch] = myState.modalState;

    useEffect(() => {
        setTitle('Panitia')
    }, [setTitle])

    const fetchData = useCallback(async(q='', offset=0, limit=config.itemPerPage) => {
        showLoading()
        try{
            const params = {
                offset : offset,
                limit : limit,
                tipe : 'panitia'
            }
            if (q) {
                params['q'] = q
            }
            const res = await api.get(`${config.endPoint.kontak}`, {params: params}).then(res => res.data);
            if (res){
                setListData(listData => ({...listData, isLoading: false, data: res.results}))
                setPageValue(pageValue => ({...pageValue,
                    obj : pageObj(res.count, limit, offset),
                    lastPage : Math.ceil(parseInt(res.count) / parseInt(limit))
                }))
            }else{
                setListData(listData => ({...listData, isLoading: false}))
            }
            closeLoading()
        }catch(error){
            setListData(listData => ({...listData, isLoading: false}))
            closeLoading()
            const { response } = error;
            let msg = 'Unable to fetch data'
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            setAlertValue(alertValue => ({...alertValue,
                show: true,
                text : 'Error',
                subText: msg,
                color: 'danger'
            }))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
        // eslint-disable-next-line 
    },[])
    
    useEffect(() => {
        fetchData();
    },[fetchData])

    const handleCreate = () => {
        setView('create');
        setIsEdit(false);
        setFormData(formData => ({...formData,
            id : {...formData.id, value : ''},
            nama : {...formData.nama, value : ''},
            kategori_panitia : {...formData.kategori_panitia, value : ''},
            kategori_panitia_display : {...formData.kategori_panitia_display, value : ''},
            foto_url : {...formData.foto_url, value : ''},
        }))
    }

    const handleEdit = (post) => {
        console.log('post is >>>>', post);
        setView('create');
        setIsEdit('true');
        setFormData(formData => ({...formData,
            id : {...formData.id, value : post.id},
            nama : {...formData.nama, value : post.nama},  
            kategori_panitia : {...formData.kategori_panitia, value : post.kategori_panitia},          
            kategori_panitia_display : {...formData.kategori_panitia_display, value : post.kategori_display},
            foto_url : {...formData.foto_url, value : post.foto_url}
        }))
    }

    const handleChangeSearch = (e) => {
        const { name, value } = e.target;
        setPageValue(pageValue => ({...pageValue, [name] : value}))
    }
    
    const handleKeyDownSearch = (e) => {
        if (e.key === 'Enter'){
            handleSearch();
        }
    }
    
    const handleSearch = () => {
        fetchData(pageValue.search, 0, config.itemPerPage)
    }

    const handleCancel = () => {
        setView('list');
    }


    const handleSubmit = async() => {
        setIsLoading(true);
        try{
            let obj = {...formData}
            let countError = 0;
            let tmp = {}
            Object.entries(obj).map(([index, post]) => {
                if (post.required && post.value === ''){
                    post.showError= true;
                    countError++;
                }else{
                    tmp[index] = post.value
                }
                    return true;
            })
            
            if (countError > 0){
                setAlertValue(alertValue => ({...alertValue,
                    show: true,
                    text : 'Error',
                    subText: 'Lengkapi data',
                    color: 'danger'
                }))
                setIsLoading(false);
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue)
            }
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmp));
            let res;
            if (isEdit){
                res = await api.put(`${config.endPoint.panitia}${formData.id.value}/`, newForm).then(res => res.data);
            }else{
                res = await api.post(`${config.endPoint.panitia}`, newForm).then(res => res.data);
            }
            if (res){
                setAlertValue(alertValue => ({...alertValue,
                    show: true,
                    text : 'Success',
                    subText: 'Data berhasil disimpan',
                    color: 'success'
                }))
            
                fetchData(pageValue.search, pageValue.offset, config.itemPerPage)
            
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue)
            }
            setView('list');
            setIsLoading(false);
        }catch(error){            
            catchFetching(error, 'admin')
            setIsLoading(false);
            const { response } = error;
            let msg = 'Unable to save data'
            
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            
            setAlertValue(alertValue => ({...alertValue,
                show: true,
                text : 'Error',
                subText: msg,
                color: 'danger'
            }))
            
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }

    const handlePaginate = (page) => {
        let myOffset = (parseInt(page) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
        setPageValue(pageValue => ({...pageValue, page : page, offset: myOffset}))
        fetchData(pageValue.search, myOffset, config.itemPerPage);
    }

    const showPanitia = () => {
        modalDispatch({
            type: 'KATEGORI_PANITIA',
            clickTr: (post) => handleClickPanitia(post),
            onClose : closeModalHandler
        })
    }

    const closeModalHandler = () => {
        modalDispatch({type: 'CLOSE'})
    }

    const handleClickPanitia = (post) => {
        setFormData(formData => ({...formData,
            kategori_panitia : {...formData.kategori_panitia, value : post.id},
            kategori_panitia_display : {...formData.kategori_panitia_display, value : post.nama},
        }));
        closeModalHandler();
    }

    const handleDelete = (params) => {
        setModalValue(modalValue => ({...modalValue,
            show: true,
            text: `Apakah anda yakin akan menghapus Panitia ${params.nama} ?`,
            id : params.id,
            title : 'Konfirmasi',
        }))
    }
    
    const handleSubmitDelete = async() => {
        setIsLoading(true);
        try{
            const res = await api.delete(`${config.endPoint.panitia}${modalValue.id}/`).then(res => res.data);
            if (res){
                fetchData(pageValue.search, pageValue.offset, config.itemPerPage);
                setAlertValue(alertValue => ({...alertValue,
                    show: true,
                    text : 'Delete Success',
                    subText: 'Data berhasil di delete',
                    color: 'success'
                }))
    
                setTimeout(() => {
                    handleCloseAlert();
                }, config.timeOutValue)
            }
            handleCloseModal()
        }catch(error){
            setIsLoading(false);
            const { response } = error;
            let msg = 'Unable to delete data'
    
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            setAlertValue(alertValue => ({...alertValue,
                show: true,
                text : 'Error',
                subText: msg,
                color: 'danger'
            }))
    
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }

    return {
        isLoading, setIsLoading, alertValue, setAlertValue, handleCloseAlert, 
        modalValue, setModalValue, handleCloseModal, formData, setFormData,
        isEdit, setIsEdit, view, setView, listData, setListData,
        pageValue, setPageValue, closeLoading, showLoading, handleCreate,
        handleEdit, handleChangeSearch, handleKeyDownSearch, handleSearch,handleCancel,
        handleSubmit, handlePaginate, showPanitia, modalState, modalDispatch,
        handleDelete, handleSubmitDelete,
    }
}