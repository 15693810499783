import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalFunction } from "../../../../generalFunction";
import api from "../../../../api";
import config from "../../../../config";
import { ThemeContext } from "../../../../context/ThemeContext";


export function useRegistrasiEdit(){
    const { showLoading, closeLoading, alertValue, setAlertValue, handleCloseAlert, catchFetching} = useGlobalFunction();
    const { setTitle } = useContext(ThemeContext)
    const pesertaId = sessionStorage.getItem('pesertaId') || 0;
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        id : {
            name : 'id',
            label : 'ID',
            value : '',
            type : 'text',
            col : 2,
            required : true,
            show : false,
            errorMsg : '',
            showError : false,                    
        },
        nama : {
            name : 'nama',
            label : 'Nama',
            value : '',
            type : 'text',
            col : 2,
            required : true,
            show : true,
            readOnly : true,
            errorMsg : '',
            showError : false,                    
        },
        perusahaan_display : {
            name : 'perusahaan_display',
            label : 'Perusahaan',
            value : '',
            type : 'text',
            col : 2,
            required : true,
            show : true,
            readOnly : true,
            errorMsg : '',
            showError : false,                    
        },
        jabatan : {
            name : 'jabatan',
            label : 'Jabatan',
            value : '',
            type : 'text',
            col : 2,
            required : false,
            show : true,
            readOnly : true,
            errorMsg : '',
            showError : false,                    
        },
        jabatan_display : {
            name : 'jabatan_display',
            label : 'Jabatan',
            value : '',
            type : 'text',
            col : 2,
            required : true,
            show : true,
            readOnly : true,
            errorMsg : '',
            showError : false,                    
        },
        foto : {
            name : 'foto',
            label : 'Foto',
            value : '',
            type : 'image',
            col : 2,
            required : false,
            show : true,
            errorMsg : '',
            showError : false,    
            file : null,                
        },
        akta : {
            name : 'akta',
            label : 'Akta',
            value : '',
            type : 'dokumen',
            col : 2,
            required : false,
            show : true,
            // readOnly : true,
            errorMsg : '',
            showError : false,            
            file : null,
            file_label : '',
        },
        surat_pernyataan : {
            name : 'surat_pernyataan',
            label : 'Surat Pernyataan',
            value : '',
            type : 'dokumen',
            col : 2,
            required : false,
            show : true,
            // readOnly : true,
            errorMsg : '',
            showError : false,            
            file : null,
            file_label : '',
        },
        surat_kuasa : {
            name : 'surat_kuasa',
            label : 'Surat Kuasa',
            value : '',
            type : 'dokumen',
            col : 2,
            required : false,
            show : true,
            // readOnly : true,
            errorMsg : '',
            showError : false,            
            file : null,
            file_label : '',
        },
    })
    const fetchDetail = useCallback(async(id=0) => {
        showLoading();
        try{
            const res = await api.get(`${config.endPoint.dataRegistrasi}/${id}/`).then(res => res.data);            
            if (res){
                let post = res.results;
                setFormData(formData => ({...formData,
                    id : {...formData.id, value : post.id},
                    nama : {...formData.nama, value : post.nama},
                    akta : {...formData.akta, value : post.akta_url},
                    perusahaan_display : {...formData.perusahaan_display, value : post.perusahaan_display},
                    jabatan_display : {...formData.jabatan_display, value : post.jabatan_display},
                    jabatan : {...formData.jabatan, value : post.jabatan},
                    foto : {...formData.foto, value : post.foto_url},
                    surat_pernyataan : {...formData.surat_pernyataan, value : post.jabatan === 1 ? post.detail_display.surat_pernyataan : null},
                    surat_kuasa : {...formData.surat_kuasa, value : post.jabatan === 2 ?  post.detail_display.surat_kuasa : null},
                }))
            }
            closeLoading();
        }catch(error){
            closeLoading();            
            let msg = 'Unable to save Data';
            let { response } = error;            
            
            catchFetching(error, 'admin')            
            if (response && response.data && response.data.message){
                msg = response.data.message
            }
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }));
            }, config.timeOutValue)  
        }
        // eslint-disable-next-line
    },[setAlertValue])

    useEffect(() => {
        setTitle('Edit Data Registrasi');
    },[setTitle])

    useEffect(() => {
        if (pesertaId){
            fetchDetail(pesertaId);
        }
    },[fetchDetail, pesertaId])

    const handleSubmit = async() => {
        showLoading();
        try{            
            
            let countError = 0;
            let obj  ={...formData}
            let tmp = {};
            Object.entries(obj).map(([index, post]) => {
                if (post.required && [null, ''].includes(post.value)){
                    post.showError = true;
                    countError++;
                      
                }else{
                    post.showError = false;
                    if (['file', 'image', 'dokumen'].includes(post.type)){
                        tmp[index] = post.file
                    }else{
                        tmp[index] = post.value ? post.value : '';
                    }
                }
                return true;
            })
            if (countError > 0){
                setFormData(obj);
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: "Lengkapi data", color: 'danger' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue)
                closeLoading();
                return
            }       

            let newForm = new FormData();            
            newForm.append('formData', JSON.stringify(tmp))       
            
                                      
            newForm.append(`surat_pernyataan`, tmp.surat_pernyataan)
            newForm.append(`surat_kuasa`, tmp.surat_kuasa)
            newForm.append(`foto`, tmp.foto)                                
            newForm.append(`akta`, tmp.akta)                                
            
            
            const res = await api.put(`${config.endPoint.dataRegistrasi}/${formData.id.value}/`, newForm).then(res => res.data);
            if (res){
                
                handleCancel();
            }
            closeLoading();
        }catch(error){
            closeLoading();
            
            let msg = 'Unable to save Data';
            let { response } = error;            
            
            catchFetching(error, 'member')
            if (response && response.data && response.data.message){
                msg = response.data.message
            }
            
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }));
            }, config.timeOutValue)
            
        }
    }

    const handleCancel = () => {
        navigate('/admin/registrasi/data-registrasi-peserta/')
    }

    return {
        alertValue, setAlertValue,  handleCloseAlert, formData, setFormData, handleSubmit, handleCancel
    }
}