import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "../views/Home/HomePage";

// import { menu } from "../constants/menu";
import LayoutAdmin from "../Components/Layout/LayoutAdmin";
// import Layout from "../Components/Layout/Layout";
import HomeAdmin from "../views/Admin/HomeAdmin/HomeAdmin";
import LoginPage from "../views/Auth/LoginPage";
import ShowDokumenPage from "../views/Admin/Registrasi/ShowDocument/ShowDocumentPage";
import DataRegistrasiDetailPage from "../views/Admin/Registrasi/DataRegistrasiDetail/DataRegistrasiDetailPage";
import DataRegistrasiEditPage from "../views/Admin/Registrasi/DataRegistrasiEdit/DataRegistrasiEditPage";
import LoginMemberPage from "../views/Member/Auth/Login/LoginMemberPage";
import Layout from "../Components/Layout/Layout";
import HomeNew from "../views/HomeNew/Home/HomeNew";
import SyaratKetentuanPage from "../views/HomeNew/SyaratKetentuan/SyaratKetentuanPage";
import DownloadPage from "../views/HomeNew/DownloadPage/DownloadPage";
import AgendaPage from "../views/HomeNew/AgendaPage/AgendaPage";
import PesertaPage from "../views/HomeNew/Peserta/PesertaPage";
import LokasiPage from "../views/HomeNew/LokasiPage/LokasiPage";
import DashboardMember from "../views/Member/Dashboard/DashboardMember";
import LayoutMember from "../Components/Layout/LayoutMember";
import DownloadMember from "../views/Member/Download/DownloadMember";
import EditDataPesertaPage from "../views/Member/EditDataPeserta/EditDataPesertaPage";
import ChangePasswordPage from "../views/Member/ChangePassword/ChangePasswordPage";
import LayoutMemberLogin from "../Components/Layout/LayoutMemberLogin";
import RegisterPage from "../views/Member/Auth/Register/RegisterPage";
import VerificationEmail from "../views/Member/Auth/Verification/VerificationEmail";
import ForgotPasswordPage from "../views/Member/Auth/ForgotPassword/ForgotPasswordPage";
import ResetPasswordPage from "../views/Member/Auth/ResetPassword/ResetPasswordPage";
import PrivateRoute from "../Components/Layout/PrivateRoute";
import { useMenu } from "../constants/menuFunction";
import NotFoundPage from "../Components/Layout/NotFound";
import UncompleteSetting from "../views/Admin/Master/UncompleteSetting/UncompleteSetting";
import MemberCalonPage from "../views/Member/Calon/MemberCalonPage";
import PerusahaanMendaftarPage from "../views/Admin/Preview/PerusahaanMendaftar/PerusahaanMendaftarPage";
import PesertaLengkapPage from "../views/Admin/Preview/PesertaLengkap/PesertaLengkapPage";
import TakDiundangPage from "../views/Preview/TakDiundangPage/TakDiundangPage";
import LivePendaftaranPage from "../views/Preview/LivePendaftaran/LivePendaftaranPage";
import ExportDataExcelPage from "../views/Preview/ExportDataExcel/ExportDataExcelPage";
import TataTertibPage from "../views/HomeNew/TataTertib/TataTertibPage";

const Router = () => {
  const { myMenu, isSuperUser } = useMenu();
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route
            path="/jelangkung/exportdata"
            element={<ExportDataExcelPage />}
          />
          <Route
            path="/jelangkung/takdiundang"
            element={<TakDiundangPage tipe="takdiundang" />}
          />
          <Route
            path="/jelangkung/all"
            element={<TakDiundangPage tipe="all" />}
          />
          <Route
            path="/live-preview"
            element={<LivePendaftaranPage />}
          />
          <Route path="/" element={<HomeNew />} />
          <Route
            path="/syarat-dan-ketentuan"
            element={<SyaratKetentuanPage />}
          />
          <Route
            path="/tata-tertib"
            element={<TataTertibPage />}
          />
          <Route path="/download-area" element={<DownloadPage />} />
          <Route path="/agenda" element={<AgendaPage />} />
          <Route path="/peserta" element={<PesertaPage />} />
          <Route path="/lokasi" element={<LokasiPage />} />
          <Route path="/member" exact element={<LayoutMember />}>
            <Route path="/member" element={<DashboardMember />} />
            <Route path="/member/download" element={<DownloadMember />} />
            <Route path="/member/peserta" element={<EditDataPesertaPage />} />
            <Route path="/member/calon-dewas" element={<MemberCalonPage fetchTipe={'dewas'} />} />
            <Route path="/member/calon-ketum" element={<MemberCalonPage fetchTipe={'ketum'} />} />
            <Route
              path="/member/change-password"
              element={<ChangePasswordPage />}
            />
          </Route>
        </Route>
        <Route path="/" element={<LayoutMemberLogin />}>
          <Route path="/signup" element={<RegisterPage />} />
          <Route path="/login" element={<LoginMemberPage />} />
          <Route path="/member/login" element={<LoginMemberPage />} />
          <Route
            path="/member/email-verification/"
            element={<VerificationEmail />}
          />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route
            path="/member/reset-password"
            element={<ResetPasswordPage />}
          />
        </Route>
        <Route path="/homeold" element={<HomePage />}></Route>
        <Route path="/admin/login" element={<LoginPage />} />


        <Route path="*" element={<NotFoundPage />} />
        <Route path="/not-found" element={<NotFoundPage />} />
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/admin" element={<LayoutAdmin />}>
            <Route path="/admin" element={<HomeAdmin />} />
            <Route
              path="/admin/uncomplete-settings"
              element={<UncompleteSetting />}
            />
            <Route
              path="/admin/registrasi/showdocument"
              element={<ShowDokumenPage />}
            />
            <Route
              path="/admin/registrasi/data-registrasi-peserta/detail"
              element={<DataRegistrasiDetailPage />}
            />
            <Route
              path="/admin/registrasi/data-registrasi-peserta/edit"
              element={<DataRegistrasiEditPage />}
            />
            {isSuperUser &&
              <Route
                path="/admin/wani/perusahaan-mendaftar"
                element={<PerusahaanMendaftarPage />}
              />
            }
            {isSuperUser &&
              <Route
                path="/admin/wani/peserta-complete"
                element={<PesertaLengkapPage />}
              />
            }
            {myMenu.map((item, index) => {
              if (item.sub?.length > 0) {
                return item.sub.map((sub, index) => {
                  return (
                    <Route key={index} path={sub.path} element={sub.element} />
                  );
                });
              } else {
                return (
                  <Route key={index} path={item.path} element={item.element} />
                );
              }
            })}
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
export default Router;
