import { useCallback, useContext, useEffect, useState } from "react";


import Moment from 'moment';
import { useGlobalFunction } from "../../../../generalFunction";
import api from "../../../../api";
import config from "../../../../config";
import { ThemeContext } from "../../../../context/ThemeContext";

export function useSettingLock(props){
    const { 
        alertValue, setAlertValue,  handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal, 
        isLoading, setIsLoading, catchFetching,  showLoading, closeLoading
    } = useGlobalFunction();
    const [isEdit, setIsEdit] = useState(false);
    const [formData, setFormData] = useState({        
        id : {
            name : 'id',
            label : 'ID',
            value : false,
            type : 'text',
            col : 2,
            required : false,
            show : false,
            errorMsg : '',
            showError : false,
            flexType : 'row',
            readOnly : true,
        },
        registrasi_disable : {
            name : 'registrasi_disable',
            label : 'Disable Registrasi',
            value : false,
            type : 'switch',
            col : 2,
            required : false,
            show : true,
            errorMsg : '',
            showError : false,
            flexType : 'row'
        },
        lock_all : {
            name : 'lock_all',
            label : 'Kunci Semua',
            value : false,
            type : 'switch',
            col : 2,
            required : false,
            show : true,
            errorMsg : '',
            showError : false,
            flexType : 'row',
            className : 'text-right'
        },
        open_until : {
            name : 'open_until',
            label : 'Buka Kunci s.d',
            value : '',
            type : 'date',
            col : 2,
            required : false,
            show : true,
            errorMsg : '',
            showError : false,
            flexType : 'row',
            className : 'text-right',
            dateFormat : 'dd-MM-yyyy HH:mm'
        },
    });

    const { setTitle } = useContext(ThemeContext);
    const fetchData = useCallback(async() => {
        setIsLoading(true);
        showLoading();
        try{
            const res = await api.get(`${config.endPoint.settingDisable}`).then(res => res.data);
            if (res){
                if (res.results && res.results.length > 0 ){
                    let results = res.results[0]
                    // console.log('results is >>>>', results);
                    setFormData(formData => ({...formData, 
                        id : {...formData.id, value : results.id},
                        lock_all : {...formData.lock_all, value : results.lock_all},
                        registrasi_disable : {...formData.registrasi_disable, value : results.registrasi_disable},
                        open_until : {...formData.open_until,
                            value : results.open_until_display ? Moment(results.open_until_display, 'DD-MM-YYYY HH:mm').toDate() : null
                        }
                    }))
                    setIsEdit(true);
                }
            }
            closeLoading();
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            closeLoading();
            catchFetching(error, 'admin');
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to fetch data', color : 'danger'}))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)          
        }
        // eslint-disable-next-line
    },[setIsLoading])

    useEffect(() => {
        setTitle('Lock Settings')
    }, [setTitle])

    // console.log('formData is >>>', formData);

    useEffect(() => {
        fetchData();
    },[fetchData])


    const handleChange = (e) => {        
        const { name, value } = e.target;
        let nilai = value;                
        
        if (formData[name].type === 'switch') {
            setFormData(formData => ({
                ...formData,
                [name]: { ...formData[name], value: e.target.checked }
            }))
        } else {
            setFormData(formData => ({
                ...formData,
                [name]: { ...formData[name], value: nilai }
            }))
        }
       
    }

    const handleSubmit = async() => {
        setIsLoading(true);
        showLoading();
        try{
            let countError = 0;
            let form = {...formData};
            let tmpForm = {}
            Object.entries(form).map(([index, post]) => {                
                if (post.required && (post.value === '' || post.value === null)){
                    post.showError = true;
                    countError++;
                }else{
                    tmpForm[index] = post.value;
                }                
                return true;
            });
            
            if (countError > 0){
                setFormData(form);
                setIsLoading(false);
                setAlertValue(alertValue => ({...alertValue, show: true, text : 'Lengkapi Data', color: 'danger'}));
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue);
                return;
            }
            setIsLoading(false);

            console.log('moment is >>>>', tmpForm.open_until)

            if (tmpForm['open_until'] !== ''){
                tmpForm['open_until'] = Moment(tmpForm['open_until']).format('YYYY-MM-DD HH:mm')
            }
            // Moment(tmpForm['tanggal_mulai']).format('YYYY-MM-DD HH:mm')
            console.log('moment is >>>>', tmpForm.open_until)
            
            
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmpForm));
            
            let res;
            if (isEdit){
                res = await api.put(`${config.endPoint.settingDisable}${formData.id.value}/`, newForm).then(res => res.data);
            }else{
                res = await api.post(`${config.endPoint.settingDisable}`, newForm).then(res => res.data);
            }

            if (res){
                setAlertValue(alertValue => ({
                    ...alertValue,
                    text: 'Data tersimpan',
                    color: 'success',
                    show: true
                }))
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }))
                }, config.timeOutValue)       
            }
           
            
            closeLoading();
            setIsLoading(false);
        }catch(error){
            closeLoading();
            setIsLoading(false);
            catchFetching(error, 'admin')

            let msg = "Unable to save data";
            if (error.response && error.response.data && error.response.data.message){
                msg = error.response.data.message
            }
            setAlertValue(alertValue => ({...alertValue, show: true, text : msg, color : 'danger'}))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))                
            }, config.timeOutValue);      
        }
    }

    return {
        alertValue, setAlertValue,  handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal, 
        isLoading, setIsLoading, catchFetching, 
        formData, setFormData, handleChange, handleSubmit
    }
}