import { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import Nav from "../Atoms/Nav";
import { Icon } from "@iconify/react/dist/iconify.js";

const Header = () => {
  const { isScroll, setShowMobileMenu, showMobileMenu } = useContext(
    ThemeContext
  );
  return (
    <>
      <header
        className={`${isScroll ? "bg-primaryweb" : "bg-transparent"
          }  w-full  px-[20px] md:px-[120px]  h-[50px] md:h-[60px] fixed z-50 flex flex-row items-center justify-between`}
      >
        <div className="w-fit h-fit  flex flex-row gap-1">
          <img
            src={require("../../assets/images/logoapjii.png")}
            alt="logo"
            className="object-contain h-10"
          />
          <div className="text-white border-l-2">&nbsp;</div>
          <img
            src={require("../../assets/images/logo_munas_putih.png")}
            alt="logo"
            className="object-contain h-10"
          />
        </div>
        <div className="visible md:invisible text-white">
          <button
            // data-drawer-target="logo-sidebar"
            // data-drawer-toggle="logo-sidebar"
            // aria-controls="logo-sidebar"
            type="button"
            className="text-white p-2"
            onClick={() => setShowMobileMenu(!showMobileMenu)}
          // id="button_aside"
          >
            <span class="sr-only">Open sidebar</span>
            <Icon icon="ic:round-menu" fontSize={24} />
          </button>

          {/* <aside 
                        // className={`fixed top-0 
                        //     ${showMobileMenu ? 'right-0' : 'right-64'} w-64 h-screen bg-green-200 transition-transform -translate-x-full sm:translate-x-64
                        //     delay-150 duration-1000
                        // `}
                        className={`
                            grid fixed top-0 bg-[#F6F9FE] 
                            w-[233px] 
                            h-full -left-[233px] peer-focus:left-0 ease-out delay-150 duration-300 rounded-r-[25px] rounded-bl-[25px]
                        `}
                        aria-label="sidebar-right"
                        id="aside"
                    >
                        
                    </aside> */}
        </div>
        <div className="hidden md:block  w-0 h-0 md:w-fit md:h-fit ">
          <Nav />
        </div>
      </header>
    </>
  );
};
export default Header;
