import { Fragment } from "react"
import Card from "../../../../Components/Layout/Card";
import { useKandidatKetum } from "./kandidatKetumFunction";
import Tables from "../../../../Components/molecules/Tables";
import FormTextSingleComp from "../../../../Components/forms/FormTextSingleComp";
import { Icon } from "@iconify/react/dist/iconify.js";
// import Tooltip from "../../../../Components/Atoms/Tooltip";
import PagePagination from "../../../../Components/Atoms/PagePagination";
import Modal from "../../../../Components/Atoms/Modal";
import Button from "../../../../Components/Atoms/Button";
import AlertComp from "../../../../Components/Atoms/AlertComp";



const KandidatKetumPage = (props) => {
    const { view, pageValue, listData, handleCreate,
        handleSubmit, handleCancel, formData, setFormData,
        handleAddMisi, handleDeleteVisiMisi, handleAddVisi, listVisi, listMisi,
        handleChangeVisi, handleChangeMisi, alertValue, handleCloseAlert,
        handlePaginate, modalValue, setModalValue, handleSubmitDelete, handleCloseModal,
        handleChangeSearch, handleSearch
    } = useKandidatKetum()




    return (
        <Fragment>
            <AlertComp
                isOpen={alertValue.show}
                onClose={handleCloseAlert}
                text={alertValue.text}
                subText={alertValue.subText}
                color={alertValue.color}
                icon={alertValue.icon}
            />
            <Modal
                show={modalValue.show}
                setShow={() => {
                    setModalValue(modalValue => ({ ...modalValue, show: false }))
                }}
                width='xl'
                persistent={false}
                btnClose={false}
            >
                <div className='py-[20px] px-[30px] border-b border-b-gray-200 font-bold'>
                    {modalValue.title}
                </div>
                <div className='py-[50px] px-[30px]'>
                    {modalValue.text}
                </div>
                <div className='py-[20px] px-[30px] border-t border-t-gray-200 flex flex-row justify-end gap-2'>
                    <Button color='danger' size='md' onClick={handleSubmitDelete}>Hapus</Button>
                    <Button color='base' size='md' onClick={handleCloseModal}>Cancel</Button>
                </div>
            </Modal>
            <Card
                title="Master Kandidat Ketua Umum"
                isCreate={view === 'create'}
                isSearch={view === 'list'}
                searchValue={pageValue.search}
                handleCreate={handleCreate}
                handleSubmit={handleSubmit}
                handleCancel={handleCancel}
                onChangeSearch={handleChangeSearch}
                handleSearch={handleSearch}
                btnCreateText={"Fetching"}
                disableIconButtonCreate={true}
            >
                {view === 'create' &&
                    <Fragment>
                        <div className="grid grid-cols-2 gap-5">
                            <div className="col-span-2 md:col-span-1">
                                <FormTextSingleComp
                                    {...formData.foto}
                                    formData={formData}
                                    setFormData={setFormData}
                                />
                                <FormTextSingleComp
                                    {...formData.nama}
                                    formData={formData}
                                    setFormData={setFormData}
                                />
                                <div className='mt-5'>
                                    <FormTextSingleComp
                                        {...formData.perusahaan}
                                        formData={formData}
                                        setFormData={setFormData}
                                    />
                                </div>
                            </div>
                            <div className='col-span-2 md:col-span-1'>
                                <div className="flex flex-row gap-2 items-center">
                                    <button color="primary" className='px-2 py-1 bg-primary text-white rounded shadow-lg' onClick={handleAddVisi}><Icon icon="ic:round-plus" /></button>
                                    <div className='text-[16px] font-bold'>Visi</div>
                                </div>
                                <div className='mt-3'>
                                    {listVisi.map((post, index) => {
                                        return (
                                            <div className='flex flex-row items-center gap-2'>
                                                <div className='w-full'>
                                                    <FormTextSingleComp
                                                        {...post}
                                                        onChange={(e) => handleChangeVisi(e, index)}
                                                        hideLabel={true}
                                                    />
                                                </div>
                                                <button className='bg-red-600 text-white px-2 py-3 rounded' onClick={() => handleDeleteVisiMisi('visi', index)}><Icon icon="ic:round-delete" /></button>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="flex flex-row gap-2 items-center mt-5 border-t border-t-gray-300 pt-[10px]">
                                    <button color="primary" className='px-2 py-1 bg-primary text-white rounded shadow-lg' onClick={handleAddMisi}><Icon icon="ic:round-plus" /></button>
                                    <div className='text-[16px] font-bold'>Misi</div>
                                </div>
                                <div className='mt-3'>
                                    {listMisi.map((post, index) => {
                                        return (
                                            <div className='flex flex-row items-center gap-2'>
                                                <div className='w-full'>
                                                    <FormTextSingleComp
                                                        {...post}
                                                        onChange={(e) => handleChangeMisi(e, index)}
                                                        hideLabel={true}
                                                    />
                                                </div>
                                                <button className='bg-red-600 text-white px-2 py-3 rounded' onClick={() => handleDeleteVisiMisi('misi', index)}><Icon icon="ic:round-delete" /></button>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </Fragment>
                }
                {view === 'list' &&
                    <Fragment>

                        <Tables className='border-separate rounded-sm'>
                            <Tables.Head className='bg-[#efeeee]'>
                                <tr>
                                    <Tables.Header className='font-bold text-[13px] py-[10px] text-right'>No Urut</Tables.Header>
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>Nama</Tables.Header>
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>Perusahaan</Tables.Header>
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>Visi</Tables.Header>
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>Misi</Tables.Header>
                                </tr>
                            </Tables.Head>
                            <Tables.Body>
                                {listData?.data?.length === 0 ?
                                    <Tables.Row>
                                        <Tables.Data colspan={4} className='text-center'>Belum ada data</Tables.Data>
                                    </Tables.Row>
                                    :
                                    <Fragment>
                                        {listData?.data?.map((post, index) => {
                                            return (
                                                <Tables.Row key={index}>
                                                    <Tables.Data className='text-right align-top'>
                                                        {/* {pageValue.offset + index + 1} */}
                                                        {post.no_urut}
                                                    </Tables.Data>
                                                    <Tables.Data className='align-top'>
                                                        <div className='flex flex-col w-full'>
                                                            <img src={post.foto} alt="foto" className='h-[80px] rounded-md object-contain' />
                                                            <div className='text-center'>{post.nama}</div>
                                                        </div>
                                                    </Tables.Data>
                                                    <Tables.Data className='align-top'>
                                                        {post.perusahaan}
                                                    </Tables.Data>
                                                    <Tables.Data className='align-top'>
                                                        {post.visi}
                                                    </Tables.Data>
                                                    <Tables.Data className='align-top'>
                                                        {post.misi}
                                                    </Tables.Data>
                                                    {/* <Tables.Data className='align-top'>
                                                        <div className='flex flex-row gap-1'>
                                                            <Tooltip
                                                                tooltip={'Edit'}
                                                            >
                                                                <button onClick={() => handleEdit(post)} className='p-1 bg-orange-500 text-white rounded'><Icon icon='line-md:edit' /></button>
                                                            </Tooltip>
                                                            <Tooltip
                                                                tooltip={'Hapus'}
                                                            >
                                                                <button onClick={() => handleDelete(post)} className='p-1 bg-red-500 text-white rounded'><Icon icon='fluent-mdl2:delete'  /></button>
                                                            </Tooltip>
                                                        </div>                                                    
                                                    </Tables.Data> */}
                                                </Tables.Row>
                                            )
                                        })}
                                    </Fragment>
                                }
                            </Tables.Body>
                        </Tables>
                        <div className='mt-5'>
                            <PagePagination
                                data={pageValue.obj}
                                handlePagination={handlePaginate}
                                page={pageValue.page}
                                lastPage={pageValue.lastPage}
                            />
                        </div>
                    </Fragment>
                }
            </Card>
        </Fragment>
    )
}
export default KandidatKetumPage;