import { Fragment } from "react"
import { useFileUpload } from "./fileUploadFunction";
import Card from "../../../../Components/Layout/Card";
import Tables from "../../../../Components/molecules/Tables";
import { Icon } from "@iconify/react/dist/iconify.js";
import Modal from "../../../../Components/Atoms/Modal";
import Button from "../../../../Components/Atoms/Button";
import FormTextSingleComp from "../../../../Components/forms/FormTextSingleComp";
import AlertComp from "../../../../Components/Atoms/AlertComp";
import PagePagination from "../../../../Components/Atoms/PagePagination";
import Tooltip from "../../../../Components/Atoms/Tooltip";



const FileUploadPage = () => {
    const { listData, pageValue,
        handleDelete, handleSubmit, modalValue, 
        setModalValue, handleSubmitDelete, handleCloseModal,
        handleCancel, handleCreate, view, formData, setFormData,
        alertValue, handleCloseAlert, handleSearch, handlePaginate,
        availableForm, setAvailableForm, handleEdit, mimeDisplay,
        handleChangeSearch
    } = useFileUpload();
    return(
        <Fragment>
            <AlertComp
                isOpen={alertValue.show}
                onClose={handleCloseAlert}
                text={alertValue.text}
                subText={alertValue.subText}
            />
            <Modal
                    show={modalValue.show}
                    setShow={() => {                        
                        setModalValue(modalValue => ({...modalValue, show: false}))
                    }}
                    width="xl"
                    persistent={false}   
                    btnClose={false}   
                    title={modalValue.title}
                    withHeader={true}
                >

                    <div className='p-[20px]'>
                        { modalValue.text}
                    </div>
                    <div className='border-t border-t-gray-200 p-[20px]'>
                        <div className="flex flex-row justify-end gap-2">
                            <Button className='bg-red-500 text-white' onClick={handleSubmitDelete}>Hapus</Button>
                            <Button className='bg-gray-400 text-white' onClick={handleCloseModal}>Cancel</Button>
                        </div>
                    </div>
            </Modal>
            <Card
                title="File Upload"
                isCreate={view === 'create'}
                isSearch={true}
                handleCreate={handleCreate}
                searchValue={pageValue.search}
                handleSubmit={handleSubmit}
                handleCancel={handleCancel}
                handleSearch={handleSearch}
                onChangeSearch={handleChangeSearch}
            >
                { view === 'create' &&
                    <div className='grid grid-cols-2'>
                        <div className="col-span-2">
                            <FormTextSingleComp
                                {...formData.nama}
                                formData={formData}
                                setFormData={setFormData}
                            />
                            <div className="mt-5">
                                <FormTextSingleComp
                                    {...formData.path}
                                    formData={formData}
                                    setFormData={setFormData}
                                    accept_file=".docx, .pdf"
                                />
                                { formData.path.value !== '' &&                                    
                                    <>
                                        { mimeDisplay === "Image/png" &&
                                            <img src={formData.path.value} alt="preview" />                                        
                                        }
                                        { mimeDisplay === 'Document/pdf' &&
                                            <div className='grid grid-cols-12'>
                                                <div className='col-span-4'>

                                                </div>
                                                <div className='col-span-8'>
                                                    <iframe 
                                                        src={formData.path.value}
                                                        width="100%"
                                                        height="400px"
                                                        title="akta"
                                                    ></iframe>
                                                </div>
                                            </div>
                                        }
                                    </>
                                }
                            </div>
                            <div className='font-bold mt-7'>Tampilkan pada halaman</div>
                            <div className="mt-5 flex flex-col">
                                <FormTextSingleComp
                                    {...availableForm.web_page}
                                    formData={availableForm}
                                    setFormData={setAvailableForm}
                                />
                                <FormTextSingleComp
                                    {...availableForm.member_page}
                                    formData={availableForm}
                                    setFormData={setAvailableForm}
                                />
                                { availableForm.member_page.value === true &&
                                    <div>
                                        <FormTextSingleComp
                                            {...formData.type_file}
                                            formData={formData}
                                            setFormData={setFormData}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }
                { view === 'list' &&
                    <Fragment>
                        <Tables className='border-separate rounded-sm'>
                            <Tables.Head className='bg-[#efeeee]'>
                                <tr>
                                    <Tables.Header className='font-bold text-[13px] py-[10px] text-right'>#</Tables.Header>                            
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>Nama</Tables.Header>            
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>Path</Tables.Header>
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>File Type</Tables.Header>
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>Sebagai Dokumen</Tables.Header>
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>Tersedia Pada Halaman</Tables.Header>
                                    <Tables.Header className='font-bold text-[13px] py-[10px] text-center'>Action</Tables.Header>
                                </tr>
                            </Tables.Head>
                            <Tables.Body>
                                { listData.length === 0 ?
                                    <Tables.Row>
                                        <Tables.Data colspan={4} className='text-center'>Belum ada data</Tables.Data>
                                    </Tables.Row>
                                    :
                                    <Fragment>
                                        {listData.map((post, index) => {
                                            return (
                                                <Tables.Row key={index}>
                                                    <Tables.Data className='text-right'>{pageValue.offset + index + 1}</Tables.Data>
                                                    <Tables.Data>{post.nama}</Tables.Data>
                                                    <Tables.Data>{post.path}</Tables.Data>
                                                    <Tables.Data>{post.mime_display}</Tables.Data>
                                                    <Tables.Data>{post.type_file_display}</Tables.Data>
                                                    <Tables.Data>
                                                        { post.list_available.map((post, index) => {
                                                            return(
                                                                <div className='' key={index}>{post.page_display}</div>
                                                            )
                                                        })}
                                                    </Tables.Data>

                                                    <Tables.Data className='text-center'>
                                                        <div className="flex flex-row justify-center gap-1">
                                                            <Tooltip
                                                                tooltip={"Hapus"}
                                                            >
                                                                <button onClick={() => handleDelete(post)} className='h-[25px] px-2 py-1 bg-red-500 text-white rounded'>
                                                                    <Icon icon="ph:trash-fill" />
                                                                </button>
                                                            </Tooltip>
                                                            <Tooltip
                                                                tooltip={"Edit"}
                                                            >
                                                                <button onClick={() => handleEdit(post)} className='px-2 py-1 h-[25px] bg-orange-500 text-white rounded'>                                                                
                                                                    <Icon icon="mi:edit-alt" />
                                                                </button>
                                                            </Tooltip>
                                                        </div>
                                                    </Tables.Data>
                                                </Tables.Row>
                                            )
                                        })}
                                    </Fragment>
                                }
                            </Tables.Body>
                        </Tables>
                        <div className='mt-5 flex flex-row justify-start w-full'>
                            <PagePagination
                                data={pageValue.obj}
                                handlePagination={handlePaginate}
                                page={pageValue.page}
                                lastPage={pageValue.lastPage}
                            />
                        </div>
                    </Fragment>
                }
            </Card>
        </Fragment>
    )
}
export default FileUploadPage;