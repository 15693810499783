import { useCallback, useEffect, useState } from "react";
import config from "../../../../config";
import { useGlobalFunction } from "../../../../generalFunction";
import api from "../../../../api";
import { pageObj } from "../../../../pageObj";

export function usePesertaLogin() {
  const {
    showLoading,
    closeLoading,
    pageValue,
    setPageValue,
    setAlertValue,
    alertValue,
    handleCloseAlert,
  } = useGlobalFunction();
  const [listData, setListData] = useState({ data: [], isLoading: false });
  const peserta = [];

  const fetchData = useCallback(
    async (q = "", offset = 0, limit = 6) => {
      setListData((listData) => ({ ...listData, isLoading: true, data: [] }));
      showLoading();
      try {
        let params = {
          offset: offset,
          limit: limit,
        };
        const res = await api
          .get(`${config.endPoint.pesertaWeb}`, { params: params })
          .then((res) => res.data);
        if (res) {
          setListData((listData) => ({
            ...listData,
            isLoading: false,
            data: res.results,
          }));
          setPageValue((pageValue) => ({
            ...pageValue,
            obj: pageObj(res.count, limit, offset),
            lastPage: Math.ceil(parseInt(res.count) / parseInt(limit)),
          }));
        }
      } catch (error) {
        setListData((listData) => ({ ...listData, isLoading: true, data: [] }));
        let msg = "Internet Disconnect";
        let { response } = error;
        if (response && response.data && response.data.message) {
          msg = response.data.message;
        }
        setAlertValue((alertValue) => ({
          ...alertValue,
          show: true,
          text: "Error",
          subText: msg,
          color: "danger",
        }));
        setTimeout(() => {
          setAlertValue((alertValue) => ({ ...alertValue, show: false }));
        }, config.timeOutValue);
        closeLoading();
      }
    },
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handlePaginate = (page) => {
    let myOffset = parseInt(page) * parseInt(6) - parseInt(6);
    setPageValue((pageValue) => ({
      ...pageValue,
      page: page,
      offset: myOffset,
    }));
    fetchData(pageValue.search, myOffset, 6);
  };

  return {
    peserta,
    pageValue,
    listData,
    handlePaginate,
    setAlertValue,
    alertValue,
    handleCloseAlert,
  };
}
