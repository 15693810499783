import { useCallback, useContext, useEffect, useState } from "react";
import { useGlobalFunction } from "../../../../generalFunction";
import api from "../../../../api";
import config from "../../../../config";
import { useLocation } from "react-router-dom";
import { ThemeContext } from "../../../../context/ThemeContext";
import { pageObj } from "../../../../pageObj";

export function useRevokeHotel(){
    const { 
        isLoading, setIsLoading, alertValue, setAlertValue, handleCloseAlert,
        catchFetching, showLoading, closeLoading, pageValue, setPageValue,
        modalValue, setModalValue, handleCloseModal
    } = useGlobalFunction();
    const location = useLocation()
    const { setTitle } = useContext(ThemeContext);
    const [listData, setListData] = useState([]);

    const fetchHotel = useCallback(async() => {
        showLoading();         
        try{
            const res = await api.get(`${config.endPoint.hotel}`).then(res => res.data);
            let tmp = [{value : '', label : '-- Filter Hotel --'}]
            if (res){
                res.results.map(post => tmp.push({value : post.encrypt, label : post.nama}))
            }
            setPageValue(pageValue => ({...pageValue, 
                hotel_obj: tmp,
                hotel : location && location.search ? location.search.replace('?q=', '') : ''
            }))
            closeLoading();                   
        }catch(error){
            closeLoading();                               
            let msg = 'Unable to save Data';
            let { response } = error;            
            
            catchFetching(error, 'admin')            
            if (response && response.data && response.data.message){
                msg = response.data.message
            }
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }));
            }, config.timeOutValue)  
        }
        // eslint-disable-next-line 
    },[setIsLoading, setAlertValue])

    useEffect(() => {
        fetchHotel();
    },[fetchHotel])

    useEffect(() => {
        setTitle('Revoke hotel')
    }, [setTitle])

    const fetchData = useCallback(async(q='', offset=0, limit=config.itemPerPage, hotel_encrypt='') => {
        showLoading();
        setListData([]);
        try{
            let params = {
                offset : offset,
                limit : limit,
                tipe : 'hotel'
            }
            if (q){
                params['q'] = q;
            }
            if (hotel_encrypt){
                params['hotel_encrypt'] = hotel_encrypt
            }
            
            const res = await api.get(`${config.endPoint.hotelPeserta}`, {params: params}).then(res => res.data );
            if (res){
                setListData(res.results);
                setPageValue(pageValue => ({...pageValue,
                    obj : pageObj(res.count, limit, offset),
                    lastPage : Math.ceil(parseInt(res.count) / parseInt(limit))
                }))
            }
            closeLoading();
            setIsLoading();
        }catch(error){
            closeLoading();
            setIsLoading(false);
            let msg = 'Unable to save Data';
            let { response } = error;            
            
            catchFetching(error, 'admin')            
            if (response && response.data && response.data.message){
                msg = response.data.message
            }
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }));
            }, config.timeOutValue)            
        }
        // eslint-disable-next-line 
    },[setIsLoading, setAlertValue])

    useEffect(() => {
        if (location && location.search){                       
            fetchData('', 0, config.itemPerPage,  location.search.replace('?q=', ''))
        }else{
            fetchData();
        }
    },[fetchData, location])

    const handleDelete = (post) => {
        console.log('post is >>>>', post);
        setModalValue(modalValue => ({...modalValue,
            show: true, text : `Apakah anda yakin akan melakukan revoke perusahaan ${post.nama} an. peserta ${post.peserta_display} pada  ${post.hotel_display}`,
            title : 'konfirmasi',
            id : post.id,
            tipe: 'delete'
        }))
    }

    const handleSubmitDelete = async() => {
        setIsLoading(true);
        try{
            const res = await api.delete(`${config.endPoint.hotelPeserta}${modalValue.id}/`).then(res => res.data);
            if (res){
                fetchData(pageValue.search, pageValue.offset, config.itemPerPage, pageValue.hotel);
                handleCloseModal();
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            catchFetching(error, 'admin');
            setAlertValue(alertValue => ({...alertValue,
                show: true,
                text : 'Unable to delete data',
                color : 'danger'
            }))
        }
    }

    const handleChangeSearch = (e) => {
        const { name, value } = e.target;
        setPageValue(pageValue => ({...pageValue, [name] : value}))        
        if (name === 'hotel'){
            fetchData(pageValue.search, pageValue.offset, config.itemPerPage, value)
        }
    }



    const handleSearch = () => {
        setPageValue(pageValue => ({...pageValue, offset: 0, page : 1}));
        fetchData(pageValue.search, 0, config.itemPerPage, pageValue.hotel)
    }

    const handleKeyDownSearch = (e) => {
        if (e.key === "Enter"){
            handleSearch();
        }
    }

    const handlePaginate = (page) => {
        let myOffset = (parseInt(page) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
        setPageValue(pageValue => ({...pageValue, page : page, offset: myOffset}))
        fetchData(pageValue.search, myOffset, config.itemPerPage, pageValue.hotel);
    }



    // resend email
    const [resendEmailForm, setResendEmailForm] = useState({
        id: {
            name: 'id',
            type: 'text',
            value: '',
            show: false,
            required: true,
            errorMsg: '',
            showError: false,
            label: 'Email Template',
            readOnly: true,
        },
        email_template: {
            name: 'email_template',
            type: 'select',
            obj: [],
            value: '',
            show: true,
            required: true,
            errorMsg: '',
            showError: false,
            label: 'Email Template',
            readOnly: false,
            disabled: false,
        },
        perusahaan: {
            name: 'perusahaan',
            type: 'text',
            value: '',
            show: true,
            required: true,
            errorMsg: '',
            showError: false,
            label: 'Perusahaan',
            readOnly: true,
            disabled: true,
        },
        to: {
            name: 'to',
            type: 'text',
            value: '',
            show: true,
            required: true,
            errorMsg: '',
            showError: false,
            label: 'Tujuan',
            readOnly: true,
            disabled: true,
        },
        subject: {
            name: 'subject',
            type: 'text',
            value: '',
            show: true,
            required: true,
            errorMsg: '',
            showError: false,
            label: 'Subject'
        },
        body_email: {
            name: 'body_email',
            type: 'editor',
            value: '',
            show: true,
            required: true,
            errorMsg: '',
            showError: false,
            label: 'Body Email'
        },
    })
    const fetchEmailTemplate = useCallback(async () => {
        setIsLoading(true);
        try {
            const res = await api.get(`${config.endPoint.masterEmailTemplate}`).then(res => res.data);
            let tmp = [{ value: '', label: '' }];
            if (res) {
                res.results.map(post => tmp.push({ value: post.id, label: `${post.kode_display} | Subject : ${post.subjek}` }))
            }
            setResendEmailForm(resendEmailForm => ({
                ...resendEmailForm,
                email_template: { ...resendEmailForm.email_template, obj: tmp }
            }))
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            let msg = 'Unable to save Data';
            let { response } = error;

            catchFetching(error, 'admin')
            if (response && response.data && response.data.message) {
                msg = response.data.message
            }
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }));
            }, config.timeOutValue)
        }
        // eslint-disable-next-line
    }, [setIsLoading, setAlertValue])

    const handleResendEmail = (post) => {
        setModalValue(modalValue => ({
            ...modalValue,
            show: true, 
            tipe: 'resend_email', 
            title: 'Resend Email', 
            id: post.id
        }))
        fetchEmailTemplate();
        // console.log('post is >>>', post);
        setResendEmailForm(resendEmailForm => ({
            ...resendEmailForm,
            id: { ...resendEmailForm.id, value: post.id },
            to: { ...resendEmailForm.to, value: post.email_perusahaan },
            perusahaan: { ...resendEmailForm.perusahaan, value: post.perusahaan_display },
            email_template: { ...resendEmailForm.email_template, value: "" },
            body_email: { ...resendEmailForm.body_email, value: "" },
            subject: { ...resendEmailForm.subject, value: "" },
        }))
    }

    const replaceString = async (id = 0) => {
        setIsLoading(false);
        try {
            let tmp = {
                'template_id': id,
                'kontak_id': modalValue.id
            }
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmp));
            const res = await api.post(`${config.endPoint.replaceString}`, newForm).then(res => res.data);
            if (res) {
                setResendEmailForm(resendEmailForm => ({
                    ...resendEmailForm,
                    subject: { ...resendEmailForm.subject, value: res.subject },
                    body_email: { ...resendEmailForm.body_email, value: res.results }
                }))
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            let msg = 'Unable to save Data';
            let { response } = error;

            catchFetching(error, 'admin')
            if (response && response.data && response.data.message) {
                msg = response.data.message
            }
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }));
            }, config.timeOutValue)
        }
    }

    const handleChangeResendEmailForm = (e) => {
        const { name, value } = e.target;
        if (name === 'email_template') {
            replaceString(value);
        }
    }

    const handleSubmitResendEmail = async () => {
        setIsLoading(true);
        try {
            let countError = 0;
            let form = { ...resendEmailForm };
            let tmpForm = {}
            Object.entries(form).map(([index, post]) => {
                if (post.required && (post.value === '' || post.value === null)) {
                    post.showError = true;
                    countError++;
                } else {
                    tmpForm[index] = post.value;
                }
                return true;
            });

            if (countError > 0) {
                setResendEmailForm(resendEmailForm);
                setIsLoading(false);
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Lengkapi Data', color: 'danger' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }))
                }, config.timeOutValue);
                return;
            }

            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmpForm));

            let res = await api.post(`${config.endPoint.resendEmail}`, newForm).then(res => res.data);
            if (res) {
                handleCloseModal();
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Email terkirim', color: 'success' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue)
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            let msg = 'Unable to save Data';
            let { response } = error;

            catchFetching(error, 'admin')
            if (response && response.data && response.data.message) {
                msg = response.data.message
            }
            setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }));
            }, config.timeOutValue)
        }
    }
    

    return {
        isLoading, setIsLoading, alertValue, setAlertValue, handleCloseAlert,
        handleKeyDownSearch, handlePaginate, handleChangeSearch,
        handleDelete, handleSubmitDelete, listData, modalValue, handleCloseModal, pageValue,
        setModalValue, handleSearch, handleResendEmail, handleSubmitResendEmail,
        resendEmailForm, handleChangeResendEmailForm, setResendEmailForm, 
    }
}